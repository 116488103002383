import Papa from 'papaparse';

export const importerMix = {
  data() {
    return {
      parsedRows: [],
      fileParsing: false,
      loading: false, // Loading is a network variable (hitting the api)

      allFields: [],
      requiredFields: [],
      validFieldsFound: [],
      ignoredFields: [],
      missingOptionalFields: [],

      importFinished: false,
      importMessage: '',
      error: false,
      errorMessage: 'Error, please try again later or contact support@wrestlingiq.com',
    };
  },
  methods: {
    arrayToStr(array) {
      return _.join(array, ', ');
    },
    showUploadForm() {
      return this.parsedRows.length === 0;
    },
    reuploadFile() {
      this.parsedRows = [];
    },
    parseFile() {
      const vm = this;

      console.log('parsing file');
      if (vm.fileParsing) {
        return;
      }

      if (!$('#files')[0].files.length) {
        alert('Please choose at least one file to import.');
        return;
      }

      vm.fileParsing = true;
      vm.error = false;

      const config = vm.papaConfig();
      const file = $('#files')[0].files[0];

      Papa.parse(file, config);
    },
    papaCompleteFn(results) {
      if (results && results.errors) {
        const vm = this;
        vm.fileParsing = false;

        // Success
        if (results.data && results.data.length > 0) {
          vm.transformResults(results.data, results.meta);
          return;
        }

        // Or failure
        let error = 'Something went wrong with the file upload';
        if (results.errors && results.errors.length > 0) {
          error = results.errors[0];
        }

        vm.error = true;
        vm.errorMessage = error;
      }
    },
    papaErrorFn(err, file) {
      const vm = this;
      vm.error = true;
      vm.errorMessage = `Error ${err}`;
      vm.fileUploading = false;
    },
    papaConfig() {
      return {
        delimiter: ',',
        header: true,
        dynamicTyping: false,
        skipEmptyLines: true,
        worker: false,
        complete: this.papaCompleteFn,
        error: this.papaErrorFn,
        download: false,
      };
    },
    transformResults(data, meta) {
      console.log(meta);
      console.log(data);
      const vm = this;
      const missingFields = _.difference(this.requiredFields, meta.fields);
      if (missingFields.length > 0) {
        vm.errorMessage = `${_.join(missingFields)} is required as a header in your csv file`;
        vm.error = true;
        return;
      }

      const ignoredValues = _.difference(meta.fields, this.allFields);
      if (ignoredValues.length > 0) {
        vm.ignoredFields = ignoredValues;
      }

      const missingOptionalInfo = _.difference(this.allFields, meta.fields);
      if (missingOptionalInfo.length > 0) {
        vm.missingOptionalFields = missingOptionalInfo;
      }

      // Needs the opposite of missingOptionalInfo
      vm.validFieldsFound = _.intersection(this.allFields, meta.fields);

      data.forEach((uploaded_row) => {
        // This needs to be defined on the actual implentation not the mixin
        vm.parsedRows.push(vm.formatUploadedRow(uploaded_row))
      });
      vm.uploadParsed();
    },
    uploadParsed() {

    },
  },
};
