<template>
    <div class="m-b-md">
        <div class="flex justify-space-between align-items-center m-t-sm m-b-xs">
            <div class="flex gap-x-xs align-items-center">
                <i :class="iconClass"></i><p class="m-y-none">{{ readableTitle }}</p>
            </div>
            <div>
                <i :class="getIcon()" v-on:click="showList = !showList"></i>
            </div>
        </div>
        <template v-if="loadList">
            <div v-show="showList" class="m-l-sm">
                <full-load-list
                        :base-url="getListUrl()"
                        response-key="event_invites">
                    <template v-slot:list="slotProps">
                        <event-invite :allow-edit="true" :invite="slotProps.result"></event-invite>
                    </template>
                </full-load-list>
            </div>
        </template>
    </div>
</template>

<script>
import FullLoadList from './full_load_list.vue';
import EventInvite from './event_invite.vue';

export default {
  name: 'event-invite-list',
  components: {
    FullLoadList,
    EventInvite,
  },
  props: {
    // For the title / text
    title: {
      type: String,
      required: true,
    },
    // For the API
    status: {
      type: String,
      required: true
    },
    eventId: {
      type: Number,
      required: true,
    },
    defaultOpen: {
      type: Boolean,
      default: false,
    },
    // WrestlerProfile, ParentProfile, or CoachProfile
    forProfileType: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      loadList: false,
      showList: false,
      count: null,
    };
  },
  created() {
    const vm = this;
    vm.loadList = true;
    let localShowList = JSON.parse(localStorage.getItem(this.localStorageKey)) || false;
    if (vm.defaultOpen || localShowList) {
      vm.showList = true;
    }

    vm.$on('full-list-count', vm.fullListLoaded);
  },
  destroyed() {
    const vm = this;
    vm.$off('full-list-count', vm.fullListLoaded);
  },
  computed: {
    iconClass() {
      if (this.title === 'Accepted') {
        return 'fa fa-check text-green-600';
      } else if (this.title === 'Declined') {
        return 'fa fa-times text-red-600';
      } else if (this.title === 'Pending') {
        return 'fa fa-clock-o text-gray-600';
      }
      return '';
    },
    readableTitle() {
      const count = this.count ? `(${this.count})` : '';
      return `${this.title} ${count}`;
    },
    localStorageKey() {
      return `event_invite_list_open_${this.status}`;
    }
  },
  watch: {
    showList() {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.showList));
    }
  },
  methods: {
    fullListLoaded(count) {
      this.count = count;
    },
    getIcon() {
      if (this.showList) {
        return 'fa fa-minus';
      }
      return 'fa fa-plus';
    },
    getListUrl() {
      let profileType = '';
      if (this.forProfileType !== 'all') {
        profileType = `&profile_type=${this.forProfileType}`
      }
      return `${this.$apiService.eventInvitesUrl(this.eventId)}?status=${this.status}${profileType}`;
    },
  },
};
</script>
