<template>
    <div :class="getVisibilityClass()">
        <div>
            <spinner v-if="loading"></spinner>
        </div>
        <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
        <div :class="containerClass" v-show="resultsLoaded">
            <slot name="list"
                  v-for="result in results"
                  :result="result">
            </slot>
            <slot name="empty" v-if="isEmpty">
              <p>None found</p>
            </slot>
        </div>
    </div>
</template>

<script>
import Spinner from './spinner.vue';

export default {
  components: {
    Spinner,
  },
  name: 'full-load-list',
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    responseKey: {
      type: String,
      required: true,
    },
    containerClass: {
      type: String,
      default: 'list-results m-l-xs'
    }
  },
  data() {
    return {
      loading: false,

      resultsLoaded: false,
      results: [],

      error: false,
      errorMessage: '',

      dimSearchResults: false,
    };
  },
  created() {
    const vm = this;
    vm.getList();
  },
  computed: {
    isEmpty() {
      return this.resultsLoaded && this.results.length === 0;
    }
  },
  methods: {
    getVisibilityClass() {
      if (this.dimSearchResults) {
        return 'dim';
      }
      return '';
    },
    getList() {
      const vm = this;

      vm.loading = true;

      vm.$apiService.loadAllPages(vm.baseUrl, vm.responseKey)
        .then((objects) => {
          vm.results = objects;
          vm.resultsLoaded = true;
          vm.loading = false;
          vm.$parent.$emit('full-list-count', vm.results.length);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving results ${error.toString()}`;
          vm.error = true;
        });
    },
  },
};
</script>
