<template>
    <p id="event-time">
        {{ displayTime }}
    </p>
</template>

<script>
export default {
  name: 'event-time',
  props: [
    'startAt',
    'endAt',
    'allDay',
    'forPaidSession',
  ],
  data() {
    return {
      displayTime: '',
    };
  },
  created() {
    const vm = this;

    let start;
    let end;

    if (vm.allDay) {
      const ambiguousStart = vm.startAt.split('T')[0];
      const ambiguousEnd = vm.endAt.split('T')[0];
      start = moment(ambiguousStart);
      // Subtract one day for 'human speak' UI display
      end = moment(ambiguousEnd).subtract(1, 'day');
    } else {
      start = moment(vm.startAt);
      end = moment(vm.endAt);
    }

    const multiDay = end.diff(start, 'days') >= 1;
    // todo DRY this up
    if (vm.allDay) {
      if (vm.forPaidSession) {
        vm.displayTime = `${start.format('M/D/YYYY')} to ${end.format('M/D/YYYY')}`;
        return;
      }

      if (multiDay) {
        vm.displayTime = `All day ${start.format('M/D/YYYY')} to ${end.format('M/D/YYYY')}`;
        return;
      }

      vm.displayTime = `All day ${start.format('M/D/YYYY')}`;
    } else {
      if (multiDay) {
        vm.displayTime = `${start.format('M/D/YYYY hh:mm a')} to ${end.format('M/D/YYYY hh:mm a')}`;
        return;
      }

      vm.displayTime = `${start.format('M/D/YYYY hh:mm a')} to ${end.format('hh:mm a')}`;
    }
  },
};
</script>
