<template>
  <div class="row">
    <div class="col-md-12">
      <div class="ibox">
        <div class="ibox-content p-t-m">
          <div class="row m-t-m">
            <div class="col-md-4">
              <h1 class="form-section-title">Product Info</h1>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label for="product_name">Name</label>
                <input id="product_name" v-model="name" type="text" class="form-control">
              </div>
              <div class="form-group">
                <label class="m-r-sm">
                  Category
                </label>
                <select v-model="category" class="form-control">
                  <option selected disabled>Choose category...</option>
                  <option value="clothing">Clothing</option>
                  <option value="donation">Donation</option>
                  <option value="service">Service</option>
                  <option value="ticket">Ticket</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="form-group">
                <label class="m-r-sm m-b-none">
                  Description
                </label>
                <input id="description-editor" type="hidden" name="content">
                <trix-editor id="trix-description" input="description-editor" class="trix-content"></trix-editor>
              </div>
              <div class="form-group" v-show="isClothing">
                <label class="m-r-sm m-b-none">
                  Fabric & Care (optional)
                </label>
                <input id="fabric-editor" type="hidden" name="content">
                <trix-editor id="trix-fabric" input="fabric-editor" class="trix-content"></trix-editor>
              </div>
            </div>
          </div>
          <div class="row m-t-xl">
            <div class="col-md-4">
              <h1 class="form-section-title">Sku Pricing</h1>
            </div>
            <div class="col-md-8">
              <div v-if="!this.category">
                <p>Please select a category to add skus.</p>
              </div>
              <div v-else>
                <div class="bg-gray-50 border-bottom p-y-m grid font-semi text-gray-500 uppercase tracking-wide m-b-sm m-t-m"
                     :class="isClothing ? 'grid-cols-6' : 'grid-cols-4'"
                >
                  <div class="p-l-xs">
                    Name
                  </div>
                  <div>
                    Price
                  </div>
                  <div v-if="isClothing">
                    Color
                  </div>
                  <div v-if="isClothing">
                    Size
                  </div>
                  <div class="">
                    Sku ID
                  </div>
                  <div class="">
                    Status
                  </div>
                </div>
                <div v-for="(plan, index) in billingPlans"
                     class="grid sm-grid-cols-1 gap-x-sm"
                     :class="planGridClass(index)">
                  <div class="form-group">
                    <label class="font-normal sr-only">Name</label>
                    <input v-model="plan.name" type="text" class="form-control">
                  </div>
                  <div class="form-group">
                    <label class="font-normal  sr-only">Amount</label>
                    <money v-model="plan.amount" v-bind="money" class="form-control"></money>
                  </div>
                  <div class="form-group" v-if="isClothing">
                    <label class="font-normal sr-only">Color</label>
                    <input v-model="plan.color" type="text" class="form-control">
                  </div>
                  <div class="form-group" v-if="isClothing">
                    <label class="font-normal sr-only">Size</label>
                    <input v-model="plan.size" type="text" class="form-control">
                  </div>
                  <div class="form-group">
                    <label class="font-normal sr-only">Sku ID</label>
                    <input v-model="plan.vendor_sku_id" type="text" class="form-control">
                  </div>
                  <div class="form-group">
                    <label class="checkbox-inline">
                      <input type="checkbox" v-model="plan.active"> Active
                    </label>
                  </div>
                </div>
                <div class="grid grid-cols-3 md-grid-cols-1 gap-x-sm align-items-center">
                  <div class="bg bg-gray-50 border-gray-200 rounded-sm p-sm m-b-sm flex-shrink-1" v-if="isClothing">
                    <h2 class="m-b-none"><i class="fa fa-magic"></i> Size/Color Shortcuts</h2>
                    <p>
                      Enter a comma separated list of sizes and colors to quickly add them as unique skus.
                    </p>
                    <div class="form-group">
                      <label for="size-shortcut">Sizes</label>
                      <input id="size-shortcut" type="text" v-model="sizeShortcut" class="form-control"/>
                    </div>
                    <div class="form-group">
                      <label for="color-shortcut">Colors</label>
                      <input id="color-shortcut" type="text" v-model="colorShortcut" class="form-control"/>
                    </div>
                    <div class="form-group">
                      <label for="sizes">Price</label>
                      <money v-model="priceShortcut" v-bind="money" class="form-control"></money>
                    </div>
                    <button class="btn btn-default" @click.prevent="generateSizes">
                      <i class="fa fa-plus-square"></i> Generate Skus
                    </button>
                  </div>
                  <div v-if="isClothing" class="text-center flex justify-center">
                    <h3>Or</h3>
                  </div>
                  <div class="flex" :class="isClothing ? 'justify-center': ''">
                    <a class="btn btn-default" @click.prevent="addBillingPlan('', '', 0)">
                      <i class="fa fa-plus"></i> Add <span v-if="isClothing">Single</span> Sku
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-t-xl">
            <div class="col-md-4">
              <h1 class="form-section-title">Add-ons</h1>
              <p>Not finding anything? Create <a href="/upsells" target="_blank">add-ons first here.</a></p>
            </div>
            <div class="col-md-8">
              <search
                  class="search-form"
                  response-key="upsells"
                  placeholder="Type name of the add-on..."
                  :mutate-history="false"
                  :allow-add="false"
                  :small-input="true"
                  :display-all-on-empty="false"
                  readable-model-name="upsell"
                  base-url="/api/v1/upsells"
              >
                <template v-slot:list="slotProps">
                  <div
                      class="border-top m-t-sm m-b-xs p-t-sm p-b-xs cursor-pointer flex justify-space-between align-items-center p-r-sm"
                      @click="upsellClicked(slotProps.result)"
                  >
                    <div>
                      {{ slotProps.result.name }}
                    </div>
                    <div
                        class="border rounded-sm border-blue-200 border-solid font-small"
                        style="padding: 2px 6px"
                    >
                      Add
                    </div>
                  </div>
                </template>
              </search>
              <div class="flex flex-wrap gap-x-xxs gap-y-xxs m-t-sm">
                <button
                    @click="upsellClicked(upsell)"
                    class="btn btn-success btn-outline rounded-lg font-small"
                    style="padding: 2px 6px"
                    v-for="upsell in notDestroyedUpsells"
                >
                  {{ upsell.name }}
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row m-t-xl">
            <div class="col-md-4">
              <h1 class="form-section-title">Images</h1>
            </div>
            <div class="col-md-8 filepond--grid-layout">
              <pintura-photo-uploader
                  :access-key="accessKey"
                  :endpoint="endpoint"
                  :cloudfront-base-url="cloudfrontBaseUrl + '/'"
                  :existing-attachments="existingPhotosUniqByS3Key"
                  :max-files="10"
                  prefix="billing_plans/"
                  imageCropAspectRatio="1"
                  @photos-changed="photosChanged"
              ></pintura-photo-uploader>
            </div>
          </div>
          <div class="button-area text-right m-t-xl">
            <div>
              <ladda-button @lbClicked="saveProduct" el-class="btn-primary" :loading="loading">
                Save
              </ladda-button>
              <div v-if="error">
                <small>
                  <span class="text-danger">{{ errorMessage }}</span>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { errorableMix } from './mixins/errorable_mix';
import { trixFileAttachmentMix } from './mixins/trix_file_attachment_mix';
import { Money } from 'v-money';
import { photoUploadMix } from './mixins/photo_upload_mix.js';
import PinturaPhotoUploader from './pintura_photo_uploader.vue';
import Search from '../../shared/search.vue';

export default {
  name: 'product-form',
  mixins: [errorableMix, trixFileAttachmentMix, photoUploadMix],
  components: {
    PinturaPhotoUploader,
    Money,
    LaddaButton,
    Spinner,
    Search,
  },
  props: {
    existingProduct: {
      type: Object,
      required: false,
    },
    currency: {
      type: String,
      default: 'usd'
    }
  },
  data() {
    return {
      name: '',
      description: '',
      descriptionTrixEditor: null,
      category: '',
      fabricCare: '',
      fabricCareTrixEditor: null,
      //  size and color shortcu are for clothing only and a comma separated list of sizes and colors
      sizeShortcut: '',
      colorShortcut: '',
      priceShortcut: '',
      billingPlanAddOns: [],
      // A key by id array of upsells to make billingPlanAddOns make sense in the UI
      upsells: {},

      loading: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },

      billingPlans: [],
    };
  },
  watch: {
    category() {
      if (this.existingProduct) {
        return;
      }

      if (this.category === 'clothing') {
        this.billingPlans = [];
      } else {
        if (this.billingPlans.length === 0) {
          // shortcut to add one plan to it, if needed
          this.addBillingPlan('', '', this.name);
        }
      }
    },
  },
  mounted() {
    if (this.existingProduct) {
      this.name = this.existingProduct.name;
      this.description = this.existingProduct.description;
      this.category = this.existingProduct.category;
      this.fabricCare = this.existingProduct.fabric_and_care;
      this.billingPlans = _.map(this.existingProduct.billing_plans, (plan) => {
        plan._destroy = false;
        plan.amount = plan.amount / 100;
        return plan;
      });
      // Is this going to work? Needs lots of testing, I'm missing something here
      this.billingPlanAddOns = _.flatMap(this.existingProduct.billing_plans, (plan) => {
        // Build up our key by id array of upsells
        plan.upsells.forEach((upsell) => {
          this.$set(this.upsells, upsell.id, upsell);
          this.$set(this.upsells[upsell.id], '_destroy', false);
        });

        return _.map(plan.billing_plan_add_ons, (addOn) => {
          addOn._destroy = false;
          return addOn;
        });
      });

      // Trix might already be initialized so explicitly try to load it
      this.loadTrixContent(this.descriptionTrixEditor, this.description);
      this.loadTrixContent(this.fabricCareTrixEditor, this.fabricCare);
    };
    // otherwise listen for that init event
    document.addEventListener('trix-initialize', this.trixInit);
  },
  destroyed() {
    document.removeEventListener('trix-initialize', this.trixInit);
  },
  computed: {
    notDestroyedUpsells() {
      return _.filter(Object.values(this.upsells), (upsell) => {
        return !upsell._destroy;
      });
    },
    // Method that checks whether the current category is clothing
    isClothing() {
      return this.category === 'clothing';
    },
    existingPhotosUniqByS3Key() {
      return _.uniqBy(this.existingAttachments, 'key');
    },
    addOnParams() {
      // Remove all the front end stuff we use to manage the addons
      return _.flatMap(this.billingPlanAddOns, (addOn) => {
        return {
          id: addOn.id,
          _destroy: addOn._destroy,
          upsell_id: addOn.upsell_id,
        };
      });
    },
  },
  methods: {
    billingPlansParams(withPhotos, withAddOns) {
      return this.billingPlans.map((plan) => {
        let params = {
          id: plan.id,
          updated_at: new Date().toISOString(),
          name: plan.name,
          _destroy: plan._destroy,
          amount: _.round(plan.amount * 100),
          currency: plan.currency,
          usage_type: 'licensed',
          interval: 'one_time',
          inventory_type: 'infinite',
          vendor_sku_id: plan.vendor_sku_id,
          sold_out: plan.sold_out,
          active: plan.active,
          color: plan.color,
          size: plan.size,
          // photo attributes are weird, because we push them into the billing plans, we do not use the attachment id we create a new record
          photos_attributes:  this.attachmentParamsForBillingPlan(plan),
          billing_plan_add_ons_attributes: this.upsellParamsForBillingPlan(plan),
        };

        // if !withPhotos, remove the photo_attributes key
        if (!withPhotos) {
          delete params.photos_attributes;
        }

        // if !withAddOns, remove the billing_plan_add_ons_attributes key
        if (!withAddOns) {
          delete params.billing_plan_add_ons_attributes;
        }

        return params;
      });
    },
    attachmentAlreadyAttachedToOtherPlan(attachment) {
      // loop through all billing plan photos, return whether this attachment.id is present in any of them
      return _.some(this.billingPlans, (plan) => {
        return _.some(plan.photos, (photo) => {
          return photo.id === attachment.id;
        });
      });
    },
    attachmentParamsForBillingPlan(plan) {
      return _.compact(_.flatMap(this.attachmentParams, (attachment) => {
        // Loop through the photos on the plan, if we find the attachment id, we update the _destroy flag as needed
        for (let i = 0; i < plan.photos.length; i++) {
          if (plan.photos[i].id === attachment.id) {
            return {
              id: attachment.id,
              _destroy: attachment._destroy,
            };
          }
        }

        // If we didn't find the attachment on any other object, we add it to the array because this is a new attachment
        // todo test with adding a new photo, but not finalizing, etc
        if (!this.attachmentAlreadyAttachedToOtherPlan(attachment)) {
          return {
            id: attachment.id,
            _destroy: attachment._destroy,
          };
        }

        return null;
      }));
    },
    upsellParamsForBillingPlan(plan) {
      return _.compact(_.flatMap(this.addOnParams, (addOn) => {
        if (!addOn.id) {
          return {
            upsell_id: addOn.upsell_id,
          }
        }

        for (let i = 0; i < plan.billing_plan_add_ons.length; i++) {
          if (plan.billing_plan_add_ons[i].id === addOn.id) {
            return {
              id: addOn.id,
              _destroy: addOn._destroy,
              upsell_id: addOn.upsell_id,
            };
          }
        }

        return null;
      }));
    },
    upsellClicked(upsell) {
      this.$notificationManager.$emit("profile-clicked");
      // This method loop sthrough billing plan addons, looking for billing plan add ons with upsell.id == to billing_plan_add_on.upsell_id
      // if found, it updates the _destroy flag on it, if not found, it adds a new billing plan add on with the upsell id
      const existingAddOns = _.filter(this.billingPlanAddOns, (addOn) => {
        return addOn.upsell_id === upsell.id;
      });
      const found = existingAddOns.length > 0;
      let isDestroyed = false;
      if (found) {
        // write for loop of this same map code
        let draftIndexesToRemove = []
        for (let i = 0; i < this.billingPlanAddOns.length; i++) {
          if (this.billingPlanAddOns[i].upsell_id === upsell.id) {
            this.billingPlanAddOns[i]._destroy = !this.billingPlanAddOns[i]._destroy;
            if (this.billingPlanAddOns[i]._destroy) {
              isDestroyed = true;
            }

            if (this.billingPlanAddOns[i]._destroy && !this.billingPlanAddOns[i].id) {
              draftIndexesToRemove.push(i)
            }
          }
        }
        this.billingPlanAddOns = this.billingPlanAddOns.filter((addOn, index) => {
          return !draftIndexesToRemove.includes(index)
        })
      } else {
        // If this upsell is not found in the billing plan add ons, create it
        this.billingPlanAddOns.push({
          id: null,
          upsell_id: upsell.id,
          _destroy: false,
        });
      }
      this.$set(this.upsells, upsell.id, upsell);
      this.$set(this.upsells[upsell.id], '_destroy', isDestroyed);
    },
    planGridClass(index) {
      let classes = ''
      if (index > 0) {
        classes += ' m-t-xs';
      }
      if (this.isClothing) {
        classes += ' grid-cols-6';
      } else {
        classes += ' grid-cols-4';
      }

      return classes;
    },
    generateSizes() {
      const sizes = this.sizeShortcut.split(',');
      const colors = this.colorShortcut.split(',');
      sizes.forEach((size) => {
        colors.forEach((color) => {
          this.addBillingPlan(color.trim(), size.trim(), this.priceShortcut);
        });
      });

      this.sizeShortcut = '';
      this.colorShortcut = '';
      this.priceShortcut = '';
    },
    addBillingPlan(color, size, amount) {
      this.billingPlans.push({
        name: this.name,
        _destroy: false,
        amount: amount,
        currency: this.currency,
        usage_type: 'licensed',
        interval: 'one_time',
        inventory_type: 'infinite',
        vendor_sku_id: '',
        sold_out: false,
        active: true,
        color: color,
        size: size,
        photos: [],
        billing_plan_add_ons: [],
      });
    },
    trixInit(e) {
      if (e.target.id === 'trix-description') {
        this.descriptionTrixEditor = e.target.editor;
        this.loadTrixContent(this.descriptionTrixEditor, this.description);
      } else if (e.target.id === 'trix-fabric') {
        this.fabricCareTrixEditor = e.target.editor;
        this.loadTrixContent(this.fabricCareTrixEditor, this.fabricCare);
      }
    },
    loadTrixContent(trixEditor, html) {
      if (trixEditor) {
        trixEditor.loadHTML(html);
      }
    },
    validate() {
      if (!this.name) {
        this.error = true;
        this.errorMessage = 'Name is required';
        return false;
      }
      if (!this.category) {
        this.error = true;
        this.errorMessage = 'Category is required';
        return false;
      }
      const description = $('#description-editor').val()
      if (!description) {
        this.error = true;
        this.errorMessage = 'Description is required';
        return false;
      }
      if (this.billingPlans.length === 0) {
        this.error = true;
        this.errorMessage = 'At least one sku is required';
        return false;
      }
      if (this.isClothing) {
        var missingSizeOrColor = false;
        this.billingPlans.forEach((plan) => {
          if (!plan.size || !plan.color) {
            missingSizeOrColor = true;
          }
        });
        if (missingSizeOrColor) {
          this.error = true;
          this.errorMessage = 'Size and color are required for clothing.';
          return false;
        }
      }

      return true;
    },
    params(withPhotos, withAddOns) {
      return {
        product: {
          name: this.name,
          product_type: 'good', // All e-commerce related products are goods
          category: this.category,
          description: $('#description-editor').val(),
          fabric_and_care: $('#fabric-editor').val(),
          billing_plans_attributes: this.billingPlansParams(withPhotos, withAddOns),
        },
      }
    },
    saveProduct: _.throttle(function() {
      if (this.loading) {
        return;
      }

      let valid = this.validate();
      if (valid) {
        this.error = false;
        this.errorMessage = "";

        if (this.existingProduct) {
          this.updateProductWithDumbRailsIssue();
        } else {
          this.createProduct(this.params(true, true));
        }
      }
    }, 500),
    createProduct(params) {
      this.loading = true;
      const url = this.$apiService.productsUrl();
      axios.post(url, params)
          .then((response) => {
            this.loading = false;
            this.$notificationManager.$emit('show-toast', 'Saved', true);
            window.location.assign('/products');
          })
          .catch((error) => {
            this.loading = false;
            this.showError(this.parseErrorResponse(error));
          });
    },
    // This is a silly way to do an update, because I can't seem to get nested attributes to work well when both
    // photos and add ons are passed in. So we just do two update calls and call it a day.
    async updateProductWithDumbRailsIssue() {
        await this.updateProduct(this.params(true, false));
        await this.updateProduct(this.params(false, true));
        this.loading = false;
        if (!this.error) {
          this.$notificationManager.$emit('show-toast', 'Saved', true);
          window.location.assign('/products');
        }
    },
    async updateProduct(params) {
      // Splice out the excludeKey from product.billing_plan_attributes from the params object. The excludeKey is the Object key
      // of the parameter to remove
      // My really lame attempt at fixing this? Just do teh update twice, one with each set of parameters.
      this.loading = true;
      const url = this.$apiService.productUrl(this.existingProduct.id);
      return axios.put(url, params)
          .then((response) => {})
          .catch((error) => {
            this.loading = false;
            this.showError(this.parseErrorResponse(error));
          });
    }
  },
};
</script>
<style scoped>
</style>
