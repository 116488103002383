<template>
  <div role="form">
    <template v-if="loadingExisting">
      <spinner></spinner>
    </template>
    <div v-show="!loadingExisting">
      <div v-show="!sessionTypeSet">
        <div class="row m-t-m">
          <div class="col-xs-12">
            <h1 class="m-t-xs font-heavy">What are you setting up registration for?</h1>
            <div class="grid sm-grid-cols-1 md-grid-cols-1 grid-cols-3 gap-x-m gap-y-sm m-t-md">
              <div class="flex flex-col relative border-2 border-solid border-gray-200 rounded-xs cursor-pointer p-md">
                <a href="javascript:void(0)" class="cursor-pointer" onclick="Beacon('article', '650cd0d8aed9c75bab283301', { type: 'modal' })">
                  <div class="flex flex-col align-items-center justify-center bg-gray-900 text-gray-50 p-y-m w-full" style="aspect-ratio: 16/9;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                    </svg>
                    <p>Full Season Example</p>
                  </div>
                </a>
                <div class="flex flex-col relative" @click="sessionType = 'full'">
                  <h2 class="font-heavy m-t-lg">Full Season</h2>
                  <p class="m-t-md">
                    Parents will <strong>create an account</strong> before signing up.
                  </p>
                  <p>
                    After registration, parents/wrestlers <strong>will have access</strong> to your team's WrestlingIQ account.
                  </p>
                  <p>
                    You can set up billing as a one time charge or installments.
                  </p>
                  <p class="text-semi m-t-md">
                    Commonly used for:
                  </p>
                  <ul class="p-l-m m-b-lg">
                    <li>Seasonal registration (Folkstyle/Freestyle)</li>
                    <li>Any situation people are registering for your team and need a login (e.g. private lesson)</li>
                  </ul>
                </div>
                <a class="btn btn-primary w-full p-y-m text-semi m-t-auto" @click="sessionType = 'full'">Create a Full Season</a>
              </div>
              <div class="flex flex-col relative border-2 border-solid border-gray-200 rounded-xs cursor-pointer p-md">
                <a href="javascript:void(0)" class="cursor-pointer" onclick="Beacon('article', '650dc66aaed9c75bab2835e9', { type: 'modal' })">
                  <div class="flex flex-col align-items-center justify-center bg-gray-900 text-gray-50 p-y-m w-full" style="aspect-ratio: 16/9;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                    </svg>
                    <p>Camp / Clinic Example</p>
                  </div>
                </a>
                <div class="flex flex-col relative" @click="sessionType = 'guest'">
                  <h2 class="font-heavy m-t-lg">Camp / Clinic</h2>
                  <p class="m-t-md">
                    New parents have a <strong>streamlined guest checkout experience.</strong> Existing parents/wrestlers will have their info pre-filled.
                  </p>
                  <p>
                    Registrants <strong>will not have access</strong> to your team's WrestlingIQ account, but you will still be able to send messages to registrants related to the camp.
                  </p>
                  <p class="m-t-md text-semi">
                    Commonly used for:
                  </p>
                  <ul class="p-l-m m-b-lg">
                    <li>Summer clinic</li>
                    <li>Multi-day/week camp</li>
                  </ul>
                </div>
                <a class="btn btn-primary w-full p-y-m text-semi m-t-auto" @click="sessionType = 'guest'">Create a Camp / Clinic</a>
              </div>
              <div class="flex flex-col relative border-2 border-solid border-gray-200 rounded-xs cursor-pointer p-md">
                <a href="javascript:void(0)" class="cursor-pointer" onclick="Beacon('article', '650dc6c8e7ab134c25de1ab0', { type: 'modal' })">
                  <div class="flex flex-col align-items-center justify-center bg-gray-900 text-gray-50 p-y-m w-full" style="aspect-ratio: 16/9;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                    </svg>
                    <p>Recurring Subscription Example</p>
                  </div>
                </a>
                <div class="flex flex-col relative" @click="sessionType = 'recurring'">
                  <h2 class="font-heavy m-t-lg">Recurring Subscription</h2>
                  <p class="m-t-md">
                    Parents will <strong>create an account</strong> before signing up.
                  </p>
                  <p>
                    After registration, parents/wrestlers <strong>will have access</strong> to your team's WrestlingIQ account.
                  </p>
                  <p>
                    You define the billing schedule (monthly, every two weeks, annually, etc).
                  </p>
                  <p class="text-semi m-t-md">
                    Commonly used for:
                  </p>
                  <ul class="p-l-m m-b-lg">
                    <li>Clubs that bill on a recurring basis instead of seasonally</li>
                    <li>Clubs that offer contracts (e.g. $X/month for 12 months)</li>
                  </ul>
                </div>
                <a class="btn btn-primary w-full p-y-m text-semi m-t-auto" @click="sessionType = 'recurring'">Create a Recurring Subscription</a>
              </div>
              <div class="flex flex-col relative border-2 border-solid border-gray-200 rounded-xs cursor-pointer p-md">
                <a href="javascript:void(0)" class="cursor-pointer" onclick="Beacon('article', '650dc733e7ab134c25de1ab3', { type: 'modal' })">
                  <div class="flex flex-col align-items-center justify-center bg-gray-900 text-gray-50 p-y-m w-full" style="aspect-ratio: 16/9;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                    </svg>
                    <p>Drop in Example</p>
                  </div>
                </a>
                <div class="flex flex-col relative" @click="sessionType = 'dropin'">
                  <h2 class="font-heavy m-t-lg">Drop in</h2>
                  <p class="m-t-md">
                    New parents have a <strong>streamlined guest checkout experience.</strong> Existing parents/wrestlers will have their info pre-filled.
                  </p>
                  <p>
                    Registrants <strong>will not have access</strong> to your team's WrestlingIQ account.
                  </p>
                  <p>
                    Drop-ins allows you to set multiple price points for different class pass amounts (e.g. 1 class pass vs pack of 10).
                  </p>
                  <p class="m-t-md text-semi">
                    Commonly used for:
                  </p>
                  <ul class="p-l-m m-b-lg">
                    <li>Drop in practices</li>
                  </ul>
                </div>
                <a class="btn btn-primary w-full m-t-auto p-y-m text-semi" @click="sessionType = 'dropin'">Create a Drop In</a>
              </div>
              <div class="flex flex-col relative border-2 border-solid border-gray-200 rounded-xs cursor-pointer p-md">
                <a href="javascript:void(0)" class="cursor-pointer" onclick="Beacon('article', '66340e0d0d8e3e7142b0f36a', { type: 'modal' })">
                  <div class="flex flex-col align-items-center justify-center bg-gray-900 text-gray-50 p-y-m w-full" style="aspect-ratio: 16/9;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                    </svg>
                    <p>Trial Example</p>
                  </div>
                </a>
                <div class="flex flex-col relative" @click="sessionType = 'trial'">
                  <h2 class="font-heavy m-t-lg">Trial</h2>
                  <p class="m-t-md">
                    Often used in conjunction with subscriptions, the trial type is the best way to offer a limited number of classes to potential members.
                  </p>
                  <p>
                    Parents will <strong>create an account</strong> before signing up.
                  </p>
                  <p>
                    After registration, parents/wrestlers <strong>will have access</strong> to your team's WrestlingIQ account.
                  </p>
                  <p>You can set price points and the number of class passes available.</p>
                  <p class="m-t-md text-semi">
                    Commonly used for:
                  </p>
                  <ul class="p-l-m m-b-lg">
                    <li>Trial class(es)</li>
                  </ul>
                </div>
                <a class="btn btn-primary w-full m-t-auto p-y-m text-semi" @click="sessionType = 'trial'">Create a Trial</a>
              </div>
              <div class="flex flex-col relative border-2 border-solid border-gray-200 rounded-xs cursor-pointer p-md">
                <a href="javascript:void(0)" class="cursor-pointer" onclick="Beacon('article', '650dc79b6a7bf14d5d3c51fe', { type: 'modal' })">
                  <div class="flex flex-col align-items-center justify-center bg-gray-900 text-gray-50 p-y-m w-full" style="aspect-ratio: 16/9;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                    </svg>
                    <p>Other Example</p>
                  </div>
                </a>
                <div class="flex flex-col relative" @click="sessionType = 'anonymous'">
                  <h2 class="font-heavy m-t-lg">Other</h2>
                  <p class="m-t-md">
                    Only email, first name, and last name are collected.
                  </p>
                  <p>
                    <strong>No parent or wrestler records are created with this session type.</strong>
                  </p>
                  <p class="text-semi m-t-md">
                    Commonly used for:
                  </p>
                  <ul class="p-l-m m-b-lg">
                    <li>Event entry (e.g. coaches clinic, potluck, or team BBQ)</li>
                    <li>Snack menu</li>
                    <li>Anything else you need a simple payment form for</li>
                  </ul>
                </div>
                <a class="btn btn-primary w-full p-y-m text-semi m-t-auto" @click="sessionType = 'anonymous'">Create Other</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="sessionTypeSet">
        <div class="row m-t-m">
          <div class="col-md-4">
            <h1 class="form-section-title">Session Info</h1>
          </div>
          <div class="col-md-8">
            <div v-if="replicatingSession" class="alert alert-info">
              <p>
                <strong>Replicating {{ replicateSession.name }}</strong>. Before saving, make sure you take a close look at:
              </p>
              <ul>
                <li>Name</li>
                <li>Dates</li>
                <li><strong>Installment plans,</strong> which will likely have incorrect billing dates and need to be updated.</li>
                <li>If you are replicating a camp/one time session, and you
                  <a class="text-underline" href="https://help.wrestlingiq.com/article/67-customize-questions-for-a-specific-one-time-event" target="_blank">
                    customized the registration questions</a>, you will need to edit the questions after creating this new session.</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="session_name">Name</label>
              <input id="session_name" v-model="name" type="text" class="form-control">
            </div>
            <div class="form-group">
              <label class="m-r-sm">
                Type of event
              </label>
              <select v-model="sessionType" class="form-control" :disabled="existingSessionId">
                <option selected disabled>Choose session type...</option>
                <option value="full">Full Season</option>
                <option value="guest">Camp / Clinic</option>
                <option value="dropin">Drop in</option>
                <option value="recurring">Recurring Subscription</option>
                <option value="trial">Trial</option>
                <option value="anonymous">Other</option>
              </select>
            </div>
            <div class="form-group" v-show="notAnonymous">
              <label class="m-r-sm">
                Who can participate in this session?
                <VDropdown theme="info-tooltip">
                  <!-- This will be the popover target (for the events and position) -->
                  <i class="fa fa-question"></i>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div>
                      <p class="header">
                        What happens if I choose 'Adult'?
                      </p>
                      <p>
                        Adult sessions streamline the registration experience for sessions where registrants are all 18+.
                      </p>
                      <p>
                        For most clubs, 'kids' is the correct option, which ensures registration is done by the guardian
                        on behalf of the child.
                      </p>
                    </div>
                  </template>
                </VDropdown>
              </label>
              <select v-model="participants" class="form-control">
                <option value="kids">Kids</option>
                <option value="adults">Adults</option>
              </select>
            </div>
            <div class="form-group" v-show="!recurring && !dropin && !trial">
              <label>Dates</label>
              <date-pair-picker :initial-start="start"
                                :initial-end="end"
                                @dateRangeChanged="dateRangeChanged"></date-pair-picker>
            </div>
          </div>
        </div>
        <div class="row m-t-xl">
          <template v-if="recurring">
            <div class="col-md-4">
              <h1 class="form-section-title">Subscription Prices</h1>
            </div>
            <div class="col-md-8">
              <div class="-m-t-xs">
                <div v-for="(price) in subscriptionPrices" class="m-t-xs">
                  <subscription-price-form :current-billing-partner="currentBillingPartner" :price="price" v-if="!price.is_wiq_fee && !price.is_tax_item && !price.is_one_time_fee"></subscription-price-form>
                </div>
              </div>
              <div class="card-bordered-dashed m-t-xs" v-show="showSaveButton">
                <div class="row">
                  <div class="col-xs-12">
                    <a @click.prevent="addNewSubPrice"><i class="fa fa-plus"></i> Subscription Price</a>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-md-4">
              <h1 class="form-section-title">Payment Options</h1>
            </div>
            <div class="col-md-8">
              <div v-if="sessionType" class="-m-t-xs">
                <div v-for="(po, index) in paymentOptions" class="m-t-xs">
                  <payment-option-form :payment-option="po" :allow-sib-discount="notAnonymous" :allow-class-pass="dropin || trial"></payment-option-form>
                </div>
                <div class="card-bordered-dashed m-t-xs" v-show="showSaveButton">
                  <div class="row">
                    <div class="col-xs-12">
                      <a @click.prevent="addNewOption"><i class="fa fa-plus"></i> Payment Option</a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                Pick a session type to configure your payment options
              </div>
            </div>
          </template>
        </div>
        <div class="row m-t-xl">
          <div class="col-md-4">
            <h1 class="form-section-title">Settings</h1>
          </div>
          <div class="col-md-8">
            <div class="form-group flex align-items-start">
              <input id="registration_open" type="checkbox" v-model="registrationOpen">
              <div class="m-l-sm">
                <label for="registration_open" class="m-b-none">
                  Is registration open?
                </label>
                <p class="text-gray-600">
                  Uncheck if you do not want registration open yet.
                </p>
              </div>
            </div>
            <div v-if="notAnonymous && !recurring && !dropin">
              <div class="form-group flex align-items-start" :class="{'m-b-none': hasCapacityLimit }">
                <input id="reg_limit" type="checkbox" v-model="hasCapacityLimit">
                <div class="m-l-sm">
                  <label for="reg_limit" class="m-b-none">
                    Close registration after a certain # of wrestlers?
                  </label>
                  <p class="text-gray-600" :class="{'m-b-xxs': hasCapacityLimit }">
                    Not recommended unless you have a strict capacity limit at your facility.
                  </p>
                </div>
              </div>
              <div v-if="hasCapacityLimit" class="m-b-md m-l-lg" style="max-width: 100px; margin-left: 28px">
                <input type="number" v-model.number="capacityLimit" class="form-control">
              </div>
            </div>
            <div v-if="campOrClinic || fullSeason" :class="insuranceAlertClass">
              <div class="alert alert-warning m-b-none" v-if="!billingPartnerAllowsInsurance">
                Offering insurance is only available to teams using WrestlingIQ Payments for billing. Please email support@wrestlingiq.com if you want to switch over to WrestlingIQ Payments.
              </div>
              <div class="alert alert-success m-b-none" v-else-if="highlightInsuranceValue">
                <strong>New in WrestlingIQ!</strong> Allow your registrants to purchase cancellation and gap medical insurance.
                <a href="https://help.wrestlingiq.com/article/198-offering-insurance-to-registrants-cancellation-and-gap-medical-coverage"
                   class="text-underline"
                   target="_blank">
                  Read more about insurance <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </div>
              <div class="form-group flex align-items-start" :class="insuranceBodyClass">
                <input id="show_insurance" type="checkbox" v-model="showInsurance" :disabled="!billingPartnerAllowsInsurance">
                <div class="m-l-sm">
                  <label for="show_insurance" class="m-b-none">
                    Allow customers to purchase insurance?
                  </label>
                  <p class="text-gray-600">
                    Leave checked to allow registrants to purchase cancellation and gap medical insurance. This helps protect you from unexpected refunds.
                    Read more about
                    <a href="https://help.wrestlingiq.com/article/198-offering-insurance-to-registrants-cancellation-and-gap-medical-coverage"
                       class="text-underline"
                       target="_blank">
                      our insurance offerings <i class="fa fa-external-link" aria-hidden="true"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group flex align-items-start" v-if="notAnonymous && !recurring && !dropin">
            <input id="multi_sign_up" type="checkbox" v-model="allowMultipleSignupsPerWrestler">
            <div class="m-l-sm">
              <label for="multi_sign_up" class="m-b-none">
                Allow wrestlers to sign up more than once
                <VDropdown theme="info-tooltip">
                  <!-- This will be the popover target (for the events and position) -->
                  <i class="fa fa-question"></i>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div>
                      <p class="header">
                        Why would a wrestler need to sign up twice?
                      </p>
                      <p>
                        If you are setting up a registration for something like a private lesson, you'll want to make sure
                        wrestlers can register/pay for this session more than once.
                      </p>
                      <p>
                        <b>In most cases this should remain unchecked.</b>
                      </p>
                    </div>
                  </template>
                </VDropdown>
              </label>
              <p class="text-gray-600">
                In most cases, this should remain unchecked.
              </p>
            </div>
          </div>
            <div class="form-group flex align-items-start" v-if="notAnonymous && !recurring && !dropin && !trial">
              <input id="global_sib" type="checkbox" v-model="excludeFromGSibDiscount">
              <div class="m-l-sm">
                <label for="global_sib" class="m-b-none">
                  Exclude from global sibling discounts
                  <VDropdown theme="info-tooltip">
                    <!-- This will be the popover target (for the events and position) -->
                    <i class="fa fa-question"></i>
                    <!-- This will be the content of the popover -->
                    <template v-slot:popper>
                      <div>
                        <p class="header">
                          What is global sibling discount?
                        </p>
                        <p>
                          Sibling discounts are given when a kid registering for
                          this session has a sibling who has registered for a different session that is happening at the
                          same time or if multiple siblings register for the same paid session.
                        </p>
                        <p>
                          In some situations you may not want sessions to count towards this calculation.
                          <b>In most cases this should remain unchecked.</b>
                        </p>
                      </div>
                    </template>
                  </VDropdown>
                </label>
                <p class="text-gray-600">
                  In most cases, this should remain unchecked.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-t-xl">
          <div class="col-md-4">
            <h1 class="form-section-title">Automations</h1>
          </div>
          <div class="col-md-8">
            <div class="form-group flex align-items-start" v-if="!existingSessionId && notAnonymous">
              <input id="create_roster" type="checkbox" v-model="createRoster">
              <div class="m-l-sm">
                <label for="create_roster" class="m-b-none">
                  Create a roster that tracks wrestlers registered?
                </label>
                <p class="text-gray-600">
                  Roster will automatically add wrestlers as they register, powered by
                  <a href="https://help.wrestlingiq.com/article/77-automatically-sync-a-roster-with-registrations-and-memberships"
                     class="text-underline"
                     target="_blank">
                    roster syncers. <i class="fa fa-external-link" aria-hidden="true"></i>
                  </a>
                </p>
              </div>
            </div>
            <div class="form-group flex align-items-start" v-if="notAnonymous">
              <input v-if="!existingSessionId" id="sync_more_rosters" type="checkbox" v-model="syncMoreRosters">
              <div :class="{ 'm-l-sm': !existingSessionId}">
                <label for="sync_more_rosters" class="m-b-none">
                  <span v-if="!existingSessionId">
                    Sync registrants to an existing roster
                  </span>
                  <span v-else>
                    Registrants are synced to these rosters &nbsp;
                  </span>
                  <VDropdown theme="info-tooltip">
                    <!-- This will be the popover target (for the events and position) -->
                    <i class="fa fa-question" v-if="!existingSessionId"></i>
                    <i class="fa fa-info-circle" v-else></i>
                    <!-- This will be the content of the popover -->
                    <template v-slot:popper>
                      <div>
                        <p class="header">
                          Why would I sync to multiple rosters?
                        </p>
                        <p>
                          Some teams like to maintain separate rosters per registration, as well as an annual roster (e.g. 'All Wrestlers 2024-2025').
                          Check this, box if you want to also sync registrants to an existing roster.
                        </p>
                      </div>
                    </template>
                  </VDropdown>
                </label>
                <p class="text-gray-600">
                  <span v-if="!existingSessionId">
                    In most cases, this will remain unchecked.
                  </span>
                </p>
                <div v-if="syncMoreRosters || existingSessionId">
                  <search
                      class="search-form"
                      response-key="rosters"
                      placeholder="Type the name of a roster to sync..."
                      :mutate-history="false"
                      :allow-add="false"
                      only-dismiss-model="Roster"
                      :small-input="true"
                      :display-all-on-empty="false"
                      readable-model-name="roster"
                      base-url="/api/v1/rosters">
                    <template v-slot:list="slotProps">
                      <div
                          class="border-top m-t-sm m-b-xs p-t-sm p-b-xs cursor-pointer flex justify-space-between align-items-center p-r-sm"
                          @click="toggleRoster(slotProps.result)">
                        <div>
                          {{ slotProps.result.name }}
                        </div>
                        <div class="border rounded-sm border-blue-200 border-solid font-small"
                             style="padding: 2px 6px;">
                          Sync
                        </div>
                      </div>
                    </template>
                  </search>
                  <div class="flex flex-wrap gap-x-xxs gap-y-xxs m-t-sm m-b-sm">
                    <button
                        @click="toggleRosterSyncer(rosterSyncer)"
                        class="btn btn-outline rounded-lg font-small"
                        :class="{ 'btn-success': !rosterSyncer._destroy, 'btn-danger': rosterSyncer._destroy }"
                        style="padding: 2px 6px"
                        v-for="rosterSyncer in additionalRosterSyncers">
                      {{ rosterSyncer.roster.name }}
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="m-r-sm m-b-none">
                Welcome email (optional, but recommended)
                <VDropdown theme="info-tooltip">
                  <!-- This will be the popover target (for the events and position) -->
                  <i class="fa fa-question"></i>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div>
                      <p class="header">
                        Automatically email registrants
                      </p>
                      <p>
                        If you fill this field out, the contents will be automatically emailed to the registrants once
                        they
                        successfully register & pay for your session.
                      </p>
                      <p>
                        <a href="https://help.wrestlingiq.com/article/59-automatically-send-an-email-upon-registration"
                           target="_blank">Read more&nbsp;&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i></a>
                      </p>
                    </div>
                  </template>
                </VDropdown>
              </label>
              <p class="text-gray-600">
                Use this automation to thank registrants and communicate important info, such as facility location.
              </p>
              <input id="welcome-editor" type="hidden" name="content">
              <trix-editor input="welcome-editor" class="trix-content"></trix-editor>
            </div>
            <div class="form-group" v-show="trial">
              <label class="m-r-sm m-b-none">
                Trial finished email (optional, but recommended)
                <VDropdown theme="info-tooltip">
                  <!-- This will be the popover target (for the events and position) -->
                  <i class="fa fa-question"></i>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div>
                      <p class="header">
                        Automatically email trial registrants after they have finished their trial.
                      </p>
                      <p>
                        If you fill this field out, the contents will be automatically emailed to the registrants after their final trial pass has been redeemed.
                        You must use WrestlingIQ's attendance tracking feature to redeem passes.
                      </p>
                      <p>
                        <a href="https://help.wrestlingiq.com/article/59-automatically-send-an-email-after-a-trial-has-been-completed"
                           target="_blank">Read more&nbsp;&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i></a>
                      </p>
                    </div>
                  </template>
                </VDropdown>
              </label>
              <p class="text-gray-600">
                Use this automation to ask how the trial went and provide information on how to register for your subscription or full season.
              </p>
              <input id="check-in-editor" type="hidden" name="content">
              <trix-editor input="check-in-editor" class="trix-content"></trix-editor>
            </div>
          </div>
        </div>
        <div class="row m-t-xl" v-if="!recurring && !anonymous">
          <div class="col-md-4">
            <h1 class="form-section-title m-b-xxs">Custom Upsell / Add-on</h1>
            <p class="text-gray-600">Optional</p>
          </div>
          <div class="col-md-8">
            <div class="form-group flex align-items-start">
              <input id="allow_upsell" type="checkbox" v-model="allowUpsell">
              <div class="m-l-sm">
                <label for="registration_open" class="m-b-none">
                  Offer an add-on product during registration (e.g. t-shirt, gear package, etc)?
                </label>
                <p class="text-gray-600">
                  <a href="javascript:void(0)" class="cursor-pointer text-underline" onclick="Beacon('article', '6643e10a8f1b9635c99609b5', { type: 'modal' })">
                    Here is a quick video overview.
                  </a>
                  Skip this if you aren't sure, you can always add this in later.
                </p>
              </div>
            </div>
            <div v-if="allowUpsell" class="m-l-md">
              <p v-if="!upsellProduct">No product selected yet.</p>
              <div v-if="upsellProduct" class="grid grid-cols-5 sm-grid-cols-1 md-grid-cols-2 gap-sm m-b-sm">
                <div>
                  <product-list-result :product="upsellProduct" :show-edit="false"></product-list-result>
                  <div @click="upsellProduct = null" class="m-t-xxs w-full inline-flex align-items-center gap-x-xs justify-center rounded-xxs font-small p-x-xs p-y-xxs border border-solid border-red-500 text-red-600 rounded-full cursor-pointer">
                    <i class="fa fa-times"></i><span>Remove product</span>
                  </div>
                </div>

              </div>
              <div v-if="!upsellProduct">
                <hr/>
                <label class="m-b-none">Product library. Tap to select the product you want to offer as an add-on for this registration.</label>
                <p class="">Not seeing anything? <a href="/products" target="_blank" class="text-underline">Create some products first. <i class="fa fa-external-link"></i></a></p>
                <filtered-list
                    ui="grid"
                    grid-classes="grid grid-cols-5 sm-grid-cols-1 md-grid-cols-2 gap-sm m-b-sm"
                    :headers="['Name', 'Category', 'Actions']"
                    :sorts="[
            {
              label: 'Name asc',
              value: 'name asc'
              },
            {
              label: 'Name desc',
              value: 'name desc'
              },
            ]" :filters="[
            {
              label: 'Type',
              attribute: 'product_type',
              type: 'primary',
              predicates:
                [
                  {
                    label: 'All Products',
                    user_editable: false,
                    default: true,
                    values: {
                      product_type_eq: 'good'
                      }
                    },
                  ]
              },
            {
              label: 'Name',
              predicateLabel: 'Filter by product name',
              attribute: 'name',
              type: 'secondary',
              predicates:
                [
                  {
                    id: 1,
                    label: 'Name contains',
                    type: 'TextContains',
                    user_editable: true,
                    operator: 'or',
                    placeholder: 'Product name',
                    values: {
                      name_cont_any: null,
                      }
                    },
                  ]
              }
            ]"
                    response-key="products"
                    object-type="Product"
                    base-url="/api/v1/products">
                  <template v-slot:row="row">
                    <div @click.prevent="upsellProduct = row.data">
                      <product-list-result :product="row.data" :show-edit="false"></product-list-result>
                    </div>
                  </template>
                </filtered-list>
              </div>
            </div>
          </div>
        </div>
        <div class="button-area text-right m-t-xl">
          <div v-show="showSaveButton">
            <div v-show="error">
              <small>
                <span class="text-danger">{{ errorMessage }}</span>
              </small>
            </div>
            <ladda-button @lbClicked="pingServer" el-class="btn-primary" :loading="loading">
              Save
            </ladda-button>
          </div>
          <div v-show="!showSaveButton">
            <small>Finish editing your pricing to save the session.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import PaymentOptionForm from './payment_option_form.vue';
import SubscriptionPriceForm from './subscription_price_form.vue';
import DatePairPicker from './date_pair_picker.vue';
import ProductListResult from './product_list_result.vue';
import FilteredList from './filtered_list.vue';
import { trixFileAttachmentMix } from './mixins/trix_file_attachment_mix';
import Search from '../../shared/search.vue';

export default {
  name: 'paid-session-form',
  components: {
    DatePairPicker,
    FilteredList,
    LaddaButton,
    PaymentOptionForm,
    ProductListResult,
    Search,
    Spinner,
    SubscriptionPriceForm,
  },
  mixins: [trixFileAttachmentMix],
  props: {
    teamId: {
      type: Number,
      required: true
    },
    existingSessionId: {
      type: Number,
      required: false,
    },
    membership: {
      type: Boolean,
      default: false
    },
    replicateSession: {
      type: Object,
      default() { return {}; },
    },
    accessKey: {
      type: String,
      required: false,
    },
    authToken: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
    currentBillingPartner: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      default: 'usd',
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support',

      paymentOptionsBeingEdited: 0,
      subOptionsBeingEdited: 0,

      loadingExisting: false,
      name: '',
      sessionId: null,
      registrationOpen: true,
      excludeFromGSibDiscount: false,
      allowMultipleSignupsPerWrestler: false,
      createRoster: true,
      syncMoreRosters: false,
      additionalRosterSyncers: [], // Array of roster syncers
      showInsurance: true,
      highlightInsuranceValue: false,
      participants: 'kids',
      sessionType: null,
      capacityLimit: 50,
      hasCapacityLimit: false,
      start: null,
      end: null,
      welcomeText: '',
      welcomeTrixEditor: null,
      checkInText: '',
      checkInTrixEditor: null,
      allowUpsell: false,
      upsellProduct: null,

      paymentOptions: [],
      subscriptionPrices: [],

      paymentOptionId: null, // TEMP
    };
  },
  computed: {
    insuranceAlertClass() {
      return {
        border: this.highlightInsuranceValue || !this.billingPartnerAllowsInsurance,
        'border-solid': this.highlightInsuranceValue || !this.billingPartnerAllowsInsurance,
        'border-gray-50': !this.billingPartnerAllowsInsurance,
        'border-green-50': this.highlightInsuranceValue,
        'm-b-sm': this.highlightInsuranceValue || !this.billingPartnerAllowsInsurance,
      }
    },
    insuranceBodyClass() {
      return {
        'bg-green-50': this.highlightInsuranceValue && this.billingPartnerAllowsInsurance,
        'bg-gray-50': !this.billingPartnerAllowsInsurance,
        'opacity-50': !this.billingPartnerAllowsInsurance,
        'p-t-sm': this.highlightInsuranceValue || !this.billingPartnerAllowsInsurance,
        'p-l-m': this.highlightInsuranceValue || !this.billingPartnerAllowsInsurance,
        'm-b-none': this.highlightInsuranceValue || !this.billingPartnerAllowsInsurance,
      }
    },
    billingPartnerAllowsInsurance() {
      return this.currentBillingPartner === 'justifi';
    },
    replicatingSession() {
      return !_.isEmpty(this.replicateSession)
    },
    capacityLimitParam() {
      // Some safeguards for situatinos where registration limits do not yet apply
      if (this.anonymous || this.recurring || this.dropin) {
        return null;
      }

      if (this.hasCapacityLimit && this.capacityLimit > 0) {
        return this.capacityLimit;
      }

      return null;
    },
    showSaveButton() {
      return this.paymentOptionsBeingEdited === 0 && this.subOptionsBeingEdited === 0;
    },
    anonymous() {
      return !this.notAnonymous;
    },
    notAnonymous() {
      return this.sessionType !== 'anonymous';
    },
    recurring() {
      return this.sessionType === 'recurring';
    },
    dropin() {
      return this.sessionType === 'dropin';
    },
    trial() {
      return this.sessionType === 'trial';
    },
    campOrClinic() {
      return this.sessionType === 'guest';
    },
    fullSeason() {
      return this.sessionType === 'full';
    },
    requireDates() {
      return !(this.dropin || this.recurring || this.trial);
    },
    sessionTypeSet() {
      return this.sessionType !== null;
    }
  },
  mounted() {
    const vm = this;

    if (this.membership) {
      this.sessionType = 'recurring';
    }

    if (!this.billingPartnerAllowsInsurance) {
      this.showInsurance = false;
    }

    if (vm.existingSessionId) {
      // Pull an existing session from the server if we are editing it
      vm.getExisting();
    } else if (this.replicatingSession) {
      // Use the replicate session if given
      vm.setExistingOrReplicateData(vm.replicateSession, true);
    }

    document.addEventListener('trix-initialize', vm.trixInit);
    // Registration session types
    vm.$on('editing-payment-option', vm.incrementEditing);
    vm.$on('editing-payment-option-cancelled', vm.handleCancelledOption);
    vm.$on('discard-payment-option-draft', vm.removeDraft);
    vm.$on('payment-option-updated', vm.handleUpdatedOption);

    // Membership types
    vm.$on('editing-subscription-option', vm.incrementSubEditing);
    vm.$on('editing-subscription-option-cancelled', vm.handleCancelledSubOption);
    vm.$on('discard-subscription-option-draft', vm.removeSubDraft);
    vm.$on('subscription-option-updated', vm.handleUpdatedSubOption);
  },
  destroyed() {
    const vm = this;
    document.removeEventListener('trix-initialize', vm.trixInit);

    // registration session types
    vm.$off('editing-payment-option', vm.incrementEditing);
    vm.$off('editing-payment-option-cancelled', vm.handleCancelledOption);
    vm.$off('discard-payment-option-draft', vm.removeDraft);
    vm.$off('payment-option-updated', vm.handleUpdatedOption);

    // membership types
    vm.$off('editing-subscription-option', vm.incrementSubEditing);
    vm.$off('editing-subscription-option-cancelled', vm.handleCancelledSubOption);
    vm.$off('discard-subscription-option-draft', vm.removeSubDraft);
    vm.$off('subscription-option-updated', vm.handleUpdatedSubOption);
  },

  methods: {
    toggleRosterSyncer(rosterSyncer) {
      // Roster syncers come in a payload of { id: roster_syncer.id, roster_id: roster.id } or a nested one to create a new roster
      const rosterIdx = _.findIndex(this.additionalRosterSyncers, { id: rosterSyncer.id });
      if (rosterIdx === -1) {
        rosterSyncer._destroy = false
        this.additionalRosterSyncers.push(rosterSyncer);
      } else {
        let syncer = this.additionalRosterSyncers[rosterIdx];
        if (syncer.id) {
          syncer._destroy = !syncer._destroy;
        } else {
          this.additionalRosterSyncers.splice(rosterIdx, 1);
        }
      }
      this.$notificationManager.$emit('profile-clicked', 'Roster');
    },
    toggleRoster(roster) {
      const rosterIdx = _.findIndex(this.additionalRosterSyncers, { roster_id: roster.id });
      if (rosterIdx === -1) {
        this.additionalRosterSyncers.push({
          id: null,
          _destroy: false,
          roster_id: roster.id,
          roster: roster,
        });
      } else {
        let syncer = this.additionalRosterSyncers[rosterIdx];
        if (syncer.id) {
          syncer._destroy = !syncer._destroy;
        } else {
          this.additionalRosterSyncers.splice(rosterIdx, 1);
        }
      }
      this.$notificationManager.$emit('profile-clicked', 'Roster');
    },
    trixInit(event) {
      let vm = this;
      if (event.target.inputElement.id === 'check-in-editor') {
        vm.checkInTrixEditor = event.target.editor;
        vm.loadTrixContent(vm.checkInTrixEditor, vm.checkInText);
      } else if (event.target.inputElement.id === 'welcome-editor') {
        vm.welcomeTextTrixEditor = event.target.editor;
        vm.loadTrixContent(vm.welcomeTextTrixEditor, vm.welcomeText);
      }
    },
    incrementEditing() {
      this.paymentOptionsBeingEdited += 1;
    },
    decrementEditing() {
      this.paymentOptionsBeingEdited -= 1;
    },
    handleCancelledOption(newOptionId, shouldRemove) {
      if (newOptionId && shouldRemove) {
        const newOptionIdx = _.findIndex(this.paymentOptions, { newOptionId });
        this.paymentOptions.splice(newOptionIdx, 1);
      }
      this.decrementEditing();
    },
    removeDraft(newOptionId) {
      const newOptionIdx = _.findIndex(this.paymentOptions, { newOptionId });
      this.paymentOptions.splice(newOptionIdx, 1);
    },
    handleUpdatedOption(option) {
      const vm = this;
      if (option.id) {
        // this option already exists so replace it in paymentOptions
        const existingOptionIdx = _.findIndex(vm.paymentOptions, { id: option.id });
        vm.paymentOptions.splice(existingOptionIdx, 1, option);
      } else {
        // option does not already exist, find it's draft option or push
        const newOptionIdx = _.findIndex(vm.paymentOptions, { newOptionId: option.newOptionId });
        vm.paymentOptions.splice(newOptionIdx, 1, option);
      }

      this.decrementEditing();
    },
    addNewOption() {
      // ok so this needs to throw down a new payment option form
      // and open it for editing
      const newOptionId = this.paymentOptions.length + 1;
      const newPaymentOption = {
        draft: true,
        id: null,
        newOptionId,
        name: '',
        price: 0,
        sibling_discount_enabled: false,
        class_pass_quantity: null,
        discount_per_extra_wrestler: 0,
        sibling_count_for_discount: 1,
        allowed_payment_methods: 'card',
      };
      this.paymentOptions.push(newPaymentOption);
    },
    incrementSubEditing() {
      this.subOptionsBeingEdited += 1;
    },
    decrementSubEditing() {
      this.subOptionsBeingEdited -= 1;
    },
    handleCancelledSubOption(newOptionId, shouldRemove) {
      if (newOptionId && shouldRemove) {
        const newOptionIdx = _.findIndex(this.subscriptionPrices, { newOptionId });
        this.subscriptionPrices.splice(newOptionIdx, 1);
      }
      this.decrementSubEditing();
    },
    removeSubDraft(newOptionId) {
      const newOptionIdx = _.findIndex(this.subscriptionPrices, { newOptionId });
      this.subscriptionPrices.splice(newOptionIdx, 1);
    },
    handleUpdatedSubOption(option) {
      const vm = this;
      if (option.id) {
        // this option already exists so replace it in paymentOptions
        const existingOptionIdx = _.findIndex(vm.subscriptionPrices, { id: option.id });
        vm.subscriptionPrices.splice(existingOptionIdx, 1, option);
      } else {
        // option does not already exist, find it's draft option or push
        const newOptionIdx = _.findIndex(vm.subscriptionPrices, { newOptionId: option.newOptionId });
        vm.subscriptionPrices.splice(newOptionIdx, 1, option);
      }

      this.decrementSubEditing();
    },
    addNewSubPrice() {
      // Throw down a new sub price, open it for editing
      const newOptionId = this.subscriptionPrices.length + 1;
      const newSubPrice = {
        draft: true,
        id: null,
        newOptionId,
        name: '',
        amount: 0,
        currency: this.currency,
        interval: 'month',
        interval_count: 1,
        intervals_to_bill: null,
        sibling_coupon_id: null,
        third_sibling_coupon_id: null,
        one_time_fee_amount: 0,
      };
      this.subscriptionPrices.push(newSubPrice);
    },
    loadTrixContent(trixEditor, html) {
      console.log('trix init');
      if (trixEditor && html) {
        trixEditor.loadHTML(html);
      }
    },
    // Useful for setting everything - except the session id - in different contexts.
    setExistingOrReplicateData(data, isReplicating) {
      this.name = data.name;
      this.registrationOpen = data.registration_open;
      this.showInsurance = data.show_insurance;
      this.highlightInsuranceValue = data.show_insurance === false && moment(data.created_at).isBefore('2024-03-06');
      this.excludeFromGSibDiscount = data.exclude_from_g_sib_discount;
      this.allowMultipleSignupsPerWrestler = data.allow_multiple_signups_per_wrestler;
      this.sessionType = data.session_type;
      this.capacityLimit = data.capacity_limit;
      // If we receive null, we need to reset this so the number model works still (it does not accept null without throwing a front end error)
      // We rely on the hasCapacityLimit flag to properly handle not sending this if it isn't checked, no matter what this number is
      if (this.capacityLimit == null) {
        this.capacityLimit = 50;
      } else {
        // If it's not null, we do have a limit so make sure that is set here!
        this.hasCapacityLimit = true;
      }

      this.upsellProduct = data.upsell_product;
      this.allowUpsell = this.upsellProduct !== null;
      this.participants = data.participants;
      this.welcomeText = data.welcome_text;
      this.checkInText = data.check_in_text;
      this.start = moment(data.start_at);
      // Subtracting one day for display purposes, this gets added back in before posting to the server
      this.end = moment(data.end_at);

      const paymentOptions = _.get(data, 'payment_options', []);
      paymentOptions.forEach((po) => {
        let option = {
          draft: isReplicating,
          name: po.name,
          price: po.price,
          class_pass_quantity: po.class_pass_quantity,
          payment_strategy: po.payment_strategy,
          installment_plan: po.installment_plan,
          sibling_discount_enabled: po.sibling_discount_enabled,
          discount_per_extra_wrestler: po.discount_per_extra_wrestler,
          sibling_count_for_discount: po.sibling_count_for_discount,
          allowed_payment_methods: po.allowed_payment_methods,
        };
        if (isReplicating) {
          // If this is a replication situation these all need a temp id
          option.newOptionId = this.paymentOptions.length + 1;
        } else {
          // If we are editing an existing one, make sure the id is set so we don't spin out duplicate options/
          option.id = po.id
        }

        this.paymentOptions.push(option);
      });

      // problem here is that I can't distinguish between the original roster syncer and additional sessions at all.
      // in other words in a replication, I want to skip the original roster syncer that was created.
      // This is held together by duct tape, hope, and probability. We sync roster names and message groups when a paid session is updated, as long as the user hasn't messed with it.
      // So a best guess here is to remove the syncer with the same name that we are replicating.

      const syncers = _.get(data, 'roster_syncers', []);
      syncers.forEach((syncer) => {
        // In a replication case, we want to attempt to not sync with the standalone roster for that session and only sync with additional stuff they added.
        if (isReplicating && syncer.roster.name === this.name) {
          return;
        }
        // In a replicate case we always nil out the syncer id, but copy the roster id in
        this.additionalRosterSyncers.push({
          id: isReplicating ? null : syncer.id,
          _destroy: false,
          roster_id: syncer.roster_id,
          roster: syncer.roster,
        });
      });

      // Check that box by default in a replicate case if we have any roster syncers beyond the first
      if (this.additionalRosterSyncers.length > 0) {
        this.syncMoreRosters = true;
      }
    },
    getExisting() {
      const vm = this;
      vm.loadingExisting = true;
      const url = vm.$apiService.paidSessionUrl(vm.existingSessionId);
      axios.get(url)
          .then((response) => {
            const { data } = response;
            vm.sessionId = data.id;
            vm.setExistingOrReplicateData(data, false);

            // subscription options... these are not replicatable so it's not in that DRYed up method
            const priceOptions = _.get(data, 'product.billing_plans', []);
            priceOptions.forEach((price) => {
              const pr = {
                draft: false,
                id: price.id,
                name: price.name,
                amount: price.amount,
                interval: price.interval,
                interval_count: price.interval_count,
                intervals_to_bill: price.intervals_to_bill,
                coupon_strategy: price.coupon_strategy,
                sibling_coupon_id: price.sibling_coupon?.id,
                third_sibling_coupon_id: price.third_sibling_coupon?.id,
                active: price.active,
                associated_fee_id: price.associated_fee_id, // wiq fees
                associated_tax_id: price.associated_tax_id,
                associated_one_time_fee_id: price.associated_one_time_fee_id, // user set prices (enrollment fees)
                one_time_fee_amount: price.one_time_fee_amount,
                is_wiq_fee: price.is_wiq_fee,
                is_tax_item: price.is_tax_item,
                is_one_time_fee: price.is_one_time_fee,
              };
              vm.subscriptionPrices.push(pr);
            });

            vm.loadingExisting = false;
            vm.loadTrixContent(vm.welcomeTextTrixEditor, vm.welcomeText);
            vm.loadTrixContent(vm.checkInTrixEditor, vm.checkInText);
          })
          .catch((error) => {
            console.log(error);
            vm.errorHandler(error);
          });
    },
    successHandler(name) {
      const vm = this;
      vm.loading = false;
      vm.$notificationManager.$emit('show-toast', `${name} saved`, true);
      window.location.assign('/paid_sessions');
    },
    errorHandler() {
      const vm = this;
      vm.loading = false;
      vm.errorMessage = 'Error, please try again later or contact support.';
      vm.error = true;
    },
    validate() {
      const vm = this;

      let errorMessage = 'Please ensure ';
      let error = false;

      if (!vm.name) {
        errorMessage += 'name, ';
        error = true;
      }
      if (vm.start === null && vm.requireDates) {
        errorMessage += 'start, ';
        error = true;
      }
      if (vm.end === null && vm.requireDates) {
        errorMessage += 'end, ';
        error = true;
      }

      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        return false;
      }

      if (!vm.recurring) {
        if (_.size(vm.paymentOptions) === 0) {
          vm.errorMessage = 'Paid sessions must have at least one payment option.';
          vm.error = true;
          return false;
        }
      } else if (_.filter(vm.subscriptionPrices, ['active', true]).length === 0) {
        vm.errorMessage = 'Membership options must have at least one active subscription price.';
        vm.error = true;
        return false;
      }

      return true;
    },
    getPlainTrixText(trixEditor) {
      if (trixEditor) {
        return trixEditor.getDocument()
            .toString();
      }
      return '';
    },
    checkIfFinished() {
      const vm = this;
      // some checks if any still have a draft status
      if (!_.some(vm.paymentOptions, ['draft', true])) {
        // All have finished
        vm.successHandler(vm.name);
      }
    },
    // used for creating and updating payment options
    getPaymentOptionJson(po, paid_session_id) {
      return {
        payment_option: {
          paid_session_id,
          name: po.name,
          price: po.price,
          class_pass_quantity: po.class_pass_quantity,
          payment_strategy: po.payment_strategy,
          installment_plan: po.installment_plan,
          sibling_discount_enabled: po.sibling_discount_enabled,
          discount_per_extra_wrestler: po.discount_per_extra_wrestler,
          sibling_count_for_discount: po.sibling_count_for_discount,
          allowed_payment_methods: po.allowed_payment_methods,
        },
      };
    },
    findOrCreatePaymentOption(po, paid_session_id) {
      const vm = this;
      if (!po.draft) {
        // Skip non draft payment options
        return;
      }

      const params = vm.getPaymentOptionJson(po, paid_session_id);
      if (po.id) {
        // PUT
        const url = vm.$apiService.paymentOptionUrl(po.id);
        axios.put(url, params)
            .then((response) => {
              po.draft = false;
              vm.checkIfFinished();
            })
            .catch((error) => {
              vm.errorHandler(error);
            });
      } else {
        // POST
        const url = vm.$apiService.paymentOptionsUrl();
        axios.post(url, params)
            .then((response) => {
              po.draft = false;
              vm.checkIfFinished();
            })
            .catch((error) => {
              vm.errorHandler(error);
            });
      }
    },
    savePaymentOptions(paid_session_id) {
      const vm = this;
      if (_.some(vm.paymentOptions, ['draft', true])) {
        // If we have draft payment options, save them
        vm.paymentOptions.forEach((po) => {
          vm.findOrCreatePaymentOption(po, paid_session_id);
        });
      } else {
        // else just fire the success handler as we are all saved up
        vm.successHandler(vm.name);
      }
    },
    // used for creating and updating payment options
    getSubPriceJson(price) {
      return {
        billing_plan: {
          name: price.name,
          amount: price.amount,
          currency: price.currency,
          interval: price.interval,
          interval_count: price.interval_count,
          intervals_to_bill: price.intervals_to_bill,
          sibling_coupon_id: price.sibling_coupon_id,
          third_sibling_coupon_id: price.third_sibling_coupon_id,
          one_time_fee_amount: price.one_time_fee_amount,
          usage_type: 'licensed',
          active: price.active,
        },
      };
    },
    checkIfPricesFinished() {
      const vm = this;
      // some checks if any still have a draft status
      if (!_.some(vm.subscriptionPrices, ['draft', true])) {
        // All have finished
        vm.successHandler(vm.name);
      }
    },
    updateOrCreateSubscriptionPrice(price, paid_session_id) {
      const vm = this;
      if (!price.draft) {
        // Skip non draft payment options
        return;
      }

      const params = vm.getSubPriceJson(price);
      if (price.id) {
        // PUT
        const url = vm.$apiService.billingPlanUrl(price.id);
        axios.put(url, params)
            .then((response) => {
              price.draft = false;
              vm.checkIfPricesFinished();
            })
            .catch((error) => {
              vm.errorHandler(error);
            });
      } else {
        // POST
        const url = vm.$apiService.paidSessionBillingPlansUrl(paid_session_id);
        axios.post(url, params)
            .then((response) => {
              price.draft = false;
              vm.checkIfPricesFinished();
            })
            .catch((error) => {
              vm.errorHandler(error);
            });
      }
    },
    savePrices(paid_session_id) {
      const vm = this;
      if (_.some(vm.subscriptionPrices, ['draft', true])) {
        // If we have draft payment options, save them
        vm.subscriptionPrices.forEach((price) => {
          vm.updateOrCreateSubscriptionPrice(price, paid_session_id);
        });
      } else {
        // else just fire the success handler as we are all saved up
        vm.successHandler(vm.name);
      }
    },
    pingServer: _.throttle(function () {
      const vm = this;
      if (vm.loading === true) {
        return;
      }

      if (vm.validate()) {
        vm.loading = true;

        const welcome = $('#welcome-editor').val();
        const plainWelcome = vm.getPlainTrixText(vm.welcomeTextTrixEditor);
        const checkIn = $('#check-in-editor').val();
        const plainCheckIn = vm.getPlainTrixText(vm.checkInTrixEditor);

        const params = {
          paid_session: {
            name: vm.name,
            start_at: vm.start ? vm.start.toISOString() : null,
            end_at: vm.end ? vm.end.toISOString() : null,
            registration_open: vm.registrationOpen,
            // We only allow insurance on these specific types. This is a bit of a hack to avoid a larger refactor
            show_insurance: ((vm.campOrClinic || vm.fullSeason) && vm.billingPartnerAllowsInsurance) ? vm.showInsurance : false,
            exclude_from_g_sib_discount: (vm.dropin || vm.trial) ? true : vm.excludeFromGSibDiscount,
            allow_multiple_signups_per_wrestler: vm.allowMultipleSignupsPerWrestler,
            session_type: vm.sessionType,
            capacity_limit: vm.capacityLimitParam,
            participants: vm.participants,
            team_id: vm.teamId,
            welcome_text: welcome,
            plain_welcome_text: plainWelcome,
            check_in_text: checkIn,
            plain_check_in_text: plainCheckIn,
            upsell_product_id: vm.allowUpsell && vm.upsellProduct ? vm.upsellProduct.id : null,
            roster_syncers_attributes: [],
          },
        };

        if (vm.notAnonymous) {
          vm.additionalRosterSyncers.forEach((rosterSyncer) => {
            params.paid_session.roster_syncers_attributes.push(
                {
                  id: rosterSyncer.id,
                  _destroy: rosterSyncer._destroy,
                  roster_id: rosterSyncer.roster_id,
                }
            );
          });
        }

        vm.loading = true;

        if (vm.sessionId) {
          // PUT
          const url = vm.$apiService.paidSessionUrl(vm.existingSessionId);
          axios.put(url, params)
              .then((response) => {
                if (vm.recurring) {
                  vm.savePrices(vm.existingSessionId);
                } else {
                  vm.savePaymentOptions(vm.existingSessionId);
                }
              })
              .catch((error) => {
                vm.errorHandler(error);
              });
        } else {
          // POST
          // Make sure we set nested roster attributes first if a roster is requested
          if (vm.createRoster && vm.notAnonymous) {
            params.paid_session.roster_syncers_attributes.push(
              {
                roster_attributes: {
                  name: vm.name,
                  team_id: vm.teamId,
                }
              }
            );
          }

          const url = vm.$apiService.paidSessionsUrl();
          axios.post(url, params)
              .then((response) => {
                if (vm.recurring) {
                  vm.savePrices(response.data.id);
                } else {
                  vm.savePaymentOptions(response.data.id);
                }
              })
              .catch((error) => {
                vm.errorHandler(error);
              });
        }
      }
    }, 500),
    dateRangeChanged(start, end) {
      this.start = start;
      this.end = end;
    },
  },
};
</script>
