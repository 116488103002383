<template>
  <div class="archive-form should-dim modify-form m-t-sm">
    <div>
      Are you sure you want to cancel this registration?
    </div>
    <ul class="mt-xs">
      <li>
        All future invoices will be voided
      </li>
      <li>
        Registration will be marked as canceled
      </li>
      <li>
        Refunds will need to be issued separately
      </li>
    </ul>
    <div class="">
      <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
      <br/>
      <button v-on:click="hideCancelForm" class="btn btn-default">Cancel</button>
      <ladda-button @lbClicked="cancelPlan" el-class="btn-danger" :loading="loading">
        Yes, cancel registration
      </ladda-button>
    </div>
  </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'cancel-payment-plan',
  components: { LaddaButton },
  props: [
    'plan',
  ],
  data() {
    return {
      loading: false, // Loading is a network variable (hitting the api)

      error: false,
      errorMessage: 'Error, please try again later or contact support@wrestlingiq.com',
    };
  },
  methods: {
    hideCancelForm() {
      const vm = this;
      vm.$notificationManager.$emit('hide-plan-cancel-form', vm.plan);
    },
    cancelPlan: _.throttle(function () {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;

      const url = vm.$apiService.paymentPlanUrl(vm.plan.id);
      axios.delete(url)
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', 'Registration canceled', true);
            vm.$notificationManager.$emit('payment-plan-canceled', vm.plan);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support@wrestlingiq.com';
            vm.error = true;
          });
    }, 500),
  },
};
</script>
