<template>
    <div class='search-result'>
        <div class='hr-line-dashed'></div>
        <h3>
            <template v-if="isSelectable">
                <span class="selectable">{{ getName() }}</span>
            </template>
            <template v-else>
                <a :href="getHref()">{{ getName() }}</a>
            </template>
        </h3>
        <p class='search-type-label'>
            {{ result.readable_type }}
        </p>
    </div>
</template>

<script>
export default {
  name: 'generic-search-result',
  props: [
    'result',
    'isSelectable',
    'overrideKey',
  ],
  methods: {
    getName() {
      const vm = this;
      if (vm.overrideKey) {
        return vm.result[vm.overrideKey];
      }

      return vm.result.name;
    },
    getHref() {
      const vm = this;
      return vm.result.href;
    },
  },
};
</script>
