<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="ibox">
        <div class="ibox-title">
          <div class="flex flex-wrap justify-space-between">
            <div>
              <h1 v-if="editing">Edit {{ name }} roster</h1>
              <h1 v-else>Create a roster</h1>
            </div>
            <div>
              <ladda-button
                  @lbClicked="saveRoster"
                  el-class="btn-primary"
                  :loading="loading"
              >
                Save
              </ladda-button>
              <div>
                <small v-show="error"
                ><span class="text-danger">{{ errorMessage }}</span></small
                >
              </div>
            </div>
          </div>
        </div>
        <div class="ibox-content">
          <div v-if="loadingProfiles">
            <spinner></spinner>
          </div>
          <div v-if="!loadingProfiles">
            <div class="row m-t-sm">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="roster_name">Name</label>
                  <small>e.g. Varsity, J.V., etc</small>
                  <input
                    v-model="name"
                    type="text"
                    id="roster_name"
                    class="form-control"
                    maxlength="200"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-6 m-t-only-xs-m">
                <div>
                  <div>
                    <label>Tags</label>
                    <VDropdown theme="info-tooltip">
                      <!-- This will be the popover target (for the events and position) -->
                      <i class="fa fa-question"></i>
                      <!-- This will be the content of the popover -->
                      <template v-slot:popper>
                        <div>
                          <p class="header">What are roster tags for?</p>
                          <p>
                            When a wrestler is part of a roster with tags,
                            WrestlingIQ will show a small colored dot, along
                            with the tag abbreviation, next to their name.
                          </p>
                          <p>
                            You could use this for putting a green dot next to
                            your 'Active Members' roster. Or for non-profit
                            clubs, to track youth development effort and impact.
                          </p>
                        </div>
                      </template>
                    </VDropdown>
                  </div>
                  <div class="grid grid-cols-2">
                    <div>
                      <p>Tags added</p>
                      <div class="flex flex-wrap gap-x-xs items-center">
                        <span
                          v-for="tagging in notDestroyedTaggings"
                          @click="tagSyncClicked(tagging.tag)"
                          class="inline-flex align-items-center gap-x-xxs rounded-xxs font-small p-x-xs p-y-xxs"
                          :style="{
                            color: tagging.tag.text_color,
                            'background-color': tagging.tag.color,
                          }"
                        >
                          {{ tagging.tag.abbreviation }}
                          <i class="fa fa-times"></i>
                        </span>
                      </div>
                    </div>
                    <div>
                      <p>Tags available</p>
                      <div class="flex flex-wrap gap-x-xs items-center">
                        <span
                          v-for="tag in notAddedTags"
                          @click="tagSyncClicked(tag)"
                          class="inline-flex align-items-center gap-x-xxs rounded-xxs font-small p-x-xs p-y-xxs cursor-pointer"
                          :style="{
                            color: tag.text_color,
                            'background-color': tag.color,
                          }"
                        >
                          {{ tag.abbreviation }}
                        </span>
                      </div>
                      <button
                              class="btn btn-default m-t-sm"
                              @click="showAddTagForm = true"
                              v-if="!showAddTagForm"
                      >
                          Create a new tag
                      </button>
                    </div>
                  </div>
                  <div class="m-t-sm">
                    <add-tag-form
                        v-if="showAddTagForm"
                        @hide="showAddTagForm = false"
                    ></add-tag-form>
                  </div>
                </div>
                <div v-if="showSyncers && !showReSyncForm" class="m-t-lg">
                  <div class="form-group">
                    <div class="flex justify-space-between align-items-baseline">
                      <div>
                        <label>Sync roster with registrations?</label>
                        <VDropdown theme="info-tooltip">
                          <!-- This will be the popover target (for the events and position) -->
                          <i class="fa fa-question"></i>
                          <!-- This will be the content of the popover -->
                          <template v-slot:popper>
                            <div>
                              <p class="header">
                                What does syncing a roster with a session do?
                              </p>
                              <p>
                                For registrations with a single payment, wrestlers
                                will get added to this roster as soon as their
                                registration has been paid for.
                              </p>
                              <p>
                                For recurring memberships, or installments,
                                wrestlers will get added to this roster when their
                                membership is in good standing and removed from it
                                when their membership is cancelled, or payment
                                fails.
                              </p>
                              <p>
                                For drop ins, wrestlers will get added when they
                                receive > 0 passes and are removed when they no
                                longer have passes.
                              </p>
                            </div>
                          </template>
                        </VDropdown>
                      </div>
                      <div v-if="editing">
                        <a @click.prevent="showReSyncForm = true" class="btn btn-default btn-compact flex gap-x-xs justify-center align-items-center">
                          <i class="fa fa-refresh"></i>
                          Resync
                        </a>
                      </div>
                    </div>
                    <div class="m-t-sm">
                      <search
                          class="search-form"
                          response-key="paid_sessions"
                          placeholder="Type name of paid session..."
                          :mutate-history="false"
                          :allow-add="false"
                          :small-input="true"
                          :display-all-on-empty="false"
                          readable-model-name="session"
                          base-url="/api/v1/paid_sessions?type=not_archived"
                      >
                        <template v-slot:list="slotProps">
                          <div
                              class="border-top m-t-sm m-b-xs p-t-sm p-b-xs cursor-pointer flex justify-space-between align-items-center p-r-sm"
                              @click="sessionSyncClicked(slotProps.result)"
                          >
                            <div>
                              {{ slotProps.result.name }}
                            </div>
                            <div
                                class="border rounded-sm border-blue-200 border-solid font-small"
                                style="padding: 2px 6px"
                            >
                              Sync
                            </div>
                          </div>
                        </template>
                      </search>
                      <div class="flex flex-wrap gap-x-xxs gap-y-xxs m-t-sm">
                        <button
                            @click="sessionSyncClicked(sync.paid_session)"
                            class="btn btn-success btn-outline rounded-lg font-small"
                            style="padding: 2px 6px"
                            v-for="sync in notDestroyedSyncers"
                        >
                          {{ sync.paid_session.name }}
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                      <div
                          class="m-t-xs"
                          v-if="
                        notDestroyedSyncers.length > 0 ||
                        destroyedSyncers.length > 0
                      "
                      >
                      <span v-if="notDestroyedSyncers.length > 0">
                        <strong>Note:</strong> syncing of wrestlers based on
                        registrations does not happen until after you press
                        save.<br />
                      </span>
                        <span v-if="destroyedSyncers.length > 0">
                        <strong>Note:</strong> When removing a registration, the wrestlers part of that registration will be removed unless they are part of another synced registration.<br />
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showReSyncForm" class="m-t-lg">
                  <h3>Are you sure you want to resync?</h3>
                  <p class="text-heavy">
                    Resyncing will remove all wrestlers from this roster, then re-add the wrestlers who have good standing registrations that are synced to this roster.
                  </p>
                  <p class="text-heavy">
                    If you have manually added wrestlers to this roster, they will be removed.
                  </p>
                  <div class="flex gap-x-xs">
                    <a class="btn btn-default" @click.prevent="showReSyncForm = false">
                      Cancel
                    </a>
                    <ladda-button @lbClicked="triggerSync" el-class="btn-primary" :loading="reSyncingRoster">
                      Yes, re-sync roster
                    </ladda-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-t-md">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Wrestlers</label>
                  <div class="" style="position: relative">
                    <input
                      v-model="search"
                      type="text"
                      id="wrestler_search"
                      placeholder="Find by name or weight.."
                      class="form-control"
                      maxlength="25"
                    />
                    <i
                      @click="search = ''"
                      v-if="search && search.length > 0"
                      class="cursor-pointer fa fa-times color-gray-400"
                      style="
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        transform: translate(-66%, 29%);
                      "
                    ></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6" v-if="showFilters">
                <p style="margin-bottom: 5px; font-weight: bold">Filters</p>
                <div class="form-group">
                  <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      id="gues_checkbox"
                      v-model="showGuests"
                    />
                    Show guest wrestlers
                  </label>
                </div>
              </div>
              <div class="col-sm-12 m-t-m">
                <div
                  v-for="wp in searchedProfiles"
                  class="profile-wrapper col-sm-4"
                >
                  <div class="d-inline-block">
                    <input
                      type="checkbox"
                      v-model="rosterMemberships[wp.id].checked"
                    />
                  </div>
                  <div class="d-inline-block m-l-xs">
                    <div class="search-result">
                      <div class="hr-line-dashed"></div>
                      <h3>
                        {{ wp.full_name }}
                        <small class="roster-weight">
                          {{ wp.weight_class }}
                        </small>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-t-lg">
              <div class="col-xs-6">
                <a
                  class="text-danger"
                  @click.prevent="confirmDestroy"
                  v-show="existingRoster"
                  >Delete roster...</a
                >
              </div>
              <div class="col-xs-6 text-right">
                <ladda-button
                  @lbClicked="saveRoster"
                  el-class="btn-primary"
                  :loading="loading"
                >
                  Save
                </ladda-button>
                <div>
                  <small v-show="error"
                    ><span class="text-danger">{{ errorMessage }}</span></small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Search from "../../shared/search.vue";
import GenericSearchResult from "./generic-search-result.vue";
import Spinner from "./spinner.vue";
import FullLoadList from "./full_load_list.vue";
import AddTagForm from "./add_tag_form.vue";
import LaddaButton from "../../shared/ladda_button.vue";
import swal from "sweetalert2";

export default {
  name: "roster-form",
  components: {
    LaddaButton,
    FullLoadList,
    Spinner,
    GenericSearchResult,
    Search,
    AddTagForm,
  },
  props: {
    existingRoster: {
      type: Object,
      required: false,
    },
    showSyncers: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wrestlers: [],
      rosterMemberships: {},
      rosterSyncers: {},
      taggings: {},
      // Tags are all possible tags
      tags: [],
      loading: false,
      loadingProfiles: true,
      error: false,
      errorMessage: "",
      name: "",
      search: "",
      editing: false,
      showGuests: false,
      showAddTagForm: false,
      showReSyncForm: false,
      reSyncingRoster: false,
    };
  },
  watch: {
    // whenever showGuests changes, this function will run
    showGuests() {
      this.loadProfiles();
    },
  },
  computed: {
    searchedProfiles() {
      if (!this.search || this.search === "") {
        return this.wrestlers;
      }

      if (isNaN(this.search)) {
        return this.wrestlers.filter((wp) =>
          wp.full_name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.wrestlers.filter((wp) =>
          wp.weight_class.includes(this.search)
        );
      }
    },
    notDestroyedSyncers() {
      return _.flatMap(this.rosterSyncers).filter((rs) => !rs._destroy);
    },
    destroyedSyncers() {
      return _.flatMap(this.rosterSyncers).filter((rs) => rs._destroy);
    },
    // Taggings added or to be added
    notDestroyedTaggings() {
      return _.flatMap(this.taggings).filter((rs) => !rs._destroy);
    },
    // Taggings to be removed
    destroyedTaggings() {
      return _.flatMap(this.taggings).filter((rs) => rs._destroy);
    },
    // Tags to show to the user
    notAddedTags() {
      return _.filter(this.tags, (t) => {
        return this.taggings[t.id] == null;
      });
    },
  },
  created() {
    this.$notificationManager.$on("tag-created", this.tagCreated);
    if (this.existingRoster) {
      this.name = this.existingRoster.name;
      this.editing = true;
      this.rosterSyncers = _.keyBy(
        _.map(this.existingRoster.roster_syncers, (rs) => {
          rs._destroy = false;
          return rs;
        }),
        "paid_session.id"
      );
      this.taggings = _.keyBy(
        _.map(this.existingRoster.taggings, (tagging) => {
          tagging._destroy = false;
          return tagging;
        }),
        "tag.id"
      );
    }
    this.loadProfiles();
    this.loadTags();
  },
  destroyed() {
    this.$notificationManager.$off("tag-created", this.tagCreated);
  },
  methods: {
    tagCreated(tag) {
      this.showAddTagForm = false;
      this.tags.push(tag);
    },
    sessionSyncClicked(session) {
      this.$notificationManager.$emit("profile-clicked");
      if (this.rosterSyncers[session.id]) {
        // If we already have a syncer check to see if we have an id
        let sync = this.rosterSyncers[session.id];
        if (sync.id) {
          // We have an existing syncer on the backend, so we toggle the destroy flag
          sync._destroy = !sync._destroy;
        } else {
          // We do not have an existing syncer on the backend, so we simply drop it from the payload
          this.$delete(this.rosterSyncers, session.id);
        }

        return;
      }

      // In this world we do not have a session id so we set it
      let syncer = {
        _destroy: false,
        paid_session: {
          id: session.id,
          name: session.name,
        },
      };
      this.$set(this.rosterSyncers, session.id, syncer);
    },
    tagSyncClicked(tag) {
      if (this.taggings[tag.id]) {
        // If we already have a tag, check to see if we have an id on it
        let tagging = this.taggings[tag.id];
        if (tagging.id) {
          // We have an existing tagging on the backend, so we toggle the destroy flag
          tagging._destroy = !tagging._destroy;
        } else {
          // We do not have an existing tagging on the backend, so we simply drop it from the payload
          this.$delete(this.taggings, tag.id);
        }

        return;
      }

      // In this world we do not have a tagging for this tag at all so we set it
      let tagging = {
        _destroy: false,
        tag: tag,
      };
      this.$set(this.taggings, tag.id, tagging);
    },
    loadProfiles() {
      const vm = this;
      vm.loadingProfiles = true;
      const url = vm.$apiService.filteredWrestlers(this.showGuests, [], null, 100);
      vm.$apiService
        .loadAllPages(url, "wrestlers")
        .then((objects) => {
          vm.wrestlers = objects;
          if (vm.editing) {
            vm.loadRosterMembers();
          } else {
            // If we aren't editing we are creating. Build up the appropriate props
            vm.setMembershipList({});
            vm.loadingProfiles = false;
          }
        })
        .catch((error) => {
          vm.loadingProfiles = false;
          vm.errorMessage = `Error retrieving wrestlers ${error.toString()}`;
          vm.error = true;
        });
    },
    loadTags() {
      const vm = this;
      const url = vm.$apiService.tagsUrl();
      vm.$apiService
        .loadAllPages(url, "tags")
        .then((objects) => {
          vm.tags = objects;
        })
        .catch((error) => {
          vm.errorMessage = `Error retrieving tags ${error.toString()}`;
          vm.error = true;
        });
    },
    setMembershipList(existingMemberships) {
      const membershipList = this.wrestlers.map((w) => {
        if (existingMemberships[w.id]) {
          let existingMembership = existingMemberships[w.id];
          return {
            id: existingMembership.id,
            profile_id: existingMembership.profile.id,
            profile_type: existingMembership.profile.type,
            checked: true,
          };
        }

        return {
          profile_id: w.id,
          profile_type: "WrestlerProfile",
          checked: false,
        };
      });
      this.rosterMemberships = _.keyBy(membershipList, "profile_id");
    },
    loadRosterMembers() {
      const vm = this;

      const url = vm.$apiService.rosterMembershipsUrl(vm.existingRoster.id);
      vm.$apiService
        .loadAllPages(url, "roster_memberships")
        .then((objects) => {
          /// Once we have roster memberships, we build up the object the front end works with to check and uncheck members.
          vm.setMembershipList(_.keyBy(objects, "profile.id"));
          vm.loadingProfiles = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving wrestlers ${error.toString()}`;
          vm.error = true;
        });
    },
    getRosterMembershipParams() {
      const memberships = [];
      for (let [key, membership] of Object.entries(this.rosterMemberships)) {
        if (membership.checked) {
          memberships.push(membership);
        } else if (membership.id && !membership.checked) {
          // We inject a _destroy flag, which is how the Rails backend determines what to destroy in the update call
          // But we only do it if there is an id and we are not checked
          membership._destroy = true;
          memberships.push(membership);
        }
      }

      return memberships;
    },
    getSyncerParams() {
      return _.flatMap(this.rosterSyncers, function (r) {
        if (r.id) {
          return {
            id: r.id,
            paid_session_id: r.paid_session.id,
            _destroy: r._destroy,
          };
        }

        return {
          paid_session_id: r.paid_session.id,
          _destroy: r._destroy,
        };
      });
    },
    getTaggingsParams() {
      return _.flatMap(this.taggings, function (tagging) {
        if (tagging.id) {
          return {
            id: tagging.id,
            tag_id: tagging.tag.id,
            _destroy: tagging._destroy,
          };
        }

        return {
          tag_id: tagging.tag.id,
          _destroy: tagging._destroy,
        };
      });
    },
    buildParams() {
      return {
        roster: {
          name: this.name,
          roster_memberships_attributes: this.getRosterMembershipParams(),
          roster_syncers_attributes: this.getSyncerParams(),
          taggings_attributes: this.getTaggingsParams(),
        },
      };
    },
    validate() {
      const vm = this;
      vm.error = false;

      if (vm.name.length === 0) {
        vm.error = "Please name the roster";
        return false;
      }

      return true;
    },
    saveRoster() {
      const vm = this;
      const valid = vm.validate();
      if (vm.loading || !valid) {
        return;
      }

      vm.loading = true;
      const params = vm.buildParams();
      if (vm.editing) {
        vm.updateRoster(params);
      } else {
        vm.createRoster(params);
      }
    },
    // TODO dry this up further
    createRoster(params) {
      const vm = this;
      const url = vm.$apiService.rostersUrl();
      axios
        .post(url, params)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit("show-toast", "Saved roster...", true);
          window.location.assign(`/wrestlers?roster_ids[]=${response.data.id}`);
        })
        .catch((error) => {
          vm.error = true;
          vm.errorMessage = `Error creating roster ${error.toString()}`;
          vm.loading = false;
        });
    },
    updateRoster(params) {
      const vm = this;
      const url = vm.$apiService.rosterUrl(vm.existingRoster.id);
      axios
        .put(url, params)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit("show-toast", "Saved roster...", true);
          window.location.assign(
            `/wrestlers?roster_ids[]=${vm.existingRoster.id}`
          );
        })
        .catch((error) => {
          vm.error = true;
          vm.errorMessage = `Error creating roster ${error.toString()}`;
          vm.loading = false;
        });
    },
    confirmDestroy() {
      const vm = this;
      swal
        .fire({
          type: "warning",
          title: `Are you sure you want to delete the ${vm.existingRoster.name} roster?`,
          text: "You cannot undo deleting a roster.",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonClass: "btn btn-danger m-l-md",
          confirmButtonText: "Yes, delete roster",
          cancelButtonClass: "btn btn-default",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            axios
              .delete(vm.$apiService.rosterUrl(vm.existingRoster.id))
              .then((response) => {
                vm.$notificationManager.$emit(
                  "show-toast",
                  "Roster deleted",
                  true
                );
                window.location.assign("/wrestlers");
              })
              .catch((error) => {
                vm.$notificationManager.$emit(
                  "show-toast",
                  "Roster failed to delete",
                  false
                );
              });
          }
        });
    },
    triggerSync() {
      if (this.reSyncingRoster) {
        return;
      }
      this.reSyncingRoster = true;
      const url = this.$apiService.rosterSyncUrl(this.existingRoster.id);
      axios.post(url)
        .then((response) => {
          this.reSyncingRoster = false;
          this.$notificationManager.$emit("show-toast", "Roster re-synced...", true);
          window.location.assign(`/wrestlers?roster_ids[]=${this.existingRoster.id}`);
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = `Error re-syncing roster ${error.toString()}`;
          this.reSyncingRoster = false;
        });
    }
  },
};
</script>
