<template>
  <div>
    <form role="form" id="usaw-form">
      <div class="flex flex-col">
        <label class="flex">
          <input type="radio" name="usaw_memberships" v-model="setting" value="usaw_disabled" aria-labelledby="usaw-0-label" aria-describedby="usaw-0-description">
          <div class="m-l-m flex flex-col">
              <span id="usaw-0-label" class="font-semi">
                Disabled
              </span>
            <p id="usaw-0-description" class="font-normal m-b-none">
              Do not collect USAW information
            </p>
          </div>
        </label>
        <label class="flex m-t-m">
          <input type="radio" name="usaw_memberships" v-model="setting" value="usaw_optional" aria-labelledby="usaw-1-label" aria-describedby="usaw-1-description">
          <div class="m-l-m flex flex-col">
              <span id="usaw-1-label" class="font-semi">
                Optional
              </span>
            <p id="usaw-1-description" class="font-normal m-b-none">
              USAW information can be entered, but it is not required to finish registration for the team
            </p>
          </div>
        </label>
        <label class="flex m-t-m">
          <input type="radio" name="usaw_memberships" v-model="setting" value="usaw_require_id_and_expires" aria-labelledby="usaw-3-label" aria-describedby="usaw-3-description">
          <div class="m-l-m flex flex-col">
              <span id="usaw-3-label" class="font-semi">
                Require id and expiration
              </span>
            <p id="usaw-3-description" class="font-normal m-b-none">
              USAW id and expiration date must be entered to register for the team
            </p>
          </div>
        </label>
        <label class="flex m-t-m">
          <input type="radio" name="usaw_memberships" v-model="setting" value="usaw_require_all" aria-labelledby="usaw-2-label" aria-describedby="usaw-2-description">
          <div class="m-l-m flex flex-col">
              <span id="usaw-2-label" class="font-semi">
                Require all info (id, expiration, screenshot of card)
              </span>
            <p id="usaw-2-description" class="font-normal m-b-none">
              USAW information must be entered to register and photo uploaded of membership card
            </p>
          </div>
        </label>
        <label class="flex m-t-m">
          <input type="radio" name="usaw_memberships" v-model="setting" value="usaw_invisible" aria-labelledby="usaw-4-label" aria-describedby="usaw-4-description">
          <div class="m-l-m flex flex-col">
              <span id="usaw-4-label" class="font-semi">
                Invisible (show on wrestler page, but not during registration)
              </span>
            <p id="usaw-4-description" class="font-normal m-b-none">
              This setting lets you record USAW info behind the scenes, but does not collect it from registrants at registration time
            </p>
          </div>
        </label>
      </div>
      <ladda-button @lbClicked="update" el-class="btn-primary m-t-md" :loading="updating">
        Save
      </ladda-button>
      <div v-if="error">
        <small><span class="text-danger">{{ errorMessage }}</span></small>
      </div>
    </form>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { errorableMix } from './mixins/errorable_mix';

export default {
  name: 'usaw-settings',
  components: { LaddaButton },
  mixins: [ errorableMix],
  props: [
    'initialSetting',
    'teamId',
  ],
  data() {
    return {
      setting: null,
      updating: false
    };
  },
  mounted() {
    this.setting = this.initialSetting;
  },
  methods: {
    update: _.throttle(function () {
      if (this.updating) {
        return;
      }

      let vm = this;
      vm.updating = true;
      const url = vm.$apiService.teamUrl(this.teamId);
      const data = {
        team: {
          usaw_memberships: this.setting,
        }
      };
      axios.put(url, data)
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Settings saved', true);
            vm.updating = false;
          })
          .catch((error) => {
            vm.saving = false;
            vm.errorMessage = `Error: ${error.toString()}`;
            vm.error = true;
          });
    }, 500),
  },
};
</script>
