<template>
  <div role="form">
    <div class="form-group flex flex-col">
      <label>Add note</label>
      <input id="x-editor" type="hidden" name="content">
      <trix-editor :id="trixDomId" input="x-editor" class="trix-content"></trix-editor>
      <div class="flex justify-space-between m-t-sm">
        <button class="btn btn-default" @click.prevent="cancelNote()">
          Cancel
        </button>
        <div>
          <ladda-button @lbClicked="saveNote" el-class="btn-primary" :loading="loading">
            Save
          </ladda-button>
          <div v-if="error">
            <small>
              <span class="text-danger">{{ errorMessage }}</span>
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import LaddaButton from '../../shared/ladda_button.vue';
import Tribute from 'tributejs';
import { trixFileAttachmentMix } from './mixins/trix_file_attachment_mix';

export default {
  name: 'wrestler-note-form',
  components: {
    LaddaButton,
  },
  mixins: [trixFileAttachmentMix, errorableMix],
  props: {
    wrestlerId: {
      type: Number,
      required: true
    },
    existingNoteContent: {
      type: String,
      default: null,
    },
    existingNoteId: {
      type: Number,
      default: null
    },
    // For @ mentions
    suggestUrl: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      loading: false,
      content: '',
      trixEditor: null,
    };
  },
  mounted() {
    document.addEventListener('trix-initialize', this.trixInitHandler);
  },
  destroyed() {
    document.removeEventListener('trix-initialize', this.trixInitHandler);
  },
  computed: {
    trixDomId() {
      let noteId = this.existingNoteId ?? 'new';
      return `wrestler-note-form-${noteId}`;
    },
  },
  methods: {
    trixInitHandler(event) {
      let vm = this;
      vm.trixEditor = event.target.editor;

      if (vm.existingNoteContent) {
        vm.content = vm.existingNoteContent;
        vm.trixEditor.loadHTML(vm.content);
        if (vm.trixEditor != null) {
          //console.log(vm.trixEditor);
          //vm.trixEditor.composition.delegate.inputController.events.keypress = function() {};
          //vm.trixEditor.composition.delegate.inputController.events.keydown = function() {};
        }
      }

      // Load up @ mention functionality
      if (vm.suggestUrl) {
        vm.$apiService.loadAllPages(vm.suggestUrl, 'coaches')
          .then((coaches) => {
            const data = _.map(coaches, (coach) => {
              return {
                key: coach.full_name,
                value: coach.full_name
              };
            });
            const tribute = new Tribute({
              values: data,
              allowSpaces: true,
              noMatchTemplate: function () {
                return '<span style:"visibility: hidden;"></span>';
              }
            });
            let trixDomElement = document.getElementById(vm.trixDomId);
            tribute.attach(trixDomElement);
          })
          .catch((error) => {
            console.log(error);
            console.log('failed to load coaches for mentions');
          });
      }
    },
    cancelNote() {
      this.$emit('cancel-note-form');
    },
    getPlainNoteText() {
      const vm = this;
      if (vm.trixEditor) {
        return vm.trixEditor.getDocument().toString();
      }
      return '';
    },
    saveNote: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }
      vm.clearError();

      const content = $('#x-editor').val();
      if (content.length === 0) {
        vm.showError('Content must exist to save the note.');
        return;
      }
      const plainContent = vm.getPlainNoteText();

      vm.loading = true;
      const params = {
        note: {
          content: content,
          plain_content: plainContent,
        },
      };

      if (vm.existingNoteId != null) {
        // PUT
        const url = vm.$apiService.updateNoteUrl(vm.existingNoteId);
        axios.put(url, params)
            .then((response) => {
              vm.loading = false;
              vm.$notificationManager.$emit('show-toast', 'Note saved', true);
              vm.$emit('note-updated', vm.existingNoteId, content);
              vm.$notificationManager.$emit('mentionable-created', 'Note');
            })
            .catch((error) => {
              this.loading = false;
              this.showError('Error updating note');
            });
      } else {
        // POST
        const url = vm.$apiService.wrestlerNotesUrl(vm.wrestlerId);
        axios.post(url, params)
            .then((response) => {
              vm.loading = false;
              vm.$notificationManager.$emit('show-toast', 'Note saved', true);
              vm.$emit('note-created', response.data.note);
              vm.$notificationManager.$emit('mentionable-created', 'Note');
            })
            .catch((error) => {
              this.loading = false;
              this.showError('Error creating note');
            });
      }
    }, 500),
  }
};
</script>
