<template>
  <div>
    <div class="flex flex-wrap gap-x-lg gap-x-xs-xs-only gap-y-sm align-items-end">
      <div class="form-group m-b-none">
        <label class="font-normal">Viewing</label>
        <select v-model="range" id="payment-chart-range" class="form-control" name="range">
          <option value="today">Today</option>
          <option value="7d">Last 7 days</option>
          <option value="4w">Last 4 weeks</option>
          <option value="3m">Last 3 months</option>
          <option value="12m">Last 12 months</option>
          <option value="year to date">Year to date</option>
        </select>
      </div>
      <div class="form-group m-b-none">
        <label class="font-normal">Grouped</label>
        <select v-model="interval" id="payment-interval" class="form-control" name="interval">
          <option v-for="i in availableIntervals" :value="i">{{ capitalize(i) }}</option>
        </select>
      </div>
      <div class="form-group m-b-none">
        <label class="font-normal">Compared to</label>
        <select id="payment-comparison" class="form-control" name="comparison">
          <option value="previous-period">Previous period</option>
        </select>
      </div>
    </div>
    <div class="grid grid-cols-3 md-grid-cols-2 sm-grid-cols-1 gap-8 m-t-md">
      <div>
        <metrics-chart :key="keyFor('gross')" chart-key="gross" :endpoint="this.grossUrl"></metrics-chart>
      </div>
      <div>
        <metrics-chart :key="keyFor('net')" chart-key="net" :endpoint="this.netUrl"></metrics-chart>
      </div>
      <div v-if="showRecurring">
        <metrics-chart :key="keyFor('new-subscribers')" chart-key="new-subscribers" :endpoint="this.newSubscribersUrl"></metrics-chart>
      </div>
      <div v-if="showRecurring">
        <metrics-chart :key="keyFor('active-subscribers')" chart-key="active-subscribers" :endpoint="this.activeSubscribersUrl"></metrics-chart>
      </div>
      <div v-if="showRecurring">
        <metrics-chart :key="keyFor('mrr')" chart-key="mrr" :endpoint="this.mrrUrl"></metrics-chart>
      </div>
      <div v-if="showRecurring">
        <metrics-chart :key="keyFor('rev-per-sub')" chart-key="rev-per-sub" :endpoint="this.revSubscriberUrl"></metrics-chart>
      </div>
      <div>
        <metrics-chart :key="keyFor('charge-count')" chart-key="charge-count" :endpoint="this.chargeCountUrl"></metrics-chart>
      </div>
      <div>
        <metrics-chart :key="keyFor('charge-avg')" chart-key="charge-avg" :endpoint="this.chargeAvgUrl"></metrics-chart>
      </div>
    </div>
  </div>
</template>
<script>
import MetricsChart from './metrics_chart.vue';

export default {
  name: "payment-charts",
  components: { MetricsChart },
  props: {
    showRecurring: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      range: "7d",
      interval: "daily",
    }
  },
  computed: {
    grossUrl() {
      return this.$apiService.grossVolume(this.range, this.interval);
    },
    netUrl() {
      return this.$apiService.netVolume(this.range, this.interval);
    },
    activeSubscribersUrl() {
      return this.$apiService.activeSubscribers(this.range, this.interval);
    },
    mrrUrl() {
      return this.$apiService.mrr(this.range, this.interval);
    },
    newSubscribersUrl() {
      return this.$apiService.newSubscribers(this.range, this.interval);
    },
    revSubscriberUrl() {
      return this.$apiService.revPerSubscriber(this.range, this.interval);
    },
    chargeCountUrl() {
      return this.$apiService.chargeCount(this.range, this.interval);
    },
    chargeAvgUrl() {
      return this.$apiService.chargeAvg(this.range, this.interval);
    },
    availableIntervals() {
      if (this.range === 'today') {
        return ["hourly"]
      } else if (this.range === "7d") {
        return ["daily"]
      } else if (this.range === "4w") {
        return ["daily", "weekly"]
      } else {
        return ["monthly"]
      }
    }
  },
  watch: {
    range(newRange) {
      if (newRange === 'today') {
        this.interval = "hourly"
      } else if (newRange === "7d") {
        this.interval = "daily"
      } else if (newRange === "4w") {
        this.interval = "weekly"
      } else if (newRange === '3m' || newRange === '12m' || newRange === 'year to date') {
        this.interval = "monthly"
      }
    }
  },
  methods: {
    keyFor(chartName) {
      return `${chartName}-${this.range}-${this.interval}`;
    },
    capitalize(name) {
      return _.capitalize(name);
    }
  }
};
</script>
