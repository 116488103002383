<template>
  <div>
    <div class="row" v-if="showingChoice">
      <div class="col-xs-12">
        <div class="ibox">
          <div class="ibox-title">
            <h1>What type of message do you want to send?</h1>
          </div>
          <div class="ibox-content">
            <div class="grid sm-grid-cols-1 grid-cols-2 sm-gap-4 gap-24">
              <div @click="choseGroup"
                   class="relative border border-solid border-gray-200 rounded-sm cursor-pointer p-lg">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="25" width="25" fill="currentColor"
                     class="text-gray-200 absolute" style="top: 12px; right: 16px;">
                  <path
                      d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"/>
                </svg>
                <h2 class="m-t-none m-b-none text-semi">
                  Group
                </h2>
                <p class="m-t-md m-b-none">
                  Use messaging groups for subject based groups such as "travel team" or "regionals".
                </p>
                <p class="m-t-xs">
                  <strong>You decide whether everyone can send to the group or only coaches.</strong>
                </p>
              </div>
              <div @click="choseDirect"
                   class="relative border border-solid border-gray-200 rounded-sm cursor-pointer p-lg">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" height="25" width="25" fill="currentColor"
                     class="text-gray-200 absolute" style="top: 12px; right: 16px;">
                  <path
                      d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"/>
                </svg>
                <h2 class="m-t-none m-b-none text-semi">
                  Direct
                </h2>
                <p class="m-t-md m-b-none">
                  Use direct messages for 1-1 or group messages between specific people, rather than about specific
                  topics.
                </p>
                <p class="m-t-xs">
                  <strong>Anyone part of a direct message group can send messages to it.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!showingChoice">
      <div class="col-xs-12">
        <div class="ibox">
          <div class="ibox-title">
            <div class="flex justify-space-between align-items-center p-t-xs">
              <h1 style="padding-top: 0;">
                {{ isDirectMessage() ? 'Who do you want to send a message to?' : 'Create a new message group' }}
              </h1>
              <button v-if="showTypeChoice" v-on:click="showingChoice = true" class="btn btn-default btn-compact">
                Change group type
              </button>
            </div>
          </div>
          <div class="ibox-content">
            <div v-if="!isDirectMessage()">
              <div class="row m-t-lg">
                <div class="col-md-4">
                  <h1 class="form-section-title">Group details</h1>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="group_name">Group Name</label>
                    <small>e.g. rides, varsity travel team, tournament help, etc</small>
                    <input v-model="groupName" type="text" id="group_name" class="form-control" maxlength="50"/>
                  </div>
                  <div class="m-t-sm form-group">
                    <label for="group_purpose">Group Purpose</label>
                    <small>e.g. "Used by parents and coaches to organize rides for tournaments."</small>
                    <input v-model="groupPurpose" type="text" id="group_purpose" class="form-control" maxlength="75"/>
                  </div>
                </div>
              </div>
              <div class="row m-t-xl">
                <div class="col-md-4">
                  <h1 class="form-section-title">Message Control</h1>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <div>
                      <label>
                        Who should be able to send messages within the group?
                      </label>
                      <div>
                        <input type="radio" value="all" id="all_related_events" v-model="allowPublish"> Everyone
                      </div>
                      <div>
                        <input type="radio" value="admins" id="only_this_event" v-model="allowPublish"> Only coaches and
                        admins
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" :class="isDirectMessage() ? 'm-t-lg' : 'm-t-xl'">
              <div class="col-md-4">
                <template v-if="isDirectMessage()">
                  <h1 class="form-section-title">Add Recipients</h1>
                </template>
                <template v-else>
                  <h1 class="form-section-title">Add Group Members</h1>
                </template>
              </div>
              <div class="col-md-8">
                <div v-if="canMessageAll && addingStrategy === 'none'" class="m-t-only-xs-sm grid sm-grid-cols-1 grid-cols-2 sm-gap-4 gap-24">
                  <div @click="addingStrategy = 'sync'"
                       class="relative border border-solid border-gray-200 rounded-sm cursor-pointer p-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="25" width="25"
                         fill="currentColor"
                         class="text-gray-200 absolute" style="top: 12px; right: 16px;">
                      <path
                          d="M80 0L53.34 53.33 0 80l53.34 26.67L80 160l26.66-53.33L160 80l-53.34-26.67zm192 48l-32-16-16-32-16 32-32 16 32 16 16 32 16-32zm186.66 293.33L432 288l-26.66 53.33L352 368l53.34 26.67L432 448l26.66-53.33L512 368zM399 243.07l86.6-86.55 17-17a32 32 0 0 0 0-45.26l-17-17-50.86-50.86-17-17a32 32 0 0 0-45.25 0l-17 17L269 112.94l-39.62 39.6 39.61 39.61 50.91 50.91 39.59 39.58zm-90.5-90.52L395.14 66l50.91 50.91-86.6 86.55z"
                          class="fa-secondary"/>
                      <path d="M359.44 282.64l-220 220a32 32 0 0 1-45.25 0L9.38 417.77a32 32 0 0 1 0-45.25l220-220z"
                            class="fa-primary"/>
                    </svg>
                    <h2 class="m-t-none m-b-none text-semi">
                      Sync with rosters
                    </h2>
                    <p class="m-t-md m-b-none">
                      Message groups synced with rosters <strong>will automatically add, and remove, wrestlers when they are
                      added to or removed from specific rosters</strong>.
                      You can also choose to automatically sync guardians of those wrestlers to the group.
                    </p>
                  </div>
                  <div @click="addingStrategy = 'individual'"
                       class="relative border border-solid border-gray-200 rounded-sm cursor-pointer p-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"height="25" width="25"
                         fill="currentColor"
                         class="text-gray-200 absolute" style="top: 12px; right: 16px;">
                      <path d="M400 144A144 144 0 1 1 256 0a144 144 0 0 1 144 144z" class="fa-secondary"/>
                      <path
                          d="M384 320h-55.1a174.1 174.1 0 0 1-145.8 0H128A128 128 0 0 0 0 448v16a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48v-16a128 128 0 0 0-128-128z"
                          class="fa-primary"/>
                    </svg>
                    <h2 class="m-t-none m-b-none text-semi">
                      Add individually
                    </h2>
                    <p class="m-t-md m-b-none">
                      Manually add coaches, parents, or wrestlers to the group individually.
                    </p>
                  </div>
                </div>
                <div v-if="addingStrategy === 'sync'">
                  <div class="form-group flex align-items-start" v-if="showGuardianChoice">
                    <input id="include_guardians" type="checkbox" v-model="includeGuardians">
                    <div class="m-l-sm">
                      <label for="create_roster" class="m-b-none">
                        Include guardians of wrestlers in the messaging group?
                      </label>
                      <p class="text-gray-600">
                        Keep checked unless you want to create a wrestler-only messaging group
                      </p>
                    </div>
                  </div>
                  <div>
                    <label class="">
                      Which coaches should be added to the group?
                    </label>
                    <!-- Coaches -->
                    <full-load-list
                        base-url="/api/v1/coaches"
                        container-class="roster-grid grid grid-cols-3 sm-grid-cols-1 md-grid-cols-2 border m-t-xs"
                        response-key="coaches">
                      <template v-slot:list="slotProps">
                        <div class="p-xs">
                          <div class="flex align-items-baseline cursor-pointer" @click="coachClicked(slotProps.result)">
                            <i class="fa fa-check text-blue-300" v-if="slotProps.result.id === activeProfileId || isCoachSelected(slotProps.result)"></i>
                            <i class="fa fa-times-circle text-red-500" v-else-if="!coachHasRosterPermission(slotProps.result)"></i>
                            <i class="fa fa-square-o" v-else></i>
                            <h3 class="m-y-none m-l-xs">
                              {{ slotProps.result.full_name }}
                            </h3>
                          </div>
                          <p class="leading-5" v-if="slotProps.result.id === activeProfileId">
                            <small>You are auto-added to any group you create</small>
                          </p>
                          <p class="leading-5" v-else-if="!coachHasRosterPermission(slotProps.result)">
                            <small>Roster restricted coach. Add {{ coachRosters(slotProps.result) }} to allow coach into group.</small>
                          </p>
                        </div>
                      </template>
                    </full-load-list>
                  </div>
                  <div>
                    <label class="m-t-lg m-b-none">
                      Which rosters should the messaging group sync with?
                    </label>
                    <div class="flex flex-wrap gap-xxs m-t-xs">
                      <button @click="toggleRoster(sync.roster)" class="btn btn-success btn-outline rounded-lg font-small"
                              style="padding: 2px 6px;" v-for="sync in rosterSyncers">
                        {{ sync.roster.name }}
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                    <search
                        class="search-form m-t-sm"
                        response-key="rosters"
                        placeholder="Type the name of a roster..."
                        :mutate-history="false"
                        :allow-add="false"
                        :small-input="true"
                        :display-all-on-empty="false"
                        readable-model-name="roster"
                        base-url="/api/v1/rosters">
                      <template v-slot:list="slotProps">
                        <div
                            class="border-top m-t-sm m-b-xs p-t-sm p-b-xs cursor-pointer flex justify-space-between align-items-center p-r-sm"
                            @click="toggleRoster(slotProps.result)">
                          <div>
                            {{ slotProps.result.name }}
                          </div>
                          <div class="border rounded-sm border-blue-200 border-solid font-small"
                               style="padding: 2px 6px;">
                            Sync
                          </div>
                        </div>
                      </template>
                    </search>
                  </div>
                </div>
                <div v-if="addingStrategy === 'individual' || !canMessageAll">
                  <div>
                    <h5>Shortcuts</h5>
                    <template v-if="loadingProfiles">
                      <spinner></spinner>
                    </template>
                    <div v-show="!loadingProfiles">
                      <button class="btn btn-default" v-on:click="addAllCoaches">Add all active coaches</button>
                      <template v-if="canMessageAll">
                        <button class="btn btn-default" v-on:click="addAllParents">Add all active parents</button>
                        <button class="btn btn-default" v-on:click="addAllWrestlers">Add all active wrestlers</button>
                      </template>
                    </div>
                  </div>
                  <div class="m-t-m">
                    <h5>Add individually</h5>
                    <template v-if="canMessageAll">
                      <search
                          class="search-form"
                          response-key="results"
                          placeholder="Type name..."
                          :mutate-history="false"
                          :allow-add="false"
                          :display-all-on-empty="false"
                          readable-model-name="results"
                          :profiles-only="true"
                          base-url="/api/v1/search">
                        <template v-slot:list="slotProps">
                          <div class="profile-wrapper" @click="profileClicked(slotProps.result)">
                            <generic-search-result
                                v-bind:result="slotProps.result"
                                v-bind:is-selectable="true"
                                v-bind:key="slotProps.result.id">
                            </generic-search-result>
                          </div>
                        </template>
                      </search>
                    </template>
                    <template v-else>
                      <!-- can only messages coaches -->
                      <full-load-list
                          base-url="/api/v1/coaches"
                          response-key="coaches">
                        <template v-slot:list="slotProps">
                          <div class="profile-wrapper" @click="profileClicked(slotProps.result)">
                            <generic-search-result
                                override-key='full_name'
                                v-bind:result="slotProps.result"
                                v-bind:is-selectable="true"
                                v-bind:key="slotProps.result.id">
                            </generic-search-result>
                          </div>
                        </template>
                      </full-load-list>
                    </template>
                  </div>
                  <div class="m-t-lg">
                    <template v-if="isDirectMessage()">
                      <h5>Recipients</h5>
                    </template>
                    <template v-else>
                      <h5>Members</h5>
                    </template>
                    <div class="m-l-sm">
                      <div v-for="profile in recipients">
                        <div>
                          <p>
                            <i class="fa fa-clock-o"></i> {{ profile.full_name || profile.name }} &nbsp;&nbsp
                            <a v-on:click="removeRecipient(profile)" class="text-danger">(remove)</a>
                          </p>
                        </div>
                      </div>
                      <div v-if="recipients.length === 0">
                        None added
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-t-lg">
              <div class="col-xs-12 text-right">
                <ladda-button @lbClicked="createGroup" el-class="btn-primary" :loading="loading">
                  {{ buttonText() }}
                </ladda-button>
                <div>
                  <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import FullLoadList from './full_load_list.vue';
import GenericSearchResult from './generic-search-result.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import Search from '../../shared/search.vue';
import Spinner from './spinner.vue';

export default {
  name: 'message-group-form',
  components: {
    LaddaButton,
    FullLoadList,
    Spinner,
    GenericSearchResult,
    Search,
  },
  mixins: [errorableMix],
  props: {
    activeProfileId: {
      type: Number,
      required: true
    },
    activeProfileType: {
      type: String,
      required: true,
    },
    canMessageAll: {
      type: Boolean,
      default: false,
    },
    guessedGroupType: {
      type: String,
      required: true,
    },
    showTypeChoice: {
      type: Boolean,
      default: false,
    },
    showGuardianChoice: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      recipients: [],
      addingStrategy: 'none', // can be 'none', 'sync', or 'individual'
      rosterSyncers: {},
      includeGuardians: true,
      loading: false,
      groupName: '',
      groupPurpose: '',
      groupType: 'direct',
      loadingProfiles: false,
      allowPublish: 'all',
      showingChoice: false,
    };
  },
  mounted() {
    if (this.showTypeChoice) {
      this.showingChoice = true;
    }
    this.guessedGroupType = this.groupType;
  },
  watch: {
    // When changing group messaging stratgies, null out recipients and rosterSyncers
    addingStrategy(newStrategy) {
      this.rosterSyncers = {};
      this.recipients = [];
    },
  },
  computed: {
    rosterIdsToSync() {
      return Object.keys(this.rosterSyncers);
    }
  },
  methods: {
    isCoachSelected(profile) {
      return this.recipients.indexOf(profile) !== -1;
    },
    coachHasRosterPermission(coach) {
      if (!coach.roster_restricted) {
        return true;
      }

      const rosterIds = _.flatMap(coach.roster_permissions, (rp) => { return String(rp.roster.id) });
      return _.intersection(rosterIds, this.rosterIdsToSync).length > 0;
    },
    coachRosters(coach) {
      let names = _.flatMap(coach.roster_permissions, 'roster.name');
      return names.slice(0, -2)
          .join(', ') + (names.slice(0, -2).length ? ', ' : '') + names.slice(-2)
          .join(' or ');
    },
    // Method used in roster sync coach adding
    coachClicked(coach) {
      // Short circuit if this click is for our own coach profile
      if (coach.id === this.activeProfileId) {
        return;
      }

      if (this.isCoachSelected(coach)) {
        this.removeRecipient(coach);
      } else if (this.coachHasRosterPermission(coach)){
        this.profileClicked(coach);
      }
    },
    toggleRoster(roster) {
      // Clear search
      this.$notificationManager.$emit('profile-clicked');
      if (this.rosterSyncers[roster.id]) {
        // In this world we have this in the existing roster permissions array
        // Since this component is just for creating new memberships we can just remove it from our local copy
        this.$delete(this.rosterSyncers, roster.id);
        return;
      }

      // If we get here, we don't know about it, so just add it.
      this.$set(this.rosterSyncers, roster.id, {
        // We need this nested structure to match server side message_group_roster_syncer_attributes
        _destroy: false,
        roster: {
          id: roster.id,
          name: roster.name,
        }
      });
    },
    getSyncerParams() {
      let vm = this;
      return _.flatMap(this.rosterSyncers, function (r) {
        return {
          roster_id: r.roster.id,
          _destroy: r._destroy,
          include_guardians: vm.includeGuardians,
        };
      });
    },
    choseDirect() {
      this.groupType = 'direct';
      this.addingStrategy = 'individual'
      this.showingChoice = false;
    },
    choseGroup() {
      this.groupType = 'custom_created';
      this.addingStrategy = 'none'
      this.showingChoice = false;
    },
    buttonText() {
      if (this.isDirectMessage()) {
        return 'Start Direct Message';
      }

      return 'Create Group';
    },
    isDirectMessage() {
      return this.groupType === 'direct';
    },
    addAllCoaches() {
      // load all pages of coaches, convert them to pending coaches
      const vm = this;
      vm.loadingProfiles = true;
      vm.error = false;

      const url = vm.$apiService.coachesUrl();

      vm.$apiService.loadAllPages(url, 'coaches')
          .then((objects) => {
            vm.addRecipients(objects);
            vm.loadingProfiles = false;
          })
          .catch((error) => {
            vm.loadingProfiles = false;
            vm.errorMessage = `Error retrieving coaches ${error.toString()}`;
            vm.error = true;
          });
    },
    addAllParents() {
      const vm = this;
      vm.loadingProfiles = true;
      vm.error = false;

      const url = vm.$apiService.parentsUrl();

      vm.$apiService.loadAllPages(url, 'parent_profiles')
          .then((objects) => {
            vm.addRecipients(objects);
            vm.loadingProfiles = false;
          })
          .catch((error) => {
            vm.loadingProfiles = false;
            vm.errorMessage = `Error retrieving parents ${error.toString()}`;
            vm.error = true;
          });
    },
    addAllWrestlers() {
      const vm = this;
      vm.loadingProfiles = true;
      vm.error = false;

      const url = vm.$apiService.wrestlersUrl();

      vm.$apiService.loadAllPages(url, 'wrestlers')
          .then((objects) => {
            vm.addRecipients(objects);
            vm.loadingProfiles = false;
          })
          .catch((error) => {
            vm.loadingProfiles = false;
            vm.errorMessage = `Error retrieving results ${error.toString()}`;
            vm.error = true;
          });
    },
    addRecipients(newRecipients) {
      const vm = this;
      const recipientsMinusMe = _.reject(newRecipients, (profile) => profile.id === vm.activeProfileId && profile.type === vm.activeProfileType);
      const allReceipients = vm.recipients.concat(recipientsMinusMe);
      vm.recipients = _.uniqBy(allReceipients, (e) => `${e.id}_${e.type}`);
    },
    removeRecipient(profile) {
      const vm = this;
      const index = vm.recipients.indexOf(profile);
      vm.recipients.splice(index, 1);
    },
    profileClicked(profile) {
      const vm = this;
      vm.addRecipients([profile]);
      vm.$notificationManager.$emit('profile-clicked');
    },
    toSentence(arr) {
      return arr.slice(0, -2)
          .join(', ') + (arr.slice(0, -2).length ? ', ' : '') + arr.slice(-2)
          .join(' and ');
    },
    getAllNames() {
      const name = function name(profile) {
        if (profile.type === 'CoachProfile') {
          const lName = (profile.full_name || profile.name).split(' ')
              .pop();
          return `Coach ${lName}`;
        }

        return (profile.full_name || profile.name);
      };
      return _.map(this.recipients, name);
    },
    getName() {
      const vm = this;
      if (vm.isDirectMessage()) {
        // returning null for direct messages lets the backend setup the name
        return null;
      }

      return vm.groupName;
    },
    getPurpose() {
      const vm = this;
      if (vm.isDirectMessage()) {
        // returning null for purpose lets the backend setup the purpose
        return null;
      }

      return vm.groupPurpose;
    },
    getMembershipParams() {
      const vm = this;
      // add yourself as a member
      const memberships = [
        {
          profile_id: vm.activeProfileId,
          profile_type: vm.activeProfileType,
          can_publish: true,
        },
      ];

      // todo change this up for other types of custom groups for can_publish
      vm.recipients.forEach((profile) => {
        const member = {
          profile_id: profile.id,
          profile_type: profile.type,
          can_publish: vm.allowedToPublish(profile),
        };
        memberships.push(member);
      });

      return memberships;
    },
    allowedToPublish(profile) {
      const vm = this;
      // Everyone is allowed to publish in a direct message
      if (vm.isDirectMessage() || vm.allowPublish === 'all') {
        return true;
      }

      if (profile.type === 'CoachProfile') {
        return true;
      }

      return false;
    },
    buildParams() {
      const vm = this;
      return {
        message_group: {
          name: vm.getName(),
          group_type: vm.groupType,
          purpose: vm.getPurpose(),
          default_allow_publish: vm.allowPublish,
          message_memberships_attributes: vm.getMembershipParams(),
          message_group_roster_syncers_attributes: vm.getSyncerParams(),
        },
      };
    },
    createGroup() {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.error = false;

      if (vm.addingStrategy === 'sync' && vm.rosterIdsToSync.length === 0) {
        vm.errorMessage = 'Add at least one roster to sync';
        vm.error = true;
        return;
      }

      if (vm.recipients.length === 0 && vm.addingStrategy !== 'sync') {
        vm.errorMessage = 'Add at least one recipient';
        vm.error = true;
        return;
      }

      vm.loading = true;

      const url = vm.$apiService.messageGroupsUrl();
      const params = vm.buildParams();

      axios.post(url, params)
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', 'Saved...', true);
            window.location.assign(`/message_groups/${response.data.id}`);
          })
          .catch((error) => {
            vm.error = true;
            vm.errorMessage = vm.parseErrorResponse(error);
            vm.loading = false;
          });
    },

  },
};
</script>
