<template>
    <div class="">
        <div class="hr-line-dashed"></div>
        <div class="flex flex-col-xs-only justify-space-between">
            <div>
                <h3 class="m-t-none m-b-xxs">
                    <span class="text-default">{{ invoice.billing_profile.email }}</span>
                </h3>
                <p class="m-b-none text-12">
                    {{ paidAt() }} | {{ source() }}
                </p>
            </div>
            <div class="flex flex-col m-t-only-xs-sm" v-if="refunds.length > 0">
                <div v-if="fullyRefunded"><span class="label label-warning">Fully Refunded</span></div>
                <div v-else-if="partiallyRefunded"><span class="label label-warning">Partially Refunded</span></div>
                <div class="flex flex-col" style="margin-top: 2px;">
                    <div v-for="refund in refunds" class="font-small">
                        <template v-if="refund.receipt !== null">
                            <a :href="refund.receipt.cloudfront_url"
                               target="_blank">{{ formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a') }} <span
                                v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span> <i
                                class="fa fa-file-text-o"></i></a>
                        </template>
                        <template v-else>
                            {{ formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a') }} <span
                            v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span>
                        </template>
                    </div>
                </div>
            </div>
            <div class="text-right t-l-md  m-t-only-xs-sm">
                <p class="font-heavy text-gray-700 text-14 m-b-none m-t-none">
                    {{ amount() }}
                    <span class="m-l-xs" v-if="invoice.receipt">
                        <a :href="invoice.receipt.cloudfront_url" target="_blank"><i class="fa fa-file-text-o"></i></a>
                    </span>
                </p>
                <div v-if="this.paidWithCard && !this.fullyRefunded && this.showRefundActions">
                    <button @click="showRefundForm = true"
                            class="btn no-underline btn-compact bg-gray-50 text-gray-700 flex justify-space-between gap-x-xxs">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z"/>
                        </svg>
                        <span class="text-12">
                          Refund...
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="showRefundForm">
            <refund-form :possible-refund-amount="possibleRefundAmount" :charge="succeededCharge"></refund-form>
        </div>
    </div>
</template>
<script>
import { formattingMixin } from './mixins/formatting_mix';
import RefundForm from './refund_form.vue';
import { manualInvoiceRefundMixin } from './mixins/manual_invoice_refund_mix';

export default {
  name: 'donation-manual-invoice',
  components: { RefundForm },
  mixins: [formattingMixin, manualInvoiceRefundMixin],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  methods: {
    paidAt() {
      if (this.invoice.status === 'paid') {
        return moment(this.invoice.paid_at)
          .format('M/D/YYYY');
      }

      const dueDate = moment(this.invoice.due_on)
        .format('M/D/YYYY');
      return `Will be collected on ${dueDate}`;
    },
    source() {
      const invoiceType = this.invoice.invoice_type;
      if (invoiceType === 'order') {
        return 'Donated online via WIQ donation form';
      }

      if (invoiceType === 'payment_plan') {
        return `Added to ${_.lowerFirst(this.invoice.description)}`;
      }

      return 'Unknown source';
    },
    amount() {
      // In some cases, like the donation form, the whole invoice is a donation
      if (this.invoice.description === 'Donation') {
        return this.formatAsCurrency(this.invoice.amount_paid);
      }

      // In other cases, we have donations threaded into invoices
      for (const item of this.invoice.invoice_items) {
        if (item.description === 'Donation') {
          return this.formatAsCurrency(item.amount);
        }
      }

      return 'Unknown amount';
    },
  },
};
</script>
