<template>
  <div class="row">
    <div class="col-md-12">
      <div class="ibox">
        <div class="ibox-title">
          <h1>Import USAW information</h1>
        </div>
        <div class="ibox-content">
          <h2>Upload the .csv export from USAW</h2>
          <div class="grid grid-cols-2 sm-grid-cols-1 gap-12">
            <div class="form-group">
              <label for="expiresOn">When do these USAW cards expire?</label>
              <the-mask mask="##/##/####"
                        placeholder="mm/dd/yyyy"
                        v-model="expiresOn"
                        class="form-control"
                        id="expiresOn"
                        :masked="true"
                        ref="date"
                        data-cy="expiresOn"
                        type="tel">
              </the-mask>
            </div>
            <div class="form-group">
              <label for="membership_type">Membership type</label>
              <select data-cy="usaw-type" id="membership_type" v-model="membershipType" class="form-control">
                <option selected disabled>Choose membership type...</option>
                <optgroup label="Athlete Membership">
                  <option value="Full Athlete">Full Athlete</option>
                  <option value="High Performance Athlete">High Performance Athlete</option>
                  <option value="Intramural">Intramural</option>
                  <option value="Limited Folkstyle">Limited Folkstyle</option>
                  <option value="Open License">Open License</option>
                </optgroup>
              </select>
              <small>Note: The USAW export does not contain membership level info. <strong>Unfortunately, this means you need to ensure the file only contains athletes you know have the same membership level.</strong></small>
            </div>
          </div>
          <div v-if="showUploadForm()">
            <div>
              <h2>Upload the .csv export from USAW</h2>
              <p>
                Your .csv file should come directly from USAW. The following column names are required for the import to work.
              </p>
              <ul>
                <li>usaw_id</li>
                <li>first_name</li>
                <li>last_name</li>
                <li>dob</li>
              </ul>
            </div>
            <div class="m-t-md">
              <input type="file" id="files">
              <ladda-button @lbClicked="validateAndParse" el-class="btn-primary m-t-lg" :loading="fileParsing">
                Upload
              </ladda-button>
              <div v-if="error">
                <small class="text-danger">
                  {{ errorMessage }}
                </small>
              </div>
            </div>
          </div>
          <div v-if="!showUploadForm()">
            <div>
              <h2>
                Verify Headers
              </h2>
            </div>
            <div class="alert-wrappers">
              <div v-if="validFieldsFound.length > 0" class="alert alert-success">
                <strong>{{ arrayToStr(validFieldsFound) }}</strong> found in file.
              </div>
              <div v-if="ignoredFields.length > 0" class="alert alert-warning">
                <strong>{{ arrayToStr(ignoredFields) }}</strong> found in file, but is not used in the import. These columns are being ignored.
              </div>
              <div v-if="missingOptionalFields.length > 0" class="alert alert-info">
                <strong>{{ arrayToStr(missingOptionalFields) }}</strong> not found in file. This is ok, as this is optional info, but if you expected it to be included, please double check your headers.
              </div>
              <p>
                Want to choose a new file? <strong><a class="text-danger" @click.prevent="reuploadFile()">Click here</a></strong>
              </p>
            </div>
            <div class="m-t-xl">
              <h2>Confirm USAW ids to import</h2>
            </div>
            <div class="uploaded-info list-results">
              <div class="search-result teammate" v-for="row in parsedRows">
                <div class='hr-line-dashed'></div>
                <div class="grid grid-cols-2 sm-grid-cols-1 gap-3">
                  <div>
                    <h3>
                      {{ row.first_name }} {{ row.last_name }}
                    </h3>
                    <p class="search-type-label" v-html="usawInfo(row)"></p>
                  </div>
                  <div class="text-right t-l-md" v-html="importStatus(row)"></div>
                </div>
              </div>
            </div>
            <div>
              <p>
                <strong>You are about to import {{ toImportCount }} USAW memberships</strong>
              </p>
              <div v-show="!importFinished">
                <ladda-button @lbClicked="beginImport" el-class="btn-primary m-t-lg" :loading="loading">
                  Import
                </ladda-button>
              </div>
              <div v-show="importFinished">
                {{ importMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { importerMix } from './mixins/importer_mix';
import { TheMask } from 'vue-the-mask';
import { errorableMix } from './mixins/errorable_mix';

export default {
  name: 'bulk-add-usaw',
  components: { Spinner, LaddaButton, TheMask },
  mixins: [importerMix, errorableMix],
  data() {
    return {
      expiresOn: '',
      membershipType: '',
      allFields: ['usaw_id', 'first_name', 'last_name', 'dob'],
      requiredFields: ['usaw_id', 'first_name', 'last_name', 'dob'],
      queryingWrestlers: false,
    };
  },
  computed: {
    membershipsToImport() {
      return _.filter(this.parsedRows, { import_status: 'not_started' });
    },
    toImportCount() {
      return this.membershipsToImport.length;
    }
  },
  methods: {
    validateAndParse() {
      if (this.expiresOn === '' || this.membershipType === '') {
        this.error = true;
        this.errorMessage = 'Please enter an expiration date and membership level before uploading';
        return;
      }
      this.parseFile();
    },
    formatUploadedRow(uploaded_row) {
      let dob = uploaded_row['dob'];
      if (dob) {
        dob = moment(dob, 'MM/DD/YYYY').format('YYYY-MM-DD');
      }
      return {
        usaw_id: uploaded_row['usaw_id'],
        first_name: uploaded_row['first_name'],
        last_name: uploaded_row['last_name'],
        dob: dob,
        expires_on: this.expiresOn,
        import_status: 'searching',
        import_reason: 'Finding wrestler...'
      }
    },
    uploadParsed() {
      let promises = []
      this.parsedRows.forEach(async (row, index) => {
        promises.push(this.searchForWrestler(row, index))
      });

      Promise.all(promises).then((results) => {
        console.log('all finished');
        console.log(results);
      }).catch(error => {
        console.log('error');
        console.log(error);
      })
    },
    async searchForWrestler(row, index){
      console.log(`search for wrestler called with index ${index}`);
      const url = `${this.$apiService.wrestlersUrl()}?first_name=${row.first_name}&last_name=${row.last_name}&dob=${row.dob}`;
      return axios.get(url)
          .then((response) => {
            if (response.data.wrestlers.length > 1) {
              row.import_status = 'duplicate';
              let urls = _.map(response.data.wrestlers, (wp) => { return `<a href="/wrestlers/${wp.id}" target="_blank"> Profile ${wp.id} <i class="fa fa-external-link"></i></a>`})
              row.import_reason = `Multiple wrestlers found with this name and dob, skipping.<br/> ${urls.join(',')}`;
            } else if (response.data.wrestlers.length === 1) {
              let wp = response.data.wrestlers[0];
              if (wp.usaw_membership_id && moment(wp.usaw_expires_on).isSameOrAfter(moment(row.expires_on))) {
                row.import_status = 'blocked';
                row.import_reason = `Existing usaw membership ${wp.usaw_membership_id} is active until ${wp.usaw_expires_on}, skipping`;
              } else {
                row.wrestler_profile_id = wp.id;
                row.import_status = 'not_started';
                row.import_reason = `Wrestler found, ready to import usaw id ${row.usaw_id} with expiration of ${row.expires_on}`;
              }
            } else {
              row.import_status = 'blocked';
              row.import_reason = `No wrestler found with name ${row.first_name} ${row.last_name} and dob of ${row.dob}, skipping`;
            }
          })
          .catch((error) => {
            row.import_status = 'failed';
            row.import_reason = 'Searching for wrestler failed';
          });
    },
    usawInfo(row) {
      return `Born on ${row.dob}, attempting to import USAW id ${row.usaw_id}`;
    },
    importStatus(row) {
      switch (row.import_status) {
        case 'searching':
          return `<p><i class='fa fa-search'></i> ${row.import_reason}</p>`;
        case 'not_started':
          return `<p><i class='fa fa-circle-o'></i> ${row.import_reason}</p>`;
        case 'importing':
          return "<p><i class='fa fa-circle-o-notch'></i> Importing...</p>";
        case 'blocked':
          return `<p class='text-warning'><i class='fa fa-exclamation-triangle'></i> ${row.import_reason}</p>`;
        case 'duplicate':
          return `<p class='text-danger'><i class='fa fa-times-circle'></i> ${row.import_reason}</p>`;
        case 'failed':
          return `<p class='text-danger'><i class='fa fa-times-circle'></i> ${row.import_reason}</p>`;
        case 'imported':
          return `<p class='text-success'><i class='fa fa-check-circle-o'></i> ${row.import_reason}</p>`;
      }
    },
    checkIfFinished() {
      const vm = this;
      // some checks if any still have that status
      if (!_.some(vm.parsedRows, ['import_status', 'importing'])) {
        // All have finished
        vm.loading = false;
        vm.importFinished = true;
        vm.importMessage = 'Finished importing';
      }
    },
    createUsawMembership(row) {
      const vm = this;
      const url = vm.$apiService.usawMemberships(row.wrestler_profile_id);
      const params = {
        usaw_membership: {
          public_id: row.usaw_id,
          expires_on: moment(row.expires_on, 'MM/DD/YYYY').toISOString(),
          membership_type: vm.membershipType,
        }
      };
      row.import_status = 'importing';
      axios.post(url, params)
          .then((response) => {
            row.import_status = 'imported';
            row.import_reason = `Usaw id ${row.usaw_id} successfully imported`;
            vm.checkIfFinished();
          })
          .catch((error) => {
            row.import_status = 'failed';
            row.import_reason = this.parseErrorResponse(error);
            vm.checkIfFinished();
          });
    },
    beginImport: _.throttle(function () {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.loading = true;
      vm.membershipsToImport.forEach((row) => {
        vm.createUsawMembership(row);
      });
    }, 500),
  }
};
</script>
<style scoped>
</style>
