<template>
  <div role="form">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit } ">
      <div class="row m-t-m">
        <div class="col-md-4">
          <h1 class="form-section-title">Edit Coach {{ coach.full_name }}</h1>
        </div>
        <div class="col-md-8">
          <ValidationProvider name="first name" rules="required" :debounce="1000" v-slot="{ errors }">
            <div class="form-group">
              <label for="first_name">First name</label>
              <div class="relative">
                <input type="text"
                       name="first name"
                       id="first_name"
                       v-model="coach.first_name"
                       :class="inputClasses(errors[0])">
                <error-icon v-if="errors[0]"></error-icon>
              </div>
              <p class="m-t-xxs text-sm text-red-600" v-if="errors[0]">
                {{ errors[0] }}
              </p>
            </div>
          </ValidationProvider>
          <ValidationProvider name="last name" rules="required" :debounce="1000" v-slot="{ errors }">
            <div class="form-group">
              <label for="last_name">Last name</label>
              <div class="relative">
                <input type="text"
                       name="last name"
                       id="last_name"
                       v-model="coach.last_name"
                       :class="inputClasses(errors[0])">
                <error-icon v-if="errors[0]"></error-icon>
              </div>
              <p class="m-t-xxs text-sm text-red-600" v-if="errors[0]">
                {{ errors[0] }}
              </p>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="row m-t-m">
        <div class="col-md-4">
          <h2 class="form-section-h2">Role</h2>
          <p>
            In most cases, the assistant role is recommended for coaches or staff members who need access to WrestlingIQ, unless access to financial data and account configuration is needed.<br><br>
            <a href="https://help.wrestlingiq.com/article/80-understanding-coach-account-roles" target="_blank" class="text-underline">Read more about coach roles here. <i class="fa fa-external-link" aria-hidden="true"></i></a>
          </p>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <div class="m-t-xs flex align-items-center justify-space-between flex-wrap" :class="isAdmin ? 'border-gray-500 bg-gray-50 border border-solid p-sm rounded-sm' : ''">
              <div class="flex-shrink-0">
                <input type="radio" value="admin" v-model="coach.role"> Admin
              </div>
              <p class="m-t-xxs">Admin accounts have full access to create, view, edit, and delete everything in the
                system. <strong>This includes financial data, personal info, and the access levels of other
                  coaches.</strong></p>
            </div>
            <!-- Gray spacer for default state -->
            <div v-if="coach.role === ''" class="border-gray-50 border-top" style="height: 1px; width: 100%;"></div>
            <div :class="isAssistant ? 'border-gray-500 bg-gray-50 border border-solid p-sm rounded-sm' : ''">
              <ValidationProvider rules="required|oneOf:admin,assistant" name="role" v-slot="{ errors }">
                <div class="p-t-sm">
                  <input type="radio" value="assistant" v-model="coach.role"> Assistant
                </div>
                <p class="m-t-xxs">
                  Assistant accounts <strong>do not</strong> have access to financial data and team configuration, as well as limited access to documents (i.e. they cannot create/modify liability forms) and coach accounts (i.e. they cannot make themselves an admin or invite other coaches).<br><br>
                  This role is suitable for most people who are helping build your team, but don't need to know the back office details.
                </p>
                <p class="m-t-xsm text-sm text-red-600" v-if="errors[0]">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
              <div class="m-t-lg" v-if="isAssistant">
                <div class="flex form-group">
                  <label class="checkbox-inline">
                    <input type="checkbox" v-model="coach.roster_restricted"> Limit access to specific rosters
                  </label>
                  <VDropdown theme="info-tooltip">
                    <!-- This will be the popover target (for the events and position) -->
                    <i class="fa fa-question m-l-xs text-blue-800"></i>
                    <!-- This will be the content of the popover -->
                    <template v-slot:popper>
                      <div class="m-l-sm">
                        <p class="header">
                          What happens when a coach is limited to a specific roster?
                        </p>
                        <p>
                          Roster limited assistants still retain all of their abilities described above—such as modifying the calendar for the whole team—but it ensures
                          their coach account can only view wrestler information (and their associated parents) who are part of a specific roster.
                        </p>
                        <p>
                          This is most commonly used if you have multiple teams or locations and have coaching staff members who shouldn't know about any other team in the system.
                        </p>
                      </div>
                    </template>
                  </VDropdown>
                </div>
                <div v-if="coach.roster_restricted">
                  <div class="flex flex-wrap gap-xxs m-t-xs">
                    <button @click="toggleRoster(rp.roster)" class="btn btn-success btn-outline rounded-lg font-small" style="padding: 2px 6px;" v-for="rp in notDestroyedRosterPermissions">
                      {{ rp.roster.name }}
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                  <search
                      class="search-form m-t-sm"
                      response-key="rosters"
                      placeholder="Type the name of a roster..."
                      :mutate-history="false"
                      :allow-add="false"
                      :small-input="true"
                      :display-all-on-empty="false"
                      readable-model-name="roster"
                      base-url="/api/v1/rosters">
                    <template v-slot:list="slotProps">
                      <div class="border-top m-t-sm m-b-xs p-t-sm p-b-xs cursor-pointer flex justify-space-between align-items-center p-r-sm" @click="toggleRoster(slotProps.result)">
                        <div>
                          {{ slotProps.result.name }}
                        </div>
                        <div class="border rounded-sm border-blue-200 border-solid font-small" style="padding: 2px 6px;">
                          Apply
                        </div>
                      </div>
                    </template>
                  </search>
                </div>
              </div>
            </div>
          </div>
          <div class="button-area text-right">
            <div v-if="error">
              <small>
                <span class="text-danger">{{ errorMessage }}</span>
              </small>
            </div>
            <ladda-button @lbClicked="handleSubmit(putUpdate)" el-class="btn-primary" :loading="loading">
              Save
            </ladda-button>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import ErrorIcon from './svgs/error_icon.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import Search from '../../shared/search.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  name: 'coach-edit',
  components: {
    ErrorIcon,
    LaddaButton,
    Search,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [errorableMix],
  props: {
    initialCoach: {
      type: Object,
      required: true
    },
    showDelete: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      coach: this.initialCoach,
      // if rosterRestricted is set to true (and we are an assistant), this array holds all the rosters we want to limit this assistant to.
      allowedRosters: {},
    };
  },
  computed: {
    isAssistant() {
      return this.coach.role === 'assistant';
    },
    isAdmin() {
      return this.coach.role === 'admin';
    },
    notDestroyedRosterPermissions() {
      return _.flatMap(this.allowedRosters).filter(rp => !rp._destroy);
    }
  },
  created() {
    // I am not 100% certain why I needed cloneDeep to get reactivity here, but it works...
    const rps = _.cloneDeep(this.initialCoach.roster_permissions);
    this.allowedRosters = _.keyBy(_.map(rps, (rp) => {
      rp._destroy = false;
      return rp;
    }), 'roster.id');
  },
  methods: {
    toggleRoster(roster) {
      this.$notificationManager.$emit('profile-clicked');
      if (this.allowedRosters[roster.id]) {
        // In this world we have this in the existing roster permissions array
        // Now check whether it's synced with the server or we can just drop it from the front end payload
        let rp = this.allowedRosters[roster.id];
        if (rp.id) {
          // We have an existing, backend saved, roster_permission, toggle the destroy flag.
          rp._destroy = !rp._destroy;
        } else {
          // We do not have it saved, just drop it from the front end payload
          this.$delete(this.allowedRosters, roster.id);
        }

        console.log(rp);
        return;
      }

      // If we get here, we don't know about it, so just add it.
      this.$set(this.allowedRosters, roster.id, {
        // We need this nested structure to match server side roster permissions (saved ones have an id and coach_profile_id on it)
        _destroy: false,
        roster: {
          id: roster.id,
          name: roster.name,
        }
      });
    },
    inputClasses(error) {
      const normalClasses = 'form-control';
      if (!error) {
        return normalClasses;
      }

      return `border-red-300 text-red-900 ${normalClasses}`;
    },
    getRosterPermissionsParams() {
      return _.flatMap(this.allowedRosters, function (rp) {
        let payload = {
          roster_id: rp.roster.id,
          _destroy: rp._destroy,
        }

        if (rp.id) {
          payload.id = rp.id;
        }

        return payload;
      });
    },
    putUpdate() {
      const vm = this;
      if (vm.loading === true) {
        return;
      }

      const params = {
        coach_profile: {
          first_name: vm.coach.first_name,
          last_name: vm.coach.last_name,
          role: vm.coach.role,
          // If we are an admin, we never have roster restrictions. If we are setting up an assistant, you might have rosterRestrictions
          roster_restricted: vm.isAdmin ? false : vm.coach.roster_restricted,
          roster_permissions_attributes: this.getRosterPermissionsParams(),
        }
      }

      const url = vm.$apiService.coachUrl(vm.coach.id);
      axios.put(url, params)
          .then((response) => {
            vm.loading = false;
            const name = `${vm.coach.first_name} ${vm.coach.last_name}`;
            vm.$notificationManager.$emit('show-toast', `${name} updated`, true);
            window.location.assign(`/coaches/${vm.coach.id}`);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
    },
  }
};
</script>
