<template>
  <div class="search-result">
    <div v-if="showHr" class='hr-line-dashed m-t-only-xs-sm m-b-xs m-t-none'></div>
    <div class="grid sm-grid-cols-2 grid-cols-5 align-items-baseline">
      <div class="flex gap-x-sm align-items-baseline">
        <h3 class="text-gray-700 font-semi">
          {{ formatAsCurrency(payout.amount) }}
        </h3>
        <p class="text-gray-500 uppercase">{{ payout.currency }}</p>
      </div>
      <div class="flex align-items-center justify-space-between">
        <div class="flex align-items-center">
          <div v-if="payout.status === 'paid'" class="badge badge-primary-light p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs">
            {{ status }}
          </div>
          <div v-else-if="payout.status === 'in_transit'" class="badge badge-info-light p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs">
            {{ status }}
          </div>
          <div v-else class="badge badge-warning p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs">
            {{ status }}
          </div>
        </div>
      </div>
      <div class="flex align-items-center justify-space-between">
        {{ payout.destination.bank_name }} {{ payout.destination.bank_last4 }}
      </div>
      <div class="flex align-items-center justify-space-between">
        {{ depositDate }}
      </div>
      <div class="hidden-xs hidden-sm flex justify-self-end align-items-center">
        <a class="btn btn-primary btn-compact" :href="showUrl">
          Details
        </a>
      </div>
      <div class="hidden-md hidden-lg m-t-sm m-b-xs sm-col-span-2">
        <a class="w-full btn btn-primary btn-compact" :href="showUrl">
          Details
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { currencyMix } from '../../shared/currency_mix';

export default {
  name: 'payout',
  mixins: [currencyMix],
  props: {
    payout: {
      type: Object,
      required: true
    },
    showHr: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showUrl() {
      return `/payouts/${this.payout.id}`
    },
    status() {
      return _.startCase(this.payout.status)
    },
    depositDate() {
      return moment(this.payout.deposits_at).format('M/D/YYYY');
    }
  }
};
</script>
<style scoped>
</style>
