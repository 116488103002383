<template>
  <div class="group relative flex flex-col overflow-hidden rounded-sm border border-gray-300 bg-white border-solid">
    <div class="">
      <img
          :src="photoUrl"
          alt="Picture of product" class="h-full w-full object-contain object-center sm:h-full sm:w-full rounded-t-sm">
    </div>
    <div class="flex flex-1 flex-col space-y-2 p-x-sm p-b-m">
      <h3 class="text-sm font-medium text-gray-900">
      {{ product.name }}
      </h3>
      <p class="text-sm text-gray-800 m-b-none">
        {{ description }}
      </p>
      <div class="flex flex-1 flex-col justify-end text-gray-700 gap-y-xxs m-t-xxs">
        <p class="text-sm m-y-none">
          {{ readableCategory }}<span v-if="product.category === 'clothing'">.</span>
          <span v-if="product.category === 'clothing'">
            {{ colorCount }} color<span v-if="colorCount > 1">s</span>. {{ sizeCount }} size<span v-if="sizeCount > 1">s</span>.
          </span>
        </p>
        <p class="text-sm font-medium m-t-none">{{ priceRange }}</p>
        <a :href="`/products/${product.id}/edit`" class="text-gray-800" v-if="showEdit">
          <i class="fa fa-pencil color-gray-400 m-r-xxs"></i>Edit
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { formattingMixin } from './mixins/formatting_mix';

export default {
  name: 'product-list-result',
  mixins: [formattingMixin],
  props: {
    product: {
      type: Object,
      required: true
    },
    showEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    readableCategory() {
      return _.capitalize(this.product.category)
    },
    photoUrl() {
      return this.photo?.url || 'https://mktg-cdn.wrestlingiq.com/placeholders/placeholder.png'
    },
    activeBillingPlans() {
      return _.filter(this.product.billing_plans, 'active');
    },
    photo() {
      return _.head(_.uniqBy(_.flatMap(this.activeBillingPlans, 'photos'), 'url'));
    },
    description() {
      return this.truncate(this.stripHTML(this.product.description), 100);
    },
    colorCount() {
      return _.uniq(_.flatMap(this.activeBillingPlans, 'color')).length;
    },
    sizeCount() {
      return _.uniq(_.flatMap(this.activeBillingPlans, 'size')).length;
    },
    priceRange() {
      let prices = _.map(this.activeBillingPlans, 'amount');
      let minPrice = _.min(prices);
      let maxPrice = _.max(prices);
      return minPrice === maxPrice ? this.formatAsCurrency(minPrice) : `${this.formatAsCurrency(minPrice)} - ${this.formatAsCurrency(maxPrice)}`;
    }
  },
  methods: {
    stripHTML(html) {
      let doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || '';
    },
    truncate(str, n) {
      return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    },
  }
};
</script>
<style scoped>
</style>
