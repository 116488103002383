<template>
    <div>
        <h3>
            {{ teamName() }}
        </h3>
    </div>
</template>

<script>
export default {
  name: 'match-invite',
  props: [
    'matchInvite',
  ],
  data() {
    return {};
  },
  methods: {
    teamName() {
      const vm = this;
      if (vm.matchInvite.team) {
        return vm.matchInvite.team.name;
      }

      if (vm.matchInvite.opposing_team) {
        return vm.matchInvite.opposing_team.name;
      }

      return '';
    },
  },
};
</script>
