/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.6
 *
 */

$(function() {
  // Add body-small class if window less than 768px
  if ($(this).width() < 769) {
    $('body').addClass('body-small');
  } else {
    $('body').removeClass('body-small');
  }

  // todo remove metis menu
  $('#side-menu').metisMenu();

  // Minimalize menu
  $('.navbar-minimalize').on('click', (event) => {
    event.preventDefault();
    $('body').toggleClass('mini-navbar');
  });
});

// Minimalize menu when screen is less than 768px
$(window).bind('resize', function () {
  if ($(this).width() < 769) {
    $('body').addClass('body-small');
  } else {
    $('body').removeClass('body-small');
  }
});
