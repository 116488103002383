<template>
  <tbody class="border border-gray-100 border-b border-t-none border-solid">
  <tr class="relative" :class="{ 'bg-gray-50': showRefundForm }">
    <td class="text-gray-700" data-title="Billed to">
      <div>
        {{ invoice.billing_profile.billable.display_name }}
      </div>
      <div v-if="supportWallComment" class="support-wall-comment-td">
        <div class="bg-purple-50 text-purple-700 rounded-sm p-xs">
          <i class="fa fa-comment"></i>&nbsp;{{ supportWallComment }}
        </div>
      </div>
    </td>
    <td class="p-r-sm" data-title="Amount">
      <div class="font-heavy text-gray-700">
        {{ formatAsCurrency(invoice.amount_paid) }}&nbsp;
        <VDropdown theme="info-tooltip">
          <!-- This will be the popover target (for the events and position) -->
          <i class="fa fa-info"></i>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div>
              <div class="flex flex-col justify-space-between" style="min-width: 140px;">
                <div class="flex gap-x-sm justify-space-between m-b-sm p-b-sm border-bottom text-semi">
                  <div>
                    Gross
                  </div>
                  <div>
                    {{ formatAsCurrency(succeededCharge.amount_paid) }}
                  </div>
                </div>
                <div class="flex gap-x-sm justify-space-between">
                  <div>
                    CC processing fee
                  </div>
                  <div>
                    {{ formatAsCurrency(succeededCharge.processing_fee) }}
                  </div>
                </div>
                <div class="flex gap-x-sm justify-space-between">
                  <div>
                    WIQ fee
                  </div>
                  <div>
                    {{ formatAsCurrency(succeededCharge.wiq_fee) }}
                  </div>
                </div>
                <div class="flex gap-x-sm justify-space-between" v-if="succeededCharge.tax_amount > 0">
                  <div>
                    Taxes
                  </div>
                  <div>
                    {{ formatAsCurrency(succeededCharge.tax_amount) }}
                  </div>
                </div>
                <div v-if="alreadyRefundedAmount > 0" class="flex gap-x-sm justify-space-between">
                  <div>
                    Refund
                  </div>
                  <div>
                    {{ formatAsCurrency(alreadyRefundedAmount) }}
                  </div>
                </div>
                <div class="flex gap-x-sm justify-space-between m-t-sm p-t-sm border-top text-semi">
                  <div>
                    Net
                  </div>
                  <div>
                    {{ formatAsCurrency(succeededCharge.net_amount) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </VDropdown>
      </div>
    </td>
    <td class="text-gray-700 -m-t-sm">
      <div v-if="refunds.length === 0" class="flex gap-x-xs">
        <div class="label label-primary bg-green-50 text-green-800 font-normal border border-solid border-green-500">
          <i class="fa fa-check"></i> Succeeded
        </div>
        <a :href="invoice.receipt.url" v-if="invoice.receipt" target="_blank"><i class="fa fa-file-text-o" title="Receipt"></i></a>
      </div>
      <div class="flex flex-col" v-if="refunds.length > 0">
        <div v-if="fullyRefunded"><span class="label label-warning">Fully Refunded</span></div>
        <div v-else-if="partiallyRefunded"><span class="label label-warning">Partially Refunded</span></div>
        <div class="flex flex-col" style="margin-top: 4px;">
          <div class="font-small"><a :href="invoice.receipt.url" v-if="invoice.receipt" target="_blank">Original receipt <i
              class="fa fa-file-text-o" title="Receipt"></i></a></div>
          <div class="font-small">Refunds</div>
          <div v-for="refund in refunds" class="font-small">
            <template v-if="refund.receipt !== null">
              <a :href="refund.receipt.url" target="_blank">{{
                  formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a')
                }} <span v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span> <i
                    class="fa fa-file-text-o"></i></a>
            </template>
            <template v-else>
              {{ formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a') }} <span
                v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span>
            </template>
          </div>
        </div>
      </div>
    </td>
    <td class="text-gray-700" data-title="For">
      <div>
        {{ invoice.description }}
      </div>
      <div v-for="item in invoice.invoice_items">
        {{ item.description }}
      </div>
    </td>
    <td class="text-gray-700" data-title="Date">{{ formatWithMoment(invoice.created_at, 'M/D/YYYY hh:mm a') }}</td>
    <td class="flex flex-col gap-y-xxs" data-title="Actions">
      <div v-if="possibleRefundAmount > 0">
        <button @click="showRefundForm = true"
                class="btn no-underline btn-compact bg-gray-50 border border-gray-400 text-gray-700 flex justify-space-between gap-x-xxs">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z"/>
          </svg>
          <span class="text-12">
              Refund
            </span>
        </button>
      </div>
    </td>
  </tr>
  <tr v-if="showRefundForm">
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td colspan="2">
      <div class="m-b-lg" v-if="showRefundForm">
        <refund-form :possible-refund-amount="possibleRefundAmount" :charge="succeededCharge"></refund-form>
      </div>
    </td>
  </tr>
  </tbody>
</template>
<script>
import { formattingMixin } from './mixins/formatting_mix';
import RefundForm from './refund_form.vue';
import { manualInvoiceRefundMixin } from './mixins/manual_invoice_refund_mix';

export default {
  name: 'fundraiser-manual-invoice',
  mixins: [formattingMixin, manualInvoiceRefundMixin],
  components: { RefundForm },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    noHr: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    supportWallComment() {
      return this.invoice.metadata?.support_wall?.comment;
    }
  }
};
</script>
