<template>
  <div class="guardian-invite-row border-y p-y-sm border-gray-50 border-solid">
    <div class="flex items-center gap-x-xs">
      <h3 class="font-semi m-b-none m-t-none">{{ guardian.full_name }}</h3>
      <span v-if="active" class="badge badge-success">Active</span>
      <span v-if="invited" class="badge badge-warning">Invited</span>
    </div>
    <div v-if="active" class="text-gray-600">
      {{ guardian.email }}
    </div>
    <div v-if="invited" class="text-gray-600">
      {{ guardian.invite_email }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuardianInviteRow',
  props: {
    guardian: {
      type: Object,
      required: true
    }
  },
  computed: {
    active() {
      return this.guardian.user_status === 'active';
    },
    invited() {
      return this.guardian.user_status === 'invited';
    }
  }
}
</script>