<template>
    <div class="ibox">
        <div class="ibox-title">
            <div class="row">
                <div class="col-xs-6 col-md-8">
                    <h1>
                        {{ report.result.paid_session.name }} Accounting Report
                    </h1>
                    <p class="m-b-none"><small>
                        Report generated on {{ processedAt() }}
                    </small></p>
                </div>
                <div class="col-xs-6 col-md-4 text-right">
                    <div class="ibox-tools">
                        <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span class="hidden-xs">Download CSV</span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="ibox-content">
            <div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Due on</th>
                            <th v-bind:style="secondColStyles"># Registered</th>
                            <th>Registrant names</th>
                            <th>Registrant weights</th>
                            <th>Billed to</th>
                            <th>Billed to email</th>
                            <th>Status</th>
                            <th>Payment option</th>
                            <th>Add-on purchased</th>
                            <th>Amount due</th>
                            <th>Amount paid</th>
                            <th>Paid at</th>
                            <th>Payment method</th>
                            <th>Receipt</th>
                            <th>Scholarship code</th>
                            <th>Scholarship amount</th>
                            <th>WrestlingIQ fee amount</th>
                            <th>Notes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="row in rows">
                            <td class="first-col"
                                v-bind:style="firstColStyles">
                                {{ row.due_on }}
                            </td>
                            <td v-bind:style="secondColStyles">
                                {{ row.reg_count }}
                            </td>
                            <td>
                                {{ row.reg_names }}
                            </td>
                            <td>
                                {{ row.reg_weights }}
                            </td>
                            <td>
                                {{ row.billed_to_name }}
                            </td>
                            <td>
                                {{ row.billed_to_email }}
                            </td>
                            <td>
                                {{ row.status }}
                            </td>
                            <td>
                              {{ row.payment_option_names }}
                            </td>
                            <td>
                              {{ row.add_on_purchased }}
                            </td>
                            <td>
                                {{ row.amount_due }}
                            </td>
                            <td>
                                {{ row.amount_paid }}
                            </td>
                            <td>
                                {{ row.paid_at }}
                            </td>
                            <td>
                                {{ row.payment_method }}
                            </td>
                            <td>
                                <template v-if="row.receipt !== ''">
                                    <a target="_blank" :href="row.receipt"><i class="fa fa-file-text-o"></i></a>
                                </template>
                            </td>
                          <td>
                            {{ row.scholarship_code }}
                          </td>
                          <td>
                            {{ row.scholarship_amount_off }}
                          </td>
                          <td>
                            {{ row.wiq_fee_amount }}
                          </td>
                          <td>
                            {{ row.notes }}
                          </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';

export default {
  name: 'paid-session-accounting-report',
  mixins: [downloadMix, formattingMixin],
  props: [
    'report',
  ],
  data() {
    return {
      rows: [],
      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  mounted() {
    const vm = this;

    const paymentPlans = _.get(vm.report, 'result.payment_plans.objects', null);
    vm.rows = [];
    paymentPlans.forEach((plan) => {
      const email = _.get(plan, 'billing_profile.email', '');
      const name = _.get(plan, 'billing_profile.billable.display_name', '');
      const registrations = _.get(plan, 'registrations');
      const regCount = registrations.length;
      const regNames = _.join(_.map(registrations, 'profile.display_name'));
      const regWeights = _.join(_.map(registrations, 'profile.weight_class'));
      const paymentOptionNames = _.join(_.map(registrations, 'payment_option.name'));

      plan.manual_invoices.forEach((mi) => {
        const paidAt = mi.paid_at ? moment(mi.paid_at).format('M/D/YYYY, hh:mm a') : '';
        const scholarshipItem = _.head(_.filter(mi.invoice_items, ['transactable_type', 'Coupon']));
        const scholarshipCode = scholarshipItem ? _.get(scholarshipItem, 'transactable.code', '') :  '';
        const scholarshipAmountOff = scholarshipItem ? vm.formatAsCurrency(scholarshipItem.amount) : '';

        const wiqTransactableItem = _.head(_.filter(mi.invoice_items, ['transactable_type', 'ApplicationFee']));
        const wiqFeeAmount = wiqTransactableItem ? vm.formatAsCurrency(wiqTransactableItem.amount) : '';

        const upsell = _.head(_.filter(mi.invoice_items, ['transactable_type', 'BillingPlan']));
        const addOnPurchased = _.get(upsell, 'description', '');

        const row = {
          due_on: moment(mi.due_on).format('M/D/YYYY'),
          reg_count: regCount,
          reg_names: regNames,
          reg_weights: regWeights,
          billed_to_email: email,
          billed_to_name: name,
          status: mi.status,
          payment_option_names: paymentOptionNames,
          add_on_purchased: addOnPurchased,
          amount_due: vm.formatAsCurrency(mi.amount_due),
          amount_paid: vm.formatAsCurrency(mi.amount_paid),
          paid_at: paidAt,
          payment_method: mi.payment_method,
          receipt: _.get(mi, 'receipt.cloudfront_url', ''),
          scholarship_code: scholarshipCode,
          scholarship_amount_off: scholarshipAmountOff,
          wiq_fee_amount: wiqFeeAmount,
          notes: mi.notes,
        };
        vm.rows.push(row);
      });
    });

    vm.$nextTick(function () {
      const header = this.$refs.lnameHeader;
      if (header) {
        const rect = header.getBoundingClientRect();

        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    downloadCSV() {
      const vm = this;

      const rows = [];
      rows.push([
        'Due on',
        '# Registered',
        'Registrant names',
        'Registrant weights',
        'Billed to',
        'Billed to email',
        'Status',
        'Payment option',
        'Add on purchased',
        'Amount due',
        'Amount paid',
        'Paid at',
        'Payment method',
        'Receipt',
        'Scholarship code',
        'Scholarship amount',
        'WrestlingIQ fee amount',
        'Notes'
      ]);
      vm.rows.forEach((r) => {
        rows.push([
          r.due_on,
          r.reg_count,
          r.reg_names,
          r.reg_weights,
          r.billed_to_name,
          r.billed_to_email,
          r.status,
          r.payment_option_names,
          r.add_on_purchased,
          r.amount_due,
          r.amount_paid,
          r.paid_at,
          r.payment_method,
          r.receipt,
          r.scholarship_code,
          r.scholarship_amount_off,
          r.wiq_fee_amount,
          r.notes
        ]);
      });

      const fileName = `${vm.report.result.paid_session.name} Accounting Report - ${vm.processedAt()}.csv`;
      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
