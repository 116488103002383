<template>
  <div role="form">
    <div class="row">
      <div class="col-xs-12 col-sm-3">
        <div class="relative flex align-items-center gap-x-xxs">
          <label class="form-label">Wrestler Photo</label>
          <svg style="transform: rotate(21deg); stroke-width: 1.6px;" class="text-green-400" width="30" height="11" viewBox="0 0 59 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.0252 6.243C52.3687 4.4345 51.8317 2.28949 51.8317 1.47749C51.8317 0.497493 52.0842 0 52.5817 0C53.0452 0 53.3317 0.470006 53.3317 1.23151C53.3317 2.77901 55.0608 7.2145 56.3933 9.086C56.9483 9.866 57.5963 11.179 57.8333 12.0045C58.1928 13.259 58.0847 13.6335 57.1727 14.288C56.2287 14.966 50.5552 18.712 47.0187 20.9925C46.0657 21.6075 45.7563 21.6215 45.2243 21.0755C44.6978 20.5345 44.9427 20.0995 46.5817 18.663C47.6817 17.699 50.0987 16.0055 51.9527 14.9005C53.8067 13.7955 55.2127 12.7115 55.0767 12.4925C54.5932 11.709 50.0738 10.489 45.6148 9.9375C35.9438 8.741 21.9292 10.2015 13.2098 13.3145C7.77123 15.256 1.72672 18.5055 2.03922 19.3195C2.34372 20.1125 0.564236 20.2805 0.0842361 19.504C-0.860264 17.976 6.27525 13.817 14.5918 11.0485C23.1278 8.20749 29.9862 7.30451 40.3317 7.66051C47.8532 7.91951 49.9902 8.17451 53.1502 9.18851L54.2192 9.53149L53.0252 6.243Z"></path>
          </svg>
        </div>
        <pintura-photo-uploader
            :access-key="accessKey"
            :endpoint="endpoint"
            :cloudfront-base-url="cloudfrontBaseUrl"
            prefix="profile_photos/"
            imageCropAspectRatio="1"
            @photos-changed="photosChanged"
        ></pintura-photo-uploader>
        <p class="m-t-none m-b-m text-10 text-gray-500 text-center visible-xs">On a mobile device? You can take a photo or upload from your photo library.</p>
      </div>
      <div class="col-xs-12 col-sm-8 col-sm-offset-1">
        <div class="form-group m-t-only-xs-m">
          <label for="first_name">First Name</label>
          <input data-cy="first-name" id="first_name" v-model="first_name" type="text" class="form-control font-no-ios-zoom">
        </div>
        <div class="form-group">
          <label for="last_name">Last Name</label>
          <input data-cy="last-name" id="last_name" v-model="last_name" type="text" class="form-control font-no-ios-zoom">
        </div>
        <div class="form-group">
          <label for="weight_class">Weight</label>
          <input data-cy="weight" id="weight_class" v-model="weight_class" type="text" class="form-control font-no-ios-zoom">
        </div>
        <div class="form-group">
          <label for="dob">
            Date of Birth <span v-if="!requireDob">(optional)</span>
          </label>
          <the-mask mask="##/##/####"
                    placeholder="mm/dd/yyyy"
                    v-model="dob"
                    class="form-control"
                    id="dob"
                    :masked="true"
                    ref="date"
                    data-cy="dob"
                    type="tel">
          </the-mask>
        </div>
        <div class="form-group">
          <label for="academic_class">
            Academic Class <span v-if="!requireAcademicClass">(optional)</span>
          </label>
          <select id="academic_class" v-model="academic_class" class="form-control">
            <option selected disabled>Choose academic class...</option>
            <optgroup label="Pre-K to 12">
              <option value="Pre-K">Pre-K</option>
              <option value="Kindergarten">Kindergarten</option>
              <option value="1st grade">1st grade</option>
              <option value="2nd grade">2nd grade</option>
              <option value="3rd grade">3rd grade</option>
              <option value="4th grade">4th grade</option>
              <option value="5th grade">5th grade</option>
              <option value="6th grade">6th grade</option>
              <option value="7th grade">7th grade</option>
              <option value="8th grade">8th grade</option>
              <option value="9th grade">9th grade</option>
              <option value="10th grade">10th grade</option>
              <option value="11th grade">11th grade</option>
              <option value="12th grade">12th grade</option>
            </optgroup>
            <optgroup label="College">
              <option value="Freshmen">Freshmen</option>
              <option value="RS Freshmen">RS Freshmen</option>
              <option value="Sophomore">Sophomore</option>
              <option value="RS Sophomore">RS Sophomore</option>
              <option value="Junior">Junior</option>
              <option value="RS Junior">RS Junior</option>
              <option value="Senior">Senior</option>
              <option value="RS Senior">RS Senior</option>
              <option value="Grad Student">Grad Student</option>
            </optgroup>
            <optgroup label="Other">
              <option value="other">Other</option>
              <option value="Class of 2022">Class of 2022</option>
              <option value="Class of 2023">Class of 2023</option>
              <option value="Class of 2024">Class of 2024</option>
              <option value="Class of 2025">Class of 2025</option>
              <option value="Class of 2026">Class of 2026</option>
              <option value="Class of 2027">Class of 2027</option>
            </optgroup>
          </select>
        </div>
        <div v-if="showExperience" class="form-group">
          <label for="experience">Experience (optional)</label>
          <select id="experience" v-model="experience" class="form-control">
            <option selected disabled>Choose experience level...</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>
        </div>
        <div class="button-area text-right">
          <div v-show="error">
            <small>
              <span class="text-danger">{{ errorMessage }}</span>
            </small>
          </div>
          <button v-if="showCancel" name="button" v-on:click="$emit('cancel-wrestler-create')" class="btn btn-default">
            Cancel
          </button>
          <ladda-button data-cy="post-wrestler" @lbClicked="postWrestler" el-class="btn-primary" :loading="loading">
            {{ buttonText }}
          </ladda-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { TheMask } from 'vue-the-mask';
import { photoUploadMix } from './mixins/photo_upload_mix.js';
import PinturaPhotoUploader from './pintura_photo_uploader.vue';


export default {
  name: 'wrestler-create',
  mixins: [photoUploadMix],
  components: {
    PinturaPhotoUploader,
    LaddaButton,
    TheMask
  },
  props: {
    buttonText: {
      type: String,
      default: "Create Wrestler",
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    showExperience: {
      type: Boolean,
      default: false,
    },
    requireDob: {
      type: Boolean,
      default: false,
    },
    requireAcademicClass: {
      type: Boolean,
      default: false,
    },
    guardianId: {
      type: Number,
      default: null,
    },
    guardianType: {
      type: String,
      default: null,
    },
    redirectAfterCreate: {
      type: Boolean,
      default: true,
    },
    wrestlerType: {
      type: String,
      default: 'teammate',
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      first_name: '',
      last_name: '',
      weight_class: '',
      academic_class: '',
      dob: '',
      experience: '',
    };
  },
  computed: {
    isoDob() {
      if (!this.dob || this.dob.length === 0) {
        return null;
      }

      return moment(this.dob, 'MM/DD/YYYY')
          .toISOString();
    }
  },
  methods: {
    validate() {
      let errorMessage = 'Please ensure ';
      let error = false;
      if (this.first_name.length === 0) {
        errorMessage += 'first name, ';
        error = true;
      }
      if (this.last_name.length === 0) {
        errorMessage += 'last name, ';
        error = true;
      }
      if (this.weight_class.length === 0) {
        errorMessage += 'weight class, ';
        error = true;
      }
      if (this.requireDob && this.dob.length === 0) {
        errorMessage += 'date of birth, ';
        error = true;
      }
      if (this.requireAcademicClass && this.academic_class.length === 0) {
        errorMessage += 'academic class, ';
        error = true;
      }

      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        this.errorMessage = errorMessage;
        this.error = true;
        return false;
      }

      return true;
    },
    postWrestler: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (!vm.validate()) {
        return;
      }

      vm.loading = true;
      const params = {
        wrestler_profile: {
          first_name: vm.first_name,
          last_name: vm.last_name,
          dob: vm.isoDob,
          weight_class: vm.weight_class,
          academic_class: vm.academic_class,
          experience: vm.experience,
          profile_type:  vm.wrestlerType,
          profile_photos_attributes: vm.attachmentParams
        },
      };
      if (vm.guardianId && vm.guardianType) {
        params.wrestler_profile.guardian_relationships_attributes = [
          {
            guardianable_id: vm.guardianId,
            guardianable_type: vm.guardianType,
          },
        ]
      }

      const url = vm.$apiService.wrestlersUrl();
      axios.post(url, params)
        .then((response) => {
          vm.loading = false;
          response = response.data;
          vm.$notificationManager.$emit('show-toast', `${response.first_name} ${response.last_name} added`, true);
          // Bit sloppy here, but in some cases the event bus needs to be notified
          // and in others the parent component is just listening for this event
          vm.$notificationManager.$emit('wrestler-created', response.id);
          vm.$emit('wrestler-created', response.id);
          if (this.redirectAfterCreate) {
            window.location.assign(`/wrestlers/${response.id}`);
          } else {
            // Otherwise we need to reset the form in case we need to use it again
            vm.first_name = '';
            vm.last_name = '';
            vm.weight_class = '';
            vm.dob = '';
            vm.experience = '';
            vm.academic_class = '';

            // Also reset the profile photo element
            vm.photoAttachments = [];
          }
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
