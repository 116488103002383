<template>
    <div class="ibox">
        <div class="ibox-title">
            <div class="row">
                <div class="col-xs-6 col-md-8">
                    <h1>
                        {{ report.name }}, {{ report.result.roster_name }}, {{ reportSubTitle() }}
                    </h1>
                    <p class="m-b-none"><small>
                        Report generated on {{ processedAt() }}
                    </small></p>
                </div>
                <div class="col-xs-6 col-md-4 text-right">
                    <div class="ibox-tools">
                        <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span class="hidden-xs">Download CSV</span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="ibox-content">
            <div class="m-t-md m-b-xs">
                <div class="row" v-if="paidSessions.length > 0">
                    <div class="col-lg-8">
                        <div class="form-group">
                            <label>Show by session</label>
                            <select v-model="sessionFilter" class="form-control">
                                <option selected value="all">All Sessions</option>
                                <option v-for="session in paidSessions" :value="session.id">{{ session.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <ul class="list-inline">
                            <li class="text-primary">
                                <i class="fa fa-check"></i> Present
                            </li>
                            <li class="text-primary">
                                Ex - Excused
                            </li>
                            <li class="text-danger">
                                Ux - Unexcused
                            </li>
                            <li class="text-warning">
                                La - Late
                            </li>
                            <li>
                                In - Injured
                            </li>
                            <li>
                                Ot - Other
                            </li>
                            <li>
                                <i class="fa fa-question"></i> Unknown
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4">
                        <!-- todo filters based on client side data -->
                        <!--<button class="btn btn-default btn-compact"><i class="fa fa-filter"></i> Filter</button>-->
                    </div>
                </div>
            </div>
            <div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                            <th v-bind:style="secondColStyles">First name</th>
                            <th># Attended</th>
                            <th v-for="practice in practicesForCols">
                                {{ formattedStart(practice) }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="row in rows">
                            <td class="first-col"
                                v-bind:style="firstColStyles"
                            >
                                {{ row.last_name }}
                            </td>
                            <td v-bind:style="secondColStyles">{{ row.first_name }}</td>
                            <td>{{ row.attended_count }}</td>
                            <td v-for="practice in row.practices">
                                <div>
                                    <!-- This will be the popover target (for the events and position) -->
                                    <span v-html="htmlForStatus(practice.status)"></span>
                                    <VDropdown v-if="practice.notes" theme="info-tooltip">
                                      <i class="fa fa-sticky-note-o"></i>
                                      <!-- This will be the content of the popover -->
                                      <template v-slot:popper>
                                        <div>
                                          <p>{{ practice.notes }}</p>
                                        </div>
                                      </template>
                                    </VDropdown>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';

export default {
  name: 'practice-attendance-report',
  mixins: [downloadMix, formattingMixin],
  props: [
    'report',
  ],
  data() {
    return {
      practices: [],
      wrestlers: [],
      checkIns: [],
      paidSessions: [],
      sessionFilter: 'all',

      rows: [],
      practicesForCols: [],

      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  mounted() {
    const vm = this;
    const practices = _.get(vm.report, 'result.practices.objects', null);
    vm.practices = _.sortBy(practices, ['start_at']);

    const wrestlers = _.get(vm.report, 'result.wrestler_profiles.objects', null);
    vm.wrestlers = _.sortBy(wrestlers, ['last_name']);

    vm.checkIns = _.get(vm.report, 'result.check_ins.objects', null);

    // Use lodash to get all the unique paid sessions here
    const sessions = _.map(vm.practices, 'paid_session');
    vm.paidSessions = _.compact(_.uniqBy(sessions, 'id'));

    vm.rows = [];
    vm.setupRows();
  },
  watch: {
    sessionFilter() {
      // todo filter sessions
      this.setupRows();
    },
  },
  methods: {
    // forSessionId will either be 'all' or an int. That dictates a filter if it exists
    setupRows() {
      const vm = this;

      const rows = [];
      let wrestlers = null;
      let practices = null;

      if (vm.sessionFilter === 'all') {
        wrestlers = vm.wrestlers;
        practices = vm.practices;
      } else {
        // Limit to practices in the session
        practices = vm.practicesInSession(vm.sessionFilter);
        // Only give me wrestlers who have a check_in
        wrestlers = vm.wrestlersInPractices(practices);
      }

      const practiceIds = _.map(practices, 'id');

      wrestlers.forEach((wrestler) => {
        // notes
        const practicesForWrestler = [];
        practices.forEach((practice) => {
          practicesForWrestler.push(vm.practiceObjectFor(wrestler, practice));
        });

        const r = {
          last_name: wrestler.last_name,
          first_name: wrestler.first_name,
          attended_count: vm.countOfAttended(wrestler, practiceIds),
          practices: practicesForWrestler,
        };
        rows.push(r);
      });
      vm.rows = rows;
      vm.practicesForCols = practices;

      vm.$nextTick(function () {
        const header = this.$refs.lnameHeader;
        if (header) {
          const rect = header.getBoundingClientRect();
          this.$set(this.firstColStyles, 'width', `${rect.width}px`);
          this.$set(this.firstColStyles, 'position', 'absolute');

          this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
        }
      });
    },
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    reportSubTitle() {
      const vm = this;
      const start = moment(vm.report.start_at).format('MMM D, YYYY');
      const end = moment(vm.report.end_at).format('MMM D, YYYY');
      return `${start} to ${end}`;
    },
    practiceObjectFor(wrestler, practice) {
      const vm = this;
      const checkIn = this.getCheckInFor(wrestler, practice);

      return {
        status: _.get(checkIn, 'status', 'unknown'),
        notes: vm.noteForPractice(wrestler, practice),
      };
    },
    practiceObjectToString(pObject) {
      return _.get(pObject, 'status');
    },
    formattedStart(practice) {
      return `${practice.name} ${moment(practice.start_at).format('M/D/YY hh:mm a')}`;
    },
    htmlForStatus(status) {
      switch (status) {
        case 'present':
          return "<i class='fa fa-check text-primary'></i>";
        case 'excused':
          return 'Ex';
        case 'unexcused':
          return "<span class='text-danger'>Ux</span>";
        case 'late':
          return "<span class='text-warning'>La</span>";
        case 'injured':
          return 'In';
        case 'Other':
          return 'Ot';
        default:
          return "<i class='fa fa-question'></i>";
      }
    },
    noteForPractice(wrestler, practice) {
      const checkIn = this.getCheckInFor(wrestler, practice);

      return _.get(checkIn, 'notes', null);
    },
    getCheckInFor(wrestler, practice) {
      const vm = this;
      return _.find(vm.checkIns, {
        wrestler_profile_id: wrestler.id,
        event_id: practice.id,
      });
    },
    isWrestlerInPractices(wrestler, practiceIds) {
      const vm = this;
      const checkins = _.filter(vm.checkIns, (ch) => ch.wrestler_profile_id === wrestler.id && _.includes(practiceIds, ch.event_id));
      return checkins.length > 0;
    },
    wrestlersInPractices(practices) {
      const vm = this;
      const practiceIds = _.map(practices, 'id');
      const wrestlers = [];
      vm.wrestlers.forEach((w) => {
        if (vm.isWrestlerInPractices(w, practiceIds)) {
          wrestlers.push(w);
        }
      });

      return wrestlers;
    },
    practicesInSession(sessionId) {
      const vm = this;
      return _.filter(vm.practices, ['paid_session.id', sessionId]);
    },
    countOfAttended(wrestler, practiceIds) {
      const vm = this;
      const func = function (ch) {
        return ch.wrestler_profile_id === wrestler.id && _.includes(practiceIds, ch.event_id) && ch.status === 'present';
      };
      const checkIns = _.uniqBy(_.filter(vm.checkIns, func), 'event_id');
      return checkIns.length;
    },
    downloadCSV() {
      const vm = this;

      const rows = [];
      rows.push(_.concat([
        'Last name',
        'First name',
        'Practices attended',
      ], _.flatMap(vm.practicesForCols, vm.formattedStart)));

      vm.rows.forEach((row) => {
        rows.push(_.concat([
          row.last_name,
          row.first_name,
          row.attended_count,
        ], _.flatMap(row.practices, 'status')));
      });

      const fileName = `${vm.report.name} ${vm.report.result.roster_name} ${vm.reportSubTitle()}.csv`;
      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
