<!-- A Vue component that allows admins to manually add a kid to a registration -->
<template>
  <div class="ibox">
    <div class="ibox-content">
      <div class="p-m">
        <h1 class="font-heavy">
          Register Wrestler(s) For {{ paidSession.name }}
        </h1>
        <h2 class="font-heavy m-t-lg">
          Select Wrestlers
        </h2>
        <search
            class="search-form"
            response-key="wrestlers"
            placeholder="Type wrestler name..."
            :mutate-history="false"
            :allow-add="false"
            readable-model-name="wrestler"
            :include-guests="true"
            :display-all-on-empty="false"
            base-url="/api/v1/wrestlers">
          <template v-slot:list="slotProps">
            <div class="wrestler-wrapper" @click="wrestlerClicked(slotProps.result)">
              <wrestler-search-result
                  v-bind:result="slotProps.result"
                  v-bind:is-selectable="true"
                  v-bind:key="slotProps.result.id"
              >
              </wrestler-search-result>
            </div>
          </template>
        </search>
        <div>
          <h2 class="font-heavy m-b-xs m-t-md">
            Selected Wrestlers
          </h2>
          <p class="text-12">Tap to open wrestler details in a new tab to confirm you have the right wrestler.</p>
          <div v-if="!hasSelectedWrestlers">
            <p>
              No wrestlers selected
            </p>
          </div>
          <div v-else>
            <div v-for="wrestler in selectedWrestlers" :key="wrestler.id">
              <wrestler-search-result
                  :result="wrestler"
                  :is-selectable="false"
                  :key="wrestler.id"
                  :blank-target="true">
              </wrestler-search-result>
            </div>
          </div>
        </div>
        <div>
          <h2 class="font-heavy m-b-xs m-t-md">
            Payment Option
          </h2>
          <p class="text-12"><strong>NOTE:</strong> No money will be charged. The payment option choice is for your internal tracking and analytics, if you use multiple price points.</p>
          <select v-model="paymentOptionId" class="form-control" name="scholarship_code">
            <option v-for="option in paidSession.payment_options" :value="option.id">
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="alert alert-danger m-t-lg">
          <h2 class="font-heavy m-t-xs m-b-xs">
            <i class="fa fa-exclamation-triangle"></i>
            Please read carefully
          </h2>
          <p>
            You are about to add wrestlers manually to {{ paidSession.name }}. Wrestlers must already exist inside WIQ in order to do this; however, please note that:
          </p>
          <ul class="m-t-xs m-l-none p-l-m">
            <li>It is your responsibility to ensure the wrestlers have all liability forms signed by a parent/guardian.</li>
            <li>If your team uses USA Wrestling, AAU, or other card based insurance, this process will skip those verification steps, which could nullify your insurance coverage.</li>
            <li>No money will be charged for this transaction. A free registration, paid in cash, will show up in your payment dashboard.</li>
            <li>Your name will recorded and listed as the registrant for this wrestler under the 'Billed To', column in registration reports.</li>
          </ul>
          <p class="m-t-sm">
            If you are comfortable with those caveats, please check the box below and click "Register Wrestlers" to complete the process. If you have questions, please reach out to support@wrestlingiq.com for help.
          </p>
          <div class="m-t-md flex align-items-start gap-x-xs font-heavy">
            <input type="checkbox" id="agree_checkbox" v-model="agreedToTerms" data-cy="agreement-check"> I understand
            <div v-if="agreeError">
              <small class="text-danger">
                Please read and check the box to add these wrestlers.
              </small>
            </div>
          </div>
        </div>
        <ladda-button
            el-class="btn btn-primary"
            :loading="submitting"
            @lbClicked="createRegistration"
            data-cy="add-wrestlers-button"
            :disabled="!agreedToTerms || !hasSelectedWrestlers || !paymentOptionId">
          Register Wrestlers
        </ladda-button>
        <p class="text-danger m-t-sm" v-if="error">
          {{ errorMessage }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import WrestlerSearchResult from '../../shared/wrestler_search_result.vue';
import Search from '../../shared/search.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'registration-form',
  components: {
    LaddaButton,
    Search,
    WrestlerSearchResult
  },
  mixins: [errorableMix],
  props: {
    // The paid session we will be registering with
    paidSession: {
      type: Object,
      default() { return {}; },
    },
    // The internal, 100% off coupon to apply
    coupon: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      // The form data. We will replace registrations with the selected wrestlers
      params: {
        payment_method_type: 'cash',
        payment_method_id: null,
        payment_plan: {
          registrations: [],
          coupon: this.coupon.code,
          donation_amount: 0,
          insurance_quotes: [],
          billing_plans_added: [],
        },
      },
      // The selected payment option
      paymentOptionId: null,
      // Key value of id, wrestler objects
      selectedWrestlers: {},
      // Whether the form is being submitted
      submitting: false,
      agreedToTerms: false,
      agreeError: false,
    };
  },
  mounted() {
    if (this.paidSession.payment_options.length === 1) {
      this.paymentOptionId = this.paidSession.payment_options[0].id;
    }
  },
  computed: {
    hasSelectedWrestlers() {
      return Object.keys(this.selectedWrestlers).length > 0;
    }
  },
  methods: {
    // When a wrestler is clicked, add them to the registration
    wrestlerClicked(wrestler) {
      this.$set(this.selectedWrestlers, wrestler.id, wrestler);
      this.$notificationManager.$emit('profile-clicked');
    },
    createRegistration: _.throttle(function () {
      if (!this.paymentOptionId) {
        this.errorMessage = 'Please select a payment option';
        this.error = true;
        return;
      }

      if (!this.hasSelectedWrestlers) {
        this.errorMessage = 'Please select at least one wrestler';
        this.error = true;
        return;
      }

      if (this.submitting) {
        return;
      }

      this.submitting = true;
      this.error = false;

      const url = this.$apiService.paymentPlansUrl();
      this.params.payment_plan.registrations = _.map(this.selectedWrestlers, (wrestler) => {
        return {
          payment_option_id: this.paymentOptionId,
          registerable: {
            id: wrestler.id,
            type: "WrestlerProfile",
          }
        };
      });

      axios.post(url, this.params)
          .then((response) => {
            this.$notificationManager.$emit(
                'show-toast',
                'Registration completed',
                true
            );
            this.submitting = false;
            window.location.assign(`/paid_sessions/${this.paidSession.id}`);
          })
          .catch((error) => {
            this.errorMessage = this.getErrorMessage(error);
            this.error = true;
            this.submitting = false;
          });
    }, 500),
  }
};
</script>
