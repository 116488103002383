<template>
    <div class="match-prefiller">
        <template v-if="showingEventForm">
            <p>Find an event to prefill into tagged match uploads:</p>
            <search
                    readable-model-name="event"
                    :allow-add="allowEventCreate"
                    only-dismiss-model="Event"
                    :mutate-history="false"
                    class="search-form"
                    response-key="events"
                    placeholder="Type event name..."
                    results-container-classes="m-t-xs"
                    base-url="/api/v1/events"
            >
                <template v-slot:list="slotProps">
                    <div class="event-wrapper cursor-pointer" @click="eventClicked(slotProps.result)">
                        <event-search-result
                                v-bind:result="slotProps.result"
                                v-bind:key="slotProps.result.id"
                                v-bind:has-href="false"
                        >
                        </event-search-result>
                    </div>
                </template>
                <template v-slot:addform="slotProps">
                    <event-create
                            :for-calendar="false"
                            v-bind:name="slotProps.eventName"
                            :paid-session-tagging="false"
                    >
                    </event-create>
                </template>
            </search>
            <button type="button" @click="showingEventForm = false" class="btn btn-white m-t-md">Cancel</button>
        </template>
        <template v-if="showingWrestlerForm">
            <p>Find a wrestler to prefill into tagged match uploads:</p>
            <search
                class="search-form"
                response-key="wrestlers"
                placeholder="Type wrestler name..."
                :mutate-history="false"
                :allow-add="false"
                readable-model-name="wrestler"
                base-url="/api/v1/wrestlers">
              <template v-slot:list="slotProps">
                <div class="wrestler-wrapper" @click="wrestlerClicked(slotProps.result)">
                  <wrestler-search-result
                      v-bind:result="slotProps.result"
                      v-bind:is-selectable="true"
                      v-bind:key="slotProps.result.id"
                  >
                  </wrestler-search-result>
                </div>
              </template>
            </search>
            <button type="button" @click="showingWrestlerForm = false" class="btn btn-white m-t-md">Cancel</button>
        </template>
        <template v-else>
            <div v-if="!showingEventForm && !showingWrestlerForm">
              <div class="m-b-sm">
                <a v-if="!event" class="btn btn-default" @click="showingEventForm = true"><i class="fa fa-bolt m-r-xxs"></i>Prefill Event</a>
                <a v-if="!wrestler" class="btn btn-default" @click="showingWrestlerForm = true"><i class="fa fa-bolt m-r-xxs"></i>Prefill Wrestler</a>
              </div>
            </div>
            <div v-if="event" class="flex align-items-center gap-x-sm">
                <p class="m-b-none"><strong>Prefilled Event:</strong> {{ event.name }}</p>
                <a class="btn btn-default btn-compact flex justify-content-center align-items-center" @click.prevent="resetEvent()"><i class="fa fa-trash m-none"></i></a>
            </div>
            <div v-if="wrestler" class="flex align-items-center gap-x-sm">
                <p class="m-b-none"><strong>Prefilled Wrestler:</strong> {{ wrestler.full_name }}</p>
                <a class="btn btn-default btn-compact flex justify-content-center align-items-center" @click.prevent="resetWrestler()"><i class="fa fa-trash m-none"></i></a>
            </div>
        </template>
    </div>
</template>

<script>
import EventSearchResult from './event_search_result.vue';
import EventCreate from './event_create.vue';
import Search from '../../shared/search.vue';
import WrestlerSearchResult from '../../shared/wrestler_search_result.vue'

export default {
  components: {
    Search,
    EventCreate,
    EventSearchResult,
    WrestlerSearchResult,
  },
  name: 'match-prefiller',
  props: {
    prefilledWrestler: {
      type: Object,
      default: null,
    },
    allowEventCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      event: null,
      wrestler: null,
      showingEventForm: false,
      showingWrestlerForm: false,
    };
  },
  created() {
    const vm = this;
    if (vm.prefilledWrestler) {
      vm.wrestlerClicked(vm.prefilledWrestler);
    }
    vm.$notificationManager.$on('event-created', vm.eventCreated);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('event-created', vm.eventCreated);
  },
  methods: {
    eventCreated(event) {
      const vm = this;
      if (vm.showingEventForm) {
        vm.eventClicked(event);
      }
    },
    resetEvent() {
      const vm = this;
      vm.event = null;
      vm.$notificationManager.$data.store.clearPrefilledEvent();
      vm.$notificationManager.$emit('prefilled-event-changed', null);
    },
    eventClicked(event) {
      const vm = this;
      if (event.event_type === 'scramble') {
        this.$notificationManager.$emit('show-toast', 'Error: scramble events cannot currently be tagged in videos.', false);
        return;
      }
      vm.event = event;
      vm.showingEventForm = false;
      vm.$notificationManager.$data.store.setPrefilledEvent(event);
      vm.$notificationManager.$emit('prefilled-event-changed', event);
    },
    wrestlerClicked(wrestler) {
      const vm = this;
      vm.wrestler = wrestler;
      vm.showingWrestlerForm = false;
      vm.$notificationManager.$data.store.setPrefilledWrestler(wrestler);
      vm.$notificationManager.$emit('prefilled-wrestler-changed', wrestler);
    },
    resetWrestler() {
      const vm = this;
      vm.wrestler = null;
      vm.$notificationManager.$data.store.clearPrefilledWrestler();
      vm.$notificationManager.$emit('prefilled-wrestler-changed', null);
    },
  },
};
</script>
