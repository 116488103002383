export const paymentOptionsMix = {
  data() {
    return {
      showCouponForm: false,
      previouslyTriedCouponCode: '',
      couponCode: '',
      selectedPaymentOptionId: null, // non-recurring
      pendingPlan: null,
      registrations: [],
      invoices: [],
      donationAmount: null,
    };
  },
  computed: {
    cashSelected() {
      return this.paymentMethod === 'cash';
    },
    creditSelected() {
      return !this.cashSelected;
    },
    donationAdded() {
      return this.donationAmount != null;
    },
    validCouponApplied() {
      const vm = this;
      if (vm.invoices.length === 0) {
        return false;
      }

      const invoiceItems = _.flatMap(vm.invoices, 'invoice_items');
      const couponItems = _.filter(invoiceItems, ['transactable.code', vm.couponCode]);
      return couponItems.length > 0;
    },
    free() {
      const vm = this;

      if (!vm.pendingPlan) {
        return false;
      }

      let total = 0;

      vm.invoices.forEach((mi) => {
        total += mi.amount_due;
      });

      return total === 0;
    },
    installmentsPicked() {
      const vm = this;
      if (!vm.selectedPaymentOption) {
        return false;
      }

      return vm.selectedPaymentOption.payment_strategy === 'installments';
    },
    activePaymentOptions() {
      return _.filter(this.session.payment_options, ['status', 'active']);
    },
    selectedPaymentOption() {
      if (this.selectedPaymentOptionId) {
        return _.find(this.activePaymentOptions, ['id', this.selectedPaymentOptionId]);
      }

      return null;
    },
  },
  methods: {
    allowsCash() {
      const vm = this;
      return vm.selectedPaymentOption && vm.selectedPaymentOption.allowed_payment_methods === 'all';
    },
    cashTapped() {
      this.paymentMethod = 'cash';
    },
    creditTapped() {
      this.paymentMethod = 'card';
    },
    toggleShowCoupon() {
      this.showCouponForm = !this.showCouponForm;
    },
    removeCoupon() {
      this.previouslyTriedCouponCode = '';
      this.couponCode = '';
      this.showCouponForm = true;
      this.previewCharge(this.previewUrl);
    },
    selectOption(option) {
      const vm = this;
      vm.selectedPaymentOptionId = option.id;
      if (vm.selectedPaymentOption) {
        vm.previewCharge(this.previewUrl);
      }
    },
    optionDescription(option) {
      if (option.payment_strategy === 'once') {
        return '<p>One time charge, due now.</p>';
      }

      if (option.allowed_payment_methods === 'card') {
        return `<p>Payment will be automatically charged to your card on the following dates.</p>`;
      }

      return `<p>This is an installment based option, payment is due on the following dates:</p>`;
    },
    futureInstallmentListFor(option) {
      const installmentList = _.map(option.installment_plan.installments, (i) => {
        const chargeDate = moment(i.date);
        if (this.isDateSameOrBeforeToday(chargeDate)) {
          return '';
        }

        const price = (i.price / 100.00).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return `<div>Due ${chargeDate.format('M/D/YYYY')}: ${price}</div>`;
      });

      return _.join(installmentList, '');
    },
    dueNowFor(option) {
      if (option.payment_strategy !== 'installments') {
        return 0;
      }

      const installmentTotalNow = _.sum(_.map(option.installment_plan.installments, (i) => {
        const chargeDate = moment(i.date);
        if (this.isDateSameOrBeforeToday(chargeDate)) {
          return i.price;
        }

        return 0;
      }));

      return (installmentTotalNow / 100.00).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    isDateSameOrBeforeToday(date) {
      const today = moment();
      return date.isSameOrBefore(today);
    },
    due(invoice) {
      const chargeDate = moment(invoice.due_on);
      if (this.isDateSameOrBeforeToday(chargeDate)) {
        return 'Due now';
      }

      return `Charged ${chargeDate.format('M/D/YYYY')}`;
    },
    installmentPaymentDueNow() {
      const vm = this;

      if (!vm.installmentsPicked || !vm.pendingPlan) {
        return false;
      }

      // Now we are in install land
      let dueNow = false;
      vm.pendingPlan.manual_invoices.forEach((mi) => {
        const dueDate = moment(mi.due_on);
        const isTodayOrPast = vm.isDateSameOrBeforeToday(dueDate);
        if (isTodayOrPast) {
          dueNow = true;
        }
      });

      return dueNow;
    },
    installmentTotalDueNow(plusInsuranceTotal) {
      const vm = this;

      if (!vm.pendingPlan) {
        return '--';
      }

      let total = 0;

      vm.pendingPlan.manual_invoices.forEach((mi) => {
        const dueDate = moment(mi.due_on);
        const isTodayOrPast = vm.isDateSameOrBeforeToday(dueDate);
        if (isTodayOrPast) {
          total += mi.amount_due;
        }
      });

      return vm.formatAsCurrency(total + plusInsuranceTotal);
    },
    total(plusInsuranceTotal) {
      const vm = this;

      if (!vm.pendingPlan) {
        return '--';
      }

      let total = 0;

      vm.pendingPlan.manual_invoices.forEach((mi) => {
        total += mi.amount_due;
      });

      return vm.formatAsCurrency(total + plusInsuranceTotal);
    },
    attemptCoupon() {
        this.previewCharge(this.previewUrl);
    },
    previewCharge(url) {
      const vm = this;
      vm.loadingPreview = true;

      // Make sure we note what the coupon was for the actual request at the time.
      const previousCoupon = vm.couponCode;

      const params = {
        payment_plan: {
          registrations: vm.buildRegistrationPayload(),
          coupon: vm.couponCode,
          donation_amount: this.donationAmount || 0,
          payment_method_type: this.paymentMethod,
          billing_plans_added: vm.buildProductPayload(),
        },
      };

      axios.post(url, params)
        .then((response) => {
          vm.pendingPlan = response.data;
          vm.registrations = vm.pendingPlan.registrations;
          vm.invoices = vm.pendingPlan.manual_invoices;
          vm.previouslyTriedCouponCode = previousCoupon;
          vm.$forceNextTick(() => {
            vm.loadingPreview = false;
          });
        })
        .catch((error) => {
          vm.errorMessage = `Error retrieving price ${error.toString()}`;
          vm.error = true;
          vm.loadingPreview = false;
        });
    },
  },
};
