<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="row">
        <div class="col-xs-6 col-md-8">
          <h1>
            {{ reportName() }}
          </h1>
          <p class="m-b-none"><small>
            Report generated on {{ processedAt() }}
          </small></p>
        </div>
        <div class="col-xs-6 col-md-4 text-right">
          <div class="ibox-tools">
            <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span class="hidden-xs">Download CSV</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th ref="emailHeader" class="first-col" v-bind:style="firstColStyles">Email</th>
              <th v-bind:style="secondColStyles">Amount</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Paid at</th>
              <th>Source</th>
              <th>Receipt</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in rows">
              <td class="first-col"
                  v-bind:style="firstColStyles">
                {{ row.email }}
              </td>
              <td v-bind:style="secondColStyles">
                {{ row.amount }}
              </td>
              <td>
                {{ row.first_name }}
              </td>
              <td>
                {{ row.last_name }}
              </td>
              <td>
                {{ row.paid_at }}
              </td>
              <td>
                {{ row.source }}
              </td>
              <td>
                <a target="_blank" :href="row.receipt"><i class="fa fa-file-text-o"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';

export default {
  name: 'donation-transaction-report',
  mixins: [downloadMix, formattingMixin],
  props: [
    'report',
  ],
  data() {
    return {
      rows: [],
      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  mounted() {
    const vm = this;

    vm.rows = [];

    // Parse the manual invoices which are one off donations from various sources
    const invoices = _.get(vm.report, 'result.manual_invoices.objects', null);
    invoices.forEach((invoice) => {
      const email = _.get(invoice, 'billing_profile.email', '');
      const names = (_.get(invoice, 'billing_profile.billable.display_name', 'Unknown Unknown') ?? "Unknown Unknown").split(' ');

      const first_name = names.shift();
      const last_name = names.join(' ');
      const amount = vm.amount(invoice);
      const paid_at = vm.formatDate(invoice.paid_at);
      const source = vm.source(invoice);
      const receipt = _.get(invoice, 'receipt.cloudfront_url');
      vm.rows.push({
        email,
        amount,
        first_name,
        last_name,
        paid_at,
        source,
        receipt,
      });
    });

    const invoice_payments = _.get(vm.report, 'result.invoice_payments.objects', null);
    invoice_payments.forEach((ip) => {
      const email = _.get(ip, 'billing_subscription.billing_profile.email', '');
      const names = (_.get(ip, 'billing_subscription.billing_profile.billable.display_name', 'Unknown Unknown') ?? "Unknown Unknown").split(' ');
      const first_name = names.shift();
      const last_name = names.join(' ');
      const amount = vm.formatAsCurrency(ip.amount_paid);
      const paid_at = vm.formatDate(ip.created_at);
      const stripeId = ip.billing_subscription.billing_partner_id;
      const source = `Recurring subscription ${stripeId}`;
      const receipt = _.get(ip, 'receipt.cloudfront_url');
      vm.rows.push({
        email,
        amount,
        first_name,
        last_name,
        paid_at,
        source,
        receipt,
      });
    });

    vm.rows = _.sortBy(vm.rows, ['paid_at']);

    vm.$nextTick(function () {
      const header = this.$refs.emailHeader;
      if (header) {
        const rect = header.getBoundingClientRect();

        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    amount(forInvoice) {
      let donationAmount = 0;

      for (const item of forInvoice.invoice_items) {
        if (['Donation', 'Contribution'].some(el => item.description.indexOf(el) > -1)) {
          donationAmount += item.amount;
        }
      }

      return this.formatAsCurrency(donationAmount);
    },
    source(invoice) {
      const invoiceType = invoice.invoice_type;
      if (invoiceType === 'order') {
        return 'Donated online via WIQ donation form';
      }

      if (invoiceType === 'payment_plan') {
        return `Added to ${_.lowerFirst(invoice.description)}`;
      }

      return 'Unknown source';
    },
    reportName() {
      const start = moment(this.report.start_at).format('MMM D, YYYY');
      const end = moment(this.report.end_at).format('MMM D, YYYY');

      return `${this.report.name}, ${start} to ${end}`;
    },
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    formatDate(date) {
      return moment(date).format('M/D/YYYY');
    },
    downloadCSV() {
      const vm = this;
      const fileName = `${vm.reportName()} - ${vm.processedAt()}.csv`;
      const rows = [];
      rows.push([
        'Email',
        'Amount',
        'First name',
        'Last name',
        'Paid at',
        'Source',
        'Receipt',
      ]);

      vm.rows.forEach((row) => {
        rows.push([
          row.email,
          row.amount,
          row.first_name,
          row.last_name,
          row.paid_at,
          row.source,
          row.receipt,
        ]);
      });

      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
