<template>
  <div class="gap-4 grid grid-cols-3 sm-grid-cols-2">
    <div>
      <h2 class="dashboard-stat-label">
        Available passes
      </h2>
      <div class="dashboard-stat-value">
        {{ loadingAvailablePasses ? '—' : available }}
      </div>
    </div>
    <div>
      <h2 class="dashboard-stat-label">
        Used passes
      </h2>
      <div class="dashboard-stat-value">
        {{ loadingAvailablePasses ? '—' : used }}
      </div>
    </div>
    <div class="sm-col-span-2">
      <div class="flex flex-col gap-y-xxs" v-if="!showAddForm && !showUseForm && !loadingAvailablePasses">
        <button @click.prevent="showUseForm = true" class="btn btn-default" :disabled="available === 0"><i class="fa fa-calendar-check-o"></i> Use pass</button>
        <button @click.prevent="showAddForm = true" class="btn btn-default"><i class="fa fa-plus"></i> Add pass</button>
      </div>
      <spinner v-if="loadingAvailablePasses"></spinner>
      <div v-if="showAddForm">
        <div class="archive-form should-dim">
          <div class="text-semi">
            Give a free drop in pass to {{ wrestlerName }}? <strong>Note that this will not charge the parent or wrestler</strong>.
          </div>
          <div class="form-group m-t-sm">
            <label class="font-normal">Which drop in registration should this pass be tied to?</label>
            <select v-model="paidSessionId" >
              <option v-for="session in availableDropInSessions" v-bind:value="session.id">
                {{ session.name }}
              </option>
            </select>
          </div>
          <div class="m-t-sm">
            <button v-on:click="hideForms" class="btn btn-default">Cancel</button>
            <ladda-button @lbClicked="addPass" el-class="btn-primary" :loading="loading">
              Add pass
            </ladda-button>
          </div>
        </div>
      </div>
      <div v-if="showUseForm">
        <div class="archive-form should-dim">
          <div class="text-semi">
            Use a drop in pass for {{ wrestlerName }}?
          </div>
          <div class="m-t-sm">
            <button v-on:click="hideForms" class="btn btn-default">Cancel</button>
            <ladda-button @lbClicked="usePass" el-class="btn-primary" :loading="loading">
              Use pass
            </ladda-button>
          </div>
        </div>
      </div>
      <p v-if="error" class="text-danger">{{ errorMessage }}</p>
    </div>
  </div>
</template>
<script>
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'class-pass-manager',
  components: { LaddaButton, Spinner },
  props: {
    availableCount: {
      type: Number,
      required: true,
    },
    usedCount: {
      type: Number,
      required: true,
    },
    wrestlerId: {
      type: Number,
      required: true,
    },
    wrestlerName: {
      type: String,
      required: true,
    },
    adminName: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      available: 0,
      used: 0,
      showUseForm: false,
      showAddForm: false,
      error: false,
      errorMessage: '',
      loading: false,
      loadingAvailablePasses: false,
      loadingAvailableSessions: false,
      availablePasses: [],
      paidSessionId: null,
      availableDropInSessions: [],
    }
  },
  created() {
    this.available = this.availableCount;
    this.used = this.usedCount;
    this.loadAvailablePasses();
    this.loadAvailableSessions();
  },
  methods: {
    loadAvailablePasses() {
      const vm = this;
      vm.loadingAvailablePasses = true;

      const url = vm.$apiService.availableWrestlerClassPasses(vm.wrestlerId)
      vm.$apiService.loadAllPages(url, 'class_passes')
          .then((class_passes) => {
            vm.availablePasses = class_passes;
            vm.loadingAvailablePasses = false;
            vm.available = vm.availablePasses.length;
          })
          .catch((error) => {
            vm.loadingAvailablePasses = false;
            vm.errorMessage = `Error retrieving available passes ${error.toString()}`;
            vm.error = true;
          });
    },
    loadAvailableSessions() {
      const vm = this;
      vm.loadingAvailableSessions = true;
      const url = `${vm.$apiService.paidSessionsUrl()}?type=trial_or_dropin`;
      vm.$apiService.loadAllPages(url, 'paid_sessions')
          .then((paid_sessions) => {
            vm.availableDropInSessions = paid_sessions;
            if (vm.availableDropInSessions.length > 0) {
              vm.paidSessionId = _.head(vm.availableDropInSessions).id;
            }
            vm.loadingAvailableSessions = false;
          })
          .catch((error) => {
            vm.errorMessage = `Error retrieving available drop in sessions ${error.toString()}`;
            vm.error = true;
          });
    },
    hideForms() {
      this.showAddForm = false;
      this.showUseForm = false;
      this.error = false;
    },
    addPass() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      const tz = moment.tz.guess();
      const params = {
        class_pass: {
          status: 'available',
          log: `Free pass added to ${this.wrestlerName} by ${this.adminName} at ${moment().tz(tz).format('M/D/YYYY hh:mm a')}`,
          paid_session_id: this.paidSessionId,
        }
      }
      const url = this.$apiService.wrestlerClassPasses(this.wrestlerId)
      axios.post(url, params)
          .then((response) => {
            this.$notificationManager.$emit('show-toast', 'Pass added', true);
            this.$notificationManager.$emit('class-pass-changed');
            this.loading = false;
            this.hideForms();
            this.loadAvailablePasses();
          })
          .catch((error) => {
            this.error = true;
            this.errorMessage = `Error adding pass ${error.toString()}`;
            this.loading = false;
          });
    },
    usePass() {
      const pass = _.head(this.availablePasses);
      if (this.loading || !pass) {
        return;
      }

      this.loading = true;

      const tz = moment.tz.guess();
      const params = {
        class_pass: {
          status: 'used',
          log: `${pass.log} Pass for ${this.wrestlerName} redeemed by ${this.adminName} at ${moment().tz(tz).format('M/D/YYYY hh:mm a')}`
        }
      }
      const url = this.$apiService.wrestlerClassPass(this.wrestlerId, pass.id)
      axios.put(url, params)
          .then((response) => {
            this.$notificationManager.$emit('show-toast', 'Pass used', true);
            this.$notificationManager.$emit('class-pass-changed');
            this.loading = false;
            this.hideForms();
            this.loadAvailablePasses();
            this.used = this.used + 1;
          })
          .catch((error) => {
            this.error = true;
            this.errorMessage = `Error using pass ${error.toString()}`;
            this.loading = false;
          });
    }
  }
};
</script>
