<template>
  <div>
    <form role="form" id="payment-form">
      <div class="mt-2">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit } ">
          <div id="billing-info" class="">
            <h2 class="text-18 font-semi">Billing Info</h2>
            <div>
              <ValidationProvider name="name on card" rules="required" v-slot="{ errors }">
                <div class="form-group">
                  <label for="name_on_card" class="font-normal">Name on card</label>
                  <input type="text"
                         autocomplete="cc-name"
                         name="name on card"
                         id="name_on_card"
                         v-model="nameOnCard"
                         :class="inputClasses(errors[0])">
                  <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                    {{ errors[0] }}
                  </p>
                </div>
              </ValidationProvider>
              <ValidationProvider name="address line 1" rules="required" v-slot="{ errors }">
                <div class="form-group">
                  <label for="location-input" class="font-normal">
                    Address line 1
                  </label>
                  <input autocomplete="address-line1" v-model="addressLine1" id="location-input" type="text"
                         :class="inputClasses(errors[0])">
                  <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                    {{ errors[0] }}
                  </p>
                </div>
              </ValidationProvider>
              <div class="form-group">
                <label for="address_2" class="font-normal">
                  Address line 2 (optional)
                </label>
                <input autocomplete="address-line2" v-model="addressLine2" id="address_2" type="text"
                       class="form-control">
              </div>
              <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                <div class="form-group">
                  <label for="locality-input" class="font-normal">
                    City
                  </label>
                  <input autocomplete="address-level2" v-model="city" id="locality-input" type="text" ref="city"
                         :class="inputClasses(errors[0])">
                  <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                    {{ errors[0] }}
                  </p>
                </div>
              </ValidationProvider>
              <div class="form-group">
                <div class="row">
                  <div class="col-xs-12 col-sm-6 m-b-only-xs-m">
                    <ValidationProvider :name="isUSA ? 'state' : 'region'" rules="required" v-slot="{ errors }">
                      <label :for="isUSA ? 'administrative_area_level_1-input' : 'region-input'" class="font-normal">
                        {{ isUSA ? 'State' : 'Region/Province' }}
                      </label>
                      <the-mask
                          v-if="isUSA"
                          v-model="state"
                          mask="AA"
                          type="text"
                          :class="inputClasses(errors[0])"
                          ref="state"
                          autocomplete="address-level1"
                          id="administrative_area_level_1-input"
                          data-cy="state"
                      ></the-mask>
                      <input
                          v-else
                          v-model="state"
                          type="text"
                          :class="inputClasses(errors[0])"
                          ref="state"
                          autocomplete="address-level1"
                          id="region-input"
                          data-cy="state"
                      >
                      <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                  <div class="col-xs-12 col-sm-6 m-b-only-xs-m">
                    <ValidationProvider :name="isUSA ? 'zip' : 'postal code'" rules="required" v-slot="{ errors }">
                      <label for="postal_code-input" class="font-normal">
                        {{ isUSA ? 'Zip' : 'Postal Code' }}
                      </label>
                      <the-mask
                          v-if="isUSA"
                          v-model="zip"
                          mask="#####"
                          type="text"
                          :class="inputClasses(errors[0])"
                          ref="zip"
                          autocomplete="postal-code"
                          id="postal_code-input"
                          data-cy="zip"
                      ></the-mask>
                      <input
                          v-else
                          v-model="zip"
                          type="text"
                          :class="inputClasses(errors[0])"
                          ref="zip"
                          autocomplete="postal-code"
                          id="postal_code-input"
                          data-cy="zip"
                      >
                      <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                  <div class="form-group">
                    <label for="country-input" class="font-normal">
                      Country
                    </label>
                    <select v-model="country" class="form-control">
                      <option selected disabled></option>
                      <option v-for="country in countries" :value="country.short_name" id="country-input">{{
                          country.long_name
                        }}
                      </option>
                    </select>
                    <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
              <div class="form-group">
                <label class="checkbox-inline">
                  <input type="checkbox" id="save_billing_info" v-model="reusePaymentMethodInFuture"> Save billing &
                  card info for fast future checkout
                </label>
              </div>
            </div>
          </div>
          <h2 class="text-18 font-semi m-t-lg">Card Info</h2>
          <div v-if="useStripe" id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>
          <div v-else-if="useJustifi">
            <div class="flex mt-2 flex-col space-y-4">
              <spinner v-if="justifiLoading"></spinner>
              <justifi-card-form @cardFormReady="justifiLoading = false" validation-mode="onBlur"></justifi-card-form>
            </div>
          </div>
          <ladda-button @lbClicked="handleSubmit(getToken)" id="submit" el-class="btn-primary m-t-lg btn-block"
                        :loading="creatingToken">
            Save
          </ladda-button>
        </ValidationObserver>
        <div id="payment-message" v-if="error">
          <small><span class="text-danger">{{ errorMessage }}</span></small>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { billingPartnerMix } from '../../shared/billing_partner_mix';
import { errorableMix } from './mixins/errorable_mix';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Spinner from './spinner.vue';
import { TheMask } from 'vue-the-mask';

export default {
  name: 'update-cc',
  mixins: [billingPartnerMix, errorableMix],
  components: {
    LaddaButton,
    Spinner,
    ValidationProvider,
    ValidationObserver,
    TheMask,
  },
  props: {
    shouldRedirect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.useStripe) {
      this.$forceNextTick(() => {
        this.setupPaymentElement(this.intentSecret);
      });
    }
  },
  methods: {
    inputClasses(error) {
      const normalClasses = 'form-control';
      if (!error) {
        return normalClasses;
      }

      return `text-danger border-red-500 ${normalClasses}`;
    },
    getToken() {
      this.getPaymentToken(this.nameOnCard, this.email, this.zip);
    },
    tokenized(token) {
      const params = {
        billing_profile: {
          default_payment_method_id: token,
          default_payment_method_reusable: this.reusePaymentMethodInFuture,
          name_on_card: this.nameOnCard,
          address_line_1: this.addressLine1,
          address_line_2: this.addressLine2,
          city: this.city,
          state: this.state,
          zip: this.zip
        },
      };
      this.creatingToken = true;
      const url = this.$apiService.billingProfileUrl(this.billingProfile.id);
      axios.put(url, params)
          .then((response) => {
            this.creatingToken = false;
            this.showUpdatedToast();
          })
          .catch((error) => {
            this.error = true;
            this.errorMessage = `Error updating default card ${error.toString()}`;
            this.creatingToken = false;
          });

    },
    showUpdatedToast() {
      this.$notificationManager.$emit('show-toast', 'Card updated', true);
      if (this.shouldRedirect) {
        setTimeout(function () {
          window.location.assign('/settings?card_updated=true');
        }, 2000);
      } else {
        this.$emit('card-updated');
      }
    }
  },
};
</script>
