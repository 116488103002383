<template>
    <div class="ibox">
        <div class="ibox-title">
            <div class="row">
                <div class="col-xs-6 col-md-8">
                    <h1>
                        {{ report.result.roster_name }} Roster Report
                    </h1>
                    <p class="m-b-none"><small>
                        Report generated on {{ processedAt() }}
                    </small></p>
                </div>
                <div class="col-xs-6 col-md-4 text-right">
                    <div class="ibox-tools">
                        <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span class="hidden-xs">Download CSV</span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="ibox-content">
            <div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                            <th v-bind:style="secondColStyles">First name</th>
                            <th>Weight class</th>
                            <th>Academic class</th>
                            <th>Date of birth</th>
                            <th>Age</th>
                            <th>Experience</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="row in wrestlers">
                            <td class="first-col"
                                v-bind:style="firstColStyles"
                            >
                                {{ row.last_name }}
                            </td>
                            <td v-bind:style="secondColStyles">{{ row.first_name }}</td>
                            <td>{{ row.weight_class }}</td>
                            <td>{{ row.academic_class }}</td>
                            <td>{{ row.dob }}</td>
                            <td>{{ row.age }}</td>
                            <td>{{ row.experience }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { downloadMix } from '../mixins/download_mix';

export default {
  name: 'roster-report',
  mixins: [downloadMix],
  props: [
    'report',
  ],
  data() {
    return {
      wrestlers: [],

      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  mounted() {
    const vm = this;

    const wrestlers = _.get(vm.report, 'result.wrestler_profiles.objects', null);
    vm.wrestlers = _.sortBy(wrestlers, ['last_name']);

    vm.$nextTick(function () {
      const header = this.$refs.lnameHeader;
      if (header) {
        const rect = header.getBoundingClientRect();
        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    downloadCSV() {
      const vm = this;

      const rows = [];
      rows.push([
        'Last name',
        'First name',
        'Weight class',
        'Academic class',
        'Date of birth',
        'Age',
        'Experience',
      ]);

      vm.wrestlers.forEach((wp) => {
        rows.push([
          wp.last_name,
          wp.first_name,
          wp.weight_class,
          wp.academic_class,
          wp.dob,
          wp.age,
          wp.experience,
        ]);
      });

      const fileName = `${vm.report.result.roster_name} Roster Report - ${vm.processedAt()}.csv`;
      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
