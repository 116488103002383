<template>
  <div class="row">
    <div class="col-md-12">
      <div class="ibox">
        <div class="ibox-content p-t-m">
          <div class="row m-t-m">
            <div class="col-md-4">
              <h1 class="form-section-title">Upsell Info</h1>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label for="name">Name</label>
                <input id="name" v-model="upsell.name" type="text" class="form-control">
              </div>
              <div class="form-group">
                <label for="description">Description</label>
                <input id="description" v-model="upsell.description" type="text" class="form-control">
              </div>
              <div class="form-group">
                <label>Price</label>
                <money v-model="upsell.amount" v-bind="money" class="form-control"></money>
              </div>
            </div>
          </div>
          <div class="row m-t-xl">
            <div class="col-md-4">
              <h1 class="form-section-title">Options</h1>
            </div>
            <div class="col-md-8">
              <div class="form-group flex align-items-start">
                <input id="upsell_active" type="checkbox" v-model="upsell.active">
                <div class="m-l-sm">
                  <label for="upsell_active" class="m-b-none">
                    Is the upsell active?
                  </label>
                  <p class="text-gray-600">
                    Uncheck if you want to stop offering this upsell on all products.
                  </p>
                </div>
              </div>
              <div class="form-group flex align-items-start" :class="{'m-b-none': upsell.require_user_input }">
                <input id="user_input" type="checkbox" v-model="upsell.require_user_input">
                <div class="m-l-sm">
                  <label for="reg_limit" class="m-b-none">
                    Require user input after selected?
                  </label>
                  <p class="text-gray-600" :class="{'m-b-xxs': upsell.require_user_input }">
                    E.g. You need to ask for a name on personalization add-on for custom shirts.
                  </p>
                </div>
              </div>
              <div v-if="upsell.require_user_input" class="m-b-md m-l-md m-t-sm">
                <div class="form-group">
                  <label for="input-label">Input label</label>
                  <input id="input-label" v-model="upsell.input_label" type="text" class="form-control">
                </div>
              </div>
              <div class="form-group">
                <label for="vendor-id">Vendor ID (optional)</label>
                <input id="vendor-id" v-model="upsell.vendor_id" type="text" class="form-control">
              </div>
          </div>
        </div>
          <div class="button-area text-right m-t-xl">
            <div>
              <ladda-button @lbClicked="saveUpsell" el-class="btn-primary" :loading="loading">
                Save
              </ladda-button>
              <div v-if="error">
                <small>
                  <span class="text-danger">{{ errorMessage }}</span>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { Money } from 'v-money';
export default {
  name: "upsell-form",
  mixins: [errorableMix],
  components: {
    Money,
    LaddaButton,
    Spinner
  },
  props: {
    teamActiveBillingPartner: {
      type: String,
      required: true,
    },
    existingUpsell: {
      type: Object,
      required: false
    },
    currency: {
      type: String,
      default: 'usd'
    }
  },
  data() {
    return {
      upsell: {
        id: null,
        name: '',
        currency: 'usd',
        description: '',
        amount: 0,
        active: true,
        billing_partner: '',
        require_user_input: false,
        input_label: '',
        vendor_id: '',
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },

      loading: false
    }
  },
  mounted() {
    if (this.existingUpsell) {
      this.upsell = this.existingUpsell;
      this.upsell.amount = this.upsell.amount / 100;
    } else {
      this.upsell.billing_partner = this.teamActiveBillingPartner;
    }
  },
  methods: {
    validate() {
      this.error = false;
      let errors = [];
      if (!this.upsell.name) {
        errors.push('Name is required');
      }
      if (!this.upsell.description) {
        errors.push('Description is required');
      }
      if (!this.upsell.amount) {
        errors.push('Amount is required');
      }
      if (!this.upsell.billing_partner) {
        errors.push('Billing Partner is required');
      }
      if (this.upsell.require_user_input && !this.upsell.input_label) {
        errors.push('Input Label is required');
      }
      if (errors.length > 0) {
        this.errorMessage = errors.join('. ')
        this.error = true;
        return false;
      }

      return true;
    },
    saveUpsell: _.throttle(function() {
        if (this.loading) {
          return;
        }

        if (!this.validate()) {
          return;
        }

        this.loading = true;
        const params = {
          upsell: {
            id: this.upsell.id,
            name: this.upsell.name,
            description: this.upsell.description,
            amount: _.round(this.upsell.amount * 100),
            currency: this.upsell.currency,
            active: this.upsell.active,
            billing_partner: this.upsell.billing_partner,
            require_user_input: this.upsell.require_user_input,
            input_label: this.upsell.input_label,
            vendor_id: this.upsell.vendor_id,
          }
        }

        if (this.upsell.id) {
          this.update(params);
        } else {
          this.create(params);
        }
    }, 500),
    create(params) {
      const url = this.$apiService.upsellsUrl();
      axios.post(url, params)
        .then(response => {
          this.loading = false;
          this.$notificationManager.$emit('show-toast', 'Saved', true);
          window.location.assign('/upsells');
        })
        .catch(error => {
          this.loading = false;
          this.errorMessage = this.parseErrorResponse(error);
          this.error = true;
        });
    },
    update(params) {
      const url = this.$apiService.upsellUrl(this.upsell.id);
      axios.put(url, params)
        .then(response => {
          this.loading = false;
          this.$notificationManager.$emit('show-toast', 'Saved', true);
          window.location.assign('/upsells');
        })
        .catch(error => {
          this.loading = false;
          this.errorMessage = this.parseErrorResponse(error);
          this.error = true;
        });
    }
  }
};
</script>
