<template>
  <span
    class="inline-flex align-items-center gap-x-xxs rounded-xxs font-small"
    :style="{
      color: tag.text_color,
      'background-color': tag.color,
      'padding': '2px 4px'
    }"
  >
    {{ tag.abbreviation }} {{ tagCount}}
  </span>
</template>
<script>
export default {
  name: "tag",
  props: {
    tag: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
      default: 1,
    }
  },
  computed: {
    tagCount() {
      if (this.count <= 1) {
        return ""
      }

      return `x${this.count}`;
    }
  }
};
</script>
