<template>
  <div>
    <div class="grid grid-cols-2 sm-grid-cols-1 md-grid-cols-1 gap-x-lg gap-y-lg">
      <div role="form" class="p-x-m">
        <h1 class="m-t-m m-b-md font-heavy">Invoice</h1>
        <div class="form">
          <div class="form-group">
            <label class="text-18">Recipient</label>
            <template v-if="!recipient">
              <search
                  class="search-form"
                  response-key="results"
                  placeholder="Who are you invoicing..."
                  :mutate-history="false"
                  :allow-add="true"
                  :display-all-on-empty="false"
                  readable-model-name="customer"
                  :billables="true"
                  :small-input="true"
                  base-url="/api/v1/search">
                <template v-slot:list="slotProps">
                  <div class="profile-wrapper" @click.prevent="recipientClicked(slotProps.result)">
                    <generic-search-result
                        v-bind:result="slotProps.result"
                        v-bind:is-selectable="true"
                        v-bind:key="slotProps.result.id">
                    </generic-search-result>
                  </div>
                </template>
                <template v-slot:addform="slotProps">
                  <div class="border border-1 border-solid p-m rounded-sm">
                    <h2 class="m-t-xxs font-semi">New Customer</h2>
                    <p>
                      A 'customer' record in WrestlingIQ does not have access to your team's account. Any payments made by this customer—through this invoice, donations, or online stores—will be tracked to their customer record. If this is an active parent or wrestler, you should add them to your team's account instead.
                    </p>
                    <div role="form">
                      <div class="form-group">
                        <label>Email</label>
                        <input v-model="newCustomer.email" type="email" class="form-control">
                      </div>
                      <div class="form-group">
                        <label>First name</label>
                        <input v-model="newCustomer.firstName" type="email" class="form-control">
                      </div>
                      <div class="form-group">
                        <label>Last name</label>
                        <input v-model="newCustomer.lastName" type="email" class="form-control">
                      </div>
                      <div class="buttonArea">
                        <div v-show="error">
                          <small>
                            <span class="text-danger">{{ errorMessage }}</span>
                          </small>
                        </div>
                        <button v-show="!loading" type="button" v-on:click="cancelCustomerCreate" class="btn btn-white">Cancel</button>
                        <ladda-button @lbClicked="createCustomer" el-class="btn-primary" :loading="loading">
                          Create Customer
                        </ladda-button>
                      </div>
                    </div>
                  </div>
                </template>
              </search>
            </template>
            <template v-else>
              <div class="flex gap-x-m align-items-baseline justify-space-between m-t-sm">
                <div>
                  <p class="m-t-none m-b-none text-16">{{ receipientName }}</p>
                  <p class="text-12 text-gray-600 m-t-none">{{ recipientType }}</p>
                </div>
                <div @click="recipient = null" class="cursor-pointer align-items-center flex gap-x-xxs text-red-500 hover-text-red-300 hover-underline">
                  <i class="fa fa-times text-red-500"></i> <span class="text-12 hidden-xs">Change</span>
                </div>
              </div>
            </template>
          </div>
          <div class="form-group m-t-md">
            <label class="text-18">Payment Collection</label>
            <div class="flex gap-x-m m-t-xs align-items-start">
              <input type="radio" value="autocharge" id="autocharge" v-model="chargeMethod">
              <div>
                <label for="autocharge" class="m-b-none">Autocharge customer</label>
                <p class="m-t-none text-gray-500 text-12">
                  Automatically charge a payment method on file.
                </p>
                <div v-if="canChargeCardOnFile">
                  <div class="flex align-items-center gap-x-sm">
                    <div v-html="cardLogo"></div>
                    <div class="font-heavy">* * * * {{
                        recipientBillingProfile.default_payment_method_last4
                      }}
                    </div>
                    <div class="">
                      {{recipientBillingProfile.default_payment_method_exp_month }} / {{ recipientBillingProfile.default_payment_method_exp_year }}
                    </div>
                    <div @click.prevent="toggleCardModal" class="text-12 text-gray-600 hover-text-gray-500 cursor-pointer">
                      <i class="fa fa-pencil"></i> Change card
                    </div>
                  </div>
                </div>
                <div v-else-if="recipientBillingProfile">
                  <p class="m-y-none">
                    <i class="fa fa-exclamation-triangle text-red-600"></i> Customer has no authorized card on file.
                    <VDropdown theme="info-tooltip">
                      <!-- This will be the popover target (for the events and position) -->
                      <div>Learn more</div>
                      <!-- This will be the content of the popover -->
                      <template v-slot:popper>
                        <div>
                          <p class="header">
                            Why is there no card on file?
                          </p>
                          <p>
                            Customers must explicitly authorize their card for autocharging. Adding a card manually here as an admin will also authorize a card for autocharging.
                            <a class="text-underline" href="https://help.wrestlingiq.com/article/206-why-isnt-a-card-authorized-for-autocharging" target="_blank">Read more about authorization here.</a>
                          </p>
                        </div>
                      </template>
                    </VDropdown>
                  </p>
                  <button @click.prevent="toggleCardModal" class="btn btn-default flex gap-x-xxxs m-t-xxs align-items-baseline">
                    <i class="fa fa-plus m-r-xs"></i> <span>Add Card</span>
                  </button>
                </div>
              </div>
            </div>
            <hr/>
            <div class="flex gap-x-sm align-items-start">
              <input type="radio" value="send" id="send" v-model="chargeMethod">
              <div>
                <label for="send" class="m-b-none">Send invoice</label>
                <p class="m-t-none text-gray-500 text-12">
                  Request payment on a specific date. A payment link will be emailed.
                </p>
              </div>
            </div>
          </div>
          <div class="form-group" v-show="isSending">
            <label>Due on</label>
            <div>
              <date-picker v-model="dueOn" :date="dueOn" :disabled-date="tomorrowOrLater"></date-picker>
            </div>
          </div>
          <div class="form-group" v-show="isSending">
            <label>Memo</label>
            <input v-model="memo" type="text" class="form-control" placeholder="Here is your invoice for xyz thank you for your business!">
          </div>
          <div class="form-group">
            <label class="text-18">Items</label>
            <p>
              Add single, one-time items or products from your <a href="/products" class="text-underline" target="_blank">product catalog <i class="fa fa-external-link"></i></a> to this invoice. Not seeing any products? Create <a href="/products" class="text-underline" target="_blank">one first.</a>
            </p>
            <div class="">
              <div v-for="(item, index) in lineItems">
                <div class="row">
                  <div class="col-xs-12 col-sm-5">
                    <div class="form-group">
                      <div class="flex gap-x-sm">
                        <label class="font-normal">Description</label>
                      </div>
                      <input :id="`description-${index}`" v-model="item.description" type="text" class="form-control" :disabled="itemIsPlanOrCoupon(item)" />
                    </div>
                  </div>
                  <div class="col-xs-6 col-sm-2">
                    <div class="form-group">
                      <label class="font-normal">Quantity</label>
                      <input v-model="item.quantity" type="number" class="form-control"/>
                    </div>
                  </div>
                  <div class="col-xs-6 col-sm-2">
                    <div class="form-group">
                      <label class="font-normal">Unit price</label>
                      <money v-model="item.unitPrice" v-bind="money" class="form-control" :disabled="itemIsPlanOrCoupon(item)"></money>
                    </div>
                  </div>
                  <div class="col-xs-10 col-sm-2">
                    <div class="form-group">
                      <label class="font-normal">Item total</label>
                      <p class="m-t-xxs m-b-none">{{ formatAsCurrency(totalForItem(item)) }}</p>
                    </div>
                  </div>
                  <div class="col-xs-2 col-sm-1">
                    <div class="form-group m-t-md">
                      <label class="font-normal sr-only">Remove</label>
                      <div class="cursor-pointer" @click.prevent="removeItemAt(index)">
                        <i class="fa fa-times text-red-500"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="coupon">
                <div class="row">
                  <div class="col-xs-12 col-sm-5">
                    <div class="form-group">
                      <label class="font-normal">
                        Description
                      </label>
                      <input type="text" class="form-control" :value="couponDescription" disabled/>
                    </div>
                  </div>
                  <div class="col-xs-6 col-sm-2">
                    <div class="form-group">
                      <label class="font-normal">Quantity</label>
                      <input value="1" type="number" class="form-control" disabled />
                    </div>
                  </div>
                  <div class="col-xs-6 col-sm-2">
                    <div class="form-group">
                      <label class="font-normal">Unit price</label>
                      <money v-bind="money" class="form-control" :value="couponAmountOff / 100" disabled></money>
                    </div>
                  </div>
                  <div class="col-xs-10 col-sm-2">
                    <div class="form-group">
                      <label class="font-normal">Item total</label>
                      <p class="m-t-xxs m-b-none">{{ formatAsCurrency(couponAmountOff) }}</p>
                    </div>
                  </div>
                  <div class="col-xs-2 col-sm-1">
                    <div class="form-group">
                      <label class="font-normal sr-only">Remove</label>
                      <div class="m-t-xxs m-b-none cursor-pointer" @click.prevent="couponId = null">
                        <i class="fa fa-times text-red-500"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="wiqFee">
                <div class="row">
                  <div class="col-xs-12 col-sm-5">
                    <div class="form-group">
                      <label class="font-normal">
                        Description
                        <VDropdown theme="info-tooltip">
                          <!-- This will be the popover target (for the events and position) -->
                          <i class="fa fa-question"></i>
                          <!-- This will be the content of the popover -->
                          <template v-slot:popper>
                            <div>
                              <p class="header">
                                What is this fee?
                              </p>
                              <p>
                                With your team's current plan, WrestlingIQ adds a service fee of {{ percentFee * 100 }}% to each transaction. This service fee is paid by the recipient.
                              </p>
                              <p>
                                If you have questions please email support@wrestlingiq.com
                              </p>
                            </div>
                          </template>
                        </VDropdown>
                      </label>
                      <input type="text" class="form-control" value="WrestlingIQ Fee" disabled/>
                    </div>
                  </div>
                  <div class="col-xs-6 col-sm-2">
                    <div class="form-group">
                      <label class="font-normal">Quantity</label>
                      <input value="1" type="number" class="form-control" disabled/>
                    </div>
                  </div>
                  <div class="col-xs-6 col-sm-2">
                    <div class="form-group">
                      <label class="font-normal">Unit price</label>
                      <money v-bind="money" class="form-control" :value="wiqFee / 100" disabled></money>
                    </div>
                  </div>
                  <div class="col-xs-10 col-sm-2">
                    <div class="form-group">
                      <label class="font-normal">Item total</label>
                      <p class="m-t-xxs m-b-none">{{ formatAsCurrency(wiqFee) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="addingFromLibrary">
                <div class="row m-t-xxs">
                  <div class="col-xs-12">
                    <div v-if="pendingProductAdd" class="border border-gray-300 rounded-sm p-sm bg-gray-50">
                      <a class="" @click.prevent="pendingProductAdd = null">
                        <i class="fa fa-chevron-left"></i> Back
                      </a>
                      <p>Which SKU of <strong>{{ pendingProductAdd.name }}</strong> should be added?</p>
                      <div class="flex flex-wrap gap-x-sm gap-y-sm">
                        <div @click="addBillingPlan(plan)" class="cursor-pointer p-m flex flex-col rounded-sm border border-gray-300 bg-white border-solid" v-for="plan in pendingProductAdd.billing_plans">
                          <div class="flex justify-space-between font-semi" style="gap: 8px;">{{ plan.name }} <span v-html="formatAsCurrency(plan.amount)"></span></div>
                          <p class="m-t-xxs m-b-none">{{ plan.color }} {{ plan.size }}</p>
                        </div>
                      </div>
                    </div>
                    <div v-else class="bg-gray-50 p-sm rounded-sm">
                      <div class="m-b-xs">
                        <a class="m-b-sm" @click.prevent="addingFromLibrary = false">
                          <i class="fa fa-chevron-left"></i> Back
                        </a>
                      </div>
                      <filtered-list
                          ui="grid"
                          grid-classes="grid grid-cols-3 sm-grid-cols-1 md-grid-cols-2 gap-sm m-b-sm bg-gray-50"
                          :headers="['Name', 'Category', 'Actions']"
                          :sorts="[
            {
              label: 'Name asc',
              value: 'name asc'
              },
            {
              label: 'Name desc',
              value: 'name desc'
              },
            ]" :filters="[
            {
              label: 'Type',
              attribute: 'product_type',
              type: 'primary',
              predicates:
                [
                  {
                    label: 'Product Library',
                    user_editable: false,
                    default: true,
                    values: {
                      product_type_eq: 'good'
                      }
                    },
                  ]
              },
            {
              label: 'Name',
              predicateLabel: 'Filter by product name',
              attribute: 'name',
              type: 'secondary',
              predicates:
                [
                  {
                    id: 1,
                    label: 'Name contains',
                    type: 'TextContains',
                    user_editable: true,
                    operator: 'or',
                    placeholder: 'Product name',
                    values: {
                      name_cont_any: null,
                      }
                    },
                  ]
              }
            ]"
                          response-key="products"
                          object-type="Product"
                          base-url="/api/v1/products">
                        <template v-slot:row="row">
                          <div @click.prevent="addProductItem(row.data)">
                            <product-list-result :product="row.data" :show-edit="false"></product-list-result>
                          </div>
                        </template>
                      </filtered-list>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="addingCoupon" class="m-y-m bg-gray-50 rounded-sm p-sm">
                <spinner v-if="couponsLoading"></spinner>
                <div v-if="!couponsLoading">
                  <div>
                    <a class="" @click.prevent="addingCoupon = false">
                      <i class="fa fa-chevron-left"></i> Back
                    </a>
                  </div>
                  <label>Which scholarship code do you want to apply to this invoice?</label>
                  <select v-model="couponId" class="form-control" name="scholarship_code">
                    <option :value="null">No Scholarship Code</option>
                    <option v-for="c in availableCoupons" :value="c.id">
                      {{ c.code }}
                      <template v-if="c.amount_off > 0">
                        ({{ formatAsCurrency(c.amount_off) }})
                      </template>
                      <template v-else>
                        ({{ c.percent_off * 100 }}% off)
                      </template>
                    </option>
                  </select>
                </div>
              </div>
              <div class="grid sm-grid-cols-1 grid-cols-2 gap-x-sm gap-y-sm" v-if="!addingCoupon && !addingFromLibrary">
                <div class="relative border border-solid border-gray-600 rounded-xs p-sm">
                  <p class="absolute text-10 font-semi top-0 left-0 right-0 text-center bg-gray-600 text-gray-50 uppercase tracking-wide p-x-m p-y-xs rounded-t-xs">
                    Items
                  </p>
                  <div class="flex flex-col gap-y-sm m-t-lg">
                    <div class="card-bordered-dashed">
                      <a @click.prevent="addingFromLibrary = true"><i class="fa fa-plus"></i> From Product Library</a>
                    </div>
                    <div class="card-bordered-dashed">
                      <a @click.prevent="addNewItem(false)"><i class="fa fa-plus"></i> One-time Item</a>
                    </div>
                  </div>
                </div>
                <div class="relative border border-solid border-gray-100 rounded-xs p-sm">
                  <p class="absolute text-10 font-semi top-0 left-0 right-0 text-center bg-gray-600 text-gray-50 uppercase tracking-wide p-x-m p-y-xs rounded-t-xs">
                    Discounts
                  </p>
                  <div class="flex flex-col gap-y-sm m-t-lg">
                    <div class="card-bordered-dashed">
                      <a @click.prevent="addingCoupon = true">
                        <i class="fa fa-pencil" v-if="coupon"></i><i class="fa fa-plus" v-if="!coupon"></i> Scholarship Code
                      </a>
                    </div>
                    <div class="card-bordered-dashed">
                      <a @click.prevent="addNewItem(true)"><i class="fa fa-plus"></i> One-time Discount</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group flex align-items-center justify-space-between m-t-lg">
            <label class="text-16 m-b-none-important">Total</label>
            <h3 class="font-heavy m-y-none"> {{ formatAsCurrency(invoiceTotal) }}</h3>
          </div>
          <div class="m-t-lg">
            <div v-if="isSending">
              <ladda-button @lbClicked="createInvoice(false)" el-class="btn-default btn-block text-semi p-y-sm" :loading="loading">
                <i class="fa fa-paper-plane"></i> &nbsp; Send Invoice
              </ladda-button>
              <div class="text-center m-t-xxs">
                <small>A payment link will be emailed.</small>
              </div>
            </div>
            <div v-else>
              <ladda-button @lbClicked="createInvoice(true)" el-class="btn-primary btn-block text-semi p-y-sm" :loading="loading" :disabled="!canChargeCardOnFile && !isFreeInvoice">
                <i class="fa fa-credit-card"></i> &nbsp; Charge Card
              </ladda-button>
              <div class="text-center m-t-xxs">
                <small v-if="canChargeCardOnFile">Card ending in {{ recipientBillingProfile.default_payment_method_last4 }} will be charged.</small>
                <small v-else-if="isFreeInvoice" class="font-semi text-red-700"><i class="fa fa-exclamation-triangle"></i> Invoice total is $0. Tap to send receipt for $0 charge.</small>
                <small v-else class="font-semi text-red-700"><i class="fa fa-exclamation-triangle"></i> No card on file.</small>
              </div>
            </div>
            <div class="col-span-2 ">
              <div v-if="error" class="text-center m-t-xxs">
                <small>
                  <span class="text-danger">{{ errorMessage }}</span>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-800 rounded-sm p-x-xxs-only-xs p-x-lg p-y-m">
        <div class="flex flex-col">
          <h1 class="m-t-m m-b-xs font-heavy text-gray-50">Preview</h1>
          <div id="preview-frame" class="app-frame mac scrolling bg-gray-50" style="background-color: rgb(249 250 251);" data-title="Invoice">
            <div v-if="!readyForPreview">
              Please enter recipient<span v-if="isSending && !dueOn">, due date,</span> and items to preview invoice.
            </div>
            <iframe v-else id="preview-frame" :src="debouncedIframeUrl" width="100%"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div id="card-modal" class="modal fade hidden-print" tabindex="-1" role="dialog" data-keyboard="false"
         data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Update card on file</h4>
          </div>
          <div class="modal-body" v-if="this.recipientBillingProfile && renderCardForm" style="margin-bottom: -8px;">
            <update-cc
                :billing-profile="this.recipientBillingProfile"
                :billing-partner="this.recipientBillingProfile.billing_update_meta.billing_partner"
                :billing-key="this.recipientBillingProfile.billing_update_meta.billing_partner_key"
                :billing-acct-id="this.recipientBillingProfile.billing_update_meta.billing_partner_id"
                :billing-intent-secret="this.recipientBillingProfile.billing_update_meta.intent.client_secret"
                :team-name="this.recipientBillingProfile.billing_update_meta.team.name"
                :team-currency="this.teamCurrency"
                :should-redirect="false"
                @card-updated="cardUpdated"
                :use-google-autocomplete="true"
            >
            </update-cc>
            <div class="m-t-sm">
              <button type="button" class="btn btn-default btn-block" @click.prevent="toggleCardModal">Cancel</button>
            </div>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
import { Money } from 'v-money';
import Search from '../../shared/search.vue';
import GenericSearchResult from './generic-search-result.vue';
import DatePicker from './date_picker.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { formattingMixin } from './mixins/formatting_mix';
import OpposingTeamCreate from './opposing_team_create.vue';
import FilteredList from './filtered_list.vue';
import ProductListResult from './product_list_result.vue';
import Spinner from './spinner.vue';
import { cardLogoMix } from '../../shared/card_logo_mix';
import UpdateCc from './update_cc.vue';
import { errorableMix } from './mixins/errorable_mix';

export default {
  name: 'manual-invoice-form',
  mixins: [formattingMixin, cardLogoMix, errorableMix],
  components: {
    OpposingTeamCreate,
    LaddaButton,
    GenericSearchResult,
    Search,
    DatePicker,
    FilteredList,
    ProductListResult,
    Spinner,
    Money,
    UpdateCc,
  },
  props: {
    percentFee: {
      type: Number,
      default: 0,
    },
    teamSlug: {
      type: String,
      required: true,
    },
    teamCurrency: {
      type: String,
      default: 'usd',
    }
  },
  data() {
    return {
      loading: false,
      recipient: null,
      recipientBillingProfile: null,
      dueOn: null,
      memo: '',

      lineItems: [],

      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },

      newCustomer: {
        email: '',
        firstName: '',
        lastName: '',
      },
      showNewCustomerForm: false,
      addingFromLibrary: false,
      pendingProductAdd: null,
      debouncedIframeUrl: '',
      chargeMethod: 'autocharge',

      addingCoupon: false,
      couponsLoading: false,
      couponId: null,
      availableCoupons: [],
      // We use this to create/destroy the card form so it hooks into the DOM properly. We can't just hide it.
      renderCardForm: false,
    };
  },
  mounted() {
    window.addEventListener('message', (ev) => {
      if(ev.data.type && ev.data.type === 'resize-iframe') {
        const iframe = document.getElementById('preview-frame')
        // 300 is rudimentary padding to account for the spacing inside the iframe content (padding, body, margins, etc)
        iframe.style.height = ev.data.payload.height + 300 + 'px'
      }
    }, false)

    this.updateIframeUrl = _.debounce(this.updateIframeUrl, 500);
  },
  computed: {
    isSending() {
      return this.chargeMethod === 'send';
    },
    descriptionForInvoice() {
      if (this.isSending) {
        return this.memo;
      }

      // Otherwise we generate by looping through items and creating a description
      return _.map(this.lineItems, (li) => {
        const quantity = li.quantity > 1 ? ` x ${li.quantity}` : '';
        return `${li.description}${quantity}`
      }).join(', ');
    },
    coupon() {
      return _.find(this.availableCoupons, { id: this.couponId });
    },
    invoiceTotal() {
      return _.sumBy(this.lineItems, this.totalForItem) + this.wiqFee + this.couponAmountOff;
    },
    invoiceSubtotal() {
      return _.sumBy(this.lineItems, this.totalForItem);
    },
    wiqFee() {
      if (this.percentFee === 0) {
        return 0;
      }

      return (this.invoiceSubtotal + this.couponAmountOff) * this.percentFee;
    },
    receipientName() {
      if (!this.recipient) {
        return '';
      }

      return this.recipient.name || `${this.recipient.first_name} ${this.recipient.last_name}`;
    },
    recipientType() {
      if (!this.recipient) {
        return '';
      }

      return _.replace(this.recipient.type, 'Profile', '');
    },
    readyForPreview() {
      if (!this.recipient) {
        return false;
      }

      return !(this.isSending && !this.dueOn);
    },
    iframeUrl() {
      if (!this.readyForPreview) {
        return '';
      }

      const data = {
        status: 'preview',
        number: 'Preview',
        due_on: this.isSending ? this.dueOn.toISOString() : '',
        invoice_items: this.formattedLineItems,
        amount_due: this.invoiceTotal,
        description: this.descriptionForInvoice,
        invoice_type: 'one_off',
        payment_method: 'all',
        to_name: this.receipientName,
      };

      const params = encodeURIComponent(JSON.stringify(data));
      return `/${this.teamSlug}/preview/invoice?preview_invoice=${params}`;
    },
    formattedLineItems() {
      const items = _.map(this.lineItems, this.transformLineItem);
      if (this.wiqFee) {
        items.push({
          quantity: 1,
          amount: this.wiqFee,
          description: 'WrestlingIQ Fee',
          transactable_type: 'ApplicationFee',
        });
      }

      if (this.coupon) {
        items.push({
          quantity: 1,
          amount: this.couponAmountOff,
          description: `Scholarship Code ${this.coupon.code}`,
          transactable_type: 'Coupon',
          transactable_id: this.coupon.id,
        });
      }

      return items;
    },
    couponAmountOff() {
      if (!this.coupon) {
        return 0;
      }

      if (this.coupon.amount_off > 0) {
        return this.coupon.amount_off * -1;
      } else {
        return this.invoiceSubtotal * this.coupon.percent_off * -1;
      }
    },
    couponDescription() {
      if (!this.coupon) {
        return '';
      }

      let discountText = this.coupon.amount_off > 0 ? `(${this.formatAsCurrency(this.coupon.amount_off)})` : `(${this.coupon.percent_off * 100}% Off)`;
      return `Scholarship Code ${this.coupon.code} ${discountText}`;
    },
    canChargeCardOnFile() {
      return this.recipientBillingProfile && this.recipientBillingProfile.default_payment_method_reusable;
    },
    isFreeInvoice() {
      return this.invoiceTotal === 0 && this.lineItems.length > 0;
    },
    cardLogo() {
      switch (this.recipientBillingProfile.default_payment_method_brand) {
        case 'visa':
          return this.visaLogo;
        case 'mastercard':
          return this.mastercardLogo;
        case 'amex':
        case 'american_express':
          return this.amexLogo;
        case 'discover':
          return this.discoverLogo;
        default:
          return `<div>${this.recipientBillingProfile.default_payment_method_brand}</div>`;
      }
    },
  },
  watch: {
    iframeUrl: function (newUrl) {
      this.updateIframeUrl(newUrl);
    },
    addingCoupon: function (newVal) {
      if (this.availableCoupons.length === 0) {
        this.loadCoupons();
      }
    },
    coupon: function(newCoupon) {
      this.addingCoupon = false;
    },
    lineItems: {
      handler(lineItems) {
        lineItems.forEach((item) => {
          if (item.isOneTimeDiscount && item.unitPrice > 0) {
            item.unitPrice *= -1;
          }
        });
      },
      deep: true,
    },
    recipient: function(newRecipient) {
      if (newRecipient) {
        this.loadBillingProfile();
      }
    }
  },
  methods: {
    toggleCardModal() {
      this.renderCardForm = !this.renderCardForm;
      $('#card-modal').modal('toggle');
    },
    cardUpdated() {
      this.loadBillingProfile();
      this.toggleCardModal();
    },
    removeItemAt(index) {
      this.lineItems.splice(index, 1);
    },
    itemIsPlanOrCoupon(item) {
      // return if item.transactable_type is BillingPlan or Coupon
      return item.transactable_type === 'BillingPlan' || item.transactable_type === 'Coupon';
    },
    updateIframeUrl(url) {
      this.debouncedIframeUrl = url;
    },
    cancelCustomerCreate() {
      this.$notificationManager.$emit('cancel-customer-create');
    },
    addProductItem(product) {
      if (product.billing_plans.length === 1) {
        this.addBillingPlan(product.billing_plans[0]);
        return;
      }

      // Otherwise we need to let the user pick the sku in question
      this.pendingProductAdd = product;
    },
    addBillingPlan(plan) {
      this.lineItems.push({
        quantity: 1,
        unitPrice: plan.amount / 100,
        description: `${plan.name} ${plan.color || ''} ${plan.size || ''}`,
        transactable_type: "BillingPlan",
        transactable_id: plan.id,
      });
      this.addingFromLibrary = false;
      this.pendingProductAdd = null;
    },
    // Func to disable certain dates in the date-picker
    tomorrowOrLater(date) {
      const tomorrow = new Date();
      // Set the hours to 0
      tomorrow.setHours(0, 0, 0, 0);
      // Bump the day up one
      tomorrow.setDate(tomorrow.getDate() + 1)
      return date < tomorrow;
    },
    recipientClicked(profile) {
      this.recipient = profile;
    },
    addNewItem(isDiscount) {
      const newItem = {
        quantity: 1,
        unitPrice: isDiscount ? 0 : 0,
        description: isDiscount ? 'Discount' : '',
        transactable_id: '',
        transactable_type: '',
        isOneTimeDiscount: isDiscount,
      };
      this.lineItems.push(newItem);
      this.$nextTick(() => {
        document.getElementById(`description-${this.lineItems.length - 1}`).focus();
      });
    },
    totalForItem(item) {
      return _.round(item.unitPrice * item.quantity * 100);
    },
    validate() {
      const vm = this;

      let errorMessage = 'Please ensure ';
      let error = false;

      if (!vm.recipient) {
        errorMessage += 'recipient, ';
        error = true;
      }

      if (!vm.descriptionForInvoice) {
        errorMessage += 'memo, ';
        error = true;
      }

      if (this.isSending && !vm.dueOn) {
        errorMessage += 'due on, ';
        error = true;
      }

      if (vm.invoiceTotal < 0) {
        errorMessage += 'invoice total, ';
        error = true;
      }

      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        return false;
      }

      return true;
    },
    transformLineItem(item) {
      return {
        quantity: item.quantity,
        amount: this.totalForItem(item),
        description: item.description,
        transactable_id: item.transactable_id,
        transactable_type: item.transactable_type
      };
    },
    createInvoice: _.throttle(function (chargeCard) {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.error = false;
      if (vm.validate()) {
        vm.loading = true;
        const params = {
          manual_invoice: {
            profile_id: vm.recipient.id,
            profile_type: vm.recipient.type,
            amount_paid: 0,
            status: 'pending',
            invoice_type: 'one_off',
            send_payment_page: !chargeCard,
            payment_method: 'all',
            due_on: chargeCard ? null : vm.dueOn.toISOString(),
            description: vm.descriptionForInvoice,
            charge_card: chargeCard,
            invoice_items_attributes: this.formattedLineItems,
          },
        };

        const url = vm.$apiService.manualInvoicesUrl();
        axios.post(url, params)
          .then((response) => {
            vm.loading = false;
            const toast = chargeCard ? 'Charge successful' : 'Invoice sent';
            vm.$notificationManager.$emit('show-toast', toast, true);
            window.location.assign('/invoices');
          })
          .catch((error) => {
            vm.errorMessage = vm.parseErrorResponse(error);
            vm.error = true;
            vm.loading = false;
          });
      }
    }, 300),
    createCustomer: _.throttle(function () {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.error = false;
      vm.loading = true;
      const params = {
        customer: {
          email: vm.newCustomer.email,
          first_name: vm.newCustomer.firstName,
          last_name: vm.newCustomer.lastName,
        },
      };

      const url = vm.$apiService.customersUrl();
      axios.post(url, params)
        .then((response) => {
          vm.loading = false;
          vm.recipient = response.data;
          vm.showNewCustomerForm = false;
        })
        .catch((error) => {
          vm.errorMessage = vm.parseErrorResponse(error);
          vm.error = true;
          vm.loading = false;
        });
    }),
    loadCoupons() {
      const vm = this;
      const url = vm.$apiService.couponsUrl()
      vm.couponsLoading = true;
      vm.$apiService.loadAllPages(url, 'coupons')
          .then((objects) => {
            vm.availableCoupons = objects;
            vm.couponsLoading = false;
          })
          .catch((error) => {
            console.error(error);
            vm.errorMessage = 'Something went wrong loading available scholarship codes. Try again or email support@wrestlingiq.com';
            vm.error = true;
            vm.couponsLoading = false;
          });
    },
    loadBillingProfile() {
      const vm = this;
      const url = vm.$apiService.profileBillingProfileUrl(vm.recipient.id, vm.recipient.type);
      axios.get(url)
        .then((response) => {
          vm.recipientBillingProfile = response.data;
        })
        .catch((error) => {
          console.error(error);
          vm.errorMessage = 'Something went wrong loading billing profile. Try again or email';
        });
    }
  },
};
</script>
