<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="row">
        <div class="col-xs-6 col-md-8">
          <h1>
            Membership Summary Report
          </h1>
          <p class="m-b-none"><small>
            Report generated on {{ processedAt() }}
          </small></p>
        </div>
        <div class="col-xs-6 col-md-4 text-right">
          <div class="ibox-tools">
            <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span
                class="hidden-xs">Download CSV</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Billed to</th>
              <th v-bind:style="secondColStyles">Wrestler names</th>
              <th>Status</th>
              <th>Member since</th>
              <th>Next due</th>
              <th>Expires on</th>
              <th>Total</th>
              <th>Billing interval</th>
              <th>Items</th>
              <th>Discount</th>
              <th>Billing partner id</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in rows">
              <td class="first-col"
                  v-bind:style="firstColStyles">
                {{ row.name }}
              </td>
              <td v-bind:style="secondColStyles">
                {{ row.wrestler_names }}
              </td>
              <td>
                {{ row.status }}
              </td>
              <td>
                {{ row.member_since }}
              </td>
              <td>
                {{ row.next_due }}
              </td>
              <td>
                {{ row.expires_on }}
              </td>
              <td>
                {{ row.total }}
              </td>
              <td>
                {{ row.billing_interval }}
              </td>
              <td>
                {{ row.items }}
              </td>
              <td>
                {{ row.discount }}
              </td>
              <td>
                <a class="text-underline" :href="`/subscriptions/${row.billing_partner_id}`">
                  {{ row.billing_partner_id }}
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';
import { billingPlanMix } from '../mixins/billing_plan_mix';

export default {
  name: 'membership-summary-report',
  mixins: [downloadMix, formattingMixin, billingPlanMix],
  props: [
    'report',
  ],
  data() {
    return {
      rows: [],
      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },
    };
  },
  mounted() {
    const vm = this;

    const subscriptions = _.get(vm.report, 'result.billing_subscriptions.objects', null);
    vm.rows = [];
    subscriptions.forEach((subscription) => {
      const coupon = _.first(subscription.coupons);

      const row = {
        name: this.parentName(false, subscription),
        wrestler_names: this.wrestlerNames((subscription)),
        status: _.capitalize(subscription.status),
        member_since: this.readableCreatedAt(subscription),
        next_due: _.replace(this.nextDue(subscription), 'Next due', ''),
        expires_on: this.expiresOn(subscription),
        total: vm.formatAsCurrency(this.subscriptionTotal(subscription)),
        billing_interval: this.subInterval(subscription),
        items: this.productNames(subscription),
        discount: coupon?.code,
        billing_partner_id: _.get(subscription, 'billing_partner_id', null) || _.get(subscription, 'stripe_id', '')
      };
      vm.rows.push(row);
    });

    vm.$nextTick(function () {
      const header = this.$refs.lnameHeader;
      if (header) {
        const rect = header.getBoundingClientRect();

        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at)
          .format('M/D/YYYY, hh:mm a');
    },
    downloadCSV() {
      const vm = this;
      let rows = [];
      rows.push([
        'Billed to',
        'Wrestler names',
        'Status',
        'Member since',
        'Next due',
        'Expires on',
        'Total',
        'Billing interval',
        'Items',
        'Discount',
        'Billing partner id',
      ]);
      const rowsToArray = _.map(vm.rows, (r) => {
        return _.values(r);
      });
      rows = _.concat(rows, rowsToArray);
      const fileName = `Membership Summary Report - ${vm.processedAt()}.csv`;

      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
