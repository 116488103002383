<template>
    <div>
      <template v-if="loading">
        <spinner :inline="true"></spinner>
      </template>
      <small v-if="error" class="text-danger">
        {{ errorMessage }}
      </small>
      <div v-if="ready">
        <div v-if="showScoringPicker">
          <p>How do you want to score this match?</p>
          <div class="flex flex-wrap gap-sm">
            <button @click="setRuleset(ruleset)" class="btn btn-default" v-for="ruleset in availableRulesets">
              {{ readableRuleset(ruleset) }}
            </button>
          </div>
        </div>
        <div v-else>
          <div v-if="hasOutcome" class="flex flex-col-xs-only justify-space-between">
            <h2>Result</h2>
            <div class="border border-gray-100 rounded-sm border-solid flex align-items-center justify-space-between">
              <div class="flex-grow-1">
                <div class="flex justify-space-between p-y-xs p-x-m border-b border-solid border-gray-200 gap-x-m">
                  <div class="text-gray-700">{{ wrestlerName(redWrestler) }}</div>
                  <div class="text-gray-700 relative">
                    <div id="redCurrentScore" class="score-e-container">
                      <span class="red circle-bg score-legend">
                          {{ currentRedScore }}
                      </span>
                    </div>
                    <i v-if="isRedWinner" class="fa fa-caret-left text-red-400 absolute" style="right: -16px; top: 50%; transform: translateY(-50%);"></i>
                  </div>
                </div>
                <div class="flex justify-space-between p-y-xs p-x-m gap-x-m">
                  <div class="font-bold">{{ wrestlerName(greenWrestler) }}</div>
                  <div class="font-bold relative">
                    <div id="greenCurrentScore" class="score-e-container">
                      <span class="green score-legend">
                          {{ currentGreenScore }}
                      </span>
                    </div>
                    <i v-if="!isRedWinner" class="fa fa-caret-left text-green-400 absolute" style="right: -16px; top: 50%; transform: translateY(-50%);"></i>
                  </div>
                </div>
              </div>
              <div class="align-self-stretch  align-items-center flex border-l border-solid border-gray-200 p-x-sm">
                <div class="text-gray-600" v-html="outcomeAbbreviation"></div>
              </div>
            </div>
          </div>
          <div class="m-t-lg flex flex-col-xs-only justify-space-between gap-sm" style="min-height: 172px;">
            <div>
              <div class="flex gap-x-sm">
                <h2 class="m-y-none">Scoring</h2>
                <a class="btn btn-default btn-compact flex justify-content-center align-items-center" @click.prevent="toggleEditing" v-if="canEdit && !editing && hasOutcome"><i class="fa fa-pencil m-none"></i></a> 
              </div>
              <div class="score-wrestlers align-self-center m-t-sm flex flex-column flex-0-0-100-xs-only" v-if="editing">
                <div class="flex flex-nowrap align-items-center gap-x-m text-gray-700">
                  <div>
                    {{ wrestlerName(redWrestler) }}
                  </div>
                  <div id="redCurrentScore" class="score-e-container">
                    <span class="red circle-bg score-legend">
                      {{ currentRedScore }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-nowrap m-t-auto align-items-center gap-x-m text-gray-700">
                  <div>
                    {{ wrestlerName(greenWrestler) }}
                  </div>
                  <div id="greenCurrentScore" class="score-e-container">
                      <span class="green score-legend">
                          {{ currentGreenScore }}
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap gap-xs flex-col-xs-only justify-space-between">
              <div class="round-scoring flex-0-0-100-xs-only" v-for="round in allScoredRounds">
                <div class="round-title">
                  <h3>{{ round.name }}</h3>
                </div>
                <div class="round-events flex flex-nowrap">
                  <div v-for="event in scoringEventsForRound(round)" :class="classForRoundEvent(event)">
                    <template v-if="hasOpponentPoints(event)">
                      <div class="flex flex-column h-100">
                        <div class="red circle-bg score-event">
                          {{ pointTextFor(event, redWrestlerId) }}
                        </div>
                        <div class="green score-event m-t-auto">
                          {{ pointTextFor(event, greenWrestlerId) }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="flex flex-col" :class="getColorClassForEvent(event)" @click.prevent="seekTo(event.in_video_at)">
                        <div v-html="eventDisplayHtml(event)"></div>
                        <div class="text-10 text-center" v-if="event.in_video_at">
                          <div class="text-10 text-center">{{ readableVideoAt(event.in_video_at) }}</div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showResultChoices">
            <div class="panel panel-default m-t-m">
              <div class="panel-heading">
                Result Suggestion
              </div>
              <div class="panel-body">
                <div class="flex flex-wrap">
                  <div class="flex-1-1-0">
                    <template v-if="leaderName === 'Tied'">
                      <p>
                        The score is currently tied.<br/>
                      </p>
                    </template>
                    <template v-else>
                      <p>
                        Based on the match score, it appears that
                        <strong>{{ leaderName }}</strong> wins by <strong>{{ potentialMatchResult.name }}</strong>.
                      </p>
                      <button class="btn btn-primary m-b-sm" @click="resultTapped(potentialMatchResult, leader)">
                        Confirm {{ leaderName }} by {{ potentialMatchResult.name }}
                      </button>
                    </template>
                  </div>
                  <div class="flex flex-column">
                    <p>
                      <strong>
                        Not true?
                      </strong><br/>
                      Tap the correct match result below.
                    </p>
                    <button class="btn btn-default" @click="toggleEndMatch">Back To Scoring</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="editing" class="flex flex-col-xs-only gap-sm justify-space-evenly m-t-sm">
            <div class="scorepad">
              <div class="scorepad-title red">
                <div class="flex">
                  <div>
                    {{ wrestlerName(redWrestler) }}
                  </div>
                  <div class="scorepad-position red" v-html="positionIconFor(redWrestler)">
                  </div>
                </div>
              </div>
              <!-- todo surely this can be DRYed up -->
              <div class="scorepad-options p-b-sm">
                <div class="flex flex-wrap" v-if="!showOnlyChoices && !showResultChoices">
                  <div class="score-pool m-t-sm m-l-m" v-for="pool in redScoringOptions">
                    <div class="flex">
                      <div v-for="event in pool.events" @click="scoreOptionEventTapped(event, redWrestlerId)" class="score-bubble score-option-btn red circle-bg" >
                        {{ event.name }}
                      </div>
                    </div>
                    <p class="m-l-xxs m-t-xxs" v-if="pool.events.length > 0">
                      {{ pool.pool_name }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-wrap" v-if="!showResultChoices">
                  <div class="score-pool m-t-sm m-l-m">
                    <div class="flex flex-wrap">
                      <button v-for="choice in ruleset.choice_options" @click="choiceTapped(choice, redWrestler)"
                              class="choice-option-btn btn-default">
                        {{ choice.name }}
                      </button>
                    </div>
                    <p class="m-l-xxs m-t-xxs">
                      Choices
                    </p>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="score-pool m-t-sm m-l-m">
                    <div class="flex flex-wrap">
                      <button v-for="result in selectableResultOptions()" @click="resultTapped(result, redWrestler)"
                              class="choice-option-btn btn-default">
                        {{ result.name }}
                      </button>
                    </div>
                    <p class="m-l-xxs m-t-xxs">
                      Match Result
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="scorepad">
              <div class="scorepad-title green">
                <div class="flex">
                  <div>
                    {{ wrestlerName(greenWrestler) }}
                  </div>
                  <div class="scorepad-position green" v-html="positionIconFor(greenWrestler)">
                  </div>
                </div>
              </div>
              <div class="scorepad-options p-b-sm">
                <div class="flex flex-wrap" v-if="!showOnlyChoices && !showResultChoices">
                  <div class="score-pool m-t-sm m-l-m" v-for="pool in greenScoringOptions">
                    <div class="flex">
                      <div v-for="event in pool.events" @click="scoreOptionEventTapped(event, greenWrestlerId)" class="score-bubble score-option-btn green">
                        {{ event.name }}
                      </div>
                    </div>
                    <p class="m-l-xxs m-t-xxs">
                      {{ pool.pool_name }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-wrap" v-if="!showResultChoices">
                  <div class="score-pool m-t-sm m-l-m">
                    <div class="flex flex-wrap">
                      <button v-for="choice in ruleset.choice_options" @click="choiceTapped(choice, greenWrestler)"
                              class="choice-option-btn btn-default">
                        {{ choice.name }}
                      </button>
                    </div>
                    <p class="m-l-xxs m-t-xxs">
                      Choices
                    </p>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="score-pool m-t-sm m-l-m">
                    <div class="flex flex-wrap">
                      <button v-for="result in selectableResultOptions()" @click="resultTapped(result, greenWrestler)"
                              class="choice-option-btn btn-default">
                        {{ result.name }}
                      </button>
                    </div>
                    <p class="m-l-xxs m-t-xxs">
                      Match Result
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="editing" class="flex flex-column m-l-sm">
              <button class="btn btn-primary btn-outline" @click="toggleEndMatch">End Match </button>
              <button class="btn btn-default m-t-xxs" @click="swapRedGreen">Swap Red/Green</button>
              <button v-if="hasNextRound" class="btn btn-success m-t-xxs" @click="goToNextRound">Next Round</button>
              <button class="btn btn-warning m-t-xxs" @click="deleteLastEvent">Undo</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Spinner from './spinner.vue';

export default {
  name: 'scoring',
  components: { Spinner },
  props: {
    matchId: {
      type: Number,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    defaultRuleset: {
      type: String,
      default: 'NFHS_2024_25',
    },
    availableRulesets: {
      type: Array,
      default() {
        return [];
      },
    }
  },
  data() {
    return {
      ready: false, // Ready is for showing the thing initially, loading for network requests
      loading: false,
      error: false,
      errorMessage: '',

      ruleset: null,
      userPickedRuleset: null,

      scoringEvents: null,
      match: null,
      editing: false,

      scoringPlayheadPosition: 0,
      redWrestlerId: null,
      greenWrestlerId: null,
      currentRound: null,
      hasNextRound: true,
      showOnlyChoices: false,
      showResultChoices: false,
      outcomeMap: {
        "dec": "DEC",
        "m_dec": "MD",
        "tf": "TF",
        "fall": "FALL",
        "dq": "DQ",
        "default": "DEF",
        "ff": "FF"
      }
    };
  },
  mounted() {
    const vm = this;
    vm.getMatch();
  },
  computed: {
    outcomeAbbreviation() {
      return this.outcomeMap[this.match.outcome_slug] || "&nbsp;&nbsp;&nbsp;&nbsp;";
    },
    isRedWinner() {
      return this.match.winning_wrestler_profile_id === this.redWrestlerId;
    },
    matchWinnerName() {
      const vm = this;
      const winnerId = _.get(vm.match, 'winning_wrestler_profile_id', null);
      if (!winnerId) {
        return 'Unknown';
      }

      return vm.wrestlerNameById(winnerId);
    },
    leader() {
      if (this.currentRedScore > this.currentGreenScore) {
        return this.redWrestler;
      } if (this.currentRedScore < this.currentGreenScore) {
        return this.greenWrestler;
      }
      return null;
    },
    leaderId() {
      if (this.currentRedScore > this.currentGreenScore) {
        return this.redWrestlerId;
      } if (this.currentRedScore < this.currentGreenScore) {
        return this.greenWrestlerId;
      }
      return null;
    },
    leaderName() {
      const lId = this.leaderId;
      if (!lId) {
        return 'Tied';
      }

      return this.wrestlerNameById(lId);
    },
    potentialMatchResult() {
      const vm = this;
      if (!vm.ruleset) {
        return null;
      }
      const diff = Math.abs(vm.currentRedScore - vm.currentGreenScore);
      for (let i = 0; i < vm.ruleset.result_options.length; i++) {
        const rOption = vm.ruleset.result_options[i];
        // todo this is implicit ordering again
        const lessThanRule = _.get(rOption, 'criteria.less_than', null);
        const meetsLessThanCriteria = lessThanRule ? diff < lessThanRule : true;

        const greaterThanRule = _.get(rOption, 'criteria.greater_than', null);
        const meetsGreaterThanCriteria = greaterThanRule ? diff > greaterThanRule : true;

        if (meetsLessThanCriteria && meetsGreaterThanCriteria) {
          return rOption;
        }
      }

      return null;
    },
    // Does this match have scoring events?
    hasOutcome() {
      return _.get(this.match, 'outcome_slug', null) !== null;
    },
    redScoringEvents() {
      if (this.redWrestlerId == null || this.scoringEvents == null) {
        return [];
      }

      return this.scoringEventsForWrestlerId(this.redWrestlerId);
    },
    currentRedScore() {
      const redEarnedPoints = _.sumBy(this.redScoringEvents, 'points');
      const greenPenaltyPoints = _.sumBy(this.greenScoringEvents, 'opponent_points');
      return redEarnedPoints + greenPenaltyPoints;
    },
    // this also returns pools, not just options
    // this is computed because it's based on the state machine of current playhead
    redScoringOptions() {
      return this.wrestlerScoringOptions(this.redWrestlerId);
    },
    greenScoringEvents() {
      if (this.greenWrestlerId == null || this.scoringEvents == null) {
        return [];
      }

      return this.scoringEventsForWrestlerId(this.greenWrestlerId);
    },
    currentGreenScore() {
      const greenEarnedPoints = _.sumBy(this.greenScoringEvents, 'points');
      const redPenaltyPoints = _.sumBy(this.redScoringEvents, 'opponent_points');
      return greenEarnedPoints + redPenaltyPoints;
    },
    greenScoringOptions() {
      return this.wrestlerScoringOptions(this.greenWrestlerId);
    },
    greenWrestler() {
      if (!this.greenWrestlerId || !this.match) {
        return null;
      }

      const homeWrestler = _.get(this.match, 'wrestler', null);
      const opponent = _.get(this.match, 'opponent', null);

      if (homeWrestler.id === this.greenWrestlerId) {
        return homeWrestler;
      }
      return opponent;
    },
    redWrestler() {
      if (!this.redWrestlerId || !this.match) {
        return null;
      }

      const homeWrestler = _.get(this.match, 'wrestler', null);
      const opponent = _.get(this.match, 'opponent', null);

      if (homeWrestler.id === this.redWrestlerId) {
        return homeWrestler;
      }
      return opponent;
    },
    allScoredRounds() {
      if (!this.scoringEvents || !this.ruleset) {
        return [];
      }
      // Get the last round scored in + currentRound
      const rounds = [];
      for (let i = 0; i < this.ruleset.round_options.length; i++) {
        const r = this.ruleset.round_options[i];
        rounds.push(r);
        // We break if we hit the round we last scored in
        if (r.value === this.currentRound.value) {
          break;
        }
      }
      return rounds;
    },
    showScoringPicker() {
      return this.hasNoScoringEventsRecorded && this.availableRulesets.length > 1 && this.userPickedRuleset == null;
    },
    hasNoScoringEventsRecorded() {
      if (this.scoringEvents == null) {
        return true;
      } else {
        return this.scoringEvents.length === 0;
      }
    }
  },
  methods: {
    readableRuleset(rulesetName) {
      const prettyName = rulesetName.replace(/_/g,' ');
      if (rulesetName.includes('NFHS') || rulesetName.includes('NCAA')) {
        return `Folkstyle (${prettyName})`;
      }

      return prettyName;
    },
    seekTo(seconds) {
      if (seconds != null) {
        let seekTo = seconds - 15
        if (seekTo <= 0) { seekTo = 0 }
        this.$notificationManager.$emit('video-seek-to', seekTo);
      }
    },
    scoringEventParams() {
      return _.map(this.scoringEvents, (se) => ({
        id: se.id,
        wrestler_profile_id: se.wrestler_profile_id,
        score_type: se.score_type,
        points: se.points,
        opponent_points: se.opponent_points,
        round: se.round,
        round_display_name: se.round_display_name,
        display_name: se.display_name,
        slug: se.slug,
        in_video_at: se.in_video_at,
      }));
    },
    resultTapped: _.throttle(function (result, wrestler) {
      // Save to db!
      const vm = this;
      vm.match.outcome_slug = result.slug;
      vm.match.outcome_name = result.name;
      vm.match.winning_wrestler_profile_id = wrestler.id;

      if (vm.redWrestlerId === vm.match.wrestler.id) {
        vm.match.final_wrestler_score = vm.currentRedScore;
        vm.match.final_opponent_score = vm.currentGreenScore;
      } else {
        vm.match.final_wrestler_score = vm.currentGreenScore;
        vm.match.final_opponent_score = vm.currentRedScore;
      }

      // todo handle fall time
      vm.toggleEditing();
      vm.showResultChoices = false;

      vm.loading = true;
      const params = {
        match: {
          final_opponent_score: vm.match.final_opponent_score,
          final_wrestler_score: vm.match.final_wrestler_score,
          red_wrestler_id: vm.redWrestlerId,
          outcome_slug: vm.match.outcome_slug,
          outcome_name: vm.match.outcome_name,
          winning_wrestler_profile_id: vm.match.winning_wrestler_profile_id,
          scoring_events_attributes: vm.scoringEventParams(),
        },
      };

      const url = vm.$apiService.matchUrl(vm.match.id);
      axios.put(url, params)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit('show-toast', 'Stats saved', true);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
    swapRedGreen() {
      const currentRedId = this.redWrestlerId;
      const currentGreenId = this.greenWrestlerId;

      this.redWrestlerId = currentGreenId;
      this.greenWrestlerId = currentRedId;
      this.match.red_wrestler_id = this.redWrestlerId;
    },
    wrestlerNameById(wrestlerId) {
      const vm = this;
      if (!vm.match) {
        return '';
      }

      if (vm.match.wrestler.id === wrestlerId) {
        return vm.match.wrestler.full_name;
      } if (vm.match.opponent.id === wrestlerId) {
        return vm.match.opponent.full_name;
      }
      return 'Unknown';
    },
    selectableResultOptions() {
      return _.filter(this.ruleset.result_options, ['criteria.user_selectable', true]);
    },
    toggleEndMatch() {
      this.showResultChoices = !this.showResultChoices;
    },
    scoringEventsForWrestlerId(wrestlerId) {
      if (wrestlerId == null || this.scoringEvents == null) {
        return [];
      }

      return _.filter(this.scoringEvents, { wrestler_profile_id: wrestlerId });
    },
    isChoiceEvent(scoreEvent) {
      return _.find(this.ruleset.choice_options, ['value', scoreEvent.score_type]);
    },
    deleteLastEvent() {
      const vm = this;
      const index = vm.scoringEvents.length - 1;
      if (index >= 0) {
        // First check if it's in the same round
        const event = vm.scoringEvents[index];
        if (event.round === vm.currentRound.value) {
          // delete it
          vm.$delete(vm.scoringEvents, index);

          // handle scoring
          if (vm.isChoiceEvent(event)) {
            vm.showOnlyChoices = true;
          }
        } else {
          // delete the round
          vm.goToPreviousRound();
        }
      } else {
        // We dont' have a scoring event, but we may need to decrement the round
        if (vm.allScoredRounds.length > 1) {
          vm.goToPreviousRound();
        }
      }
    },
    eventDisplayHtml(event) {
      const name = event.display_name;
      if (_.includes(['Top', 'Bottom', 'Defer', 'Neutral'], name)) {
        return this.iconForPosition(name);
      }
      return event.display_name;
    },
    // This is used for events that have opponent points involved for special UI handling
    pointTextFor(event, pointsForWrestlerId) {
      // given an event, how many points should I show for the wrestler?
      return (event.wrestler_profile_id === pointsForWrestlerId) ? event.display_name : event.opponent_points;
    },
    scoringEventsForRound(round) {
      return _.filter(this.scoringEvents, { round: round.value });
    },
    iconForPosition(position) {
      position = _.lowerCase(position);
      if (position === 'top') {
        return "<i class='fa fa-chevron-circle-up'></i>";
      } if (position === 'bottom') {
        return "<i class='fa fa-chevron-circle-down'></i>";
      } if (position === 'neutral') {
        return "<i class='fa fa-pause rotate-90'></i>";
      } if (position === 'defer') {
        return 'D';
      }

      return '';
    },
    positionIconFor(wrestler) {
      const position = this.positionForWrestlerId(wrestler.id);
      return this.iconForPosition(position);
    },
    isRedEvent(scoreEvent) {
      return scoreEvent.wrestler_profile_id === this.redWrestlerId;
    },
    hasOpponentPoints(scoreEvent) {
      return scoreEvent.opponent_points && scoreEvent.opponent_points > 0;
    },
    classForRoundEvent(scoreEvent) {
      let alignmentClass = '';
      if (this.hasOpponentPoints(scoreEvent)) {
        alignmentClass = '';
      } else if (this.isRedEvent(scoreEvent)) {
        alignmentClass = 'align-self-flex-start';
      } else {
        alignmentClass = 'align-self-flex-end';
      }

      return `${alignmentClass} score-e-container`;
    },
    readableVideoAt(seconds) {
      if (seconds === null) {
        return ""
      }

      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds - (minutes * 60);
      if (remainingSeconds < 10) {
        remainingSeconds = `0${remainingSeconds}`;
      }

      return `${minutes}:${remainingSeconds}`;
    },
    getColorClassForEvent(scoreEvent) {
      let clickClass = scoreEvent.in_video_at ? "text-underline-hover cursor-pointer" : '';
      if (this.isRedEvent(scoreEvent)) {
        return `red circle-bg score-event ${clickClass}`;
      }

      // todo how do I fix this up for freestyle?
      return `green score-event ${clickClass}`;
    },
    goToPreviousRound() {
      const vm = this;
      const currentRoundIndex = _.findIndex(vm.ruleset.round_options, vm.currentRound);
      const prevIndx = currentRoundIndex - 1;
      if (prevIndx >= 0) {
        vm.currentRound = vm.ruleset.round_options[prevIndx];
        vm.showOnlyChoices = false;
      }
    },
    goToNextRound() {
      const vm = this;
      if (vm.currentRound === null) {
        // go to first round
        // Not sure about this logic, will need to test
        const lastRoundScoredInValue = _.last(_.map(vm.scoringEvents, 'round'));
        if (lastRoundScoredInValue) {
          vm.currentRound = _.find(vm.ruleset.round_options, ['value', lastRoundScoredInValue]);
        } else {
          vm.currentRound = _.head(vm.ruleset.round_options);
        }
      } else {
        // Implicit ordering; however that is just how rounds work (flows 1 to the other) so I think it's ok
        const roundIndex = _.findIndex(vm.ruleset.round_options, (ro) => ro.value === vm.currentRound.value);
        if (roundIndex > -1 && vm.ruleset.round_options.length > roundIndex + 1) {
          vm.currentRound = vm.ruleset.round_options[roundIndex + 1];

          if (vm.currentRound.criteria.has_choices) {
            vm.showOnlyChoices = true;
          } else {
            // clunky as hell, but works
            vm.showOnlyChoices = false;
            // if we don't have choices force them into the correct position
            // todo hide this choice from the timeline somehow?
            const choice = _.find(vm.ruleset.choice_options, (o) => o.value === vm.currentRound.criteria.after_use_position);
            vm.choiceTapped(choice, vm.match.wrestler);
            vm.choiceTapped(choice, vm.match.opponent);
          }
        } else {
          // todo setup this is the last round logic?
          vm.hasNextRound = false;
        }
      }
    },
    choiceTapped(choice, wrestler) {
      // choice has a name and value
      const vm = this;
      // construct the scoring event
      const scoreEvent = {
        match_id: vm.match.id,
        wrestler_profile_id: wrestler.id,
        score_type: choice.value,
        points: 0,
        opponent_points: 0,
        display_name: choice.name,
        round: vm.currentRound.value,
        round_display_name: vm.currentRound.name,
        slug: choice.slug,
        in_video_at: null,
      };
      vm.scoringEvents.push(scoreEvent);
      const hasCriteria = _.get(choice, 'criteria.after_use_position', null);
      if (hasCriteria) {
        // smart scoring only works due to criteria, if it doesn't exist (defer)
        // we still cannot proceed
        vm.showOnlyChoices = false;
      }
    },
    scoreOptionEventTapped(scoreOptionEvent, wrestlerId) {
      const vm = this;
      // construct the scoring event
      const scoreEvent = {
        match_id: vm.match.id,
        wrestler_profile_id: wrestlerId,
        score_type: scoreOptionEvent.value,
        slug: scoreOptionEvent.slug,
        points: scoreOptionEvent.points,
        opponent_points: scoreOptionEvent.opponent_points,
        display_name: scoreOptionEvent.name,
        round: vm.currentRound.value,
        round_display_name: vm.currentRound.name,
        in_video_at: null,
      };
      vm.scoringEvents.push(scoreEvent);
    },
    wrestlerScoringOptions(wrestlerId) {
      const vm = this;
      if (!wrestlerId || !vm.scoringEvents) {
        return [];
      }

      const position = vm.positionForWrestlerId(wrestlerId);
      return vm.scoringOptionsForPosition(position, wrestlerId);
    },
    eventIsAChoice(scoreEvent) {
      const choiceValues = _.map(this.ruleset.choice_options, 'value');
      return _.includes(choiceValues, scoreEvent.value);
    },
    positionForWrestlerId(wrestlerId) {
      const vm = this;

      // Walk through the sequence of scoring events to build this up
      let position = vm.getMatchStartPosition();
      vm.scoringEvents.forEach((e) => {
        const sType = e.score_type;
        const option = vm.getScoringOrChoiceOptionFromScoreType(sType);
        if (option && option.criteria) {
          // figure out which way to branch this
          const wrestlerScored = (e.wrestler_profile_id === wrestlerId);
          if (wrestlerScored) {
            const newPosition = _.get(option, 'criteria.after_use_position', null);
            if (newPosition) {
              position = newPosition;
            }
          } else {
            // handle scoring
            const newPosition = _.get(option, 'criteria.after_use_opponent_position', null);
            if (newPosition) {
              position = newPosition;
            }
          }
        }
      });

      return position;
    },
    getScoringOrChoiceOptionFromScoreType(scoreType) {
      const vm = this;
      if (!vm.ruleset) {
        return null;
      }

      // Scoring
      for (let i = 0; i < vm.ruleset.scoring_options.length; i++) {
        const pool = vm.ruleset.scoring_options[i];
        const choice = _.find(pool.events, { value: scoreType });
        if (choice) {
          return choice;
        }
      }

      // choices
      return _.find(vm.ruleset.choice_options, ['value', scoreType]);
    },
    countOfScoreEventsForWrestlerId(events, wrestlerId) {
      const sEvents = this.scoringEventsForWrestlerId(wrestlerId);
      let count = 0;
      sEvents.forEach((scoreEvent) => {
        for (let i = 0; i < events.length; i++) {
          const potentialEvent = events[i];
          if (potentialEvent.slug === scoreEvent.slug) {
            count++;
          }
        }
      });

      return count;
    },
    eventsInSharedUsePool(poolId, poolOfEvents) {
      return _.filter(poolOfEvents, ['criteria.shared_use_pool', poolId]);
    },
    recursivelyWalkEventChain(events, wrestlerId, allowedUses, poolOfEvents) {
      const vm = this;

      const sharedUsePoolId = _.get(_.head(events), 'criteria.shared_use_pool', null);
      if (sharedUsePoolId) {
        events = vm.eventsInSharedUsePool(sharedUsePoolId, poolOfEvents);
      }

      const numberOfUses = vm.countOfScoreEventsForWrestlerId(events, wrestlerId);
      // Base case
      if (!allowedUses || numberOfUses < allowedUses) {
        return events;
      }

      // Recurse
      const afterUses = _.flatten(_.uniq(_.map(events, (e) => _.get(e, 'criteria.after_use', []) || [])));

      const afEvents = _.filter(poolOfEvents, (ev) => _.includes(afterUses, ev.slug));
      // hack... but working accidentally at the moment. This assumes each pool has the same
      // step function of uses. I think that's a reasonable assumption, but it is implicit
      const newAllowedUses = _.get(_.head(afEvents), 'criteria.uses', null);
      return vm.recursivelyWalkEventChain(afEvents, wrestlerId, newAllowedUses, poolOfEvents);
    },
    scoringOptionsForPosition(position, wrestlerId) {
      const vm = this;
      if (!vm.ruleset) {
        return null;
      }
      const options = [];
      vm.ruleset.scoring_options.forEach((pool) => {
        const availableEvents = _.filter(pool.events, (ev) => {
          const evPos = _.get(ev, 'criteria.position');
          const eligiblePosition = (position === evPos || evPos === 'all_positions');

          const beforeUses = _.get(ev, 'criteria.before_use', []) || [];
          return eligiblePosition && beforeUses.length === 0;
        });

        // Now we have the state of the world at 0.
        // Now I need to walk forward on the criteria chain and splice in the events
        const events = [];
        availableEvents.forEach((avEvent) => {
          const uses = _.get(avEvent, 'criteria.uses', null);
          if (!uses) {
            // treat it as infinite if no uses present
            events.push(avEvent);
            return; // remember return in a js foreach does not break the loop, just the iteration
          }

          events.push(vm.recursivelyWalkEventChain([avEvent], wrestlerId, uses, pool.events));
        });

        options.push({
          pool_name: pool.pool_name,
          pool_slug: pool.pool_slug,
          events: _.uniq(_.flatten(events)),
        });
      });
      return options;
    },
    toggleEditing() {
      this.editing = !this.editing;
    },
    wrestlerName(wrestler) {
      if (wrestler) {
        const initial = wrestler.first_name.substring(0, 1);
        return `${initial}. ${wrestler.last_name}`;
      }

      return '';
    },
    getMatch() {
      const vm = this;
      vm.loading = true;

      const url = vm.$apiService.matchUrl(vm.matchId);
      axios.get(url)
        .then((response) => {
          vm.match = response.data;
          const ruleset = _.get(vm.match, 'ruleset', vm.defaultRuleset);
          const homeWrestlerId = _.get(vm.match, 'wrestler.id', null);
          const opponentId = _.get(vm.match, 'opponent.id', null);
          const assignedRedId = _.get(vm.match, 'red_wrestler_id', null);
          if (assignedRedId !== null) {
            vm.redWrestlerId = assignedRedId;
            vm.greenWrestlerId = (assignedRedId === homeWrestlerId) ? opponentId : homeWrestlerId;
          } else {
            vm.redWrestlerId = homeWrestlerId;
            vm.greenWrestlerId = opponentId;
            vm.match.red_wrestler_id = homeWrestlerId;
          }

          vm.getRuleset(ruleset);
          vm.getScoreEvents();
        })
        .catch((error) => {
          vm.errorMessage = 'Error retrieving score';
          vm.error = true;
        });
    },
    setRuleset(ruleset) {
      const vm = this;
      vm.loading = true;
      const params = {
        match: {
          ruleset: ruleset,
        }
      };

      const url = vm.$apiService.matchUrl(vm.match.id);
      axios.put(url, params)
          .then((response) => {
            vm.loading = false;
            vm.userPickedRuleset = true;
            vm.getRuleset(ruleset);
            vm.$notificationManager.$emit('show-toast', 'Ruleset saved', true);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
    },
    getRuleset(ruleset) {
      const vm = this;

      const url = vm.$apiService.rulesetUrl(ruleset);
      axios.get(url)
        .then((response) => {
          vm.ruleset = response.data.ruleset;
          vm.currentRound = null;
          vm.renderScore();
        })
        .catch((error) => {
          vm.errorMessage = 'Error retrieving scoring rules';
          vm.error = true;
        });
    },
    getScoreEvents() {
      const vm = this;

      const url = vm.$apiService.matchScoreEvents(vm.matchId);
      axios.get(url)
        .then((response) => {
          vm.scoringEvents = response.data.scoring_events;
          vm.renderScore();
        })
        .catch((error) => {
          vm.errorMessage = 'Error retrieving scoring events';
          vm.error = true;
        });
    },
    getMatchStartPosition() {
      const vm = this;
      const defaultChoice = _.find(vm.ruleset.choice_options, { default: true });
      return defaultChoice.value;
    },
    renderScore() {
      const vm = this;
      if (vm.scoringEvents && vm.ruleset) {
        // This is needed or else currentround breaks everything
        vm.goToNextRound();

        // If we don't yet have a score, setup the world a bit
        if (vm.canEdit && (vm.scoringEvents.length === 0 && !vm.hasOutcome)) {
          // Just show the editing form
          vm.editing = true;
        }
        vm.loading = false;
        vm.ready = true;
      }
    },
  },
};
</script>
