<template>
    <div class="reg-address">
        <div class="row">
            <div class="col-xs-12">
                <div role="form">
                    <h3>{{ prompt() }}</h3>
                    <div :class="getClass()">
                        <label :for="this.$srHelper.idFor('address1', this.srId)">
                            Address line 1
                        </label>
                        <input v-model="line1"
                               :id="this.$srHelper.idFor('address1', this.srId)"
                               autocomplete="address-line1"
                               type="text"
                               ref="line-1"
                               data-cy="address-line-1"
                               class="form-control">
                    </div>
                    <div class="form-group">
                        <label :for="this.$srHelper.idFor('address2', this.srId)">Address line 2 (optional)</label>
                        <input v-model="line2"
                               :id="this.$srHelper.idFor('address2', this.srId)"
                               type="text"
                               ref="line-2"
                               class="form-control"
                               data-cy="address-line-2"
                               autocomplete="address-line2"
                        >
                    </div>
                    <div :class="getClass()">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 m-b-only-xs-m">
                                <label :for="this.$srHelper.idFor('city', this.srId)">
                                    City
                                </label>
                                <input autocomplete="address-level2" v-model="city" :id="this.$srHelper.idFor('city', this.srId)" type="text" ref="city" data-cy="city" class="form-control">
                            </div>
                            <div class="col-xs-12 col-sm-2 m-b-only-xs-m">
                                <label :for="this.$srHelper.idFor('state', this.srId)">
                                    State
                                </label>
                                <the-mask
                                        v-model="state"
                                        mask="AA"
                                        type="text"
                                        class="form-control"
                                        ref="state"
                                        data-cy="state"
                                        autocomplete="address-level1"
                                        :id="this.$srHelper.idFor('state', this.srId)"
                                ></the-mask>
                            </div>
                            <div class="col-xs-12 col-sm-4">
                                <label :for="this.$srHelper.idFor('zip', this.srId)">
                                    Zip
                                </label>
                                <the-mask
                                        v-model="zip"
                                        mask="#####"
                                        type="tel"
                                        class="form-control"
                                        ref="zip"
                                        data-cy="zip"
                                        autocomplete="postal-code"
                                        :id="this.$srHelper.idFor('zip', this.srId)"
                                        @blur.native="validate()"
                                ></the-mask>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <p v-show="error" class="text-danger">
                                    {{ errorMessage }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
  name: 'reg-address-question',
  components: {
    TheMask,
  },
  props: [
    'question',
    'existingAnswer',
  ],
  data() {
    return {
      answer: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      },
      originalAnswer: null,
      dirty: false,
      valid: true,
      error: false,
      errorMessage: 'Please enter a valid address.',
      required: false,

      // This component is more complex since it's a composite answer of a bunch of fields
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: '',

      srId: '',
    };
  },
  watch: {
    line1() {
      this.handleChange();
    },
    line2() {
      this.handleChange();
    },
    city() {
      this.handleChange();
    },
    state() {
      this.handleChange();
    },
    zip() {
      this.handleChange();
    },
  },
  created() {
    const vm = this;
    if (vm.existingAnswer && vm.existingAnswer.answer !== '') {
      vm.originalAnswer = vm.existingAnswer.answer;
      vm.answer = vm.existingAnswer.answer;
      vm.line1 = vm.answer.line1;
      vm.line2 = vm.answer.line2;
      vm.city = vm.answer.city;
      vm.state = vm.answer.state;
      vm.zip = vm.answer.zip;
    }

    if (vm.question) {
      vm.required = vm.question.required;
      vm.srId = vm.question.id;
    }

    vm.calculateValid();
    vm.$notificationManager.$on('show-reg-errors', vm.validate);
  },
  destroyed() {
    this.$notificationManager.$off('show-reg-errors', this.validate);
  },
  methods: {
    emit() {
      const vm = this;
      vm.$emit('answer-changed', vm.question, vm.answer, vm.dirty, vm.valid);
    },
    getClass() {
      if (this.error) {
        return 'form-group has-error';
      }

      return 'form-group';
    },
    handleChange() {
      this.updateAnswer();
      this.setDirty();
      this.calculateValid();
      this.emit();
    },
    setDirty() {
      const vm = this;
      if (vm.originalAnswer === vm.answer) {
        vm.dirty = false;
      } else {
        vm.dirty = true;
      }
    },
    updateAnswer() {
      const vm = this;
      vm.answer.line1 = vm.line1;
      vm.answer.line2 = vm.line2;
      vm.answer.city = vm.city;
      vm.answer.state = vm.state;
      vm.answer.zip = vm.zip;
    },
    calculateValid() {
      const vm = this;
      const line1Valid = _.size(vm.answer.line1) > 0 || !vm.required;
      const cityValid = _.size(vm.answer.city) > 0 || !vm.required;
      const stateValid = _.size(vm.answer.state) === 2 || !vm.required;
      const zipValid = _.size(vm.answer.zip) === 5 || !vm.required;

      vm.valid = line1Valid && cityValid && stateValid && zipValid;
      if (vm.valid) {
        vm.validate();
      }
    },
    validate() {
      this.error = !this.valid;
    },
    prompt() {
      const vm = this;
      if (!vm.question) {
        return '';
      }

      let { prompt } = vm.question;

      if (!vm.required) {
        prompt += ' (optional)';
      }

      return prompt;
    },
  },
};
</script>
