<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="row">
        <div class="col-xs-6 col-md-8">
          <h1>
            Recurring Donor Report
          </h1>
          <p class="m-b-none"><small>
            Report generated on {{ processedAt() }}
          </small></p>
        </div>
        <div class="col-xs-6 col-md-4 text-right">
          <div class="ibox-tools">
            <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span
                class="hidden-xs">Download CSV</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th ref="emailHeader" class="first-col" v-bind:style="firstColStyles">Email</th>
              <th v-bind:style="secondColStyles">Recurring amount</th>
              <th>Interval</th>
              <th>Recurring donor since</th>
              <th>Next donation</th>
              <th>Billing partner id</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in subscriptions">
              <td class="first-col"
                  v-bind:style="firstColStyles">{{ row.billing_profile.email }}
              </td>
              <td v-bind:style="secondColStyles">{{ formatAsCurrency(totalFor(row)) }}</td>
              <td>{{ row.interval }}</td>
              <td>{{ formatDate(row.created_at) }}</td>
              <td>{{ formatDate(row.current_period_end_at)}}</td>
              <td>{{ row.billing_partner_id }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';

export default {
  name: 'recurring-donor-report',
  props: [
    'report',
  ],
  mixins: [downloadMix, formattingMixin],
  data() {
    return {
      subscriptions: [],

      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  mounted() {
    const vm = this;

    const subs = _.get(vm.report, 'result.billing_subscriptions.objects', null);
    vm.subscriptions = _.sortBy(subs, ['billing_profile.email']);

    vm.$nextTick(function () {
      const header = this.$refs.emailHeader;
      if (header) {
        const rect = header.getBoundingClientRect();
        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    totalFor(row) {
      return _.sumBy(row.billing_plans, 'amount');
    },
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    formatDate(date) {
      return moment(date).format('M/D/YYYY');
    },
    downloadCSV() {
      const vm = this;

      const rows = [];
      rows.push([
        'Email',
        'Recurring amount',
        'Interval',
        'Recurring donor since',
        'Next donation',
        'Billing partner id',
      ]);

      vm.subscriptions.forEach((sub) => {
        rows.push([
          sub.billing_profile.email,
          vm.formatAsCurrency(vm.totalFor(sub)),
          sub.interval,
          vm.formatDate(sub.created_at),
          vm.formatDate(sub.current_period_end_at),
          sub.billing_partner_id,
        ]);
      });

      const fileName = `Recurring Donor Report - ${vm.processedAt()}.csv`;
      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
