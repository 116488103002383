<template>
  <div role="form">
    <div :class="getClass()">
      <div>
        <input :id="pondId"
               type="file"
               ref="pond"
               multiple
        />
        <p v-show="error" class="text-danger">
          {{ errorMessage }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
// Import Vue FilePond
import * as FilePond from 'filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.js';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster';

// Import Pintura
import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  locale_en_gb,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,

  // filepond
  openEditor,
  processImage,
  createDefaultImageOrienter,
  blobToFile
} from '@pqina/pintura';

import RSVP from 'rsvp';
import heic2any from 'heic2any';

// here we set the available plugins
setPlugins(plugin_crop);

// Create FilePond component
FilePond.registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginImageEditor,
    FilePondPluginFileValidateSize,
    FilePondPluginFilePoster
);

// This is intended to be a general purpose pintura uploader as opposed to the profile photo which has specific structure.
// eventually this should get threaded into that as well
export default {
  name: 'pintura-photo-uploader',
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    accessKey: {
      type: String,
      required: true,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: true,
    },
    existingAttachments: {
      type: Array,
      default() {
        return [];
      },
    },
    prefix: {
      type: String,
      required: true,
    },
    imageCropAspectRatio: {
      type: String,
      default: undefined,
    },
    maxFiles: {
      type: Number,
      default: 1,
    },
    attachableId: {
      type: Number,
      required: false,
    },
    attachableType: {
      type: String,
      required: false,
    },
    pondId: {
      type: String,
      default: 'pintura-uploader-1'
    }
  },
  components: {
    FilePond,
    RSVP,
  },
  data() {
    const vm = this;

    const getKey = function (file) {
      const filename = file.name;
      vm.mFileName = filename;

      return new RSVP.Promise((resolve, reject) => {
        const params = {
          attachment: {
            name: filename,
            prefix: vm.prefix,
            attachable_id: vm.attachableId,
            attachable_type: vm.attachableType,
          },
        };
        const url = vm.$apiService.attachmentsUrl();
        axios.post(url, params)
            .then((response) => {
              let photo = {
                _destroy: false,
                amzDate: moment(),
                amzSignature: '',
                amzPolicy: '',
                id: response.data.id,
                file_name: response.data.name,
                s3_file_name: response.data.key,
                status: 'upload_pending',
              };
              vm.photos.push(photo);
              resolve(photo);
            })
            .catch((error) => {
              reject(error);
            });
      });
    };

    return {
      server: {
        load: (source, load, error, progress, abort, headers) => {
          let myRequest = new Request(source);
          fetch(myRequest).then(function(response) {
            response.blob().then(function(myBlob) {
              load(myBlob)
            });
          });
        },
        revert: (uniqueFileId, load, error) => {
          // TODO eventually delete from S3 that key so we don't store draft files.
          // use lodash findIndex to find the index of the photo based on the s3_file_name and then splice it out
          const index = vm.photos.findIndex((photo) => photo.s3_file_name === uniqueFileId);
          if (index !== -1) {
            vm.photos.splice(index, 1);
          }
          vm.emitChanges();
          // Should call the load method when done, no parameters required
          load();
        },
        remove: (source, load, error) => {
          const index = vm.photos.findIndex((photo) => source.includes(photo.s3_file_name));
          if (index !== -1) {
            // todo is this reactive?
            vm.photos[index]._destroy = true;
          }
          vm.emitChanges();
          // Should call the load method when done, no parameters required
          load();
        },
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          getKey(file)
              .then((photo) => {
                const sixDays = photo.amzDate.utc()
                    .add(6, 'days')
                    .format('YYYY-MM-DDTHH:mm:ss.SSS');
                const policy_params = {
                  expiration: `${sixDays}Z`,
                  conditions: [
                    { acl: 'private' },
                    { bucket: vm.bucket },
                    ['starts-with', '$key', `attachments/${vm.prefix}`],
                    /* [ "content-length-range", 0, 15728640 ], // allow up to 15 mb in size */
                    { 'x-amz-credential': vm.amzCredential(photo.amzDate) },
                    { 'x-amz-algorithm': 'AWS4-HMAC-SHA256' },
                    { 'x-amz-date': vm.amzDateAsLongISO(photo.amzDate) },
                  ],
                };
                axios.post(vm.$apiService.s3SignatureUrl(), policy_params)
                    .then((response) => {
                      // append this to the form with reactivity like so..
                      photo.amzPolicy = response.data.policy;
                      photo.amzSignature = response.data.signature;

                      const formData = new FormData();
                      formData.append('key', photo.s3_file_name);
                      formData.append('acl', 'private');
                      formData.append('X-Amz-Credential', vm.amzCredential(photo.amzDate));
                      formData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
                      formData.append('X-Amz-Date', vm.amzDateAsLongISO(photo.amzDate));
                      formData.append('Policy', photo.amzPolicy);
                      formData.append('X-Amz-Signature', photo.amzSignature);
                      formData.append('file', file, file.name);

                      const request = new XMLHttpRequest();
                      request.open('POST', `${vm.endpoint}/`);

                      // Should call the progress method to update the progress to 100% before calling load
                      // Setting computable to false switches the loading indicator to infinite mode
                      request.upload.onprogress = (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                      };

                      // Should call the load method when done and pass the returned server file id
                      // this server file id is then used later on when reverting or restoring a file
                      // so your server knows which file to return without exposing that info to the client
                      request.onload = function () {
                        if (request.status >= 200 && request.status < 300) {
                          const url = vm.$apiService.s3ConfirmUrl();
                          axios.post(url, { key: photo.s3_file_name })
                              .then((response) => {
                                // the load method accepts either a string (id) or an object
                                load(photo.s3_file_name);
                                photo.status = 'uploaded';
                                vm.emitChanges();
                              })
                              .catch((errorObject) => {
                                error('not again');
                              });
                        } else {
                          // Can call the error method if something is wrong, should exit after
                          error('oh no');
                        }
                      };

                      request.send(formData);

                      // Should expose an abort method so the request can be cancelled
                      return {
                        abort: () => {
                          // This function is entered if the user has tapped the cancel button
                          request.abort();

                          // Let FilePond know the request has been cancelled
                          abort();
                        },
                      };
                    })
                    .catch((errorObject) => {
                      console.log('s3 signature failed');
                      console.log(errorObject);
                      error('failed to upload');
                    });
              })
              .catch((error) => {
                // handle errors on getting the key
                error('failed to start uploading');
              });
        },
      },
      // Data we manipulate for the photos on the backend
      photos: [
          /* example object
          {
            id: null,
            s3_file_name: '',
            file_name: '',
            status: '',
            // S3 specific things
            amzDate: moment(),
            amzSignature: '',
            amzPolicy: '',
            amzKey: '',
           */
      ],
      // The raw files we manipulate with filepond
      files: [],

      error: false,
      errorMessage: 'Please upload a file.',
      required: false,
      srId: 'pintura-uploader',

      // Pintura editor
      pinturaEditor: {
        // used to create the editor, receives editor configuration, should return an editor instance
        createEditor: openEditor,

        // Required, used for reading the image data
        imageReader: [
          createDefaultImageReader,
          {
            // https://pqina.nl/pintura/docs/v8/examples/load-heic-image/
            preprocessImageFile: async (file, options, onprogress) => {
              // If is not of type HEIC we skip the file
              if (!/heic/.test(file.type)) return file;

              // Let's turn the HEIC image into JPEG so the browser can read it
              const blob = await heic2any({
                blob: file,
                toType: 'image/jpeg',
                quality: 0.94,
              });

              // The editor expects a File so let's convert our Blob
              return blobToFile(blob, file.name);
            }
          }
        ],

        // optionally. can leave out when not generating a preview thumbnail and/or output image
        // imageWriter: [createDefaultImageWriter],
        // optionally. can leave out when not generating a preview thumbnail and/or output image
        imageWriter: [
            createDefaultImageWriter,
        ],

        // used to generate poster images, runs an editor in the background
        imageProcessor: processImage,

        // editor options
        editorOptions: {
          imageCropAspectRatio: this.imageCropAspectRatio,
          cropSelectPresetOptions: [
            [undefined, 'Custom'],
            [1, 'Square'],
            [4 / 3, 'Landscape'],
            [3 / 4, 'Portrait'],
          ],
          createDefaultImageOrienter,
          locale: {
            ...locale_en_gb,
            ...plugin_crop_locale_en_gb,
          },
        },
      },
    };
  },
  computed: {
    inputSelector() {
      return `#${this.pondId}`;
    },
    bucket() {
      const bucketWithHost = this.endpoint.split('.')[0];
      return bucketWithHost.substr(8);
    },
  },
  created() {
    const vm = this;
    // loop through the photos passed in
    vm.existingAttachments.forEach((photo) => {
      vm.photos.push({
        id: photo.id,
        _destroy: false,
        amzDate: moment(),
        amzSignature: '',
        amzPolicy: '',
        amzKey: '',
        file_name: photo.name,
        s3_file_name: photo.key,
        status: 'uploaded',
      });

      const existingUrl = `${vm.cloudfrontBaseUrl}${photo.key}`;
      vm.files.push({
        // the server file reference
        source: existingUrl,
        // set type to local to indicate an already uploaded file
        options: {
          type: 'local',
          metadata: {
            poster: existingUrl,
          },
        },
      });
    });
  },
  mounted() {
    FilePond.create(document.querySelector(this.inputSelector), {
      filePosterMaxHeight: 512,
      imagePreviewHeight: 512,
      maxFiles: this.maxFiles,
      allowMultiple: this.maxFiles > 1,
      labelIdle: 'Tap to upload...',
      acceptedFileTypes: "image/*, application/pdf",
      maxFileSize: "15MB",
      credits: false,
      server: this.server,
      imageEditor: this.pinturaEditor,
      imageEditorInstantEdit: true,
      files: this.files,
    });
  },
  methods: {
    amzCredential(amzDate) {
      const date = amzDate.utc().format('YYYYMMDD');
      return `${this.accessKey}/${date}/us-west-2/s3/aws4_request`;
    },
    // I'm not sure why .toIsoFormat() didn't just work with aws s3 (maybe milliseconds), but this
    // is the closest format that matches their docs, which is a utc timestamp without any symbols in it
    amzDateAsLongISO(amzDate) {
      const utcIso = amzDate.utc().format('YYYYMMDDTHHmmss');
      return `${utcIso}Z`;
    },
    emitChanges() {
      this.$emit('photos-changed', this.photos);
    },
    getClass() {
      if (this.error) {
        return 'has-error';
      }

      return '';
    },
  },
};
</script>
