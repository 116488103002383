<template>
  <div role="form" class="border border-yellow-900 border-solid bg-yellow-50 p-m rounded-sm">
    <div class="text-20 font-heavy text-gray-700">Refund payment</div>
    <p class="text-sm text-gray-700">
      Refunds take 5-10 days to appear on a customer's statement. Fees for the original payment won't be
      returned, but there are no additional fees for the refund.
    </p>
    <p class="text-sm text-gray-700">
        WrestlingIQ will automatically send a refund receipt via email when you initiate a refund.
    </p>
    <div class="form-group mt-m">
      <label class="m-b-none">Refund</label>
      <div class="m-t-none m-b-xxs"><small>Maximum refund amount is {{ formatAsCurrency(possibleRefundAmount) }}</small></div>
      <money v-model="amount" v-bind="money" class="form-control"></money>
    </div>
    <div class="form-group">
      <label :for="idFor('reason_select')">Reason</label>
      <select v-model="reason" :id="idFor('reason_select')" class="form-control m-b" name="reason" required>
        <option selected disabled>Choose why you are refunding...</option>
        <option value="requested_by_customer">Requested by customer</option>
        <option value="duplicate">Duplicate</option>
        <option value="fraudulent">Fraudulent</option>
      </select>
    </div>
    <p v-if="error" class="text-danger">
      {{ errorMessage }}
    </p>
    <div class="flex gap-x-m m-t-sm">
      <button v-on:click="cancel" class="btn btn-default">Cancel</button>
      <ladda-button @lbClicked="createRefund" el-class="btn-warning" :loading="loading">
        Refund
      </ladda-button>
    </div>
  </div>
</template>
<script>
import { formattingMixin } from './mixins/formatting_mix';
import { Money } from 'v-money';
import { errorableMix } from './mixins/errorable_mix';
import LaddaButton from '../../shared/ladda_button.vue';
export default {
  name: 'refund-form',
  mixins: [formattingMixin, errorableMix],
  components: {
    Money,
    LaddaButton,
  },
  props: {
    charge: {
      type: Object,
      required: true,
    },
    // Note that the backend does validation on this, this is just for front end info
    possibleRefundAmount: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      amount: 0,
      reason: null,
      loading: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  methods: {
    idFor(name) {
      return `${name}_${this.charge.id}`;
    },
    cancel() {
      this.$parent.$emit('cancel-refund', this.charge);
    },
    createRefund: _.throttle(function() {
      const vm = this;
      if (vm.amount === 0) {
        vm.errorMessage = "Refund must be greater than zero."
        vm.error = true;
        return;
      }

      if (vm.reason === null) {
        vm.errorMessage = "Please select a reason for the refund."
        vm.error = true;
        return;
      }

      if (vm.loading) {
        return;
      }

      vm.loading = true;

      const params = {
        refund: {
          charge_id: vm.charge.id,
          amount: _.round(vm.amount * 100),
          reason: vm.reason
        }
      }
      const url = vm.$apiService.refundUrl();
      axios.post(url, params)
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', "Refund initiated...", true);
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = vm.parseErrorResponse(error);
            vm.error = true;
          });

    }, 500),
  }
};
</script>
<style scoped>
</style>
