<template>
    <div id="signature">
        <div id="signature-pad">
            <div class="signature-body">
                <canvas data-cy="canvas"></canvas>
            </div>
        </div>

        <div class="clearfix"></div>

        <div class="m-t-sm">
            <div class="pull-left">
                <button v-on:click="clear" type="button" class="btn btn-default">
                    <i class="fa fa-times"></i>
                    Clear
                </button>
            </div>
            <div class="button-area pull-right">
                <div v-show="error">
                    <small>
                        <span class="text-danger">{{ errorMessage }}</span>
                    </small>
                </div>
                <ladda-button data-cy="sig-submit" @lbClicked="pingServer" el-class="btn-primary" :loading="loading">
                    Submit Signature
                </ladda-button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import SignaturePad from 'signature_pad';
import FineUploaderS3 from 'fine-uploader-wrappers/s3';
import RSVP from 'rsvp';
import LaddaButton from '../../shared/ladda_button.vue';
import { funnelMixin } from './mixins/funnel_mix';

export default {
  components: {
    LaddaButton,
    FineUploaderS3,
    RSVP,
    SignaturePad,
  },
  mixins: [funnelMixin],
  name: 'signature',
  props: [
    'funnel',
    'agreements',
    'endpoint',
    'accessKey',
    'authToken',
    'userId',
    'profileId',
    'profileType',
  ],
  data() {
    const vm = this;

    const getKey = function (fileId) {
      const filename = vm.filename();
      const keyRetrieval = new RSVP.Promise((resolve, reject) => {
        const params = {
          attachment: {
            name: filename,
            prefix: 'signatures/',
          },
        };
        const url = vm.$apiService.attachmentsUrl();
        axios.post(url, params)
          .then((response) => {
            vm.attachmentId = response.data.id;
            resolve(response.data.key);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return keyRetrieval;
    };

    const uploader = new FineUploaderS3({
      options: {
        request: {
          endpoint: vm.endpoint,
          accessKey: vm.accessKey,
        },
        maxConnections: 1,
        signature: {
          endpoint: '/api/v1/attachments/s3_signature',
          customHeaders: {
            Authorization: `Bearer ${vm.authToken}`,
          },
        },
        uploadSuccess: {
          endpoint: '/api/v1/attachments/s3_confirm',
          customHeaders: {
            Authorization: `Bearer ${vm.authToken}`,
          },
        },
        objectProperties: {
          // acl: 'public-read',
          key: getKey,
        },
        chunking: {
          enabled: true,
        },
        resume: {
          // Resume is now disabled because the UI is not handling it correctly
          // to see the bug start an upload with pause enabled, then refresh the page
          // now try to upload again and you will see that the video does not start uploading
          enabled: false,
        },
      },
    });

    return {
      pad: null,

      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      attachmentId: null,
      uploader,
    };
  },
  mounted() {
    const vm = this;
    const canvas = document.querySelector('canvas');
    vm.pad = new SignaturePad(canvas);

    const mobile = window.matchMedia('only screen and (max-width: 1024px)');
    // todo add removal of these event listeners potentially
    if (mobile.matches) {
      // Only resize the canvas on orientation change for mobile browsers and tablets
      window.addEventListener('orientationchange', () => {
        vm.refreshCanvas(vm.pad);
      });
    } else {
      // resize on all window events for all other browsers (does not fire as rapidly as mobiles)
      window.addEventListener('resize', () => {
        vm.refreshCanvas(vm.pad);
      });
    }
    vm.refreshCanvas(vm.pad);

    vm.uploader.on('complete', (id, name, response) => {
      // handle completed upload
      if (response.success) {
        vm.$notificationManager.$emit('attachment-uploaded', id);
        vm.postToServer();
      } else {
        vm.error = true;
        vm.errorMessage = 'Error, please try again later or contact support. Failed to upload document.';
        vm.loading = false;
      }
    });
  },
  methods: {
    clear() {
      this.pad.clear();
    },
    signatureValid() {
      // https://github.com/szimek/signature_pad/issues/322

      const vm = this;
      const groups = vm.pad.toData();

      if (groups.length === 0) {
        return false;
      }

      let valid = false;

      groups.forEach((group) => {
        if (group.length > 5) {
          valid = true;
        }
      });
      return valid;
    },
    pingServer() {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (!vm.signatureValid()) {
        vm.error = true;
        vm.errorMessage = 'Signature required';
        return;
      }

      vm.loading = true;

      const canvas = document.querySelector('canvas');
      const wrapper = {};
      wrapper.canvas = canvas;
      wrapper.name = vm.filename();
      wrapper.type = 'image/png';
      vm.uploader.methods.addFiles([wrapper]);
    },
    filename() {
      return `${this.userId}.png`;
    },
    postToServer() {
      const vm = this;
      const agreementIds = [];

      vm.agreements.forEach((agreement) => {
        agreementIds.push(agreement.id);
      });
      const params = {
        signature: {
          agreement_ids: agreementIds,
          attachment_id: vm.attachmentId,
          profile_id: vm.profileId,
          profile_type: vm.profileType,
        },
      };

      const url = vm.$apiService.signatureUrl();
      axios.post(url, params)
        .then((response) => {
          response = response.data;
          vm.$notificationManager.$emit('signature-created', response.id);
          vm.$notificationManager.$emit('show-toast', 'Signature uploaded', true);
          if (vm.funnel) {
            vm.goToNextFunnelStep();
          } else {
            vm.loading = false;
            window.location.assign('/documents');
          }
        })
        .catch((error) => {
          vm.loading = false;

          vm.errorMessage = `Error, please try again later or contact support. ${error.toString()}`;
          vm.error = true;
        });
    },
    refreshCanvas(signaturePad) {
      // copy the data url first so we can put it back after we resize
      const dataUrl = signaturePad.toDataURL();

      // Fix up the signature pad to the new size
      const canvas = document.querySelector('canvas');
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d').scale(ratio, ratio);

      signaturePad.clear();
      signaturePad.fromDataURL(dataUrl);
    },
  },
};
</script>
