<template>
    <div>
        <template v-if="playable">
            <video id="video" class="video-js vjs-big-play-centered"
                   controls autoplay preload="auto" width="320" height="124"
                   data-setup='{ "fluid":true }'>
                <source :src="primaryUrl" type="application/x-mpegURL" />
                <source :src="fallbackUrl" type="video/mp4" />
                <p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>
            </video>
        </template>
        <template v-else>
            <div class="alert alert-info">
              <i class="fa fa-magic"></i> Video is processing, check back soon.
            </div>
        </template>
    </div>
</template>

<script>
import videojs from 'video.js';
import '../../src/plugins/videojs.hotkeys.js';

export default {
  name: 'video-player',
  props: [
    'playable',
    'primaryUrl',
    'fallbackUrl',
  ],
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    const vm = this;
    vm.createVideoPlayer();

    $(document).on('click', '.video-seeker', function (event) {
      event.preventDefault();
      // this returns something like :04 or 1:40
      const hashText = $(this).prop('hash').slice(1);
      let seekTimeInSeconds = vm.parseSeekTimestamp(hashText);
      if (seekTimeInSeconds !== null) {
        vm.seekTo(seekTimeInSeconds);
      }
    });

    $(window).on('resize', () => {
      if ((screen.availHeight || screen.height - 30) <= window.innerHeight) {
        // Browser is full screen
        $('body').addClass('fullscreen');
      } else {
        $('body').removeClass('fullscreen');
      }
    });

    // A way for other Vue components to natively do this instead of the hacky way above
    vm.$notificationManager.$on('video-seek-to', vm.seekTo);
  },
  destroyed() {
    this.$notificationManager.$off('video-seek-to', this.seekTo);
  },
  methods: {
    createVideoPlayer() {
      let showTheaterMode = false;
      if ($(window).width() > 1000) {
        // markVideoAsFixed();
        showTheaterMode = true;
      }

      if (this.playable) {
        this.player = videojs('video', {
          html5: {
            vhs: {
              overrideNative: !videojs.browser.IS_SAFARI
            },
            nativeAudioTracks: false,
            nativeVideoTracks: false
          },
          playbackRates: [0.25, 0.5, 1, 1.5, 2],
        });

        const qualityLevels = this.player.qualityLevels();
        this.player.controlBar.addChild('QualitySwitcher', { levels: qualityLevels }, 8);
        if (showTheaterMode) {
          this.player.controlBar.addChild('TheaterButton', {}, 11);
        }
        this.player.ready(function () {
          this.hotkeys({
            alwaysCaptureHotkeys: true,
            enableFullscreen: false,
          });
        });
        $('#video').focus();
      }
    },
    seekTo(seconds) {
      $('#video')[0].scrollIntoView({ behavior: 'smooth' });
      this.player.currentTime(seconds);
      this.player.play();
    },
    parseSeekTimestamp(text) {
      // text must be in the form of something like :04 or 1:40, with a colon in the middle
      let timecomponents = text.split(':');

      // Filter out empty strings or only white space strings
      timecomponents = timecomponents.filter((entry) => /\S/.test(entry));

      // Only seek if we have a valid time hash
      if (timecomponents.length === 1 || timecomponents.length === 2) {
        let minutes = 0;
        let seconds = 0;
        if (timecomponents.length === 1) {
          // Only seconds were supplied
          seconds = parseInt(timecomponents[0]);
        } else {
          // Both minutes and seconds were supplied
          minutes = parseInt(timecomponents[0]);
          seconds = parseInt(timecomponents[1]);
        }

        // Seek time in seconds
        return (minutes * 60) + seconds;
      }

      return null;
    }
  }
};
</script>
