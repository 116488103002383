<template>
    <div class="search-result agreement-result">
        <template v-if="result">
            <div v-if="!noHr" class='hr-line-dashed'></div>
            <div class="row">
                <div class="col-xs-10">
                    <h3>
                        <template v-if="signed">
                            <a :href="showUrl()">
                                {{ result.document_version.document_name }}
                            </a>
                        </template>
                        <template v-else>
                            <span class="text-default">
                                {{ result.document_version.document_name }}
                            </span>
                        </template>
                    </h3>
                    <p :class="subheadClass()">
                        {{ subhead() }}
                    </p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

export default {
  name: 'agreement',
  props: [
    'result',
    'noHr',
  ],
  data() {
    return {
      signed: false,
    };
  },
  mounted() {
    const vm = this;
    if (vm.result.agreed_at) {
      vm.signed = true;
    }
  },
  methods: {
    subheadClass() {
      if (this.signed) {
        return 'search-type-label';
      }

      return 'search-type-label text-danger';
    },
    subhead() {
      if (this.signed) {
        const date = moment(this.result.agreed_at);
        return `Signed ${date.format('MMMM Do YYYY')}`;
      }
      return 'Not signed';
    },
    showUrl() {
      return `/agreements/${this.result.id}/print`;
    },
  },
};
</script>
