<template>
    <div class="agreement-signature">
        <div class="ibox agreements">
            <div class="ibox-title">
                <h1>{{ title() }}</h1>
                <div class="ibox-tools">
                    <spinner v-if="loading"></spinner>
                </div>
            </div>
            <div class="ibox-content">
                <template v-if="activeAgreementExists()">
                    <div class="trix-content">
                        <div v-html="activeAgreement.document_version.content"></div>
                    </div>
                    <div class="hr-line-dashed"></div>
                    <div class="form-group m-t-lg agree-box">
                        <div class="pull-left">
                            <div v-if="isLiabilityForm()">
                                <strong>Wrestler: </strong> {{ liabilityFormName() }}
                            </div>
                            <div v-if="signeeName">
                                <strong>Signed: </strong> {{ signeeName }}
                            </div>
                            <div>
                                <strong>Date: </strong> {{ today() }}
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="pull-left m-t-sm">
                            <div>
                                <input type="checkbox" id="agree_checkbox" v-model="agreeChecked" data-cy="agreement-check"> I agree
                                <div v-if="agreeError">
                                    <small class="text-danger">
                                        {{ agreeErrorMessage }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="pull-right">
                            <button name="button" v-on:click="nextAgreement" class="btn btn-primary" data-cy="submit">
                                Next
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </template>
                <template v-else-if="showSignature">
                    <signature
                            :funnel="funnel"
                            :agreements="agreements"
                            :endpoint="endpoint"
                            :accessKey="accessKey"
                            :authToken="authToken"
                            :userId="userId"
                            :profileId="profileId"
                            :profileType="profileType"
                    ></signature>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from './spinner.vue';
import Signature from './signature.vue';

export default {
  name: 'agreement-signature',
  components: {
    Signature,
    Spinner,
  },
  props: [
    'funnel',
    'endpoint',
    'accessKey',
    'authToken',
    'userId',
    'profileId',
    'profileType',
    'signeeName',
  ],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: '',

      agreements: null,
      activeAgreement: null,
      agreeChecked: false,
      agreeError: false,
      agreeErrorMessage: 'You must check the "I agree" box before continuing.',

      showSignature: false,
    };
  },
  created() {
    const vm = this;
    vm.loadAllDocs();
  },
  methods: {
    activeAgreementExists() {
      return this.activeAgreement;
    },
    today() {
      return moment().format('M/D/YYYY');
    },
    liabilityFormName() {
      const lp = this.activeAgreement.liability_profile;
      return `${lp.first_name} ${lp.last_name}`;
    },
    isLiabilityForm() {
      const vm = this;
      if (vm.activeAgreement == null) {
        return false;
      }

      if (vm.activeAgreement.liability_profile != null) {
        return true;
      }

      return false;
    },
    nextAgreement() {
      const vm = this;
      if (vm.agreeChecked === false) {
        vm.agreeError = true;
      } else {
        vm.agreeError = false;
        vm.moveToNextAgreement();
      }
    },
    moveToNextAgreement() {
      const vm = this;
      let nextAgreementIndx = null;

      for (let i = 0; i < vm.agreements.length; i++) {
        const agreement = vm.agreements[i];
        if (agreement.id === vm.activeAgreement.id) {
          agreement.status = 'agreed';
          nextAgreementIndx = i + 1;
          break;
        }
      }

      // bounds check
      if (nextAgreementIndx < vm.agreements.length) {
        vm.agreeChecked = false;
        vm.activeAgreement = vm.agreements[nextAgreementIndx];
      } else {
        vm.activeAgreement = null;
        vm.showSignature = true;
      }

      scroll(0, 0);
    },
    title() {
      const vm = this;
      if (vm.agreements === null) {
        return 'Loading...';
      } if (vm.showSignature) {
        return 'Sign';
      } if (!vm.activeAgreementExists()) {
        return '';
      }

      return vm.activeAgreement.document_version.document_name;
    },
    loadAllDocs() {
      const vm = this;
      vm.loading = true;
      let url = vm.$apiService.wrestlerPendingAgreementsUrl(vm.profileId);
      // todo make this work for coaches too if we require docs for them
      if (vm.profileType === 'ParentProfile') {
        url = vm.$apiService.parentPendingAgreementsUrl(vm.profileId);
      } else if (vm.profileType === 'CoachProfile') {
        url = vm.$apiService.coachPendingAgreementsUrl(vm.profileId);
      }
      vm.$apiService.loadAllPages(url, 'agreements')
        .then((agreements) => {
          vm.agreements = agreements;
          if (vm.agreements.length === 0) {
            window.location.assign('/documents');
          }
          vm.activeAgreement = vm.agreements[0];
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving agreements ${error.toString()}`;
          vm.error = true;
        });
    },
  },
};
</script>
