<template>
  <div role="form">
    <div class="form-group">
      <label>Name</label>
      <input v-model="name" type="text" class="form-control">
    </div>
    <div class="form-group">
      <label class="m-r-sm">
        Liability Form
        <VDropdown theme="info-tooltip">
          <!-- This will be the popover target (for the events and position) -->
          <i class="fa fa-question"></i>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div>
              <p class="header">
                Liability forms are signed by parents on behalf of their children.
              </p>
              <p>
                When a parent registers a kid, the parent will have to sign a liability form for each kid they register.
                This ensures you have a liability form for each participant.
              </p>
              <p>
                <a href="https://help.wrestlingiq.com/article/51-whats-the-difference-between-a-liability-form-and-a-signature-required-form"
                   target="_blank">Read more&nbsp;&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i></a>
              </p>
            </div>
          </template>
        </VDropdown>
      </label>
      <input type="checkbox" id="liability_checkbox" v-model="isLiability"> &nbsp;Yes
    </div>
    <div class="form-group">
      <label class="m-r-sm">
        Signature
        <VDropdown theme="info-tooltip">
          <!-- This will be the popover target (for the events and position) -->
          <i class="fa fa-question"></i>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div>
              <p class="header">
                These forms are signed by the user (wrestlers or parents)
              </p>
              <p>
                Unlike Liability Forms, these are the forms that the currently logged in user needs to sign on their own
                behalf.
                Users are unable to use WrestlingIQ until they have signed all required documents.
              </p>
              <p>
                This is often useful for things like concussion forms, team rules, etc.
              </p>
              <p>
                <a href="https://help.wrestlingiq.com/article/51-whats-the-difference-between-a-liability-form-and-a-signature-required-form"
                   target="_blank">Read more&nbsp;&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i></a>
              </p>
            </div>
          </template>
        </VDropdown>
      </label>
      <input type="checkbox" :disabled="isLiability" id="signature_checkbox" v-model="needsSignature"> &nbsp;Required
    </div>
    <div class="form-group">
      <label>Content</label>
      <input id="x-editor" type="hidden" name="content">
      <trix-editor input="x-editor" class="trix-content"></trix-editor>
    </div>
    <div class="button-area text-right">
      <div v-show="error">
        <small>
          <span class="text-danger">{{ errorMessage }}</span>
        </small>
      </div>
      <ladda-button @lbClicked="pingServer" el-class="btn-primary" :loading="loading">
        {{ buttonText() }}
      </ladda-button>
    </div>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { trixFileAttachmentMix } from './mixins/trix_file_attachment_mix';

export default {
  name: 'document-form',
  components: {
    LaddaButton,
  },
  mixins: [trixFileAttachmentMix],
  props: {
    docType: {
      type: String,
      default: null,
    },
    existingDocumentId: {
      type: Number,
      default: null,
    },
    existingDocumentName: {
      type: String,
      default: null,
    },
    existingDocumentContent: {
      type: String,
      default: null,
    },
    existingDocumentNeedsSig: {
      type: Boolean,
      default: false,
    },
    existingDocumentIsLiability: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    const vm = this;

    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      name: '',
      docId: null,
      content: '',
      needsSignature: false,
      isLiability: false,
    };
  },
  watch: {
    // whenever isLiability changes, this function will run
    isLiability(newIsLiability) {
      if (newIsLiability) {
        this.needsSignature = true;
      }
    },
  },
  mounted() {
    const vm = this;

    if (vm.existingDocumentName) {
      vm.name = vm.existingDocumentName;
    }

    if (vm.existingDocumentId) {
      vm.docId = vm.existingDocumentId;
    }
    if (vm.existingDocumentNeedsSig || vm.docType === 'signature') {
      vm.needsSignature = true;
    }

    if (vm.existingDocumentIsLiability || vm.docType === 'liability') {
      vm.isLiability = true;
    }

    document.addEventListener('trix-initialize', vm.trixInitHandler);
  },
  destroyed() {
    document.removeEventListener('trix-initialize', this.trixInitHandler);
  },
  methods: {
    trixInitHandler(event) {
      if (this.existingDocumentContent) {
        this.content = this.existingDocumentContent;
        const { editor } = event.target;
        editor.loadHTML(this.content);
      }
    },
    validateNotEmpty(name, content) {
      const vm = this;
      let errorMessage = 'Please ensure ';
      let error = false;
      if (name.length === 0) {
        errorMessage += 'name, ';
        error = true;
      }
      if (content.length === 0) {
        errorMessage += 'content, ';
        error = true;
      }
      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        return false;
      }
      return true;
    },
    buttonText() {
      if (this.docId != null) {
        return 'Update Document';
      }
      return 'Create Document';
    },
    successHandler(name, id) {
      const vm = this;
      vm.loading = false;
      vm.$notificationManager.$emit('show-toast', `${name} saved`, true);
      window.location.assign(`/documents?recently_saved=${id}`);
    },
    errorHandler() {
      const vm = this;
      vm.loading = false;
      vm.errorMessage = 'Error, please try again later or contact support.';
      vm.error = true;
    },
    pingServer: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      const { name } = vm;
      const content = $('#x-editor').val();

      if (vm.validateNotEmpty(name, content)) {
        vm.loading = true;

        const params = {
          document: {
            name,
            needs_signature: vm.needsSignature,
            liability_form: vm.isLiability,
            document_versions_attributes: [
              {
                content,
              },
            ],
          },
        };

        if (vm.docId != null) {
          // PUT
          const url = vm.$apiService.updateDocumentUrl(vm.docId);
          axios.put(url, params)
            .then((response) => {
              vm.successHandler(name, vm.docId);
            })
            .catch((error) => {
              vm.errorHandler();
            });
        } else {
          // POST
          const url = vm.$apiService.createDocumentUrl();
          axios.post(url, params)
            .then((response) => {
              const { data } = response;
              vm.successHandler(data.name, data.id);
            })
            .catch((error) => {
              vm.errorHandler();
            });
        }
      }
    }, 500),
  },
};
</script>
