<template>
<!-- ['Wrestler(s)', 'Billed to', 'Amount', 'Subscription info', 'Actions']-->
  <tbody class="border border-gray-100 border-b border-t-none border-solid">
  <tr class="relative">
    <td class="text-gray-700 cell-max-w-250-lg" data-title="Wrestler(s)">
      <div v-for="wrestler in wrestlerProfiles">
        <div class="flex flex-wrap gap-x-m">
          <a :href="`/wrestlers/${wrestler.id}`">
            {{ wrestler.full_name }}
          </a>
          <wrestler-rosters :tags-only="true" :for-wrestler-profile="wrestler" :can-edit="allowEdits"></wrestler-rosters>
        </div>
      </div>
    </td>
    <td class="text-gray-700" data-title="Billed to">
      <div v-html="parentName(true)"></div>
    </td>
    <td class="text-gray-700" data-title="Amount">
      <div>
        {{ formatAsCurrency(subscriptionTotal()) }}{{ subInterval() }}
      </div>
    </td>
    <td class="text-gray-700" data-title="Subscription info">
      <div class="flex flex-col">
        <div class="flex flex-col gap-y-xxs">
          <div v-if="subscription.payment_collection_status === 'paused'">
            <div class="flex w-fit align-items-center p-xxs gap-x-xs bg-yellow-50 text-yellow-900 rounded-xs border-yellow-500 border border-solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                <path d="M4.5 2a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-1ZM10.5 2a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-1Z" />
              </svg>
              <span class="text-10 uppercase tracking-wide">
                Paused
              </span>
            </div>
          </div>
          <div v-if="coupon" style="margin-bottom: -4px;">
            <VDropdown theme="info-tooltip">
              <!-- This will be the popover target (for the events and position) -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-purple-600">
                <path fill-rule="evenodd" d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 0 1-.375.65 2.249 2.249 0 0 0 0 3.898.75.75 0 0 1 .375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 17.625v-3.026a.75.75 0 0 1 .374-.65 2.249 2.249 0 0 0 0-3.898.75.75 0 0 1-.374-.65V6.375Zm15-1.125a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0v.75a.75.75 0 0 0 1.5 0v-.75Zm-.75 3a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0v-.75a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-.75ZM6 12a.75.75 0 0 1 .75-.75H12a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 12Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clip-rule="evenodd" />
              </svg>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <p class="header">
                    Scholarship code {{ coupon.code }} applied
                  </p>
                </div>
              </template>
            </VDropdown>
          </div>
          <div>{{ cleanPlanNames() }}</div>
          <p class="m-t-none-important m-b-none-important">
            {{ nextDue() }}
          </p>
          <p class="m-t-none-important m-b-none-important">
            {{ since() }}
          </p>
          <p v-if="subscription.expires_on" class="m-t-none-important m-b-none-important">
            Expires on {{ expiresOn() }}
          </p>
        </div>
      </div>
    </td>
    <td class="cell-max-w-250-lg" data-title="Actions">
      <a :href="`/subscriptions/${subscription.billing_partner_id}`"
         class="btn no-underline btn-compact bg-white border border-gray-400 text-gray-700">
          <span class="text-12">
              View Subscription
            </span>
      </a>
    </td>
  </tr>
</tbody>
</template>
<script>

import { formattingMixin } from './mixins/formatting_mix';
import { billingPlanMix } from './mixins/billing_plan_mix';
import WrestlerRosters from './wrestler_rosters.vue';

export default {
  name: 'subscription-row',
  mixins: [formattingMixin, billingPlanMix],
  components: {
    WrestlerRosters,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    allowEdits: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    coupon() {
      return _.first(this.subscription?.coupons);
    },
    wrestlerProfiles() {
      let items = _.filter(this.subscription.billing_plan_subscription_items, (si) => {
        const plan = this.planForItem(si);
        return plan && !plan.is_wiq_fee && !plan.is_tax_item;
      });

      return _.flatMap(items, (si) => {
        return si.wrestler_profiles;
      });
    },
  }
}
</script>
