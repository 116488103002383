<template>
    <div>
        <div v-show="expanded" class="form-bordered">
            <div class="form-group">
                <label for="option_name">Name</label>
                <input id="option_name" v-model="name" type="text" class="form-control">
            </div>
            <div class="form-group">
                <label>Allowed payment methods</label>
                <select v-model="allowedPaymentMethods" class="form-control" name="payment_method">
                    <option selected disabled>Choose payment method...</option>
                    <option value="card">Credit or debit only</option>
                    <option value="all">All (cash, check, credit/debit)</option>
                </select>
            </div>
            <div class="form-group" v-if="!allowClassPass">
                <label>One time charge or installments?</label>
                <select v-model="paymentStrategy" class="form-control" name="payment_strategy">
                    <option selected disabled>Choose payment strategy...</option>
                    <option value="once">One time charge</option>
                    <option value="installments">Installments</option>
                </select>
            </div>
            <template v-if="oneTimeCharge">
                <div class="form-group">
                    <label>Price</label>
                    <money v-model="price" v-bind="money" class="form-control"></money>
                </div>
            </template>
            <template v-else>
                <div class="form-group">
                    <label>
                        Installment Dates
                    </label>
                    <div class="m-l-xs">
                        <div v-for="(installment, index) in installmentDates">
                            <div class="font-heavy">
                                Installment #{{index + 1}}
                            </div>
                            <div>
                                <div class="form-group">
                                    <label class="font-normal">Amount</label>
                                    <money v-model="installment.price" v-bind="money" class="form-control"></money>
                                </div>
                                <div class="form-group">
                                    <label class="font-normal">Date</label>
                                    <div>
                                      <date-picker v-model="installment.date" :date="installment.date"></date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-bordered-dashed m-t-xs">
                            <a @click.prevent="addNewInstallment"><i class="fa fa-plus"></i> Installment date</a>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="allowClassPass">
                <div class="form-group">
                    <label>How many passes does this payment option grant the wrestler?</label>
                    <input type="number" v-model.number="passCount" class="form-control">
                </div>
            </template>
            <div class="form-group" v-if="allowSibDiscount && !allowClassPass">
                <label class="m-r-sm">
                    Do you offer a sibling discount?
                </label>
                <input type="checkbox" v-model="siblingDiscountEnabled" :aria-checked="siblingDiscountEnabled">
                <div class="alert alert-warning" v-if="!oneTimeCharge">
                    With installments, the sibling discount is applied to every installment.
                    <!-- todo link to help article -->
                </div>
                <div class="m-l-md" v-show="siblingDiscountEnabled">
                    <div class="form-group">
                        <label>Discount per additional wrestler</label>
                        <money v-model="discountPerWrestler" v-bind="money" class="form-control"></money>
                    </div>
                    <div class="form-group">
                        <label >After how many siblings should this discount apply?</label>
                        <!-- todo make this default to 1 -->
                        <select v-model="siblingCountForDiscount" class="form-control" name="sibling_discount">
                            <option value="1">1 (every registration except first child gets this discount)</option>
                            <option value="2">2 (3rd child and beyond gets this discount)</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="button-area">
                <div v-show="error">
                    <small>
                        <span class="text-danger">{{ errorMessage }}</span>
                    </small>
                </div>
                <button class="btn btn-default" @click.prevent="cancel">Cancel</button>
                <button class="btn btn-primary" @click.prevent="validate">
                    Done
                </button>
            </div>
        </div>
        <div v-show="!expanded">
            <div class="card-bordered">
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        {{ name }}<span class="text-success" v-if="draft"> - Draft</span><br/>
                        {{ prettyStrategy }} {{ prettyPrice }}
                    </div>
                    <div class="col-xs-12 col-sm-6 actions">
                        <a @click.prevent="toggleExpanded">Edit</a>
                        <a v-if="!existingId" class="text-danger" @click.prevent="removeDraft">Discard</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Money } from 'v-money';
import DatePicker from './date_picker.vue';
import { formattingMixin } from './mixins/formatting_mix';

export default {
  name: 'payment-option-form',
  mixins: [formattingMixin],
  components: {
    DatePicker,
    Money,
  },
  props: {
    paymentOption: {
      type: Object,
      required: true,
    },
    allowSibDiscount: {
      type: Boolean,
      default: true,
    },
    allowClassPass: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    prettyPrice() {
      const price = this.oneTimeCharge ? this.price : this.installmentTotal;
      return this.formatAsCurrency(price * 100);
    },
    prettyStrategy() {
      const vm = this;
      if (vm.oneTimeCharge) {
        return 'One time charge of';
      }

      return `${vm.installmentDates.length} installments totaling`;
    },
    oneTimeCharge() {
      return this.paymentStrategy === 'once';
    },
    installmentTotal() {
      return _.sumBy(this.installmentDates, 'price');
    },
  },
  data() {
    return {
      error: false,
      errorMessage: '',
      draft: false,

      existingId: null,
      name: '',
      price: 0,
      passCount: 1,
      allowedPaymentMethods: 'card',
      paymentStrategy: 'once',
      discountPerWrestler: 0,
      siblingDiscountEnabled: false,
      siblingCountForDiscount: 1,

      installmentDates: [],

      isNewOption: false,

      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },

      expanded: false,
    };
  },
  mounted() {
    const vm = this;
    // The reactivity on this is fucked. This was one of the very first components I made and this changing of prop info
    // appears to have bad things happen only on Microsoft Edge when replicating sessions. Since this component
    // emits when finished, let's just clone it right out of the gates.
    const po = _.cloneDeep(vm.paymentOption);

    vm.existingId = _.get(po, 'id', null);
    vm.name = _.get(po, 'name', '');
    vm.price = _.get(po, 'price', 0);

    vm.passCount = _.get(po, 'class_pass_quantity', 1);
    // If we receive something that does need a class pass count, but for some reason has no quantity set (_.get will return null if null is set),
    // make sure we at least set it to 1
    if (vm.passCount == null && vm.allowClassPass) {
      vm.passCount = 1;
    }
    // v money expects a float, api returns cents
    if (vm.price > 0) {
      vm.price /= 100.00;
    }
    vm.allowedPaymentMethods = _.get(po, 'allowed_payment_methods', 'card');
    vm.discountPerWrestler = _.get(po, 'discount_per_extra_wrestler', 0);
    if (vm.discountPerWrestler > 0) {
      vm.discountPerWrestler /= 100.00;
    }
    vm.siblingDiscountEnabled = _.get(po, 'sibling_discount_enabled', false);
    vm.siblingCountForDiscount = _.get(po, 'sibling_count_for_discount', 1);
    vm.paymentStrategy = _.get(po, 'payment_strategy', 'once');
    vm.installmentDates = _.get(po, 'installment_plan.installments', []);
    vm.installmentDates.forEach((date) => {
      date.price /= 100.00;
      if (date.date) {
        // Normalize existing dates to moment objects for date-picker
        date.date = moment(date.date);
      }
    });

    if (!vm.existingId) {
      vm.isNewOption = true;
      vm.toggleExpanded();
    }
  },
  methods: {
    addNewInstallment() {
      const newInstallment = {
        date: null,
        price: 0,
      };
      this.installmentDates.push(newInstallment);
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$parent.$emit('editing-payment-option');
    },
    cancel() {
      this.expanded = !this.expanded;
      this.$parent.$emit('editing-payment-option-cancelled', this.paymentOption.newOptionId, this.isNewOption);
    },
    removeDraft() {
      // todo make remove work for existing payment options
      if (!this.existingId) {
        this.$parent.$emit('discard-payment-option-draft', this.paymentOption.newOptionId);
      }
    },
    validate() {
      const vm = this;

      if (!vm.name) {
        vm.errorMessage = 'Please ensure name is not empty';
        vm.error = true;
        return;
      }

      // Validate that one installment date exists
      if (!vm.oneTimeCharge && _.size(vm.installmentDates) === 0) {
        vm.errorMessage = 'If you want to do an installment plan, you must specify at least one installment date.';
        vm.error = true;
        return;
      }

      // Validate that all installments have dates
      if (!vm.oneTimeCharge && _.includes(_.map(vm.installmentDates, 'date'), null)) {
        vm.errorMessage = 'Once of your installment dates is not set, please ensure all installment dates are entered.';
        vm.error = true;
        return;
      }

      // Validate that class pass > 0 if we use that
      if (vm.allowClassPass && vm.passCount <= 0) {
        vm.errorMessage = 'The class pass count must be greater than 0.';
        vm.error = true;
        return;
      }

      vm.expanded = !vm.expanded;
      // This is used to distinguish whether it's completely new or whether it's just updated
      vm.isNewOption = false;
      vm.draft = true;
      vm.$parent.$emit('payment-option-updated', vm.getUpdatedPOObject());
    },
    installDateForApi(id) {
      return {
        date: id.date,
        price: _.round(id.price * 100),
      };
    },
    getUpdatedPOObject() {
      const vm = this;
      if (vm.oneTimeCharge) {
        return {
          draft: true,
          id: vm.existingId,
          newOptionId: vm.paymentOption.newOptionId,
          payment_strategy: 'once',
          installment_plan: {},
          name: vm.name,
          price: _.round(vm.price * 100),
          class_pass_quantity: vm.allowClassPass ? vm.passCount : null,
          allowed_payment_methods: vm.allowedPaymentMethods,
          discount_per_extra_wrestler: _.round(vm.discountPerWrestler * 100),
          sibling_discount_enabled: vm.siblingDiscountEnabled,
          sibling_count_for_discount: vm.siblingCountForDiscount,
        };
      }

      // Installments otherwise
      const total = _.round(vm.installmentTotal * 100);
      const installPlan = {
        installments: _.map(vm.installmentDates, vm.installDateForApi),
        total,
      };
      return {
        draft: true,
        id: vm.existingId,
        newOptionId: vm.paymentOption.newOptionId,
        payment_strategy: 'installments',
        installment_plan: installPlan,
        name: vm.name,
        price: total,
        class_pass_quantity: null,
        allowed_payment_methods: vm.allowedPaymentMethods,
        discount_per_extra_wrestler: _.round(vm.discountPerWrestler * 100),
        sibling_discount_enabled: vm.siblingDiscountEnabled,
        sibling_count_for_discount: vm.siblingCountForDiscount,
      };
    },
  },
};
</script>
