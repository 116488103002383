export const taggableMix = {
  methods: {
    rosterTagsWithCount: function (wrestlerProfile) {
      if (!wrestlerProfile) {
        return [];
      }
      let rosters = _.flatMap(wrestlerProfile.rosters);
      let tags = _.flatMap(rosters, "tags");
      let count = _.countBy(tags, "abbreviation");
      return _.map(_.uniqBy(tags, "abbreviation"), function (t) {
        t.count = count[t.abbreviation];
        return t;
      });
    },
    rosterNames: function (wrestlerProfile) {
      if (!wrestlerProfile) {
        return "";
      }
      // This should sort by roster name, then append them in a comma separated string
      return _.map(_.sortBy(_.flatMap(wrestlerProfile.rosters), "name"), "name").join(", ");
    },
    activeRosters: function (wrestlerProfile) {
      if (!wrestlerProfile) {
        return [];
      }
      return _.filter(wrestlerProfile.rosters, function (r) {
        return !r.archived;
      });
    },
    activeRosterNames: function (wrestlerProfile) {
      // Sort by name from the activerosters function above
      return _.map(_.sortBy(this.activeRosters(wrestlerProfile), "name"), "name").join(", ");
    },
    archivedRosters: function (wrestlerProfile) {
      if (!wrestlerProfile) {
        return [];
      }
      return _.filter(wrestlerProfile.rosters, function (r) {
        return r.archived;
      });
    },
    archivedRosterNames: function (wrestlerProfile) {
      // Sort by name from the archivedRosters function above
      return _.map(_.sortBy(this.archivedRosters(wrestlerProfile), "name"), "name").join(", ");
    },
  },
};
