import './styles/videojs.scss';
import videojs from 'video.js';
import 'videojs-contrib-quality-levels';

export default function () {
  const Button = videojs.getComponent('Button');
  class TheaterButton extends Button {
    constructor(player, options) {
      super(player, options);
      this.player = player;
      this.theaterModeOn = false;
    }

    handleKeyPress(event) {
      // ignore space and enter key operation
      if (event.which === 32 || event.which === 13) {
        return;
      }

      this.toggleTheaterMode();
    }

    handleClick() {
      this.toggleTheaterMode();
    }

    toggleTheaterMode() {
      this.theaterModeOn = !this.theaterModeOn;
      this.labelEl_.innerHTML = this.getButtonIcon();
      const $videoPlayer = $('#video-player-container');
      const $tabsContainer = $('#match-tabs-container');

      const $theaterContainer = $('#theater-mode-container');
      const $normalContainer = $('#normal-mode-container');
      if (this.theaterModeOn) {
        $theaterContainer.show();
        $videoPlayer.appendTo('#theater-mode-video');
        $tabsContainer.appendTo('#theater-mode-info');
        $normalContainer.hide();
      } else {
        $normalContainer.show();
        $videoPlayer.appendTo('#normal-video');
        $tabsContainer.appendTo('#normal-info');
        $theaterContainer.hide();
      }

      $('body').toggleClass('mini-navbar');
      this.player.play();
    }

    getButtonIcon() {
      if (this.theaterModeOn) {
        return '<i class="fa fa-window-minimize"></i>';
      }

      return '<i class="fa fa-window-maximize"></i>';
    }

    // The `createEl` function of a component creates its DOM element.
    createEl() {
      const el = super.createEl();

      this.labelEl_ = videojs.dom.createEl('div', {
        className: 'vjs-theater-mode-btn',
        innerHTML: this.getButtonIcon(),
      });

      el.appendChild(this.labelEl_);

      return el;
    }
  }

  TheaterButton.prototype.contentElType = 'button';
  // Register the component with Video.js, so it can be used in players.
  videojs.registerComponent('TheaterButton', TheaterButton);

  // Get the Component base class from Video.js for the quality switcher
  const MenuButton = videojs.getComponent('MenuButton');
  const Menu = videojs.getComponent('Menu');

  // The videojs.extend function is used to assist with inheritance. In
  // an ES6 environment, `class TitleBar extends Component` would work
  // identically.
  class QualitySwitcher extends MenuButton {
    // The constructor of a component receives two arguments: the
    // player it will be associated with and an object of options.
    constructor(player, options) {
      super(player, options);
      this.levels = options.levels;
      const self = this;
      this.levels.on('addqualitylevel', (event) => {
        self.updateVisibility();
      });
      this.levels.on('change', (event) => {
        self.updateVisibility();
      });

      // A current level of null means 'auto'
      this.currentLevel = null;
      this.updateVisibility();
    }

    setQualityLevelToCurrent(qualityLevel) {
      this.currentLevel = qualityLevel;

      // First make sure the 'currentLevel' is enabled
      for (let i = 0; i < this.levels.length; i++) {
        const level = this.levels[i];
        if (level.id === qualityLevel.id) {
          level.enabled = true;
        }
      }

      // Then turn off all the other ones
      for (let i = 0; i < this.levels.length; i++) {
        const level = this.levels[i];
        if (level.id !== qualityLevel.id) {
          level.enabled = false;
        }
      }
    }

    // The `createEl` function of a component creates its DOM element.
    createEl() {
      const el = super.createEl();

      this.labelEl_ = videojs.dom.createEl('div', {
        className: 'vjs-quality-switcher-value',
        innerHTML: 'Auto',
      });

      el.appendChild(this.labelEl_);

      return el;
    }

    updateLabel() {
      this.labelEl_.innerHTML = this.getCurrentLevelTitle();
    }

    dispose() {
      this.labelEl_ = null;
      super.dispose();
    }

    buildCSSClass() {
      return `vjs-quality-switcher ${super.buildCSSClass()}`;
    }

    buildWrapperCSSClass() {
      return `vjs-quality-switcher ${super.buildWrapperCSSClass()}`;
    }

    createMenu() {
      const menu = new Menu(this.player(), {});
      if (this.levels) {
        const pretend = this.sortedLevels();
        for (let i = 0; i < pretend.length; i++) {
          const qualityLevel = pretend[i];
          menu.addChild(
            new QualitySwitcherMenuItem(this.player(), {
              quality_level_text: this.textForQualityLevel(qualityLevel),
              quality_level: qualityLevel,
              levels: this.levels,
              current_level: this.currentLevel,
              menu: this,
            }),
          );
        }
      }
      return menu;
    }

    sortedLevels() {
      const sortedLevels = [];
      for (let i = 0; i < this.levels.length; i++) {
        sortedLevels.push(this.levels[i]);
      }

      return sortedLevels.sort((a, b) => a.id < b.id);
    }

    getCurrentLevelTitle() {
      if (this.currentLevel === null) {
        return 'Auto';
      }
      return this.textForQualityLevel(this.currentLevel);
    }

    updateARIAAttributes() {
      // get current quality level here
      this.el().setAttribute('aria-valuenow', this.getCurrentLevelTitle());
    }

    updateVisibility() {
      if (this.levels.length > 0) {
        this.removeClass('vjs-hidden');
        this.update();
        this.updateLabel();
      } else {
        this.addClass('vjs-hidden');
      }
    }

    textForQualityLevel(qualityLevel) {

      const frames = qualityLevel.id;
      if (frames.includes("2000")) {
        return '540p';
      } if (frames.includes("3000")) {
        return '720p';
      } if (frames.includes("7800")) {
        return '1080p';
      }
      return 'Unknown';
    }
  }

  QualitySwitcher.prototype.contentElType = 'button';
  // Register the component with Video.js, so it can be used in players.
  videojs.registerComponent('QualitySwitcher', QualitySwitcher);

  const MenuItem = videojs.getComponent('MenuItem');
  const Component = videojs.getComponent('Component');

  class QualitySwitcherMenuItem extends MenuItem {
    constructor(player, options) {
      const label = options.quality_level_text;
      const qualityLevel = options.quality_level;

      options.label = label;
      options.selected = false;
      options.selectable = true;

      super(player, options);

      this.label = label;
      this.qualityLevel = qualityLevel;
      this.levels = options.levels;
      this.currentLevel = options.currentLevel;
      this.menu = options.menu;
    }

    handleClick(event) {
      super.handleClick();
      this.menu.setQualityLevelToCurrent(this.qualityLevel);
    }

    update(event) {
      // todo this will be the callback for the ocnstructor listener
    }
  }

  // accessibility
  QualitySwitcherMenuItem.prototype.contentElType = 'button';
  Component.registerComponent('QualitySwitcherMenuItem', QualitySwitcherMenuItem);
}
