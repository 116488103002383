<template>
    <div class="chat-message left">
        <div class="message">
            <a class="message-author" v-on:click="findDirectMessage()">{{ message.author.display_name }}<spinner :inline="true" :ml="5" v-if="findingMessage"></spinner></a>
            <span class="message-date">{{ getTime() }}</span>
            <span class="message-content" v-html="urlify(message.content, true)"></span>
            <attachment
                    v-for="(attachment, index) in message.attachments"
                    :key="index"
                    :attachment="attachment"></attachment>
        </div>
    </div>
</template>

<script>
import qs from 'qs';
import Spinner from './spinner.vue';
import Attachment from './attachment.vue';
import { urlHelperMixin } from './mixins/url_helper_mix';

export default {
  name: 'message',
  components: { Attachment, Spinner },
  mixins: [
      urlHelperMixin
  ],
  props: [
    'message',
    'activeProfileId',
    'activeProfileType',
  ],
  data() {
    return {
      findingMessage: false,
    };
  },
  methods: {
    getTime() {
      const timestamp = _.get(this.message, 'created_at', null);
      if (timestamp !== null) {
        return moment(timestamp).format('M/D/YYYY hh:mm a');
      }

      return '';
    },
    findDirectMessage() {
      const vm = this;
      if (vm.findingMessage) {
        return;
      }

      vm.findingMessage = true;
      const profiles = [
        {
          id: vm.message.author.id,
          type: vm.message.author.author_type,
        },
        {
          id: vm.activeProfileId,
          type: vm.activeProfileType,
        },
      ];
      const params = {
        profiles,
        type: 'direct',
      };

      const url = vm.$apiService.messageGroupsUrl();
      axios.get(url, {
        params,
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          // In this case we found a message so send them over
          if (response.data.message_groups.length > 0) {
            vm.findingMessage = false;
            const groupId = response.data.message_groups[0].id;
            window.location.assign(`/message_groups/${groupId}`);
            return;
          }

          // Otherwise create a new one
          // todo if there is a front end piece here to limit direct message recipients
          // this is the place to do it
          vm.createDirectMessage();
        })
        .catch((error) => {
          // in this world a 404 happened, so we need to allow creation of a new message
          // todo some sort of error handling
        });
    },
    createDirectMessage() {
      const vm = this;
      const url = vm.$apiService.messageGroupsUrl();

      // Leave name and purpose empty so the api fills it in for us
      const memberships = [
        {
          profile_id: vm.message.author.id,
          profile_type: vm.message.author.author_type,
          can_publish: true,
        },
        {
          profile_id: vm.activeProfileId,
          profile_type: vm.activeProfileType,
          can_publish: true,
        },
      ];
      const params = {
        message_group: {
          name: null,
          group_type: 'direct',
          purpose: null,
          default_allow_publish: 'all',
          message_memberships_attributes: memberships,
        },
      };

      axios.post(url, params)
        .then((response) => {
          vm.findingMessage = false;
          window.location.assign(`/message_groups/${response.data.id}`);
        })
        .catch((error) => {
          vm.error = true;
          vm.errorMessage = `Error creating message group ${error.toString()}`;
          vm.findingMessage = false;
        });
    },
  },
};
</script>
