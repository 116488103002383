<template>
  <div class="row">
    <div class="col-md-12">
      <div class="ibox">
        <div class="ibox-content p-t-m">
          <div class="row m-t-m">
            <div class="col-md-4">
              <h1 class="form-section-title">Online Store Info</h1>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label for="name" class="m-b-none">Name</label>
                <p class="text-gray-600">E.g. Fall 2024 Gear Order
                </p>
                <input id="name" v-model="online_store.name" type="text" class="form-control">
              </div>
              <div class="form-group">
                <label class="m-b-none">Close store on specific date?</label>
                <p class="text-gray-600">
                  Leave blank if this store should remain open indefinitely.
                </p>
                <div>
                  <div class="form-group">
                    <label for="close_date" class="sr-only">Close date & time</label>
                    <input class="form-control" v-model="online_store.closes_at" id="close_date" type="datetime-local" name="close_date" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-t-xl">
            <div class="col-md-4">
              <h1 class="form-section-title">Products</h1>
              <p class="m-b-none">Add the products you want to offer for sale on this store.</p>
              <p>Not seeing anything? Create <a href="/products" target="_blank">some products first.</a></p>
            </div>
            <div class="col-md-8">
              <label class="m-b-none">Products for sale</label>
              <p v-if="online_store.product_store_listings.length === 0">No products added yet.</p>
              <div class="grid grid-cols-5 sm-grid-cols-1 md-grid-cols-2 gap-sm m-b-sm">
                <div v-for="listing in online_store.product_store_listings" :key="listing.product_id" @click.prevent="toggleProductListing(listing.product)"
                     v-if="isSelected(listing.product)"
                >
                  <product-list-result :product="listing.product" :show-edit="false"></product-list-result>
                </div>
              </div>
              <hr/>
              <label class="m-b-none">Product library. Tap to add to store.</label>
              <filtered-list
                  ui="grid"
                  grid-classes="grid grid-cols-5 sm-grid-cols-1 md-grid-cols-2 gap-sm m-b-sm"
                  :headers="['Name', 'Category', 'Actions']"
                  :sorts="[
            {
              label: 'Name asc',
              value: 'name asc'
              },
            {
              label: 'Name desc',
              value: 'name desc'
              },
            ]" :filters="[
            {
              label: 'Type',
              attribute: 'product_type',
              type: 'primary',
              predicates:
                [
                  {
                    label: 'All Products',
                    user_editable: false,
                    default: true,
                    values: {
                      product_type_eq: 'good'
                      }
                    },
                  ]
              },
            {
              label: 'Name',
              predicateLabel: 'Filter by product name',
              attribute: 'name',
              type: 'secondary',
              predicates:
                [
                  {
                    id: 1,
                    label: 'Name contains',
                    type: 'TextContains',
                    user_editable: true,
                    operator: 'or',
                    placeholder: 'Product name',
                    values: {
                      name_cont_any: null,
                      }
                    },
                  ]
              }
            ]"
                  response-key="products"
                  object-type="Product"
                  base-url="/api/v1/products">
                <template v-slot:row="row">
                  <div @click.prevent="toggleProductListing(row.data)" v-if="notSelected(row.data)">
                    <product-list-result :product="row.data" :show-edit="false"></product-list-result>
                  </div>
                </template>
              </filtered-list>
            </div>
          </div>
          <div class="button-area text-right m-t-xl">
            <div>
              <ladda-button @lbClicked="saveStore" el-class="btn-primary" :loading="loading">
                Save
              </ladda-button>
              <div v-if="error">
                <small>
                  <span class="text-danger">{{ errorMessage }}</span>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import DatePicker from './date_picker.vue';
import ProductListResult from './product_list_result.vue';
import FilteredList from './filtered_list.vue';

export default {
  name: 'online-store-form',
  mixins: [errorableMix],
  components: {
    LaddaButton,
    Spinner,
    FilteredList,
    DatePicker,
    ProductListResult,
  },
  props: {
    existingStore: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      online_store: {
        id: null,
        name: '',
        closes_at: null,
        product_store_listings: [],
      },
      loading: false,
    }
  },
  computed: {
    listingParams() {
      // Streamlined params for server side endpoints
      return this.online_store.product_store_listings.map(listing => {
        return {
          id: listing.id,
          product_id: listing.product_id,
          _destroy: listing._destroy,
        }
      });
    },
    isoFormattedDateTime() {
      if (!this.online_store.closes_at) {
        return null;
      }

      let tz = moment.tz.guess();
      return moment.tz(this.online_store.closes_at, tz).toISOString();
    },
  },
  mounted() {
    if (this.existingStore) {
      this.online_store = this.existingStore;
      if (this.online_store.closes_at) {
        // Really we should probably do this based on server side conversions, but the basic premise is that we need to convert this from
        // iso utc to local time before throwing it into the picker.
        const tz = moment.tz.guess();
        this.online_store.closes_at = moment.tz(this.online_store.closes_at, tz).format('YYYY-MM-DDTHH:mm');
      }
    }
  },
  methods: {
    isSelected(product) {
      return !this.notSelected(product);
    },
    notSelected(product) {
      let listing = this.online_store.product_store_listings.find(listing => listing.product_id === product.id);
      if (listing) {
        return listing._destroy;
      }

      return true;
    },
    toggleProductListing(product) {
      const existingListing = this.online_store.product_store_listings.find(listing => listing.product_id === product.id);
      if (existingListing) {
        if (!existingListing.id) {
          // If this is just a draft indx, never saved server side, just remove it from the array on the front end.
          const draftIdx = _.findIndex(this.online_store.product_store_listings, listing => listing.product_id === product.id);
          this.online_store.product_store_listings.splice(draftIdx, 1);
        } else {
          this.$set(existingListing, '_destroy', !existingListing._destroy);
        }
      } else {
        this.online_store.product_store_listings.push({
          id: null,
          product_id: product.id,
          product: product, // for front end magic
        });
      }
    },
    validate() {
      this.error = false;
      let errors = [];
      if (!this.online_store.name) {
        errors.push('Name is required');
      }
      if (errors.length > 0) {
        this.errorMessage = errors.join(', ');
        this.error = true;

        return false;
      }

      return true;
    },
    saveStore: _.throttle(function() {
      if (this.loading) {
        return;
      }

      if (!this.validate()) {
        return;
      }
      this.loading = true;
      const params = {
        online_store: {
          name: this.online_store.name,
          closes_at: this.isoFormattedDateTime,
          product_store_listings_attributes: this.listingParams,
        }
      }
      if (this.online_store.id) {
        this.update(params);
      } else {
        this.create(params);
      }
    }, 500),
    create(params) {
      const url = this.$apiService.onlineStoresUrl();
      axios.post(url, params)
          .then(response => {
            this.loading = false;
            this.$notificationManager.$emit('show-toast', 'Saved', true);
            window.location.assign('/online_stores');
          })
          .catch(error => {
            this.loading = false;
            this.errorMessage = this.parseErrorResponse(error);
            this.error = true;
          });
    },
    update(params) {
      const url = this.$apiService.onlineStoreUrl(this.online_store.id);
      axios.put(url, params)
          .then(response => {
            this.loading = false;
            this.$notificationManager.$emit('show-toast', 'Saved', true);
            window.location.assign('/online_stores');
          })
          .catch(error => {
            this.loading = false;
            this.errorMessage = this.parseErrorResponse(error);
            this.error = true;
          });
    }
  }
};
</script>
<style scoped>
</style>
