<template>
  <div role="form">
    <div class="row">
      <div class="col-xs-12 col-sm-3">
        <div class="relative flex align-items-center gap-x-xxs">
          <label class="form-label">Team Logo</label>
        </div>
        <pintura-photo-uploader
            :access-key="accessKey"
            :endpoint="endpoint"
            :cloudfront-base-url="cloudfrontBaseUrl"
            :existing-attachments="existingAttachments"
            :attachable-id="team.id"
            attachable-type="Team"
            prefix="team_logos/"
            imageCropAspectRatio="1"
            @photos-changed="photosChanged"
        ></pintura-photo-uploader>
      </div>
      <div class="col-xs-12 col-sm-8 col-sm-offset-1">
        <div class="form-group m-t-only-xs-m">
          <label for="team_name">Team Name</label>
          <input v-model="name" id="team_name" type="text" class="form-control font-no-ios-zoom">
        </div>
        <div class="form-group m-t-only-xs-m flex justify-space-between m-t-md">
          <label for="team_color">Team Brand Color</label>
          <input v-model="brandColor" type="color" id="team_color"/>
        </div>
        <div class="form-group m-t-md">
          <label for="receipt_footer">Receipt Footer Text</label>
          <input placeholder="Your donations are tax deductible! EIN:" id="receipt_footer" v-model="receiptFooter"
                 type="text" class="form-control font-no-ios-zoom">
        </div>
        <div class="form-group m-t-md">
          <label for="line1">
            Address line 1
          </label>
          <input id="line1" v-model="addressLine1" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label for="line2">Address line 2 (optional)</label>
          <input id="line2" v-model="addressLine2" type="text" class="form-control">
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-xs-12 col-sm-6 m-b-only-xs-m">
              <label for="city">
                City
              </label>
              <input id="city" v-model="city" type="text" class="form-control">
            </div>
            <div class="col-xs-12 col-sm-2 m-b-only-xs-m">
              <label for="state">
                State
              </label>
              <the-mask
                  v-model="state"
                  mask="AA"
                  type="text"
                  class="form-control"
                  ref="state"
                  id="state"
              ></the-mask>
            </div>
            <div class="col-xs-12 col-sm-4">
              <label for="zip">
                Zip
              </label>
              <the-mask
                  v-model="zip"
                  mask="#####"
                  type="tel"
                  class="form-control"
                  ref="zip"
                  id="zip"
              ></the-mask>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="button-area">
          <div class="text-right pull-right">
            <div v-show="error">
              <small>
                <span class="text-danger">{{ errorMessage }}</span>
              </small>
            </div>
            <ladda-button @lbClicked="updateTeam" el-class="btn-primary" :loading="loading">
              Update Team
            </ladda-button>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { TheMask } from 'vue-the-mask';
import { photoUploadMix } from './mixins/photo_upload_mix.js';
import PinturaPhotoUploader from './pintura_photo_uploader.vue';

export default {
  name: 'team-edit',
  components: {
    PinturaPhotoUploader,
    LaddaButton,
    TheMask
  },
  mixins: [photoUploadMix],
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support@wrestlingiq.com',
      name: '',
      brandColor: '',
      receiptFooter: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
    };
  },
  created() {
    this.name = this.team.name;
    this.brandColor = this.team.brand_color;
    this.receiptFooter = this.team.receipt_footer;
    this.addressLine1 = this.team.address_line_1;
    this.addressLine2 = this.team.address_line_2;
    this.city = this.team.city;
    this.state = this.team.state;
    this.zip = this.team.zip;
    // See photoupload mix for photo attachment reactivity
  },
  methods: {
    updateTeam: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (vm.name.length == null || vm.name.length === 0) {
        vm.errorMessage = 'Team name must exist';
        vm.error = true;
        return;
      }

      vm.loading = true;
      vm.error = false;
      const params = {
        team: {
          name: vm.name,
          brand_color: vm.brandColor,
          receipt_footer: vm.receiptFooter,
          address_line_1: vm.addressLine1,
          address_line_2: vm.addressLine2,
          city: vm.city,
          state: vm.state,
          zip: vm.zip,
          logos_attributes: vm.attachmentParams,
        },
      };
      const url = vm.$apiService.teamUrl(vm.team.id);
      axios.put(url, params)
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', `Team details updated`, true);
            window.location.assign('/settings/team');
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support@wrestlingiq.com';
            vm.error = true;
          });
    }, 500),
  },
};
</script>
