<template>
    <div id="bout-create" role="form">
        <p>
            Please confirm the following pairing is correct.
        </p>
        <div class="bout">
            <div class="row">
                <div class="col-xs-12 col-sm-4 col-sm-offset-1">
                    <div v-html="wrestlerInfoHtml(wp)"></div>
                </div>
                <div class="col-xs-12 col-sm-1 vs">
                    <p><strong>vs.</strong></p>
                </div>
                <div class="col-xs-12 col-sm-4 col-sm-offset-2">
                    <div v-html="wrestlerInfoHtml(opponent)"></div>
                </div>
            </div>
        </div>
        <div class="button-area text-right m-t-lg">
            <div v-show="error">
                <small>
                    <span class="text-danger">{{ errorMessage }}</span>
                </small>
            </div>
            <button type="button" v-show="!loading" @click="hideForm()" class="btn btn-white m-r-md">Cancel</button>
            <ladda-button @lbClicked="pingServer" el-class="btn-primary" :loading="loading">
                Yes, Create Bout
            </ladda-button>
        </div>
    </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'bout-create',
  components: { LaddaButton },
  props: [
    'wp',
    'opponent',
    'round',
    'event_id',
  ],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  methods: {
    wrestlerInfoHtml(wp) {
      if (wp) {
        return `<h3 class="text-semi">${wp.full_name}</h3><p>${wp.age} years old<br/>${wp.weight_class} lbs<br/>${wp.experience}<br/>${wp.team.name}</p>`;
      }

      return '';
    },
    hideForm() {
      const vm = this;
      vm.$notificationManager.$emit('bout-create-cancel');
    },
    pingServer() {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.loading = true;

      const params = {
        match: {
          home_wrestler_profile_id: vm.wp.id,
          opponent_profile_id: vm.opponent.id,
          event_id: vm.event_id,
          round: vm.round,
        },
      };

      const url = vm.$apiService.matchesUrl();
      axios.post(url, params)
        .then((response) => {
          vm.loading = false;

          vm.$notificationManager.$emit('bout-created', response.data);
          vm.$notificationManager.$emit('show-toast', 'Bout created', true);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error creating bout please try again later or contact matt@wrestlingiq.com';
          vm.error = true;
        });
    },
  },
};
</script>
