<template>
  <div class="search-result">
    <div v-if="!noHr" class='hr-line-dashed m-b-md m-t-md'></div>
    <div class="grid sm-grid-cols-1 md-grid-cols-1 grid-cols-5">
      <div class="col-span-2 flex align-items-center justify-space-between">
        <div class="flex-shrink-1">
          <a :href="showUrl()">
            <h3 class="text-gray-700 font-heavy">
              {{ result.name }}
            </h3>
          </a>
          <p class="m-t-m m-b-none hidden-xs hidden-sm" v-if="showRegUrl">
              <a :href="registerUrl()" class="text-gray-700">{{ registerUrl() }}</a>
          </p>
          <p v-if="!recurring && !dropin && !trial" class="text-green-600 font-semi m-t-none">
            {{ dateRange() }}
          </p>
        </div>
        <div class="m-l-sm m-r-lg cursor-pointer hidden-xs hidden-sm" @click="copyURL(registerUrl())">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 color-gray-700" fill="currentColor">
            <path d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z"/>
          </svg>
        </div>
        <div class="btn-group link-btn visible-xs visible-sm align-self-flex-start ">
          <button
              data-toggle="dropdown"
              aria-expanded="false"
              class="btn btn-link icon-only dropdown-toggle p-t-n p-r-n"
          >
            <i aria-hidden="true" class="fa fa-ellipsis-v"></i>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right pull-right">
            <li>
              <a class="flex align-items-center gap-x-xs" @click="copyURL(registerUrl())" style="display:flex;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 color-gray-700" fill="currentColor">
                  <path d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z"/>
                </svg>
                Copy Link
              </a>
            </li>
            <li>
              <a :href="editUrl()" class="flex align-items-center gap-x-xs" style="display:flex;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 color-gray-700" fill="currentColor"><path d="M491.609 73.625l-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533l-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"/></svg>
                Edit
              </a>
            </li>
            <li>
              <a :href="replicateUrl" class="flex align-items-center gap-x-xs" style="display:flex;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 text-gray-700" fill="currentColor">
                  <path
                      d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z"/>
                </svg>
                Replicate
              </a>
            </li>
            <li>
              <profile-event-sub-toggle
                  style="padding: 3px 20px;margin: 4px;"
                  :event-name="notifyEventName"
                  filter-key="paid_session_ids"
                  :filter-value-to-toggle="result.id"
                  :readable-header="notifyHeader"
                  :existing-sub="notifySubscription"
                  :link-only-view="true"
                  :session-name="result.name"
              ></profile-event-sub-toggle>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex align-items-center justify-space-between">
        <div class="flex align-items-center">
          <div v-if="registerable(result)" class="badge badge-primary-light p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs">
            Open
          </div>
          <div v-else class="badge badge-warning p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs">
            Closed
          </div>
        </div>
        <div class="flex align-items-center">
          <div class="visible-sm visible-xs uppercase tracking-wide font-semi text-12 m-r-xs text-gray-500">
            # Registered
          </div>
          <div class="text-12 font-semi text-gray-700 visible-xs visible-sm">
            <span v-if="recurring">{{ result.stats.good_standing_membership_count }}</span>
            <span v-else>{{ result.stats.not_canceled_registrations_count }}</span>
          </div>
        </div>
      </div>
      <div class="flex align-items-center justify-space-between hidden-xs hidden-sm">
        <div>
          <span v-if="recurring">{{ result.stats.good_standing_membership_count }}</span>
          <span v-else>{{ result.stats.not_canceled_registrations_count }}</span>
        </div>
        <div class="">
          <a class="btn btn-primary btn-compact" :href="showUrl()">
            View Registrants
          </a>
        </div>
      </div>
      <div class="flex justify-self-end align-items-center hidden-xs hidden-sm">
          <template v-if="showNotifyToggle">
            <archive-paid-session class="m-r-m" :session-id="result.id" :is-archived="false" :session-name="result.name" :allow-archive="allowArchive"></archive-paid-session>
            <VDropdown theme="info-tooltip">
              <!-- This will be the popover target (for the events and position) -->
              <a class="m-r-m cursor-pointer" :href="replicateUrl">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 text-gray-700" fill="currentColor">
                  <path
                      d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z"/>
                </svg>
              </a>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <p class="header">
                    Replicate session
                  </p>
                  <p>
                    Create a new session using {{ result.name }} as the template.
                  </p>
                </div>
              </template>
            </VDropdown>
            <profile-event-sub-toggle
                class="m-r-m"
                :event-name="notifyEventName"
                filter-key="paid_session_ids"
                :filter-value-to-toggle="result.id"
                :readable-header="notifyHeader"
                :existing-sub="notifySubscription"
                :session-name="result.name"
              ></profile-event-sub-toggle>
          </template>
          <div>
            <VDropdown theme="info-tooltip">
              <!-- This will be the popover target (for the events and position) -->
              <a :href="editUrl()" class="cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 color-gray-700" fill="currentColor"><path d="M491.609 73.625l-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533l-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"/></svg>
              </a>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <p class="header">
                    Edit session
                  </p>
                </div>
              </template>
            </VDropdown>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArchivePaidSession from './archive_paid_session.vue';
import ReplicateSession from './replicate_session.vue'
import ProfileEventSubToggle from './profile_event_sub_toggle.vue';
export default {
  name: 'paid-session-result',
  props: {
    result: {
      type: Object,
      required: true,
    },
    noHr: {
      type: Boolean,
      default: false,
    },
    showRegUrl: {
      type: Boolean,
      default: false,
    },
    teamSlug: {
      type: String,
      required: true,
    },
    showNotifyToggle: {
      type: Boolean,
      default: false,
    },
    billingPlanNotifySubscription: {
      type: Object,
      default() { return {}; },
    },
    registrationCreatedNotifySubscription: {
      type: Object,
      default() { return {}; },
    },
  },
  data: function () {
    return {};
  },
  components: {
    ArchivePaidSession, ReplicateSession, ProfileEventSubToggle
  },
  computed: {
    replicateUrl() {
      return `/paid_sessions/new?replicate_session_id=${this.result.id}`;
    },
    recurring() {
      return this.result.session_type === 'recurring';
    },
    dropin() {
      return this.result.session_type === 'dropin';
    },
    trial() {
      return this.result.session_type === 'trial';
    },
    notifyEventName() {
      return this.recurring ? "billing_plan_subscription_items_wrestler_profile.created" : "registration.created";
    },
    notifyHeader() {
      return this.recurring ? `Receive a notification every time a wrestler becomes a member of ${this.result.name}` : `Receive a notification every time a wrestler is registered for ${this.result.name}`;
    },
    notifySubscription() {
      return this.recurring ? this.billingPlanNotifySubscription : this.registrationCreatedNotifySubscription;
    },
    allowArchive() {
      if (!this.recurring) {
        return true;
      }

      // Otherwise if we are recurring, only allow archive if no members left
      return this.result.stats.good_standing_membership_count === 0;
    },
  },
  methods: {
    async copyURL(url) {
      try {
        await navigator.clipboard.writeText(url);
        this.$notificationManager.$emit('show-toast', 'Registration url copied to clipboard', true);
      } catch($e) {
        this.$notificationManager.$emit('show-toast', 'Unable to copy url to clipboard', false);
      }
    },
    dateRange() {
      let vm = this;
      let start = moment(vm.result.start_at).format('M/D/YYYY');
      let end = moment(vm.result.end_at).format('M/D/YYYY');
      return start + ' to ' + end;
    },
    showUrl() {
      return '/paid_sessions/' + this.result.id;
    },
    editUrl() {
      return '/paid_sessions/' + this.result.id + '/edit';
    },
    registerUrl() {
      if (!this.teamSlug) {
        return 'Your account is not fully setup, please contact support@wrestlingiq.com';
      }
      return window.location.origin + '/' + this.teamSlug + '/register/' + encodeURIComponent(this.result.slug);
    },
    registerable(paid_session) {
      return paid_session.registration_open && (this.recurring || this.dropin || this.trial || moment().isSameOrBefore(paid_session.end_at, 'day'));
    }
  }
};
</script>
