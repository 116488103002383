<template>
  <tbody class="border border-gray-100 border-b border-t-none border-solid">
    <tr class="relative">
      <td data-title="Name">
        <a :href="`/wrestlers/${row.id}`" target="_blank">
          <div class="font-heavy text-gray-700">
            {{ row.full_name }}
          </div>
        </a>
      </td>
      <td class="text-gray-700" data-title="USAW ID">
          {{ row.usaw_membership_id }}
      </td>
      <td class="text-gray-700" data-title="Weight">
        {{ row.weight_class }}
      </td>
      <td class="text-gray-700" data-title="Age">{{ row.age }} years old</td>
      <td class="text-gray-700" data-title="Academic Class">
        {{ row.academic_class }}
      </td>
      <td class="text-gray-700" data-title="Guardians">
        <div class="flex flex-col">
          <div v-for="guardian in row.guardians">
            {{ guardian.full_name }}
          </div>
        </div>
      </td>
      <td class="text-gray-700" data-title="Rosters">
          <div v-for="roster in row.rosters">
              {{ roster.name }}
          </div>
      </td>
      <td class="text-gray-700" data-title="Experience">
          {{ row.experience }}
      </td>
      <td v-for="questionId in expandedQuestionOrder" class="text-gray-700">
          {{ expandedQuestionFor(questionId) }}
      </td>
    </tr>
  </tbody>
</template>
<script>
import { formattingMixin } from "./mixins/formatting_mix";

export default {
  name: "expanded-wrestler-row",
  mixins: [formattingMixin],
  props: {
    row: {
      type: Object,
      required: true,
    },
    expandedQuestionOrder: {
      type: Array,
      required: true
    }
  },
  methods: {
    expandedQuestionFor(questionId) {
      const mostRecentAnswer = _.head(_.orderBy(_.filter(this.row.registration_answers, function(answer) { return answer.registration_question_id === questionId || answer.registration_question_original_id === questionId }), ['updated_at'],['desc']));
      // TODO either start sending this down in the json (which may need a counter cache) or just handle it on the front end like we do in the info form collectors.
      //return mostRecentAnswer?.plain_text_answer;
      return mostRecentAnswer?.answer;
    },
  }
};
</script>
