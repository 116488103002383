<template>
    <div class="reg-short-text">
        <div class="row">
            <div class="col-xs-12">
                <div role="form">
                    <div :class="getClass()">
                        <label :for="this.$srHelper.idFor('input', this.srId)">{{ prompt() }}</label>
                        <div v-if="hasSubtext" v-html="question.subtext"></div>
                        <input
                                v-model="answer"
                                :id="this.$srHelper.idFor('input', this.srId)"
                                type="text"
                                @blur="validate()"
                                ref="short-text-a"
                                class="form-control"
                                :autocomplete="autocomplete"
                        >
                        <p v-show="error" class="text-danger">
                            {{ errorMessage }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'reg-short-text-question',
  props: {
    question: {
      type: Object,
      required: true,
    },
    existingAnswer: {
      type: Object,
    },
    autocomplete: {
      type: String,
      default: 'off'
    }
  },
  data() {
    return {
      answer: '',
      originalAnswer: null,
      dirty: false,
      valid: true,
      error: false,
      errorMessage: 'Please enter an answer.',
      required: false,
      srId: '',
    };
  },
  computed: {
    hasSubtext() {
      const vm = this;
      if (!vm.question) {
        return false;
      }

      return vm.question.subtext && vm.question.subtext.length !== 0;
    },
  },
  watch: {
    // whenever question changes, this function will run
    answer() {
      this.setDirty();
      this.calculateValid();
      this.emit();
    },
  },
  created() {
    const vm = this;
    if (vm.existingAnswer) {
      vm.originalAnswer = vm.existingAnswer.answer;
      vm.answer = vm.existingAnswer.answer;
    }

    if (vm.question) {
      vm.required = vm.question.required;
      vm.srId = vm.question.id;
    }

    vm.calculateValid();
    vm.$notificationManager.$on('show-reg-errors', vm.validate);
  },
  destroyed() {
    this.$notificationManager.$off('show-reg-errors', this.validate);
  },
  methods: {
    emit() {
      const vm = this;
      vm.$emit('answer-changed', vm.question, vm.answer, vm.dirty, vm.valid);
    },
    getClass() {
      if (this.error) {
        return 'form-group has-error';
      }

      return 'form-group';
    },
    setDirty() {
      const vm = this;
      if (vm.originalAnswer === vm.answer) {
        vm.dirty = false;
      } else {
        vm.dirty = true;
      }
    },
    calculateValid() {
      const answerLength = this.answer.length;
      this.valid = (answerLength > 0) || (answerLength === 0 && !this.required);
    },
    validate() {
      this.error = !this.valid;
    },
    prompt() {
      const vm = this;
      if (!vm.question) {
        return '';
      }

      let { prompt } = vm.question;

      if (!vm.required) {
        prompt += ' (optional)';
      }

      return prompt;
    },
  },
};
</script>
