<template>
  <div class="registration-answers">
    <template v-if="loading">
      <spinner v-if="loading"></spinner>
    </template>
    <div v-if="!loading">
      <div v-for="(questions, groupName) in groupedQuestions">
        <dl>
          <h3>{{ safeGroupName(groupName) }}</h3>
          <div v-for="(question, index) in questions">
            <div class="grid grid-cols-3 sm-grid-cols-2 gap-4">
              <div>
                <dt class="prompt">{{ question.prompt }}</dt>
              </div>
              <div>
                <dd>
                  <template v-if="question.type === 'RegAddressQuestion'">
                    <p v-html="getAddressAnswerForQuestion(question)"></p>
                  </template>
                  <template v-else-if="question.type === 'RegPhoneNumberQuestion'">
                    <p>
                      {{ getPhoneAnswerForQuestion(question) }}
                    </p>
                  </template>
                  <template v-else-if="question.type === 'RegYesNoQuestion'">
                    <p>
                      {{ getYesNoAnswerForQuestion(question) }}
                    </p>
                  </template>
                  <template v-else-if="question.type === 'RegPhotoQuestion'">
                    <p>
                      <template v-if="getAnswerForQuestion(question) !== ''">
                        <a class="text-underline" :href="photoUrl(question)">{{ photoName(question) }}</a>
                      </template>
                      <template v-else>
                        No file attached
                      </template>
                    </p>
                  </template>
                  <template v-else-if="question.type === 'RegMultiSelectQuestion'">
                    <p v-html="getMultiSelectAnswerForQuestion(question)"></p>
                  </template>
                  <template v-else>
                    <p>
                      {{ getAnswerForQuestion(question) }}
                    </p>
                  </template>
                </dd>
              </div>
            </div>
            <div v-if="index === questions.length - 1" class="m-b-md"></div>
          </div>
        </dl>
      </div>
      <div v-if="showPrivateEmptyState">
        <p>No private properties configured. <a target="_blank" href="https://help.wrestlingiq.com/article/139-configure-private-wrestler-properties">Learn more <i class="fa fa-external-link"></i></a></p>
      </div>
    </div>
    <div v-show="error">
      <small>
        <span class="text-danger">{{ errorMessage }}</span>
      </small>
    </div>
    <div v-if="editUrl && !loading && !showPrivateEmptyState">
      <a :href="editUrl">{{ editLinkText }}</a>
    </div>
  </div>
</template>
<script>
import Spinner from './spinner.vue';
import { formattingMixin } from './mixins/formatting_mix';

export default {
  mixins: [formattingMixin],
  name: 'registration-answers',
  components: { Spinner },
  props: {
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    editUrl: {
      type: String,
      required: false,
    },
    profileId: {
      type: Number,
      required: true,
    },
    profileType: {
      type: String,
      required: true,
    },
    // For specific registrations (guests) we pull in guest registration info from their most recent registration.
    paidSessionId: {
      type: Number,
      required: false,
    },
    // Is this for public or private properties
    visibility: {
      type: String,
      default: "public"
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: '',

      groupedQuestions: null,
      answers: [],
    };
  },
  computed: {
    sessionParam() {
      return this.paidSessionId ? this.paidSessionId : 'properties';
    },
    editLinkText() {
      return this.isPublicProperty ? "Edit info..." : "Edit private info..."
    },
    isPublicProperty() {
      // Why not just match to public? Because public is our default, private the special case
      return (this.visibility !== 'private');
    },
    showPrivateEmptyState() {
      if (this.isPublicProperty) {
        return false;
      }

      return Object.keys(this.groupedQuestions).length == 0;
    }
  },
  created() {
    this.loadAllQuestions();
  },
  methods: {
    safeGroupName(groupName) {
      if (groupName !== null && groupName !== 'null') {
        return groupName;
      }

      return '';
    },
    photoUrl(question) {
      const vm = this;
      const answer = vm.getAnswerForQuestion(question);
      return `${vm.cloudfrontBaseUrl}${answer.s3_file_name}`;
    },
    photoName(question) {
      const vm = this;
      const answer = vm.getAnswerForQuestion(question);
      return answer.file_name;
    },
    getAddressAnswerForQuestion(question) {
      const vm = this;
      const answer = vm.getAnswerForQuestion(question);
      return vm.formatAddressAns(answer);
    },
    getYesNoAnswerForQuestion(question) {
      const vm = this;
      const answer = vm.getAnswerForQuestion(question);
      return vm.formatYesNoAns(answer);
    },
    getPhoneAnswerForQuestion(question) {
      const vm = this;
      const number = vm.getAnswerForQuestion(question);
      return vm.formatPhoneAns(number);
    },
    getMultiSelectAnswerForQuestion(question) {
      const vm = this;
      const answer = vm.getAnswerForQuestion(question);
      return vm.formatMultiSelectAns(answer);
    },
    getAnswerForQuestion(question) {
      const vm = this;

      // return an existing answer if it exists
      for (let i = 0; i < vm.answers.length; i++) {
        const answer = vm.answers[i];
        if (answer.registration_question_id === question.id) {
          return answer.answer;
        }
      }

      return '';
    },
    groupQuestionsAndAssign(reg_questions) {
      const vm = this;
      vm.questions = reg_questions;
      const groups_categories = _.map(reg_questions, 'group_with');

      vm.groupedQuestions = {};

      for (const key of groups_categories) {
        // each category should be able to hold an array
        vm.groupedQuestions[key] = [];
      }

      for (const question of vm.questions) {
        vm.groupedQuestions[question.group_with].push(question);
      }
    },
    loadAllQuestions() {
      const vm = this;
      vm.loading = true;
      const url = this.isPublicProperty ? vm.$apiService.registrationQuestions(vm.profileType, vm.sessionParam) : vm.$apiService.privateWrestlerProperties();
      vm.$apiService.loadAllPages(url, 'registration_questions')
          .then((reg_questions) => {
            vm.groupQuestionsAndAssign(reg_questions);
            vm.loadAllAnswers();
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = `Error retrieving registration questions ${error.toString()}`;
            vm.error = true;
          });
    },
    loadAllAnswers() {
      const vm = this;
      const url = this.isPublicProperty ? vm.$apiService.registrationAnswers(vm.profileId, vm.profileType, null, vm.sessionParam) : vm.$apiService.privateWrestlerPropertyAnswers(vm.profileId)
      vm.$apiService.loadAllPages(url, 'registration_answers')
          .then((reg_answers) => {
            vm.answers = reg_answers;
            vm.loading = false;
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = `Error retrieving registration answers ${error.toString()}`;
            vm.error = true;
          });
    },
  },
};
</script>
