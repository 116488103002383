<template>
  <div class="notepad-card m-t-sm">
    <div class="flex justify-space-between align-items-baseline">
      <h4 class="m-y-none font-heavy">
        {{ result.author.display_name }}
      </h4>
      <div class="flex gap-x-xs font-small">
        <p class="color-gray-500 m-y-none"><time :datetime="result.created_at" data-local='time-ago'></time></p>
      </div>
    </div>
    <div class="m-t-xxs" v-html="parseMentionable(result.content)"></div>
  </div>
</template>

<script>
import { urlHelperMixin } from './mixins/url_helper_mix';
export default {
  name: 'mentionable',
  props: ['result'],
  mixins: [
    urlHelperMixin
  ],
  methods: {
    parseMentionable(text) {
      let urlified = this.urlify(text, false);
      return urlified.replace(/\d{0,2}(\d|:)\d{2}/, (content) => `<a class='video-seeker' href='#${content}'>${content}</a>`);
    },
  },
};
</script>
