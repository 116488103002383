<template>
    <div class="team-score-card">
        <h3>
            {{ teamScore.team.name }}
        </h3>
    </div>
</template>

<script>
export default {
  name: 'team-score',
  props: [
    'teamScore',
  ],
  data() {
    return {};
  },
  methods: {
  },
};
</script>
