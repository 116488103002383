<template>
  <!-- YouTube-style title and meta information -->
  <div class="video-meta m-t-sm">
    <div class="flex flex-col-xs-only justify-space-between align-items-flex-start m-t-sm gap-y-m">
      <div>
        <h1 class="font-bold m-b-xs" v-html="fullTitle"></h1>
        <div>
          <span v-if="video">{{ video.impressions_count }} views</span>
          <template v-if="match.event">
            • <a :href="eventPath">{{ match.event.name }}</a>
          </template>
        </div>
      </div>
      <div class="m-t-sm">
        <div class="grid sm-grid-cols-2 md-grid-cols-2 grid-cols-4 gap-sm direction-rtl" v-if="!showMatchTagForm && !showDeleteMatchForm">
          <button v-if="canModifyMatch" @click="showDeleteMatchForm = true" class="btn btn-default direction-ltr">
            <i class="fa fa-trash"></i> Delete
          </button>
          <a v-if="playable && downloadUrl" :href="downloadUrl" class="btn btn-default direction-ltr" download>
            <i class="fa fa-download"></i> Download
          </a>
          <pintura-video-editor v-if="playable && video && clipUrl" :src="clipUrl" />
          <button type="button" v-if="canModifyMatch" @click="showMatchTagForm = true" class="btn btn-default direction-ltr">
            <i class="fa fa-pencil"></i> Edit Match
          </button>
        </div>
      </div>
    </div>
    <div v-if="showMatchTagForm && canModifyMatch" class="border border-solid border-gray-300 p-md rounded-sm bg-gray-50 m-t-sm">
      <h1 class="m-y-none m-b-sm font-heavy m-b-sm border-b border-solid border-gray-300 p-b-sm">Edit Match</h1>
      <match-tag-form
        :original-video="originalVideo"
        :existing-match="match"
        :prefilled-event="match.event"
        :allow-event-create="false"
        :ruleset-slug="match.ruleset"
        :unknown-opponent-id="unknownOpponentId"
        @cancel="showMatchTagForm = false"
      >
      </match-tag-form>
    </div>
    <div v-if="showDeleteMatchForm && canModifyMatch" class="border border-solid border-gray-300 p-md rounded-sm bg-gray-50 m-t-sm">
      <h1 class="m-y-none m-b-sm font-heavy m-b-sm border-b border-solid border-gray-300 p-b-sm">Delete Match</h1>
      <div class="m-t-sm">
        <delete-match-form :match="match"></delete-match-form>
      </div>
    </div>
  </div>
</template>

<script>
import MatchTagForm from './match_tag_form.vue';
import DeleteMatchForm from './delete_match_form.vue';
import PinturaVideoEditor from './pintura_video_editor.vue';

export default {
  name: 'match-video-meta',
  components: {
    MatchTagForm,
    DeleteMatchForm,
    PinturaVideoEditor
  },
  props: {
    match: {
      type: Object,
      required: true
    },
    video: {
      type: Object,
      required: true
    },
    originalVideo: {
      type: Object,
      required: true
    },
    clipUrl: {
      type: String,
      required: false
    },
    downloadUrl: {
      type: String,
      required: false
    },
    canModifyMatch: {
      type: Boolean,
      default: false,
    },
    unknownOpponentId: {
      type: Number,
      required: true
    },
    playable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showMatchTagForm: false,
      showDeleteMatchForm: false,
    };
  },
  computed: {
    fullTitle() {
      if (this.match && this.match.wrestler && this.match.opponent) {
        const wrestlerName = this.getLinkedName(this.match.wrestler, false);
        const opponentName = this.getLinkedName(this.match.opponent, true);
        return `${wrestlerName} vs ${opponentName}`;
      }
      return 'Unknown Match';
    },
    eventPath() {
      return `/events/${this.match.event.id}`;
    }
  },
  methods: {
    getLinkedName(person, isOpponent) {
      if (person.first_name && person.last_name && person.id) {
        const firstInitial = person.first_name.charAt(0);
        const fullName = `${firstInitial}. ${person.last_name}`;
        if (isOpponent) {
          return `<a href="/opposing_teams/${person.team.id}/opposing_wrestlers/${person.id}" class="hover:underline">${fullName}</a>`;
        } else {
          return `<a href="/wrestlers/${person.id}" class="hover:underline">${fullName}</a>`;
        }
      }
      return 'Unknown';
    },
    refreshPage() {
      window.location.reload();
    },
    matchDeleted() {
      // Navigate to the video manager page using raw js methods not router
      window.location.href = '/original_videos/new';
    },
    hideDeleteMatchForm() {
      this.showDeleteMatchForm = false;
    }
  },
  mounted() {
    this.$notificationManager.$on('match-created', this.refreshPage);
    this.$notificationManager.$on('match-updated', this.refreshPage);
    this.$notificationManager.$on('match-deleted', this.matchDeleted);
    this.$notificationManager.$on('hide-delete-match-form', this.hideDeleteMatchForm);
  },
  destroyed() {
    this.$notificationManager.$off('match-created', this.refreshPage);
    this.$notificationManager.$off('match-updated', this.refreshPage);
    this.$notificationManager.$off('match-deleted', this.matchDeleted);
    this.$notificationManager.$off('hide-delete-match-form', this.hideDeleteMatchForm);
  }
}
</script>
