<template>
  <vue-date-picker :value="pickedDate"
                   @input="handleChange"
                   prefix-class="wiq"
                   valueType="format"
                   :formatter="momentFormat"
                   type="date"
                   :placeholder="placeholder"
                   :disabled-date="disabledDate"
  ></vue-date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'date-picker',
  components: { 'vue-date-picker': DatePicker },
  props: {
    date: {
      // This is a moment object, if it's passed in
      type: Object,
      required: false
    },
    placeholder: {
      type: String,
      default: "Select date",
    },
    // This is a function the caller can pass in to disable certain dates on the calendar.
    // See the manual-invoice-form for one real example
    disabledDate: {
      type: Function,
      default(date) {
        return false;
      }
    }
  },
  data: function() {
    return {
      // Use moment.js instead of the default
      momentFormat: {
        //[optional] Date to String
        stringify: (date) => {
          return date ? moment(date).format('M/D/YYYY') : ''
        },
        //[optional]  String to Date
        parse: (value) => {
          return value ? moment(value, 'M/D/YYYY').toDate() : null
        },
      },
      pickedDate: null,
    }
  },
  mounted() {
    if (this.date) {
      this.pickedDate = this.date.format("M/D/YYYY");
    }
  },
  watch: {
    date(date) {
      this.pickedDate = date.format("M/D/YYYY");
    },
  },
  methods: {
    handleChange(date) {
      this.$emit('input', moment(date, 'M/D/YYYY'))
    }
  }
};
</script>
