export const errorableMix = {
  data() {
    return {
      error: false,
      errorMessage: '',
    };
  },
  methods: {
    showError(msg = 'We had an issue. If the problem persists contact support@wrestlingiq.com') {
      this.errorMessage = msg;
      this.error = true;
    },
    clearError() {
      this.error = false;
      this.errorMessage = '';
    },
    readableMessageFrom(errors, prefix) {
      let outStr = '';
      if (errors.length === 1) {
        outStr = errors[0];
      } else if (errors.length === 2) {
        // joins all with "and" but no commas
        // example: "email and first name"
        outStr = errors.join(' and ');
      } else if (errors.length > 2) {
        // joins all with commas, but last one gets ", and" (oxford comma!)
        // example: "bob, joe, and sam"
        outStr = `${errors.slice(0, -1).join(', ')}, and ${errors.slice(-1)}`;
      }
      return `${prefix} ${outStr}.`;
    },
    getErrorMessage(error) {
      const { errors } = error.response.data;
      if (errors && errors.length > 0) {
        return errors[1];
      }

      return error.toString();
    },
    // Attempt to parse a 422 from the backend intelligently or bail to generic toString
    parseErrorResponse(error) {
      if (Array.isArray(error.response?.data?.errors)) {
        return this.readableMessageFrom(error.response?.data?.errors, 'Error:');
      } else if (typeof error.response?.data?.errors === 'object' ) {
        let errors = _.map(error.response.data.errors, (key, value) => {
          return `${value} ${key[0]}`
        });
        return this.readableMessageFrom(errors, 'Error:');
      } else {
        return this.getErrorMessage(error);
      }
    },
    // Check to see if the backend is requesting a refresh of the front end before continuing.
    clientUpdateRequired(error) {
      // Check to make sure we don't need to reload the page
      return error.response?.status === 400 && error.response?.data["errors"][0] === "Client version expired";
    }
  },
};
