<template>
    <div class="reg-single-select">
        <div class="row">
            <div class="col-xs-12">
                <div role="form">
                    <div :class="getClass()">
                        <label>{{ prompt() }}</label>
                        <div v-if="hasSubtext" v-html="question.subtext"></div>
                        <div>
                            <div v-for="a in possibleAnswers">
                                <div class="radio">
                                    <label>
                                        <input type="radio" :value="a.prompt" v-model="mAnswer">{{ a.prompt }}
                                    </label>
                                </div>
                            </div>
                            <div v-if="needsAddtlInfo">
                                <label :for="this.$srHelper.idFor('input', this.srId)">{{ addtlInfoPrompt }}</label>
                                <input
                                        v-if="needsAddtlInfo"
                                        v-model="mAddtlInfo"
                                        :id="this.$srHelper.idFor('input', this.srId)"
                                        type="text"
                                        @blur="validate()"
                                        ref="short-text-a"
                                        class="form-control">
                            </div>
                            <p v-show="error" class="text-danger">
                                {{ errorMessage }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'reg-yes-no-question',
  props: [
    'question',
    'existingAnswer',
  ],
  data() {
    return {
      answer: {
        answer: '',
        addtl_info: '',
      },

      // These two are the v-model bound reactive things
      mAnswer: '',
      mAddtlInfo: '',
      possibleAnswers: [],
      originalAnswer: null,
      dirty: false,
      valid: true,
      error: false,
      errorMessage: 'Please enter an answer.',
      required: false,
      srId: '',
    };
  },
  watch: {
    // whenever question changes, this function will run
    mAnswer() {
      this.updateAnswer();
      this.setDirty();
      this.calculateValid();
      this.emit();
    },
    mAddtlInfo() {
      this.updateAnswer();
      this.setDirty();
      this.calculateValid();
      this.emit();
    },
  },
  computed: {
    hasSubtext() {
      const vm = this;
      if (!vm.question) {
        return false;
      }

      return vm.question.subtext && vm.question.subtext.length !== 0;
    },
    needsAddtlInfo() {
      const vm = this;
      if (!vm.answer.answer || vm.answer.answer.length === 0) {
        return false;
      }

      const possibleAnswer = vm.selectedPossibleAnswer;
      return possibleAnswer.addtl_info;
    },
    addtlInfoPrompt() {
      const vm = this;
      const possibleAnswer = vm.selectedPossibleAnswer;
      if (possibleAnswer) {
        return possibleAnswer.addtl_prompt;
      }

      return '';
    },
    selectedPossibleAnswer() {
      const vm = this;
      return _.find(vm.possibleAnswers, (pAnswer) => vm.answer.answer === pAnswer.prompt);
    },
  },
  created() {
    const vm = this;
    if (vm.existingAnswer && vm.existingAnswer.answer !== '') {
      vm.originalAnswer = vm.existingAnswer.answer.answer;
      vm.answer = vm.existingAnswer.answer;
      vm.mAnswer = vm.answer.answer;
      vm.mAddtlInfo = vm.answer.addtl_info;
    }

    if (vm.question) {
      vm.required = vm.question.required;
      vm.srId = vm.question.id;
      vm.possibleAnswers = vm.question.answers;
    }

    vm.calculateValid();
    vm.$notificationManager.$on('show-reg-errors', vm.validate);
  },
  destroyed() {
    this.$notificationManager.$off('show-reg-errors', this.validate);
  },
  methods: {
    emit() {
      const vm = this;
      vm.$emit('answer-changed', vm.question, vm.answer, vm.dirty, vm.valid);
    },
    getClass() {
      if (this.error) {
        return 'form-group has-error';
      }

      return 'form-group';
    },
    updateAnswer() {
      const vm = this;
      vm.answer.answer = vm.mAnswer;
      if (vm.needsAddtlInfo) {
        vm.answer.addtl_info = vm.mAddtlInfo;
      } else {
        vm.answer.addtl_info = '';
      }
    },
    setDirty() {
      const vm = this;
      if (vm.originalAnswer && vm.originalAnswer === vm.answer.answer) {
        vm.dirty = false;
      } else {
        vm.dirty = true;
      }
    },
    calculateValid() {
      const answerLength = this.answer.answer.length;

      if (this.needsAddtlInfo) {
        this.valid = answerLength > 0 && this.answer.addtl_info.length > 0;
      } else {
        this.valid = (answerLength > 0) || (answerLength === 0 && !this.required);
      }

      if (this.valid) {
        // if we are now valid, undo the red text
        this.validate();
      }
    },
    validate() {
      this.error = !this.valid;
    },
    prompt() {
      const vm = this;
      if (!vm.question) {
        return '';
      }

      let { prompt } = vm.question;

      if (!vm.required) {
        prompt += ' (optional)';
      }

      return prompt;
    },
  },
};
</script>
