<template>
    <div class="swal-alert-btn-container" style="display: inline-block;" @click.prevent="fireSwal">
        <slot>

        </slot>
    </div>
</template>

<script>
import swal from 'sweetalert2';

export default {
  name: 'swal-alert',
  props: [
    'title',
    'description',
    'htmlDescription',
    'url',
    'confirmText',
    'swalWidth',
  ],
  methods: {
    fireSwal() {
      const vm = this;
      const confirmText = vm.confirmText || 'Continue to Registration';
      const width = vm.swalWidth || '32em';
      swal.fire({
        title: `${vm.title}`,
        text: vm.description,
        html: vm.htmlDescription,
        width,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonClass: 'btn btn-success m-l-md',
        confirmButtonText: confirmText,
        cancelButtonClass: 'btn btn-default',
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          window.location.assign(vm.url);
        }
      });
    },
  },
};
</script>
