<template>
    <div class="ibox">
        <div class="ibox-title">
          <div class="flex gap-x-xs align-items-baseline" v-if="renaming">
            <input type="text" v-model="name" class="form-control"/>
            <a @click.present="renaming = false">
              Done
            </a>
          </div>
          <div class="flex gap-x-xs align-items-baseline" v-else>
            <h1>{{ name }}</h1>
            <div v-if="isTemplate" class="text-10">
              <a @click.present="renaming = true">
                <i class="fa fa-pencil"/> Rename
              </a>
            </div>
          </div>
        </div>
        <div class="ibox-content">
            <div role="form">
                <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
                <div class="flex align-items-center gap-x-sm" v-if="isTemplate">
                    <a href="/templates/practice_plans" class="btn btn-default">View All Templates</a>
                    <ladda-button @lbClicked="emitSave" el-class="btn-primary" :loading="loading">
                      Save Template
                    </ladda-button>
                </div>
                <div class="flex align-items-center gap-x-sm" v-else>
                  <a :href="eventHref()" class="btn btn-default">View Event</a>
                  <div class="btn-group">
                    <ladda-button @lbClicked="emitSave" el-class="btn-primary" :loading="loading">
                      Save Plan
                    </ladda-button>
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="caret"></span>
                      <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a @click.prevent="showTemplateModal">Create template...</a
                      ></li>
                    </ul>
                  </div>
                </div>
                <p v-if="templateCreated">Template created. You can use it the next time you create a practice plan or view it in the <a href="/practice_plans/templates" target="_blank">template gallery</a>.</p>
            </div>
        </div>
        <div id="plan-notify-modal" class="modal fade" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Notify Coaches?</h4>
                    </div>
                    <div class="modal-body">
                        <plan-saved-notify v-if="showNotify" :planId="planId"></plan-saved-notify>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>

<script>
import PlanSavedNotify from './plan_saved_notify.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import swal from 'sweetalert2';

export default {
  name: 'save-practice-plan',
  components: { LaddaButton, PlanSavedNotify },
  props: [
    'planId',
    'eventId',
    'planName',
    'isTemplate',
  ],
  data() {
    return {
      error: false,
      errorMessage: '',
      showNotify: false,
      loading: false,
      templateCreated: false,
      name: "",
      renaming: false,
    };
  },
  created() {
    const vm = this;
    vm.name = this.planName;
    vm.$notificationManager.$on('practice-plan-saved', vm.successfulSave);
    vm.$notificationManager.$on('practice-template-created', vm.successfulTemplateSave);
    vm.$notificationManager.$on('practice-plan-save-error', vm.handleError);
    vm.$notificationManager.$on('plan-notify-close', vm.closeNotifyModal);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('practice-plan-saved', vm.successfulSave);
    vm.$notificationManager.$off('practice-plan-save-error', vm.handleError);
    vm.$notificationManager.$off('practice-template-created', vm.successfulTemplateSave);
    vm.$notificationManager.$off('plan-notify-close', vm.closeNotifyModal);
  },
  methods: {
    successfulSave(planId) {
      const vm = this;
      if (vm.planId === planId) {
        vm.loading = false;
        if (!vm.isTemplate) {
          vm.showNotifyModal();
        }
      }
    },
    successfulTemplateSave() {

    },
    handleError(planId, errorMsg) {
      const vm = this;
      if (vm.planId === planId) {
        vm.saveError(errorMsg);
      }
    },
    eventHref() {
      return `/events/${this.eventId}`;
    },
    saveError(errorMsg) {
      const vm = this;
      vm.loading = false;
      vm.errorMessage = errorMsg;
      vm.error = true;
    },
    emitSave() {
      const vm = this;
      vm.error = false;
      vm.loading = true;
      vm.$notificationManager.$emit('save-practice-plan', vm.planId, vm.name);
    },
    notifyModalId() {
      return '#plan-notify-modal';
    },
    showNotifyModal() {
      const vm = this;
      vm.showNotify = true;
      $(vm.notifyModalId()).modal('show');
    },
    closeNotifyModal(planId) {
      const vm = this;
      if (vm.planId === planId) {
        vm.showNotify = false;
        $(vm.notifyModalId()).modal('hide');
      }
    },
    showTemplateModal() {
      const vm = this;
      swal
          .fire({
            type: "info",
            title: "Create practice plan template",
            text: "What should this template be called?",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonClass: "btn btn-primary m-l-md",
            confirmButtonText: "Create",
            cancelButtonClass: "btn btn-default",
            buttonsStyling: false,
            input: 'text',
            inputLabel: 'Name',
            inputValue: "",
            inputValidator: (value) => {
              if (!value) {
                return 'You need to provide a name for the template.'
              }
            }
          })
          .then((result) => {
            if (result.value) {
              vm.$notificationManager.$emit('create-practice-plan-template', result.value, vm.planId);
            }
          });
    },
  },
};
</script>
