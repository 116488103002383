<template>
  <div class="m-b-m flex justify-space-between">
    <div class="search-result">
      <h3>
        {{ name }}
      </h3>
      <p v-if="hasDates">
        {{ dateRange() }}
      </p>
      <p v-if="sessionType === 'recurring'">
        Membership
      </p>
      <p v-if="sessionType === 'trial'">
        Trial
      </p>
    </div>
    <div class="flex-shrink-0">
      <template v-if="hasAlert">
        <swal-alert
            :url="url"
            :title="alertProperty('title')"
            :description="alertProperty('description')"
            :confirm-text="alertProperty('confirmText')"
        >
          <a :href="url" class="btn btn-primary">Sign up</a>
        </swal-alert>
      </template>
      <template v-else>
        <a :href="url" class="btn btn-primary">Sign up</a>
      </template>
    </div>
  </div>
</template>
<script>
import SwalAlert from '../../shared/swal_alert.vue';

export default {
  name: 'sign-up-row',
  components: { SwalAlert },
  props: {
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    start: {
      type: String,
      required: false,
    },
    end: {
      type: String,
      required: false,
    },
    sessionType: {
      type: String,
      required: true,
    },
    swalAlert: {
      type: Object,
      required: false,
      default() { return {}; },
    },
  },
  computed: {
    hasAlert() {
      return this.swalAlert?.title && this.swalAlert?.description && this.swalAlert?.confirmText;
    },
    hasDates() {
      return this.start && this.end;
    },
  },
  methods: {
    alertProperty(key) {
      return _.get(this.swalAlert, key, '');
    },
    dateRange() {
      const start = moment(this.start).format('MMM Do YYYY');
      const end = moment(this.end).format('MMM Do YYYY');
      return `${start} to ${end}`;
    },
  },
};
</script>
<style scoped>
</style>
