<template>
    <div class="attachment">
        <a :href="decodeURI(attachment.url)" target="_blank">{{ attachment.name }}</a>
    </div>
</template>

<script>
export default {
  name: 'attachment',
  props: [
    'attachment',
  ],
};
</script>
