<template>
  <div role="form">
    <div class="form-group">
      <div class="row">
        <div :class="firstColClasses">
          <date-pair-picker v-if="showDates" @dateRangeChanged="dateRangeChanged"></date-pair-picker>
          <div v-if="showSessionPicker">
            <select v-model="selectedSessionId" :class="getSelectClasses()">
              <optgroup v-for="(group, name) in sessionGroupOpts" :label="name" v-if="group.length > 0">
                <option v-for="option in group" v-bind:value="option.id">
                  {{ option.name }}
                </option>
              </optgroup>
            </select>
          </div>
          <div v-if="showRosterPicker">
            <div>
              <select v-model="selectedRosterId" :class="getSelectClasses()">
                <optgroup v-for="(group, name) in rosterGroupOpts" :label="name" v-if="group.length > 0">
                  <option v-for="option in group" v-bind:value="option.id">
                    {{ option.name }}
                  </option>
                </optgroup>
              </select>
              <div class="m-t-sm" v-if="showColumnAdd">
                <p v-if="!addWrestlerProperties"><a href="#" @click.prevent="showCustomWrestlerProperties">Add custom columns...</a></p>
                <div v-if="addWrestlerProperties">
                  <h3>Append public properties</h3>
                  <hr class="m-t-xs m-b-sm"/>
                  <spinner v-if="loadingPublicWrestlerProperties" :inline="true"></spinner>
                  <div v-for="(questions, groupName) in publicQuestionsByGroup">
                    <h4 class="font-semi">{{ groupName }}</h4>
                    <div class="flex flex-col">
                      <label v-for="question in questions" :key="question.id" class="font-normal">
                        <input type="checkbox" v-model="question.selected" style="margin-right: 4px;">
                        {{ question.prompt }}
                      </label>
                    </div>
                  </div>
                  <h3 class="m-t-m" style="margin-top: 24px;">Append private properties</h3>
                  <hr class="m-t-xs m-b-sm"/>
                  <spinner v-if="loadingPrivateWrestlerProperties" :inline="true"></spinner>
                  <div v-for="(questions, groupName) in privateQuestionsByGroup">
                    <h4 class="font-semi">{{ groupName }}</h4>
                    <div class="flex flex-col">
                      <label v-for="question in questions" :key="question.id" class="font-normal">
                        <input type="checkbox" v-model="question.selected" style="margin-right: 4px;">
                        {{ question.prompt }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showFundraiserPicker">
            <select v-model="selectedFundraiserId" :class="getSelectClasses()">
              <option v-for="option in fundraiserPickerOptions" v-bind:value="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
          <div v-if="showStorePicker">
            <select v-model="selectedStoreId" :class="getSelectClasses()">
              <option v-for="option in storePickerOptions" v-bind:value="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div :class="secondColClasses">
          <div class="button-area">
            <ladda-button @lbClicked="runReport" :el-class="btnClasses" :loading="loading">
              {{ buttonText }}
            </ladda-button>
            <div v-show="error">
              <small>
                <span class="text-danger">{{ errorMessage }}</span>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import DatePicker from './date_picker.vue';
import DatePairPicker from './date_pair_picker.vue';
import Spinner from './spinner.vue';

export default {
  name: 'report-form',
  components: {
    DatePairPicker,
    DatePicker,
    LaddaButton,
    Spinner,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    version: {
      type: String,
      required: true,
    },
    showColumnAdd: {
      type: Boolean,
      default: false,
    },
    showDates: {
      type: Boolean,
      default: false,
    },
    showRosterPicker: {
      type: Boolean,
      default: false,
    },
    rosterPickerOptions: {
      type: Array,
      required: false,
    },
    showSessionPicker: {
      type: Boolean,
      default: false,
    },
    paidSessionPickerOptions: {
      type: Array,
      required: false,
    },
    showFundraiserPicker: {
      type: Boolean,
      default: false,
    },
    fundraiserPickerOptions: {
      type: Array,
      required: false,
    },
    showStorePicker: {
      type: Boolean,
      default: false,
    },
    storePickerOptions: {
      type: Array,
      required: false,
    },
    prefilledStoreId: {
      type: Number,
      required: false,
    },
    prefilledPaidSessionId: {
      type: Number,
      required: false
    },
    prefilledFundraiserId: {
      type: Number,
      required: false
    },
    buttonText: {
      type: String,
      default: 'Run Report',
    },
    firstColClasses: {
      type: String,
      default: 'col-sm-5 col-sm-offset-3 col-lg-5 col-lg-offset-5',
    },
    secondColClasses: {
      type: String,
      default: 'col-sm-3 col-lg-2',
    },
    btnClasses: {
      type: String,
      default: 'btn-primary',
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error creating report, please try again later. Or email support@wrestlingiq.com for help.',
      start: null,
      end: null,

      selectedRosterId: null,
      rosterGroupOpts: {
        All: [],
        Active: [],
        Archived: [],
      },

      sessionPickerOptions: [],
      selectedSessionId: null,
      sessionGroupOpts: {
        All: [],
        Active: [],
        Archived: [],
      },

      selectedFundraiserId: null,
      selectedStoreId: null,

      addWrestlerProperties: false,
      loadingPublicWrestlerProperties: false,
      publicQuestionsByGroup: {},

      loadingPrivateWrestlerProperties: false,
      privateQuestionsByGroup: {},
    };
  },
  mounted() {
    const vm = this;
    if (vm.showRosterPicker) {
      vm.formatRosters();
    }
    if (vm.showSessionPicker) {
      vm.formatPaidSessions();
    }
    if (vm.prefilledStoreId) {
      vm.selectedStoreId = vm.prefilledStoreId;
    }
    if (vm.prefilledPaidSessionId) {
      vm.selectedSessionId = vm.prefilledPaidSessionId;
    }
    if (vm.prefilledFundraiserId) {
      vm.selectedFundraiserId = vm.prefilledFundraiserId;
    }
  },
  computed: {
    allowAllPaidSessionsInPicker() {
      return this.type === 'UsawExportReport';
    },
    pickersVisible() {
      return this.showFundraiserPicker || this.showSessionPicker || this.showRosterPicker || this.showStorePicker;
    },
  },
  methods: {
    selectedQuestionIdsToAppend() {
      let selectedPubIds = _.map(_.filter(_.flatMap(_.values(this.publicQuestionsByGroup)), 'selected'), 'id')
      let selectedPrivIds = _.map(_.filter(_.flatMap(_.values(this.privateQuestionsByGroup)), 'selected'), 'id')
      return _.concat(selectedPubIds, selectedPrivIds);
    },
    showCustomWrestlerProperties() {
      this.addWrestlerProperties = true;
      this.loadingPublicWrestlerProperties = true;
      this.loadingPrivateWrestlerProperties = true;

      this.$apiService.loadAllPages(this.$apiService.wrestlerPropertiesForReports(), 'registration_questions')
          .then((reg_questions) => {
            let publicGroups = {}
            const groups = _.map(reg_questions, 'group_with');
            for (const key of groups) {
              // each category should be able to hold an array
              publicGroups[key] = [];
            }

            for (const question of reg_questions) {
              question.selected = false;
              publicGroups[question.group_with].push(question);
            }
            this.publicQuestionsByGroup = publicGroups;
            this.loadingPublicWrestlerProperties = false;
          })
          .catch((error) => {
            this.errorMessage = `Error retrieving public properties ${error.toString()}`
            this.loadingPublicWrestlerProperties = false;
            this.error = true
          });

      this.$apiService.loadAllPages(this.$apiService.privateWrestlerPropertiesForReports(), 'registration_questions')
          .then((reg_questions) => {
            let privateGroups = {}
            const groups = _.map(reg_questions, 'group_with');
            for (const key of groups) {
              // each category should be able to hold an array
              privateGroups[key] = [];
            }

            for (const question of reg_questions) {
              question.selected = false;
              privateGroups[question.group_with].push(question);
            }
            this.privateQuestionsByGroup = privateGroups;
            this.loadingPrivateWrestlerProperties = false;
          })
          .catch((error) => {
            this.errorMessage = `Error retrieving private properties ${error.toString()}`
            this.loadingPrivateWrestlerProperties = false;
            this.error = true
          });
    },
    getSelectClasses() {
      if (this.pickersVisible && this.showDates) {
        return 'form-control m-b m-t-xs';
      }

      return 'form-control m-b';
    },
    formatPaidSessions() {
      // For sessions we build up two option groups, one for archived one for now
      let activeSessions = _.filter(this.paidSessionPickerOptions, ['archived', false]);
      let archivedSessions = _.filter(this.paidSessionPickerOptions, ['archived', true]);
      // Add the default all back in
      if (this.allowAllPaidSessionsInPicker) {
        const defaultSession = {
          id: 0,
          name: 'All',
        };
        this.sessionGroupOpts.All = [defaultSession]
      }

      this.sessionGroupOpts.Active = _.sortBy(activeSessions, 'name');
      this.sessionGroupOpts.Archived = _.sortBy(archivedSessions, 'name');
    },
    formatRosters() {
      // Same as formatPaidSessions where we have an archived flag
      let activeRosters = _.filter(this.rosterPickerOptions, ['archived', false]);
      let archivedRosters = _.filter(this.rosterPickerOptions, ['archived', true]);
      // Add the default all back in
      const defaultRoster = {
        id: 0,
        name: 'All',
      };
      this.rosterGroupOpts.All = [defaultRoster]
      this.rosterGroupOpts.Active = _.sortBy(activeRosters, 'name');
      this.rosterGroupOpts.Archived = _.sortBy(archivedRosters, 'name');
    },
    dateRangeChanged(start, end) {
      this.start = start;
      this.end = end;
    },
    getParams() {
      const vm = this;
      let params = {
        report: {
          name: vm.name,
          type: vm.type,
          version: vm.version,
        },
      }

      let filterParams = {}
      if (vm.showRosterPicker) {
        filterParams.roster_id = vm.selectedRosterId;
      }
      if (vm.showSessionPicker || vm.prefilledPaidSessionId) {
        filterParams.paid_session_id = vm.selectedSessionId;
      }
      if (vm.showFundraiserPicker || vm.prefilledFundraiserId) {
        filterParams.fundraiser_id = vm.selectedFundraiserId;
      }
      if (vm.showStorePicker || vm.prefilledStoreId) {
        filterParams.online_store_id = vm.selectedStoreId;
      }
      let additionalColumns =  this.selectedQuestionIdsToAppend();
      if (additionalColumns.length > 0) {
        filterParams.append_property_ids = additionalColumns;
      }

      params.report.args = filterParams;


      if (vm.showDates) {
        params.report.start_at = vm.start.toISOString()
        params.report.end_at = vm.end.toISOString()
      }

      return params;
    },
    runReport: _.throttle(function () {
      const vm = this;

      if (vm.showDates && (!vm.start || !vm.end)) {
        vm.errorMessage = 'Select a start and end date to run this report.';
        vm.error = true;
        return;
      }

      if (vm.showRosterPicker && vm.selectedRosterId === null) {
        vm.errorMessage = 'Select a roster to run this report';
        vm.error = true;
        return;
      }

      if (vm.showSessionPicker && vm.selectedSessionId === null) {
        vm.errorMessage = 'Select a session to run this report';
        vm.error = true;
        return;
      }

      if (vm.showFundraiserPicker && vm.selectedFundraiserId === null) {
        vm.errorMessage = 'Select a fundraiser to run this report.';
        vm.error = true;
        return;
      }

      if (vm.showStorePicker && vm.selectedStoreId === null) {
        vm.errorMessage = 'Select a store to run this report.';
        vm.error = true;
        return;
      }

      if (vm.loading) {
        return;
      }

      vm.loading = true;

      const url = vm.$apiService.reportsUrl();
      axios.post(url, vm.getParams())
          .then((response) => {
            vm.loading = false;
            window.location.assign(`/reports/${response.data.id}`);
          })
          .catch((error) => {
            vm.errorMessage = `Error ${error.toString()}. Please try again later or contact support@wrestlingiq.com`;
            vm.error = true;
            vm.loading = false;
          });
    }, 500),
  },
};
</script>
