<template>
    <div>
        <dropzone class="dropzone"
                  qq-drop-area-text="Drop video files here"
                  :multiple="true"
                  :uploader="uploader"
        >
            <file-input :uploader="uploader" accept="video/*" :multiple="true">
                <span class="btn">Or choose videos...</span>
            </file-input>
        </dropzone>
        <div :class="getVisibilityClass()">
            <div class="list-results m-t-md grid sm-grid-cols-1 md-grid-cols-2 grid-cols-3 gap-md" role="region" aria-live="polite" aria-relevant="additions removals">
                <original-video v-for="video in state.submittedVideos" :allow-event-create="allowEventCreate" :unknown-opponent-id="unknownOpponentId" :key="video.file_id" :result="video" :currently-uploading="true" :uploader="uploader" :ruleset-slug="rulesetSlug">
                </original-video>
            </div>
        </div>
    </div>
</template>

<script>
import Dropzone from 'vue-fineuploader/dropzone.vue';
import FileInput from 'vue-fineuploader/file-input.vue';
import FineUploaderS3 from 'fine-uploader-wrappers/s3';
import RSVP from 'rsvp';
import OriginalVideo from './original_video.vue';

export default {
  components: {
    OriginalVideo,
    Dropzone,
    FileInput,
    FineUploaderS3,
    RSVP,
  },
  name: 'video-uploader',
  props: {
    endpoint: {
      type: String,
      required: true
    },
    accessKey: {
      type: String,
      required: true
    },
    authToken: {
      type: String,
      required: true
    },
    allowEventCreate: {
      type: Boolean,
      default: false
    },
    rulesetSlug: {
      type: String,
      required: true
    },
    unknownOpponentId: {
      type: Number,
      required: true
    }
  },
  data() {
    const vm = this;

    const getKey = function (fileId) {
      const filename = encodeURIComponent(vm.uploader.methods.getName(fileId));
      const keyRetrieval = new RSVP.Promise((resolve, reject) => {
        const params = {
          original_video: {
            name: filename,
          },
        };
        const url = vm.$apiService.originalVideosUrl();
        axios.post(url, params)
          .then((response) => {
            vm.appendJSONToSubmittedVideo(fileId, response.data);
            resolve(response.data.key);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return keyRetrieval;
    };

    const uploader = new FineUploaderS3({
      options: {
        request: {
          endpoint: vm.endpoint,
          accessKey: vm.accessKey,
        },
        maxConnections: 50,
        signature: {
          endpoint: '/api/v1/original_videos/s3_signature',
          customHeaders: {
            Authorization: `Bearer ${vm.authToken}`,
          },
        },
        uploadSuccess: {
          endpoint: '/api/v1/original_videos/s3_confirm',
          customHeaders: {
            Authorization: `Bearer ${vm.authToken}`,
          },
        },
        objectProperties: {
          // acl: 'public-read',
          key: getKey,
        },
        validation: {
          allowedExtensions: ['mpeg', 'mpg', 'mp4', 'avi', 'ogv', 'webm', 'mov', 'mts', 'm4v'],
          acceptFiles: 'video/mp4, video/mpeg, video/mpg, video/ogg, video/webm, video/quicktime, video/x-m4v, video/x-msvideo, video/mts, video/MP2T, video/mp2t, video/avchd, video/avchd-stream',
          itemLimit: 50,
        },
        retry: {
          enableAuto: true,
        },
        chunking: {
          enabled: true,
        },
        resume: {
          // Resume is now disabled because the UI is not handling it correctly
          // to see the bug start an upload with pause enabled, then refresh the page
          // now try to upload again and you will see that the video does not start uploading
          enabled: false,
        },
      },
    });

    return {
      state: {
        submittedVideos: [],
      },
      error: false,
      errorMessage: '',

      loading: false,

      uploader,
      dimSections: false,
    };
  },
  mounted() { // This is the new es2016 shorthand
    const vm = this;
    vm.uploader.on('complete', (id, name, response) => {
      // handle completed upload
      vm.$notificationManager.$emit('video-uploaded', id);
      vm.$notificationManager.$emit('show-toast', 'Video uploaded', true);
    });

    vm.uploader.on('statusChange', (id, oldStatus, newStatus) => {
      if (vm.isFileGone(newStatus)) {
        const { submittedVideos } = vm.state;
        let indexToRemove = null;

        for (const video of submittedVideos) {
          if (video.file_id === id) {
            indexToRemove = submittedVideos.indexOf(video);
          }
        }

        submittedVideos.splice(indexToRemove, 1);
        vm.$set(vm.state, 'submittedVideos', submittedVideos);
      }
    });

    vm.$notificationManager.$on('form-shown', vm.handleFormShown);
    vm.$notificationManager.$on('form-hidden', vm.handleFormHidden);
    vm.$notificationManager.$on('match-created', vm.handleMatchChange);
    vm.$notificationManager.$on('match-updated', vm.handleMatchChange);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('form-shown', vm.handleFormShown);
    vm.$notificationManager.$off('form-hidden', vm.handleFormHidden);
    vm.$notificationManager.$off('match-created', vm.handleMatchChange);
    vm.$notificationManager.$off('match-updated', vm.handleMatchChange);
  },
  watch: {
    state(newState) {
      if (newState.submittedVideos.length > 0) {
        // Enable navigation prompt
        window.onbeforeunload = function () {
          return true;
        };
      } else {
        // Remove navigation prompt
        window.onbeforeunload = null;
      }
    },
  },
  methods: {
    handleFormShown(mentionableType) {
      const vm = this;
      if (mentionableType === 'OriginalVideo') {
        vm.dimSections = true;
      }
    },
    handleFormHidden(mentionableType) {
      const vm = this;
      if (mentionableType === 'OriginalVideo') {
        vm.dimSections = false;
      }
    },
    handleMatchChange(match, originalVideo) {
      this.dimSections = false;
    },
    getVisibilityClass() {
      if (this.dimSections) {
        return 'dim';
      }
      return '';
    },
    isFileGone(status) {
      return [
        'canceled',
        'deleted',
      ].indexOf(status) >= 0;
    },
    appendJSONToSubmittedVideo(fileId, json) {
      const vm = this;
      const { submittedVideos } = vm.state;
      json.file_id = fileId;
      submittedVideos.push(json);
      vm.$set(vm.state, 'submittedVideos', submittedVideos);
    },
  },
};
</script>
