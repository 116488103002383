<template>
    <div>
        <full-load-list
                :base-url="getListUrl()"
                response-key="event_invites"
        >
            <template v-slot:list="slotProps">
                <event-invite :invite="slotProps.result"></event-invite>
            </template>
        </full-load-list>
    </div>
</template>

<script>
import FullLoadList from './full_load_list.vue';
import EventInvite from './event_invite.vue';

export default {
  name: 'all-event-invite-list',
  components: {
    FullLoadList,
    EventInvite,
  },
  props: [
    'eventId',
  ],
  methods: {
    getListUrl() {
      return this.$apiService.eventInvitesUrl(this.eventId);
    },
  },
};
</script>
