<template>
  <div class='search-result teammate'>
    <div v-if="!noHr" class='hr-line-dashed'></div>
    <div class="row">
      <div class="col-xs-12">
        <div class="flex">
          <div v-if="avatarExists" class="m-r-m">
            <div class="image">
              <img :src="result.avatar_thumbnail" class="profile-pic" alt="profile picture"/>
            </div>
          </div>
          <div>
            <h3>
              <template v-if="isSelectable">
                {{ result.first_name }} {{ result.last_name }}
              </template>
              <template v-else>
                <a :href="'/wrestlers/' + result.id " :target="blankTarget ? '_blank' : '_self'">
                  {{ result.first_name }} {{ result.last_name }}
                  <i class="fa fa-external-link" v-if="blankTarget"></i>
                </a>
              </template>
            </h3>
            <p class='search-type-label' v-html="subTitle()">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wrestler-search-result',
  props: {
    result: {
      type: Object,
      required: true
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    noHr: {
      type: Boolean,
      default: false
    },
    blankTarget: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    avatarExists: function() {
      return this.wrestler?.avatar_thumbnail;
    },
  },
  methods: {
    subTitle() {
      let vm = this;
      let result = vm.result;
      let age = result.age ? `${result.age} years old` : null;
      let values = _.compact([result.academic_class, result.weight_class, age, _.capitalize(result.experience)]);
      return _.join(values, "<br/>");
    },
  }
}
</script>
