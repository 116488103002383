<template>
    <div>
        <event-create
                :for-calendar="true"
                :update-event-id="eventId"
                :paid-session-tagging="showPaidSessionTagging"
                :endpoint="endpoint"
                :access-key="accessKey"
                :auth-token="authToken"
                :cloudfront-base-url="cloudfrontBaseUrl"
        >
        </event-create>
        <div id="event-update-modal" class="modal fade" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Update Repeated Events?</h4>
                    </div>
                    <div class="modal-body">
                        <repeated-event-update v-if="updatingRepeatedEvent" :event="repeatedEventForUpdate" :params="repeatingEventForUpdateParams"></repeated-event-update>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
        <div id="event-notify-modal" class="modal fade" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Send Notices?</h4>
                    </div>
                    <div class="modal-body">
                        <event-change-notify v-if="eventNotify" :event="eventForNotify" :recurring-change="recurringChanged"></event-change-notify>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>

<script>
import EventCreate from './event_create.vue';
import EventChangeNotify from './event_change_notify.vue';
import RepeatedEventUpdate from './repeated_event_update.vue';

export default {
  name: 'event-edit',
  components: {
    EventChangeNotify,
    EventCreate,
    RepeatedEventUpdate,
  },
  props: {
    eventId: {
      type: Number,
      required: true
    },
    showPaidSessionTagging: {
      type: Boolean,
      default: false,
    },
    accessKey: {
      type: String,
      required: false,
    },
    authToken: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      eventNotify: false,
      eventForNotify: false,
      recurringChanged: false,
      eventForDelete: null,

      repeatedEventForUpdate: null,
      repeatingEventForUpdateParams: null,
      revertUpdateFunc: null,
      updatingRepeatedEvent: false,
    };
  },
  mounted() {
    const vm = this;
    vm.$notificationManager.$on('repeated-event-update-cancel', vm.hideRecurringModal);
    vm.$notificationManager.$on('repeated-event-updated', vm.recurringEventUpdated);
    vm.$notificationManager.$on('event-notify-close', vm.closeNotifyEvent);
    vm.$notificationManager.$on('show-recurring-event-update', vm.showRecurringModal);
    vm.$notificationManager.$on('event-deleted', vm.eventDeleted);
    vm.$notificationManager.$on('event-updated', vm.showNotifyModal);
  },
  destroyed() {
    const vm = this;

    vm.$notificationManager.$off('repeated-event-update-cancel', vm.hideRecurringModal);
    vm.$notificationManager.$off('repeated-event-updated', vm.recurringEventUpdated);
    vm.$notificationManager.$off('event-notify-close', vm.closeNotifyEvent);
    vm.$notificationManager.$off('show-recurring-event-update', vm.showRecurringModal);
    vm.$notificationManager.$off('event-deleted', vm.eventDeleted);
    vm.$notificationManager.$off('event-updated', vm.showNotifyModal);
  },
  methods: {
    eventDeleted(event, recurring) {
      this.eventForDelete = event;
      this.showNotifyModal(event, recurring)
    },
    recurringEventUpdated(event, needsRefetch) {
      const vm = this;
      vm.hideRecurringModal();
      vm.showNotifyModal(event, needsRefetch);
    },
    hideRecurringModal() {
      const vm = this;
      vm.updatingRepeatedEvent = false;
      vm.repeatedEventForUpdate = null;
      vm.repeatingEventForUpdateParams = null;
      $(vm.updateModalId()).modal('hide');
    },
    showRecurringModal(event, params) {
      // trigger the update recurring modal
      const vm = this;
      vm.repeatedEventForUpdate = event;
      vm.repeatingEventForUpdateParams = params;
      vm.updatingRepeatedEvent = true;
      $(vm.updateModalId()).modal('show');
    },
    updateModalId() {
      return '#event-update-modal';
    },
    notifyModalId() {
      return '#event-notify-modal';
    },
    showNotifyModal(event, recurring) {
      const vm = this;
      vm.eventForNotify = event;
      vm.recurringChanged = recurring;
      vm.eventNotify = true;
      $(vm.notifyModalId()).modal('show');
    },
    closeNotifyEvent(event) {
      const vm = this;
      vm.eventNotify = false;
      vm.eventForNotify = null;
      $(vm.notifyModalId()).modal('hide');
      if (event.id === this.eventForDelete?.id) {
        window.location.assign('/calendar');
      } else{
        window.location.assign(`/events/${vm.eventId}`);
      }
    },
  },
};
</script>
