<template>
  <div class="search-result">
    <template v-if="result">
      <div v-if="!noHr" class='hr-line-dashed m-b-md m-t-md'></div>
      <div class="grid sm-grid-cols-1 md-grid-cols-1 grid-cols-5">
        <div class="col-span-2 flex align-items-center justify-space-between">
          <div class="flex-shrink-1">
            <a :href="result.url" target="_blank">
              <h3 class="text-gray-700 font-heavy">
                {{ displayName() }}
              </h3>
            </a>
            <div class="flex align-items-center gap-x-sm hidden-xs hidden-sm" v-if="result.url">
              <p class="m-t-m m-b-none ">
                <a :href="result.url" class="text-gray-700" target="_blank">View Invoice <span v-if="result.number">{{result.number}}</span> <i class="fa fa-external-link"></i></a>
              </p>
              <div class="cursor-pointer" @click="copyUrl(result.url)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 color-gray-700" fill="currentColor">
                  <path d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="btn-group link-btn visible-xs visible-sm align-self-flex-start ">
            <button
                data-toggle="dropdown"
                aria-expanded="false"
                class="btn btn-link icon-only dropdown-toggle p-t-n p-r-n"
            >
              <i aria-hidden="true" class="fa fa-ellipsis-v"></i>
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right pull-right">
              <template v-if="result.url">
                <li><a :href="result.url" target="_blank"><i class="fa fa-external-link"></i>&nbsp;&nbsp; View</a></li>
                <li><a @click.prevent="copyUrl(result.url)"><i class="fa fa-copy"></i>&nbsp;&nbsp; Copy link</a></li>
              </template>
              <template v-if="result.pdf">
                <li><a :href="result.pdf"><i class="fa fa-download"></i>&nbsp;&nbsp; Download PDF</a></li>
              </template>
              <template v-if="receiptUrl()">
                <li><a @click.prevent="downloadFile(receiptUrl(), result.number ? `Invoice #${result.number} - Receipt` : 'Receipt')">
                  <i class="fa fa-download"></i>&nbsp;&nbsp;Download Receipt</a>
                </li>
              </template>
              <template v-if="allowEdits && canBeModified()">
                <li class="divider"></li>
                <li><a @click.prevent="showPaidForm()">Mark paid...</a></li>
                <li><a @click.prevent="showMarkVoidForm()">Void...</a></li>
              </template>
            </ul>
          </div>
        </div>
        <div class="flex align-items-center justify-space-between">
          <div class="flex flex-wrap align-items-center" v-html="badgeHtml()">
          </div>
          <div>
            <div class="flex align-items-center">
              <div class="visible-sm visible-xs uppercase tracking-wide font-semi text-12 m-r-xs text-gray-500">
                Amount due
              </div>
              <div class="text-12 font-semi text-gray-700 visible-xs visible-sm">
                {{ amount() }}
                <VDropdown theme="info-tooltip">
                  <!-- This will be the popover target (for the events and position) -->
                  &nbsp;<i class="fa fa-info"></i>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div>
                      <div class="flex flex-col justify-space-between" style="min-width: 140px;">
                        <p class="header border-bottom p-b-sm">
                          Invoice # {{ result.number }}
                        </p>
                        <div v-for="item in result.invoice_items" class="flex justify-space-between">
                          <div>
                            {{ item.description }} x {{ item.quantity }}
                          </div>
                          <div class="m-l-sm">
                            {{ formatAsCurrency(item.amount) }}
                          </div>
                        </div>
                        <div class="flex justify-space-between m-t-sm p-t-sm border-top">
                          <div class="text-semi">
                            Paid
                          </div>
                          <div>
                            {{ formatAsCurrency(result.amount_paid) }}
                          </div>
                        </div>
                        <div class="flex justify-space-between">
                          <div class="text-semi">
                            Outstanding
                          </div>
                          <div>
                            {{ formatAsCurrency(Math.abs(result.amount_paid - result.amount_due)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </VDropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="flex align-items-center justify-space-between hidden-xs hidden-sm">
          <div>
            {{ amount() }}
            <VDropdown theme="info-tooltip">
              <!-- This will be the popover target (for the events and position) -->
              <i class="fa fa-info"></i>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <div class="flex flex-col justify-space-between" style="min-width: 140px;">
                    <p class="header border-bottom p-b-sm">
                      Invoice # {{ result.number }}
                    </p>
                    <div v-for="item in result.invoice_items" class="flex justify-space-between">
                      <div>
                        {{ item.description }} x {{ item.quantity }}
                      </div>
                      <div class="m-l-sm">
                        {{ formatAsCurrency(item.amount) }}
                      </div>
                    </div>
                    <div class="flex justify-space-between m-t-sm p-t-sm border-top">
                      <div class="text-semi">
                        Paid
                      </div>
                      <div>
                        {{ formatAsCurrency(result.amount_paid) }}
                      </div>
                    </div>
                    <div class="flex justify-space-between">
                      <div class="text-semi">
                        Outstanding
                      </div>
                      <div>
                        {{ formatAsCurrency(Math.abs(result.amount_paid - result.amount_due)) }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
        <div class="flex justify-self-end align-items-center hidden-xs hidden-sm">
          <div class="btn-group link-btn align-self-flex-start ">
            <button
                data-toggle="dropdown"
                aria-expanded="false"
                class="btn btn-link icon-only dropdown-toggle p-t-n p-r-n"
            >
              <i aria-hidden="true" class="fa fa-ellipsis-v"></i>
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right pull-right">
              <template v-if="result.url">
                <li><a :href="result.url" target="_blank"><i class="fa fa-external-link"></i>&nbsp;&nbsp; View</a></li>
                <li><a @click.prevent="copyUrl(result.url)"><i class="fa fa-copy"></i>&nbsp;&nbsp; Copy link</a></li>
              </template>
              <template v-if="result.pdf">
                <li><a :href="result.pdf"><i class="fa fa-download"></i>&nbsp;&nbsp; Download PDF</a></li>
              </template>
              <template v-if="receiptUrl()">
                <li><a @click.prevent="downloadFile(receiptUrl(), result.number ? `Invoice #${result.number} - Receipt` : 'Receipt')">
                  <i class="fa fa-download"></i>&nbsp;&nbsp;Download Receipt</a>
                </li>
              </template>
              <template v-if="allowEdits && canBeModified()">
                <li class="divider"></li>
                <li><a @click.prevent="showPaidForm()">Mark paid...</a></li>
                <li><a @click.prevent="showMarkVoidForm()">Void...</a></li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <mark-paid-form v-if="showMarkPaidForm" :manual-invoice="result"></mark-paid-form>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <void-invoice-form v-if="showVoidForm" :invoice="result"></void-invoice-form>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import MarkPaidForm from './mark_paid_form.vue';
import VoidInvoiceForm from './void_invoice_form.vue';
import { formattingMixin } from './mixins/formatting_mix';

export default {
  name: 'one-off-invoice-result',
  mixins: [formattingMixin],
  components: { MarkPaidForm, VoidInvoiceForm },
  props: {
    result: {
      type: Object,
      required: true,
    },
    noHr: {
      type: Boolean,
      default: false,
    },
    allowEdits: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMarkPaidForm: false,
      showVoidForm: false,
    };
  },
  mounted() {
    const vm = this;
    vm.$notificationManager.$on('hide-mark-paid-form', vm.hideListener);
    vm.$notificationManager.$on('invoice-paid', vm.refreshListener);

    vm.$notificationManager.$on('invoice-voided', vm.refreshListener);
    vm.$notificationManager.$on('hide-void-form', vm.hideListener);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('hide-mark-paid-form', vm.hideListener);
    vm.$notificationManager.$off('invoice-paid', vm.refreshListener);

    vm.$notificationManager.$off('invoice-voided', vm.refreshListener);
    vm.$notificationManager.$off('hide-void-form', vm.hideListener);
  },
  computed: {
    status() {
      return _.get(this.result, 'status', '');
    },
  },
  methods: {
    canBeModified() {
      return this.status === 'pending' || this.status === 'overdue';
    },
    receiptUrl() {
      return _.get(this.result, 'receipt.cloudfront_url', '');
    },
    readableStatus() {
      if (this.status === 'paid') {
        return `paid on ${moment(this.result.paid_at).format('M/D/YYYY')}`;
      } if (this.status === 'void') {
        return 'voided';
      }

      return `due on ${moment(this.result.due_on).format('M/D/YYYY')}`;
    },
    hideListener(invoice) {
      const vm = this;
      if (vm.result.id === invoice.id) {
        vm.hidePaidForm();
        vm.hideVoidForm();
      }
    },
    hidePaidForm() {
      this.showMarkPaidForm = false;
    },
    showPaidForm() {
      this.showMarkPaidForm = true;
    },
    refreshListener(invoice) {
      const vm = this;
      if (vm.result.id === invoice.id) {
        vm.hideVoidForm();
        vm.hidePaidForm();
        // todo fixup without refreshing
        window.location.assign('/invoices');
      }
    },
    hideVoidForm() {
      this.showVoidForm = false;
    },
    showMarkVoidForm() {
      this.showVoidForm = true;
    },
    displayName() {
      if (this.result) {
        return _.get(this.result, 'billing_profile.billable.display_name', '');
      }

      return '';
    },
    amount() {
      if (this.status === 'paid') {
        return this.formatAsCurrency(this.result.amount_paid);
      }

      return this.formatAsCurrency(this.result.amount_due);
    },
    badgeHtml() {
      // switch on status
      let dueOn = this.formatWithMoment(this.result.due_on, 'MM/DD/YY')
      switch (this.status) {
        case 'paid':
          return `<div class="badge badge-primary-light p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs"><i class="fa fa-check"></i> Paid on ${this.formatWithMoment(this.result.paid_at, 'MM/DD/YY')}</div>`;
        case 'overdue':
          return `<div class="badge badge-danger p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs"><i class="fa fa-exclamation-triangle"></i> Overdue since ${dueOn}</div>`;
        case 'void':
          return '<div class="badge badge-warning p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs"><i class="fa fa-times"></i> Void</div>';
        case 'pending':
          if (this.result.url) {
            // This has actually been sent
            return `<div class="badge badge-info p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs"><i class="fa fa-clock-o"></i> Outstanding. Due ${dueOn}</div>`;
          } else {
            // This is still being processed by WIQ and has not been sent
            return `<div class="badge p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs"><i class="fa fa-send"></i> Processing</div>`;
          }
        default:
          return `<div class="badge p-t-xxs-only-xs p-b-xxs-only-xs p-t-xs p-b-xs">${_.capitalize(this.status)}</div>`;
      }
    },
    downloadFile(url, label) {
      axios.get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
    },
    copyUrl(url) {
      const el = document.createElement('textarea');
      el.value = url;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$notificationManager.$emit('show-toast', 'Copied', true);
    },
  },
};
</script>
