<template>
    <div role="form">
        <div class="form-group">
            <label>Weight Class</label>
            <input v-model="weightClass" type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>Experience</label>
            <select v-model="experience" class="form-control">
                <option selected disabled>Choose experience level...</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
            </select>
        </div>
        <div class="form-group">
          <label for="dob">
            Date of birth
          </label>
          <the-mask mask="##/##/####"
                    placeholder="mm/dd/yyyy"
                    v-model="dob"
                    class="form-control"
                    id="dob"
                    :masked="true"
                    ref="date"
                    data-cy="dob"
                    type="tel">
          </the-mask>
        </div>
        <div class="button-area">
            <div>
                <div v-show="error">
                    <small>
                        <span class="text-danger">{{ errorMessage }}</span>
                    </small>
                </div>
                <ladda-button @lbClicked="updateWrestler" el-class="btn-primary" :loading="loading">
                    Save
                </ladda-button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { TheMask } from 'vue-the-mask';

export default {
  name: 'scramble-wrestler-edit',
  components: { LaddaButton, TheMask },
  props: {
    wrestlerProfile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error saving wrestler details',

      weightClass: '',
      dob: '',
      experience: '',
    };
  },
  computed: {
    isoDob() {
      if (!this.dob || this.dob.length === 0) {
        return null;
      }

      return moment(this.dob, 'MM/DD/YYYY').toISOString();
    }
  },
  created() {
    const vm = this;
    if (vm.wrestlerProfile) {
      vm.weightClass = vm.wrestlerProfile.weight_class;
      vm.dob = moment(vm.wrestlerProfile.dob).format('MM/DD/YYYY');
      vm.experience = vm.wrestlerProfile.experience;
    }
  },
  methods: {
    validate() {
      const vm = this;
      let errorMessage = 'Please ensure ';
      let error = false;
      if (vm.weightClass == null || vm.weightClass.length === 0) {
        errorMessage += 'weight, ';
        error = true;
      }
      if (vm.dob == null || vm.dob.length === 0) {
        errorMessage += 'date of birth, ';
        error = true;
      }
      if (vm.experience == null || vm.experience.length === 0) {
        errorMessage += 'experience, ';
        error = true;
      }
      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' are entered.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        return false;
      }
      return true;
    },
    updateWrestler: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (vm.validate()) {
        vm.loading = true;
        const params = {
          wrestler_profile: {
            weight_class: vm.weightClass,
            experience: vm.experience,
            dob: vm.isoDob,
          },
        };
        const url = vm.$apiService.wrestlerUrl(vm.wrestlerProfile.id);
        axios.put(url, params)
          .then((response) => {
            vm.reloadWrestler();
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
      }
    }, 500),
    // We reload so that the server can calculate the age of the kid correctly
    reloadWrestler() {
      let vm = this;
      const url = this.$apiService.expandedRostersWrestlerUrl(this.wrestlerProfile.id);
      axios.get(url)
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', `${vm.wrestlerProfile.first_name} ${vm.wrestlerProfile.last_name} updated`, true);
            vm.$notificationManager.$emit('scramble-wrestler-updated', response.data.id, response.data);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error reloading wrestler, please refresh the page.';
            vm.error = true;
          });

    }
  },
};
</script>
