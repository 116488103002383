<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="row">
        <div class="col-xs-6 col-md-8">
          <h1>
            {{ report.result.fundraiser.name }} Accounting Report
          </h1>
          <p class="m-b-none"><small>
            Report generated on {{ processedAt() }}
          </small></p>
        </div>
        <div class="col-xs-6 col-md-4 text-right">
          <div class="ibox-tools">
            <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span
                class="hidden-xs">Download CSV</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Billing partner id</th>
              <th v-bind:style="secondColStyles">Amount</th>
              <th>Item name</th>
              <th>Quantity</th>
              <th>Paid at</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Business name</th>
              <th>Street address</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th v-for="question in extraQuestions">
                {{ question.title }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in rows">
              <td class="first-col"
                  v-bind:style="firstColStyles">
                {{ row.billing_partner_id }}
              </td>
              <td v-bind:style="secondColStyles">
                {{ row.amount }}
              </td>
              <td>
                {{ row.item_name }}
              </td>
              <td>
                {{ row.quantity }}
              </td>
              <td>
                {{ row.paid_at }}
              </td>
              <td>
                {{ row.name }}
              </td>
              <td>
                {{ row.email }}
              </td>
              <td>
                {{ row.phone }}
              </td>
              <td>
                {{ row.business_name }}
              </td>
              <td>
                {{ row.street_address }}
              </td>
              <td>
                {{ row.city }}
              </td>
              <td>
                {{ row.state }}
              </td>
              <td>
                {{ row.zip }}
              </td>
              <td v-for="question in extraQuestions">
                {{ row[question.title] }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';

export default {
  name: 'fundraiser-accounting-report',
  mixins: [downloadMix, formattingMixin],
  props: [
    'report',
  ],
  data() {
    return {
      rows: [],
      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },
      // Stored in metadata
      extraQuestions: [],
    };
  },
  mounted() {
    const vm = this;

    const invoices = _.sortBy(_.get(vm.report, 'result.manual_invoices.objects', null), ['paid_at']).reverse();
    const customerMap = _.keyBy(vm.report.result.customers, 'id');
    const chargeMap = _.keyBy(vm.report.result.charges, 'chargeable_id');
    this.extraQuestions = _.get(vm.report, 'result.fundraiser.metadata.additional_questions', []);
    vm.rows = [];
    // This account is 1 row per invoice item
    invoices.forEach((invoice) => {
      const customer = customerMap[_.get(invoice, 'billing_profile.billable.id', '')];
      const paidAt = invoice.paid_at ? moment(invoice.paid_at)
          .format('M/D/YYYY, hh:mm a') : '';
      const charge = chargeMap[invoice.id];
      const quantityRegex = / [x][0-9]+/g;

      invoice.invoice_items.forEach((item) => {
        const itemName = item.description.replace(quantityRegex, "");

        const row = {
          billing_partner_id:  _.get(charge, 'billing_partner_id', null) || _.get(charge, 'stripe_id', ''),
          amount: vm.formatAsCurrency(item.amount),
          item_name: itemName,
          quantity: item.quantity,
          paid_at: paidAt,
          name: _.get(invoice, 'billing_profile.billable.display_name', ''),
          email: _.get(invoice, 'billing_profile.email', ''),
          phone: _.get(customer, 'phone_number', ''),
          business_name: _.get(customer, 'business_name', ''),
          street_address: _.get(customer, 'address_line_1', ''),
          city: _.get(customer, 'city', ''),
          state: _.get(customer, 'state', ''),
          zip: _.get(customer, 'zip', ''),
        };
        const answers = invoice.metadata?.additional_questions

        if (vm.extraQuestions.length > 0 && answers) {
          // Loop through the questions and pull them in
          vm.extraQuestions.forEach((q) => {
            row[q.title] = _.find(answers, ['title', q.title])?.answer ?? ""
          })
        }
        vm.rows.push(row);
      });
    });

    vm.$nextTick(function () {
      const header = this.$refs.lnameHeader;
      if (header) {
        const rect = header.getBoundingClientRect();

        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at)
          .format('M/D/YYYY, hh:mm a');
    },
    downloadCSV() {
      const vm = this;
      let rows = []
      let headers= [
        'Billing partner id',
        'Amount',
        'Item name',
        'Quantity',
        'Paid at',
        'Name',
        'Email',
        'Phone',
        'Business name',
        'Street address',
        'City',
        'State',
        'Zip'
      ]
      headers = _.concat(headers, _.map(vm.extraQuestions, 'title'))
      rows.push(headers);
      const rowsToArray = _.map(vm.rows, (r) => { return _.values(r) });
      rows = _.concat(rows, rowsToArray);

      const fileName = `${vm.report.result.fundraiser.name} Accounting Report - ${vm.processedAt()}.csv`;
      this.downloadCSVFile(fileName, rows)
    },
  },
};
</script>
