<template>
    <div>
        <template v-if="loading">
            <spinner v-if="loading"></spinner>
        </template>
        <div v-if="!loading" role="form">
            <h1 v-if="profileName" class="m-l-sm m-b-lg">We need a little more info for {{ profileName }}</h1>
            <div v-for="(questions, groupName, index) in groupedQuestions">
                <div :class="classForGroup(index)">
                    <div class="group-name col-md-4">
                        <h2>{{ safeGroupName(groupName) }}</h2>
                    </div>
                    <div class="col-md-8">
                        <div class="registration-q-group m-l-sm m-b-md" v-for="question in questions">
                            <template v-if="question.type === 'RegAddressQuestion'">
                                <reg-address-question
                                        @answer-changed="handleUpdatedAnswer"
                                        :question="question"
                                        :existing-answer="getRegAnswerForQuestion(question, answers)"></reg-address-question>
                            </template>
                            <template v-else-if="question.type === 'RegDateQuestion'">
                                <reg-date-question
                                        @answer-changed="handleUpdatedAnswer"
                                        :question="question"
                                        :existing-answer="getRegAnswerForQuestion(question, answers)"></reg-date-question>
                            </template>
                            <template v-else-if="question.type === 'RegEmailQuestion'">
                                <reg-email-question
                                        @answer-changed="handleUpdatedAnswer"
                                        :question="question"
                                        :existing-answer="getRegAnswerForQuestion(question, answers)"></reg-email-question>
                            </template>
                            <template v-else-if="question.type === 'RegPhoneNumberQuestion'">
                                <reg-phone-number-question
                                        @answer-changed="handleUpdatedAnswer"
                                        :question="question"
                                        :existing-answer="getRegAnswerForQuestion(question, answers)"></reg-phone-number-question>
                            </template>
                            <template v-else-if="question.type === 'RegShortTextQuestion'">
                                <reg-short-text-question
                                        @answer-changed="handleUpdatedAnswer"
                                        :question="question"
                                        :existing-answer="getRegAnswerForQuestion(question, answers)"></reg-short-text-question>
                            </template>
                            <template v-else-if="question.type === 'RegSingleSelectQuestion'">
                                <reg-single-select-question
                                    @answer-changed="handleUpdatedAnswer"
                                    :question="question"
                                    :existing-answer="getRegAnswerForQuestion(question, answers)"></reg-single-select-question>
                            </template>
                            <template v-else-if="question.type === 'RegMultiSelectQuestion'">
                                <reg-multi-select-question
                                        @answer-changed="handleUpdatedAnswer"
                                        :question="question"
                                        :existing-answer="getRegAnswerForQuestion(question, answers)"></reg-multi-select-question>
                            </template>
                            <template v-else-if="question.type === 'RegYesNoQuestion'">
                                <reg-yes-no-question
                                        @answer-changed="handleUpdatedAnswer"
                                        :question="question"
                                        :existing-answer="getRegAnswerForQuestion(question, answers)"></reg-yes-no-question>
                            </template>
                            <template v-else-if="question.type === 'RegPhotoQuestion'">
                                <reg-photo-question
                                        @answer-changed="handleUpdatedAnswer"
                                        :question="question"
                                        :existing-answer="getRegAnswerForQuestion(question, answers)"
                                        :endpoint="endpoint"
                                        :access-key="accessKey"
                                        :cloudfront-base-url="cloudfrontBaseUrl"
                                ></reg-photo-question>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!previewMode" class="button-area text-right" v-show="!loading">
            <ladda-button @lbClicked="save" el-class="btn-primary" :loading="loading">
                {{ buttonCta }}
            </ladda-button>
            <div v-show="error">
                <small>
                    <span class="text-danger">{{ errorMessage }}</span>
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from '../wrestling/vue/spinner.vue';
import RegAddressQuestion from '../wrestling/vue/reg_questions/reg_address_question.vue';
import RegDateQuestion from '../wrestling/vue/reg_questions/reg_date_question.vue';
import RegEmailQuestion from '../wrestling/vue/reg_questions/reg_email_question.vue';
import RegPhoneNumberQuestion from '../wrestling/vue/reg_questions/reg_phone_number_question.vue';
import RegPhotoQuestion from '../wrestling/vue/reg_questions/reg_photo_question.vue';
import RegShortTextQuestion from '../wrestling/vue/reg_questions/reg_short_text_question.vue';
import LaddaButton from './ladda_button.vue';
import RegSingleSelectQuestion from '../wrestling/vue/reg_questions/reg_single_select_question.vue';
import RegMultiSelectQuestion from '../wrestling/vue/reg_questions/reg_multi_select_question.vue';
import RegYesNoQuestion from '../wrestling/vue/reg_questions/reg_yes_no_question.vue';
import { funnelMixin } from '../wrestling/vue/mixins/funnel_mix';

export default {
  name: 'registration-info-form',
  mixins: [funnelMixin],
  components: {
    RegMultiSelectQuestion,
    RegYesNoQuestion,
    RegSingleSelectQuestion,
    RegPhotoQuestion,
    LaddaButton,
    RegShortTextQuestion,
    RegPhoneNumberQuestion,
    RegEmailQuestion,
    RegDateQuestion,
    RegAddressQuestion,
    Spinner,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
    },
    accessKey: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    defaultGroupName: {
      type: String,
      required: false,
    },
    redirect: {
      type: String,
      required: false,
    },
    profileId: {
      type: Number,
      required: true,
    },
    profileType: {
      type: String,
      required: true,
    },
    profileName: {
      type: String,
      required: false,
    },
    checkInId: {
      type: Number,
      required: false,
    },
    buttonCta: {
      type: String,
      default: 'Save',
    },
    specificQuestions: {
      type: Array,
      required: false,
    },
    sessionParam: {
      type: String,
      required: false,
    },
    funnel: {
      type: Boolean,
      default: false,
    },
    respondantId: {
      type: Number,
      default: null,
    },
    respondantType: {
      type: String,
      default: null,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    // Is this for public or private properties
    visibility: {
      type: String,
      default: "public"
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: '',
      // questions is the raw thing coming down from the api
      questions: [],
      // grouped questions are grouped nicely for visual display
      groupedQuestions: null,

      // answers are existing answers, modified slightly by the client to have a 'dirty' flag for saving
      answers: [],

      saving: false,
    };
  },
  created() {
    this.loadAllQuestions();
  },
  watch: {
    checkInId: function(newCheckInId) {
      this.loadAllQuestions();
    },
    specificQuestions: function(newQuestions) {
      this.loadAllQuestions();
    }
  },
  computed: {
    isPublicProperty() {
      // Why not just match to public? Because public is our default, private the special case
      return (this.visibility !== 'private');
    },
  },
  methods: {
    classForGroup(index) {
      if (index === 0) {
        return 'row registration-group p-t-n';
      }

      return 'row registration-group';
    },
    safeGroupName(groupName) {
      if (groupName !== null && groupName !== 'null') {
        return groupName;
      }

      return this.defaultGroupName;
    },
    // This component essentially acts as a store for the child questions.
    handleUpdatedAnswer(question, answer, dirty, valid) {
      const regAnswer = this.getRegAnswerForQuestion(question, this.answers);
      if (!regAnswer) {
        console.log('no answer found');
        return;
      }

      regAnswer.answer = answer;
      // We set dirty according to what the child component tells us
      // But we also force it to be marked as dirty if it does not have an id
      regAnswer.dirty = dirty || !regAnswer.id;
      regAnswer.valid = valid;
    },
    getRegAnswerForQuestion(question, answers) {
      const vm = this;
      // Exact match based on question id
      for (let i = 0; i < answers.length; i++) {
        const answer = answers[i];
        if (answer.registration_question_id === question.id) {
          return answer;
        }
      }

      return null;
    },
    getRelatedAnswerForQuestion(question, answers) {
      // Only do fuzzy matching if the question is a derived question
      if (!question.original_id) {
        return null;
      }
      // Fuzzy match, which will grab a related answer
      for (let i = 0; i < answers.length; i++) {
        const answer = answers[i];
        // This will return answers that were recorded for derived questions of the registration question
        // First check to see if this potential answer is for the 'parent' question that all one time session
        //  questions are derived from
        if (answer.registration_question_id === question.original_id) {
          return answer;
        }
        // If that's not true we do a fuzzy match against this answers original question
        const originalQuestionId = answer.registration_question_original_id;
        if (originalQuestionId && originalQuestionId === question.original_id) {
          return answer;
        }
      }

      return null;
    },
    loadAllQuestions() {
      const vm = this;
      vm.loading = true;

      // Use the questions passed in if we are given specific questions to answer
      if (vm.specificQuestions) {
        vm.groupQuestionsAndAssign(vm.specificQuestions);
        vm.loadAllAnswers();
        return;
      }

      const url = this.isPublicProperty ? vm.$apiService.registrationQuestions(vm.profileType, vm.sessionParam) : vm.$apiService.privateWrestlerProperties();
      vm.$apiService.loadAllPages(url, 'registration_questions')
        .then((reg_questions) => {
          vm.groupQuestionsAndAssign(reg_questions);
          vm.loadAllAnswers();
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving registration questions ${error.toString()}`;
          vm.error = true;
        });
    },
    loadAllAnswers() {
      const vm = this;

      // Don't load answers in preview mode
      if (vm.previewMode) {
        vm.loading = false;
        return;
      }

      const url = this.isPublicProperty ? vm.$apiService.registrationAnswers(vm.profileId, vm.profileType, vm.checkInId, vm.sessionParam) : vm.$apiService.privateWrestlerPropertyAnswers(vm.profileId)
      vm.$apiService.loadAllPages(url, 'registration_answers')
        .then((reg_answers) => {
          vm.createLocalAnswers(reg_answers);
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving registration answers ${error.toString()}`;
          vm.error = true;
        });
    },
    // The front end expects certain object values so we set them when they come back from the server
    // keepId is for fuzzy matched answers. They are technically new answers, since they don't belong to the question
    setupAnswerForFrontEnd(answer, question, keepId) {
      answer.dirty = false;
      answer.valid = true; // answer answers are valid by default
      answer.profile_id = this.profileId;
      answer.profile_type = this.profileType;
      answer.check_in_id = this.checkInId;
      answer.registration_question_id = question.id;
      if (!keepId) {
        // In this case we have a fuzzy match, which is just intelligent pre-filling.
        // This should be treated as a local answer that happens to some pre-filled intelligence
        // so we post it to the server
        answer.id = null;
        // Dirty is the flag we need to set so it actually gets sent to the server as a 'new'
        // registration answer
        answer.dirty = true;
      }
      return answer;
    },
    // this function ensures that reg_answers has an answer for each question
    createLocalAnswers(reg_answers) {
      const vm = this;
      // Make sure we reset at the beginning, in case this form is being rendered one after another (see kiosk)
      vm.answers = [];
      // Ensure each question has a local copy of an answer to work upon
      vm.questions.forEach((question) => {
        const existing = vm.getRegAnswerForQuestion(question, reg_answers);
        if (existing) {
          // If we have an answer, set up the front end only linkage we need for the object and add it
          vm.answers.push(vm.setupAnswerForFrontEnd(existing, question, true));
          return;
        }

        const fuzzyMatch = vm.getRelatedAnswerForQuestion(question, reg_answers);
        if (fuzzyMatch) {
          // If we have an answer, set up the front end only linkage we need for the object and add it
          // make sure to discard the id
          vm.answers.push(vm.setupAnswerForFrontEnd(fuzzyMatch, question, false));
          return;
        }

        // If get here we have nothing, new up a blank answer so we can render correctly
        const localAnswer = {
          id: null,
          registration_question_id: question.id,
          respondant_id: vm.respondantId,
          respondant_type: vm.respondantType,
          answer: '',
          valid: false,
          dirty: false,
          profile_id: vm.profileId,
          profile_type: vm.profileType,
          check_in_id: vm.checkInId,
        };
        vm.answers.push(localAnswer);
      });
    },
    groupQuestionsAndAssign(reg_questions) {
      const vm = this;
      vm.questions = reg_questions;
      const groups_categories = _.map(reg_questions, 'group_with');

      vm.groupedQuestions = { };

      for (const key of groups_categories) {
        // each category should be able to hold an array
        vm.groupedQuestions[key] = [];
      }

      for (const question of vm.questions) {
        vm.groupedQuestions[question.group_with].push(question);
      }
    },
    getChangedAnswers() {
      const vm = this;
      const changed = [];

      vm.answers.forEach((answer) => {
        if (answer.dirty) {
          changed.push(answer);
        }
      });
      return changed;
    },
    ensureValid(answers) {
      if (!answers || answers.length === 0) {
        return false;
      }

      let valid = true;
      // todo clean this up with lodash map
      answers.forEach((ans) => {
        if (!ans.valid && ans.dirty) {
          valid = false;
        }
      });

      return valid;
    },
    ensureRequiredEntered() {
      const vm = this;

      let requiredEntered = true;

      vm.questions.forEach((question) => {
        if (question.required) {
          const answer = vm.getRegAnswerForQuestion(question, vm.answers);
          if (!answer.valid) {
            requiredEntered = false;
          }
        }
      });
      return requiredEntered;
    },
    batchParams(answers) {
      // todo
      return {
        registration_answers: answers,
      };
    },
    save: _.throttle(function () {
      const vm = this;
      if (vm.saving || vm.previewMode) {
        return;
      }

      // first ensure everything is valid
      const changedAnswers = vm.getChangedAnswers();
      let allChangedValid = true;
      if (changedAnswers.length > 0) {
        allChangedValid = vm.ensureValid(changedAnswers);
      }

      const requiredEntered = vm.ensureRequiredEntered();
      if (allChangedValid && requiredEntered) {
        vm.error = false;
      } else {
        vm.errorMessage = 'You missed some required info, please make sure all info is filled out.';
        vm.error = true;
        vm.$notificationManager.$emit('show-reg-errors');
        return;
      }

      // then post
      vm.saving = true;

      const params = vm.batchParams(changedAnswers);

      const url = vm.$apiService.registrationAnswersUrl();
      axios.post(url, params)
        .then((response) => {
          vm.$notificationManager.$emit('show-toast', 'Saved info', true);
          vm.$notificationManager.$emit('registration-info-saved', vm.profileId, vm.profileType);
          if (vm.funnel) {
            vm.goToNextFunnelStep();
          } else if (vm.redirect) {
            vm.saving = false;
            window.location.assign(vm.redirect);
          } else {
            vm.saving = false;
          }
        })
        .catch((error) => {
          vm.saving = false;
          vm.errorMessage = `Error saving info: ${error.toString()}`;
          vm.error = true;
        });
    }, 500),
  },
};
</script>
