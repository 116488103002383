<template>
  <div class="search-result">
    <div v-if="!noHr" class="hr-line-dashed"></div>
    <div class="flex flex-wrap justify-space-between gap-y-sm">
      <div>
        <h3>
          <span class="text-default">{{ invoicePayment.billing_subscription.billing_profile.email }}</span>
        </h3>
        <p class="m-b-none">
          {{ paidAt() }}
        </p>
        <p class="m-t-none m-b-none" v-if="showStart">
          {{ recurringInfo() }}
        </p>
      </div>
      <div>
        <div class="search-type-label text-right t-l-md">
          <p class="donation-amount m-b-none m-t-none" v-if="paid">
            {{ amount() }}
            <span class="m-l-xs" v-if="invoicePayment.receipt">
              <a :href="invoicePayment.receipt.cloudfront_url" target="_blank"><i class="fa fa-file-text-o"></i></a>
            </span>
          </p>
        </div>
      </div>
      <div class="flex flex-col" v-if="refunds.length > 0">
        <div v-if="fullyRefunded"><span class="label label-warning">Fully Refunded</span></div>
        <div v-else-if="partiallyRefunded"><span class="label label-warning">Partially Refunded</span></div>
        <div class="flex flex-col" style="margin-top: 2px;">
          <div v-for="refund in refunds" class="font-small">
            <template v-if="refund.receipt !== null">
              <a :href="refund.receipt.cloudfront_url" target="_blank">{{ formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a') }} <span v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span> <i class="fa fa-file-text-o"></i></a>
            </template>
            <template v-else>
              {{ formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a') }} <span v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span>
            </template>
          </div>
        </div>
      </div>
      <div v-if="paidWithCard && !fullyRefunded && showRefundActions">
        <button @click="showRefundForm = true" class="btn no-underline btn-compact bg-gray-50 text-gray-700 flex justify-space-between gap-x-xxs">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z" />
          </svg>
          <span class="text-12">
              Refund...
            </span>
        </button>
      </div>
    </div>
    <div class="m-t-sm" v-if="showRefundForm">
      <refund-form :possible-refund-amount="possibleRefundAmount" :charge="succeededCharge"></refund-form>
    </div>
  </div>
</template>
<script>
import { formattingMixin } from './mixins/formatting_mix';
import { billingPlanMix } from './mixins/billing_plan_mix';
import RefundForm from './refund_form.vue'

export default {
  name: 'invoice-payment',
  mixins: [formattingMixin, billingPlanMix],
  components: { RefundForm },
  props: {
    invoicePayment: {
      type: Object,
      required: true,
    },
    showStart: {
      type: Boolean,
    },
    noHr: {
      type: Boolean,
      default: false,
    },
    showRefundActions: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showRefundForm: false,
    }
  },
  computed: {
    paid() {
      return this.invoicePayment.status === 'paid';
    },
    paidWithCard() {
      return this.paid && this.succeededCharge?.payment_method === 'card';
    },
    // TODO DRY this up with manual invoice
    possibleRefundAmount() {
      return this.invoicePayment.amount_paid - this.alreadyRefundedAmount;
    },
    alreadyRefundedAmount() {
      return _.sumBy(this.invoicePayment.charges, function (charge) {
        return _.sumBy(charge.refunds, 'amount');
      });
    },
    fullyRefunded() {
      return this.invoicePayment.amount_paid > 0 && this.possibleRefundAmount === 0;
    },
    partiallyRefunded() {
      return this.invoicePayment.amount_paid > 0 && this.alreadyRefundedAmount > 0 && !this.fullyRefunded;
    },
    refunds() {
      return _.flatMap(this.invoicePayment.charges, function (charge) {
        return charge.refunds;
      });
    },
    succeededCharge() {
      return _.head(_.filter(this.invoicePayment.charges, { status: 'successful' }));
    }
  },
  mounted() {
    this.$on('cancel-refund', this.hideRefundForm);
  },
  destroyed() {
    this.$off('cancel-refund', this.hideRefundForm);
  },
  methods: {
    hideRefundForm(charge) {
      if (charge.id === this.succeededCharge?.id) {
        this.showRefundForm = false;
      }
    },
    paidAt() {
      if (this.paid) {
        return `Paid on ${moment(this.invoicePayment.created_at).format('M/D/YYYY')}`;
      } else {
        return _.capitalize(this.invoicePayment.status);
      }
    },
    recurringInfo() {
      const plans = _.get(this, 'invoicePayment.billing_subscription.billing_plans', null);
      const noun = this.isDonation(plans) ? 'Recurring donor' : 'Member';
      return `${noun} since ${moment(this.invoicePayment.billing_subscription.created_at).format('M/D/YYYY')}`;
    },
    amount() {
      return this.formatAsCurrency(this.invoicePayment.amount_paid);
    },
  },
};
</script>
