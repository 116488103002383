<template>
    <div id="event-notify" role="form">
        <p>
            Would you like to notify the rest of the coaching staff that this practice plan has changed?
        </p>
        <div class="button-area text-right">
            <div v-show="error">
                <small>
                    <span class="text-danger">{{ errorMessage }}</span>
                </small>
            </div>
            <button type="button" v-show="!loading" @click="hideForm()" class="btn btn-white m-r-md">No, thanks</button>
            <ladda-button @lbClicked="pingServer" el-class="btn-primary" :loading="loading">
                Yes, notify coaches
            </ladda-button>
        </div>
    </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'plan-saved-notify',
  components: { LaddaButton },
  props: [
    'planId',
  ],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  methods: {
    hideForm() {
      const vm = this;
      vm.$notificationManager.$emit('plan-notify-close', vm.planId);
    },
    pingServer() {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.loading = true;

      const url = vm.$apiService.practicePlanNotifyUrl(vm.planId);
      axios.post(url, {})
        .then((response) => {
          vm.$notificationManager.$emit('show-toast', 'Coaches notified', true);
          vm.hideForm();
        })
        .catch((error) => {
          vm.loading = false;
          vm.error = true;
          vm.errorMessage = 'Error triggering notifications';
        });
    },
  },
};
</script>
