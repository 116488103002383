<template>
    <li :style="getBorderStyles()">
        <template v-if="showEditForm()">
            <div role="form">
                <div class="form-group">
                    <label>Name</label>
                    <input v-model="editingName" type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label>Minutes</label>
                    <input v-model.number="editingMinutes" type="number" class="form-control">
                </div>
                <div class="form-group">
                    <label>Details (optional)</label>
                    <textarea v-model="editingDetails" type="text" class="form-control" rows="5"></textarea>
                </div>
                <div class="button-area">
                    <div v-show="error">
                        <small>
                            <span class="text-danger">{{ errorMessage }}</span>
                        </small>
                    </div>
                    <button name="button" v-on:click="updateSection" class="btn btn-primary m-l-md">Done</button>
                    <button type="button" v-on:click="cancelSection" class="btn btn-white">Cancel</button>
                </div>
                <div class="clearfix"></div>
            </div>
        </template>
        <template v-else>
            <div class="timeline-dragger">
                <i class="fa fa-grip-large section-sorter" v-if="!readOnly"></i>
            </div>
            <div class="title">
                {{ section.name }}
            </div>
            <div class="details">
                <span v-if="section.details">
                    <div v-for="line in section.details.split('\n')" v-html="urlify(line, false)"></div>
                </span>
            </div>
            <div class="agile-detail">
                <i class="fa fa-clock-o"></i> {{ section.minutes }} minutes
            </div>
            <div class="actions-area" v-if="!readOnly">
                <a v-on:click="startEditing()">Edit</a>
                <a class="text-danger m-l-sm" v-on:click="removeSection()">Remove</a>
            </div>
        </template>
    </li>
</template>

<script>
import { urlHelperMixin } from './mixins/url_helper_mix';

export default {
  name: 'practice-plan-section',
  props: [
    'blockId',
    'section',
    'borderColor',
    'readOnly',
    'blockIndex',
    'sectionIndex'
  ],
  watch: {
    // whenever search changes, this function will run
    section(newSection) {
      this.updateEditingValues();
    },
  },
  mixins: [
    urlHelperMixin
  ],
  data() {
    return {
      editing: false,
      editingName: '',
      editingDetails: '',
      editingMinutes: '',
      error: false,
      errorMessage: '',
    };
  },
  created() {
    const vm = this;
    vm.updateEditingValues();
  },
  methods: {
    updateEditingValues() {
      const vm = this;
      if (vm.section.name === null || vm.section.minutes === null) {
        vm.editing = true;
      }
      vm.editingName = vm.section.name;
      vm.editingDetails = vm.section.details;
      vm.editingMinutes = vm.section.minutes;
    },
    getBorderStyles() {
      return `border-left: 3px solid ${this.borderColor}`;
    },
    showEditForm() {
      return this.editing;
    },
    editingFieldsValid() {
      const vm = this;
      return vm.editingName && vm.editingMinutes;
    },
    fieldsValid() {
      const vm = this;
      return vm.section.name && vm.section.minutes;
    },
    updateSection() {
      const vm = this;
      vm.error = false;

      if (vm.editingFieldsValid()) {
        vm.$set(vm.section, 'name', vm.editingName);
        vm.$set(vm.section, 'details', vm.editingDetails);
        vm.$set(vm.section, 'minutes', vm.editingMinutes);
        vm.editing = false;
        vm.$notificationManager.$emit('plan-section-modified', vm.section, vm.blockIndex);
      } else {
        vm.error = true;
        vm.errorMessage = 'Please enter name and minutes';
      }
    },
    cancelSection() {
      const vm = this;
      // If this can cancel into a previously good state, go back there
      if (vm.fieldsValid()) {
        vm.editing = false;
      } else {
        // else signal that it needs to be deleted
        vm.removeSection();
      }
    },
    startEditing() {
      this.editing = true;
    },
    removeSection() {
      const vm = this;
      vm.$notificationManager.$emit('remove-section', vm.section, vm.sectionIndex, vm.blockIndex);
    },
  },
};
</script>
