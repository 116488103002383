<template>
  <div id="event-notify" role="form">
    <div class="form-group">
      <label>
        Would you like to notify the team that {{ eventName() }} has changed? If so, who would you like to notify?
      </label>
      <div v-if="rosters.length > 0">
        <label>
          <input type="radio" value="rosters" id="rosters" v-model="rosterFilter"> Linked rosters: {{ rosterNames }}
        </label>
      </div>
      <div>
        <label>
          <input type="radio" value="all" id="all" v-model="rosterFilter"> Entire team
        </label>
      </div>
    </div>
    <div class="button-area text-right">
      <div v-show="error">
        <small>
          <span class="text-danger">{{ errorMessage }}</span>
        </small>
      </div>
      <button type="button" v-show="!loading" @click="hideForm()" class="btn btn-white m-r-md">No, don't notify</button>
      <ladda-button @lbClicked="pingServer" el-class="btn-primary" :loading="loading">
        Send notifications
      </ladda-button>
    </div>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'event-change-notify',
  components: { LaddaButton },
  props: {
    event: {
      type: Object,
      required: true
    },
    recurringChange: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      rosterFilter: 'all',
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  created() {
    if (this.rosters.length > 0) {
      this.rosterFilter = 'rosters';
    }
  },
  computed: {
    rosters() {
      return _.map(this.event.roster_events, 'roster');
    },
    rosterNames() {
      return _.join(_.map(this.rosters, 'name'), ', ');
    },
    rosterIdsToNotify() {
      if (this.rosterFilter === 'rosters') {
        return _.map(this.rosters, 'id')
      }

      return null;
    },
  },
  methods: {
    eventName() {
      const { event } = this;
      return event.title || event.name;
    },
    hideForm() {
      const vm = this;
      vm.$notificationManager.$emit('event-notify-close', vm.event);
    },
    pingServer() {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.loading = true;

      const url = vm.$apiService.eventNotifyUrl(vm.event.id);
      const params = {
        recurring: vm.recurringChange,
        roster_ids: vm.rosterIdsToNotify,
      };
      axios.post(url, params)
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Sending notifications', true);
            vm.loading = false;
            vm.hideForm();
          })
          .catch((error) => {
            vm.loading = false;
            vm.error = true;
            vm.errorMessage = 'Error triggering team notification';
          });
    },
  },
};
</script>
