<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="row">
        <div class="col-xs-6 col-md-8">
          <h1>
            {{ report.name }}
          </h1>
          <p class="m-b-none"><small>
            Report generated on {{ processedAt() }}
          </small></p>
        </div>
        <div class="col-xs-6 col-md-4 text-right">
          <div class="ibox-tools">
            <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span
                class="hidden-xs">Download CSV</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th v-for="header in rows[0]">
                {{ header }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, rowIndex) in rows">
              <!-- Skip the header -->
              <template v-if="rowIndex !== 0">
                <td v-for="(item, index) in row">
                  <template v-if="index === 2">
                    <span v-html="statusIcon(item)"></span>
                    <span>{{ item }}</span>
                  </template>
                  <template v-else>
                    <a :href="item" class="text-underline" target="_blank" v-if="index === 4">{{ item }} <i class="fa fa-external-link"></i></a>
                    <span v-else>{{ item }}</span>
                  </template>
                </td>
                <!-- this report also has an 'action column' -->
                <td class="flex align-items-center">
                  <resend-invite :invite-token="tokenFor(row)" v-if="row[3]"></resend-invite>
                </td>
              </template>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';
import ResendInvite from '../resend_invite.vue';

// This row report is for server side generated reports. No fancy logic, it just throws down rows as given.
export default {
  name: 'invite-status-report',
  components: { ResendInvite },
  mixins: [
      formattingMixin, downloadMix
  ],
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rows: [],
    };
  },
  mounted() {
    this.rows = this.report.result.rows.objects;
  },
  methods: {
    statusIcon(inviteStatus) {
      if (inviteStatus === "Not invited") {
        return '<i class="fa fa-times text-gray-300"></i>';
      } else if (inviteStatus === "Invited") {
        return '<i class="fa fa-exclamation-triangle text-yellow-400"></i>';
      } else if (inviteStatus === "Accepted") {
        return '<i class="fa fa-check text-green-400"></i>';
      }

      return ""
    },
    tokenFor(row) {
      let inviteUrl = row[3];
      if (inviteUrl) {
        const params = new URL(inviteUrl).searchParams;
        return params.get('invite_token')
      }

      return null;
    },
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at)
          .format('M/D/YYYY, hh:mm a');
    },
    downloadCSV() {
      const fileName = `${this.report.name} - ${this.processedAt()}.csv`;
      this.downloadCSVFile(fileName, this.rows);
    },
  },
};
</script>
