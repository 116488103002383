<template>
  <div class="search-result">
    <div v-if="!noHr" class='hr-line-dashed'></div>
    <div class="row">
      <div class="col-xs-12">
        <div class="flex gap-x-m gap-y-sm" :class="flexLayout">
          <div v-if="avatarExists">
            <a :href="wrestlerHref()">
              <div class="image">
                <img :src="wrestler.avatar" class="profile-pic" alt="profile picture"/>
              </div>
            </a>
          </div>
          <div class="wrestler-info">
            <h3 class="font-heavy">
              <a :href="wrestlerHref()" :target="showExternalLink ? '_blank' : ''">
                {{ wrestler.full_name }} <i v-if="showExternalLink" class="fa fa-external-link"></i>
              </a>
            </h3>
            <wrestler-rosters :tags-only="true" :for-wrestler-profile="wrestler" :can-edit="allowEdit"></wrestler-rosters>
            <p v-if="wrestler.weight_class">
              {{ wrestler.weight_class }}
            </p>
            <p v-if="wrestler.academic_class">
              {{ wrestler.academic_class }}
            </p>
            <p v-if="wrestler.age">
              {{ wrestler.age }} years old
            </p>
            <p v-if="wrestler.experience">
              {{ wrestler.experience }}
            </p>
            <p v-if="showEmail && inviteAlreadyExists()">
              {{ email() }}
            </p>
          </div>
          <div v-if="showInvite" class="m-l-auto flex flex-col-xs-only">
            <a :href="editUrl()">
              Edit
            </a>
            <div v-if="!inviteAlreadyExists()" class="xs-m-l-none m-l-sm">
              <invite :wrestler-id="wrestler.id"></invite>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Invite from "./invite.vue";
import Tag from "../../shared/tag.vue";
import { taggableMix } from '../../shared/taggable_mix';
import WrestlerRosters from './wrestler_rosters.vue';

export default {
  components: {Invite, Tag, WrestlerRosters},
  name: 'roster-card',
  mixins: [taggableMix],
  props: {
    noHr: {
      type: Boolean,
      default: false,
    },
    wrestler: {
      type: Object,
      required: true,
    },
    showInvite: {
      type: Boolean,
      default: false,
    },
    showEmail: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    showExternalLink: {
      type: Boolean,
      default: false,
    },
    flexLayout: {
      type: String,
      default: 'flex-row',
    }
  },
  data: function() {
    return {}
  },
  mounted: function() {
    let vm = this;
    vm.$on('invite-success', vm.inviteSuccess);
  },
  destroyed: function() {
    let vm = this;
    vm.$off('invite-success', vm.inviteSuccess);
  },
  computed: {
    avatarExists: function() {
      return this.wrestler?.avatar;
    },
  },
  methods: {
    wrestlerHref() {
      let vm = this;
      if (!vm.wrestler) {
        return '';
      }

      return `/wrestlers/${vm.wrestler.id}`;
    },
    inviteAlreadyExists: function() {
      let vm = this;
      if (!vm.wrestler) { return false; }

      let status = vm.wrestler.user_status;
      return (status === "invited" || status === "active");
    },
    email() {
      let vm = this;
      if (!vm.wrestler) { return false; }

      if (vm.wrestler.user_status === "invited") {
        return `${vm.wrestler.invite_email} (invited)`;
      }

      return vm.wrestler.email;
    },
    editUrl() {
      return '/wrestlers/' + this.wrestler.id + '/edit';
    },
    inviteSuccess(email) {
      let vm = this;
      vm.wrestler.user_status = "invited";
      vm.wrestler.invite_email = email;
    },
  }
}
</script>
