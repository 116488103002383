<template>
    <div class="search-result">
        <div class='hr-line-dashed'></div>
        <div class="row">
            <div class="col-xs-6">
                <h3>
                    {{ result.code }}
                  <span v-if="result.active" class="badge badge-primary">
                    Active
                  </span>
                  <span v-else class="badge badge-default">Disabled</span>
                </h3>
                <p class='search-type-label'>
                    <template v-if="result.discount_strategy === 'amount_off'">
                        {{ formatAsCurrency(result.amount_off) }} Off<br/>
                    </template>
                    <template v-if="result.discount_strategy === 'flat_rate'">
                      {{ formatAsCurrency(result.flat_rate) }} Flat Rate<br/>
                    </template>
                    <template v-if="result.discount_strategy === 'percent_off'">
                        {{ result.percent_off * 100 }}% off<br/>
                    </template>
                    {{ result.description }}
                </p>
                <div v-if="result.archived" class="m-t-xs m-b-sm">
                  <span class="label">Archived</span>
                </div>
            </div>
            <div class="col-xs-6 flex justify-end">
              <div class="text-right">
                <a :href="editUrl()">
                  Edit
                </a>
                <div class="m-t-xs text-left">
                  <archive-coupon :coupon-code="result.code" :coupon-id="result.id"></archive-coupon>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formattingMixin } from './mixins/formatting_mix';
import ArchiveCoupon from './archive_coupon.vue';

export default {
  name: 'coupon-result',
  components: { ArchiveCoupon },
  mixins: [formattingMixin],
  props: [
    'result',
  ],
  methods: {
    editUrl() {
      return `/scholarship_codes/${this.result.id}/edit`;
    },
  },
};
</script>
