// Photo upload plugins need this stuff to be able to upload to s3 and load existing photos

export const photoUploadMix = {
  props: {
    endpoint: {
      type: String,
      required: false,
    },
    accessKey: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    existingAttachments: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      photoAttachments: [],
    };
  },
  created() {
    // loop through existingAttachments prop and set the photoAttachmentParams
    this.existingAttachments.forEach((attachment) => {
      this.photoAttachments.push({
        id: attachment.id,
        key: attachment.key,
        name: attachment.name,
        url: attachment.url,
        status: 'uploaded',
        _destroy: false,
      });
    });
  },
  computed: {
    attachmentParams() {
      // Remove all the front end stuff we use to manage the photos
      return _.flatMap(this.photoAttachments, (attachment) => {
        return {
          id: attachment.id,
          _destroy: attachment._destroy,
        };
      });
    },
    hasOneAttachmentParams() {
      let attachment = this.photoAttachments.find((attachment) => {
        return attachment.status === 'uploaded' && !attachment._destroy;
      });

      if (!attachment) {
        attachment = this.photoAttachments[0];
      }

      if (!attachment) {
        return {};
      }

      return {
         id: attachment.id,
          _destroy: attachment._destroy,
      }
    },
    hasUploadedPhoto() {
      return this.photoAttachments.some((attachment) => {
        return attachment.status === 'uploaded' && !attachment._destroy;
      });
    }
  },
  methods: {
    photosChanged(photos) {
      // Alright so at this point photos have attachment_ids if the exist and destroy is updating appropriately (I think).
      // so we need to update our local copy of attachments to reflect this.
      // We need to add new attachments, update existing attachments, particularly the _destroy flag
      photos.forEach((photo) => {
        const existingAttachments = this.photoAttachments.filter((attachment) => {
          return attachment.id === photo.id || attachment.key === photo.s3_file_name;
        });
        if (existingAttachments.length > 0) {
          existingAttachments.forEach((existingAttachment) => {
            existingAttachment._destroy = photo._destroy;
          });
        } else {
          this.photoAttachments.push({
            id: photo.id,
            key: photo.s3_file_name,
            name: photo.file_name,
            url: photo.url,
            status: photo.status,
            _destroy: photo._destroy,
          });
        }
      });
    },
  }
};
