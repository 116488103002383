<template>
  <div class="search-result">
    <div v-if="!noHr" class='hr-line-dashed'></div>
    <div class="flex justify-space-between">
      <div>
        <h3>
          <span class="text-default">
              <template v-if="invoice.receipt !== null">
                  <a :href="invoice.receipt.cloudfront_url" target="_blank">{{ title() }}</a>
              </template>
              <template v-else>
                  {{ title() }}
              </template>
          </span>
        </h3>
        <p :class="subheadClass()">
          {{ subhead() }}
        </p>
      </div>
      <div class="flex flex-col" v-if="refunds.length > 0">
        <div v-if="fullyRefunded"><span class="label label-warning">Fully Refunded</span></div>
        <div v-else-if="partiallyRefunded"><span class="label label-warning">Partially Refunded</span></div>
        <div class="flex flex-col" style="margin-top: 2px;">
          <div v-for="refund in refunds" class="font-small">
            <template v-if="refund.receipt !== null">
              <a :href="refund.receipt.cloudfront_url" target="_blank">{{ formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a') }} <span v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span> <i class="fa fa-file-text-o"></i></a>
            </template>
            <template v-else>
              {{ formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a') }} <span v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span>
            </template>
          </div>
        </div>
      </div>
      <div class="" v-if="!this.paid && this.allowMarkPaidForm">
        <button @click="showPaidForm" class="btn no-underline btn-compact bg-gray-50 text-gray-700 flex justify-space-between gap-x-xxs">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
          </svg>
          <span class="text-12">
              Record payment...
            </span>
        </button>
      </div>
      <div v-if="this.paidWithCard && !this.fullyRefunded && this.showRefundActions">
        <button @click="showRefundForm = true" class="btn no-underline btn-compact bg-gray-50 text-gray-700 flex justify-space-between gap-x-xxs">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z" />
          </svg>
          <span class="text-12">
              Refund...
            </span>
        </button>
      </div>
    </div>
    <div>
      <mark-paid-form v-show="showMarkPaidForm" :manual-invoice="invoice"></mark-paid-form>
    </div>
    <div v-if="showRefundForm">
      <refund-form :possible-refund-amount="possibleRefundAmount" :charge="succeededCharge"></refund-form>
    </div>
  </div>
</template>
<script>
import MarkPaidForm from './mark_paid_form.vue';
import RefundForm from './refund_form.vue'
import { manualInvoiceRefundMixin } from './mixins/manual_invoice_refund_mix';
import { formattingMixin } from './mixins/formatting_mix';

export default {
  name: 'manual-invoice',
  mixins: [formattingMixin, manualInvoiceRefundMixin],
  components: { MarkPaidForm, RefundForm },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    allowMarkPaidForm: {
      type: Boolean,
      default: false
    },
    noHr: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pending: false,
      showMarkPaidForm: false,
    };
  },
  mounted() {
    const vm = this;
    if (vm.invoice.status === 'pending') {
      vm.pending = true;
    }

    vm.$notificationManager.$on('hide-mark-paid-form', vm.hideListener);
    vm.$notificationManager.$on('invoice-paid', vm.hideListener);

  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('hide-mark-paid-form', vm.hideListener);
    vm.$notificationManager.$off('invoice-paid', vm.hideListener);
  },
  methods: {

    hideListener(invoice, type) {
      const vm = this;
      if (vm.invoice.id === invoice.id) {
        vm.hidePaidForm();
      }
    },
    hidePaidForm() {
      this.showMarkPaidForm = false;
    },
    showPaidForm() {
      this.showMarkPaidForm = true;
    },
    title() {
      if (this.paid) {
        return `${this.formatAsCurrency(this.invoice.amount_paid)} (paid)`;
      }
      if (this.pending) {
        return `${this.formatAsCurrency(this.invoice.amount_due)} (future installment)`;
      }

      return `${this.formatAsCurrency(this.invoice.amount_due)} (overdue)`;
    },
    subheadClass() {
      if (this.paid) {
        return 'search-type-label';
      }
      if (this.pending) {
        return 'search-type-label text-warning';
      }

      return 'search-type-label text-danger';
    },
    subhead() {
      return moment(this.invoice.due_on)
          .format('M/D/YYYY');
    },
  },
};
</script>
