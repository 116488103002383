<template>
  <div>
    <spinner v-if="loading"></spinner>
    <p v-if="error" class="text-sm text-danger">
      {{ errorMessage }}
    </p>
    <div v-else v-for="(chart, index) in data.charts">
      <div class="relative">
        <highchart-wrapper :use-exporting="false" :payload="chart.payload" :is-currency="isCurrency(chart)" :container-id="`chart-${index}-${chartKey}`"></highchart-wrapper>
        <div v-if="toolTipFor(chart)" class="absolute" style="top: 16px; right: 12px;">
          <VDropdown theme="info-tooltip">
            <!-- This will be the popover target (for the events and position) -->
            <i class="fa fa-question m-l-xs text-blue-800"></i>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>
                <p class="m-n p-n" v-text="toolTipFor(chart)"></p>
              </div>
            </template>
          </VDropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HighchartWrapper from './reports/highchart_wrapper.vue';
import { errorableMix } from './mixins/errorable_mix';
import Spinner from './spinner.vue';

export default {
  name: "metrics-chart",
  props: {
    endpoint: {
      type: String,
      required: true
    },
    chartKey: {
      type: String,
      required: true
    }
  },
  mixins: [errorableMix],
  components: { HighchartWrapper, Spinner },
  data() {
    return {
      data: {
        metrics: [],
        charts: []
      },
      loading: true
    }
  },
  mounted() {
    this.loading = true;
    axios.get(this.endpoint)
        .then((response) => {
          this.data = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = 'Error retrieving metrics';
          this.error = true;
        });
  },
  methods: {
    isCurrency(chart) {
      return chart.metadata["is_currency"] === true;
    },
    toolTipFor(chart) {
      if (chart.metadata?.tooltip) {
        return chart.metadata.tooltip;
      }

      return null;
    }
  }
};
</script>
<style scoped>
</style>
