<template>
  <div role="form">
    <div class="form-group">
      <label>First name</label>
      <input v-model="firstName" type="text" class="form-control">
    </div>
    <div class="form-group">
      <label>Last name</label>
      <input v-model="lastName" type="text" class="form-control">
    </div>
    <div class="button-area text-right">
      <ladda-button @lbClicked="updateParent" el-class="btn-primary" :loading="loading">
        Update
      </ladda-button>
      <div v-show="error">
        <small>
          <span class="text-danger">{{ errorMessage }}</span>
        </small>
      </div>
    </div>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { errorableMix } from './mixins/errorable_mix';
export default {
  name: 'parent-edit',
  components: { LaddaButton },
  mixins: [errorableMix],
  props: {
    existingFirstName: {
      type: String,
      required: true
    },
    existingLastName: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      firstName: '',
      lastName: '',
    }
  },
  created() {
    this.firstName = this.existingFirstName;
    this.lastName = this.existingLastName;
  },
  methods: {
    updateParent() {
      let vm = this;
      vm.clearError();

      if (this.firstName.length === 0) {
        this.showError("First name can't be blank");
        return;
      }

      if (this.lastName.length === 0) {
        this.showError("Last name can't be blank");
        return;
      }

      if (vm.loading === true) {
        return;
      }

      const params = {
        parent_profile: {
          first_name: vm.firstName,
          last_name: vm.lastName
        }
      }

      const url = vm.$apiService.parentUrl(vm.parentId);
      axios.put(url, params)
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', `Info updated`, true);
            window.location.assign(`/parents/${vm.parentId}`);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support@wrestlingiq.com';
            vm.error = true;
          });
    }
  }
};
</script>
<style scoped>
</style>
