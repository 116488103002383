<!-- tODO this should be a filtered list, not this generic thing.-->
<template>
  <div>
    <div class="grid grid-cols-3 sm-grid-cols-1 gap-x-sm m-t-md m-b-m">
      <div :class="filterClass('all')" @click="status = 'all'">
        All
      </div>
      <div :class="filterClass('present')" @click="status = 'present'">
        Present
      </div>
      <div :class="filterClass('absent')" @click="status = 'absent'">
        Absent
      </div>
    </div>
    <paged-list response-key="check_ins" object-type="CheckIn" help-text="" :base-url="baseUrl">
      <template v-slot:list="slotProps">
        <div class="flex flex-col border border-b p-y-sm border-solid border-gray-50">
          <div class="flex gap-x-sm align-items-baseline">
            <i class="fa" :class="iconClass(slotProps.result.status)"></i>
            <div>
              <p class="font-normal text-16 m-t-none m-b-none">
                <template v-if="slotProps.result.event_id">
                  <a :href="`/events/${slotProps.result.event_id}`">{{ slotProps.result.event_name }}</a>
                </template>
                <template v-else>
                  Generic Check In
                </template>
              </p>
              <p class="search-type-label m-b-none">Recorded at {{ formatWithMoment(slotProps.result.created_at, 'llll') }}</p>
              <p class="m-b-none m-t-xxs" v-if="slotProps.result.class_pass_id">
                <span class="label">Class Pass Used - {{ slotProps.result.class_pass.paid_session.name }}</span>
              </p>
            </div>
          </div>
        </div>
      </template>
    </paged-list>
  </div>
</template>
<script>
import PagedList from './paged_list.vue';
import { formattingMixin } from './mixins/formatting_mix';

export default {
  name: 'wrestler-check-in-list',
  components: {
    PagedList
  },
  mixins: [formattingMixin],
  props: {
    wrestlerProfileId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      // Enum is 0 unknown, 1 status, all others absent
      status: 'all'
    };
  },
  computed: {
    baseUrl() {
      return this.$apiService.wrestlerCheckIns(this.wrestlerProfileId, this.ransackQuery);
    },
    ransackQuery() {
      switch (this.status) {
        case 'all':
          return { q: { s: 'created_at desc' } };
        case 'present':
          return {
            q: {
              status_eq: 1,
              s: 'created_at desc'
            }
          };
        default:
          // Absent
          return {
            q: {
              status_in: [2, 3, 4, 5, 6, 7],
              s: 'created_at desc'
            }
          };
      }
    },
  },
  methods: {
    filterClass(status) {
      if (status === this.status) {
        return 'border border-solid border-2 rounded-xs border-blue-600 text-blue-600 font-heavy p-sm cursor-pointer';
      }

      // Default gray pill
      return 'border border-solid rounded-xs border-gray-400 text-gray-400 p-sm cursor-pointer';
    },
    iconClass(status) {
      switch (status) {
        case 'present':
          return 'fa-check text-green-500';
        case 'unknown':
          return 'fa-question text-gray-500';
        default:
          return 'fa-times text-red-500';
      }
    }
  }
};
</script>
