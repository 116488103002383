<template>
    <div class="ibox" id="plan-categories">
        <div class="ibox-content">
            <h3>Add Block</h3>
            <p class="small"><i class="fa fa-hand-o-up"></i> Drag to add a new block to the practice</p>
            <hr/>
            <div id="blocks-tabs">
                <div class="tabs-container">
                    <ul class="nav nav-tabs">
                        <li class="active"><a data-toggle="tab" href="#tab-1" aria-expanded="true"><i class="fa fa-th-list"></i>Standard</a></li>
                        <li class=""><a data-toggle="tab" href="#tab-2" aria-expanded="false"><i class="fa fa-bookmark"></i>Saved</a></li>
                    </ul>
                    <div class="tab-content">
                        <div id="tab-1" class="tab-pane active">
                            <div class="generic-sections panel-body">
                                <template v-if="loading">
                                    <spinner></spinner>
                                </template>
                                <template v-else>
                                    <ul class="sortable-list agile-list draggable-cards">
                                        <draggable v-model="wrappedCategoriesForDragging" :clone="clone" :options="getDraggableOptions()">
                                            <li
                                                    is="practice-plan-block-category"
                                                    v-for="(block, index) in wrappedCategoriesForDragging"
                                                    :key="index"
                                                    v-bind:block="block"
                                            ></li>
                                        </draggable>
                                    </ul>
                                    <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
                                </template>
                            </div>
                        </div>
                        <div id="tab-2" class="tab-pane">
                            <div class="saved-sections panel-body">
                                <template v-if="loadingSavedBlocks">
                                    <spinner></spinner>
                                </template>
                                <template v-else>
                                    <!-- search component communicates via events rather than slots since we need to wrap the results -->
                                    <search id="search-form" class="search-form" :display-all-on-empty="false" query-key="query" response-key="practice_plan_blocks" placeholder="Search for saved blocks" base-url="/api/v1/practice_plan_blocks">
                                    </search>
                                    <div v-show="showSearchResults">
                                        <ul class="sortable-list agile-list draggable-cards">
                                            <draggable v-model="wrappedSearchBlocks" :clone="clone" :options="getDraggableOptions()">
                                                <li
                                                        is="practice-plan-block-category"
                                                        v-for="(block, index) in wrappedSearchBlocks"
                                                        :key="index"
                                                        v-bind:block="block"
                                                ></li>
                                            </draggable>
                                        </ul>
                                    </div>
                                    <div v-show="!showSearchResults">
                                        <ul class="sortable-list agile-list draggable-cards">
                                            <draggable v-model="wrappedSavedBlocks" :clone="clone" :options="getDraggableOptions()">
                                                <li
                                                        is="practice-plan-block-category"
                                                        v-for="(block, index) in wrappedSavedBlocks"
                                                        :key="index"
                                                        v-bind:block="block"
                                                ></li>
                                            </draggable>
                                        </ul>
                                    </div>
                                    <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import PracticePlanBlockCategory from './practice_plan_block_category.vue';
import Spinner from './spinner.vue';
import Search from '../../shared/search.vue';

export default {
  name: 'practice-plan-draggable-categories',
  components: {
    Search,
    Spinner,
    PracticePlanBlockCategory,
    draggable,
  },
  props: [
    'dragGroup',
  ],
  data() {
    return {
      wrappedCategoriesForDragging: [],
      blockCategories: [],

      savedBlocks: [],
      wrappedSavedBlocks: [],

      loading: false,
      loadingSavedBlocks: false,
      error: false,
      errorMessage: '',

      showSearchResults: false,
      wrappedSearchBlocks: [],
    };
  },
  mounted() {
    const vm = this;
    const markCategoriesAsFixed = function () {
      const $categories = $('#plan-categories');
      const width = $categories.width();
      const height = $(window).height() - 235;
      $categories.css({
        height,
        overflow: 'scroll',
        width,
        position: 'fixed',
      });
    };

    const unfixCategories = function () {
      const $categories = $('#plan-categories');
      $categories.css({
        width: 'auto',
        height: 'auto',
        position: 'inherit',
      });
    };

    if ($(window).width() > 1000) {
      markCategoriesAsFixed();
    }

    $(window).on('resize', () => {
      if ($(window).width() > 1200) {
        setTimeout(markCategoriesAsFixed, 500);
      } else {
        setTimeout(unfixCategories, 500);
      }
    });

    vm.$notificationManager.$on('reload-saved-blocks', vm.loadAllBlocks);
    vm.$notificationManager.$on('search-results-changed', vm.updateSearchResults);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('reload-saved-blocks', vm.loadAllBlocks);
    vm.$notificationManager.$off('search-results-changed', vm.updateSearchResults);
  },
  created() {
    const vm = this;
    vm.loadAllBlocks();
  },
  methods: {
    clone(original) {
      return JSON.parse(JSON.stringify(original));
    },
    loadAllBlocks() {
      const vm = this;
      vm.getCategories();
      vm.getSavedBlocks();
    },
    updateSearchResults(responseKey, query, results) {
      const vm = this;
      if (responseKey === 'practice_plan_blocks') {
        if (query && query.length > 0) {
          // update results
          vm.wrappedSearchBlocks = vm.getSanitizedSavedBlocks(results);
          vm.showSearchResults = true;
        } else {
          // clear results
          vm.showSearchResults = false;
        }
      }
    },
    getCategories() {
      const vm = this;
      vm.loading = true;
      const url = vm.$apiService.practicePlanBlockCategoriesUrl();
      axios.get(url)
        .then((response) => {
          vm.blockCategories = response.data.categories;
          vm.setupWrappedBlocks();
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error retrieving categories';
          vm.error = true;
        });
    },
    getSavedBlocks() {
      const vm = this;
      vm.loadingSavedBlocks = true;
      const url = vm.$apiService.templateBlocksUrl();

      vm.$apiService.loadAllPages(url, 'practice_plan_blocks')
        .then((blocks) => {
          vm.savedBlocks = blocks;
          vm.setupSavedWrappedBlocks();
          vm.loadingSavedBlocks = false;
        })
        .catch((error) => {
          vm.loadingSavedBlocks = false;
          vm.errorMessage = 'Error retrieving saved blocks';
          vm.error = true;
        });
    },
    getDraggableOptions() {
      const vm = this;
      return {
        group: {
          name: vm.dragGroup, pull: 'clone', put: false, revertClone: true,
        },
        sort: false,
      };
    },
    setupWrappedBlocks() {
      const vm = this;
      const wrappedBlocks = [];
      vm.blockCategories.forEach((c) => {
        const sections = [];
        if (c.name === 'Water') {
          sections.push({
            id: null,
            name: 'Hydrate',
            details: null,
            order: 0,
            minutes: 5,
          });
        }
        const wrappedCategory = {
          id: null,
          created_at: null,
          updated_at: null,
          template_id: null,
          name: c.name,
          order: null,
          practice_plan_block_category: c,
          sections,
        };
        wrappedBlocks.push(wrappedCategory);
      });

      vm.wrappedCategoriesForDragging = wrappedBlocks;
    },
    getSanitizedSavedBlocks(blocks) {
      const wrappedBlocks = [];
      blocks.forEach((b) => {
        const sanitizedSections = [];
        b.sections.forEach((s) => {
          s.id = null;
          s.practice_plan_block_id = null;
          s.created_at = null;
          s.updated_at = null;
          sanitizedSections.push(s);
        });
        const wrappedBlock = {
          id: null,
          created_at: null,
          updated_at: null,
          name: b.name,
          order: null,
          template_id: b.id,
          practice_plan_block_category: b.practice_plan_block_category,
          sections: sanitizedSections,
        };
        wrappedBlocks.push(wrappedBlock);
      });
      return wrappedBlocks;
    },
    setupSavedWrappedBlocks() {
      const vm = this;
      vm.wrappedSavedBlocks = vm.getSanitizedSavedBlocks(vm.savedBlocks);
    },
  },
};
</script>
