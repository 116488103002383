<template>
    <div class='search-result'>
        <div v-if="!noHr" class='hr-line-dashed'></div>
        <h3>
            <template v-if="isSelectable">
                {{ result.first_name }} {{ result.last_name }}
            </template>
            <template v-else>
                <a :href="'/parents/' + result.id ">{{ result.first_name }} {{ result.last_name }}</a>
            </template>
        </h3>
        <p class='search-type-label'>
        </p>
    </div>
</template>

<script>
export default {
  name: 'parent-search-result',
  props: [
    'result',
    'isSelectable',
    'noHr',
  ],
};
</script>
