<template>
  <div class="wrestler-invite-row border-y p-y-sm border-gray-50 border-solid flex align-items-center justify-space-between">
    <div>
      <div class="flex items-center gap-x-xs">
        <h3 class="font-semi m-b-none m-t-none">{{ wrestler.full_name }}</h3>
        <span v-if="active" class="badge badge-success">Active</span>
        <span v-if="invited" class="badge badge-warning">Invited</span>
      </div>
      <div v-if="active" class="text-gray-600">
        {{ wrestler.email }}
      </div>
      <div v-if="invited" class="text-gray-600">
        {{ wrestler.invite_email }}
      </div>
    </div>
    <invite :wrestler-id="wrestler.id" :reload-page="true" v-if="inactive" :wrestler-first-name="wrestler.first_name"></invite>
  </div>
</template>

<script>
import Invite from './invite.vue';

export default {
  name: 'WrestlerInviteRow',
  components: { Invite },
  props: {
    wrestler: {
      type: Object,
      required: true,
    },
  },
  computed: {
    active() {
      return this.wrestler.user_status === 'active';
    },
    invited() {
      return this.wrestler.user_status === 'invited';
    },
    inactive() {
      return !this.active && !this.invited;
    }
  }
};
</script>
