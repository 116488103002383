<template>
  <div v-show="count > 0" class="p-b-md border-b border-solid border-gray-50 m-b-lg">
    <div>
      <h2 class="m-b-sm m-t-none">
        Your Invites
      </h2>
    </div>
    <full-load-list container-class="list-results" :base-url="inviteUrl" response-key="event_invites">
      <template v-slot:list="slotProps">
        <div class="flex justify-space-between flex-nowrap align-items-center m-t-sm">
          <p class="m-t-none m-b-xxs">
            {{ slotProps.result.invitee.first_name }} {{ slotProps.result.invitee.last_name }} <spinner :inline="true" v-if="slotProps.result.loading === true"></spinner>
          </p>
          <div class="check-in-buttons flex-shrink-0">
            <button class="btn btn-primary" :class="{ 'btn-outline': slotProps.result.status !== 'accepted'}" @click="updateStatus(slotProps.result, 'accepted')"><i class="fa fa-check"></i> Yes</button>
            <button class="btn btn-danger" :class="{ 'btn-outline': slotProps.result.status !== 'declined'}" @click="updateStatus(slotProps.result, 'declined')"><i class="fa fa-times"></i> No</button>
          </div>
        </div>
      </template>
    </full-load-list>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import FullLoadList from './full_load_list.vue';
import Spinner from './spinner.vue';
export default {
  // This pulls a list of all event invites you have and shows them.
  name: "rsvp-form",
  components: {
    FullLoadList,
    Spinner
  },
  props: {
    eventId: {
      type: Number,
      required: true
    },
    forProfileId: {
      type: Number,
      required: true
    },
    forProfileType: {
      type: String,
      required: true
    }
  },
  mixins: [errorableMix],
  data() {
    return {
      count: 0,
    }
  },
  created() {
    this.$on('full-list-count', this.fullListLoaded);
  },
  destroyed() {
    this.$off('full-list-count', this.fullListLoaded);
  },
  computed: {
    inviteUrl() {
      return `${this.$apiService.eventInvitesUrl(this.eventId)}?profile_type=${this.forProfileType}&profile_id=${this.forProfileId}`;
    }
  },
  methods: {
    fullListLoaded(count) {
      this.count = count;
    },
    updateStatus(invite, status) {
      let vm = this;
      // Assume it's going to work
      invite.status = status;
      // Ensure loading gets slotted in as a reactive property
      vm.$set(invite, 'loading', true);
      const url = vm.$apiService.eventInviteUrl(invite.id);
      axios.put(url, {event_invite: { status: status } })
          .then((response) => {
            invite.loading = false;
            let countChange = status === 'accepted' ? 1 : -1;
            vm.$notificationManager.$emit('event-invite-changed', invite.invitee.type, countChange);
            vm.$notificationManager.$emit('event-invite-updated');
            vm.$notificationManager.$emit('show-toast', 'Invite update', true);
          })
          .catch((error) => {
            invite.status = 'pending';
            invite.loading = false;
            vm.$notificationManager.$emit('show-toast', 'Invite update failed', false);
          });
    }
  }
};
</script>
