<template>
  <div class="search-result">
    <div class="row">
      <div class="col-xs-12">
        <div class="flex">
          <div class="event-info">
            <h3 class="font-heavy">
              <a v-if="hasHref" :href="`/events/${result.id}`" target="_blank">
                {{ result.name }} <i class="fa fa-external-link"></i>
              </a>
              <span v-else>
                {{ result.name }}
              </span>
            </h3>
            <p class="m-b-none">{{ readableEventType }}</p>
            <p v-if="result.start_at" class="m-t-none">
              <time :datetime="result.start_at">{{ formatDate(result.start_at) }}</time>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'event-search-result',
  props: {
    result: {
      type: Object,
      required: true
    },
    hasHref: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    readableEventType() {
      const { event_type } = this.result;
      if (event_type === 'dual_meet') {
        return 'Dual meet';
      } if (event_type === 'tournament') {
        return 'Tournament';
      }
      return event_type;
    },
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
  }
};
</script>
