<template>
  <div>
    <template v-if="created">
      <p>WIQ ID Card is being created. Please refresh the page in a couple seconds...</p>
    </template>
    <template v-else-if="forRegeneration">
      <a @click.prevent="createIdCard" class=""><i class="fa fa-repeat color-gray-400 m-r-xxs"></i> Regenerate ID card...</a>
      <p class="text-danger" v-if="error">{{ errorMessage }}</p>
    </template>
    <template v-else>
      <button class="btn btn-block btn-outline btn-success" :disabled="loading" @click="createIdCard">
        Generate WIQ ID Card <spinner inline="true" v-if="loading"></spinner>
      </button>
      <p class="text-danger" v-if="error">{{ errorMessage }}</p>
    </template>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import Spinner from './spinner.vue';

export default {
  name: 'generate-wiq-id-card',
  components: { Spinner },
  mixins: [errorableMix],
  props: {
    wrestlerId: {
      type: Number,
      required: true
    },
    forRegeneration: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      created: false,
    }
  },
  methods: {
    createIdCard: function() {
      if (this.loading) return;
      this.loading = true;
      this.error = false;

      const params = {
        athlete_identifier: {
          type: 'WiqAthleteIdentifier',
          wrestler_profile_id: this.wrestlerId,
        }
      };

      const url = this.$apiService.athleteIdUrl();
      axios.post(url, params)
          .then((response) => {
            this.loading = false;
            this.created = true;
          })
          .catch((error) => {
            this.loading = false;
            this.errorMessage = 'Error creating id card, please try again later or contact support@wrestlingiq.com';
            this.error = true;
          });
    }
  }
};
</script>
