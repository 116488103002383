export const checkInMix = {
  data() {
    return {
      wrestlerProfileId: null,
      checkInId: null,
      checkInStatus: 'unknown',
      editingStatus: true,
      createdAt: null,

      saving: false,
      error: false,
    };
  },
  created() {
    this.syncPropsToData();
  },
  computed: {
    absent() {
      return this.checkInStatus === 'absent';
    },
    present() {
      return this.checkInStatus === 'present';
    },
    excused() {
      const excusedStatus = [
        'excused',
        'late',
        'injured',
        'other',
      ];

      return _.includes(excusedStatus, this.checkInStatus);
    },
    absentClasses() {
      return {
        btn: true,
        'btn-danger': this.absent,
        'btn-default': !this.absent,
      };
    },
    presentClasses() {
      return {
        btn: true,
        'btn-primary': true,
        'btn-outline': !this.present,
      };
    },
    statusIcon() {
      return {
        fa: true,
        'fa-check': this.present,
      };
    },
    cardClasses() {
      return {
        'check-in-card': true,
        'text-muted': this.absent,
      };
    },
  },
  methods: {
    syncPropsToData() {
      const vm = this;
      vm.wrestlerProfileId = vm.wrestlerProfile.id;
      if (vm.checkIn) {
        vm.checkInId = vm.checkIn.id;
        vm.checkInStatus = vm.checkIn.status;
        vm.editingStatus = vm.checkInStatus === 'unknown';
        vm.createdAt = vm.checkIn.created_at;
      }
    },
    readableStatus(removePresent) {
      const status = this.checkInStatus.replace('_', ' ');

      if (status === 'present' && removePresent) {
        return '';
      }

      return _.capitalize(status);
    },
    statusTapped(status) {
      const vm = this;
      if (!vm.editable) {
        return;
      }

      vm.checkInStatus = status;
      vm.save(false);
    },
  },
};
