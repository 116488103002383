<template>
  <div>
    <div v-show="expanded" class="form-bordered p-m">
      <div class="grid grid-cols-2 sm-grid-cols-1 gap-8">
        <div>
          <label for="price_name">Name</label>
          <input id="price_name" v-model="name" type="text" class="form-control">
        </div>
        <div>
          <label>Amount</label>
          <money v-model="amount" v-bind="money" class="form-control" :disabled="existingId"></money>
        </div>
        <div>
          <div class="form-group flex align-items-start">
            <input type="checkbox" name="active" v-model="active" :aria-checked="active">
            <div class="m-l-sm">
              <label for="active" class="m-b-none">
                Enabled?
              </label>
              <p class="text-gray-600">
                Uncheck to hide this tier from contributors.
              </p>
            </div>
          </div>
          <div class="form-group flex align-items-start">
            <input type="checkbox" name="soldout" v-model="soldOut" :aria-checked="soldOut">
            <div class="m-l-sm">
              <label for="active" class="m-b-none">
                Sold out?
              </label>
              <p class="text-gray-600">
                Check this box to keep visible on the fundraiser, but mark it as sold out.
              </p>
            </div>
          </div>
        </div>
        <div class="alert alert-info">
          <p>
            <strong>What's the difference between sold out and enabled?</strong>
          </p>
          <p>
            Disabling will hide the tier from contributors completely.
          </p>
          <p>
            A sold out tier will show a sold out graphic and stop contributors from adding it to their cart.
            Useful if you had a limited quantity item (e.g. early bird pricing) that you want people to know previously existed.
          </p>
        </div>
        <div v-if="false" class="flex flex-col">
          <div>
            <label class="m-r-sm" for="limited_quantity">
              Limited quantity?
            </label>
            <input type="checkbox" id="limited_quantity" v-model="fixedQuantity">
          </div>
          <div class="m-t-xs" v-if="fixedQuantity">
            <label>How many are available?</label>
            <input v-model="quantity" type="number" class="form-control"/>
          </div>
        </div>
      </div>
      <div class="button-area m-t-md">
        <div v-show="error">
          <small>
            <span class="text-danger">{{ errorMessage }}</span>
          </small>
        </div>
        <button class="btn btn-default" @click.prevent="cancel">Cancel</button>
        <button class="btn btn-primary" @click.prevent="validate">
          Done
        </button>
      </div>
    </div>
    <div v-show="!expanded">
      <div class="card-bordered">
        <div class="row">
          <div class="col-xs-12 col-sm-6" :class="{  'color-gray-400': !active && !draft }">
            {{ name }}
            <span class="text-success" v-if="draft"> - Draft </span>
            <span class="text-danger" v-if="!active">- Disabled </span>
            <span class="text-warning" v-if="soldOut"> - Sold out </span>
            <br/>
            {{ prettyPrice }} <span v-if="fixedQuantity"> - quantity limited to {{ quantity }}</span>
          </div>
          <div class="col-xs-12 col-sm-6 actions">
            <a @click.prevent="toggleExpanded">Edit</a>
            <a v-if="!existingId" class="text-danger" @click.prevent="removeDraft">Discard</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Money } from 'v-money';
import { formattingMixin } from './mixins/formatting_mix';
import { errorableMix } from './mixins/errorable_mix';

export default {
  name: 'fundraiser-price-form',
  mixins: [formattingMixin, errorableMix],
  components: {
    Money,
  },
  props: [
    'price',
  ],
  data() {
    return {
      draft: false,
      existingId: null,
      name: '',
      currency: 'usd',
      amount: 0,
      active: true,
      soldOut: false,
      fixedQuantity: false,
      quantity: 0,
      isNewOption: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },

      expanded: false,
    };
  },
  computed: {
    prettyPrice() {
      return this.formatAsCurrency(this.amount * 100);
    },
  },
  mounted() {
    const vm = this;
    const { price } = this;

    vm.existingId = _.get(price, 'id', null);
    vm.name = _.get(price, 'name', '');
    vm.amount = _.get(price, 'amount', 0);
    vm.active = _.get(price, 'active', true);
    vm.soldOut = _.get(price, 'sold_out', false);
    vm.currency = _.get(price, 'currency', 'usd');
    if (_.get(price, 'inventory_type', 'infinite') === 'finite') {
      vm.fixedQuantity = true;
    }
    vm.quantity = _.get(price, 'quantity', 0);

    // v money expects a float, api returns cents
    if (vm.amount > 0) {
      vm.amount /= 100.00;
    }

    if (!vm.existingId) {
      vm.isNewOption = true;
      vm.toggleExpanded();
    }
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$parent.$emit('editing-price');
    },
    cancel() {
      this.expanded = !this.expanded;
      this.$parent.$emit('editing-price-cancelled', this.price.newOptionId, this.isNewOption);
    },
    removeDraft() {
      if (!this.existingId) {
        this.$parent.$emit('discard-price-draft', this.price.newOptionId);
      }
    },
    validate() {
      const vm = this;

      if (!vm.name) {
        vm.showError('Please ensure name is not empty');
        return;
      }

      vm.expanded = !vm.expanded;
      // This is used to distinguish whether it's completely new or whether it's just updated
      vm.isNewOption = false;
      vm.draft = true;
      vm.$parent.$emit('price-updated', vm.getUpdatedPriceObject());
    },
    getUpdatedPriceObject() {
      const vm = this;
      return {
        draft: true,
        id: vm.existingId,
        newOptionId: vm.price.newOptionId,
        name: vm.name,
        amount: _.round(vm.amount * 100),
        currency: vm.currency,
        active: vm.active,
        sold_out: vm.soldOut,
        inventory_type: vm.fixedQuantity ? 'finite' : 'infinite',
        quantity: vm.quantity,
      };
    },
  },
};
</script>
