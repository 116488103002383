<template>
  <tbody class="border border-gray-100 border-b border-t-none border-solid">
  <tr class="relative" :class="{ 'bg-gray-50': showRefundForm }">
    <td class="p-r-sm" data-title="Amount">
      <div class="font-heavy text-gray-700">
          {{ formatAsCurrency(paid ? row.amount_paid : row.attempted_amount) }}&nbsp;
          <VDropdown theme="info-tooltip" v-if="paid && showRefundActions">
              <!-- This will be the popover target (for the events and position) -->
              <i class="fa fa-info"></i>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                  <div>
                      <div class="flex flex-col justify-space-between" style="min-width: 140px;">
                          <div class="flex gap-x-sm justify-space-between m-b-sm p-b-sm border-bottom text-semi">
                              <div>
                                  Gross
                              </div>
                              <div>
                                  {{ formatAsCurrency(row.amount_paid) }}
                              </div>
                          </div>
                          <div class="flex gap-x-sm justify-space-between">
                              <div>
                                  CC processing fee
                              </div>
                              <div>
                                  {{ formatAsCurrency(row.processing_fee) }}
                              </div>
                          </div>
                          <div class="flex gap-x-sm justify-space-between">
                              <div>
                                  WIQ fee
                              </div>
                              <div>
                                  {{ formatAsCurrency(row.wiq_fee) }}
                              </div>
                          </div>
                          <div class="flex gap-x-sm justify-space-between" v-if="row.tax_amount > 0">
                            <div>
                              Taxes
                            </div>
                            <div>
                              {{ formatAsCurrency(row.tax_amount) }}
                            </div>
                          </div>
                          <div v-if="alreadyRefundedAmount > 0" class="flex gap-x-sm justify-space-between">
                              <div>
                                  Refund
                              </div>
                              <div>
                                  {{ formatAsCurrency(alreadyRefundedAmount) }}
                              </div>
                          </div>
                          <div class="flex gap-x-sm justify-space-between m-t-sm p-t-sm border-top text-semi">
                              <div>
                                  Net
                              </div>
                              <div>
                                  {{ formatAsCurrency(row.net_amount) }}
                              </div>
                          </div>
                      </div>
                  </div>
              </template>
          </VDropdown>
      </div>
    </td>
    <td class="text-gray-700 -m-t-sm">
      <div v-if="paid && row.refunds.length === 0" class="flex gap-x-xs">
        <div class="label label-primary bg-green-50 text-green-800 font-normal border border-solid border-green-500">
          <i class="fa fa-check"></i> Succeeded
        </div>
        <a :href="row.receipt.url" v-if="row.receipt" target="_blank"><i class="fa fa-file-text-o" title="Receipt"></i></a>
      </div>
      <div v-if="!paid"
           class="label label-primary bg-red-50 text-red-800 font-normal border border-solid border-red-800"><i
          class="fa fa-times"></i> Failed
      </div>
      <div class="flex flex-col" v-if="row.refunds.length > 0">
        <div v-if="fullyRefunded"><span class="label label-warning">Fully Refunded</span></div>
        <div v-else-if="partiallyRefunded"><span class="label label-warning">Partially Refunded</span></div>
        <div class="flex flex-col" style="margin-top: 4px;">
          <div class="font-small"><a :href="row.receipt.url" v-if="row.receipt" target="_blank">Original receipt <i
              class="fa fa-file-text-o" title="Receipt"></i></a></div>
          <div class="font-small">Refunds</div>
          <div v-for="refund in row.refunds" class="font-small">
            <template v-if="refund.receipt !== null">
              <a :href="refund.receipt.url" target="_blank">{{
                  formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a')
                }} <span v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span> <i
                    class="fa fa-file-text-o"></i></a>
            </template>
            <template v-else>
              {{ formatWithMoment(refund.created_at, 'M/D/YYYY, hh:mm a') }} <span
                v-if="partiallyRefunded">({{ formatAsCurrency(refund.amount) }})</span>
            </template>
          </div>
        </div>
      </div>
    </td>
    <td class="text-gray-700" data-title="For">
      <div>
        {{ row.chargeable.description }}
      </div>
    </td>
    <td class="text-gray-700" data-title="Billed to">
      <div v-if="billableUrl">
        <a :href="billableUrl" target="_blank">{{ row.billing_profile.billable.display_name }}</a>
      </div>
      <div v-else>
        {{ displayNameOrEmail }}
      </div>
    </td>
    <td class="text-gray-700" data-title="Wrestler(s)">
      <div v-for="wrestler in row.wrestlers" :key="wrestler.id">
        <a :href="`/wrestlers/${wrestler.id}`" target="_blank">{{ wrestler.full_name }}</a>
      </div>
    </td>
    <td class="text-gray-700" data-title="Date">{{ formatWithMoment(row.created_at, 'M/D/YYYY hh:mm a') }}</td>
    <td class="flex flex-col gap-y-xxs" :data-title="(refundPossible || isRecurring) ? 'Actions' : ''">
      <div v-if="refundPossible">
        <button @click="showRefundForm = true"
                class="btn no-underline btn-compact bg-gray-50 border border-gray-400 text-gray-700 flex justify-space-between gap-x-xxs">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z"/>
          </svg>
          <span class="text-12">
              Refund
            </span>
        </button>
      </div>
      <div v-if="isRecurring">
        <a :href="subscriptionUrl"
                class="btn no-underline btn-compact bg-white border border-gray-400 text-gray-700">
          <span class="text-12">
              View Subscription
            </span>
        </a>
      </div>
    </td>
  </tr>
  <tr v-if="showRefundForm">
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td colspan="2">
      <div class="m-b-lg" v-if="showRefundForm">
        <refund-form :possible-refund-amount="possibleRefundAmount" :charge="row"></refund-form>
      </div>
    </td>
  </tr>
  </tbody>
</template>
<script>
import { formattingMixin } from './mixins/formatting_mix';
import RefundForm from './refund_form.vue';

export default {
  name: 'charge-row',
  mixins: [formattingMixin],
  components: { RefundForm },
  props: {
    row: {
      type: Object,
      required: true
    },
    showRefundActions: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showRefundForm: false,
    };
  },
  computed: {
    billableUrl() {
      switch (this.row.billing_profile.billable.type) {
        case 'ParentProfile':
          return `/parents/${this.row.billing_profile.billable.id}`;
        case 'CoachProfile':
          return `/coaches/${this.row.billing_profile.billable.id}`;
        default:
          return null;
      }
    },
    displayNameOrEmail() {
      return this.row.billing_profile.billable.display_name || this.row.billing_profile.email;
    },
    paid() {
      return this.row.status === 'successful';
    },
    paidWithCard() {
      return this.paid && this.row.payment_method === 'card';
    },
    // TODO DRY this up with manual invoice and invoice payment
    possibleRefundAmount() {
      if (!this.paid) {
        return 0;
      }
      return this.row.amount_paid - this.alreadyRefundedAmount;
    },
    alreadyRefundedAmount() {
      return _.sumBy(this.row.refunds, 'amount');
    },
    fullyRefunded() {
      return this.row.amount_paid > 0 && this.possibleRefundAmount === 0;
    },
    partiallyRefunded() {
      return this.row.amount_paid > 0 && this.alreadyRefundedAmount > 0 && !this.fullyRefunded;
    },
    refundPossible() {
      return this.paidWithCard && !this.fullyRefunded && this.showRefundActions && this.row.amount_paid !== 0;
    },
    isRecurring() {
      return this.row.chargeable && this.row.chargeable.type === "InvoicePayment";
    },
    subscriptionUrl() {
      // This will redirect them to the subscription page
      return `/invoice_payments/${this.row.chargeable.id}`;
    }
  },
  mounted() {
    this.$on('cancel-refund', this.hideRefundForm);
  },
  destroyed() {
    this.$off('cancel-refund', this.hideRefundForm);
  },
  methods: {
    hideRefundForm(charge) {
      if (charge.id === this.row.id) {
        this.showRefundForm = false;
      }
    },
  },
};
</script>
