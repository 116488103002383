<template>
    <div class="block-template-form" role="form">
        <h3 v-show="creating">
          Save Block
        </h3>
        <h3 v-show="!creating">Update Block</h3>
        <div class="form-group first-form-group">
            <label>Block Name</label>
            <input v-model="name" type="text" class="form-control">
        </div>
        <div class="form-group">
            <template v-if="!creating">
                <label>
                    You are updating a saved block.
                    Would you like to update all existing blocks created with this saved block in previous practice plans with the new saved block content?
                </label>
                <div>
                    <label>
                        <input type="radio" value="single" id="only_this_block" v-model="updateAll"> Only update the saved block
                    </label>
                </div>
                <div>
                    <label>
                        <input type="radio" value="all" id="all_related_blocks" v-model="updateAll"> Update saved block, and all existing blocks created with it
                    </label>
                </div>
            </template>
        </div>
        <div class="button-area text-right">
            <div v-show="error">
                <small>
                    <span class="text-danger">{{ errorMessage }}</span>
                </small>
            </div>
            <ladda-button @lbClicked="pingServer" el-class="btn-primary" :loading="loading">
                {{ buttonText() }}
            </ladda-button>
            <button type="button" v-show="!loading" @click="hideForm()" class="btn btn-white m-r-md">Cancel</button>
        </div>
    </div>
</template>

<script>

import LaddaButton from '../../shared/ladda_button.vue';

export default {
  components: {
    LaddaButton,
  },
  name: 'practice-plan-block-template-form',
  props: [
    'block',
  ],
  data() {
    return {
      name: null,
      existingTemplateId: null,
      creating: false,
      updateAll: 'single',

      createFirst: false,
      createdBlock: null,

      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  mounted() {
    const vm = this;
    vm.name = vm.block.name;
    if (vm.block.is_template) {
      vm.existingTemplateId = vm.block.id;
    } else {
      vm.existingTemplateId = vm.block.template_id;
    }

    if (!vm.existingTemplateId) {
      vm.creating = true;
    }
  },
  methods: {
    buttonText() {
      if (this.creating) {
        return 'Create Saved Block';
      }
      return 'Update Saved Block';
    },
    hideForm() {
      const vm = this;
      vm.$parent.$emit('template-form-hidden');
    },
    errorHandler() {
      const vm = this;
      vm.loading = false;

      vm.errorMessage = 'Error, please try again later or contact support.';
      vm.error = true;
    },
    pingServer() {
      const vm = this;

      const valid = vm.validateSections();
      if (!valid) {
        vm.errorMessage = "Finish editing the sections before saving (click 'Cancel' or 'Done' in the open section editor above)";
        vm.error = true;
        return;
      }

      vm.error = false;

      if (vm.loading === true) {
        return;
      }

      vm.loading = true;

      if (vm.name.length === 0) {
        vm.error = true;
        vm.errorMessage = 'Name required';
        return;
      }
      vm.error = false;
      vm.errorMessage = '';

      if (vm.creating) {
        vm.create();
      } else {
        vm.update();
      }
    },
    validateSections() {
      const vm = this;
      let valid = true;
      vm.block.sections.forEach((section) => {
        if (section.name === null || section.name === '' || section.minutes === null) {
          valid = false;
        }
      });

      return valid;
    },
    create() {
      const vm = this;

      const url = vm.$apiService.templateBlocksUrl();
      const params = {
        practice_plan_block: {
          name: vm.name,
          order: 0,
          practice_plan_block_category_id: vm.block.practice_plan_block_category.id,
        },
      };
      axios.post(url, params)
        .then((response) => {
          vm.existingTemplateId = response.data.id;
          vm.createdBlock = response.data;
          vm.update();
        })
        .catch((error) => {
          vm.errorHandler(error);
        });
    },
    update() {
      const vm = this;
      const url = vm.$apiService.templateBlockUrl(vm.existingTemplateId);

      const updateChildren = vm.updateAll === 'all';
      const params = {
        update_children: updateChildren,
        practice_plan_block: {
          name: vm.name,
          order: 0,
          practice_plan_block_category_id: vm.block.practice_plan_block_category.id,
          sections: vm.block.sections,
        },
      };
      axios.put(url, params)
        .then((response) => {
          vm.loading = false;

          if (vm.creating) {
            vm.createdBlock.sections = vm.sanitizedSections(vm.block.sections);
            vm.$parent.$emit('block-template-updated', vm.createdBlock, true);
            vm.$notificationManager.$emit('show-toast', `${vm.name} created`, true);
          } else {
            // We do this silly hack so the emit handler can deal with things the same way
            // in this case block.id
            vm.block.name = vm.name;
            vm.block.sections = vm.sanitizedSections(vm.block.sections);
            vm.$parent.$emit('block-template-updated', vm.block, false);
            vm.$notificationManager.$emit('show-toast', `${vm.name} updated`, true);
          }

          vm.$notificationManager.$emit('reload-saved-blocks');
        })
        .catch((error) => {
          vm.errorHandler(error);
        });
    },
    sanitizedSections(sections) {
      const sanitizedSections = [];
      sections.forEach((s) => {
        s.id = null;
        s.practice_plan_block_id = null;
        s.created_at = null;
        s.updated_at = null;
        sanitizedSections.push(s);
      });
      return sanitizedSections;
    },
  },
};
</script>
