<template>
    <div :class="getVisibilityClass()">
        <div class="alert alert-info" role="alert" v-if="showHelp()">
            {{ helpText }}
        </div>
        <div>
            <spinner v-if="loading"></spinner>
        </div>
        <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
        <slot name="total" :total-count="resultCount"></slot>
        <div :class="containerClass" v-show="resultsLoaded">
            <slot name="list"
                  v-for="(result, index) in results"
                  :result="result"
                  :index="index"
            >
            </slot>
            <div v-if="manualPaging && !noMoreData" class="manual-pager">
                <a v-on:click="getMoreListResults()"><small>Load more...</small></a>
            </div>
            <div class="loading-more">
                <small v-show="loadingMore">Loading more...</small>
                <spinner v-if="loadingMore"></spinner>
            </div>
            <div v-if="isEmpty() && showEmptyText" class="empty-list">
                <p>{{ emptyMessage }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import LocalTime from 'local-time';
import Spinner from './spinner.vue';

export default {
  components: {
    Spinner,
  },
  name: 'paged-list',
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    responseKey: {
      type: String,
      required: true,
    },
    objectType: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      required: false,
    },
    manualPaging: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: 'list-results'
    },
    emptyText: {
      type: String,
      required: false,
    },
    showEmptyText: {
      type: Boolean,
      default: true,
    },
    loadPerPage: {
      type: Number,
      default: 30,
    },
    ransackParams: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loading: false,

      loadingMore: false,
      noMoreData: false,

      nextPageUrl: null,
      page: 1,
      perPage: 30,

      resultsLoaded: false,
      resultCount: 0,
      results: [],

      error: false,
      errorMessage: '',

      dimSearchResults: false,
      emptyMessage: 'None found',

      cancelSource: null,
    };
  },
  created() {
    const vm = this;
    if (vm.emptyText) {
      vm.emptyMessage = vm.emptyText;
    }
    vm.getList();
    window.addEventListener('scroll', _.throttle(vm.handleScroll, 500));
    vm.$notificationManager.$on('match-invite-created', vm.matchInviteCreated);
    vm.$notificationManager.$on('mentionable-created', vm.mentionableCreated);
    vm.$notificationManager.$on('form-shown', vm.formShownHandler);
    vm.$notificationManager.$on('form-hidden', vm.formHiddenHandler);
    vm.$notificationManager.$on('wrestler-created', vm.wrestlerCreated);
    vm.$notificationManager.$on('class-pass-changed', vm.classPassChanged);
    vm.$notificationManager.$on('match-created', vm.undimResultsForMatch);
    vm.$notificationManager.$on('match-updated', vm.undimResultsForMatch);
    vm.$notificationManager.$on('invoice-paid', vm.invoicePaid);
    vm.$notificationManager.$on('original-video-deleted', vm.originalVideoDeleted);
    vm.$notificationManager.$on('unread-message-count-changed', vm.updateMessages);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    const vm = this;
    vm.$notificationManager.$off('match-invite-created', vm.matchInviteCreated);
    vm.$notificationManager.$off('mentionable-created', vm.mentionableCreated);
    vm.$notificationManager.$off('form-shown', vm.formShownHandler);
    vm.$notificationManager.$off('form-hidden', vm.formHiddenHandler);
    vm.$notificationManager.$off('wrestler-created', vm.wrestlerCreated);
    vm.$notificationManager.$off('class-pass-changed', vm.classPassChanged);
    vm.$notificationManager.$off('match-created', vm.undimResultsForMatch);
    vm.$notificationManager.$off('match-updated', vm.undimResultsForMatch);
    vm.$notificationManager.$off('invoice-paid', vm.invoicePaid);
    vm.$notificationManager.$off('original-video-deleted', vm.originalVideoDeleted);
    vm.$notificationManager.$off('unread-message-count-changed', vm.updateMessages);
  },
  watch: {
    baseUrl() {
      console.log(`changed base url to ${this.baseUrl}`);
      this.getList();
    },
  },
  updated() {
    LocalTime.run();
  },
  computed: {
    baseUrlWithParams() {
      let formattedRansackParams = this.ransackParams ? `&${this.$apiService.formatRansackParams(this.ransackParams)}` : '';
      if (this.baseUrl.indexOf('?') !== -1) {
        // We have existing parameters so append the params
        return `${this.baseUrl}&page=${this.page}&per_page=${this.perPage}${formattedRansackParams}`;
      }
      // Otherwise, assume these are the only parameters
      return `${this.baseUrl}?page=${this.page}&per_page=${this.perPage}${formattedRansackParams}`;
    }
  },
  methods: {
    isEmpty() {
      return this.resultsLoaded && this.resultCount === '0';
    },
    showHelp() {
      const vm = this;
      if (vm.helpText !== undefined && vm.helpText.length > 0) {
        return true;
      }

      return false;
    },
    matchInviteCreated() {
      const vm = this;
      if (vm.objectType === 'MatchInvite') {
        vm.getList();
      }
    },
    updateMessages() {
      const vm = this;
      if (vm.objectType === 'MessageGroup') {
        vm.getList();
      }
    },
    wrestlerCreated() {
      const vm = this;
      if (vm.objectType === 'WrestlerProfile') {
        vm.getList();
      }
    },
    classPassChanged() {
      const vm = this;
      if (vm.objectType === 'ClassPass') {
        vm.getList();
      }
    },
    invoicePaid(invoice, type) {
      if (type === this.objectType) {
        this.getList();
      }
    },
    mentionableCreated(mentionableType) {
      const vm = this;
      if (mentionableType === vm.objectType) {
        vm.getList();
      }
    },
    formHiddenHandler(mentionableType) {
      const vm = this;
      if (mentionableType === vm.objectType) {
        vm.dimSearchResults = false;
      }
    },
    formShownHandler(mentionableType) {
      const vm = this;
      if (mentionableType === vm.objectType) {
        vm.dimSearchResults = true;
      }
    },
    originalVideoDeleted(originalVideo) {
      const vm = this;
      if (vm.objectType === 'OriginalVideo') {
        vm.getList();
      }
    },
    undimResultsForMatch(match, originalVideo) {
      const vm = this;
      if (vm.objectType === 'OriginalVideo') {
        vm.dimSearchResults = false;
      }
    },
    getVisibilityClass() {
      if (this.dimSearchResults) {
        return 'dim';
      }
      return '';
    },
    setNoMoreData() {
      const vm = this;
      vm.nextPageUrl = null;
      vm.noMoreData = true;
    },
    setupInfiniteScroll(headers) {
      const vm = this;

      vm.resultCount = headers.totalcount;
      vm.$notificationManager.$emit('total-result-count', vm.objectType, vm.resultCount);

      if (!headers.link) {
        vm.setNoMoreData();
        return;
      }

      const paginationLinks = headers.link.split(',');
      let nextLinkDetected = false;
      paginationLinks.forEach((l) => {
        const nextRel = 'rel="next"';
        if (l.indexOf(nextRel) !== -1) {
          const regExp = /\<([^>]+)>/;
          vm.nextPageUrl = regExp.exec(l)[1];
          nextLinkDetected = true;
        }
      });

      if (!nextLinkDetected) {
        vm.setNoMoreData();
      }
    },
    getList() {
      const vm = this;
      vm.resetListResults();

      vm.loading = true;
      vm.cancelSource = axios.CancelToken.source();

      axios.get(this.baseUrlWithParams, { cancelToken: vm.cancelSource.token })
        .then((response) => {
          vm.loading = false;
          vm.cancelSource = null;
          vm.results = response.data[vm.responseKey];
          vm.setupInfiniteScroll(response.headers);
          vm.resultsLoaded = true;
          vm.$notificationManager.$emit('first-page-loaded', vm.objectType, vm.results.length);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('request cancelled');
          } else {
            vm.resetListResults();
            vm.errorMessage = `Error retrieving ${vm.responseKey}`;
            vm.error = true;
          }
        });
    },
    // This function is _throttled above, so we have some protection against the native scroll listener
    // Still need to be cognizant that I'm not requesting more than one page though
    getMoreListResults() {
      const vm = this;
      if (vm.loadingMore) {
        // In this scenario a load more page request is already happening
        return;
      }

      vm.loadingMore = true;
      vm.page += 1;
      axios.get(vm.nextPageUrl)
        .then((response) => {
          vm.loadingMore = false;
          vm.results = vm.results.concat(response.data[vm.responseKey]);
          vm.setupInfiniteScroll(response.headers);
          vm.error = false;
        })
        .catch((error) => {
          vm.resetListResults();
          vm.errorMessage = `Error retrieving ${vm.responseKey}`;
          vm.error = true;
        });
    },
    resetListResults() {
      const vm = this;

      // If we have a cancellation function, use it
      if (vm.cancelSource) {
        vm.cancelSource.cancel();
        vm.cancelSource = null;
      }

      vm.loading = false;

      vm.loadingMore = false;
      vm.noMoreData = false;

      vm.nextPageUrl = null;
      vm.page = 1;
      vm.perPage = this.loadPerPage;

      vm.resultsLoaded = false;
      vm.resultCount = 0;
      vm.results = [];

      vm.error = false;
      vm.errorMessage = '';
    },
    handleScroll() {
      const vm = this;

      if (!vm.manualPaging) {
        const $element = $(vm.$el);
        const triggerPoint = $element.height() + $element.offset().top - 100;
        const currentPosition = $(window).scrollTop() + $(window).height();
        if (currentPosition > triggerPoint && vm.nextPageUrl !== null) {
          this.getMoreListResults();
        }
      }
    },
  },
};
</script>
