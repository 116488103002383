<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-content">
            <search
                class="search-form"
                response-key="message_groups"
                placeholder="Search group names"
                :mutate-history="false"
                :allow-add="false"
                :small-input="true"
                :display-all-on-empty="true"
                readable-model-name="message group"
                title-classes="grid grid-cols-2 p-m p-l-sm-xs-only p-r-n-xs-only align-items-center "
                base-url="/api/v1/message_groups">
              <template v-slot:title>
                <h1 class="font-heavy hidden-xs hidden-sm">Messages</h1>
              </template>
              <template v-slot:ctas>
                <div class="justify-self-end">
                  <div v-if="!editMode">
                    <button v-on:click="editMode = !editMode" class="btn btn-primary btn-compact m-r-sm" type="button">
                      Edit
                    </button>
                    <button v-on:click="newMessage()" class="btn btn-primary btn-compact" type="button"><i
                        class="fa fa-pencil-square-o"></i><span class="hidden-xs"> Message</span></button>
                  </div>
                  <div v-else>
                    <button :disabled="hiding" v-on:click="editMode = false" class="btn btn-danger btn-compact"
                            type="button">Cancel edit
                    </button>
                  </div>
                </div>
              </template>
              <template v-slot:list="slotProps">
                <div class='hr-line-dashed'></div>
                <div class="flex align-items-center search-result justify-space-between gap-x-sm"
                     @mouseenter="showHoverMenuFor(slotProps.result)" @mouseleave="hideHoverMenuFor(slotProps.result)">
                  <div v-if="editMode" class="m-r-m">
                    <input type="checkbox" :id="`mg_${slotProps.result.id}`" :value="slotProps.result"
                           v-model="selectedGroups">
                  </div>
                  <div>
                    <h3>
                      <a :href="'/message_groups/' + slotProps.result.id" @click="handleTap($event, slotProps.result)">{{
                          slotProps.result.name
                        }}</a>
                    </h3>
                  </div>
                  <div class="flex gap-x-sm">
                    <div v-if="hasUnread(slotProps.result)" class="label label-primary">
                      {{ unreadCount(slotProps.result) }}
                    </div>
                    <div v-if=" shouldShowHoverMenuFor(slotProps.result) || hoverGroupPinned(slotProps.result)"
                         @click.prevent="togglePin(slotProps.result)">
                      <svg class="cursor-pointer block" v-if="!hoverGroupUpdating(slotProps.result)" width="15" height="16"
                           viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="8" r="7.5"
                                :fill="hoverGroupPinned(slotProps.result) ? '#27AA83' : '#9EB2C7'"/>
                        <g clip-path="url(#clip0_504_5379)">
                          <path
                              d="M8.79704 4.3375L8.8362 4.37208L11.1279 6.66375C11.1981 6.73436 11.2407 6.8278 11.248 6.92714C11.2553 7.02647 11.2267 7.12512 11.1676 7.20522C11.1084 7.28533 11.0224 7.34157 10.9253 7.36377C10.8282 7.38598 10.7264 7.37266 10.6383 7.32625L9.31662 8.6475L8.72329 10.2296C8.70766 10.2713 8.68543 10.3103 8.65745 10.345L8.62829 10.3783L8.00329 11.0033C7.93149 11.075 7.836 11.118 7.73474 11.1243C7.63348 11.1306 7.5334 11.0997 7.45328 11.0375L7.4137 11.0029L6.24995 9.83958L4.66954 11.4196C4.59455 11.4943 4.49394 11.5377 4.38813 11.5409C4.28231 11.5442 4.17924 11.507 4.09984 11.437C4.02043 11.367 3.97066 11.2694 3.96062 11.164C3.95058 11.0586 3.98103 10.9533 4.04579 10.8696L4.08037 10.8304L5.66037 9.25L4.49704 8.08625C4.42529 8.0145 4.3822 7.91904 4.37583 7.81778C4.36946 7.71652 4.40026 7.61642 4.46245 7.53625L4.49704 7.49708L5.12204 6.87208C5.15347 6.84053 5.18977 6.81422 5.22954 6.79417L5.27037 6.77667L6.85204 6.18292L8.17329 4.86208C8.12823 4.77784 8.11349 4.68068 8.13153 4.58688C8.14957 4.49307 8.1993 4.4083 8.27239 4.34679C8.34547 4.28527 8.43748 4.25074 8.53299 4.24897C8.6285 4.24721 8.72172 4.27873 8.79704 4.3375Z"
                              fill="white"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_504_5379">
                            <rect width="10" height="10" fill="white" transform="translate(2.5 3)"/>
                          </clipPath>
                        </defs>
                      </svg>
                      <spinner :inline="true" v-if="hoverGroupUpdating(slotProps.result)"></spinner>
                    </div>
                  </div>
                </div>
              </template>
            </search>
          </div>
        </div>
      </div>
    </div>
    <div class="floating-footer-toolbar" v-if="editMode">
      <div class="flex align-items-center justify-space-between">
        <div>
          <div class="flex align-items-center">
            <p class="text-gray-50 text-16 font-heavy m-b-none m-r-xs">
              {{ selectedGroups.length }} selected
            </p>
            <spinner v-if="hiding"></spinner>
          </div>
          <p class="text-red-50" v-if="error">
            {{ errorMessage }}
          </p>
        </div>
        <div>
          <button class="btn btn-primary" v-on:click="hideSelected()" :disabled="hiding">Hide</button>
        </div>
        <div>
          <button class="btn btn-default" v-on:click="editMode = false" :disabled="hiding">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Search from '../../shared/search.vue';
import Spinner from './spinner.vue';

export default {
  name: 'messaging',
  components: {
    Search,
    Spinner,
  },
  data() {
    return {
      editMode: false,
      selectedGroups: [],
      hiding: false,
      error: false,
      errorMessage: 'Hiding failed. Please try again or contact support@wrestlingiq.com for assistance.',
      // showHover Menu is a key value based on message group id
      // Each one looks like this { 1: { pinned: true, hovering: false, updating: false } }
      hoverMenuGroups: {},
    };
  },
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    profileId: {
      type: Number,
      required: true,
    },
    profileType: {
      type: String,
      required: true,
    }
  },
  methods: {
    newMessage() {
      window.location.assign('/message_groups/new');
    },
    membershipFor(group) {
      // We need to grab our own membership for the group here based on profile id and type
      return _.find(group.memberships, (membership) => {
        return membership.profile.profile_id === this.profileId && membership.profile.profile_type === this.profileType;
      });
    },
    hasUnread(forGroup) {
      return this.unreadCount(forGroup) > 0;
    },
    unreadCount(forGroup) {
      let membership = this.membershipFor(forGroup);
      if (membership) {
        return membership.unread_count;
      }

      return 0;
    },
    getMenuGroupFor(group, setHoveringTo) {
      let hGroup = this.hoverMenuGroups[group.id];
      if (!hGroup) {
        let membership = this.membershipFor(group);
        hGroup = {
          pinned: membership.pinned,
          hovering: setHoveringTo,
          updating: false
        };
      }

      hGroup.hovering = setHoveringTo;
      return hGroup;
    },
    hideHoverMenuFor(group) {
      let hGroup = this.getMenuGroupFor(group, false);
      this.$set(this.hoverMenuGroups, group.id, hGroup);
    },
    showHoverMenuFor(group) {
      let hGroup = this.getMenuGroupFor(group, true);
      this.$set(this.hoverMenuGroups, group.id, hGroup);
    },
    shouldShowHoverMenuFor(group) {
      let hGroup = this.hoverMenuGroups[group.id];
      return hGroup?.hovering;
    },
    hoverGroupUpdating(group) {
      let hGroup = this.hoverMenuGroups[group.id];
      return hGroup?.updating;
    },
    hoverGroupPinned(group) {
      let hGroup = this.hoverMenuGroups[group.id];
      // Use the membership value if it exists
      if (hGroup) {
        return hGroup.pinned;
      }

      // Otherwise use server side value
      let membership = this.membershipFor(group);
      return membership?.pinned;
    },
    handleTap(event, group) {
      // We want to intercept clicks when we are in edit mode
      if (this.editMode) {
        event.preventDefault();
        // In edit mode, toggle it with special Vue reactivity tricks
        let index = this.selectedGroups.indexOf(group);
        if (index !== -1) {
          this.selectedGroups.splice(index, 1);
        } else {
          this.selectedGroups.push(group);
        }
      }
    },
    hideSelected() {
      this.hiding = true;
      this.error = false;
      let vm = this;
      let promises = [];
      this.selectedGroups.forEach(async (group) => {
        let membership = this.membershipFor(group);
        if (membership) {
          promises.push(this.hideGroupMembership(membership.id));
        }
      });

      Promise.all(promises)
          .then((results) => {
            console.log('finished');
            console.log('results');
            vm.$notificationManager.$emit('show-toast', `Message groups hidden`, true);
            window.location.reload();
          })
          .catch(error => {
            console.log('error');
            console.log(error);
            vm.error = true;
          });
    },
    async hideGroupMembership(membershipId) {
      const params = {
        message_membership: {
          id: membershipId,
          group_visible: false
        }
      };
      return axios.put(this.$apiService.messageMembershipUrl(membershipId), params)
          .then((response) => {
            console.log(`Hid ${membershipId}`);
          })
          .catch((error) => {
            console.error(`Could not hide ${membershipId}`);
          });
    },
    togglePin(group) {
      let membership = this.membershipFor(group);
      let hGroup = this.hoverMenuGroups[group.id];
      if (!hGroup || hGroup?.updating) {
        return;
      }

      hGroup.updating = true;
      hGroup.pinned = !hGroup.pinned;
      this.$set(this.hoverMenuGroups, group.id, hGroup);

      if (membership) {
        const params = {
          message_membership: {
            id: membership.id,
            pinned: hGroup.pinned
          }
        };
        axios.put(this.$apiService.messageMembershipUrl(membership.id), params)
            .then((response) => {
              console.log(`Toggled pinned for ${membership.id}`);
              let pinnedLanguage = hGroup.pinned ? 'Pinned' : 'Unpinned';
              this.$notificationManager.$emit('show-toast', `${pinnedLanguage} ${group.name}`, true);
              // We reload the page to get the updated pinned status in the UI
              // Long term we should do this without a refresh, but for now, this is what it is
              // after a 1 second delay, reload
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              console.error(`Could not toggle pinned for ${membership.id}`);
              hGroup.updating = false;
              hGroup.pinned = !hGroup.pinned; // We also reset the optimistic pinned value here
              this.$set(this.hoverMenuGroups, group.id, hGroup);
              this.$notificationManager.$emit('show-toast', `Failed to toggle pin for ${group.name}`, false);
            });
      }
    }
  },
};
</script>
