<template>
    <li :style="getStyles()">
        <div class="title">{{ block.name }}</div>
    </li>
</template>

<script>
export default {
  name: 'practice-plan-block-category',
  props: [
    'block',
  ],
  methods: {
    getStyles() {
      const vm = this;
      return `border-left: 3px solid ${vm.block.practice_plan_block_category.color}`;
    },
  },
};
</script>
