<template>
    <div class="ibox">
        <div class="ibox-title">
            <div class="row">
                <div class="col-xs-6 col-md-8">
                    <h1>
                        Extended roster (with properties)
                    </h1>
                    <p class="m-b-none"><small>
                        Report generated on {{ processedAt() }}
                    </small></p>
                </div>
                <div class="col-xs-6 col-md-4 text-right">
                    <div class="ibox-tools">
                        <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span class="hidden-xs">Download CSV</span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="ibox-content">
            <div>
                <h1>Wrestlers</h1>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                            <th v-bind:style="secondColStyles">First name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Parent(s)</th>
                            <th>Parent(s) email</th>
                            <th v-for="question in wQuestions">{{ headerForQuestion(question) }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="wp in wrestlers">
                            <td class="first-col"
                                v-bind:style="firstColStyles"
                            >
                                {{ wp.last_name }}
                            </td>
                            <td v-bind:style="secondColStyles">{{ wp.first_name }}</td>
                            <td>{{ emailFor(wp) }}</td>
                            <td>Wrestler</td>
                            <td>{{ guardianNames(wp) }}</td>
                            <td>{{ guardianEmails(wp) }}</td>
                            <td v-for="question in wQuestions" v-html="wrestlerAnswerFor(wAnswers, wp, question, false)">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h1>Parents</h1>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                            <th v-bind:style="secondColStyles">First name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Wrestler(s)</th>
                            <th>Wrestler(s) email</th>
                            <th v-for="question in pQuestions">{{ headerForQuestion(question) }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="pp in parents">
                            <td class="first-col"
                                v-bind:style="firstColStyles"
                            >
                                {{ pp.last_name }}
                            </td>
                            <td v-bind:style="secondColStyles">{{ pp.first_name }}</td>
                            <td>{{ emailFor(pp) }}</td>
                            <td>Parent</td>
                            <td>{{ wrestlerNamesFor(pp) }}</td>
                            <td>{{ wrestlerEmails(pp) }} </td>
                            <td v-for="question in pQuestions" v-html="parentAnswerFor(pAnswers, pp, question, false)">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h1>Coaches</h1>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                            <th v-bind:style="secondColStyles">First name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th v-for="question in cQuestions">{{ headerForQuestion(question) }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="cp in coaches">
                            <td class="first-col"
                                v-bind:style="firstColStyles"
                            >
                                {{ cp.last_name }}
                            </td>
                            <td v-bind:style="secondColStyles">{{ cp.first_name }}</td>
                            <td>{{ emailFor(cp) }}</td>
                            <td>Coach</td>
                            <td v-for="question in cQuestions" v-html="coachAnswerFor(cAnswers, cp, question, false)">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';

export default {
  name: 'registration-answer-report',
  mixins: [downloadMix, formattingMixin],
  props: [
    'report',
    'cloudfrontBaseUrl',
  ],
  data() {
    return {
      wrestlers: [],
      wQuestions: [],
      wAnswers: [],

      parents: [],
      pQuestions: [],
      pAnswers: [],

      coaches: [],
      cQuestions: [],
      cAnswers: [],

      users: [],

      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  mounted() {
    const vm = this;

    const wrestlers = _.get(vm.report, 'result.wrestler_profiles.objects', null);
    vm.wrestlers = _.sortBy(wrestlers, ['last_name']);
    vm.wQuestions = _.get(vm.report, 'result.wrestler_registration_questions.objects', null);
    vm.wAnswers = _.get(vm.report, 'result.wrestler_registration_answers.objects', null);

    const parents = _.get(vm.report, 'result.parent_profiles.objects', null);
    vm.parents = _.sortBy(parents, ['last_name']);
    vm.pQuestions = _.get(vm.report, 'result.parent_registration_questions.objects', null);
    vm.pAnswers = _.get(vm.report, 'result.parent_registration_answers.objects', null);

    const coaches = _.get(vm.report, 'result.coach_profiles.objects', null);
    vm.coaches = _.sortBy(coaches, ['last_name']);
    vm.cQuestions = _.get(vm.report, 'result.coach_registration_questions.objects', null);
    vm.cAnswers = _.get(vm.report, 'result.coach_registration_answers.objects', null);

    vm.users = _.get(vm.report, 'result.users.objects', null);

    vm.$nextTick(function () {
      const header = this.$refs.lnameHeader;
      if (header) {
        const rect = header.getBoundingClientRect();
        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    wrestlerNamesFor(parent) {
      let wrestlers = _.filter(this.wrestlers, function(wp) {
        return _.includes(_.map(wp.guardians, 'id'), parent.id);
      });

      return _.join(_.map(wrestlers, 'full_name'));
    },
    emailFor(profile) {
      const user = _.find(this.users, ['id', profile.user_id]);
      if (user) {
        return user.email;
      }

      return '';
    },
    guardianNames(wrestler) {
      return _.join(_.map(wrestler.guardians, 'full_name'));
    },
    wrestlerEmails(parent) {
      let wrestlers = _.filter(this.wrestlers, function(wp) {
        return _.includes(_.map(wp.guardians, 'id'), parent.id);
      });
      let userIds = _.map(wrestlers, 'user_id');
      let users = _.filter(this.users, function(u) { return _.includes(userIds, u.id) });
      return _.join(_.map(users, 'email'));
    },
    guardianEmails(wrestler) {
      let parentIds = _.map(wrestler.guardians, 'id')
      let parents = _.filter(this.parents, function(p) { return _.includes(parentIds, p.id); });
      let userIds = _.map(parents, 'user_id');
      let users = _.filter(this.users, function(u) { return _.includes(userIds, u.id) });
      return _.join(_.map(users, 'email'));
    },
    headerForQuestion(question) {
      return `${question.prompt} (${question.group_with})`;
    },
    coachAnswerFor(cAnswers, profile, question, isCSV) {
      const vm = this;
      const answer = _.find(cAnswers, {
        profile_id: profile.id,
        profile_type: 'CoachProfile',
        registration_question_id: question.id,
      });

      return vm.formattedAnswer(answer, question, isCSV);
    },
    parentAnswerFor(pAnswers, profile, question, isCSV) {
      const vm = this;
      const answer = _.find(pAnswers, {
        profile_id: profile.id,
        profile_type: 'ParentProfile',
        registration_question_id: question.id,
      });

      return vm.formattedAnswer(answer, question, isCSV);
    },
    wrestlerAnswerFor(wAnswers, profile, question, isCSV) {
      const vm = this;
      const answer = _.find(wAnswers, {
        profile_id: profile.id,
        profile_type: 'WrestlerProfile',
        registration_question_id: question.id,
      });

      return vm.formattedAnswer(answer, question, isCSV);
    },
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    downloadWrestlerCSV() {
      const vm = this;
      const fileName = `Wrestler Extended Roster Report - ${vm.processedAt()}.csv`;

      let rows = [];
      rows.push(vm.getHeaderCSVRows([
        'Last name',
        'First name',
        'Email',
        'Parent(s)',
        'Parent(s) email',
        'Role',
      ], vm.wQuestions));

      rows = _.concat(rows, vm.getBodyCSVRows('Wrestler', vm.wrestlers, vm.wQuestions, vm.wAnswers, vm.wrestlerAnswerFor));
      this.downloadCSVFile(fileName, rows)
    },
    downloadParentCSV() {
      const vm = this;
      const fileName = `Parent Extended Roster Report - ${vm.processedAt()}.csv`;

      let rows = [];
      rows.push(vm.getHeaderCSVRows([
        'Last name',
        'First name',
        'Email',
        'Wrestler(s)',
        'Wrestler(s) email',
        'Role',
      ], vm.pQuestions));

      rows = _.concat(rows, vm.getBodyCSVRows('Parent', vm.parents, vm.pQuestions, vm.pAnswers, vm.parentAnswerFor));
      this.downloadCSVFile(fileName, rows);
    },
    downloadCoachCSV() {
      const vm = this;
      const fileName = `Coach Extended Roster Report - ${vm.processedAt()}.csv`;

      let rows = [];
      rows.push(vm.getHeaderCSVRows([
        'Last name',
        'First name',
        'Email',
        'Role',
      ], vm.cQuestions));

      rows = _.concat(rows, vm.getBodyCSVRows('Coach', vm.coaches, vm.cQuestions, vm.cAnswers, vm.coachAnswerFor));
      this.downloadCSVFile(fileName, rows);
    },
    getBodyCSVRows(role, profiles, questions, answers, retrievalFunction) {
      const vm = this;
      const rows = [];
      profiles.forEach((p) => {
        let coreRows = [
          p.last_name,
          p.first_name,
          vm.emailFor(p),
        ];
        if (role === 'Wrestler') {
          coreRows.push(vm.guardianNames(p));
          coreRows.push(vm.guardianEmails(p));
        } else if (role === 'Parent') {
          coreRows.push(vm.wrestlerNamesFor(p));
          coreRows.push(vm.wrestlerEmails(p));
        }

        coreRows.push(role);
        rows.push(_.concat(coreRows, _.map(questions, (q) => retrievalFunction(answers, p, q, true))));
      });
      return rows;
    },
    getHeaderCSVRows(core, questions) {
      return _.concat(core, _.flatMap(questions, this.headerForQuestion));
    },
    downloadCSV() {
      const vm = this;
      vm.downloadWrestlerCSV();
      vm.downloadParentCSV();
      vm.downloadCoachCSV();
    },
  },
};
</script>
