<template>
  <div id="scouting-card">
    <div class="label label-action"><i class="fa fa-binoculars m-r-xxs"></i>Scouting cards are shared across all members of your team and the coaching staff.</div>
    <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
    <div class="card-header">
      <h2>
        <a :href="getOpponentLink()">{{ opponentName }}</a>
      </h2>
      <p>
        <a :href="getOpposingTeamLink()">{{ opposingTeamName }}</a><br/>
      </p>
    </div>
    <div class="flex">
        <spinner v-if="loading"></spinner>
    </div>
    <div v-if="resultsLoaded">
      <div v-for="(question, index) in results" :key="question.id" :class="{ 'm-t-xl': index !== 0, 'm-t-sm': index === 0 }">
        <scouting-question :question="question" :currentProfileType="currentProfileType" :currentProfileId="currentProfileId" :opposingWrestlerId="opponentId">
        </scouting-question>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from './spinner.vue';
import ScoutingQuestion from './scouting_question.vue';

export default {
  components: {
    Spinner,
    ScoutingQuestion,
  },
  name: 'scouting-card',
  props: [
    'opponentId',
    'opponentName',
    'weightClass',
    'opposingTeamId',
    'opposingTeamName',
    'currentProfileId',
    'currentProfileType',
    'helpText',
  ],
  // This is a dual system
  // The Client should cache or look up the scouting questions first.
  // Then should look up the scouting answers for this particular opponent.
  data() {
    return {
      loading: false,

      page: 1,
      perPage: 30,

      resultsLoaded: false,
      results: [],

      error: false,
      errorMessage: '',
    };
  },
  created() {
    const vm = this;
    vm.getQuestions();
    vm.$notificationManager.$on('scouting-answer-created', vm.answerCreated);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('scouting-answer-created', vm.answerCreated);
  },
  methods: {
    answerCreated(opponentId) {
      const vm = this;
      if (opponentId === vm.opponentId) {
        vm.getQuestions();
      }
    },
    getOpposingTeamLink() {
      return `/opposing_teams/${this.opposingTeamId}`;
    },
    getOpponentLink() {
      return `/opposing_teams/${this.opposingTeamId}/opposing_wrestlers/${this.opponentId}`;
    },
    getQuestionId(question) {
      return `question_${question.id}`;
    },
    getPageParams(page, perPage) {
      return `page=${page}&per_page=${perPage}`;
    },
    getQuestions() {
      const vm = this;
      vm.resetScoutingCard();
      vm.loading = true;
      const questionsUrl = vm.$apiService.scoutingQuestionsUrl();
      axios.get(questionsUrl)
        .then((response) => {
          vm.loading = false;
          vm.results = response.data.scouting_questions;
          vm.getAnswers();
        })
        .catch((error) => {
          vm.resetScoutingCard();
          vm.errorMessage = 'Error retrieving scouting questions';
          vm.error = true;
        });
    },
    // This should only be called once questions are loaded
    getAnswers() {
      const vm = this;

      vm.loading = true;
      const answersUrl = vm.$apiService.opponentScoutingAnswersUrl(vm.opponentId);
      axios.get(answersUrl)
        .then((response) => {
          vm.loading = false;
          const answers = response.data.scouting_answers;
          vm.showAnswersWithQuestions(answers);
        })
        .catch((error) => {
          vm.resetScoutingCard();
          console.log(error);
          vm.errorMessage = 'Error retrieving scouting answers';
          vm.error = true;
        });
    },
    showAnswersWithQuestions(answers) {
      const vm = this;
      for (let i = 0; i < answers.length; i++) {
        const answer = answers[i];
        vm.addAnswerToQuestion(answer);
      }
      vm.resultsLoaded = true;
    },
    addAnswerToQuestion(answer) {
      let question = null;
      const vm = this;
      for (let i = 0; i < vm.results.length; i++) {
        const possibleQ = vm.results[i];
        if (possibleQ.id === answer.scouting_question_id) {
          question = possibleQ;
          break;
        }
      }

      if (question) {
        if (!('userAnswers' in question)) {
          question.userAnswers = [];
        }

        question.userAnswers.push(answer);
      }
    },
    resetScoutingCard() {
      const vm = this;
      vm.loading = false;

      vm.page = 1;
      vm.perPage = 30;

      vm.resultsLoaded = false;
      vm.results = [];

      vm.error = false;
      vm.errorMessage = '';
    },
  },
};
</script>
