<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="row">
        <div class="col-xs-6 col-md-8">
          <h1>
            {{ report.result.roster_name }} USA Wrestling Report
          </h1>
          <p class="m-b-none"><small>
            Report generated on {{ processedAt() }}
          </small></p>
        </div>
        <div class="col-xs-6 col-md-4 text-right">
          <div class="ibox-tools">
            <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span class="hidden-xs">Download CSV</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
              <th v-bind:style="secondColStyles">First name</th>
              <th>USAW ID #</th>
              <th>Membership type</th>
              <th>Expires on</th>
              <th>Card upload</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in rows">
              <td class="first-col"
                  v-bind:style="firstColStyles"
              >
                {{ row.last_name }}
              </td>
              <td v-bind:style="secondColStyles">{{ row.first_name }}</td>
              <td>{{ row.usaw_id }}</td>
              <td>{{ row.membership_type }}</td>
              <td>{{ row.expires_on }}</td>
              <td>
                <template v-if="row.url && row.url !== ''">
                  <a target="_blank" :href="row.url"><i class="fa fa-file-text-o"></i></a>
                </template>
              </td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadMix } from '../mixins/download_mix';

export default {
  name: 'usaw-report',
  mixins: [downloadMix],
  props: [
    'report',
  ],
  data() {
    return {
      rows: [],

      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  mounted() {
    const vm = this;

    const wrestlers = _.sortBy(_.get(vm.report, 'result.wrestler_profiles.objects', null), ['last_name']);
    const usawIds = _.get(vm.report, 'result.usaw_ids.objects', null)

    let rows = [];
    wrestlers.forEach((wrestler) => {
      let idsForWp = _.filter(usawIds, ['profile.id', wrestler.id]);
      let usawId = _.last(_.sortBy(idsForWp, ['expires_on']));

      const r = {
        last_name: wrestler.last_name,
        first_name: wrestler.first_name,
        usaw_id: usawId?.public_id,
        membership_type: usawId?.membership_type,
        expires_on: usawId?.expires_on,
        url: usawId?.id_card?.url,
      };
      rows.push(r);
    });
    vm.rows = rows;

    vm.$nextTick(function () {
      const header = this.$refs.lnameHeader;
      if (header) {
        const rect = header.getBoundingClientRect();
        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    downloadCSV() {
      const vm = this;

      const csvRows = [[
        'Last name',
        'First name',
        'USAW ID #',
        'Membership type',
        'Expires on',
        'Card upload',
      ]];

      vm.rows.forEach((r) => {
        csvRows.push([
            r.first_name,
            r.last_name,
            r.usaw_id,
            r.membership_type,
            r.expires_on,
            r.url
        ]);
      })

      const fileName = `${vm.report.result.roster_name} USA Wrestling Report - ${vm.processedAt()}.csv`;
      this.downloadCSVFile(fileName, csvRows);
    },
  },
};
</script>
