<template>
  <div class="vertical-timeline-block">
    <div class="vertical-timeline-icon" :style="getIconStyles()">
    </div>
    <div class="vertical-timeline-content practice-card yellow">
      <div class="should-dim">
        <div class="timeline-dragger">
          <i :class="getCollapseIcon()" v-on:click="toggleCollapse()"></i>
          <i class="fa fa-grip-large block-sorter" v-if="!readOnly"></i>
        </div>
        <div class="details">
          <h2>{{ blockName() }}</h2>
        </div>
        <div class="specifics" v-show="!collapsed">
          <draggable :element="'ul'" class="sortable-list agile-list no-hover" v-model="blockSections" :group="sortGroupName"
                     :options="getDraggableOptions()">
            <practice-plan-section
                v-for="(section, index) in blockSections"
                :key="index"
                v-bind:section-index="index"
                v-bind:block-id="block.id"
                v-bind:block-index="blockIndex"
                v-bind:section="section"
                v-bind:read-only="readOnly"
                v-bind:border-color="category().color"></practice-plan-section>
              <li v-if="!readOnly" class="add-card" v-on:click="addSection()">
                <div class="title">
                  <a>Add Section</a>
                </div>
              </li>
          </draggable>
        </div>
      </div>
      <div class="timeline-footer should-dim">
        <div class="pull-left">
          <div v-if="collapsed">
            {{ blockSections.length }} sections
          </div>
          <div>
            <i class="fa fa-clock-o"></i> {{ totalBlockMinutes() }} minutes<br/>
            <slot name="runtime"></slot>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="pull-left m-t-xs" v-if="!readOnly">
          <a v-on:click="showTemplateForm()">
            {{ templateCta() }}
          </a>
          <VDropdown theme="info-tooltip">
            <!-- This will be the popover target (for the events and position) -->
            <i class="fa fa-question"></i>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>
                <p class="header">
                  Saved Blocks
                </p>
                <p>
                  Saved blocks allow you to reuse blocks in the practice plan builder across practices,
                  rather than needing to build the same block repeatedly.
                </p>
                <p>
                  For example, you might create a saved block for a common warm-up (e.g. tumbling), or for a favorite
                  drill
                  (e.g. 2 min of your best shot, 2 min of second best, etc).
                </p>
              </div>
            </template>
          </VDropdown>
        </div>
        <div class="right pull-right" v-if="!readOnly">
          <a class="text-danger" v-on:click="removeBlock()">Remove</a>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="template-save" v-if="templateSaveForm">
        <practice-plan-block-template-form :block="block"></practice-plan-block-template-form>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import PracticePlanSection from './practice_plan_section.vue';
import PracticePlanBlockTemplateForm from './practice_plan_block_template_form.vue';

export default {
  components: {
    PracticePlanBlockTemplateForm,
    draggable,
    PracticePlanSection,
  },
  name: 'practice-plan-block',
  props: [
    'planId',
    'block',
    'readOnly',
    'blockIndex',
  ],
  data() {
    return {
      templateSaveForm: false,
      collapsed: false,
    };
  },
  computed: {
    sortGroupName() {
      return `section-group-${this.blockIndex}`;
    },
    blockSections: {
      get() {
        return this.block.sections;
      },
      set(newValue) {
        for (let i = 0; i < newValue.length; i++) {
          const section = newValue[i];
          section.order = i;
        }
        this.$set(this.block, 'sections', newValue);
        this.$notificationManager.$emit('plan-modified', this.planId);
      },
    },
  },
  mounted() {
    const vm = this;
    vm.$notificationManager.$on('remove-section', vm.removeSectionHandler);
    vm.$notificationManager.$on('plan-section-modified', vm.planSectionModified);
    vm.$notificationManager.$on('collapse-all-blocks', vm.collapseBlocks);
    vm.$notificationManager.$on('expand-all-blocks', vm.expandBlocks);
    vm.$notificationManager.$on('reload-block-with-template-id', vm.reloadBlock);

    vm.$on('block-template-updated', vm.handleUpdatedTemplate);
    vm.$on('template-form-hidden', vm.hideTemplateForm);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('remove-section', vm.removeSectionHandler);
    vm.$notificationManager.$off('plan-section-modified', vm.planSectionModified);
    vm.$notificationManager.$off('collapse-all-blocks', vm.collapseBlocks);
    vm.$notificationManager.$off('expand-all-blocks', vm.expandBlocks);
    vm.$notificationManager.$off('reload-block-with-template-id', vm.reloadBlock);

    vm.$off('template-form-hidden', vm.hideTemplateForm);
  },
  methods: {
    templateCta() {
      const vm = this;
      if (vm.block.template_id) {
        return 'Update saved block...';
      }

      return 'Save block...';
    },
    handleUpdatedTemplate(block, created) {
      const vm = this;
      if (created) {
        // in this case I'm not mutating the block directly, but it updates ok
        const updatedBlock = vm.block;
        updatedBlock.sections = block.sections;
        updatedBlock.name = block.name;
        updatedBlock.template_id = block.id;
        updatedBlock.just_replaced = true;
        updatedBlock.id = null;
        vm.$notificationManager.$emit('replace-block', vm.blockIndex, updatedBlock);
        vm.hideTemplateForm();

        // this should then trigger the updating of other blocks with this template-id
        vm.$notificationManager.$emit('reload-block-with-template-id', updatedBlock.template_id, updatedBlock);
      } else {
        // in this case the block already existed, but the template has now updated
        const updatedBlock = vm.block;

        updatedBlock.sections = block.sections;
        updatedBlock.name = block.name;
        updatedBlock.just_replaced = true;
        updatedBlock.id = null;

        const templateId = vm.block.template_id;

        // this should then trigger the updating of this block
        vm.$notificationManager.$emit('replace-block', vm.blockIndex, updatedBlock);
        vm.hideTemplateForm();

        // this should trigger the updating of other blocks
        vm.$notificationManager.$emit('reload-block-with-template-id', templateId, updatedBlock);
      }
    },
    reloadBlock(templateId, updatedBlock) {
      const vm = this;
      if (vm.block.template_id === templateId && !vm.block.just_replaced) {
        vm.$notificationManager.$emit('replace-block', vm.blockIndex, updatedBlock);
      }
    },
    showTemplateForm() {
      this.templateSaveForm = true;
      this.$notificationManager.$emit('form-shown', 'PracticePlanBlock');
    },
    hideTemplateForm() {
      this.templateSaveForm = false;
      this.$notificationManager.$emit('form-hidden', 'PracticePlanBlock');
    },
    expandBlocks(planId) {
      const vm = this;
      if (vm.planId === planId) {
        vm.collapsed = false;
      }
    },
    collapseBlocks(planId) {
      const vm = this;
      if (vm.planId === planId) {
        vm.collapsed = true;
      }
    },
    planSectionModified(section, blockIndex) {
      const vm = this;
      if (vm.blockIndex === blockIndex) {
        vm.$notificationManager.$emit('plan-modified', vm.planId);
      }
    },
    removeSectionHandler(section, sectionIndex, blockIndex) {
      const vm = this;
      // Make sure we are the correct block to be doing the removing
      if (vm.blockIndex === blockIndex) {
        const { sections } = vm.block;
        sections.splice(sectionIndex, 1);
        vm.$set(vm.block, 'sections', sections);
        vm.$notificationManager.$emit('plan-modified', vm.planId);
      }
    },
    getCollapseIcon() {
      const readOnlyClass = this.readOnly ? ' read-only' : '';
      if (this.collapsed) {
        return `fa fa-angle-up collapse-arrow${readOnlyClass}`;
      }
      return `fa fa-angle-down collapse-arrow${readOnlyClass}`;
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    totalBlockMinutes() {
      const vm = this;
      let totalMinutes = 0;
      vm.block.sections.forEach((section) => {
        totalMinutes += section.minutes;
      });
      return totalMinutes;
    },
    getDraggableOptions() {
      const vm = this;
      return { group: { name: `block-${vm.block.id}` }, handle: '.section-sorter', sort: true };
    },
    blockName() {
      const vm = this;
      if (vm.block.name) {
        return vm.block.name;
      }

      return vm.block.practice_plan_block_category.name;
    },
    category() {
      return this.block.practice_plan_block_category;
    },
    getIconStyles() {
      return `color: #fffff; background-color: ${this.category().color}`;
    },
    addSection() {
      const vm = this;
      vm.block.sections.push({
        id: null,
        name: null,
        details: null,
        order: vm.block.sections.length,
        minutes: null,
      });
      vm.$notificationManager.$emit('plan-modified', vm.planId);
    },
    removeBlock() {
      const vm = this;
      vm.$notificationManager.$emit('remove-block', vm.block, vm.planId);
      vm.$notificationManager.$emit('plan-modified', vm.planId);
    },
  },
};
</script>
