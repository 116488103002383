<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="row">
        <div class="col-xs-6 col-md-8">
          <h1>
            {{ report.result.fundraiser.name }} Summary Report
          </h1>
          <p class="m-b-none"><small>
            Report generated on {{ processedAt() }}
          </small></p>
        </div>
        <div class="col-xs-6 col-md-4 text-right">
          <div class="ibox-tools">
            <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span
                class="hidden-xs">Download CSV</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Name</th>
              <th v-bind:style="secondColStyles">Email</th>
              <th>Phone</th>
              <th>Business name</th>
              <th>Paid at</th>
              <th>Total</th>
              <th>Items</th>
              <th>Receipt url</th>
              <th>Billing partner id</th>
              <th>Street address</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th v-for="question in extraQuestions">
                {{ question.title }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in rows">
              <td class="first-col"
                  v-bind:style="firstColStyles">
                {{ row.name }}
              </td>
              <td v-bind:style="secondColStyles">
                {{ row.email }}
              </td>
              <td>
                {{ row.phone }}
              </td>
              <td>
                {{ row.business_name }}
              </td>
              <td>
                {{ row.paid_at }}
              </td>
              <td>
                {{ row.total }}
              </td>
              <td>
                {{ row.items }}
              </td>
              <td>
                <template v-if="row.receipt_url !== ''">
                  <a target="_blank" :href="row.receipt_url"><i class="fa fa-file-text-o"></i></a>
                </template>
              </td>
              <td>
                {{ row.billing_partner_id }}
              </td>
              <td>
                {{ row.street_address }}
              </td>
              <td>
                {{ row.city }}
              </td>
              <td>
                {{ row.state }}
              </td>
              <td>
                {{ row.zip }}
              </td>
              <td v-for="question in extraQuestions">
                {{ row[question.title] }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';

export default {
  name: 'fundraiser-summary-report',
  mixins: [downloadMix, formattingMixin],
  props: [
    'report',
  ],
  data() {
    return {
      rows: [],
      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },
      // Stored in metadata
      extraQuestions: [],
    };
  },
  mounted() {
    const vm = this;

    const invoices = _.get(vm.report, 'result.manual_invoices.objects', null);
    const customerMap = _.keyBy(vm.report.result.customers, 'id');
    const chargeMap = _.keyBy(vm.report.result.charges, 'chargeable_id');
    this.extraQuestions = _.get(vm.report, 'result.fundraiser.metadata.additional_questions', []);
    vm.rows = [];
    invoices.forEach((invoice) => {
      const customer = customerMap[_.get(invoice, 'billing_profile.billable.id', '')];
      const paidAt = invoice.paid_at ? moment(invoice.paid_at)
          .format('M/D/YYYY, hh:mm a') : '';
      const items = _.join(_.map(invoice.invoice_items, 'description'), ', ');
      const charge = chargeMap[invoice.id];

      const row = {
        name: _.get(invoice, 'billing_profile.billable.display_name', ''),
        email: _.get(invoice, 'billing_profile.email', ''),
        phone: _.get(customer, 'phone_number', ''),
        business_name: _.get(customer, 'business_name', ''),
        paid_at: paidAt,
        items: items,
        total: vm.formatAsCurrency(invoice.amount_paid),
        receipt_url: _.get(invoice, 'receipt.cloudfront_url', ''),
        billing_partner_id:  _.get(charge, 'billing_partner_id', null) || _.get(charge, 'stripe_id', ''),
        street_address: _.get(customer, 'address_line_1', ''),
        city: _.get(customer, 'city', ''),
        state: _.get(customer, 'state', ''),
        zip: _.get(customer, 'zip', ''),
      };
      const answers = invoice.metadata?.additional_questions

      if (vm.extraQuestions.length > 0 && answers) {
        // Loop through the questions and pull them in
        vm.extraQuestions.forEach((q) => {
          row[q.title] = _.find(answers, ['title', q.title])?.answer ?? ""
        })
      }
      vm.rows.push(row);
    });

    vm.rows = _.sortBy(vm.rows, ['name']);

    vm.$nextTick(function () {
      const header = this.$refs.lnameHeader;
      if (header) {
        const rect = header.getBoundingClientRect();

        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at)
          .format('M/D/YYYY, hh:mm a');
    },
    downloadCSV() {
      const vm = this;
      let rows = [];
      let headers = [
        'Name',
        'Email',
        'Phone',
        'Business name',
        'Paid at',
        'Total',
        'Items',
        'Receipt url',
        'Billing partner id',
        'Street address',
        'City',
        'State',
        'Zip'
      ]
      headers = _.concat(headers, _.map(vm.extraQuestions, 'title'))
      rows.push(headers);

      const rowsToArray = _.map(vm.rows, (r) => { return _.values(r) });
      rows = _.concat(rows, rowsToArray);

      const fileName = `${vm.report.result.fundraiser.name} Summary Report - ${vm.processedAt()}.csv`;
      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
