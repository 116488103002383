<template>
  <div>
    <div class="btn-group btn-group-justified">
      <div class="btn-group" role="group">
        <button class="btn btn-compact" :class="{ 'btn-primary': viewingWrestlers, 'btn-white': !viewingWrestlers }" type="button" @click="viewing = 'wrestlerprofile'">
          Wrestlers ({{ wrestlerAcceptedCount }})
        </button>
      </div>
      <div class="btn-group" role="group">
        <button class="btn btn-compact" :class="{ 'btn-primary': viewingParents, 'btn-white': !viewingParents }" type="button" @click="viewing = 'parentprofile'">
          Parents ({{ parentAcceptedCount }})
        </button>
      </div>
      <div class="btn-group" role="group">
        <button class="btn btn-compact" :class="{ 'btn-primary': viewingCoaches, 'btn-white': !viewingCoaches }" type="button" @click="viewing = 'coachprofile'">
          Coaches ({{ coachAcceptedCount }})
        </button>
      </div>
    </div>
    <div class="m-t-sm">
        <event-invite-list :key="acceptedKey" :for-profile-type="viewing" title="Accepted" status="accepted" :event-id="eventId" :default-open="true"></event-invite-list>
        <event-invite-list :key="declinedKey" :for-profile-type="viewing" title="Declined" status="declined" :event-id="eventId"></event-invite-list>
        <event-invite-list :key="pendingKey" :for-profile-type="viewing" title="Pending" status="pending" :event-id="eventId"></event-invite-list>
    </div>
  </div>
</template>
<script>
import EventInviteList from './event_invite_list.vue'
export default {
  name: 'event-invite-overview',
  components: {
    EventInviteList
  },
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    coachesRsvped: {
      type: Number,
      default: null,
    },
    parentsRsvped: {
      type: Number,
      default: null,
    },
    wrestlersRsvped: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      wrestlerAcceptedCount: null,
      parentAcceptedCount: null,
      coachAcceptedCount: null,
      // wrestlerprofile, parentprofile, coachprofile
      viewing: 'wrestlerprofile',
      // Lets us refresh the list when needed
      refreshKey: Date.now().toString(),
    }
  },
  computed: {
    // keys so vue actually re-renders this when btn group changes
    acceptedKey() {
      return this.viewing + 'accepted' + this.refreshKey;
    },
    declinedKey() {
      return this.viewing + 'declined' + this.refreshKey;
    },
    pendingKey() {
      return this.viewing + 'pending' + this.refreshKey;
    },
    viewingWrestlers() {
      return this.viewing === 'wrestlerprofile';
    },
    viewingParents() {
      return this.viewing === 'parentprofile';
    },
    viewingCoaches() {
      return this.viewing === 'coachprofile';
    }
  },
  created() {
    this.wrestlerAcceptedCount = this.wrestlersRsvped || 0;
    this.parentAcceptedCount = this.parentsRsvped || 0;
    this.coachAcceptedCount = this.coachesRsvped || 0;
    this.$notificationManager.$on('event-invite-changed', this.changeCount);
  },
  destroyed() {
    this.$notificationManager.$off('event-invite-changed', this.changeCount);
  },
  methods: {
    changeCount(profileType, amount) {
      switch (profileType.toLowerCase()) {
        case 'coachprofile':
          this.coachAcceptedCount = this.coachAcceptedCount + amount;
          break;
        case 'parentprofile':
          this.parentAcceptedCount = this.parentAcceptedCount + amount;
          break;
        case 'wrestlerprofile':
          this.wrestlerAcceptedCount = this.wrestlerAcceptedCount + amount;
          break;
        default:
          console.log('no profile type found');
      }

      this.refreshKey = Date.now().toString();
    },
  }
};
</script>
