<template>
    <div>
        <div class="m-t-lg">
            <div v-show="!showForm">
                <button @click.prevent="toggleForm()" class="btn-primary btn"><i class="fa fa-plus"></i> Invite Team</button>
            </div>
            <div v-show="showForm">
                <div class="team-match-invite" role="form">
                    <div class="form-group">
                        <label>Invite Opposing Team</label>
                        <div v-if="opposingTeam">
                            <div class="pull-left m-l-sm">
                                <h3>
                                    {{ opposingTeam.name }}
                                </h3>
                                <p class='search-type-label'>
                                    {{ opposingTeam.conference }}
                                </p>
                                <small class="no-l-pad">
                                    <a @click="opposingTeam = null">Change...</a>
                                </small>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <search v-show="!opposingTeam"
                                readable-model-name="opposing team"
                                :mutate-history="false"
                                :allow-add="true"
                                class="search-form"
                                response-key="teams"
                                placeholder="Type opposing team name..."
                                base-url="/api/v1/opposing_teams">
                            <template v-slot:list="slotProps">
                                <div class="owrestler-oteam-wrapper" @click="opposingTeamClicked(slotProps.result)">
                                    <opposing-team-search-result
                                            v-bind:result="slotProps.result"
                                            v-bind:is-selectable="true"
                                            v-bind:key="slotProps.result.id"
                                    >
                                    </opposing-team-search-result>
                                </div>
                            </template>
                            <template v-slot:addform="slotProps">
                                <opposing-team-create
                                        v-bind:name="slotProps.eventName"
                                >
                                </opposing-team-create>
                            </template>
                        </search>
                    </div>
                    <template v-if="opposingTeam">
                        <template v-if="!activeTeam" class="form-group">
                            <div class="alert alert-info">
                                Looks like you haven't tracked a match in WrestlingIQ with {{ opposingTeam.name }} before.<br/>
                                In order to get the pairings setup, enter their email below so we can invite them to this match.
                            </div>
                            <div class="form-group">
                                <label>{{ opposingTeam.name}}'s Email</label>
                                <input v-model="email" type="text" class="form-control">
                            </div>
                        </template>
                        <template v-else-if="activeTeam">
                            <div class="alert alert-success">
                                You've previously tracked a match in WrestlingIQ with {{ opposingTeam.name }}.
                            </div>
                        </template>
                        <div class="button-area">
                            <div v-show="error">
                                <small>
                                    <span class="text-danger">Something went wrong with the invite, unable to send right now.</span>
                                </small>
                            </div>
                            <button v-show="!loading" type="button" v-on:click="toggleForm" class="btn btn-white">Cancel</button>
                            <ladda-button @lbClicked="postMatchInvite" el-class="btn-primary" :loading="loading">
                                Send Invite
                            </ladda-button>

                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Search from '../../shared/search.vue';
import OpposingTeamSearchResult from './opposing_team_search_result.vue';
import OpposingTeamCreate from './opposing_team_create.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'team-match-invite',
  components: {
    LaddaButton, OpposingTeamCreate, OpposingTeamSearchResult, Search,
  },
  props: [
    'eventId',
  ],
  data() {
    return {
      showForm: false,
      loading: false,
      error: false,

      opposingTeam: null,
      activeTeam: false,
      email: '',
    };
  },
  created() {
    const vm = this;
    vm.$notificationManager.$on('opposing-team-created', vm.opposingTeamClicked);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('opposing-team-created', vm.opposingTeamClicked);
  },
  watch: {
    // whenever opposingTeam changes, this function will run
    opposingTeam(newOpposingTeam) {
      if (newOpposingTeam && newOpposingTeam.active_team_id && this.opposingTeam) {
        this.activeTeam = true;
      } else {
        this.activeTeam = false;
      }
    },
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    opposingTeamClicked(opposingTeam) {
      this.opposingTeam = opposingTeam;
    },
    validate() {
      // match invites are valid if there is an active team, or an email + opposing team
      const vm = this;

      vm.error = false;

      if (vm.activeTeam) {
        return true;
      }

      if (vm.email.length > 0 && vm.opposingTeam) {
        return true;
      }
      vm.errorMessage = 'Please enter required info';
      vm.error = true;

      return false;
    },
    params() {
      const vm = this;
      if (vm.activeTeam) {
        return {
          match_invite: {
            team_id: vm.opposingTeam.active_team_id,
          },
        };
      }

      return {
        match_invite: {
          email: vm.email,
          opposing_team_id: vm.opposingTeam.id,
        },
      };
    },
    postMatchInvite: _.throttle(function () {
      const vm = this;
      if (vm.loading) {
        return;
      }

      if (!vm.validate()) {
        return;
      }

      vm.loading = true;
      const url = vm.$apiService.eventMatchInvitesUrl(vm.eventId);

      axios.post(url, vm.params())
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit('show-toast', 'Match invites sent', true);
          vm.$notificationManager.$emit('match-invite-created');

          // Hide the form, reset it's data
          vm.toggleForm();
          vm.error = false;
          vm.opposingTeam = null;
          vm.activeTeam = false;
          vm.email = '';
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error saving match invite';
          vm.error = true;
        });
      // validate etc post
    }, 500),
  },
};
</script>
