<template>
  <div class="row">
    <div class="col-md-12">
      <div class="ibox">
        <div class="ibox-title">
          <h1>Bulk Update Wrestlers</h1>
        </div>
        <div class="ibox-content">
          <div v-if="uploadState === 'setup'">
            <h2>
              <span class="font-heavy">Step 1.</span> Select the properties you
              want to bulk update
            </h2>
            <div class="grid sm-grid-cols-1 grid-cols-2 m-t-md">
              <div>
                <h3>WIQ Properties</h3>
                <div v-for="property in coreColumns">
                  <label class="font-normal">
                    <input
                      type="checkbox"
                      v-model="property.selected"
                      style="margin-right: 4px"
                    />
                    {{ property.name }}
                  </label>
                </div>
              </div>
              <div>
                <h3 class="m-t-m">Custom private properties</h3>
                <spinner
                  v-if="loadingPrivateWrestlerProperties"
                  :inline="true"
                ></spinner>
                <div v-for="(questions, groupName) in privateQuestionsByGroup">
                  <h4 class="font-semi">{{ groupName }}</h4>
                  <div class="flex flex-col">
                    <label
                      v-for="question in questions"
                      :key="question.id"
                      class="font-normal"
                    >
                      <input
                        type="checkbox"
                        v-model="question.selected"
                        style="margin-right: 4px"
                      />
                      {{ question.prompt }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                @click.prevent="uploadState = 'upload'"
                class="btn btn-primary"
              >
                Next: Upload a CSV
              </button>
            </div>
          </div>
          <div v-if="uploadState === 'upload'">
            <h2><span class="font-heavy">Step 2.</span> Upload a CSV file</h2>
            <p>
                Please create a spreadsheet that matches these columns and column names.
              <strong>Your header rows must match the column names below (case sensitive).</strong>
            </p>
            <ul>
              <li v-for="expectedHeader in allFields">
                {{ expectedHeader }}
              </li>
            </ul>
            <div class="m-t-md">
              <input type="file" id="files" />
              <ladda-button
                @lbClicked="parseFile"
                el-class="btn-primary m-t-lg"
                :loading="fileParsing"
              >
                Upload .csv
              </ladda-button>
              <div v-if="error">
                <small class="text-danger">
                  {{ errorMessage }}
                </small>
              </div>
            </div>
          </div>
            <div v-if="uploadState === 'review'">
                    <div>
                        <h2><span class="font-heavy">Step 3.</span> Verify & Update</h2>
                    </div>
                    <div class="alert-wrappers">
                        <div v-if="validFieldsFound.length > 0" class="alert alert-success">
                            <strong>{{ arrayToStr(validFieldsFound) }}</strong> found in file.
                        </div>
                        <div v-if="ignoredFields.length > 0" class="alert alert-warning">
                            <strong>{{ arrayToStr(ignoredFields) }}</strong> found in file, but is not used in the import. These
                            columns are being ignored.
                        </div>
                        <div v-if="missingOptionalFields.length > 0" class="alert alert-info">
                            <strong>{{ arrayToStr(missingOptionalFields) }}</strong> not found in file. This is ok, as this is
                            optional info, but if you expected it to be included, please double check your headers.
                        </div>
                        <p>
                            Want to choose a new file? <strong><a class="text-danger" @click.prevent="reuploadFile()">Click here</a></strong>
                        </p>
                    </div>
                    <div class="m-t-xl">
                        <h2>Confirm Import</h2>
                    </div>
                    <div class="uploaded-info list-results">
                        <div class="search-result teammate" v-for="row in parsedRows">
                            <div class='hr-line-dashed'></div>
                            <div class="grid grid-cols-2 sm-grid-cols-1 gap-3">
                                <div>
                                    <h3>
                                        {{ row.id }}: {{ row.first_name }} {{ row.last_name }}
                                    </h3>
                                    <div v-for="update in row.updates">
                                        <span v-if="update.should_update" class="text-primary">
                                            <strong>{{ update.property.name }} will be updated to {{ update.update_to }}</strong>
                                        </span>
                                        <span v-else class="text-danger">
                                          <strong>{{ update.property.name }} will NOT be updated.
                                            <template v-if="update.validation_error">
                                                {{ update.validation_error }}
                                            </template>
                                            <template v-else>
                                                No value found in csv.
                                            </template>
                                              </strong>
                                        </span>
                                    </div>
                                </div>
                                <div class="text-right t-l-md" v-html="importStatus(row)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="m-t-lg">
                        <p>
                            <strong>You are about to update {{ pluralizedCount(toUpdateCount, 'wrestler') }}.</strong>
                        </p>
                        <div v-show="!importFinished">
                            <ladda-button @lbClicked="beginUpdate" el-class="btn-primary" :loading="loading">
                                Process Bulk Update
                            </ladda-button>
                        </div>
                        <div v-show="importFinished">
                            {{ importMessage }}
                        </div>
                    </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { importerMix } from "./mixins/importer_mix";
import LaddaButton from "../../shared/ladda_button.vue";
import Spinner from "./spinner.vue";
import pLimit from "p-limit";

export default {
  name: "bulk-update-wrestlers",
  components: {
    Spinner,
    LaddaButton,
  },
  mixins: [importerMix],
  props: {
    respondantId: {
      type: Number,
      required: true,
    },
    respondantType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      requiredFields: ["wiq_id"],
      uploadState: "setup", // setup, upload, review, updating.
      loadingPrivateWrestlerProperties: false,
      privateQuestionsByGroup: {}, // Used for user display
      privateQuestionsById: {}, // Used for validation
      coreColumns: [
        {
          slug: "academic_class",
          name: "Academic class",
          type: "core",
          registration_question_id: null,
          selected: false,
        },
        {
          slug: "experience",
          name: "Experience",
          type: "core",
          registration_question_id: null,
          selected: false,
        },
        {
          slug: "weight_class",
          name: "Weight class",
          type: "core",
          registration_question_id: null,
          selected: false,
        },
      ],
      error: false,
      errorMessage: "",
    };
  },
  computed: {
    selectedCustomProperties() {
      return _.map(
        _.filter(_.flatMap(_.values(this.privateQuestionsByGroup)), "selected"),
        function (selected) {
          return {
            slug: `custom_property_${selected.id}_${selected.prompt.split(" ").join("_")}`,
            name: selected.prompt,
            type: "private_wrestler_property",
            registration_question_id: selected.id,
            selected: true,
          };
        }
      );
    },
    selectedCoreProperties() {
      return _.filter(this.coreColumns, "selected");
    },
    allSelectedProperties() {
      // This method returns an array we can map against when doing the headers
      return _.concat(
        this.selectedCoreProperties,
        this.selectedCustomProperties
      );
    },
    wrestlersToUpdate() {
      return _.filter(this.parsedRows, { import_status: 'not_started' });
    },
    toUpdateCount() {
      return this.wrestlersToUpdate.length;
    },
  },
  watch: {
    // whenever search changes, this function will run
    allSelectedProperties() {
      this.allFields = _.concat(
        ["wiq_id"],
        _.map(this.allSelectedProperties, "slug")
      );
    },
    // importerMix uses this internally. Use it to move to review
    parsedRows() {
      if (this.parsedRows.length > 0) {
        this.uploadState = 'review';
      } else {
        this.uploadState = 'upload';
      }
    },
  },
  mounted() {
    this.$apiService
      .loadAllPages(
        this.$apiService.privateWrestlerPropertiesForReports(),
        "registration_questions"
      )
      .then((reg_questions) => {
        let privateGroups = {};
        const groups = _.map(reg_questions, "group_with");
        for (const key of groups) {
          // each category should be able to hold an array
          privateGroups[key] = [];
        }

        for (const question of reg_questions) {
          question.selected = false;
          privateGroups[question.group_with].push(question);
        }
        this.privateQuestionsByGroup = privateGroups;
        this.privateQuestionsById = _.keyBy(reg_questions, 'id');
        this.loadingPrivateWrestlerProperties = false;
      })
      .catch((error) => {
        this.errorMessage = `Error retrieving custom private properties ${error.toString()}`;
        this.loadingPrivateWrestlerProperties = false;
        this.error = true;
      });
  },
  methods: {
    getSelectedPropertyForHeader(header) {
      return _.head(_.filter(this.allSelectedProperties, ['slug', header]));
    },
    pluralizedCount(count, word) {
      if (count === 1) {
        return `1 ${word}`;
      }

      return `${count} ${word}s`;
    },
    formatUploadedRow(uploaded_row) {
      let importStatus = "searching";
      let importReason = "Finding wrestlers to update...";

      const wrestlerId = uploaded_row["wiq_id"];
      if (_.isEmpty(wrestlerId)) {
        importStatus = "blocked";
        importReason = "Wrestler ID missing, skipping update.";
      }

      let row = {
        id: wrestlerId,
        updates: [],
      }

      let vm = this;
      this.allSelectedProperties.forEach(function(property) {
        if (property.slug === 'wiq_id') {
          // skip the id field, as we already parsed that into the id above
          return;
        }
        const updateRequest = {
          property: property,
        }
        const newValue = uploaded_row[property.slug];
        if (_.isEmpty(newValue)) {
          updateRequest["update_to"] = null;
          updateRequest["should_update"] = false;
          row.updates.push(updateRequest);
          return;
        }

        if (property.type === "core" && property.slug === "academic_class") {
          // validate
          let validClasses = ["Pre-K", "Kindergarten", "1st grade", "2nd grade", "3rd grade", "4th grade", "5th grade", "6th grade", "7th grade", "8th grade", "9th grade", "10th grade", "11th grade", "12th grade", "Freshmen", "RS Freshmen", "Sophomore", "RS Sophomore", "Junior", "RS Junior", "Senior", "RS Senior", "Grad Student", "Other"]
          if (!_.includes(validClasses, newValue)) {
            updateRequest["update_to"] = null;
            updateRequest["validation_error"] = `${newValue} is not a valid academic class, skipping.`;
            updateRequest["should_update"] = false;
            row.updates.push(updateRequest);
            return;
          }
        }

        // Todo validation for other types (yes/no, etc)
        if (property.type === "private_wrestler_property") {
          let registrationQuestion = vm.privateQuestionsById[property.registration_question_id];
          if (registrationQuestion.type === "RegSingleSelectQuestion" || registrationQuestion.type === "RegMultiSelectQuestion") {
            // validate these types
            if (!_.includes(registrationQuestion.answers, newValue)) {
              // We did not pass validation, skip this field
              updateRequest["update_to"] = null;
              updateRequest["validation_error"] = `${newValue} is not a valid answer for ${registrationQuestion.prompt}, skipping.`;
              updateRequest["should_update"] = false;
              row.updates.push(updateRequest);
              return;
            }
          }
        }

        updateRequest["update_to"] = newValue;
        updateRequest["should_update"] = true;
        row.updates.push(updateRequest);
      });

      let updateRequestCount = _.filter(row.updates, { should_update: true }).length;
      // don't overwrite if we are already blocked based on WIQ ID missing
      if (updateRequestCount === 0 && importStatus !== 'blocked') {
        importStatus = "blocked";
        importReason = "No updates found in file, skipping.";
      }

      row["import_status"] = importStatus;
      row["import_reason"] = importReason;

      return row;
    },
    uploadParsed() {
      // Concurrency limit of 3 promise at once
      const limit = pLimit(3);
      let promises = [];
      this.queryingWrestlers = true;

      this.parsedRows.forEach(async (row, index) => {
        // If we are in a blocked state, the wrestler validation failed so we skip everything
        if (row.import_status !== "blocked") {
          promises.push(limit(() => this.searchForWrestler(row, index)));
        }
      });

      Promise.all(promises)
        .then((results) => {
          console.log("all finished");
          console.log(results);
          this.queryingWrestlers = false;
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          this.queryingWrestlers = false;
        });
    },
    async searchForWrestler(row) {
      console.log(`searching for wrestler row ${row.id}`);
      // row is already a moment object, so format it how the API expects
      let url = `${this.$apiService.expandedAnswersWrestlerUrl(row.id)}`;
      return axios.get(url)
        .then((response) => {
            row.first_name = response.data.first_name;
            row.last_name = response.data.last_name;
            row.registration_answers = response.data.registration_answers;
            row.import_status = 'not_started';
            row.import_reason = `Wrestler ${response.data.id} ${response.data.first_name} ${response.data.last_name} found. Ready to update.`;

        })
        .catch((error) => {
          row.import_status = 'failed';
          row.import_reason = `No wrestler found with id ${row.id}`;
        });
    },
    urlForWrestler(wId) {
      return `<a href="/wrestlers/${wId}" target="_blank"> Wrestler ${wId} <i class="fa fa-external-link"></i></a>`;
    },
    importStatus(row) {
      switch (row.import_status) {
        case 'searching':
          return `<p><i class='fa fa-search'></i> ${row.import_reason}</p>`;
        case 'not_started':
          return `<p><i class='fa fa-circle-o'></i> ${row.import_reason}</p>`;
        case 'importing':
          return '<p><i class=\'fa fa-circle-o-notch\'></i> Importing...</p>';
        case 'blocked':
          return `<p class='text-warning'><i class='fa fa-exclamation-triangle'></i> ${row.import_reason}</p>`;
        case 'duplicate':
          return `<p class='text-danger'><i class='fa fa-times-circle'></i> ${row.import_reason}</p>`;
        case 'failed':
          return `<p class='text-danger'><i class='fa fa-times-circle'></i> ${row.import_reason}</p>`;
        case 'imported':
          return `<p class='text-success'><i class='fa fa-check-circle-o'></i> ${row.import_reason}</p>`;
      }
    },
    checkIfFinished() {
      const vm = this;
      if (vm.toUpdateCount === 0) {
        // All have finished
        vm.loading = false;
        vm.importFinished = true;
        vm.importMessage = 'Finished updating';
      }
    },
    coreUpdatesFor(row) {
      return _.filter(row.updates, function(update) {
         return update.property.type === "core" && update.should_update;
      });
    },
    async updateCoreWrestlerProperties(row) {
      const vm = this;

      const params = {
        wrestler_profile: {
        },
      };

      // This coreupdate function returns an array of only core attributes that should be updated it
      vm.coreUpdatesFor(row).forEach(function(updateRequest) {
        params.wrestler_profile[updateRequest.property.slug] = updateRequest.update_to;
      })

      const url = vm.$apiService.wrestlerUrl(row.id);
      return axios.put(url, params)
        .then((response) => {
          row.import_status = 'imported';
          row.import_reason = `Wrestler updated. <br/>${this.urlForWrestler(row.id)}`;
          vm.checkIfFinished();
        })
        .catch((error) => {
          row.import_status = 'failed';
          row.import_reason = `Failed to update wrestler ${error.toString()}`;
          vm.checkIfFinished();
        });
    },
    privatePropertyUpdatesFor(row) {
      return _.filter(row.updates, function(update) {
        return update.property.type === "private_wrestler_property" && update.should_update;
      });
    },
    async updatePrivateWrestlerProperties(row) {
      const vm = this;
      let updatedPrivateProperties = [];
      vm.privatePropertyUpdatesFor(row).forEach(function(updateRequest) {
        const existingAnswerId = _.get(_.head(_.filter(row.registration_answers, { 'registration_question_id': updateRequest.property.registration_question_id})), 'id', null)
        updatedPrivateProperties.push({
          id: existingAnswerId,
          registration_question_id: updateRequest.property.registration_question_id,
          respondent_id: vm.respondantId,
          respondant_type: vm.respondantType,
          answer: updateRequest.update_to,
          profile_id: row.id,
          profile_type: "WrestlerProfile"
        })
      });
      const params = {
        registration_answers: updatedPrivateProperties
      };
      const url = vm.$apiService.registrationAnswersUrl();
      return axios.post(url, params)
        .then((response) => {
          row.import_status = 'imported';
          row.import_reason = `Wrestler updated<br/>${this.urlForWrestler(row.id)}`;
          vm.checkIfFinished();
        })
        .catch((error) => {
          row.import_status = 'failed';
          row.import_reason = `Failed to update wrestler ${error.toString()}`;
          vm.checkIfFinished();
        });
    },
    beginUpdate: _.throttle(function () {
      const vm = this;
      if (vm.loading) {
        return;
      }

      // Concurrency limit of 1 promises at once, but due to the chained nature of it, the concurrency is limited to per original promise, not subsequent promises/calls too
      const limit = pLimit(1);
      let promises = [];
      vm.loading = true;

      vm.parsedRows.forEach((row) => {
        // Skip rows that did not pass client side validation
        if (row.import_status === 'blocked') {
          return;
        }
        if (vm.coreUpdatesFor(row).length > 0) {
          promises.push(limit(() => vm.updateCoreWrestlerProperties(row)));
        }
        if (vm.privatePropertyUpdatesFor(row).length > 0) {
          promises.push(limit(() => vm.updatePrivateWrestlerProperties(row)));
        }
      });

      Promise.all(promises)
        .then((results) => {
          console.log('all finished importing');
          console.log(results);
        })
        .catch(error => {
          console.log('error');
          console.log(error);
        });
    }, 500)
  },
};
</script>
