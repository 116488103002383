<template>
    <div class="col-md-12">
        <div class="chat-message-form">
            <div class="form-group">
                <div class="input-group">
                    <textarea v-model="message" class="form-control custom-control" rows="2"  v-bind:style="textAreaStyle" name="message" :placeholder="placeholder" ></textarea>
                    <span class="input-group-addon btn btn-primary" v-on:click="createMessage">
                        Send
                    </span>
                </div>
                <div class="row m-t-sm">
                    <div class="col-xs-8">
                        <div class="" v-show="!showFile">
                            <div v-if="attachment">
                                {{ attachment.name }} will be attached to the next message you send. <small class="text-danger" @click="removeAttachment">(remove)</small>
                            </div>
                            <div v-show="!attachment && !loading">
                                <a @click="toggleShowFile"><small>Attach file...</small></a>
                            </div>
                        </div>
                        <div v-show="showFile">
                            <file-input
                                    :multiple="false"
                                    :uploader="uploader"
                            >
                                &nbsp;
                            </file-input>
                            <div v-if="uploadingAttachment">
                        <span class="text-muted">
                            Uploading {{ pendingAttachmentName }}...<spinner :inline="true"></spinner>
                        </span>
                            </div>
                        </div>
                        <div>
                            <div v-if="loading" class="m-t-xs">
                                <span class="text-muted">Sending...</span><spinner :inline="true"></spinner>
                            </div>
                            <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
                        </div>
                    </div>
                    <div class="col-xs-4 text-right">
                        <a @click="toggleTextAreaSize"><small>{{ textAreaExpanded ? "Minimize" : "Expand" }} textarea</small></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FineUploaderS3 from 'fine-uploader-wrappers/s3';
import RSVP from 'rsvp';
import FileInput from 'vue-fineuploader/file-input.vue';
import Spinner from './spinner.vue';
import { errorableMix } from './mixins/errorable_mix';

export default {
  components: {
    Spinner,
    FileInput,
    FineUploaderS3,
    RSVP,
  },
  mixins: [errorableMix],
  name: 'message-form',
  props: [
    'group',
    'endpoint',
    'accessKey',
    'authToken',
    'cloudfrontHost',
  ],
  data() {
    const vm = this;

    const getKey = function (fileId) {
      const filename = vm.uploader.methods.getName(fileId);
      vm.uploadingAttachment = true;
      vm.pendingAttachmentName = filename;

      const keyRetrieval = new RSVP.Promise((resolve, reject) => {
        const params = {
          attachment: {
            name: filename,
            prefix: 'messages/',
          },
        };
        const url = vm.$apiService.attachmentsUrl();
        axios.post(url, params)
          .then((response) => {
            vm.pendingAttachment = response.data;
            resolve(response.data.key);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return keyRetrieval;
    };

    const uploader = new FineUploaderS3({
      options: {
        request: {
          endpoint: vm.endpoint,
          accessKey: vm.accessKey,
        },
        maxConnections: 1,
        signature: {
          endpoint: '/api/v1/attachments/s3_signature',
          customHeaders: {
            Authorization: `Bearer ${vm.authToken}`,
          },
        },
        uploadSuccess: {
          endpoint: '/api/v1/attachments/s3_confirm',
          customHeaders: {
            Authorization: `Bearer ${vm.authToken}`,
          },
        },
        objectProperties: {
          // acl: 'public-read',
          key: getKey,
        },
        chunking: {
          enabled: false,
        },
        resume: {
          // Resume is now disabled because the UI is not handling it correctly
          // to see the bug start an upload with pause enabled, then refresh the page
          // now try to upload again and you will see that the video does not start uploading
          enabled: false,
        },
      },
    });

    return {
      loading: false,

      placeholder: '',
      message: '',
      showFile: false,
      uploader,

      pendingAttachmentName: null,
      uploadingAttachment: false,
      pendingAttachment: null,
      attachment: null,

      textAreaExpanded: false,
      textAreaStyle: {
        resize: 'none',
        height: 'auto',
      },
    };
  },
  mounted() {
    const vm = this;
    vm.uploader.on('complete', (id, name, response) => {
      // handle completed upload
      vm.$notificationManager.$emit('attachment-uploaded', id);
      vm.attachment = vm.pendingAttachment;
      vm.pendingAttachment = null;
      vm.pendingAttachmentName = null;
      vm.uploadingAttachment = false;
      vm.showFile = false;
      vm.$notificationManager.$emit('show-toast', 'File attached', true);
    });

    vm.uploader.on('statusChange', (id, oldStatus, newStatus) => {
      console.log(`status changed on uploader from ${oldStatus} to ${newStatus}`);
    });
  },
  created() {
    const vm = this;
    if (vm.group) {
      vm.placeholder = `Message ${vm.group.name}...`;
    }
  },
  methods: {
    toggleTextAreaSize() {
      const vm = this;
      vm.textAreaExpanded = !vm.textAreaExpanded;

      if (vm.textAreaExpanded) {
        vm.textAreaStyle.height = '400px';
      } else {
        vm.textAreaStyle.height = 'auto';
      }

      vm.$nextTick(() => {
        // send a message
        vm.$parent.$emit('text-area-expanded', vm.textAreaExpanded);
      });
    },
    removeAttachment() {
      const vm = this;
      vm.attachment = null;
      vm.pendingAttachment = null;
      vm.pendingAttachmentName = null;
      vm.uploadingAttachment = false;
      vm.showFile = false;
    },
    toggleShowFile() {
      this.showFile = !this.showFile;
    },
    getAttachmentsParams() {
      const vm = this;
      if (!vm.attachment) {
        return [];
      }

      return [
        {
          id: vm.attachment.id,
        },
      ];
    },
    createMessage: _.throttle(function () {
      const vm = this;
      if (vm.message.length === 0) {
        vm.errorMessage = 'Please enter a message';
        vm.error = true;
        return;
      }
      vm.error = false;

      if (vm.loading === true) {
        return;
      }

      vm.loading = true;

      const params = {
        message: {
          content: vm.message,
        },
        attachments: vm.getAttachmentsParams(),
      };
      const url = vm.$apiService.messagesUrl(vm.group.id);
      axios.post(url, params)
        .then((response) => {
          vm.loading = false;
          vm.message = '';
          vm.removeAttachment();
          vm.$parent.$emit('message-created', vm.group);
          if (vm.textAreaExpanded) {
            vm.toggleTextAreaSize();
          }
          vm.$notificationManager.$emit('show-toast', 'Message sent', true);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = vm.parseErrorResponse(error);
          vm.error = true;
        });
    }, 500),
  },
};
</script>
