<template>
    <button :id="buttonId" @click.prevent="clicked" ref="btn" :data-cy="cypressClass" name="button" :class="classes" data-style="slide-left">
        <span class="ladda-label"><slot></slot></span><span class="ladda-spinner"></span>
    </button>
</template>
<script>
import * as Ladda from 'ladda';

export default {
  name: 'ladda-button',
  props: {
    elClass: {
      type: String,
      default: '',
    },
    elIdentifier: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clickArg: {
      type: [String, Number, Object, Array, Boolean],
      default: null,
    },
    cypressClass: {
      type: String,
      default: 'submit',
    },
  },
  watch: {
    // whenever search changes, this function will run
    loading(newLoading) {
      if (newLoading) {
        this.start();
        return;
      }

      this.stop();
    },
  },
  data() {
    return {
      classes: 'btn ladda-button',
      ladda: null,
    };
  },
  computed: {
    buttonId() {
      return this.elIdentifier || `ladda-button-${Math.random().toString(36).substring(2, 15)}`;
    },
  },
  mounted() {
    this.classes = `${this.classes} ${this.elClass}`;
    this.ladda = Ladda.create(this.$refs.btn);
  },
  methods: {
    clicked() {
      this.$emit('lbClicked', this.clickArg);
    },
    start() {
      if (this.ladda) {
        this.ladda.start();
      }
    },
    stop() {
      if (this.ladda) {
        this.ladda.stop();
      }
    },
  },
};
</script>
