<template>
  <svg :style="textColor" class="h-6 w-6 animate-pulse" width="54" height="50" viewBox="0 0 54 50"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M30.0981 7.6495C31.755 7.6495 33.0981 6.30635 33.0981 4.6495C33.0981 2.99264 31.755 1.6495 30.0981 1.6495C28.4412 1.6495 27.0981 2.99264 27.0981 4.6495C27.0981 6.30635 28.4412 7.6495 30.0981 7.6495Z"
        stroke="currentColor" stroke-width="2" stroke-miterlimit="10"/>
    <path
        d="M31.0514 15.04L37.4383 22.19C37.7784 22.6318 37.9932 23.1571 38.0603 23.7106C38.1274 24.2641 38.0443 24.8255 37.8196 25.3358L33.9112 32.39C33.3884 33.5071 33.0349 34.6958 32.8626 35.9171L32.0047 46.4031C31.9631 46.6234 31.9679 46.8499 32.0186 47.0683C32.0694 47.2867 32.1651 47.4921 32.2996 47.6714C32.4342 47.8508 32.6046 48.0001 32.8 48.11C32.9954 48.2199 33.2116 48.2879 33.4347 48.3096C33.9222 48.2436 34.3801 48.0372 34.7524 47.7156C35.1248 47.394 35.3956 46.9711 35.5319 46.4984L37.3431 38.7769C37.6944 37.5694 38.2074 36.4151 38.8683 35.3452L41.4422 31.8181C42.199 30.8074 43.0619 29.8806 44.016 29.0536L46.78 26.7654C47.6281 25.9291 48.1374 24.8089 48.21 23.62C48.1464 22.4076 47.714 21.2435 46.9707 20.2836L39.6307 12.1808"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M48.21 24.5729L48.9726 33.1523C49.1349 34.4086 49.3898 35.6513 49.7352 36.8701L52.5 44.6869C52.8813 45.6402 52.5 46.4981 51.7374 46.4981C51.2484 46.4114 50.7832 46.2222 50.3725 45.943C49.9618 45.6638 49.6147 45.3009 49.3542 44.8781L45.3505 37.6332C44.7967 36.5118 44.3806 35.3275 44.1112 34.1061L43.4439 30.293"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M39.6308 12.18C37.7243 10.2734 35.5318 9.7015 33.9108 10.2734C30.818 11.2762 27.6191 11.916 24.3785 12.18C21.6237 11.9375 18.9872 10.9487 16.7523 9.32"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M17.0383 18.0907L13.3206 22.285C12.9765 22.7422 12.7381 23.2701 12.6227 23.8306C12.5073 24.3911 12.5177 24.9702 12.6533 25.5262L15.2271 33.343C15.5786 34.5498 15.7394 35.8042 15.7037 37.0607L15.2271 46.5935C15.2182 47.0645 15.0398 47.5165 14.7246 47.8667C14.4095 48.2168 13.9787 48.4417 13.5112 48.5C13.0373 48.4575 12.5971 48.2374 12.2788 47.8837C11.9606 47.5301 11.7878 47.0692 11.7953 46.5935L11.2234 38.014C11.0976 36.764 10.7759 35.5416 10.2701 34.3916L9.1262 32.0084C8.52712 30.9069 7.7546 29.9091 6.8383 29.0533L4.0738 26.7654C3.22558 25.929 2.71631 24.8086 2.6438 23.6196C2.70736 22.4072 3.13975 21.2431 3.8831 20.2832L11.2231 12.1804"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M3.4065 26.4794V34.1056C3.37398 35.3843 3.24653 36.6589 3.0252 37.9187L1.786 44.5916C1.5953 45.6402 2.072 46.4981 2.8346 46.4981C3.30451 46.4142 3.74138 46.1999 4.09534 45.8797C4.44929 45.5594 4.70608 45.1461 4.8364 44.6869L6.6477 38.7769C6.9336 37.7283 7.315 36.1078 7.5056 35.0592L8.1729 31.2461"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.9879 10.0832C12.9198 10.5643 11.9747 11.2812 11.2234 12.18" stroke="currentColor"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M15.8944 13.1336C15.8944 13.1336 20.5654 16.9467 22.472 16.9467C23.4252 16.9467 25.332 16.9467 26.285 12.1804"
        stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path
        d="M22.6654 8.6794C22.968 8.4015 23.2056 8.06023 23.3612 7.67995C23.5168 7.29967 23.5865 6.88973 23.5654 6.4794C23.5654 5.68375 23.2493 4.92069 22.6867 4.35808C22.1241 3.79547 21.361 3.4794 20.5654 3.4794C19.7697 3.4794 19.0067 3.79547 18.4441 4.35808C17.8815 4.92069 17.5654 5.68375 17.5654 6.4794"
        stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path
        d="M17.2852 3.8225C17.2852 3.8225 16.8267 3.3502 16.3871 3.4996C15.87 3.6745 15.1389 4.7886 14.4735 5.1373C13.8202 5.49288 13.0673 5.62177 12.3329 5.5038"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M33.2383 2.1353C33.2383 2.1353 33.6968 1.6629 34.1364 1.8123C34.6535 1.9872 35.3846 3.1013 36.05 3.4501C36.7034 3.80562 37.4562 3.93447 38.1906 3.8165"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'wrestler-icon',
  props: {
    strokeColor: String,
  },
  computed: {
    textColor() {
      if (this.strokeColor) {
        return {
          color: this.strokeColor,
        };
      }

      return {};
    },
  },
};
</script>
