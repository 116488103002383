<template>
  <div v-if="usawConfig !== 'usaw_disabled'">
    <div class="row">
      <div class="col-md-8" v-show="!showForm">
        <div class="ibox">
          <div class="ibox-title border-bottom-none m-b-none">
            <h1><span v-if="profileName !== ''">{{ profileName }}'s</span> USA Wrestling Membership <span
                v-if="!requiresValidUsawCard">(optional)</span></h1>
            <p class="m-t-xs font-small">
              {{ promptText }}
            </p>
            <div class="border-top-bottom grid grid-cols-3 sm-grid-cols-5 p-x-xs m-t-lg">
              <h2 class="upper-label sm-col-span-2">
                USAW ID
              </h2>
              <h2 class="upper-label sm-col-span-2">
                Valid until
              </h2>
              <h2 class="upper-label m-l-auto">
                Actions
              </h2>
            </div>
          </div>
          <div class="ibox-content">
            <spinner v-if="loading"></spinner>
            <div v-else>
              <div class="grid grid-cols-3 sm-grid-cols-5" v-for="usawm in usawMemberships">
                <div class="flex-col sm-col-span-2">
                  <h4 class="font-semi m-y-none">
                    <template v-if="usawm.id_card && usawm.id_card.url">
                      <a :href="usawm.id_card.url" target="_blank" class="flex align-items-center">
                        {{ usawm.public_id }} <i class="fa fa-external-link m-l-xxs" style="font-size: 8px;"
                                                 aria-hidden="true"></i>
                      </a>
                    </template>
                    <template v-else>
                      {{ usawm.public_id }}
                    </template>
                  </h4>
                  <p class="font-small">
                    {{ usawm.membership_type }}
                  </p>
                </div>
                <div class="flex sm-col-span-2 gap-x-xs gap-x-xxs-xs-only">
                  <div>
                    {{ readableExpires(usawm.expires_on) }}
                  </div>
                  <div class="" v-html="badgeFor(usawm)"></div>
                </div>
                <div class="m-l-auto">
                  <a class="text-danger" @click.prevent="confirmDelete(usawm)">
                    <i class="fa fa-trash-o color-red-600"></i>
                  </a>
                </div>
              </div>
              <div v-if="!hasValidUsawCard">
                <p class="alert alert-warning m-t-lg" v-if="requiresValidUsawCard && hasOldCard">
                  {{ profileName }} has an expired USA Wrestling card. Please renew {{ profileName }}'s membership, then
                  input your new info.
                  <br/><br/><strong>Note:</strong> USA Wrestling info recorded in WrestlingIQ is not editable.
                  Please use the button below to enter your USA Wrestling membership info, even if just your expiration
                  date changed.
                </p>
                <p class="alert alert-warning m-t-lg" v-else-if="requiresValidUsawCard">
                  {{ profileName }} needs an active USA Wrestling membership on file to train with {{ teamName }}.
                  Please purchase one and enter your
                  membership info here.
                </p>
                <p class="alert alert-warning m-t-lg" v-else-if="hasOldCard">
                  {{ profileName }} has an expired USA Wrestling membership on file. If {{ profileName }}'s membership
                  has renewed, please input your new info.
                  <br/><br/><strong>Note:</strong> USA Wrestling info recorded in WrestlingIQ is not editable.
                  Please use the button below to enter your USA Wrestling membership info, even if just your expiration
                  date changed.
                </p>
                <p v-else>
                  {{ profileName }} does not have an active USAW Membership on file. If {{ profileName }} has a
                  membership,
                  please enter that info here.
                </p>
              </div>
              <div class="m-t-lg flex">
                <button data-cy="usaw-btn" class="btn btn-success" @click="toggleForm">
                  <i class="fa fa-plus"></i> USAW Membership
                </button>
                <button data-cy="submit" class="btn btn-primary align-self-flex-end m-l-auto"
                        :disabled="!hasValidUsawCard && requiresValidUsawCard"
                        @click="$emit('usaw-completed')"
                        v-if="!redirect"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showForm">
        <usaw-form
            :wrestler-id="wrestlerId"
            :profile-name="profileName"
            :usaw-config="usawConfig"
            :access-key="accessKey"
            :endpoint="endpoint"
            :most-recent-usaw-expired="expiresOnPrefill"
            :most-recent-usaw-id="mostRecentUsawId"
            :cloudfront-base-url="cloudfrontBaseUrl"
            v-on:cancel-usaw-form="toggleForm"
            v-on:usaw-created="addUsawMembership"
        ></usaw-form>
      </div>
      <div class="col-md-4">
        <div class="ibox p-lg">
          <usaw-logo class=""></usaw-logo>
          <h2 class="font-heavy usaw-blue m-t-md" v-html="usawCta"></h2>
          <p class="m-t-md">
            As the national governing body of wrestling in the United States, USA Wrestling charters clubs and sanctions
            over 2100 competitions a year for their members. Athlete USAW memberships are needed for:
          </p>
          <ul class="list-unstyled usaw-features m-t-m">
            <li>
              <i class="fa fa-check"></i> Secondary sports accident coverage for injuries sustained while wrestling
            </li>
            <li>
              <i class="fa fa-check"></i> Participation in local, regional, and national competitions
            </li>
            <li v-if="requiresValidUsawCard">
              <i class="fa fa-check"></i> Compliance with {{ teamName }}'s USAW Club Charter. All athletes must have an
              active membership to practice
            </li>
          </ul>
          <a target="blank" href="https://www.usawmembership.com" class="btn usaw-btn btn-block m-t-lg">
            Purchase membership
          </a>
          <p class="text-center font-small m-t-sm">
            You will be redirected to USAW's website to <a
              href="https://www.usawmembership.com/help/category/3/question/14" target="_blank" class="text-underline">purchase
            your membership</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import UsawLogo from './svgs/usaw_logo.vue';
import Spinner from './spinner.vue';
import UsawForm from './usaw_form.vue';
import { photoUploadMix } from './mixins/photo_upload_mix';
import swal from 'sweetalert2';

export default {
  name: 'usaw-manager',
  components: {
    UsawForm,
    Spinner,
    UsawLogo,
  },
  mixins: [errorableMix, photoUploadMix],
  props: {
    wrestlerId: {
      type: Number,
      required: true
    },
    mostRecentUsawId: {
      type: String,
      default: ''
    },
    mostRecentUsawExpired: {
      type: String,
      default: ''
    },
    buttonCta: {
      type: String,
      default: 'Save',
    },
    usawConfig: {
      type: String,
      default: 'usaw_disabled',
    },
    teamName: {
      type: String,
      default: '',
    },
    profileName: {
      type: String,
      default: '',
    },
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      usawMemberships: [],
      showForm: false,
    };
  },
  computed: {
    expiresOnPrefill() {
      if (this.mostRecentUsawExpired !== '') {
        return moment(this.mostRecentUsawExpired).format('MM/DD/YYYY');
      }

      return '';
    },
    hasValidUsawCard() {
      return _.find(this.usawMemberships, function (usaw) {
        return moment()
            .isBefore(usaw.expires_on, 'day');
      });
    },
    hasOldCard() {
      return this.usawMemberships.length > 0;
    },
    requiresValidUsawCard() {
      return this.usawConfig === 'usaw_require_all' || this.usawConfig === 'usaw_require_id_and_expires';
    },
    promptText() {
      switch (this.usawConfig) {
        case 'usaw_optional':
          return `If you have a valid USA Wrestling Membership, please enter it`;
        case 'usaw_require_all':
        case 'usaw_require_id_and_expires':
          return `In order to train with ${this.teamName} an active USA Wrestling Membership is required`;
        default:
          return '';
      }
    },
    usawCta() {
      switch (this.usawConfig) {
        case 'usaw_require_all':
        case 'usaw_require_id_and_expires_on':
          return `What is USA Wrestling and why does ${this.profileName} need a membership?`;
        default:
          return `Why would ${this.profileName} want a USA Wrestling Membership?`;
      }
    }
  },
  created() {
    this.getUsawMemberships();
    let vm = this;
    // If we have a redirect, this is a standalone view (meaning we need an initial history state)
    // if we don't, we expect the parent vue component to manage initial state
    // todo clean this fucking yuck shit up. Hacking this in to get it working
    // but it is not clean, since this component should not be capable of knowing about parent state.
    history.replaceState({
      showingForm: false,
      managerState: 'usaw'
    }, '');

    window.addEventListener('popstate', this.popStateHandler);
  },
  destroyed() {
    window.removeEventListener('popstate', this.popStateHandler);
  },
  methods: {
    popStateHandler(event) {
      if (event.state && 'showingForm' in event.state) {
        this.showForm = event.state.showingForm;
      }
    },
    toggleForm() {
      // If we are showing the form, just go backwards in time.
      // this is handled by the popstate handler above to show or hide the form
      if (this.showForm) {
        history.back();
      } else {
        this.showForm = !this.showForm;
        // Push state so we know what's up
        history.pushState({
          showingForm: true,
          managerState: 'usaw'
        }, '');
      }
    },
    readableExpires(expiresOn) {
      return moment(expiresOn)
          .format('MMM Do YYYY');
    },
    badgeFor(usawm) {
      if (moment()
          .isSameOrBefore(usawm.expires_on, 'day')) {
        return `<span class="badge badge-primary">Active</span>`;
      }

      return `<span class="badge badge-danger">Expired</span>`;
    },
    // This is hit when usaw-form creates a brand new usaw membership
    addUsawMembership(usawm) {
      this.usawMemberships.push(usawm);
      this.toggleForm();
      if (this.redirect) {
        window.location.assign(`/wrestlers/${this.wrestlerId}`);
      }
    },
    getUsawMemberships() {
      const vm = this;
      const url = vm.$apiService.usawMemberships(this.wrestlerId);
      vm.loading = true;
      axios.get(url)
          .then((response) => {
            vm.loading = false;
            vm.usawMemberships = response.data.usaw_memberships;
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error retrieving USA Wrestling memberships';
            vm.error = true;
          });
    },
    destroyID(usawid) {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;
      vm.error = false;

      const url = vm.$apiService.usawMembership(vm.wrestlerId, usawid.id)
      axios.delete(url)
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', 'USAW ID deleted', true);
            // Remove it from our local knowledge
            vm.usawMemberships = _.reject(vm.usawMemberships,['id', usawid.id]);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error deleting USAW ID, please try again later or contact support@wrestlingiq.com';
            vm.error = true;
          });
    },
    confirmDelete(usawid) {
      let vm = this;
      swal.fire({
        type: 'warning',
        title: `Are you sure you want to delete USAW #${usawid.public_id} that expires on ${this.readableExpires(usawid.expires_on)}?`,
        text: `This will remove the USA Wrestling membership info from ${this.profileName} and regenerate their WIQ ID card.`,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonClass: 'btn btn-danger m-l-md',
        confirmButtonText: 'Yes, delete the USAW ID',
        cancelButtonText: 'No, keep it',
        cancelButtonClass: 'btn btn-default',
        buttonsStyling: false,
      })
          .then((result) => {
            if (result.value) {
              vm.destroyID(usawid);
            }
          });
    }
  }
};
</script>
