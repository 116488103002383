<template>
  <div :class="getVisibilityClass()">
    <div :class="getWrestlerBlockClass()">
      <div class="grid sm-grid-cols-1 gap-x-sm gap-y-xs" :class="`grid-cols-${maxGridColumns}`">
        <div>
          <h1 class="form-section-title m-b-xxs">Wrestler</h1>
        </div>
        <div v-if="wrestler" class="flex gap-x-sm justify-space-between">
          <roster-card
            v-bind:no-hr="true"
            v-bind:wrestler="wrestler"
            v-bind:show-invite="false"
            v-bind:show-email="false"
            :allow-edit="false"
            v-bind:key="wrestler.id"
            v-bind:show-external-link="true"
            v-bind:flex-layout="maxGridColumns <= 2 ? 'flex-col' : 'flex-row'"
          >
          </roster-card>
          <div>
            <a class="btn btn-default btn-compact flex justify-content-center align-items-center" @click.prevent="resetWrestler()"><i class="fa fa-pencil m-none"></i></a>
          </div>
        </div>
      </div>
      <search
          v-show="!wrestler"
          class="search-form"
          response-key="wrestlers"
          placeholder="Type wrestler name..."
          :mutate-history="false"
          :allow-add="false"
          readable-model-name="wrestler"
          base-url="/api/v1/wrestlers">
        <template v-slot:list="slotProps">
          <div class="wrestler-wrapper" @click="wrestlerClicked(slotProps.result)">
            <wrestler-search-result
                v-bind:result="slotProps.result"
                v-bind:is-selectable="true"
                v-bind:key="slotProps.result.id"
            >
            </wrestler-search-result>
          </div>
        </template>
      </search>
    </div>
    <div :class="getOpposingWrestlerBlockClass()">
      <div class="grid sm-grid-cols-1 gap-x-sm gap-y-xs" :class="`grid-cols-${maxGridColumns}`">
        <div>
          <h1 class="form-section-title m-b-xxs">Opponent</h1>
        </div>
        <div v-if="opponent" class="flex gap-x-sm justify-space-between">
          <div class="search-result">
            <div class="row">
              <div class="col-xs-12">
                <div class="flex">
                  <div class="wrestler-info">
                    <h3 class="font-heavy">
                      <a :href="`/opposing_teams/${opponent.team.id}/opposing_wrestlers/${opponent.id}`" target="_blank">
                        {{ opponent.first_name }} {{ opponent.last_name }} <i class="fa fa-external-link"></i>
                      </a>
                    </h3>
                    <p>{{ opponent.team.name }}</p>
                    <p v-if="opponent.weight_class">
                      {{ opponent.weight_class }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <a v-if="opponent" class="btn btn-default btn-compact flex justify-content-center align-items-center" @click.prevent="removeOpponent()"><i class="fa fa-trash m-none"></i></a>
          </div>
        </div>
        <div v-else>
          <a v-if="!addingOpponent" class="btn btn-default" @click.prevent="addingOpponent = true"><i class="fa fa-plus m-r-xxs"></i> Opponent (optional)</a>
        </div>
      </div>
      <search v-show="addingOpponent"
              readable-model-name="opposing wrestler"
              :allow-add="true"
              class="search-form"
              response-key="wrestlers"
              placeholder="Type opposing wrestler's name..."
              base-url="/api/v1/opposing_wrestlers"
              :mutate-history="false"
              v-on:adding="dimForOpposingWrestler()"
              v-on:doneAdding="showAll()"
      >
        <template v-slot:list="slotProps">
          <div class="opponent-wrapper" @click="opponentClicked(slotProps.result)">
            <opposing-wrestler-search-result
                v-bind:result="slotProps.result"
                v-bind:is-selectable="true"
                v-bind:key="slotProps.result.id">
            </opposing-wrestler-search-result>
          </div>
        </template>
        <template v-slot:addform="slotProps">
          <opposing-wrestler-create
              v-bind:first-name-guess="slotProps.firstName"
              v-bind:last-name-guess="slotProps.lastName"
          >
          </opposing-wrestler-create>
        </template>
      </search>
    </div>
    <div :class="getEventBlockClass()">
      <div class="grid sm-grid-cols-1 gap-x-sm gap-y-xs" :class="`grid-cols-${maxGridColumns}`">
        <div>
          <h1 class="form-section-title m-b-xxs">Event</h1>
        </div>
        <div v-if="event" class="flex gap-x-sm justify-space-between">
          <event-search-result
            v-bind:result="event"
            v-bind:has-href="true"
            v-bind:key="event.id">
          </event-search-result>
          <div>
            <a v-if="event" class="btn btn-default btn-compact flex justify-content-center align-items-center" @click.prevent="resetEvent()"><i class="fa fa-trash m-none"></i></a>
          </div>
        </div>
        <div v-else>
          <a v-if="!addingEvent" class="btn btn-default" @click.prevent="addingEvent = true"><i class="fa fa-plus m-r-xxs"></i> Event (optional)</a>
        </div>
      </div>
      <search v-show="addingEvent"
              readable-model-name="event"
              :allow-add="allowEventCreate"
              :mutate-history="false"
              class="search-form"
              response-key="events"
              placeholder="Type event name..."
              base-url="/api/v1/events"
              only-dismiss-model="Event"
              v-on:adding="dimForEvent()"
              v-on:doneAdding="showAll()"
      >
        <template v-slot:list="slotProps">
          <div class="event-wrapper" @click="eventClicked(slotProps.result)">
            <event-search-result
                v-bind:result="slotProps.result"
                v-bind:key="slotProps.result.id"
                v-bind:has-href="false"
            >
            </event-search-result>
          </div>
        </template>
        <template v-slot:addform="slotProps">
          <event-create
              :for-calendar="false"
              v-bind:name="slotProps.eventName"
              :paid-session-tagging="false"
          >
          </event-create>
        </template>
      </search>
    </div>
    <div :class="getButtonBlockClass()" class="grid sm-grid-cols-1 gap-sm m-t-m">
      <div>
      </div>
      <div>
        <div class="flex flex-col gap-y-sm">
          <ladda-button @lbClicked="tagWrestler" el-class="btn-primary btn w-full" :loading="loading">
            {{ getButtonText() }}
          </ladda-button>
          <button v-show="!loading" type="button" v-on:click="cancel" class="btn btn-white w-full">Cancel</button>
        </div>
        <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small><br/>
      </div>
    </div>
  </div>
</template>

<script>
import Search from '../../shared/search.vue'
import OpposingWrestlerSearchResult from './opposing_wrestler_search_result.vue'
import WrestlerSearchResult from '../../shared/wrestler_search_result.vue'
import EventSearchResult from './event_search_result.vue'
import OpposingWrestlerCreate from "./opposing_wrestler_create.vue";
import EventCreate from "./event_create.vue";
import LaddaButton from "../../shared/ladda_button.vue";
import RosterCard from './roster_card.vue';

export default {
  components: {
    LaddaButton,
    EventCreate,
    OpposingWrestlerCreate,
    Search,
    OpposingWrestlerSearchResult,
    WrestlerSearchResult,
    EventSearchResult,
    RosterCard
  },
  name: 'match-tag-form',
  props: {
    originalVideo: {
      type: Object,
      default: null
    },
    existingMatch: {
      type: Object,
      default: null
    },
    prefilledEvent: {
      type: Object,
      default: null
    },
    prefilledWrestler: {
      type: Object,
      default: null
    },
    allowEventCreate: {
      type: Boolean,
      default: false
    },
    rulesetSlug: {
      type: String,
      required: true
    },
    unknownOpponentId: {
      type: Number,
      required: true
    },
    maxGridColumns: {
      type: Number,
      default: 2
    }
  },
  data: function() {
    return {
      wrestler: null,
      opponent: null,
      addingOpponent: false,
      event: null,
      addingEvent: false,

      error: false,
      errorMessage: '',

      loading: false,
      dimSections: false,
      addingModelName: '',

      editForm: false,
      existingMatchId: null
    }
  },
  created: function() {
    let vm = this;
    vm.$notificationManager.$on('opposing-wrestler-created', vm.opponentCreated);
    vm.$notificationManager.$on('event-created', vm.eventCreated);

    if (vm.existingMatch) {
      // This is an edit form as a tagged match already exists
      vm.editForm = true;
      vm.wrestler = vm.existingMatch.wrestler;
      vm.opponent = vm.existingMatch.opponent;
      vm.event = vm.existingMatch.event;
      vm.existingMatchId = vm.existingMatch.id;
    }

    if (vm.prefilledEvent && !vm.editForm) {
      vm.event = vm.prefilledEvent;
    }

    if (vm.prefilledWrestler && !vm.editForm) {
      vm.wrestler = vm.prefilledWrestler;
    }

    vm.$notificationManager.$on('prefilled-event-changed', vm.prefilledEventChange);
    vm.$notificationManager.$on('prefilled-wrestler-changed', vm.prefilledWrestlerChange);

    // If this is a new match and we have a prefilled wrestler, just go ahead and create the match. No need to wait around.
    if (!vm.editForm && vm.wrestler) {
      vm.tagWrestler();
    }
  },
  destroyed: function() {
    let vm = this;
    vm.$notificationManager.$off('opposing-wrestler-created', vm.opponentCreated);
    vm.$notificationManager.$off('event-created', vm.eventCreated);
    vm.$notificationManager.$off('prefilled-event-changed', vm.prefilledEventChange);
    vm.$notificationManager.$off('prefilled-wrestler-changed', vm.prefilledWrestlerChange);
  },
  methods: {
    prefilledEventChange(event){
      let vm = this;
      if (vm.event === null) {
        vm.event = event;
      }
    },
    prefilledWrestlerChange(wrestler) {
      let vm = this;
      if (vm.wrestler === null) {
        vm.wrestler = wrestler;
      }
    },
    eventCreated(event){
      let vm = this;
      if (event.event_type === 'scramble') {
        this.$notificationManager.$emit('show-toast', 'Error: scramble events cannot currently be tagged in videos.', false);
        vm.addingEvent = false;
        return;
      }

      vm.event = event;
      vm.showAll();
    },
    opponentCreated(opposingWrestler) {
      let vm = this;
      vm.opponent = opposingWrestler;
      vm.addingOpponent = false;
      vm.showAll();
    },
    getButtonText: function() {
      let vm = this;
      if (vm.editForm) {
        return "Update";
      } else {
        return "Create Match"
      }
    },
    avatarExists: function() {
      return this.wrestler?.avatar_thumbnail;
    },
    getWrestlerBlockClass: function() {
      let vm = this;
      if (vm.dimSections && vm.addingModelName !== 'wrestler') {
        return "search-select-block should-dim";
      }     else {
        return "search-select-block";
      }
    },
    getOpposingWrestlerBlockClass: function() {
      let vm = this;
      if (vm.dimSections && vm.addingModelName !== 'opposing-wrestler') {
        return "search-select-block should-dim";
      }     else {
        return "search-select-block";
      }
    },
    getEventBlockClass: function() {
      let vm = this;
      if (vm.dimSections && vm.addingModelName !== 'event') {
        return "search-select-block should-dim";
      }     else {
        return "search-select-block";
      }
    },
    getVisibilityClass: function() {
      if (this.dimSections) {
        return "match-tag-form dim";
      }     else {
        return "match-tag-form";
      }
    },
    getButtonBlockClass: function() {
      const gridClass = `grid-cols-${this.maxGridColumns}`;
      return this.dimSections ? `should-dim ${gridClass}` : gridClass;
    },
    showAll: function() {
      let vm = this;
      vm.dimSections = false;
      vm.addingModelName = null;
    },
    dimForOpposingWrestler: function() {
      let vm = this;
      vm.dimSections = true;
      vm.addingModelName = 'opposing-wrestler';
    },
    dimForEvent: function() {
      let vm = this;
      vm.dimSections = true;
      vm.addingModelName = 'event';
    },
    cancelOpposingWrestlerCreate: function() {
      let vm = this;
      vm.dimSections = false;
    },
    removeOpponent: function() {
      this.opponent = null;
    },
    resetWrestler: function() {
      this.wrestler = null;
    },
    resetEvent: function() {
      this.event = null;
    },
    wrestlerSubLabel: function() {
      let vm = this;
      if (vm.opponent.weight_class !== "") {
        return vm.opponent.weight_class + " - " + vm.opponent.team.name;
      } else {
        return vm.opponent.team.name;
      }
    },
    wrestlerClicked: function(wrestler) {
      this.wrestler = wrestler;
    },
    opponentClicked: function(opponent) {
      this.opponent = opponent;
      this.addingOpponent = false;
    },
    eventClicked: function(event) {
      if (event.event_type === 'scramble') {
        this.$notificationManager.$emit('show-toast', 'Error: scramble events cannot currently be tagged in videos.', false);
        return;
      }
      this.event = event;
      this.addingEvent = false;
    },
    cancel: function() {
      this.$emit('cancel');
    },
    errorHandler: function() {
      let vm = this;
      vm.loading = false;
      vm.errorMessage = "Error, please try again later or contact support.";
      vm.error = true;
    },
    tagWrestler: _.throttle(function() {
      let vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }

      // If the client side is not valid, return
      if (!vm.wrestler) {
        vm.error = true;
        vm.errorMessage = "Please select a wrestler.";
        return;
      } else {
        vm.error = false;
      }

      vm.loading = true;

      let params = {};
      params["match"] = {
        home_wrestler_profile_id: vm.wrestler.id,
        opponent_profile_id: vm.opponent?.id || vm.unknownOpponentId,
        event_id: vm.event?.id || null,
        original_video_id: vm.originalVideo?.id,
        ruleset: vm.rulesetSlug,
      };

      if (vm.editForm) {
        // This is an update request
        let url = vm.$apiService.matchUrl(vm.existingMatchId);
        axios.put(url, params)
            .then(function (response) {
              // With an update request we have a 204 no content response so we need to query to get the latest match json
              axios.get(url)
                  .then(function(response) {
                    vm.loading = false;
                    let match = response.data;
                    vm.$notificationManager.$emit('match-updated', match, vm.originalVideo);
                    vm.$notificationManager.$emit('show-toast', 'Match updated', true);
                  }).catch(function(error) {
                vm.errorHandler();
              });
            })
            .catch(function (error) {
              vm.errorHandler();
            })
      } else {
        // This is a create request
        let url = vm.$apiService.matchesUrl();
        axios.post(url, params)
            .then(function (response) {
              vm.loading = false;

              let match = response.data;
              let originalVideo = vm.originalVideo;
              vm.$notificationManager.$emit('match-created', match, originalVideo);
              vm.$notificationManager.$emit('show-toast', 'Wrestler tagged', true);
            })
            .catch(function (error) {
              vm.errorHandler();
            })
      }
    }, 500),
  }
}
</script>
