export const urlHelperMixin = {
  methods: {
    urlForProfile(profile_id, profile_type) {
      if (profile_type === 'ParentProfile') {
        return `/parents/${profile_id}`;
      }
      if (profile_type === 'CoachProfile') {
        return `/coaches/${profile_id}`;
      }
      if (profile_type === 'WrestlerProfile') {
        return `/wrestlers/${profile_id}`;
      }

      return '';
    },
    // Attempt to detect urls in text and anchor tag it
    urlify(text, includeBreaks) {
      let urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
      let linkedText = text.replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
      })
      let lineBreak = includeBreaks ? '<br>' : '';
      return `${linkedText}${lineBreak}`;
    },
  }
}
