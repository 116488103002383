<template>
  <div>
    <div :class="index === 0 ? 'm-t-m' : 'm-t-xl'" v-for="(items, productId, index) in itemsGroupedByProduct">
      <div class="search-result">
        <div class="row border-top-bottom">
          <div class="col-xs-6">
            <h2 class="upper-label">
              {{ productByBillingId(productId).name }}
            </h2>
          </div>
          <div class="col-xs-6">
            <div class="flex flex-wrap justify-space-between">
              <h2 class="upper-label">
                Quantity
              </h2>
              <h2 class="upper-label">
                Total
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="m-t-sm" v-for="(item, index) in items">
        <div class="search-result" v-if="item.billing_item_type === 'BillingPlan'">
          <div class='hr-line-dashed' v-if="index > 0"></div>
          <div class="row">
            <div class="col-xs-6">
              <h3>
                {{ planName(item) }}
              </h3>
              <div class="payment-plan-details">
                {{ planInterval(item) }}
              </div>
            </div>
            <div class="col-xs-6">
              <div class="flex flex-wrap justify-space-between">
                <div>
                  {{ item.quantity }}
                </div>
                <div class="text-right">
                  {{ itemTotal(item) }}<br/>
                  {{ item.stripe_id }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.subscription.coupons.length > 0" class="m-t-m">
      <div class="search-result">
        <div class="row border-top-bottom flex justify-space-between p-x-m">
          <h2 class="upper-label">
            Scholarship Code
          </h2>
          <h2 class="upper-label m-l-auto">
            Amount Off
          </h2>
        </div>
      </div>
      <div class="m-t-sm" v-for="(coupon, index) in this.subscription.coupons">
        <div class="search-result">
          <div class='hr-line-dashed' v-if="index > 0"></div>
          <div class="row flex justify-space-between p-x-m">
            <h3>
              {{ coupon.code }}
            </h3>
            <div class="m-l-auto">
              <template v-if="coupon.amount_off > 0">
                - {{ formatAsCurrency(coupon.amount_off) }}
              </template>
              <template v-else>
                - {{ coupon.percent_off * 100 }}% off
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formattingMixin } from './mixins/formatting_mix';
import { billingPlanMix } from './mixins/billing_plan_mix';

export default {
  name: 'subscription-plan-details',
  mixins: [formattingMixin, billingPlanMix],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // This logic is more complicated than it needs to be now that we have moved to a 1 wrestler per subscription rule.
    // But we have not done cleanup to ensure all old subscriptions are in this format, so here we are.
    itemsGroupedByProduct() {
      const vm = this;
      const eligibleItems = _.filter(this.subscription.billing_plan_subscription_items, { billing_item_type: 'BillingPlan' });
      return _.groupBy(eligibleItems, (item) => vm.productForItem(item).id);
    },
  },
  methods: {
    planInterval(item) {
      const plan = this.planForItem(item);
      if (!plan) {
        return '';
      }

      return `${this.formatAsCurrency(plan.amount)} ${this.readableInterval(plan)}`;
    },
    itemTotal(item) {
      const plan = this.planForItem(item);
      if (!plan) {
        return '';
      }

      return `${this.formatAsCurrency(plan.amount * item.quantity)} ${this.readableInterval(plan)}`;
    },
    readableInterval(plan) {
      return `every ${plan.interval_count} ${plan.interval}`;
    },
  },
};
</script>
