<template>
    <div role="form">
        <div class="form-group">
            <label>Name</label>
            <input v-model="name" type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>Level (optional)</label>
            <input v-model="level" type="text" class="form-control" placeholder="">
        </div>
        <div class="form-group">
            <label>Conference (optional)</label>
            <input v-model="conference" type="text" class="form-control">
        </div>
        <div class="button-area">
            <div v-show="error">
                <small>
                    <span class="text-danger">{{ errorMessage }}</span>
                </small>
            </div>
            <button v-show="!loading" type="button" v-on:click="cancel" class="btn btn-white">Cancel</button>
            <ladda-button @lbClicked="postOpposingTeam" el-class="btn-primary" :loading="loading">
                Create Opposing Team
            </ladda-button>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  components: {
    LaddaButton,
  },
  name: 'opposing-team-create',
  props: [
    'name',
  ],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      level: '',
      conference: '',
    };
  },
  methods: {
    cancel() {
      this.$notificationManager.$emit('cancel-opposing-team-create');
    },
    validateNotEmpty(name) {
      const vm = this;

      if (name.length === 0) {
        vm.errorMessage = 'Please ensure name is not empty';
        vm.error = true;
        return false;
      }
      return true;
    },
    buildParams() {
      const vm = this;
      const params = {
        opposing_team: {
          name: vm.name,
          level: vm.level,
          conference: vm.conference,
        },
      };
      return params;
    },
    postOpposingTeam: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (vm.validateNotEmpty(vm.name)) {
        vm.loading = true;

        const params = vm.buildParams();
        const url = vm.$apiService.opposingTeamsUrl();
        axios.post(url, params)
          .then((response) => {
            vm.loading = false;
            response = response.data;
            vm.$notificationManager.$emit('opposing-team-created', response);
            vm.$notificationManager.$emit('show-toast', `${response.name} created`, true);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
      }
    }, 500),
  },
};
</script>
