<template>
  <div>
    <div>
      <div class="pull-left">
        <div>
          Practice starts at {{ startTime() }}<br/>
          Estimated end at {{ endTime() }}
        </div>
      </div>
      <div class="pull-right collapse-toggles" v-if="blocksExist()">
        <a v-on:click="collapseAll()">Collapse All</a> - <a v-on:click="expandAll()">Expand All</a>
      </div>
    </div>
    <div class="clearfix"></div>
    <div id="vertical-timeline" :class="getTimelineStyles()">
      <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
      <template v-if="loading">
        <spinner></spinner>
      </template>
      <template v-else-if="!loading && !error">
        <div v-if="!blocksExist() && readOnly">
          <p>
            Nothing has been added to this practice plan yet.
          </p>
        </div>
        <div v-if="!blocksExist() && !readOnly">
          <h2 class="m-b-xxs">Start from scratch</h2>
          <p>Drag a block over to begin creating this plan.</p>
        </div>
        <draggable v-model="blocks" :class="getDraggableClass()" class="m-t-sm" :options="getDraggableOptions()"
                   group="blocks">
          <practice-plan-block
              v-for="(block, index) in blocks"
              :key="index"
              v-bind:block="block"
              v-bind:plan-id="planId"
              v-bind:read-only="readOnly"
              v-bind:block-index="index"
          >
            <template v-slot:runtime>
              {{ runTimeUntil(index) }}
            </template>
          </practice-plan-block>
        </draggable>
        <div class="flex flex-col" v-if="!blocksExist() && !readOnly">
          <div class="m-t-lg m-b-lg">
            <h3 class="text-gray-600">OR</h3>
          </div>
          <div class="m-b-xs flex justify-space-between items-center">
            <div>
              <h2 class="m-b-xxs m-t-none">Use a template</h2>
              <p>Make a copy of one of your saved practice plan templates.</p>
            </div>
            <div>
              <a href="/templates/practice_plans" class="btn btn-default" target="_blank">View/Edit All Templates <i class="fa fa-external-link"></i></a>
            </div>
          </div>
          <filtered-list
              :headers="['Name', '']"
              ui="table"
              :sorts="[
                    {
                      label: 'Name desc',
                      value: 'Name desc'
                      },
                    {
                      label: 'Name asc',
                      value: 'Name asc'
                      },
                    ]"
              :filters="[
                   {
                      label: 'Template',
                      attribute: 'is_template',
                      type: 'primary',
                      predicates:
                        [
                          {
                            label: 'Your Templates',
                            user_editable: false,
                            default: true,
                            values: {
                              is_template_true: 1
                              }
                            },
                          ]
                      },
                    {
                      label: 'Name',
                      predicateLabel: 'Filter by template name',
                      attribute: 'name',
                      type: 'secondary',
                      predicates:
                        [
                          {
                            id: 1,
                            label: 'Name contains',
                            type: 'TextContains',
                            user_editable: true,
                            operator: 'or',
                            placeholder: 'Template name',
                            values: {
                              name_cont_any: null,
                              }
                            },
                          ]
                      }
                      ]"
              response-key="practice_plans"
              object-type="PracticePlan"
              base-url="/api/v1/practice_plans">
            <template v-slot:row="row">
              <tbody class="border border-gray-100 border-b border-t-none border-solid">
              <tr class="relative">
                <td class="p-r-sm" data-title="Template name">
                  <div class="text-gray-700">
                    {{ row.data.name }}
                  </div>
                </td>
                <td data-title="Actions">
                  <button @click="useTemplate(row.data.id)"
                          class="m-l-auto btn no-underline btn-compact bg-gray-50 text-gray-700 flex justify-space-between gap-x-xxs">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>
                    <span class="text-12">
              Use template
            </span>
                  </button>
                </td>
              </tr>
              </tbody>
            </template>
          </filtered-list>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import PracticePlanBlock from './practice_plan_block.vue';
import Spinner from './spinner.vue';
import FilteredList from './filtered_list.vue';

export default {
  name: 'practice-plan-timeline',
  components: {
    Spinner,
    PracticePlanBlock,
    draggable,
    FilteredList,
  },
  props: [
    'dragGroup',
    'planId',
    'readOnly',
    'eventStart',
  ],
  data() {
    return {
      blocks: [],
      loading: false,
      error: false,
      errorMessage: '',
      initialLoadComplete: false,
      unsavedChanges: false,
      templateFormCount: 0,
      isoStart: null,
      parentTemplateId: null,
    };
  },
  created() {
    const vm = this;
    vm.isoStart = vm.eventStart;
    vm.loadPlan();
    vm.$notificationManager.$on('save-practice-plan', vm.handleSaveEvent);
    vm.$notificationManager.$on('create-practice-plan-template', vm.createTemplate);
    vm.$notificationManager.$on('plan-modified', vm.planModified);
    vm.$notificationManager.$on('remove-block', vm.removeBlock);
    vm.$notificationManager.$on('form-shown', vm.handleFormShown);
    vm.$notificationManager.$on('form-hidden', vm.handleFormHidden);
    vm.$notificationManager.$on('replace-block', vm.spliceBlock);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('save-practice-plan', vm.handleSaveEvent);
    vm.$notificationManager.$off('plan-modified', vm.planModified);
    vm.$notificationManager.$off('remove-block', vm.removeBlock);
    vm.$notificationManager.$off('form-shown', vm.handleFormShown);
    vm.$notificationManager.$off('form-hidden', vm.handleFormHidden);
    vm.$notificationManager.$off('replace-block', vm.spliceBlock);
  },
  watch: {
    blocks(newBlocks) {
      if (this.initialLoadComplete) {
        this.unsavedChanges = true;
      } else {
        // Skip the first change of blocks which loads it up from the network
        this.initialLoadComplete = true;
      }
    },
    unsavedChanges(newUnsavedChanges) {
      if (newUnsavedChanges && !this.readOnly) {
        // Enable navigation prompt
        window.onbeforeunload = function () {
          return true;
        };
      } else {
        // Remove navigation prompt
        window.onbeforeunload = null;
      }
    },
  },
  methods: {
    startTime() {
      const vm = this;
      if (vm.isoStart == null) {
        return '';
      }

      return moment(vm.isoStart)
          .format('M/D/YYYY hh:mm a');
    },
    endTime() {
      const vm = this;
      if (vm.isoStart == null) {
        return '';
      }

      if (vm.loading) {
        return 'Calculating...';
      }

      const totalRunTime = vm.fullRunTime();
      return moment(vm.isoStart)
          .add(totalRunTime, 'minutes')
          .format('M/D/YYYY hh:mm a');
    },
    getDraggableClass() {
      const vm = this;

      if (vm.readOnly) {
        return '';
      }

      return 'dropArea';
    },
    handleFormShown(mentionableType) {
      if (mentionableType === 'PracticePlanBlock') {
        $('#practice-plan-container')
            .addClass('dim');
        this.templateFormCount += 1;
      }
    },
    handleFormHidden(mentionableType) {
      if (mentionableType === 'PracticePlanBlock') {
        this.templateFormCount -= 1;
        if (this.templateFormCount === 0) {
          $('#practice-plan-container')
              .removeClass('dim');
        }
      }
    },
    spliceBlock(blockIndex, updatedBlock) {
      const vm = this;
      const { blocks } = vm;
      blocks[blockIndex] = updatedBlock;
      vm.$set(vm.blocks, blocks);
    },
    removeBlock(block, planId) {
      const vm = this;
      if (vm.planId === planId) {
        let indexToSplice = null;
        for (let i = 0; i < vm.blocks.length; i++) {
          const internalBlock = vm.blocks[i];
          if (block === internalBlock) {
            indexToSplice = i;
            break;
          }
        }

        if (indexToSplice !== null) {
          const { blocks } = vm;
          blocks.splice(indexToSplice, 1);
          vm.$set(vm.blocks, blocks);
        }
      }
    },
    planModified(planId) {
      const vm = this;
      if (vm.planId === planId) {
        vm.unsavedChanges = true;
      }
    },
    handleSaveEvent(planId, planName) {
      const vm = this;
      if (vm.planId === planId) {
        vm.savePlan(planName);
      }
    },
    createTemplate(name, planId) {
      const vm = this;
      if (vm.planId === planId) {
        const vm = this;
        if (vm.readOnly) {
          return;
        }

        const blocks = [];
        let error = false;
        let sectionErrorMsg = '';

        // Templates are a bit odd, in the sense that we are always creating new ones,
        // if we are here. Updating happens as if it's a normal practice plan and this method is never called.
        vm.blocks.forEach((block, index) => {
          const blockParams = {
            name: block.name,
            order: index,
            practice_plan_block_category_id: block.practice_plan_block_category.id,
            sections: _.map(block.sections, (s) => {
              // Make sure to nil out the section when creating a template, otherwise creating a template will fail if the section already exists.
              s.id = null;
              return s;
            }),
            template_id: block.template_id,
          };
          blocks.push(blockParams);

          if (_.filter(block.sections, function (s) {
            return !s.name || !s.minutes;
          }).length > 0) {
            error = true;
            sectionErrorMsg = `${sectionErrorMsg}One or more sections in the ${block.name} block is missing a name or length in minutes. `;
          }
        });

        if (error) {
          sectionErrorMsg = `${sectionErrorMsg}Please ensure all sections are not blank and you have tapped 'done' on them, then try creating the template again.`;
          vm.$notificationManager.$emit('practice-plan-save-error', vm.planId, sectionErrorMsg);
          return;
        }

        const params = {
          practice_plan: {
            name: name,
            is_template: true
          }
        };

        const url = vm.$apiService.practicePlansUrl();
        // First create the template
        axios.post(url, params)
            .then((response) => {
              const updateParams = {
                practice_plan: {
                  id: response.data.id,
                  name: name,
                  is_template: true,
                  blocks,
                },
              };
              // Then update it to put the blocks through, because this endpoint is so old I did stupid shit in API land
              const updateUrl = vm.$apiService.practicePlanUrl(response.data.id);
              axios.put(updateUrl, updateParams)
                  .then((response) => {
                    vm.$notificationManager.$emit('show-toast', 'Template created', true);
                    vm.$notificationManager.$emit('practice-template-created');
                  })
                  .catch((error) => {
                    vm.$notificationManager.$emit('practice-plan-save-error', vm.planId, 'Error updating template, please contact support@wrestlingiq.com if the issue persists.');
                  });
            })
            .catch((error) => {
              vm.$notificationManager.$emit('practice-plan-save-error', vm.planId, 'Error saving template, please contact support@wrestlingiq.com if the issue persists.');
            });
      }
    },
    blocksExist() {
      return this.blocks.length !== 0;
    },
    fullRunTime() {
      const vm = this;
      if (vm.blocks.length === 0) {
        return 0;
      }

      return vm.runTimeInMinutes(vm.blocks.length - 1);
    },
    runTimeUntil(index) {
      const vm = this;
      const runTime = vm.runTimeInMinutes(index);

      const relativeTime = moment(vm.isoStart)
          .add(runTime, 'minutes')
          .format('hh:mm a');

      const hours = Math.floor(runTime / 60);
      const minutes = runTime % 60;

      if (hours > 0) {
        const hourString = (hours === 1) ? 'hour ' : 'hours ';
        return `Practice run time ${hours} ${hourString}${minutes} minutes, ${relativeTime}`;
      }
      return `Practice run time ${runTime} minutes, ${relativeTime}`;
    },
    runTimeInMinutes(index) {
      const vm = this;
      let runTime = 0;
      for (let i = 0; i <= index; i++) {
        let sectionRunTime = 0;
        vm.blocks[i].sections.forEach((section) => {
          sectionRunTime += section.minutes;
        });
        runTime += sectionRunTime;
      }
      return runTime;
    },
    savePlan(planName) {
      const vm = this;
      if (vm.readOnly) {
        return;
      }

      const blocks = [];
      let error = false;
      let sectionErrorMsg = '';

      vm.blocks.forEach((block, index) => {
        const blockParams = {
          id: block.id,
          name: block.name,
          order: index,
          practice_plan_block_category_id: block.practice_plan_block_category.id,
          sections: block.sections,
          template_id: block.template_id,
        };
        blocks.push(blockParams);

        if (_.filter(block.sections, function (s) {
          return !s.name || !s.minutes;
        }).length > 0) {
          error = true;
          sectionErrorMsg = `${sectionErrorMsg}One or more sections in the ${block.name} block is missing a name or length in minutes. `;
        }
      });

      if (error) {
        sectionErrorMsg = `${sectionErrorMsg}Please ensure all sections are not blank and you have tapped 'done' on them, then try saving again.`;
        vm.$notificationManager.$emit('practice-plan-save-error', vm.planId, sectionErrorMsg);
        return;
      }

      const params = {
        practice_plan: {
          name: planName,
          // todo the actual updating of the practice plan itself (name) and what not
          parent_template_id: vm.parentTemplateId,
          blocks,
        },
      };

      const url = vm.$apiService.practicePlanUrl(vm.planId);
      axios.put(url, params)
          .then((response) => {
            vm.$notificationManager.$emit('practice-plan-saved', vm.planId);
            vm.unsavedChanges = false;
            vm.$notificationManager.$emit('show-toast', 'Practice plan saved', true);
          })
          .catch((error) => {
            vm.$notificationManager.$emit('practice-plan-save-error', vm.planId, 'Error saving plan, please contact support@wrestlingiq.com if the issue persists.');
          });
    },
    getDraggableOptions() {
      const vm = this;
      return {
        group: {
          name: vm.dragGroup,
          pull: false,
          put: true
        },
        handle: '.block-sorter',
        sort: true
      };
    },
    getTimelineStyles() {
      const vm = this;
      let styles = 'vertical-container dark-timeline';
      if (vm.isEmpty()) {
        styles += ' no-content';
      }

      return styles;
    },
    isEmpty() {
      return this.blocks.length === 0;
    },
    loadPlan() {
      const vm = this;
      vm.loading = true;
      const url = vm.$apiService.practicePlanBlocksUrl(vm.planId);
      vm.$apiService.loadAllPages(url, 'practice_plan_blocks')
          .then((blocks) => {
            vm.blocks = blocks;
            vm.loading = false;
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = `Error retrieving practice plan ${error.toString()}`;
            vm.error = true;
          });
    },
    useTemplate(templateId) {
      const vm = this;
      vm.loading = true;
      const url = vm.$apiService.practicePlanBlocksUrl(templateId);
      // Load up the blocks, but null out the ids so they are re-recreated for this plan.
      vm.$apiService.loadAllPages(url, 'practice_plan_blocks')
          .then((blocks) => {
            vm.blocks = _.map(blocks, (b) => {
              b.id = null;
              b.sections = _.map(b.sections, (s) => {
                s.id = null;
                s.practice_plan_block_id = null;
                return s;
              })
              return b;
            });
            vm.parentTemplateId = templateId;
            vm.loading = false;
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = `Error retrieving template practice plan ${error.toString()}`;
            vm.error = true;
          });
    },
    collapseAll() {
      const vm = this;
      vm.$notificationManager.$emit('collapse-all-blocks', vm.planId);
    },
    expandAll() {
      const vm = this;
      vm.$notificationManager.$emit('expand-all-blocks', vm.planId);
    },
  },
};
</script>
