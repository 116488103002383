<template>
  <div role="form">
    <div class="row">
      <div class="col-xs-12 col-sm-3">
        <div class="relative flex align-items-center gap-x-xxs">
          <label class="form-label">Wrestler Photo</label>
        </div>
        <pintura-photo-uploader
            :access-key="accessKey"
            :endpoint="endpoint"
            :cloudfront-base-url="cloudfrontBaseUrl"
            :existing-attachments="existingAttachments"
            :attachable-id="wrestlerId"
            attachable-type="WrestlerProfile"
            prefix="profile_photos/"
            imageCropAspectRatio="1"
            @photos-changed="photosChanged"
        ></pintura-photo-uploader>
        <p class="m-t-none m-b-m text-10 text-gray-500 text-center visible-xs">On a mobile device? You can take a photo or upload from your photo library.</p>
      </div>
      <div class="col-xs-12 col-sm-8 col-sm-offset-1">
        <div class="form-group m-t-only-xs-m">
          <label>First Name</label>
          <input v-model="first_name" type="text" class="form-control font-no-ios-zoom">
        </div>
        <div class="form-group">
          <label>Last Name</label>
          <input v-model="last_name" type="text" class="form-control font-no-ios-zoom">
        </div>
        <div class="form-group">
          <label>Weight</label>
          <input v-model="weight_class" type="text" class="form-control font-no-ios-zoom">
        </div>
        <div class="form-group">
          <label for="academic_class">
            Academic Class <span v-if="!requireAcademicClass">(optional)</span>
          </label>
          <select id="academic_class" v-model="academic_class" class="form-control">
            <option selected disabled>Choose academic class...</option>
            <optgroup label="Pre-K to 12">
              <option value="Pre-K">Pre-K</option>
              <option value="Kindergarten">Kindergarten</option>
              <option value="1st grade">1st grade</option>
              <option value="2nd grade">2nd grade</option>
              <option value="3rd grade">3rd grade</option>
              <option value="4th grade">4th grade</option>
              <option value="5th grade">5th grade</option>
              <option value="6th grade">6th grade</option>
              <option value="7th grade">7th grade</option>
              <option value="8th grade">8th grade</option>
              <option value="9th grade">9th grade</option>
              <option value="10th grade">10th grade</option>
              <option value="11th grade">11th grade</option>
              <option value="12th grade">12th grade</option>
            </optgroup>
            <optgroup label="College">
              <option value="Freshmen">Freshmen</option>
              <option value="RS Freshmen">RS Freshmen</option>
              <option value="Sophomore">Sophomore</option>
              <option value="RS Sophomore">RS Sophomore</option>
              <option value="Junior">Junior</option>
              <option value="RS Junior">RS Junior</option>
              <option value="Senior">Senior</option>
              <option value="RS Senior">RS Senior</option>
              <option value="Grad Student">Grad Student</option>
            </optgroup>
            <optgroup label="Other">
              <option value="other">Other</option>
              <option value="Class of 2022">Class of 2022</option>
              <option value="Class of 2023">Class of 2023</option>
              <option value="Class of 2024">Class of 2024</option>
              <option value="Class of 2025">Class of 2025</option>
              <option value="Class of 2026">Class of 2026</option>
              <option value="Class of 2027">Class of 2027</option>
            </optgroup>
          </select>
        </div>
        <div class="form-group">
          <label>Date of Birth (optional)</label>
          <the-mask mask="##/##/####"
                    placeholder="mm/dd/yyyy"
                    v-model="dob"
                    class="form-control"
                    :masked="true"
                    ref="date"
                    data-cy="dob"
                    type="tel">
          </the-mask>
        </div>
        <div class="form-group" v-if="showExperience">
          <label>Experience (optional)</label>
          <select v-model="experience" class="form-control">
            <option selected disabled>Choose experience level...</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="button-area">
          <div class="p-t-lg pull-left">
            <template v-if="showDelete">
              <a class="text-danger" v-on:click="showDeleteForm" v-show="!deleteForm">Graduate wrestler...</a>
              <delete-wrestler-form v-show="deleteForm" :wrestler-id="wrestlerId"></delete-wrestler-form>
            </template>
          </div>
          <div class="text-right pull-right">
            <div v-show="error">
              <small>
                <span class="text-danger">{{ errorMessage }}</span>
              </small>
            </div>
            <ladda-button @lbClicked="updateWrestler" el-class="btn-primary" :loading="loading">
              {{ buttonCta }}
            </ladda-button>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import DeleteWrestlerForm from './delete_wrestler_form.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { TheMask } from 'vue-the-mask';
import { photoUploadMix } from './mixins/photo_upload_mix.js';
import PinturaPhotoUploader from './pintura_photo_uploader.vue';

export default {
  name: 'wrestler-edit',
  components: { PinturaPhotoUploader, LaddaButton, DeleteWrestlerForm, TheMask },
  mixins: [photoUploadMix],
  props: {
    buttonCta: {
      type: String,
      default: 'Update Wrestler',
    },
    wrestlerId: {
      type: Number,
      required: true,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showExperience: {
      type: Boolean,
      default: true,
    },
    shouldRedirect: {
      type: Boolean,
      default: true
    },
    requireAcademicClass: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      first_name: '',
      last_name: '',
      weight_class: '',
      academic_class: '',
      dob: '',
      experience: '',

      deleteForm: false,
    };
  },
  created() {
    const vm = this;
    vm.getWrestler();
    vm.$notificationManager.$on('hide-delete-wrestler-form', vm.handleHideDeleteFormEvent);
    vm.$notificationManager.$on('wrestler-deleted', vm.wrestlerDeleted);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('hide-delete-wrestler-form', vm.handleHideDeleteFormEvent);
    vm.$notificationManager.$off('wrestler-deleted', vm.wrestlerDeleted);
  },
  computed: {
    isoDob() {
      if (!this.dob || this.dob.length === 0) {
        return null;
      }

      return moment(this.dob, 'MM/DD/YYYY').toISOString();
    }
  },
  methods: {
    showDeleteForm() {
      this.deleteForm = true;
    },
    handleHideDeleteFormEvent(wrestlerId) {
      const vm = this;
      if (wrestlerId === vm.wrestlerId) {
        vm.deleteForm = false;
      }
    },
    wrestlerDeleted(wrestlerId) {
      if (wrestlerId === this.wrestlerId) {
        window.location.assign('/wrestlers');
      }
    },
    getWrestler() {
      const vm = this;
      vm.loading = true;
      const wrestlerUrl = `api/v1/wrestlers/${vm.wrestlerId}`;
      axios.get(wrestlerUrl)
        .then((response) => {
          vm.loading = false;
          const { data } = response;
          vm.first_name = data.first_name;
          vm.last_name = data.last_name;
          vm.weight_class = data.weight_class;
          vm.academic_class = data.academic_class;
          if (data.dob) {
            vm.dob = moment(data.dob).format('MM/DD/YYYY');
          }
          vm.experience = data.experience;
        })
        .catch((error) => {
          console.log(error);
          vm.errorMessage = 'Error retrieving wrestler info';
          vm.error = true;
        });
    },
    validateNotEmpty(first_name, last_name, weight_class) {
      const vm = this;
      let errorMessage = 'Please ensure ';
      let error = false;
      if (first_name == null || first_name.length === 0) {
        errorMessage += 'first name, ';
        error = true;
      }
      if (last_name == null || last_name.length === 0) {
        errorMessage += 'last name, ';
        error = true;
      }
      if (weight_class == null || weight_class.length === 0) {
        errorMessage += 'weight class, ';
        error = true;
      }
      if (this.requireAcademicClass && this.academic_class.length === 0) {
        errorMessage += 'academic class, ';
        error = true;
      }
      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        return false;
      }
      return true;
    },
    updateWrestler: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      const { first_name } = vm;
      const { last_name } = vm;
      const { weight_class } = vm;

      if (vm.validateNotEmpty(first_name, last_name, weight_class)) {
        vm.loading = true;
        const params = {
          wrestler_profile: {
            first_name: vm.first_name,
            last_name: vm.last_name,
            weight_class: vm.weight_class,
            academic_class: vm.academic_class,
            experience: vm.experience,
            dob: vm.isoDob,
            profile_photos_attributes: vm.attachmentParams,
          },
        };
        const url = vm.$apiService.wrestlerUrl(vm.wrestlerId);
        axios.put(url, params)
          .then((response) => {
            vm.loading = false;
            response = response.data;
            vm.$notificationManager.$emit('wrestler-updated', vm.wrestlerId);
            vm.$notificationManager.$emit('show-toast', `${vm.first_name} ${vm.last_name} updated`, true);
            if (vm.shouldRedirect) {
              window.location.assign(`/wrestlers/${vm.wrestlerId}`);
            }
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
      }
    }, 500),
  },
};
</script>
