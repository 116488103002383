<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="row">
        <div class="col-xs-6 col-md-8">
          <h1>
            {{ report.name }}<span v-if="rosterName !== ''">, {{ rosterName }}</span>
          </h1>
          <p class="m-b-none"><small>
            Report generated on {{ processedAt() }}
          </small></p>
        </div>
        <div class="col-xs-6 col-md-4 text-right">
          <div class="ibox-tools">
            <div class="m-t-sm flex align-items-center justify-end gap-x-lg">
              <div class="btn-group" v-if="hasCharts">
                <button class="btn btn-compact" :class="{ 'btn-primary': showingTable, 'btn-white': !showingTable }" type="button" @click="showingTable = true">
                  <i class="fa fa-table" aria-hidden="true"></i>&nbsp;<span class="hidden-xs">Table</span>
                </button>
                <button class="btn btn-compact" :class="{ 'btn-primary': !showingTable, 'btn-white': showingTable }" type="button" @click="showingTable = false">
                  <i class="fa fa-bar-chart" aria-hidden="true"></i>&nbsp;<span class="hidden-xs">Charts</span>
                </button>
              </div>
              <a class="btn btn-primary btn-compact" @click="downloadCSV()">
                <i class="fa fa-download"></i> <span class="hidden-xs">Download CSV</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div class="table-responsive" v-if="showingTable">
        <table id="report-table" class="table">
          <thead>
            <tr>
              <th v-for="header in headers">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows">
            <td v-for="(item, index) in row" v-html="parseItem(item, index)">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!showingTable && hasCharts" class="grid grid-cols-2 sm-grid-cols-1">
        <div v-for="(chart, index) in charts">
          <highchart-wrapper :payload="chart.payload" :container-id="`chart-${index}`"></highchart-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';
import { urlHelperMixin } from '../mixins/url_helper_mix';
import HighchartWrapper from './highchart_wrapper.vue';

// This row report is for server side generated reports. No fancy logic, it just throws down rows as given.
export default {
  name: 'row-report',
  components: { HighchartWrapper },
  mixins: [downloadMix, formattingMixin, urlHelperMixin],
  props: {
    report: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      headers: [],
      rows: [],
      columnMeta: {},
      charts: [],
      showingTable: true,
    };
  },
  computed: {
    rosterName() {
      return this.report.result.roster_name ?? "";
    },
    hasCharts() {
      return this.charts.length > 0;
    }
  },
  mounted() {
    let rows = this.report.result.rows.objects;
    this.columnMeta = this.report.result.rows.metadata ?? {};
    this.headers = rows.shift();
    this.rows = rows;

    this.charts = this.report.result.charts ?? [];
  },
  methods: {
    parseItem(item, index) {
      let metaKey = this.headers[index];
      let colMeta = this.columnMeta[metaKey];
      if (colMeta?.contains_link) {
        return this.urlify(item, false);
      }

      return item;
    },
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at)
          .format('M/D/YYYY, hh:mm a');
    },
    downloadCSV() {
      const vm = this;

      const fileName = `${vm.report.name} ${vm.rosterName} - ${vm.processedAt()}.csv`;
      const rows = _.concat([vm.headers], vm.rows);
      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
