<template>
  <div>
    <template v-if="tokenCreated">
      <h1>Your kiosk is ready to use!</h1>
      <p>
        Go to <a href="https://kiosk.wrestlingiq.com" target="blank"><strong>https://kiosk.wrestlingiq.com</strong></a>
        on the device you intend to use this WrestlingIQ Kiosk on.
      </p>
      <p>
        At the device sign in screen, enter this code: <strong>{{ this.kioskToken.token }}</strong>
      </p>
    </template>
    <template v-else>
      <div role="form">
        <div class="form-group m-t-m">
          <label>Kiosk name</label>
          <input v-model="name" type="text" class="form-control" placeholder="e.g. Front desk iPad">
        </div>
        <div class="button-area text-right">
          <div v-show="error">
            <small>
              <span class="text-danger">{{ errorMessage }}</span>
            </small>
          </div>
          <ladda-button @lbClicked="createToken" el-class="btn-primary" :loading="loading">
            Create Kiosk
          </ladda-button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { errorableMix } from './mixins/errorable_mix';

export default {
  name: 'kiosk-token-create',
  components: { LaddaButton },
  mixins: [errorableMix],
  data() {
    return {
      loading: false,
      name: '',
      kioskToken: null,
    };
  },
  computed: {
    tokenCreated() {
      return this.kioskToken !== null;
    },
  },
  methods: {
    createToken: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (vm.name.length === 0) {
        this.errorMessage = 'A Kiosk name is required';
        this.error = true;
        return;
      }

      vm.loading = true;
      const params = {
        kiosk_token: {
          name: vm.name,
        },
      };
      const url = vm.$apiService.kioskTokensUrl();
      axios.post(url, params)
        .then((response) => {
          vm.loading = false;
          vm.kioskToken = response.data;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support@wrestlingiq.com';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
