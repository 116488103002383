<template>
  <div :class="cardClasses">
    <div class="row">
      <div class="col-xs-6">
        <div v-show="!editingWrestler">
          <h3 class="text-semi m-b-none">
            {{ wrestlerProfile.full_name }}
            <span v-if="saving" class="text-success">
                          <spinner :inline="true"></spinner>
                      </span>
            <span v-if="error" class="text-danger">
                          Error saving attendance
                      </span>
          </h3>
          <div class="flex flex-wrap gap-x-xxs gap-y-xxs items-center">
            <tag
                v-for="tag in rosterTagsWithCount(wrestlerProfile)"
                :tag="tag"
                :key="tag.abbreviation"
                :count="tag.count"
            ></tag>
          </div>
          <div class="details m-t-xxs">
            <p>
              <template v-if="wrestlerProfile.weight_class">
                {{ wrestlerProfile.weight_class }} lbs,
              </template>
              <template v-else>
                                <span class="text-danger">
                                    missing weight class,
                                </span>
              </template>
              <template v-if="wrestlerProfile.age">
                {{ wrestlerProfile.age }} years old,
              </template>
              <template v-else>
                                <span class="text-danger">
                                    missing age
                                </span>
              </template>
              <template v-if="wrestlerProfile.experience">
                {{ capitalize(wrestlerProfile.experience) }}
              </template>
              <template v-else>
                                <span class="text-danger">
                                    missing experience level
                                </span>
              </template>
              &nbsp;&nbsp;<a @click.prevent="editWrestlerTapped()" style="text-decoration: underline">Edit...</a>
            </p>
            <p v-if="editable" class="edit-button">
            </p>
          </div>
        </div>
        <div v-if="editingWrestler">
          <scramble-wrestler-edit :wrestler-profile="wrestlerProfile"></scramble-wrestler-edit>
        </div>
      </div>
      <div class="col-xs-6 text-right p-b-sm">
        <button :class="presentClasses" @click.prevent="statusTapped('present')"><i class="fa fa-check"></i>
          Present
        </button>
        <button :class="absentClasses" @click.prevent="statusTapped('absent')"><i class="fa fa-times"></i>
          Absent
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from './spinner.vue';
import ScrambleWrestlerEdit from './scramble_wrestler_edit.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { checkInMix } from './mixins/check_in_mix';
import { taggableMix } from '../../shared/taggable_mix';
import Tag from '../../shared/tag.vue';

export default {
  name: 'match-check-in-form',
  components: { LaddaButton, ScrambleWrestlerEdit, Spinner, Tag },
  mixins: [checkInMix, taggableMix],
  props: [
    'wrestlerProfile',
    'checkIn',
    'eventId',
    'editable',
  ],
  data() {
    // see CheckInMix for majority of data
    return {
      editingWrestler: false,
    };
  },
  created() {
    this.$notificationManager.$on('scramble-wrestler-updated', this.wrestlerUpdated);
  },
  destroyed() {
    this.$notificationManager.$off('scramble-wrestler-updated', this.wrestlerUpdated);
  },
  methods: {
    wrestlerUpdated(wrestlerId, wrestlerProfile) {
      if (wrestlerId === this.wrestlerProfile.id) {
        this.editingWrestler = false;
      }
    },
    editWrestlerTapped() {
      this.editingWrestler = true;
    },
    capitalize(string) {
      return _.capitalize(string);
    },
    save: _.throttle(function () {
      const vm = this;

      if (vm.saving) {
        return;
      }

      vm.saving = true;
      vm.editingStatus = false;
      const params = {
        check_in: {
          wrestler_profile_id: vm.wrestlerProfileId,
          status: vm.checkInStatus,
        },
      };

      if (vm.checkInId) {
        vm.update(params);
      } else {
        vm.create(params);
      }
    }, 500),
    update(params) {
      const vm = this;
      const url = vm.$apiService.eventCheckInUrl(vm.eventId, vm.checkInId);
      axios.put(url, params)
        .then((response) => {
          vm.successHandler(response.data);
        })
        .catch((error) => {
          vm.errorHandler(error);
          vm.editingStatus = true;
        });
    },
    create(params) {
      const vm = this;
      const url = vm.$apiService.checkInsUrl(vm.eventId);
      axios.post(url, params)
        .then((response) => {
          vm.successHandler(response.data);
        })
        .catch((error) => {
          vm.errorHandler(error);
          vm.editingStatus = true;
        });
    },
    errorHandler(error) {
      const vm = this;
      vm.saving = false;
      vm.error = true;
    },
    successHandler(data) {
      const vm = this;
      if (data.id) {
        vm.checkInId = data.id;
      }
      vm.$notificationManager.$emit('wrestler-checked-in', vm.checkInId, vm.checkInStatus, vm.wrestlerProfile, '');
      const toastText = `${vm.wrestlerProfile.full_name} marked as ${_.lowerCase(vm.readableStatus(false))}`;
      vm.$notificationManager.$emit('show-toast', toastText, true);
      vm.saving = false;
      if (vm.checkInStatus === 'unknown') {
        vm.editingStatus = true;
      }
    },
  },
};
</script>
