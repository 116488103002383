import 'v-tooltip/dist/v-tooltip.css';
import './styles/tooltip.scss';
import './styles/wrestling.scss';

import Rails from '@rails/ujs';

import 'bootstrap';
import 'metismenu';

import '../src/plugins/inspinia.js';
import '../src/plugins/pace.js';

import LocalTime from 'local-time';
import videoSetup from './videoSetup';

import 'fullcalendar';
import 'trix';

import wrestlingSetup from './wrestlingSetup';

Rails.start();
videoSetup();
LocalTime.start();
wrestlingSetup();
