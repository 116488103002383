<template>
  <div>
    <div @click.prevent="open = !open" class="flex align-items-baseline gap-xs cursor-pointer">
      <i class="fa m-r-xs" :class="open ? 'fa-caret-down': 'fa-caret-right' "></i>
      <slot name="title"></slot>
      <p class="m-t-none m-b-none text-gray-300" v-if="count">({{ count }})</p>
    </div>
    <div v-show="open">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'vertical-expander',
  props: {
    // The Object type to auto expand for if we receive an event of > 1 found for that
    expandFor: {
      type: String,
      default: null,
    },
    defaultOpen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      open: false,
      count: null,
    };
  },
  created() {
    const vm = this;
    vm.$notificationManager.$on('total-result-count', vm.checkExpansion);
  },
  mounted() {
    this.open = this.defaultOpen;
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('total-result-count', vm.checkExpansion);
  },
  methods: {
    checkExpansion(objectType, count) {
      if (objectType === this.expandFor) {
        this.count = count;
        if (this.count > 0) {
          // Auto expand if we have found something!
          this.open = true;
        }
      }
    }
  }
};
</script>
