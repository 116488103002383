<template>
    <div>
        <div v-if="show" class="row">
            <div class="col-xs-12">
                <div class="alert alert-success alert-dismissible">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <p>
                        {{ name }} saved. Would you like to require new signatures from the team?
                    </p>
                    <sign-again-form :document="constructedDoc()" :alert-mode="true" :new-doc="newDoc"></sign-again-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SignAgainForm from './sign_again_form.vue';

export default {
  name: 'recent-doc-sign-again',
  components: { SignAgainForm },
  props: [
    'docId',
    'name',
    'newDoc',
  ],
  data() {
    return {
      show: true,
    };
  },
  created() {
    const vm = this;
    vm.$notificationManager.$on('hide-sign-again-document-form', vm.hideAll);
    vm.$notificationManager.$on('document-sign-again', vm.hideAll);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('hide-sign-again-document-form', vm.hideAll);
    vm.$notificationManager.$off('document-sign-again', vm.hideAll);
  },
  methods: {
    constructedDoc() {
      const vm = this;
      return {
        id: vm.docId,
        name: vm.name,
      };
    },
    hideAll(document) {
      if (document.id === this.docId) {
        this.show = false;
      }
    },
  },
};
</script>
