<template>
    <div class="mark-paid-form modify-form" role="form">
        <div class="m-t-sm form-group">
            <label :for="idFor('paid_on')">Paid On</label>
            <div>
              <date-picker v-model="paidOn" :date="paidOn"></date-picker>
            </div>
        </div>
        <div class="form-group">
            <label :for="idFor('payment_method_select')">Payment Method</label>
            <select v-model="paymentMethod" :id="idFor('payment_method_select')" class="form-control m-b" name="payment_method">
                <option selected disabled>Choose payment method...</option>
                <option value="cash">Cash</option>
                <option value="check">Check</option>
            </select>
        </div>
        <div class="form-group">
            <label :for="idFor('notes')">Notes</label>
            <input :id="idFor('notes')" v-model="notes" type="text" class="form-control">
        </div>
        <div>
            <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
            <br/>
            <button v-on:click="hideMarkPaidForm" class="btn btn-default">Cancel</button>
            <ladda-button @lbClicked="markPaidPressed" el-class="btn-primary" :loading="loading">
                Mark paid
            </ladda-button>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';
import DatePicker from './date_picker.vue';

export default {
  name: 'mark-paid-form',
  components: { LaddaButton, DatePicker },
  props: [
    'manualInvoice',
  ],
  data() {
    return {
      loading: false, // Loading is a network variable (hitting the api)
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      notes: '',
      paymentMethod: '',
      paidOn: null,
    };
  },
  methods: {
    idFor(name) {
      return `${name}_${this.manualInvoice.id}`;
    },
    hideMarkPaidForm() {
      const vm = this;
      vm.$notificationManager.$emit('hide-mark-paid-form', vm.manualInvoice);
    },
    markPaidPressed: _.throttle(function () {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }

      if (!vm.paidOn || vm.paymentMethod === '') {
        vm.errorMessage = "Please enter a payment method and when this was paid for."
        vm.error = true;
        return;
      }
      vm.error = false;
      vm.loading = true;

      const url = vm.$apiService.manualInvoiceUrl(vm.manualInvoice.id);

      const params = {
        manual_invoice: {
          status: 'paid',
          amount_paid: vm.manualInvoice.amount_due,
          paid_at: vm.paidOn.toISOString(),
          notes: vm.notes,
          payment_method: vm.paymentMethod,
        },
      };

      axios.put(url, params)
        .then((response) => {
          vm.loading = false;

          vm.$notificationManager.$emit('show-toast', 'Invoice paid', true);
          // this is weird, becaue the paged list that shows this is a payment plan object, not manual invoice
          vm.$notificationManager.$emit('invoice-paid', vm.manualInvoice, 'PaymentPlan');
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
