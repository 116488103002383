<template>
  <div class="row">
    <div class="col-xs-12">
      <a @click="toggleForm" v-if="!showForm" class="text-danger"><small>Deactivate coach...</small></a>
      <div class="should-dim modify-form card-bordered-dashed" v-if="showForm">
        <div>
          Are you sure you want to deactivate this coach?<br/><br/>
          This will remove their access to your team's WrestlingIQ account and is <strong>irreversible</strong>.
        </div>
        <div class="">
          <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
          <br/>
          <button v-on:click="toggleForm" class="btn btn-default">Cancel</button>
          <ladda-button @lbClicked="deleteCoachPressed" el-class="btn-danger" :loading="loading">
            Yes, deactivate
          </ladda-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'deactivate-coach-form',
  components: { LaddaButton },
  props: {
    coachId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showForm: false,
      loading: false, // Loading is a network variable (hitting the api)

      error: false,
      errorMessage: 'Error, please try again later or contact support@wrestlingiq.com',
    };
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    deleteCoachPressed: _.throttle(function () {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;

      const url = vm.$apiService.coachUrl(vm.coachId);
      axios.delete(url)
          .then((response) => {
            vm.loading = false;

            vm.$notificationManager.$emit('show-toast', 'Coach deactivated', true);
            window.location.assign('/coaches');
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support@wrestlingiq.com';
            vm.error = true;
          });
    }, 500),
  },
};
</script>
