<template>
  <div>
    <div class="flex flex-col gap-xs">
      <div class="invite">
        <ladda-button @lbClicked="resendInvite" el-class="btn-primary btn-block w-full" :loading="loading" v-if="!inviteResent">
          {{ buttonCta }}
        </ladda-button>
        <p v-if="inviteResent">
          Invite resent
        </p>
      </div>
      <div v-if="showUpdateEmail">
        <button class="btn btn-default btn-block w-full" @click="showUpdateEmailForm = true">
          Update Invite Email
        </button>
      </div>
    </div>
    <div v-if="showUpdateEmailForm" role="form" class="border-top p-t-sm m-t-sm">
      <div class="form-group">
        <label>Change invite e-mail</label>
        <p class="text-10">Updating the email will also resend the invite</p>
        <input v-model="newEmail" type="email" class="form-control">
      </div>
      <div class="grid grid-cols-2 gap-sm">
        <button name="button" @click="showUpdateEmailForm = false" class="btn btn-default w-full">Cancel</button>
        <div>
          <ladda-button @lbClicked="putInvite" el-class="btn-primary w-full" :loading="loading">
            Update
          </ladda-button>
        </div>
      </div>
      <div v-show="error">
        <small>
          <span class="text-danger">{{ errorMessage }}</span>
        </small>
      </div>
    </div>
  </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { errorableMix } from './mixins/errorable_mix';

export default {
  name: 'resend-invite',
  components: { LaddaButton },
  mixins: [errorableMix],
  props: {
    inviteToken: {
      type: String,
      required: true,
    },
    buttonCta: {
      type: String,
      default: "Resend",
    },
    currentInviteEmail: {
      type: String,
      required: true,
    },
    showUpdateEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      inviteResent: false,
      showUpdateEmailForm: false,
      newEmail: '',
    };
  },
  mounted() {
    this.newEmail = this.currentInviteEmail;
  },
  methods: {
    putInvite() {
      const vm = this;

      if (vm.loading === true) {
        return;
      }
      vm.loading = true;
      const params = { invite: {
        email: vm.newEmail,
        }
      };
      const url = vm.$apiService.inviteUrl(this.inviteToken);
      axios.put(url, params)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit('show-toast', `Email updated and invite resent`, true);
          // only reload teh page after a second delay
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          vm.loading = false;
          const errorMsg = vm.parseErrorResponse(error);
          vm.showError(errorMsg);
        });
    },
    resendInvite: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      vm.loading = true;
      const url = vm.$apiService.resendInviteUrl(this.inviteToken);
      axios.post(url, {})
          .then((response) => {
            vm.loading = false;
            vm.inviteResent = true;
            vm.$notificationManager.$emit('show-toast', `Invite sent again`, true);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error sending invite, please try again later or contact support@wrestlingiq.com';
            vm.error = true;
          });
    }, 500),
  },
};
</script>
