<template>
  <div class="grid grid-cols-1 gap-4">
    <div id="note-create-form">
      <wrestler-note-form
          v-if="showNoteForm"
          :wrestler-id="wrestlerId"
          :existing-note-content="existingNoteContent"
          :existing-note-id="existingNoteId"
          :suggest-url="mentionSuggestUrl"
          :access-key="accessKey"
          :auth-token="authToken"
          :cloudfront-base-url="cloudfrontBaseUrl"
          :endpoint="endpoint"
          v-on:cancel-note-form="removeNoteForm"
          v-on:note-updated="removeNoteForm"
          v-on:note-created="removeNoteForm"
      >
      </wrestler-note-form>
    </div>
    <div class="notepad-card flex cursor-pointer" @click="showNoteCreateForm" v-if="!showNoteForm">
      + Add Note
    </div>
    <paged-list response-key="notes" object-type="Note" help-text="" :base-url="baseUrl" empty-text=" " container-class="grid grid-cols-1 gap-4">
      <template v-slot:list="slotProps">
        <div class="notepad-card flex w-full">
          <div class="flex-col flex m-l-m w-full">
            <div class="flex justify-space-between align-items-baseline">
              <h4 class="m-y-none"><span class="font-heavy">Note</span> by {{ slotProps.result.author.display_name }}</h4>
              <div class="flex gap-x-xs font-small">
                <p v-if="isAuthorOf(slotProps.result)"><a @click.prevent="showUpdateFormFor(slotProps.result)">Edit</a></p>
                <p class="color-gray-500 m-y-none">{{ createdAt(slotProps.result) }}</p>
              </div>
            </div>
            <div class="m-t-sm trix-content" v-html="slotProps.result.content"></div>
          </div>
        </div>
      </template>
    </paged-list>
  </div>
</template>
<script>
import WrestlerNoteForm from './wrestler_note_form.vue';
import PagedList from './paged_list.vue';

export default {
  name: 'wrestler-notes-list',
  components: {
    PagedList,
    WrestlerNoteForm
  },
  props: {
    wrestlerId: {
      type: Number,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    currentAuthorId: {
      type: Number,
      required: true
    },
    currentAuthorType: {
      type: String,
      required: true,
    },
    mentionSuggestUrl: {
      type: String,
      required: false,
    },
    accessKey: {
      type: String,
      required: false,
    },
    authToken: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      noteForEdit: null,
      showNoteForm: false,
    }
  },
  computed: {
    existingNoteContent() {
      return this.noteForEdit?.content ?? null;
    },
    existingNoteId() {
      return this.noteForEdit?.id ?? null;
    },
  },
  methods: {
    showNoteCreateForm() {
      this.showNoteForm = true;
      document.getElementById('note-create-form').scrollIntoView({ behavior: 'smooth' });
    },
    createdAt(note) {
      return moment(note.created_at).format('MMM Do, YYYY hh:mm a');
    },
    isAuthorOf(note) {
      return note.author.id === this.currentAuthorId && note.author.author_type === this.currentAuthorType;
    },
    showUpdateFormFor(note) {
      // Make sure the old trix editor / form gets blown away
      this.noteForEdit = null;
      this.showNoteForm = false;

      // Create the new one
      this.$nextTick(function () {
        this.noteForEdit = note;
        this.showNoteCreateForm();
      });
    },
    removeNoteForm() {
      this.noteForEdit = null;
      this.showNoteForm = false;
    }
  }
};
</script>
