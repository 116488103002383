<template>
  <div v-if="!useSvg" class="sk-spinner sk-spinner-fading-circle" v-bind:style="styles">
    <div v-for="n in 12" :key="n" :class="`sk-circle${n} sk-circle`"></div>
  </div>
  <svg v-else class="animate-spin w-4 h-4" v-bind:style="styles" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle class="opacity-25" cx="12" cy="12" r="10" :stroke="themeColors.stroke" stroke-width="4" fill="none"></circle>
    <path class="opacity-75" :fill="themeColors.fill" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</template>
<script>
export default {
  name: 'spinner',
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    ml: {
      type: Number,
      default: 0,
    },
    useSvg: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'green',
      validator: (value) => ['green', 'light'].includes(value),
    },
  },
  computed: {
    styles() {
      if (!this.inline) {
        return {};
      }

      if (this.ml) {
        return {
          display: 'inline-block',
          width: '10px',
          height: '10px',
          'margin-left': `${this.ml}px`,
        };
      }

      return {
        display: 'inline-block',
        width: '10px',
        height: '10px',
      };
    },
    themeColors() {
      return this.theme === 'green'
        ? { stroke: '#1ab394', fill: '#1ab394' }
        : { stroke: '#ffffff', fill: '#ffffff' };
    },
  },
};
</script>
