<template>
  <div>
    <a v-if="!confirm" @click="confirm = true" class="text-gray-800">
      <span>
        <VDropdown theme="info-tooltip">
            <!-- This will be the popover target (for the events and position) -->
          <svg xmlns="http://www.w3.org/2000/svg" style="height: 2rem; width: 2rem;" class="color-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
          </svg>
          <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>
                <p class="header">
                  Archive scholarship code
                </p>
                <p>
                  This will deactivate the coupon {{ couponCode}} so new registrants can't use it; however, archiving a coupon does not affect any registrants who have already used it.
                </p>
              </div>
            </template>
          </VDropdown>
      </span>
    </a>
    <div v-else
         class="archive-form should-dim modify-form m-t-sm card-bordered-dashed text-left">
      <div>
        <p>
          Are you sure you want to archive {{ couponCode }}? Archiving will make it impossible to redeem for new registrations, but will not affect any registrants who have already used it.
        </p>
        <p>
          <strong>Archiving is permanent. Only archive a coupon if you are sure you do not need it anymore.</strong>
        </p>
      </div>
      <div class="">
        <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
        <br/>
        <button v-on:click="confirm = false" class="btn btn-default m-b-sm m-r-sm">Cancel</button>
        <ladda-button @lbClicked="archivePressed" el-class="btn-danger m-b-sm" :loading="loading">
          Yes, archive
        </ladda-button>
      </div>
    </div>
  </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'archive-coupon',
  components: { LaddaButton },
  props: {
    couponId: {
      type: Number,
      required: true
    },
    couponCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      confirm: false,
      loading: false, // Loading is a network variable (hitting the api)

      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  methods: {
    archivePressed: _.throttle(function () {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;

      const params = {
        coupon: {
          archived: true
        }
      }
      const url = vm.$apiService.couponUrl(this.couponId)
      axios.put(url, params)
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', "Archived scholarship code...", true);
            window.location.reload();
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
    }, 500),
  },
};
</script>
