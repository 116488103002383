<template>
  <div>
    <template v-if="currentState === 'core_info'">
      <div class="ibox registration-questions">
        <div class="ibox-title">
          <h1>Is this info still accurate for {{ profileName }}?</h1>
          <p class="m-t-xs font-small">Please make any necessary updates, then tap save</p>
        </div>
        <div class="ibox-content p-t-md">
          <wrestler-edit
              :wrestler-id="profileId"
              :show-delete="false"
              :endpoint="endpoint"
              :access-key="accessKey"
              :existing-attachments="existingAttachments"
              :cloudfront-base-url="cloudfrontBaseUrl"
              :require-academic-class="requireAcademicClass"
              :show-experience="false"
              :button-cta="buttonCta"
              :should-redirect="false"
          >
          </wrestler-edit>
        </div>
      </div>
    </template>
    <template v-else-if="currentState === 'usaw'">
      <usaw-manager
          :wrestler-id="profileId"
          :most-recent-usaw-id="mostRecentUsawId"
          :most-recent-usaw-expired="mostRecentUsawExpired"
          :usaw-config="usawConfig"
          :profile-name="profileName"
          :team-name="teamName"
          :endpoint="endpoint"
          :access-key="accessKey"
          :cloudfront-base-url="cloudfrontBaseUrl"
          v-on:usaw-completed="goToNextState"
      >
      </usaw-manager>
    </template>
    <template v-else>
      <div class="ibox registration-questions">
        <div class="ibox-title">
          <h1>Additional info needed for {{ profileName }}</h1>
          <p class="m-t-xs font-small">Finally, fill out {{ teamName }} specific info, then tap save</p>
        </div>
        <div class="ibox-content p-t-md">
          <registration-info-form
              :funnel="funnel"
              profile-type="WrestlerProfile"
              :profile-id="profileId"
              button-cta="Save"
              :redirect="redirect"
              :endpoint="endpoint"
              :access-key="accessKey"
              :cloudfront-base-url="cloudfrontBaseUrl"
              :session-param="sessionParam"
          >
          </registration-info-form>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { funnelMixin } from './mixins/funnel_mix';
import RegistrationInfoForm from '../../shared/registration_info_form.vue';
import WrestlerEdit from './wrestler_edit.vue';
import UsawManager from './usaw_manager.vue';
/*
 * This component provides a wrapper around sub components for various pieces of info
 * Generally speaking, we have
 * 1. The core attributes on the wrestler itself, defined by WIQ (name, weight, grade, dob)
 * 2. Any configured USAW information needed
 * 3. Any configured registration questions needed by the club
 *
 * In general I want that to be on the same page, and feel like it's part of the same process
 * even if they are different sub components under the hood.
 */
export default {
  name: 'wrestler-full-info-collector',
  mixins: [funnelMixin],
  components: {
    UsawManager,
    WrestlerEdit,
    RegistrationInfoForm
  },
  props: {
    accessKey: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
    existingAttachments: {
      type: Array,
      default() { return []; },
    },
    redirect: {
      type: String,
      required: false,
    },
    profileId: {
      type: Number,
      required: true,
    },
    mostRecentUsawId: {
      type: String,
      default: ''
    },
    mostRecentUsawExpired: {
      type: String,
      default: ''
    },
    profileType: {
      type: String,
      required: true,
    },
    profileName: {
      type: String,
      required: false,
    },
    buttonCta: {
      type: String,
      default: 'Save',
    },
    sessionParam: {
      type: String,
      required: false,
    },
    teamName: {
      type: String,
      default: '',
    },
    funnel: {
      type: Boolean,
      default: false,
    },
    verifyCoreInfo: {
      type: Boolean,
      default: true,
    },
    requireAcademicClass: {
      type: Boolean,
      default: false
    },
    usawConfig: {
      type: String,
      default: 'usaw_disabled',
    },
    verifyCustomInfo: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      currentState: 'core_info', // Can be core_info, usaw, or custom_info
    };
  },
  computed: {
    verifyUsawInfo() {
      return this.usawConfig !== 'usaw_disabled' && this.usawConfig !== 'usaw_invisible';
    },
  },
  created() {
    // Setup state so the back/forward buttons work correctly on this page
    // replace state is setup for the initial load
    history.replaceState({ managerState: this.currentState }, '');
    let vm = this;
    window.addEventListener('popstate', this.popStateHandler);

    if (!this.verifyCoreInfo) {
      this.goToNextState();
    }
    this.$notificationManager.$on('wrestler-updated', this.goToNextState);
  },
  destroyed() {
    this.$notificationManager.$off('wrestler-updated', this.goToNextState);
    window.removeEventListener('popstate', this.popStateHandler);
  },
  methods: {
    popStateHandler(event) {
      if (event.state && 'managerState' in event.state) {
        this.currentState = event.state.managerState;
      }
    },
    goToNextState() {
      switch (this.currentState) {
        case 'core_info':
          if (this.verifyUsawInfo) {
            this.currentState = 'usaw';
          } else if (this.verifyCustomInfo) {
            this.currentState = 'custom_info';
          } else {
            this.finished();
          }
          break;
        case 'usaw':
          if (this.verifyCustomInfo) {
            this.currentState = 'custom_info';
          } else {
            this.finished();
          }
          break;
        default:
          this.finished();
      }

      history.pushState({ managerState: this.currentState }, '');
    },
    finished() {
      this.goToNextFunnelStep();
    }
  }
};
</script>
