<template>
  <div class="search-result">
    <div class='hr-line-dashed' v-if="!noHr"></div>
    <div class="grid sm-grid-cols-1 grid-cols-2">
      <div>
        <template v-if="showProducts">
          <div class="flex flex-col-xs-only gap-x-sm align-items-start">
            <div class="flex flex-col gap-y-xxs" v-html="statusBadge"></div>
            <div>
              <h3>
                {{ productNames() }}
              </h3>
              <div class="payment-plan-details">
                <p>
                  <span v-html="title"></span><br/>
                  <span v-html="parentName(true)"></span>
                </p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <h3>
            <span class="text-default" v-html="title"></span>
          </h3>
          <div class="payment-plan-details">
            <p v-html="parentName()">
            </p>
          </div>
        </template>
      </div>
      <div>
        <div class="flex flex-col-xs-only gap-y-sm flex-wrap justify-space-between m-t-only-xs-sm">
          <div class="flex flex-col">
            <p class="m-t-none-important m-b-none-important">
              {{ formatAsCurrency(subscriptionTotal()) }}{{ subInterval() }}
            </p>
            <p class="m-t-none-important m-b-none-important">
              <a :href="`/subscriptions/${subscription.billing_partner_id}`">{{
                  subscription.billing_partner_id
                }}</a>
            </p>
            <div class="flex gap-x-xs" v-if="coupon">
              <p class="m-t-none-important m-b-none-important">
                <span class="text-primary">{{ coupon.code }} applied</span>
              </p>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="m-b-xxs" v-html="collectionStatusBadge" v-if="!showProducts"></div>
            <p class="m-t-none-important m-b-none-important">
              {{ nextDue() }}
            </p>
            <p class="m-t-none-important m-b-none-important">
              {{ since() }}
            </p>
            <p v-if="subscription.expires_on" class="m-t-none-important m-b-none-important">
              Expires {{ expiresOn() }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formattingMixin } from './mixins/formatting_mix';
import { billingPlanMix } from './mixins/billing_plan_mix';

export default {
  name: 'subscription',
  mixins: [formattingMixin, billingPlanMix],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    showProducts: {
      type: Boolean,
      default: false,
    },
    noHr: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    coupon() {
      return _.first(this.subscription?.coupons);
    },
    collectionStatusBadge() {
      if (this.subscription.payment_collection_status !== 'paused') {
        return "";
      }

      return '<span class="label">Paused<i class="fa fa-pause m-l-xs"></i></span>';
    },
    statusBadge() {
      switch (this.subscription.status) {
        case 'paid':
          return '<span class="label label-primary">Active</span>' + this.collectionStatusBadge;
        case 'canceled':
          return '<span class="label">Canceled</span>';
        case 'overdue':
          return '<span class="label label-warning">Overdue</span>' + this.collectionStatusBadge;
        default:
          return '<span class="label">Unknown status</span>' + this.collectionStatusBadge;
      }
    },
    title() {
      const members = [];
      const vm = this;
      this.subscription.billing_plan_subscription_items.forEach((si) => {
        const plan = vm.planForItem(si);
        if (plan && !plan.is_wiq_fee && !plan.is_tax_item) {
          si.wrestler_profiles.forEach((profile) => {
            const link = `<a href='/wrestlers/${profile.id}'>${profile.first_name} ${profile.last_name}</a>`;
            members.push(link);
          });
        }
      });
      return _.uniq(members).join(', ');
    },
  },
};
</script>
