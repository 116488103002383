<template>
    <div id="opposing-wrestler-create" role="form" :class="getVisibilityClass(null)">
        <div :class="getVisibilityClass('form-group')">
            <label>First Name</label>
            <input v-model="first_name" type="text" class="form-control">
        </div>
        <div :class="getVisibilityClass('form-group')">
            <label>Last Name</label>
            <input v-model="last_name" type="text" class="form-control">
        </div>
        <div :class="getVisibilityClass('form-group')">
            <label>Weight Class</label>
            <input v-model="weight_class" type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>Opposing Team</label>
            <div v-if="opposingTeam">
                <div class="pull-left m-l-sm">
                    <h3>
                        {{ opposingTeam.name }}
                    </h3>
                    <p class='search-type-label'>
                        {{ opposingTeam.conference }}
                    </p>
                    <small class="no-l-pad">
                        <a @click="resetOpposingTeam()">Change...</a>
                    </small>
                </div>
                <div class="clearfix"></div>
            </div>
            <search v-show="!opposingTeam"
                    readable-model-name="opposing team"
                    :mutate-history="false"
                    :allow-add="true"
                    class="search-form"
                    response-key="teams"
                    v-on:adding="dimForOpposingTeamCreate()"
                    v-on:doneAdding="showAll()"
                    placeholder="Type opposing team name..."
                    base-url="/api/v1/opposing_teams">
                <template v-slot:list="slotProps">
                    <div class="owrestler-oteam-wrapper" @click="opposingTeamClicked(slotProps.result)">
                        <opposing-team-search-result
                                v-bind:result="slotProps.result"
                                v-bind:is-selectable="true"
                                v-bind:key="slotProps.result.id"
                        >
                        </opposing-team-search-result>
                    </div>
                </template>
                <template v-slot:addform="slotProps">
                    <opposing-team-create
                            v-bind:name="slotProps.eventName"
                    >
                    </opposing-team-create>
                </template>
            </search>
        </div>
        <div :class="getVisibilityClass('button-area')">
            <div v-show="error">
                <small>
                    <span class="text-danger">{{ errorMessage }}</span>
                </small>
            </div>
            <button v-show="!loading" type="button" v-on:click="cancel" class="btn btn-white">Cancel</button>
            <ladda-button @lbClicked="postOpposingWrestler" el-class="btn-primary" :loading="loading">
                Create Opposing Wrestler
            </ladda-button>
        </div>
    </div>
</template>

<script>
import OpposingTeamSearchResult from './opposing_team_search_result.vue';
import OpposingTeamCreate from './opposing_team_create.vue';
import Search from '../../shared/search.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  components: {
    LaddaButton,
    OpposingTeamCreate,
    Search,
    OpposingTeamSearchResult,
  },
  name: 'opposing-wrestler-create',
  props: {
    firstNameGuess: {
      type: String,
      default: '',
    },
    lastNameGuess: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      first_name: this.firstNameGuess,
      last_name: this.lastNameGuess,
      weight_class: '',
      opposingTeam: null,
      dimSections: false,
    };
  },
  created() {
    const vm = this;
    vm.$notificationManager.$on('opposing-team-created', vm.opposingTeamCreated);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('opposing-team-created', vm.opposingTeamCreated);
  },
  methods: {
    opposingTeamCreated(opposingTeam) {
      const vm = this;
      vm.opposingTeam = opposingTeam;
      vm.showAll();
    },
    getVisibilityClass(extraClasses) {
      if (this.dimSections) {
        if (extraClasses) {
          return `${extraClasses} should-dim`;
        }
        return 'dim';
      }
      return extraClasses;
    },
    dimForOpposingTeamCreate() {
      this.dimSections = true;
    },
    showAll() {
      this.dimSections = false;
    },
    resetOpposingTeam() {
      this.opposingTeam = null;
    },
    opposingTeamClicked(opposingTeam) {
      this.opposingTeam = opposingTeam;
    },
    cancel() {
      this.$notificationManager.$emit('cancel-opposing-wrestler-create');
    },
    validateNotEmpty(first_name, last_name, weight_class, team) {
      const vm = this;
      let errorMessage = 'Please ensure ';
      let error = false;
      if (first_name.length === 0) {
        errorMessage += 'first name, ';
        error = true;
      }
      if (last_name.length === 0) {
        errorMessage += 'last name, ';
        error = true;
      }
      if (weight_class.length === 0) {
        errorMessage += 'weight class, ';
        error = true;
      }

      if (team === null || team.id.length === 0) {
        errorMessage += 'opposing team, ';
        error = true;
      }

      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        return false;
      }
      return true;
    },
    postOpposingWrestler: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (vm.validateNotEmpty(vm.first_name, vm.last_name, vm.weight_class, vm.opposingTeam)) {
        vm.loading = true;

        const params = {
          wrestler_profile: {
            first_name: vm.first_name,
            last_name: vm.last_name,
            weight_class: vm.weight_class,
            team_id: vm.opposingTeam.id,
          },
        };
        const url = vm.$apiService.opposingWrestlersUrl();
        axios.post(url, params)
          .then((response) => {
            vm.loading = false;
            response = response.data;
            vm.$notificationManager.$emit('opposing-wrestler-created', response);
            vm.$notificationManager.$emit('show-toast', `${response.first_name} ${response.last_name} created`, true);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
      }
    }, 500),
  },
};
</script>
