<template>
  <div class="row">
    <div class="col-md-12">
      <div class="ibox">
        <div class="ibox-title">
          <h1>Import Wrestlers</h1>
        </div>
        <div class="ibox-content">
          <div v-if="showUploadForm()">
            <div class="row">
              <div class="col-md-6">
                <h2>Upload a .csv file</h2>
                <p>
                  Your .csv file must have a header row at the top, with these column names (capitalization matters).
                  After you upload the file, WrestlingIQ will attempt to find duplicates, so you can re-upload a csv
                  file if needed.<br/><br/>
                  <strong>First name and last name are required, all others are optional. If an email—or parent email—is provided, invites will automatically be sent.</strong><br/>
                </p>
                <ul>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Weight Class</li>
                  <li>Date of Birth</li>
                  <li>Experience</li>
                  <li>Grade</li>
                  <li>Email</li>
                  <li>Parent 1 First Name</li>
                  <li>Parent 1 Last Name</li>
                  <li>Parent 1 Email</li>
                  <li>Parent 2 First Name</li>
                  <li>Parent 2 Last Name</li>
                  <li>Parent 2 Email</li>
                </ul>
                <p>
                  If you are going to be using scramble pairings, you'll need to fill out date of birth and
                  experience.<br/>
                  Experience must be "beginner", "intermediate", or "advanced".
                </p>
              </div>
              <div class="col-md-5 p-md border border-solid border-gray-200 rounded-sm m-t-lg">
                <h3 class="m-t-none">
                  Import Template
                </h3>
                <p class="m-t-m">
                  Once you tap the button below, go to <strong>File -> 'Make a copy'</strong> so you can fill out the template with your own
                  data.<br/><br/>
                  Once you have the data entered, go to <strong>File -> Download -> 'Comma Separated Values (.csv)'</strong> and upload it.
                </p>
                <a href="https://docs.google.com/spreadsheets/d/1mHXKQvbVxypavDT17Cpqs2CzOscYCtf6k658sFxMh7A/edit?usp=sharing"
                   class="btn btn-default m-t-xs">
                  Get the template
                </a>
                <h3 class="m-t-lg">
                  Google Form Template
                </h3>
                <p class="m-t-m">
                  If you want to collect responses via a Google Form, here is a template you can make a copy of and use.
                  Once you have responses, link it to a Google Sheet and export as a CSV to upload here.
                </p>
                <a href="https://docs.google.com/forms/d/1mX5uQ_TigIgE_H-zKCMrTLxqabkVyjzdIS9QqW-GWsc/template/preview"
                   class="btn btn-default m-t-xs">
                  Get a Google Form template
                </a>
              </div>
            </div>
            <div class="m-t-md">
              <input type="file" id="files">
              <ladda-button @lbClicked="parseFile" el-class="btn-primary m-t-lg" :loading="fileParsing">
                Upload
              </ladda-button>
              <div v-if="error">
                <small class="text-danger">
                  {{ errorMessage }}
                </small>
              </div>
            </div>
          </div>
          <div v-if="!showUploadForm()">
            <div>
              <h2>
                Verify Headers
              </h2>
            </div>
            <div class="alert-wrappers">
              <div v-if="validFieldsFound.length > 0" class="alert alert-success">
                <strong>{{ arrayToStr(validFieldsFound) }}</strong> found in file.
              </div>
              <div v-if="ignoredFields.length > 0" class="alert alert-warning">
                <strong>{{ arrayToStr(ignoredFields) }}</strong> found in file, but is not used in the import. These
                columns are being ignored.
              </div>
              <div v-if="missingOptionalFields.length > 0" class="alert alert-info">
                <strong>{{ arrayToStr(missingOptionalFields) }}</strong> not found in file. This is ok, as this is
                optional info, but if you expected it to be included, please double check your headers.
              </div>
              <p>
                Want to choose a new file? <strong><a class="text-danger" @click.prevent="reuploadFile()">Click here</a></strong>
              </p>
            </div>
            <div class="m-t-xl">
              <h2>Confirm Import</h2>
            </div>
            <div class="uploaded-info list-results">
              <div class="search-result teammate" v-for="row in parsedRows">
                <div class='hr-line-dashed'></div>
                <div class="grid grid-cols-2 sm-grid-cols-1 gap-3">
                  <div>
                    <h3>
                      {{ row.first_name }} {{ row.last_name }}
                    </h3>
                    <p class="search-type-label" v-html="wrestlerInfo(row)"></p>
                    <div v-if="row.parents.length === 0">
                      <p class="search-type-label">No parents detected in file.</p>
                    </div>
                    <div class="m-l-m grid grid-cols-2 sm-grid-cols-1 gap-3" v-for="parent in row.parents">
                      <div>
                        <h3>
                          {{ parent.first_name }} {{ parent.last_name }}
                        </h3>
                        <p class="search-type-label">
                          Parent of {{ row.first_name }} {{ row.last_name }}<br/>
                          {{ parent.email }}
                        </p>
                      </div>
                      <div v-html="importStatus(parent)"></div>
                    </div>
                  </div>
                  <div class="text-right t-l-md" v-html="importStatus(row)"></div>
                </div>
              </div>
            </div>
            <div class="m-t-lg">
              <p>
                <strong>You are about to import {{ pluralizedCount(toImportCount, 'wrestler') }} and {{ pluralizedCount(parentToImportCount, 'parent') }}.</strong>
              </p>
              <div v-show="!importFinished">
                <ladda-button @lbClicked="beginImport" el-class="btn-primary" :loading="loading">
                  Import
                </ladda-button>
              </div>
              <div v-show="importFinished">
                {{ importMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { importerMix } from './mixins/importer_mix';
import LaddaButton from '../../shared/ladda_button.vue';
import Spinner from './spinner.vue';
import pLimit from 'p-limit';

export default {
  name: 'bulk-add-wrestlers',
  components: {
    Spinner,
    LaddaButton
  },
  mixins: [importerMix],
  props: [],
  data() {
    return {
      allFields: ['First Name', 'Last Name', 'Weight Class', 'Date of Birth', 'Experience', 'Grade', 'Email', 'Parent 1 First Name', 'Parent 1 Last Name', 'Parent 1 Email', 'Parent 2 First Name', 'Parent 2 Last Name', 'Parent 2 Email'],
      requiredFields: ['First Name', 'Last Name'],
      queryingWrestlers: false,
    };
  },
  computed: {
    wrestlersToImport() {
      return _.filter(this.parsedRows, { import_status: 'not_started' });
    },
    toImportCount() {
      return this.wrestlersToImport.length;
    },
    parentsToImport() {
      return _.filter(_.flatMap(this.parsedRows, 'parents'), { import_status: 'not_started' });
    },
    parentToImportCount() {
      return this.parentsToImport.length;
    }
  },
  methods: {
    pluralizedCount(count, word) {
      if (count === 1) {
        return `1 ${word}`;
      }

      return `${count} ${word}s`
    },
    parseParent(row, parentNumber) {
      const fName = row[`Parent ${parentNumber} First Name`];
      const lName = row[`Parent ${parentNumber} Last Name`];
      const email = row[`Parent ${parentNumber} Email`];
      const nonFalseyValues = _.compact([fName, lName, email]);

      if (nonFalseyValues.length === 3) {
        return {
          first_name: fName,
          last_name: lName,
          email: email,
          import_status: 'searching',
          import_reason: 'Checking for duplicate parents...',
        }
      }

      return null;
    },
    formatUploadedRow(uploaded_row) {
      let dob = uploaded_row['Date of Birth'];
      if (!_.isEmpty(dob)) {
        dob = moment(dob, 'MM/DD/YYYY');
      }

      let parents = []
      let importStatus = 'searching'
      let importReason = 'Checking for duplicate wrestlers...';

      const fName = uploaded_row['First Name'];
      const lName = uploaded_row['Last Name'];
      if (_.isEmpty(fName) || _.isEmpty(lName)) {
        importStatus = 'blocked';
        importReason = 'First name or last name missing. Skipping import.'
      } else {
        // If the wrestler validation passes, build up the parents
        let parent1 = this.parseParent(uploaded_row, 1);
        if (parent1) {
          parents.push(parent1);
        }
        let parent2 = this.parseParent(uploaded_row, 2);
        if (parent2) {
          parents.push(parent2);
        }
      }

      return {
        first_name: fName,
        last_name: lName,
        email: uploaded_row['Email'],
        weight_class: uploaded_row['Weight Class'],
        dob: dob,
        academic_class: uploaded_row.Grade,
        experience: _.lowerCase(uploaded_row.Experience),
        import_status: importStatus,
        import_reason: importReason,
        parents: parents,
      };
    },
    uploadParsed() {
      // Concurrency limit of 3 promise at once
      const limit = pLimit(3);
      let promises = [];
      this.queryingWrestlers = true;

      this.parsedRows.forEach(async (row, index) => {
        // If we are in a blocked state, the wrestler validation failed so we skip everything
        if (row.import_status !== 'blocked') {
          promises.push(limit(() => this.searchForWrestler(row, index)));
          row.parents.forEach((parentRow) => {
            promises.push(limit(() => this.searchForParent(parentRow)));
          });
        }
      });

      Promise.all(promises)
          .then((results) => {
            console.log('all finished');
            console.log(results);
            this.queryingWrestlers = false;
          })
          .catch(error => {
            console.log('error');
            console.log(error);
            this.queryingWrestlers = false;
          });
    },
    async searchForParent(row) {
      console.log(`searching for parent row ${row.first_name} ${row.last_name}`);
      return axios.get(this.$apiService.guardianExactSearchUrl(row.first_name, row.last_name))
          .then((response) => {
            if (response.data.results.length > 1) {
              row.import_status = 'duplicate';
              let urls = _.map(response.data.results, (r) => {
                return this.urlForGuardian(r.type, r.id);
              });
              row.import_reason = `Multiple guardians found with this name, skipping import.<br/> ${urls.join(',')}`;
            } else if (response.data.results.length === 1) {
              let guardian = response.data.results[0];
              row.guardian_id = guardian.id;
              row.guardian_type = guardian.type;
              row.import_status = 'blocked';
              row.import_reason = `Guardian found, skipping data import, but importing will link existing guardian with wrestler.<br/> ${this.urlForGuardian(guardian.type, guardian.id)}`;
            } else {
              row.import_status = 'not_started';
              row.import_reason = `No parent found with name ${row.first_name} ${row.last_name}. Ready to import.`;
            }
          })
          .catch((error) => {
            row.import_status = 'failed';
            row.import_reason = 'Searching for guardian failed';
          });
    },
    urlForGuardian(type, id) {
      if (type === 'CoachProfile') {
        return `<a href="/coaches/${id}" target="_blank"> Coach ${id} <i class="fa fa-external-link"></i></a>`;
      } else {
        return `<a href="/parents/${id}" target="_blank"> Parent ${id} <i class="fa fa-external-link"></i></a>`
      }
    },
    async searchForWrestler(row, index) {
      console.log(`searching for wrestler row ${row.first_name} ${row.last_name}`);
      // row is already a moment object, so format it how the API expects
      let url = `${this.$apiService.wrestlersUrl()}?first_name=${row.first_name}&last_name=${row.last_name}`;
      if (!_.isEmpty(row.dob)) {
        url = `${url}&dob=${row.dob.format('YYYY-MM-DD')}`;
      }
      return axios.get(url)
          .then((response) => {
            if (response.data.wrestlers.length > 1) {
              row.import_status = 'duplicate';
              let urls = _.map(response.data.wrestlers, (wp) => {
                return this.urlForWrestler(wp.id);
              });
              row.import_reason = `Multiple wrestlers found with this name, skipping wrestler import.<br/> ${urls.join(',')}`;
            } else if (response.data.wrestlers.length === 1) {
              let wp = response.data.wrestlers[0];
              row.wrestler_profile_id = wp.id;
              row.import_status = 'blocked';
              row.import_reason = `Wrestler found, skipping wrestler import.<br/>${this.urlForWrestler(wp.id)}`;
            } else {
              row.import_status = 'not_started';
              row.import_reason = `No wrestler found with name ${row.first_name} ${row.last_name}. Ready to import.`;
            }

          })
          .catch((error) => {
            row.import_status = 'failed';
            row.import_reason = 'Searching for wrestler failed';
          });
    },
    urlForWrestler(wId) {
      return `<a href="/wrestlers/${wId}" target="_blank"> Wrestler ${wId} <i class="fa fa-external-link"></i></a>`;
    },
    wrestlerInfo(wrestler) {
      const result = wrestler;
      const email = result.email ? result.email : 'No email found';
      const dob = result.dob ? `Born ${result.dob.format('MMM Do, YYYY')}` : 'Date of birth not found';
      const weight = result.weight_class ? `${result.weight_class} lbs` : 'Weight class not found';
      const academic_class = result.academic_class ? `Grade ${result.academic_class}` : 'Grade not found';
      const validExperiences = [
        'beginner',
        'intermediate',
        'advanced',
      ];
      let experienceMessage = '';
      if (!result.experience) {
        experienceMessage = 'Experience not found';
      } else if (_.includes(validExperiences, result.experience)) {
        experienceMessage = _.capitalize(result.experience);
      } else {
        experienceMessage = `<span class="text-danger">Experience level ${result.experience} not valid</span>`;
      }

      const values = _.compact([email, dob, weight, experienceMessage, academic_class]);
      return _.join(values, '<br/>');
    },
    importStatus(row) {
      switch (row.import_status) {
        case 'searching':
          return `<p><i class='fa fa-search'></i> ${row.import_reason}</p>`;
        case 'not_started':
          return `<p><i class='fa fa-circle-o'></i> ${row.import_reason}</p>`;
        case 'importing':
          return '<p><i class=\'fa fa-circle-o-notch\'></i> Importing...</p>';
        case 'blocked':
          return `<p class='text-warning'><i class='fa fa-exclamation-triangle'></i> ${row.import_reason}</p>`;
        case 'duplicate':
          return `<p class='text-danger'><i class='fa fa-times-circle'></i> ${row.import_reason}</p>`;
        case 'failed':
          return `<p class='text-danger'><i class='fa fa-times-circle'></i> ${row.import_reason}</p>`;
        case 'imported':
          return `<p class='text-success'><i class='fa fa-check-circle-o'></i> ${row.import_reason}</p>`;
      }
    },
    checkIfFinished() {
      const vm = this;
      if (this.toImportCount === 0 && this.parentToImportCount === 0) {
        // All have finished
        vm.loading = false;
        vm.importFinished = true;
        vm.importMessage = 'Finished importing';
      }
    },
    inviteWrestler(wrestler) {
      const vm = this;
      const params = {
        invite: {
          email: wrestler.email,
          inviteable_id: wrestler.wrestler_profile_id,
          inviteable_type: 'WrestlerProfile',
        },
      };
      const url = this.$apiService.invitesUrl();
      axios.post(url, params)
          .then((response) => {
            wrestler.import_status = 'imported';
            wrestler.import_reason = `Wrestler imported and invite sent via email<br/>${this.urlForWrestler(wrestler.wrestler_profile_id)}`;
            vm.checkIfFinished();
          })
          .catch((error) => {
            vm.checkIfFinished();
            wrestler.import_status = 'failed';
            wrestler.import_reason = `Created wrestler ${this.urlForWrestler(wrestler.wrestler_profile_id)}, but failed to process invite ${error.toString()}`;
          });
    },
    async createWrestler(wrestler) {
      const vm = this;
      const dob = _.isEmpty(wrestler.dob) ? null : wrestler.dob.toISOString();
      const params = {
        wrestler_profile: {
          first_name: wrestler.first_name,
          last_name: wrestler.last_name,
          dob: dob,
          weight_class: wrestler.weight_class,
          academic_class: wrestler.academic_class,
          experience: wrestler.experience,
          profile_type: 'teammate',
        },
      };

      return axios.post(vm.$apiService.wrestlersUrl(), params)
          .then((response) => {
            wrestler.import_status = 'imported';
            wrestler.wrestler_profile_id = response.data.id;
            wrestler.import_reason = `Wrestler created<br/>${this.urlForWrestler(wrestler.wrestler_profile_id)}`;
            if (wrestler.email) {
              vm.inviteWrestler(wrestler);
            }
            wrestler.parents.forEach((parent) => {
              vm.createGuardianFor(parent, wrestler.wrestler_profile_id);
            });
            vm.checkIfFinished();
          })
          .catch((error) => {
            wrestler.import_status = 'failed';
            wrestler.import_reason = `Failed to create wrestler ${error.toString()}`;
            vm.checkIfFinished();
          });
    },
    async createGuardianFor(parent, wrestlerId) {
      if (parent.guardian_id) {
        // Parent already exists, only find or create the guardian relationship
        return this.createGuardianRelationships(parent, wrestlerId);
      } else {
        // create parent, invite, and guardian relationship all in one go
        const vm = this;
        const params = {
          parent_profile: {
            first_name: parent.first_name,
            last_name: parent.last_name,
          },
          email: parent.email,
          wrestler_profile_ids: [wrestlerId],
        };
        const url = vm.$apiService.parentsUrl();
        return axios.post(url, params)
            .then((response) => {
              parent.import_status = 'imported';
              parent.import_reason = `Parent created, invited, and marked as guardian of wrestler.<br/>${this.urlForGuardian('ParentProfile', response.data.id)}`;
              vm.checkIfFinished();
            })
            .catch((error) => {
              parent.import_status = 'failed';
              parent.import_reason = `Failed to create parent ${error.toString()}`;
              vm.checkIfFinished();
            });
      }
    },
    async createGuardianRelationships(parent, wrestlerId) {
      const vm = this;
      const params = {
        guardian_relationship: {
          guardianable_id: parent.guardian_id,
          guardianable_type: parent.guardian_type,
          wrestler_profile_id: wrestlerId,
        },
      };

      const url = vm.$apiService.guardianRelationshipUrl();
      return axios.post(url, params)
          .then((response) => {
            parent.import_status = 'imported';
            parent.import_reason = `Existing account marked as guardian of wrestler.<br/>${this.urlForGuardian(parent.guardian_type, parent.guardian_id)}`;
            vm.checkIfFinished();
          })
          .catch((error) => {
            parent.import_status = 'failed';
            parent.import_reason = `Failed to associate existing guardian ${this.urlForGuardian(parent.guardian_type, parent.guardian_id)} with wrestler ${error.toString()}`;
            vm.checkIfFinished();
          });
    },
    beginImport: _.throttle(function () {
      const vm = this;
      if (vm.loading) {
        return;
      }

      // Concurrency limit of 2 promises at once, but due to the chained nature of it, the concurrency is limited to per original promise, not subsequent promises/calls too
      const limit = pLimit(1);
      let promises = [];
      vm.loading = true;

      vm.parsedRows.forEach((row) => {
        // Skip rows that did not pass client side validation
        if (row.import_status === 'blocked') {
          return;
        }

        if (row.wrestler_profile_id) {
          // only import parents as needed
          row.parents.forEach((parent) => {
            promises.push(limit(() => vm.createGuardianFor(parent, row.wrestler_profile_id)));
          });
        } else {
          // First create wrestler, then handle parents
          promises.push(limit(() => vm.createWrestler(row)));
        }
      });

      Promise.all(promises)
          .then((results) => {
            console.log('all finished importing');
            console.log(results);
          })
          .catch(error => {
            console.log('error');
            console.log(error);
          });
    }, 500),
  },
};
</script>
