<template>
    <div class="row">
        <div class="col-xs-12">
            <div class="ibox">
                <div class="ibox-title flex justify-space-between">
                    <h1 class="hidden-xs">Edit members of {{ groupName() }}</h1>
                    <a class="btn btn-primary" :href="'/message_groups/' + messageGroup.id"><i class="fa fa-arrow-left"></i> Back</a>
                </div>
                <div class="ibox-content">
                    <div v-if="loading">
                        <spinner></spinner>
                    </div>
                    <div v-if="error">
                        <p class="text-danger">
                            {{ errorMessage }}
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <label>Add Member</label>
                            <search
                                    class="search-form"
                                    response-key="results"
                                    placeholder="Type name..."
                                    :mutate-history="false"
                                    :allow-add="false"
                                    :display-all-on-empty="false"
                                    readable-model-name="results"
                                    :profiles-only="true"
                                    base-url="/api/v1/search">
                                <template v-slot:list="slotProps">
                                    <div class="profile-wrapper" @click="profileClicked(slotProps.result)">
                                        <generic-search-result
                                                v-bind:result="slotProps.result"
                                                v-bind:is-selectable="true"
                                                v-bind:key="slotProps.result.id">
                                        </generic-search-result>
                                    </div>
                                </template>
                            </search>
                        </div>
                    </div>
                    <div class="row m-t-xl">
                        <div v-show="!removing && !adding" class="col-sm-12">
                            <label>Edit Members</label>
                              <div class="bg-gray-50 border-bottom p-y-m grid grid-cols-3 font-semi text-gray-500 uppercase tracking-wide m-b-xxs m-t-m" style="margin-left: -24px; margin-right: -24px; padding-left: 24px; padding-right: 24px;">
                                <div>
                                  Name
                                </div>
                                <div>
                                  Can send to group?
                                </div>
                                <div class="text-right">
                                  Actions
                                </div>
                              </div>
                              <div v-for="m in memberships" class="grid grid-cols-3 border border-b border-solid p-y-xs border-gray-100 align-items-center">
                                <div>
                                  {{ m.profile.display_name }}
                                </div>
                                <div>
                                  <input type="checkbox" v-bind:checked="m.can_publish" @change="updateMembership(m, $event)"/>
                                </div>
                                <div class="text-right">
                                  <a class="text-danger cursor-pointer" @click.prevent="confirmRemove(m)"><i class="fa fa-trash-o color-red-600"></i></a>
                                </div>
                              </div>
                        </div>
                        <div v-show="removing" class="col-sm-12">
                            <label>
                                Removing member... <spinner :inline="true"></spinner>
                            </label>
                        </div>
                        <div v-show="adding" class="col-sm-12">
                            <label>
                                Adding member... <spinner :inline="true"></spinner>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert2';
import Spinner from './spinner.vue';
import Search from '../../shared/search.vue';
import GenericSearchResult from './generic-search-result.vue';

export default {
  name: 'edit-message-memberships',
  components: { GenericSearchResult, Search, Spinner },
  props: [
    'messageGroup',
  ],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error loading members',
      memberships: [],

      removing: false,
      adding: false,
    };
  },
  created() {
    this.getMemberships();
  },
  computed: {

  },
  methods: {
    profileClicked: _.throttle(function (profile) {
      const vm = this;
      vm.adding = true;
      vm.$notificationManager.$emit('profile-clicked');

      const params = {
        // shit this can publish needs to be a different method
        message_membership: {
          can_publish: vm.allowedToPublish(profile),
          profile_id: profile.id,
          profile_type: profile.type,
        },
      };

      const url = vm.$apiService.messageMembershipsUrl(vm.messageGroup.id);
      axios.post(url, params)
        .then((response) => {
          vm.adding = false;
          vm.error = false;
          vm.$notificationManager.$emit('show-toast', 'Member added', true);
          vm.getMemberships();
        })
        .catch((error) => {
          let errorMsg = 'Error adding member. If this issue persists, contact support@wrestlingiq.com';
          const { errors } = error.response.data;
          if (errors.message_group_id && errors.message_group_id[0] === "has already been taken") {
            errorMsg = `${profile.name} is already part of this messaging group`;
          }
          vm.adding = false;
          vm.errorMessage = errorMsg;
          vm.error = true;
        });
    }, 500),
    allowedToPublish(profile) {
      const vm = this;
      // Everyone is allowed to publish in a direct message
      if (vm.messageGroup.group_type === 'direct' || vm.messageGroup.default_allow_publish === 'all') {
        return true;
      }

      // todo fixup for admins when I add that
      if (profile.type === 'CoachProfile') {
        return true;
      }

      return false;
    },
    confirmRemove(membership) {
      const vm = this;
      swal.fire({
        type: 'warning',
        title: `Are you sure you want to remove ${membership.profile.display_name}?`,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonClass: 'btn btn-danger m-l-md',
        confirmButtonText: 'Yes, remove',
        cancelButtonClass: 'btn btn-default',
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          vm.removeMember(membership);
        }
      });
    },
    groupName() {
      if (this.messageGroup) {
        return this.messageGroup.name;
      }

      return '';
    },
    removeMember: _.throttle(function (membership) {
      const vm = this;
      vm.removing = true;

      const deleteUrl = vm.$apiService.messageMembershipUrl(membership.id);
      axios.delete(deleteUrl)
        .then((response) => {
          vm.removing = false;
          vm.error = false;
          vm.$notificationManager.$emit('show-toast', 'Member removed', true);
          vm.getMemberships();
        })
        .catch((error) => {
          vm.removing = false;
          vm.getMemberships();
        });
    }, 500),
    getMemberships() {
      const vm = this;

      if (vm.loading) {
        return;
      }

      vm.loading = true;

      const url = vm.$apiService.messageMembershipsUrl(vm.messageGroup.id);
      vm.$apiService.loadAllPages(url, 'memberships')
        .then((members) => {
          vm.memberships = _.sortBy(members, ['profile.profile_type', 'profile.display_name']);
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.error = true;
        });
    },
    updateMembership(membership, $event) {
      let canPublish = $event.currentTarget.checked;
      const params = {
        message_membership: {
          id: membership.id,
          can_publish: canPublish,
        }
      };
      axios.put(this.$apiService.messageMembershipUrl(membership.id), params)
          .then((response) => {
            let permission = canPublish ? "can send" : "is now unable to send"
            let msg = `Saved! ${membership.profile.display_name} ${permission} messages to this group.`
            this.$notificationManager.$emit('show-toast', msg, true);
            membership.can_publish = canPublish;
          })
          .catch((error) => {
            this.$notificationManager.$emit('show-toast', error.toString(), false);
          });
    }
  },
};
</script>
