<template>
  <div role="form">
    <spinner v-if="retrieving"></spinner>
    <div v-if="preferences">
      <h2 class="m-t-none">
        How would you like to receive notifications?
      </h2>
      <p>
        Examples of notifications include an event changing time, a new video available, a new message, etc.
      </p>
      <div class="form-group m-t-lg">
        <label class="checkbox-inline">
          <input type="checkbox" id="email_checkbox" v-model="email"> Email
        </label>
        <p v-if="!email" class="m-t-xxs">
          <strong>Note on transactional emails</strong><br/>Turning off email notifications will mute team related
          notifications sent via email (new message, practice changed, etc).<br/>
          A handful of purely transactional situations—such as a receipt—will still be sent by email for your records.
        </p>
      </div>
      <div class="form-group">
        <label class="checkbox-inline">
          <input type="checkbox" id="sms_checkbox" v-model="sms"> Text message (SMS)
        </label>
      </div>
      <div class="form-group" v-if="sms">
        <label :for="this.$srHelper.idFor('phone_number', 1)">
          Phone number
        </label>
        <the-mask mask="(###) ### - ####"
                  v-model="phoneNumber"
                  class="form-control"
                  :id="this.$srHelper.idFor('phone_number', 1)"
                  @blur.native="validate()"
                  ref="phone"
                  type="tel">
        </the-mask>
        <p v-show="phoneError" class="text-danger">
          Please enter a valid phone number
        </p>
      </div>
      <div class="form-group">
        <label class="checkbox-inline">
          <input type="checkbox" id="push_checkbox" v-model="push"> Push notifications (iOS or Android app)
        </label>
        <p v-if="push" class="m-t-xxs">
          <strong>Note</strong><br/>Make sure you have downloaded the WrestlingIQ <a href="https://apple.co/3wMcv92" target="_blank" class="text-underline">iOS <i class="fa fa-external-link"></i></a> or <a href="https://play.google.com/store/apps/details?id=com.athleticsiq.android.wrestlingiq" class="text-underline" target="_blank">Android <i class="fa fa-external-link"></i></a> app, signed in, and given WrestlingIQ permission to send push notifications.
        </p>
      </div>
      <div class="button-area text-right">
        <div v-show="error">
          <small>
            <span class="text-danger">{{ errorMessage }}</span>
          </small>
        </div>
        <ladda-button @lbClicked="pingServer" el-class="btn-primary" :loading="saving">
          Save
        </ladda-button>
      </div>
    </div>
  </div>
</template>
<script>
import { TheMask } from 'vue-the-mask';
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'notification-preferences',
  components: {
    Spinner,
    TheMask,
    LaddaButton
  },
  props: [
    'userId',
  ],
  data() {
    return {
      retrieving: false,
      phoneNumber: '',
      preferences: null,
      valid: true,

      email: false,
      sms: false,
      push: false,

      phoneError: false,
      error: false,
      errorMessage: 'Error syncing info',
      saving: false,
    };
  },
  mounted() {
    this.getPreferences();
  },
  watch: {
    phoneNumber() {
      this.calculateValid();
    },
  },
  methods: {
    calculateValid() {
      let answerLength = 0;
      if (this.phoneNumber) {
        answerLength = this.phoneNumber.length;
      }
      this.valid = (answerLength === 10);
    },
    validate() {
      this.phoneError = !this.valid;
    },
    getPreferences() {
      const vm = this;
      vm.retrieving = true;
      const url = vm.$apiService.notificationPreferencesUrl();
      axios.get(url)
          .then((response) => {
            vm.preferences = response.data.notification_preferences[0];
            vm.email = vm.preferences.email;
            vm.sms = vm.preferences.sms;
            vm.push = vm.preferences.push
            vm.getPhoneNumber();
          })
          .catch((error) => {
            vm.retrieving = false;
            vm.error = true;
            vm.errorMessage = 'Error retrieving notification preferences';
          });
    },
    getPhoneNumber() {
      const vm = this;
      const url = vm.$apiService.userUrl(vm.userId);
      axios.get(url)
          .then((response) => {
            const number = response.data.phone_number;
            if (number) {
              // hard code the removal of the +1
              vm.phoneNumber = number.substring(2);
            }
            vm.retrieving = false;
          })
          .catch((error) => {
            vm.retrieving = false;
            vm.error = true;
            vm.errorMessage = 'Error retrieving notification preferences (phone number)';
          });
    },
    pingServer: _.throttle(function () {
      const vm = this;
      if (vm.saving) {
        return;
      }

      if (vm.sms && !vm.valid) {
        return;
      }

      vm.saving = true;

      if (vm.sms) {
        // In this case we first update the user, then if successful, update prefs in success handler
        vm.updateUser();
      } else {
        vm.updatePreferences();
      }
    }, 500),
    updatePreferences() {
      const vm = this;
      const params = {
        notification_preference: {
          email: vm.email,
          sms: vm.sms,
          push: vm.push,
        },
      };
      const url = vm.$apiService.notificationPreferenceUrl(vm.preferences.id);
      axios.put(url, params)
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Preferences updated', true);
            window.location.assign('/settings');
          })
          .catch((error) => {
            vm.saving = false;
            vm.error = true;
            vm.error = 'Error saving preferences';
          });
    },
    updateUser() {
      const vm = this;
      const params = {
        user: {
          phone_number: `+1${vm.phoneNumber}`,
        },
      };
      const url = vm.$apiService.userUrl(vm.userId);

      axios.put(url, params)
          .then((response) => {
            vm.updatePreferences();
          })
          .catch((error) => {
            vm.saving = false;
            vm.error = true;
            vm.error = 'Error saving preferences';
          });
    },
  },
};
</script>
