<template>
    <div></div>
</template>

<script>
// Note that this probably should live somewhere else, like vuex or vue router
// but for now, this hack gets all js out of the erb templates
export default {
  name: 'tab-router',
  mounted() {
    // If an inbound link contains a specific tab, show it
    const url = document.location.toString();
    if (url.match('#')) {
      $(`.nav-tabs a[href="#${url.split('#')[1]}-tab"]`).tab('show');
    }
  },
};
</script>
