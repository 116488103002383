<template>
    <div class="ibox">
      <div class="ibox-title">
        <h1 v-text="paidSessionName ? `Who would you like to register for ${paidSessionName}?` : 'Your Wrestlers'"></h1>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-xs-12">
            <paged-list response-key="wrestlers" object-type="WrestlerProfile" :base-url="baseUrl()">
                <template v-slot:list="slotProps">
                  <div class='search-result'>
                    <div v-if="slotProps.index !== 0" class='hr-line-dashed'></div>
                    <template v-if="funnel">
                      <input type="checkbox" :id="`wp_${slotProps.result.id}`" :value="slotProps.result.id" v-model="wpIdsSelected" :disabled="disableWrestlerSelect(slotProps.result.id)">
                      <label class="wrestler-select-box" :class="{ 'text-gray-400': disableWrestlerSelect(slotProps.result.id) }" :for="`wp_${slotProps.result.id}`">{{ slotProps.result.first_name }} {{ slotProps.result.last_name }}</label>
                      <p v-if="disableWrestlerSelect(slotProps.result.id)" class="text-gray-800 m-b-none">
                        {{ slotProps.result.first_name }} has already been registered for {{ paidSessionName }}.
                      </p>
                    </template>
                    <template v-else>
                      <h3>
                        {{ slotProps.result.first_name }} {{ slotProps.result.last_name }}
                      </h3>
                    </template>
                  </div>
                </template>
              </paged-list>
          </div>
        </div>
        <hr/>
        <div v-show="!showForm && resultsLoaded" class="row">
          <div class="col-xs-6">
            <a data-cy="add-wrestler" class="btn btn-default" v-on:click="toggleShowForm()"><i class="fa fa-plus"></i> New Wrestler</a>
          </div>
          <div class="col-xs-6 text-right">
            <a class="btn btn-primary" data-cy="submit" v-on:click="goToNext()">Next</a>
            <div v-show="error">
              <small>
                <span class="text-danger">{{ errorMessage }}</span>
              </small>
            </div>
          </div>
        </div>
        <div v-if="showForm" class="border border-solid border-gray-300 p-md rounded-sm bg-gray-50 m-t-sm">
          <h1 class="m-t-none m-b-m font-heavy border-b border-solid border-gray-300 p-b-sm">New Wrestler</h1>
          <wrestler-create
              button-text="Save"
              :redirect-after-create="false"
              :require-academic-class="requireAcademicClass"
              :require-dob="true"
              :show-cancel="true"
              :access-key="accessKey"
              :endpoint="endpoint"
              :guardian-id="guardianId"
              :guardian-type="guardianType"
              :cloudfront-base-url="cloudfrontBaseUrl"
              :wrestler-type="isGuest ? 'guest' : 'teammate'"
              v-on:cancel-wrestler-create="toggleShowForm"
              v-on:wrestler-created="wrestlerCreated"
          >
          </wrestler-create>
        </div>
      </div>
    </div>

</template>

<script>
import PagedList from './paged_list.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { TheMask } from 'vue-the-mask';
import { funnelMixin } from './mixins/funnel_mix';
import { photoUploadMix } from './mixins/photo_upload_mix.js';
import WrestlerCreate from "./wrestler_create.vue";

export default {
  name: 'wizard-wrestlers',
  mixins: [funnelMixin, photoUploadMix],
  components: {
    LaddaButton,
    PagedList,
    TheMask,
    WrestlerCreate,
  },
  props: {
    guardianId: {
      type: Number,
      default: null,
    },
    guardianType: {
      type: String,
      default: null,
    },
    isGuest: {
      type: Boolean,
      default: false,
    },
    funnel: {
      type: Boolean,
      default: false,
    },
    paidSessionName: {
      type: String,
      default: null,
    },
    teamName: {
      type: String,
      default: '',
    },
    alreadyRegisteredWrestlerIds: {
      type: Array,
      default() {
        return [];
      },
    },
    requireAcademicClass: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      first_name: '',
      last_name: '',
      weight_class: '',
      age: '',
      dob: '',
      showForm: false,
      resultsLoaded: false,
      wpIdsSelected: [],

      wrestlerCount: 0,

      // Override profile photo mix option
      // so the profile form by default shows filepond
      editingPhoto: true,
    };
  },
  created() {
    const vm = this;
    vm.$notificationManager.$on('first-page-loaded', vm.wrestlersLoaded);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('first-page-loaded', vm.wrestlersLoaded);
  },
  computed: {
    isoDob() {
      if (!this.dob || this.dob.length === 0) {
        return null;
      }

      return moment(this.dob, 'M/D/YYYY').toISOString();
    },
    hasValidWrestlers() {
      if (this.funnel) {
        return this.wpIdsSelected.length > 0;
      }

      return this.wrestlerCount > 0;
    }
  },
  methods: {
    disableWrestlerSelect(wrestlerId) {
      return _.includes(this.alreadyRegisteredWrestlerIds, wrestlerId);
    },
    wrestlersLoaded(objectType, count) {
      const vm = this;
      if (objectType === 'WrestlerProfile') {
        vm.wrestlerCount = count;
        if (vm.wrestlerCount > 0) {
          vm.showForm = false;
        } else {
          vm.showForm = true;
        }
        vm.resultsLoaded = true;
      }
    },
    toggleShowForm() {
      this.showForm = !this.showForm;
    },
    wrestlerCreated(wpId) {
      if (this.funnel) {
        this.wpIdsSelected.push(wpId);
      }
      this.toggleShowForm();
    },
    baseUrl() {
      return this.$apiService.guardianWrestlersUrl(this.guardianType, this.guardianId);
    },
    goToNext() {
      const vm = this;
      if (!vm.hasValidWrestlers) {
        vm.errorMessage = vm.funnel ? `You need to select at least one wrestler to register for ${vm.paidSessionName}.` : "You need at least one active wrestler to continue. Please add a wrestler.";
        vm.error = true;
        return;
      }

      vm.error = false;
      if (vm.funnel) {
        vm.goToNextFunnelStep({
          wps_to_verify: vm.wpIdsSelected,
          wps_to_register: vm.wpIdsSelected,
        });
      } else {
        window.location.assign('/required-wrestler-info');
      }
    },
  },
};
</script>
