<template>
  <div role="form">
    <div class="border-bottom">
      <h1 class="form-section-title">Create a merge job</h1>
      <p>
        Use merge jobs to join duplicate records together. <a href="https://help.wrestlingiq.com/article/90-merging-accounts" target="_blank" class="text-underline">Read more here.</a>
      </p>
    </div>
    <div v-if="canonicalExists" class="m-t-md">
      <h2>1. Choose the record to keep</h2>
      <div class="m-t-m m-l-m">
        <p class="text-heavy m-b-none">
          {{ canonical.name }} ({{ canonical.readable_type }}, internal id {{ canonical.id }})
          <a target="_blank" :href="'/' + canonical.href">
            <i class="fa fa-external-link"></i>
          </a>
        </p>
        <p class="text-underline">
          <a @click.prevent="canonicalClicked({})">
            Change...
          </a>
        </p>
      </div>
    </div>
    <div v-else class="m-t-md">
      <h2 class="text-heavy">1. Choose the record to keep</h2>
      <p><span class="text-heavy">Tip:</span> When records are merged, only the <span class="text-heavy">record selected to keep will continue to have the ability to login</span>.
        Typically it's best to select the 'most active' account as the record to keep.</p>
      <search id="search-form" class="m-t-sm"
              :mutate-history="false"
              :display-all-on-empty="false"
              :query-deleted="true"
              :profiles-only="true"
              query-key="query"
              response-key="results" placeholder="Search by name of parent or wrestler..."
              :base-url="this.$apiService.searchUrl()">
        <template v-slot:list="slotProps">
          <div class="search-result bg-gray-50-hover"
               :class="isCoach(slotProps.result) ? 'text-gray-400-important' : ''">
            <div class='hr-line-dashed'></div>
            <div class="flex justify-space-between">
              <div>
                <div class="flex">
                  <h3 :class="isCoach(slotProps.result) ? 'text-gray-400-important' : ''">
                    {{ slotProps.result.name }} (internal id {{ slotProps.result.id }})
                  </h3>
                  <div class="m-l-lg">
                    <a target="_blank" :href="'/' + slotProps.result.href">
                      <i class="fa fa-external-link"></i>
                    </a>
                  </div>
                </div>
                <p class='search-type-label' :class="isCoach(slotProps.result) ? 'text-gray-400-important' : ''">
                  {{ slotProps.result.readable_type }}
                  <span v-if="isCoach(slotProps.result)">
                    <br>Coaches cannot be merged, but you can delete them. Contact support@wrestlingiq.com if you need assistance.
                  </span>
                </p>
              </div>
              <div v-if="!isCoach(slotProps.result)">
                <button class="btn btn-default bg-white-hover" @click="canonicalClicked(slotProps.result)">Select
                </button>
              </div>
            </div>
          </div>
        </template>
      </search>
    </div>
    <div v-if="bothSelected" class="m-t-md">
      <h2>2. Choose the duplicate record</h2>
      <div class="m-t-m m-l-m">
        <p class="text-heavy m-b-none">
          {{ duplicate.name }} ({{ duplicate.readable_type }}, internal id {{ duplicate.id }})
          <a target="_blank" :href="'/' + duplicate.href">
            <i class="fa fa-external-link"></i>
          </a>
        </p>
        <p class="text-underline">
          <a @click.prevent="duplicateClicked({})">
            Change...
          </a>
        </p>
      </div>
    </div>
    <div v-else-if="canonicalExists && !duplicateExists" class="m-t-md">
      <h2 class="text-heavy">2. Choose the duplicate record</h2>
      <search id="search-form-duplicate" class="m-t-sm"
              :mutate-history="false"
              :display-all-on-empty="false"
              :query-deleted="true"
              :profiles-only="true"
              query-key="query"
              response-key="results" placeholder="Search by name of parent or wrestler..."
              :base-url="this.$apiService.searchUrl()">
        <template v-slot:list="slotProps">
          <div class="search-result bg-gray-50-hover"
               :class="isCoach(slotProps.result) ? 'text-gray-400-important' : ''">
            <div class='hr-line-dashed'></div>
            <div class="flex justify-space-between">
              <div>
                <div class="flex">
                  <h3 :class="isCoach(slotProps.result) ? 'text-gray-400-important' : ''">
                    {{ slotProps.result.name }} (internal id {{ slotProps.result.id }})
                  </h3>
                  <div class="m-l-lg">
                    <a target="_blank" :href="'/' + slotProps.result.href">
                      <i class="fa fa-external-link"></i>
                    </a>
                  </div>
                </div>
                <p class='search-type-label' :class="isCoach(slotProps.result) ? 'text-gray-400-important' : ''">
                  {{ slotProps.result.readable_type }}
                  <span v-if="isCoach(slotProps.result)">
                    <br>Coaches cannot be merged, but you can delete them. Contact support@wrestlingiq.com if you need assistance.
                  </span>
                </p>
              </div>
              <div v-if="!isCoach(slotProps.result)">
                <button class="btn btn-default bg-white-hover" @click="duplicateClicked(slotProps.result)">Select
                </button>
              </div>
            </div>
          </div>
        </template>
      </search>
    </div>
    <div v-if="bothSelected" class="m-t-lg">
      <h2 class="text-heavy">3. Review merge</h2>
      <div v-if="canMerge">
        <div v-if="wrestlerMerge">
          <p>
            Please double check the wrestlers being merge by clicking the arrow icon next to their names, which will
            open their account info in a new tab.
            Merging wrestlers will have the following consequences:
          </p>
          <ul>
            <li>If the wrestler being merged—{{ duplicate.name }} (internal id {{ duplicate.id }})—has an email/login
              <strong>they will lose access to their account.</strong></li>
            <li>Although the name/email will remain the same for the record being kept—{{ canonical.name }} (internal id
              {{ canonical.id }})—all linked information will be moved over, including guardian relationships,
              registrations, stats, matches, and more.
            </li>
            <li>After the merge, you will not be able to load the duplicate wrestler's page anymore—{{ duplicate.name }}
              (internal id {{ duplicate.id }})—since they will not exist.
            </li>
            <li>Historical reporting will change, since these two wrestlers will now be considered the same.</li>
          </ul>
          <ladda-button @lbClicked="createMergeJob" el-class="btn-primary m-t-m" :loading="loading">
            Create merge job
          </ladda-button>
        </div>
        <div v-if="parentMerge">
          <p>
            Please double check the parents being merge by clicking the arrow icon next to their names, which will open
            their account info in a new tab.
            Merging parents will have the following consequences:
          </p>
          <ul>
            <li>If the parent being merged—{{ duplicate.name }} (internal id {{ duplicate.id }})—has an email/login
              <strong>they will lose access to their account.</strong></li>
            <li>Although the name/email will remain the same for the record being kept—{{ canonical.name }} (internal id
              {{ canonical.id }})—all linked information will be moved over, including wrestler linkages, registrations,
              charges, subscriptions, and more.
            </li>
            <li>After the merge, you will not be able to load the duplicate parents's page anymore—{{ duplicate.name }}
              (internal id {{ duplicate.id }})—since they will not exist.
            </li>
            <li>Historical reporting will change, since these two parents will now be considered the same.</li>
          </ul>
          <ladda-button @lbClicked="createMergeJob" el-class="btn-primary m-t-m" :loading="loading">
            Create merge job
          </ladda-button>
        </div>
      </div>
      <div v-else>
        <p v-if="!recordsAreSameType" class="text-danger">
          <i class="fa fa-exclamation-circle"></i> Record types must match (i.e. you cannot merge a parent into a
          wrestler).
          Please change your selection so that both records are either parents or wrestlers.
        </p>
        <p v-else-if="recordsAreSameIdAndType" class="text-danger">
          <i class="fa fa-exclamation-circle"></i> The selected records are already the same.
        </p>
        <p v-else-if="fullIntoGuest" class="text-danger">
          <i class="fa fa-exclamation-circle"></i> Merging full accounts into guest accounts is not supported. If you
          want to merge the guest account into the full one, select the full account in step 1.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Search from '../../shared/search.vue';
import GenericSearchResult from './generic-search-result.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'merge-job-form',
  components: {
    GenericSearchResult,
    LaddaButton,
    Search
  },
  props: {
    username: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      canonical: {},
      duplicate: {},
      loading: false,
      error: false,
      errorMessage: '',
    };
  },
  computed: {
    canonicalExists() {
      return Object.keys(this.canonical).length > 0;
    },
    duplicateExists() {
      return Object.keys(this.duplicate).length > 0;
    },
    bothSelected() {
      return this.canonicalExists && this.duplicateExists;
    },
    recordsAreSameType() {
      if (!this.canonicalExists && !this.duplicateExists) {
        return false;
      }

      return this.canonical.type === this.duplicate.type;
    },
    recordsAreSameIdAndType() {
      return this.recordsAreSameType && this.canonical.id === this.duplicate.id;
    },
    fullIntoGuest() {
      return _.startsWith(this.canonical.readable_type, 'Guest') && !_.startsWith(this.duplicate.readable_type, 'Guest');
    },
    canMerge() {
      return (this.parentMerge || this.wrestlerMerge) && !this.recordsAreSameIdAndType && !this.fullIntoGuest;
    },
    parentMerge() {
      return this.recordsAreSameType && this.canonical.type === 'ParentProfile';
    },
    wrestlerMerge() {
      return this.recordsAreSameType && this.canonical.type === 'WrestlerProfile';
    }
  },
  methods: {
    canonicalClicked(profile) {
      this.canonical = profile;
    },
    duplicateClicked(profile) {
      this.duplicate = profile;
    },
    isCoach(result) {
      return result.type === 'CoachProfile';
    },
    mergeMessage() {
      return `Merge created by ${this.username}. Merge duplicate ${this.duplicate.readable_type} (internal id: ${this.duplicate.id}, name: ${this.duplicate.name}) into ${this.canonical.readable_type} (internal id: ${this.canonical.id}, name: ${this.canonical.name})`;
    },
    createMergeJob() {
      if (!this.canMerge || this.loading) {
        return;
      }

      const params = {
        merge_job: {
          name: this.mergeMessage(),
          version: 'v1',
          type: this.parentMerge ? 'ParentMergeJob' : 'WrestlerMergeJob',
          canonical_id: this.canonical.id,
          canonical_type: this.canonical.type,
          duplicate_id: this.duplicate.id,
          duplicate_type: this.duplicate.type,
        }
      };

      this.loading = true;
      axios.post(this.$apiService.mergeJobsUrl(), params)
          .then((response) => {
            this.$notificationManager.$emit('show-toast', `Merge job ${response.data.id} queued`, true);
            window.location.assign('/merge_jobs');
          })
          .catch((error) => {
            this.loading = false;
            this.error = true;
            this.errorMessage = `Error creating merge job: ${error.toString()}`;
          });
    }
  }
};
</script>
