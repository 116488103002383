<template>
  <div class="row">
    <div class="col-md-5 col-xs-12">
      <div class="ibox">
        <div class="ibox-content">
          <h1 class="font-heavy p-t-md m-t-none m-b-none hidden-xs">Renew your subscription</h1>
          <p class="p-t-sm p-t-m-only-xs">Extend your subscription before it <strong>{{
              expiresOn()
                  .toLowerCase()
            }}</strong> to avoid missing any training.</p>
          <div
              class="m-t-lg bg-gray-50 border-bottom border-top p-y-m font-semi text-gray-500 uppercase tracking-wide m-t-m"
              style="margin-left: -24px; margin-right: -24px; padding-left: 24px; padding-right: 24px;">
            Renewal Details
          </div>
          <div class="flex flex-col gap-y-m">
            <div class="flex justify-space-between m-t-sm">
              <div>
                <p class="text-16 font-semi m-b-none">
                  {{ productNames() }} - {{ billingPlan.name }}
                </p>
                <p class="m-t-none m-b-none text-10">
                  Total includes WIQ fees <span v-if="coupon">and {{ coupon.code }} discount</span>
                </p>
              </div>
              <p class="text-16 flex-shrink-1">
                {{ formatAsCurrency(renewalTotal) }} {{ planPriceExtra(billingPlan) }}
              </p>
            </div>
            <div class="flex justify-space-between">
              <p class="text-16 font-semi">Wrestler</p>
              <p class="text-16 flex-shink-1">{{ wrestlerNames() }}</p>
            </div>
            <div class="flex justify-space-between">
              <p class="text-16 font-semi">Renewal Length</p>
              <p class="text-16 flex-shink-1">{{ planLength }}</p>
            </div>
          </div>
          <hr class="m-t-sm">
          <div class="bg-yellow-50 text-yellow-900 p-sm rounded-xxs">
            <p class="m-t-none m-b-none">
              By clicking Renew Subscription, <strong>you are agreeing to extend your auto-pay commitment by an
              additional {{ planLength }}</strong>.
            </p>
            <p class="m-t-sm">You will not be charged until your normal billing date of the {{ billingDay }}.</p>
          </div>
          <ladda-button @lbClicked="renew" el-class="btn btn-primary m-t-sm w-full" :loading="loading">
            Renew Subscription
          </ladda-button>
          <div v-if="error">
            <small>
              <span class="text-danger">{{ errorMessage }}</span>
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { billingPlanMix } from './mixins/billing_plan_mix';
import { formattingMixin } from './mixins/formatting_mix';
import { errorableMix } from './mixins/errorable_mix';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'renew-subscription',
  components: { LaddaButton },
  mixins: [billingPlanMix, formattingMixin, errorableMix],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    renewalPlan: {
      type: Object,
      requird: true,
    },
    renewalFee: {
      type: Object,
      default() {
        return {
          amount: 0
        };
      },
    },
    renewalTax: {
      type: Object,
      default() {
        return {
          amount: 0
        };
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    coupon() {
      return _.head(this.subscription.coupons);
    },
    // This assumes that subscriptions only have 1 billing plan. That is true for now, but we need to do error checking at the rails layer first.
    // We do this computed layer for the subscription mix in we leverage in this component.
    billingPlan() {
      return this.renewalPlan;
    },
    planLength() {
      if (this.billingPlan === null) {
        return '';
      }

      let intervalExpiration = this.billingPlan.interval_count * this.billingPlan.intervals_to_bill;
      let pluralizedInterval = (this.billingPlan.interval_count * this.billingPlan.intervals_to_bill > 1) ? `${this.billingPlan.interval}s` : this.billingPlan.interval;
      return `${intervalExpiration} ${pluralizedInterval}`;
    },
    billingDay() {
      return moment(this.subscription.current_period_end_at)
          .format('Do');
    },
    renewalTotal() {
      const vm = this;
      // TODO fix this up if we ever get into quantity > 1 for renewals
      let total = this.billingPlan.amount + this.renewalFee.amount + this.renewalTax.amount;
      if (this.coupon) {
        if (this.coupon.amount_off > 0) {
          total -= this.coupon.amount_off;
        } else {
          total -= this.coupon.percent_off * (total);
        }
      }

      return total;
    },
  },
  methods: {
    renew: _.throttle(function () {
      const vm = this;
      if (vm.loading === true) {
        return;
      }

      vm.error = false;
      vm.loading = true;

      const renewUrl = vm.$apiService.renewSubscriptionUrl(vm.subscription.id);
      axios.post(renewUrl, {})
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Subscription renewed!', true);
            vm.loading = false;
            setTimeout(function () {
              window.location.assign(`/subscriptions/${vm.subscription.billing_partner_id}`);
            }, 2000);
          })
          .catch((error) => {
            vm.errorMessage = 'Something went wrong renewing your subscription. Try again or email support@wrestlingiq.com';
            vm.error = true;
            vm.loading = false;
          });
    }, 500),
  }
};
</script>
<style scoped>
</style>
