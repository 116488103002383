<template>
  <div>
    <div class="widget style1">
      <div class="row">
        <div class="col-xs-12 text-center">
          <p id="score-numbers" class="score-card">
            {{ score }}
          </p>
        </div>
      </div>
      <div class="row" v-show="scored()">
        <div class="col-xs-12 text-center">
          <p id="score-winner" class="score-card">
            {{ winnerLastName }}
          </p>
        </div>
      </div>
      <template v-if="scored()">
        <div class="add-score-block" v-show="!answering">
          <a v-on:click="showScoreForm">Edit</a>
        </div>
      </template>
      <template v-else>
        <div class="add-score-block" v-show="!answering">
          <a v-on:click="showScoreForm">Add Score</a>
        </div>
      </template>
      <template v-if="answering">
        <div class="score-create-container">
          <div v-show="error">
            <small>
              <span class="text-danger">{{ errorMessage }}</span>
            </small>
          </div>
          <div class="form-group">
            <label for="wrestlerScore">{{ wrestlerLastName }}</label>
            <input v-model="updatedWrestlerScore" class="form-control" id="wrestlerScore" placeholder="" :disabled="loading">
          </div>
          <div class="form-group">
            <label for="opponentScore">{{ opponentLastName }}</label>
            <input v-model="updatedOpponentScore" class="form-control" id="opponentScore" placeholder="" :disabled="loading">
          </div>
          <div class="button-area">
            <button v-show="!loading" type="button" v-on:click="cancel" class="btn btn-white">Cancel</button>
            <ladda-button @lbClicked="saveScore" el-class="btn-primary" :loading="loading">
                Save Score
            </ladda-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'match-score',
  components: { LaddaButton },
  props: [
    'matchId',
  ],
  data() {
    return {
      loading: false, // Loading is a network variable (hitting the api)
      answering: false, // Answering is a click driven action (user is editing the score - show the form)

      updatedOpponentScore: '',
      updatedWrestlerScore: '',

      finalOpponentScore: '',
      finalWrestlerScore: '',

      score: 'N/A',
      wrestlerLastName: '',
      opponentLastName: '',
      winnerLastName: '',
      match: null,

      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  created() {
    const vm = this;
    vm.getMatch();
    vm.$notificationManager.$on('match-score-updated', vm.handleUpdatedScore);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('match-score-updated', vm.handleUpdatedScore);
  },
  methods: {
    handleUpdatedScore(matchId) {
      const vm = this;
      if (matchId === vm.matchId) {
        vm.getMatch();
      }
    },
    setupLastNames() {
      const vm = this;
      const { match } = vm;

      vm.wrestlerLastName = match.wrestler.last_name;
      vm.opponentLastName = match.opponent.last_name;

      if (vm.finalOpponentScore > vm.finalWrestlerScore) {
        vm.winnerLastName = match.opponent.last_name;
      } else {
        vm.winnerLastName = match.wrestler.last_name;
      }
    },
    setupScore() {
      const vm = this;
      const { match } = vm;

      vm.finalOpponentScore = match.final_opponent_score;
      vm.updatedOpponentScore = match.final_opponent_score;

      vm.finalWrestlerScore = match.final_wrestler_score;
      vm.updatedWrestlerScore = match.final_wrestler_score;

      if (match.finalOpponentScore === null || match.final_opponent_score === null) {
        vm.score = 'N/A';
      } else {
        vm.score = `${vm.finalWrestlerScore} - ${vm.finalOpponentScore}`;
      }
    },
    scored() {
      const vm = this;
      return vm.finalOpponentScore !== null && vm.finalWrestlerScore !== null;
    },
    showScoreForm() {
      this.answering = true;
    },
    cancel() {
      this.answering = false;
    },
    validNumber(string) {
      // Greater than 0 length, and all digits
      return (string.length > 0 && !/\D/.test(string));
    },
    getMatch() {
      const vm = this;

      vm.loading = true;
      const url = vm.$apiService.matchUrl(vm.matchId);
      axios.get(url)
        .then((response) => {
          vm.loading = false;
          vm.match = response.data;
          vm.setupScore();
          vm.setupLastNames();
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error retrieving match score';
          vm.error = true;
        });
    },
    saveScore: _.throttle(function () {
      const vm = this;

      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }

      const validNumbers = vm.validNumber(String(vm.updatedOpponentScore)) && vm.validNumber(String(vm.updatedWrestlerScore));
      if (!validNumbers) {
        vm.errorMessage = 'Please enter only numbers. Letters are not allowed.';
        vm.error = true;
        return;
      }
      vm.error = false;

      vm.loading = true;

      const params = {
        match: {
          final_opponent_score: vm.updatedOpponentScore,
          final_wrestler_score: vm.updatedWrestlerScore,
        },
      };

      const url = vm.$apiService.matchUrl(vm.matchId);
      axios.put(url, params)
        .then((response) => {
          vm.loading = false;
          vm.answering = false;
          vm.$notificationManager.$emit('match-score-updated', vm.matchId);
          vm.$notificationManager.$emit('show-toast', 'Score updated', true);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
