<template>
  <div class="col-md-8">
    <div class="ibox">
      <div class="ibox-title">
        <h1>
          Enter USA Wrestling Membership Info for {{ profileName }}
        </h1>
      </div>
      <div class="ibox-content">
        <div v-if="mostRecentUsawId !== ''" class="alert alert-info" role="alert">
          <strong>Previous USA Wrestling info found.</strong> Please verify that the member number, expiration date, and membership type matches
          the USA Wrestling membership you just purchased or renewed.
        </div>
        <div role="form">
          <div class="form-group">
            <label for="usaw_id">USAW ID</label>
            <input data-cy="usaw-id" id="usaw_id" v-model="usawId" type="text" class="form-control font-no-ios-zoom">
          </div>
          <div class="form-group">
            <label for="membership_type">Membership type</label>
            <select data-cy="usaw-type" id="membership_type" v-model="membershipType" class="form-control">
              <option selected disabled>Choose membership type...</option>
              <optgroup label="Athlete Membership">
                <option value="Full Athlete">Full Athlete</option>
                <option value="High Performance Athlete">High Performance Athlete</option>
                <option value="Intramural">Intramural</option>
                <option value="Limited Folkstyle">Limited Folkstyle</option>
                <option value="Open License">Open License</option>
              </optgroup>
            </select>
          </div>
          <div class="form-group">
            <label for="expiresOn">Expires on</label>
            <the-mask mask="##/##/####"
                      placeholder="mm/dd/yyyy"
                      v-model="expiresOn"
                      class="form-control font-no-ios-zoom"
                      id="expiresOn"
                      :masked="true"
                      ref="date"
                      data-cy="expiresOn"
                      type="tel">
            </the-mask>
          </div>
          <div v-if="photoRequired">
            <label>Photo or screenshot of USA Wrestling card</label>
            <pintura-photo-uploader
                :access-key="accessKey"
                :endpoint="endpoint"
                :cloudfront-base-url="cloudfrontBaseUrl"
                :existing-attachments="existingAttachments"
                prefix="usaw_cards/"
                @photos-changed="photosChanged"
            ></pintura-photo-uploader>
          </div>
          <div class="m-t-lg flex">
            <button class="btn btn-default" @click="$emit('cancel-usaw-form')">
              Cancel
            </button>
            <button data-cy="usaw-submit"
                    class="btn btn-primary align-self-flex-end m-l-auto"
                    @click="submit"
                    :disabled="loading">
              Save
            </button>
          </div>
          <p class="text-danger m-t-m" v-if="error">
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from './spinner.vue';
import { errorableMix } from './mixins/errorable_mix';
import { TheMask } from 'vue-the-mask';
import PinturaPhotoUploader from './pintura_photo_uploader.vue';
import { photoUploadMix } from './mixins/photo_upload_mix';

export default {
  name: 'usaw-form',
  components: {
    PinturaPhotoUploader,
    Spinner,
    TheMask,
  },
  mixins: [errorableMix, photoUploadMix],
  props: {
    wrestlerId: {
      type: Number,
      required: true
    },
    mostRecentUsawId: {
      type: String,
      default: ''
    },
    mostRecentUsawExpired: {
      type: String,
      default: ''
    },
    profileName: {
      type: String,
      default: '',
    },
    usawConfig: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      usawId: '',
      membershipType: '',
      expiresOn: '',
    }
  },
  computed: {
    photoRequired() {
      return this.usawConfig === 'usaw_require_all';
    }
  },
  created() {
    this.usawId = this.mostRecentUsawId
    if (this.mostRecentUsawExpired !== '') {
      this.expiresOn = moment(this.mostRecentUsawExpired, 'MM/DD/YYYY').add(1, 'year').format('MM/DD/YYYY');
    } else {
      this.expiresOn = '';
    }
  },
  methods: {
    validate() {
      let error = false;
      let errorMessage = 'Please ensure ';

      if (this.usawId.length === 0) {
        errorMessage += 'USAW ID, ';
        error = true;
      }

      if (this.membershipType.length === 0) {
        errorMessage += 'membership type, ';
        error = true;
      }

      if (this.expiresOn === '' || moment().isAfter(moment(this.expiresOn))) {
        errorMessage += 'expires on, ';
        error = true;
      }

      if (this.photoRequired && !this.hasUploadedPhoto) {
        errorMessage += 'screenshot of USA Wrestling card, ';
        error = true;
      }

      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        this.errorMessage = errorMessage;
      }

      return error;
    },
    submit() {
      const vm = this;
      // Some initial guards
      if (vm.loading === true) { return; }

      vm.error = this.validate();
      if (vm.error) { return; }

      // Now to the good stuff
      vm.loading = true;
      const params = {
        usaw_membership: {
          public_id: vm.usawId,
          expires_on: moment(vm.expiresOn, 'MM/DD/YYYY').toISOString(),
          membership_type: vm.membershipType
        }
      };

      if (vm.photoRequired) {
        params.usaw_membership.id_card_attributes = vm.hasOneAttachmentParams;
      }

      const url = vm.$apiService.usawMemberships(vm.wrestlerId);
      axios.post(url, params)
          .then((response) => {
            vm.loading = false;
            vm.$emit('usaw-created', response.data);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error creating usaw membership, please try again later or contact support@wrestlingiq.com';
            vm.error = true;
          });
    }
  }
};
</script>
