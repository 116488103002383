<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="">
          <h2 class="m-t-xs m-b-xs">Roster shortcuts</h2>
          <p class="m-t-none">
            Send invites to wrestlers and parents associated with a specific roster
          </p>
        </div>
        <div>
          <template v-if="loadingProfiles">
            <spinner></spinner>
          </template>
          <div v-show="!loadingProfiles" class="flex flex-wrap align-items-center gap-xxs">
            <button v-for="roster in rosters" @click.prevent="addRoster(roster)" class="btn btn-default">
               {{ roster.name }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12 m-t-md">
        <h2>Other shortcuts</h2>
        <div>
          <template v-if="loadingProfiles">
            <spinner></spinner>
          </template>
          <div v-show="!loadingProfiles" class="flex align-items-center gap-x-xs">
            <button class="btn btn-default" v-on:click="addAllCoaches">Add all active coaches</button>
            <button v-if="showParents" class="btn btn-default" v-on:click="addAllParents">Add all active parents
            </button>
            <button class="btn btn-default" v-on:click="addAllWrestlers">Add all active wrestlers</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-t-md">
      <div class="col-sm-12">
        <h2>Or add guests individually</h2>
        <search
            class="search-form"
            response-key="results"
            placeholder="Type name..."
            :mutate-history="false"
            :allow-add="false"
            :display-all-on-empty="false"
            readable-model-name="results"
            :profiles-only="true"
            base-url="/api/v1/search">
          <template v-slot:list="slotProps">
            <div class="profile-wrapper" @click="profileClicked(slotProps.result, true)">
              <generic-search-result
                  v-bind:result="slotProps.result"
                  v-bind:is-selectable="true"
                  v-bind:key="slotProps.result.id">
              </generic-search-result>
            </div>
          </template>
        </search>
      </div>
    </div>
    <div class="row m-t-xs" v-if="suggestFinding != null && suggestions.length > 0">
      <div class="col-sm-12 col-lg-4 rounded-sm p-y-lg p-x-m m-x-m bg-gray-50">
        <h3 class="m-t-none"><strong><i class="fa fa-magic"></i> Smart Suggestion</strong></h3>
        <h3 class="m-t-sm">{{ suggestFinding }}</h3>
        <spinner v-if="suggestLoading"></spinner>
        <div class="grid grid-cols-1 gap-y-3 m-t-m">
          <div class="flex justify-space-between align-items-center" v-for="(profile, index) in suggestions">
            <p class="m-y-none">{{ profile.first_name }} {{ profile.last_name }}</p>
            <button class="btn btn-primary btn-outline btn-compact" style="padding: 0 8px 0 8px;" @click="addSuggestion(profile, index)"><i class="fa fa-plus"></i> Add</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="uniqueInvitees.length > 0" class="row m-t-lg">
      <div class="col-xs-12 col-sm-12 col-lg-4">
        <h5>Invites to be sent</h5>
        <div class="m-l-sm">
          <div v-for="invite in uniqueInvitees">
            <event-invite :invite="invite" :show-type="true"></event-invite>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-t-lg">
      <div class="col-xs-12 text-right">
        <ladda-button @lbClicked="batchPostInvites" el-class="btn-primary" :loading="loading">
          Save & Send Invites
        </ladda-button>
        <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
      </div>
    </div>
  </div>
</template>
<script>
import Search from '../../shared/search.vue';
import GenericSearchResult from './generic-search-result.vue';
import EventInvite from './event_invite.vue';
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'add-guests-form',
  components: {
    LaddaButton,
    Spinner,
    EventInvite,
    GenericSearchResult,
    Search,
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    showParents: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      invitesToAdd: [],
      loading: false,
      error: false,
      errorMessage: '',
      suggestLoading: false,
      suggestFinding: null,
      // Suggestions when you add people individually
      suggestions: [],

      loadingProfiles: false,
    };
  },
  computed: {
    rosters() {
      return _.map(this.event.roster_events, 'roster');
    },
    uniqueInvitees() {
      return _.uniqWith(this.invitesToAdd,   (invite1, invite2) => {
        return invite1.invitee.id === invite2.invitee.id && invite1.invitee.type === invite2.invitee.type
      });
    }
  },
  created() {
    const vm = this;
    vm.$notificationManager.$on('remove-event-invite', vm.removeInvite);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('remove-event-invite', vm.removeInvite);
  },
  methods: {
    // Used in the add individually case to suggest related parents
    suggestionsFor(profile, profile_type) {
      let vm = this;
      vm.suggestLoading = true
      vm.suggestFinding = profile_type === 'WrestlerProfile' ? `Add guardians of ${profile.name}` : `Add wrestler(s) related to ${profile.name}`;
      const url = profile_type === 'WrestlerProfile' ? vm.$apiService.wrestlerGuardiansUrl(profile.id) : vm.$apiService.guardianWrestlersUrl(profile_type, profile.id);
      const jsonKey = profile_type === 'WrestlerProfile' ? 'guardians' : 'wrestlers'
      vm.$apiService.loadAllPages(url, jsonKey)
          .then((objects) => {
            vm.suggestions = objects;
            vm.suggestLoading = false;
          })
          .catch((error) => {
            vm.suggestLoading = false;
            vm.errorMessage = `Error retrieving suggestion results ${error.toString()}`;
            vm.error = true;
          });
    },
    addSuggestion(profile, index) {
      this.profileClicked(profile, false);
      this.$delete(this.suggestions, index);
    },
    // The batch invite endpoint dedupes server side
    addInviteesWithType(profiles, profile_type) {
      const vm = this;
      profiles.forEach((profile) => {
        const pendingInvite = {
          status: 'pending',
          invitee: {
            id: profile.id,
            type: profile_type,
            first_name: profile.first_name,
            last_name: profile.last_name,
          },
        };
        vm.invitesToAdd.push(pendingInvite);
      });
    },
    addWrestlersAndGuardians(wrestlers) {
      const vm = this;
      wrestlers.forEach((wp) => {
        const pendingInvite = {
          status: 'pending',
          invitee: {
            id: wp.id,
            type: "WrestlerProfile",
            first_name: wp.first_name,
            last_name: wp.last_name,
          },
        };
        vm.invitesToAdd.push(pendingInvite);

        // Guardians of the wrestler
        wp.guardians.forEach((guardian) => {
          const pendingInvite = {
            status: 'pending',
            invitee: {
              id: guardian.id,
              type: guardian.type,
              first_name: guardian.first_name,
              last_name: guardian.last_name,
            },
          };
          vm.invitesToAdd.push(pendingInvite);
        })
      });
    },
    addRoster(roster) {
      // load all pages of the roster, convert them to pending invites
      const vm = this;
      vm.loadingProfiles = true;
      vm.error = false;

      const url = vm.$apiService.rosterWrestlersUrl(roster.id)
      vm.$apiService.loadAllPages(url, 'wrestlers')
          .then((objects) => {
            vm.addWrestlersAndGuardians(objects);
            vm.loadingProfiles = false;
          })
          .catch((error) => {
            vm.loadingProfiles = false;
            vm.errorMessage = `Error retrieving results ${error.toString()}`;
            vm.error = true;
          });
    },
    addAllParents() {
      // load all pages of parents convert them to pending invites
      const vm = this;
      vm.loadingProfiles = true;
      vm.error = false;

      const url = vm.$apiService.parentsUrl();

      vm.$apiService.loadAllPages(url, 'parent_profiles')
          .then((objects) => {
            vm.addInviteesWithType(objects, 'ParentProfile');
            vm.loadingProfiles = false;
          })
          .catch((error) => {
            vm.loadingProfiles = false;
            vm.errorMessage = `Error retrieving results ${error.toString()}`;
            vm.error = true;
          });
    },
    addAllWrestlers() {
      // load all pages of wrestlers convert them to pending invites
      const vm = this;
      vm.loadingProfiles = true;
      vm.error = false;

      const url = vm.$apiService.wrestlersUrl();

      vm.$apiService.loadAllPages(url, 'wrestlers')
          .then((objects) => {
            vm.addInviteesWithType(objects, 'WrestlerProfile');
            vm.loadingProfiles = false;
          })
          .catch((error) => {
            vm.loadingProfiles = false;
            vm.errorMessage = `Error retrieving results ${error.toString()}`;
            vm.error = true;
          });
    },
    addAllCoaches() {
      // load all pages of coaches, convert them to pending coaches
      const vm = this;
      vm.loadingProfiles = true;
      vm.error = false;

      const url = vm.$apiService.coachesUrl();

      vm.$apiService.loadAllPages(url, 'coaches')
          .then((objects) => {
            vm.addInviteesWithType(objects, 'CoachProfile');
            vm.loadingProfiles = false;
          })
          .catch((error) => {
            vm.loadingProfiles = false;
            vm.errorMessage = `Error retrieving results ${error.toString()}`;
            vm.error = true;
          });
    },
    removeInvite(invite) {
      const vm = this;
      let inviteeId = invite.invitee?.id;
      let inviteeType = invite.invitee?.type;
      let idx = vm.invitesToAdd.indexOf(invite);
      while (idx !== -1) {
        vm.invitesToAdd.splice(idx, 1);
        idx = _.findIndex(vm.invitesToAdd, function(i) {
          return i.invitee?.id === inviteeId && i.invitee?.type === inviteeType;
        });
      }
    },
    profileClicked(profile, showSuggestions) {
      const vm = this;
      const names = (profile.name || profile.full_name).split(' ');
      const pendingProfile = {
        id: profile.id,
        first_name: _.get(names, '0', ''),
        last_name: _.get(names, '1', ''),
      };
      vm.addInviteesWithType([pendingProfile], profile.type);
      vm.$notificationManager.$emit('event-invite-clicked');

      if (showSuggestions) {
        vm.suggestionsFor(profile, profile.type);
      }
    },
    batchParams() {
      return {
        event_invites: this.uniqueInvitees,
      };
    },
    batchPostInvites() {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.error = false;
      vm.loading = true;

      const url = vm.$apiService.eventInviteBatchUrl(vm.event.id);

      axios.post(url, vm.batchParams())
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', 'Event invites sent', true);
            window.location.assign(`/events/${vm.event.id}`);
          })
          .catch((error) => {
            vm.error = true;
            vm.errorMessage = `Error sending invites ${error.toString()}`;
            vm.loading = false;
          });
    },
  },
};
</script>
