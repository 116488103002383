<template>
  <div id="reg-form-builder-container" class="row">
    <div class="col-lg-7">
      <div class="ibox">
        <div class="ibox-content p-t-m">
          <div class="alert alert-info">
            <h2 class="font-heavy m-t-none">{{ infoNoteTitle }}</h2>
            <p>{{ infoNote }}</p>
          </div>
          <div class="alert alert-warning" v-if="forDerivedRegistration">
            <h2 class="font-heavy m-t-none">Derived questions are not editable</h2>
            <p>We've given you a jumpstart by cloning your existing registration questions into this one time
              session.</p>
            <p>To keep report data clean, questions that are derived from your normal questions can't be edited, but you
              can remove any questions you want—or add new ones—just for this session.</p>
          </div>
          <div id="vertical-timeline">
            <template v-if="loading">
              <spinner></spinner>
            </template>
            <draggable v-model="groups" class="dropArea" :options="draggableOptions">
              <div class="vertical-timeline-block"
                   v-for="(group, index) in groups"
                   :key="index"
              >
                <div class="vertical-timeline-icon"></div>
                <div class="vertical-timeline-content practice-card">
                  <div class="should-dim">
                    <div class="timeline-dragger">
                      <i class="fa fa-grip-large group-sorter"></i>
                    </div>
                    <div class="details">
                      <template v-if="group.editing">
                        <div class="form-inline">
                          <div class="form-group">
                            <label :for="$srHelper.idFor('group-with', index)" class="sr-only">Group name</label>
                            <input type="text" placeholder="Group name (e.g. Basic Info)"
                                   :id="$srHelper.idFor('group-with', index)"
                                   class="form-control"
                                   v-bind:value="group.name"
                                   v-on:input="group.name = $event.target.value"
                                   v-bind:ref="'group-with' + index"
                                   @keyup.enter="toggleEdit(group, null)"
                            >
                          </div>
                          <button class="btn btn-primary" type="submit" @click="toggleEdit(group, null)">Done</button>
                        </div>
                      </template>
                      <template v-else>
                        <div>
                          <div class="inline" @click="toggleEdit(group, `group-with${index}`)">
                            <h2>{{ group.name }}&nbsp;
                              <small>
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                <span class="sr-only">Edit {{ group.name }} title</span>
                              </small>
                            </h2>
                          </div>
                          <div class="inline m-l-xs" @click="removeGroup(group, index)">
                            <small>
                              <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                              <span class="sr-only">Delete {{ group.name }}</span>
                            </small>
                          </div>
                        </div>
                      </template>
                      <small v-if="group.error" class="text-danger">
                        {{ group.errorMessage }}
                      </small>
                    </div>
                    <div class="specifics">
                      <draggable :element="'ul'" v-model="group.questions" :options="questionDraggableOptions"
                                 class="sortable-list agile-list no-hover">
                        <li class="question" v-for="(q, q_index) in group.questions">
                          <div class="flex flex-col-xs-only gap-sm justify-space-between">
                            <div class="form-group flex-1-0-auto m-b-none">
                              <label :for="$srHelper.idFor(`group${index}question`, q_index)"
                                     class="sr-only">Question</label>
                              <input type="text" placeholder="Question"
                                     :id="$srHelper.idFor(`group${index}question`, q_index)"
                                     class="form-control"
                                     v-bind:value="q.prompt"
                                     v-on:input="q.prompt = $event.target.value"
                                     v-bind:ref="`group${index}question${q_index}`"
                                     :disabled="q.original_id !=  null"
                              >
                            </div>
                            <div class="form-group m-b-none">
                              <label :for="$srHelper.idFor(`group${index}question-type`, q_index)"
                                     class="sr-only">Type</label>
                              <select :id="$srHelper.idFor(`group${index}question-type`, q_index)"
                                      v-bind:value="q.type"
                                      v-on:change="handleQTypeChange(q, $event)"
                                      class="form-control"
                                      :disabled="q.original_id !=  null"
                              >
                                <option disabled value="">Type...</option>
                                <option v-for="(value, type) in questionTypes" :value="type">{{ value.name }}</option>
                              </select>
                            </div>
                            <div class="form-group m-b-none flex flex-column">
                              <div style="margin-top: -4px;" v-if="isPublicProperty">
                                <label class="checkbox-inline">
                                  <input type="checkbox" v-model="q.required">
                                  Required?
                                </label>
                              </div>
                              <div class="flex gap-x-sm justify-space-between">
                                <div class="settings-toggle cursor-pointer" @click="q.settingsOpen = !q.settingsOpen">
                                  <i class="fa fa-cog relative"></i>
                                </div>
                                <div class="timeline-dragger">
                                  <i class="fa fa-grip question-sorter relative"></i>
                                </div>
                                <div class="flex" @click="removeQuestion(index, q_index)">
                                  <i class="fa fa-trash text-danger m-auto" aria-hidden="true"></i>
                                  <span class="sr-only">Delete {{ q.prompt }} question</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <small v-if="q.error" class="text-danger">
                            {{ q.errorMessage }}
                          </small>
                          <div v-if="q.settingsOpen" class="p-m border border-solid border-gray-400 bg-gray-50 m-y-xs">
                            <div class="m-t-none border-solid border-gray-400 border-b font-semi">Advanced settings
                            </div>
                            <div class="form-group m-b-none m-t-sm">
                              <label :for="$srHelper.idFor(`group${index}question-settings`, q_index)"
                                     style="font-weight: normal;">Answer visibility (i.e. what can assistant coaches
                                see?)</label>
                              <select :id="$srHelper.idFor(`group${index}question-settings`, q_index)"
                                      v-model="q.coach_visibility"
                                      class="form-control"
                              >
                                <option value="all_coaches">All coaches</option>
                                <option value="admin_only">Admins only</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="q.type">
                            <p class="text-muted font-small" style="margin-top: 0;">
                              {{ questionTypes[q.type].description }}
                            </p>
                            <div class="m-l-lg"
                                 v-if="q.type === 'RegSingleSelectQuestion' || q.type === 'RegMultiSelectQuestion'">
                              <div v-for="(answer, a_index) in q.answers">
                                <div class="flex m-b-m">
                                  <div class="form-group m-b-none">
                                    <label :for="$srHelper.idFor(`group${index}question${q_index}answer`, a_index)"
                                           class="sr-only">Option</label>
                                    <input type="text" placeholder="Option"
                                           :id="$srHelper.idFor(`group${index}question${q_index}answer`, a_index)"
                                           class="form-control input-sm"
                                           v-model="q.answers[a_index]"
                                           v-bind:ref="`group${index}question${q_index}answer${a_index}`"
                                           :disabled="q.original_id !=  null"
                                    >
                                  </div>
                                  <div class="m-l-md flex" @click="removeQuestionAnswer(index, q_index, a_index)"
                                       v-if="q.original_id == null">
                                    <i class="fa fa-trash text-danger m-auto" aria-hidden="true"></i>
                                    <span class="sr-only">Delete {{ q.prompt }} question</span>
                                  </div>
                                </div>
                              </div>
                              <div class="add-card" @click="addAnswerFor(q, index, q_index)" style="line-height: 1.0;"
                                   v-if="q.original_id ==  null">
                                <div class="title flex align-items-center gap-x-xs">
                                  <i class="fa fa-plus"></i>
                                  <p class="m-y-none" style="margin-top: 0; margin-bottom: 0;">Add option</p>
                                </div>
                              </div>
                            </div>
                            <div class="m-l-lg" v-if="q.type === 'RegYesNoQuestion'">
                              <div v-for="(answer, a_index) in q.answers">
                                <div class="form-group m-t-md">
                                  <label class="checkbox-inline">
                                    <input type="checkbox" v-model="q.answers[a_index].addtl_info"
                                           :disabled="q.original_id != null">
                                    If '{{ answer.prompt }}', require more info?
                                  </label>
                                </div>
                                <div class="form-group m-t-xxs" v-if="answer.addtl_info">
                                  <label :for="$srHelper.idFor(`group${index}question${q_index}answer`, a_index)"
                                         class="sr-only">Additional info prompt</label>
                                  <input type="text" placeholder="e.g. Please indicate what medications:"
                                         :id="$srHelper.idFor(`group${index}question${q_index}answer`, a_index)"
                                         class="form-control input-sm"
                                         v-model="q.answers[a_index].addtl_prompt"
                                         v-bind:ref="`group${index}question${q_index}answer${a_index}`"
                                         :disabled="q.original_id !=  null"
                                  >
                                  <p class="text-muted text-small">
                                    Free text entry is provided for additional info
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="add-card" @click="addQuestion(group, index)" v-show="!group.editing">
                          <div class="title flex align-items-center gap-x-xs">
                            <i class="fa fa-plus"></i>
                            <p class="m-y-none" style="margin-top: 0; margin-bottom: 0;">Add question</p>
                          </div>
                        </li>
                      </draggable>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
          <div class="flex gap-x-m">
            <div class="border border-1 border-gray-200 rounded-sm p-sm">
              <h1 class="m-t-none m-b-none">Add a new group of questions</h1>
              <div class="grid grid-cols-1 m-t-m">
                <div
                    class="flex gap-x-lg align-items-center justify-space-between border-y border-solid border-gray-50 p-y-sm">
                  <div>
                    <h2>Start from scratch</h2>
                    <p>Create a blank section to add custom questions into. E.g. 'Emergency contact' or 'Basic
                      info'.</p>
                  </div>
                  <div class="flex-shrink-0">
                    <button @click="addGroup" class="btn btn-primary">+ Blank Section</button>
                  </div>
                </div>
                <div class="border-y border-sold border-gray-50 p-y-sm" v-if="recommendedTemplates.length > 0">
                  <div>
                    <h2>Use a recommended template</h2>
                    <p>Use pre-built groups of questions that are commonly used on WrestlingIQ for {{ convertedProfileType }}.</p>
                  </div>
                  <div class="m-t-m w-full">
                    <div v-for="(template, index) in recommendedTemplates" :key="index"
                         class="flex gap-x-lg align-items-center justify-space-between p-sm border border-solid border-gray-300 rounded-xs m-t-sm">
                      <div>
                        <h3 class="m-t-none">{{ template.name }}</h3>
                        <p class="m-t-xs m-y-none">{{ template.subtext }}</p>
                      </div>
                      <div class="flex-shrink-0">
                        <button v-if="isTemplateAdded(template)" class="btn btn-primary" disabled>
                          <i class="fa fa-check"></i> Added
                        </button>
                        <button v-else @click="addTemplate(template)" class="btn btn-primary">
                          <i class="fa fa-plus"></i> Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5">
      <div class="ibox">
        <div class="ibox-title">
          <h1>
            {{ iboxTitle }}
          </h1>
        </div>
        <div class="ibox-content">
          <p>
            {{ iboxContent }}
          </p>
          <div role="form">
            <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
            <div class="button-area text-right">
              <ladda-button @lbClicked="saveAll" el-class="btn-primary" :loading="saving">
                Save
              </ladda-button>
            </div>
          </div>
        </div>
      </div>
      <div class="ibox">
        <div class="ibox-title">
          <h1>
            Preview
          </h1>
        </div>
        <div class="ibox-content">
          <p>This is a preview of what your questions will look like on a mobile browser.</p>
          <div id="preview-frame" class="app-frame mac scrolling" data-title="Mobile Browser"
               style="max-width: 480px; height: 510px; margin-left: auto; margin-right: auto;">
            <registration-info-form
                :profile-type="forProfileType"
                :profile-id="-1"
                :specific-questions="questionsForPreview"
                :preview-mode="true"
            ></registration-info-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import swal from 'sweetalert2';
import Spinner from '../spinner.vue';
import { errorableMix } from '../mixins/errorable_mix';
import LaddaButton from '../../../shared/ladda_button.vue';
import RegistrationInfoForm from '../../../shared/registration_info_form.vue';

export default {
  name: 'reg-form-builder',
  components: {
    Spinner,
    draggable,
    LaddaButton,
    RegistrationInfoForm,
  },
  mixins: [errorableMix],
  props: {
    forProfileType: {
      type: String,
      required: true,
    },
    session: {
      type: Object,
      required: false,
    },
    sessionParam: {
      type: String,
      required: true,
    },
    // In the case of generic questions, we sometimes have one off sessions
    // that inherit from these questions. We allow the team to overwrite them if needed
    showDerivedPrompt: {
      type: Boolean,
      default: false,
    },
    // Is this for public or private properties
    visibility: {
      type: String,
      default: 'public'
    }
  },
  data() {
    return {
      groups: [],
      questionTypes: {
        RegAddressQuestion: {
          name: 'Address',
          description: 'Street address, line 2 (optional), city, state, and zip',
        },
        RegDateQuestion: {
          name: 'Date',
          description: 'mm/dd/yyyy',
        },
        RegEmailQuestion: {
          name: 'Email',
          description: 'Only correctly formatted email addresses are accepted (i.e. includes @ symbol, etc)',
        },
        RegPhoneNumberQuestion: {
          name: 'Phone #',
          description: 'Ten digit number e.g. (555) 555 - 5555',
        },
        RegPhotoQuestion: {
          name: 'Photo',
          description: 'Upload a photo from their computer, phone, or tablet',
        },
        RegShortTextQuestion: {
          name: 'Text',
          description: 'Free text entry',
        },
        RegSingleSelectQuestion: {
          name: 'Dropdown',
          description: 'Allows for picking one option',
        },
        RegMultiSelectQuestion: {
          name: 'Checkbox',
          description: 'Allows for picking multiple options',
        },
        RegYesNoQuestion: {
          name: 'Yes/No',
          description: 'Presents \'Yes\' and \'No\' answers, with an option to require additional info',
        },
      },
      loading: false,
      saving: false,
      unsavedChanges: false,
      draggableOptions: {
        group: {
          name: 'group-with',
          pull: false,
          put: false,
        },
        handle: '.group-sorter',
        sort: true,
      },
      questionDraggableOptions: {
        group: {
          name: 'question-group',
          pull: false,
          put: false,
        },
        handle: '.question-sorter',
        sort: true,
      },
      deletedQuestions: [],
      preBuiltTemplates: [
        // Wrestlers
        {
          name: 'Emergency Contact',
          subtext: 'Collect emergency contact name and phone number',
          for_type: 'wrestlers',
          added: false,
          template_id: "emg_contact_wrestlers",
          groups: [
            {
              name: 'Emergency Contact',
              questions: [
                {
                  prompt: 'Name',
                  display_order: 0,
                  for_type: 'wrestlers',
                  type: 'RegShortTextQuestion',
                  answers: null,
                  required: true,
                  subtext: '',
                  is_public: true,
                  error: false,
                  errorMessage: '',
                  settingsOpen: false,
                  coach_visibility: 'all_coaches',
                  from_template_id: "emg_contact_wrestlers",
                },
                {
                  prompt: 'Phone number',
                  display_order: 1,
                  for_type: 'wrestlers',
                  type: 'RegPhoneNumberQuestion',
                  answers: null,
                  required: true,
                  subtext: '',
                  is_public: true,
                  error: false,
                  errorMessage: '',
                  settingsOpen: false,
                  coach_visibility: 'all_coaches',
                  from_template_id: "emg_contact_wrestlers",
                },
              ],
              editing: false,
              error: false,
              errorMessage: '',
            }
          ],
        },
        {
          name: 'Nonprofit Impact Data',
          subtext: 'Do you raise money for your team through grants or sponsors? These demographic questions help show your impact in the community and align with published guidelines for data collection by the US Federal Government.',
          for_type: 'wrestlers',
          added: false,
          template_id: "nonprofit_impact_wrestlers",
          groups: [
            {
              name: 'Wrestler Info',
              questions: [
                {
                  prompt: 'Sex',
                  display_order: 0,
                  for_type: 'wrestlers',
                  type: 'RegSingleSelectQuestion',
                  answers: ['Female', 'Male'],
                  required: true,
                  subtext: '',
                  is_public: true,
                  error: false,
                  errorMessage: '',
                  settingsOpen: false,
                  coach_visibility: 'all_coaches',
                  from_template_id: "nonprofit_impact_wrestlers",
                },
                {
                  prompt: 'Race and/or ethnicity',
                  display_order: 1,
                  for_type: 'wrestlers',
                  type: 'RegMultiSelectQuestion',
                  answers: ['American Indian or Alaska Native', 'Asian', 'Black or African American', 'Hispanic or Latino', 'Middle Eastern or North African', 'Native Hawaiian or Pacific Islander', 'White', 'Prefer not to disclose'],
                  required: true,
                  subtext: '',
                  is_public: true,
                  error: false,
                  errorMessage: '',
                  settingsOpen: false,
                  coach_visibility: 'all_coaches',
                  from_template_id: "nonprofit_impact_wrestlers",
                },
                {
                  prompt: 'Do you qualify for free or reduced lunch?',
                  display_order: 2,
                  for_type: 'wrestlers',
                  type: 'RegSingleSelectQuestion',
                  answers: ['Yes', 'No', 'Prefer not to disclose'],
                  required: true,
                  subtext: '',
                  is_public: true,
                  error: false,
                  errorMessage: '',
                  settingsOpen: false,
                  coach_visibility: 'all_coaches',
                  from_template_id: "nonprofit_impact_wrestlers",
                },
                {
                  prompt: 'Address',
                  display_order: 3,
                  for_type: 'wrestlers',
                  type: 'RegAddressQuestion',
                  answers: null,
                  required: true,
                  subtext: '',
                  is_public: true,
                  error: false,
                  errorMessage: '',
                  settingsOpen: false,
                  coach_visibility: 'all_coaches',
                  from_template_id: "nonprofit_impact_wrestlers",
                },
              ],
              editing: false,
              error: false,
              errorMessage: '',
            }
          ],
        },
        // parents
        {
          name: 'Contact info',
          subtext: 'Collect a phone number for the parent/guardian',
          for_type: 'parents',
          added: false,
          template_id: "phone_contact_parents",
          groups: [
            {
              name: 'Contact info',
              questions: [
                {
                  prompt: 'Phone number',
                  display_order: 0,
                  for_type: 'parents',
                  type: 'RegPhoneNumberQuestion',
                  answers: null,
                  required: true,
                  subtext: '',
                  is_public: true,
                  error: false,
                  errorMessage: '',
                  settingsOpen: false,
                  coach_visibility: 'all_coaches',
                  from_template_id: "phone_contact_parents",
                },
              ],
              editing: false,
              error: false,
              errorMessage: '',
            }
          ],
        },
      ]
    };
  },
  created() {
    this.loadAllQuestions();
  },
  computed: {
    recommendedTemplates() {
      if (!this.isPublicProperty) {
        return [];
      }
      // Filter prebuilt templates to this.convertedprofiletype on the for_type
      return this.preBuiltTemplates.filter((template) => {
        return template.for_type === this.convertedProfileType;
      });
    },
    forDerivedRegistration() {
      return this.session?.id != null;
    },
    isPublicProperty() {
      // Why not just match to public? Because public is our default, private the special case
      return (this.visibility !== 'private');
    },
    questionsForPreview() {
      return _.flatMap(this.groups, (g) => {
        return _.map(g.questions, (q) => {
          q.group_with = g.name;
          return q;
        });
      });
    },
    forProperties() {
      return this.sessionParam === 'properties';
    },
    forCheckIns() {
      return this.convertedProfileType === 'check_ins';
    },
    isEmpty() {
      return this.groups.length === 0;
    },
    convertedProfileType() {
      switch (this.forProfileType) {
        case 'ParentProfile':
          return 'parents';
        case 'WrestlerProfile':
          return 'wrestlers';
        case 'CheckIn':
          return 'check_ins';
        default:
          return '';
      }
    },
    infoNote() {
      if (this.forCheckIns) {
        return 'The check in / attendance questions you set up here will be asked as soon as a wrestler is marked as present.';
      }

      if (!this.isPublicProperty) {
        return 'If you need guardians to enter/edit information about their wrestlers, use public properties instead.';
      }

      if (this.convertedProfileType === 'parents') {
        return 'You do not need to request that info again with custom properties.';
      }

      return 'You do not need to request that info again with custom properties.';
    },
    infoNoteTitle() {
      if (this.forCheckIns) {
        return '';
      }

      if (!this.isPublicProperty) {
        return 'Private wrestler properties are only visible to coaches.';
      }

      if (this.convertedProfileType === 'parents') {
        return 'Parent email, first name, and last name are already collected';
      }

      return 'Wrestler first name, last name, date of birth, and weight are already collected';
    },
    iboxTitle() {
      if (this.forCheckIns) {
        return 'Check in / attendance questions';
      }

      if (this.forProperties) {
        const prefix = this.isPublicProperty ? 'Properties' : 'Private properties';
        return `${prefix} for ${this.convertedProfileType}`;
      }

      return `Registration questions for ${this.sessionName}`;
    },
    iboxContent() {
      if (this.forCheckIns) {
        return 'Use these custom questions if you need to collect additional information when a wrestler attends a practice. E.g. temperature checks, asking for flu like symptoms, etc.';
      }

      if (!this.isPublicProperty) {
        return 'Use private properties to store information that should not be visible to wrestlers or parents. This could be lead information, skill level notes, internal point of contact for the wrestler, etc.';
      }

      if (this.forProperties) {
        if (this.convertedProfileType === 'parents') {
          return 'Parents answer these questions about themselves when they register for an account.';
        }

        return 'This is the info you require when a wrestler sets up their WrestlingIQ account. If you are a club team, parents answer these questions when they first add a wrestler and sign up for a session. If you invite a wrestler directly (high school or college teams) the wrestler will answer these questions when signing up.';
      }

      if (this.convertedProfileType === 'parents') {
        return `Parents answer these questions about themselves when they register for ${this.sessionName}.`;
      }
      return `Questions specific to each wrestler (e.g. USA Wrestling Card #) that parents must answer for their child when registering for ${this.sessionName}.`;
    },
    sessionName() {
      return _.get(this.session, 'name', '');
    },
    sessionIdForSaving() {
      // These are properties, which have a paid_session_id of nil
      if (this.forProperties) {
        return '';
      }

      // otherwise the sesion id is passed in as this param
      return this.sessionParam;
    },
  },
  watch: {
    unsavedChanges(newUnsavedChanges) {
      if (newUnsavedChanges) {
        // Enable navigation prompt block
        window.onbeforeunload = function () {
          return true;
        };
      } else {
        // Remove navigation prompt
        window.onbeforeunload = null;
      }
    },
  },
  methods: {
    isTemplateAdded(template) {
      // loop through the questions in the groups and see if the template_id is present
      return this.groups.some((group) => {
        return group.questions.some((question) => {
          return question.from_template_id === template.template_id;
        });
      });
    },
    handleQTypeChange(question, event) {
      const type = event.target.value;
      if (type === 'RegSingleSelectQuestion' || type === 'RegMultiSelectQuestion') {
        question.answers = [];
      } else if (type === 'RegYesNoQuestion') {
        question.answers = [
          {
            prompt: 'Yes',
            addtl_info: false,
            addtl_prompt: '',
          },
          {
            prompt: 'No',
            addtl_info: false,
            addtl_prompt: '',
          },
        ];
      } else {
        question.answers = null;
      }
      question.type = type;
      this.unsavedChanges = true;
    },
    addAnswerFor(question, index, q_index) {
      this.groups[index].questions[q_index].answers.push('');
      this.focusRef(`group${index}question${q_index}answer${question.answers.length - 1}`);
      this.unsavedChanges = true;
    },
    focusRef(refName) {
      const vm = this;
      vm.$nextTick(() => {
        const input = vm.$refs[refName];
        if (input) {
          input[0].focus();
        }
      });
    },
    addGroup() {
      this.groups.push({
        name: '',
        questions: [],
        editing: true,
        error: false,
        errorMessage: '',
      });
      this.focusRef(`group-with${this.groups.length - 1}`);
      this.unsavedChanges = true;
    },
    addTemplate(template) {
      this.groups.push(...template.groups);
      this.unsavedChanges = true;
    },
    removeGroup(group, index) {
      const vm = this;
      const deletedGroups = vm.groups.splice(index, 1);
      vm.unsavedChanges = true;

      // Make sure to include all deleted questions in this
      deletedGroups[0].questions.forEach((q) => {
        vm.deletedQuestions.push(q);
      });
    },
    toggleEdit(group, refName) {
      group.editing = !group.editing;
      if (group.editing && refName) {
        this.focusRef(refName);
      }
    },
    addQuestion(group, index) {
      group.questions.push({
        prompt: '',
        display_order: group.questions.length,
        for_type: this.convertedProfileType,
        type: null,
        answers: null,
        required: this.isPublicProperty,
        subtext: '',
        is_public: this.isPublicProperty,
        error: false,
        errorMessage: '',
        settingsOpen: false,
        coach_visibility: 'all_coaches',
        from_template_id: null,
      });
      this.focusRef(`group${index}question${group.questions.length - 1}`);
      this.unsavedChanges = true;
    },
    removeQuestionAnswer(g_index, q_index, index) {
      this.groups[g_index].questions[q_index].answers.splice(index, 1);
      this.unsavedChanges = true;
    },
    removeQuestion(g_index, index) {
      const deletedQs = this.groups[g_index].questions.splice(index, 1);
      this.deletedQuestions.push(deletedQs[0]);
      this.unsavedChanges = true;
    },
    loadAllQuestions() {
      const vm = this;
      vm.loading = true;
      const url = this.isPublicProperty ? vm.$apiService.registrationQuestions(vm.forProfileType, vm.sessionParam) : vm.$apiService.privateWrestlerProperties();
      vm.$apiService.loadAllPages(url, 'registration_questions')
          .then((reg_questions) => {
            // First build up a hash like structure with group_with as the key
            const groups_categories = _.uniq(_.map(reg_questions, 'group_with'));
            const groupedQuestions = {};
            for (const key of groups_categories) {
              // each category should be able to hold an array
              groupedQuestions[key] = [];
            }

            // Then throw in the questions into the hash structure
            for (const question of reg_questions) {
              question.error = false;
              question.errorMessage = '';
              question.settingsOpen = false;
              groupedQuestions[question.group_with].push(question);
            }

            // Finally transform this into the data structure this component needs
            const groups = [];
            for (const key of groups_categories) {
              groups.push({
                name: key,
                questions: groupedQuestions[key],
                editing: false,
                error: false,
                errorMessage: '',
              });
            }
            vm.groups = groups;
            vm.deletedQuestions = [];
            vm.loading = false;
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = `Error retrieving registration questions ${error.toString()}`;
            vm.error = true;
          });
    },
    validateAll() {
      const vm = this;
      vm.errorMessage = '';
      vm.error = false;

      let hasError = false;
      vm.groups.forEach((group) => {
        group.error = false;

        const groupName = group.name;
        if (groupName === '') {
          group.error = true;
          hasError = true;
          group.errorMessage = 'Please give this question group a title e.g. \'Basic info\' or \'Emergency contact\'';
        }

        group.questions.forEach((q) => {
          q.error = false;

          if (!q.type) {
            q.error = true;
            hasError = true;
            q.errorMessage = 'Please give this question a type';
          }

          if (q.prompt === '') {
            q.error = true;
            hasError = true;
            q.errorMessage = 'Please give this question a title';
          }

          if (q.type === 'RegSingleSelectQuestion' || q.type === 'RegMultiSelectQuestion') {
            if (q.answers.length === 0) {
              q.error = true;
              hasError = true;
              q.errorMessage = 'This type requires at least one option';
            }
            // Validate that all the answers are setup well
            q.answers.forEach((a) => {
              if (!a || a === '') {
                q.error = true;
                hasError = true;
                q.errorMessage = 'Please make sure all possible answers are not empty, or remove the empty one(s)';
              }
            });
          }

          if (q.type === 'RegYesNoQuestion') {
            q.answers.forEach((a) => {
              if (a.addtl_info && a.addtl_prompt === '') {
                q.error = true;
                hasError = true;
                q.errorMessage = 'Since you require additional info, please specify the info needed e.g. \'Which medications are you taking?\'';
              }
            });
          }
        });
      });

      vm.error = hasError;
      if (vm.error) {
        vm.errorMessage = 'Please fix the issues highlighted on the left';
      }

      return vm.error === false;
    },
    saveAll() {
      // Loop through all the groups, save or update all the questions, then delete the others
      const vm = this;

      if (vm.saving) {
        return;
      }

      if (!vm.validateAll()) {
        return;
      }

      const requests = [];
      let order = 0;

      vm.groups.forEach((group) => {
        const group_with = group.name;
        group.questions.forEach((question) => {
          const params = vm.questionParams(question, group_with, order);
          if (question.id) {
            // PUT
            requests.push(vm.updateQuestion(question.id, params));
          } else {
            // POST
            requests.push(vm.createQuestion(params));
          }
          order += 1;
        });
      });

      vm.deletedQuestions.forEach((question) => {
        // delete them if they have an id
        if (question.id) {
          requests.push(vm.deleteQuestionWithId(question.id));
        }
      });

      vm.error = false;
      vm.saving = true;
      axios.all(requests)
          .then((responseArr) => {
            vm.saving = false;
            vm.$notificationManager.$emit('show-toast', 'Registration questions updated', true);
            vm.unsavedChanges = false;
            if (vm.showDerivedPrompt) {
              vm.showDerivedAlert();
            }
            vm.loadAllQuestions();

            // todo show pop up if we have derived questions for signing
          })
          .catch((error) => {
            const { errors } = error.response.data;
            if (errors && errors.length > 0) {
              vm.errorMessage = errors[1];
            } else {
              vm.errorMessage = 'Most questions were saved, but at least one question failed to save. Please double check all prompts and types are filled out or email support@wrestlingiq.com';
            }

            vm.error = true;
            vm.saving = false;
          });
    },
    questionParams(question, group_with, order) {
      return {
        registration_question: {
          prompt: question.prompt,
          display_order: order,
          for_type: question.for_type,
          type: question.type,
          answers: question.answers,
          group_with,
          required: question.required,
          subtext: question.subtext,
          paid_session_id: this.sessionIdForSaving,
          is_public: question.is_public,
          coach_visibility: question.coach_visibility,
          from_template_id: question.from_template_id,
        },
      };
    },
    createQuestion(params) {
      const url = this.$apiService.registrationQuestionsUrl();
      return axios.post(url, params);
    },
    updateQuestion(questionId, params) {
      const url = this.$apiService.registrationQuestionUrl(questionId);
      return axios.put(url, params);
    },
    deleteQuestionWithId(questionId) {
      const url = this.$apiService.registrationQuestionUrl(questionId);
      return axios.delete(url);
    },
    showDerivedAlert() {
      const vm = this;
      swal.fire({
        type: 'question',
        title: 'Do you want to update properties for all one time sessions too?',
        html: '<p><b>At least one paid session has been created for one time events.</b>. Do you want to update those one time events to collect this info as well?',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonClass: 'btn btn-primary m-l-md',
        confirmButtonText: 'Yes, update all',
        cancelButtonText: 'No, keep them the same',
        cancelButtonClass: 'btn btn-default',
        buttonsStyling: false,
      })
          .then((result) => {
            if (result.value) {
              vm.updateDerivedQuestions();
            }
          });
    },
    updateDerivedQuestions: _.throttle(function () {
      const vm = this;
      axios.post(this.$apiService.syncGuestRegQuestionsUrl(), { for_type: this.convertedProfileType })
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'One time sessions will be updated', true);
          })
          .catch((error) => {
            console.log(error);
          });
    }, 500),
  },
};
</script>
