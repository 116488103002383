<template>
  <div role="form" class="border border-gray-900 border-solid bg-gray-50 p-m rounded-sm">
    <h2 class="m-t-none m-b-m text-18">Create a tag</h2>
    <div class="form-group">
      <label>Name</label>
      <input v-model="name" type="text" class="form-control" />
    </div>
    <div class="form-group">
      <label>Abbreviation (1-4 letters)</label>
      <input
        v-model="abbreviation"
        minlength="1"
        maxlength="4"
        type="text"
        class="form-control"
      />
    </div>
    <div class="form-group flex flex-col">
      <label>Color</label>
      <input v-model="color" type="color" />
    </div>
    <div>
      <div v-show="error">
        <small>
          <span class="text-danger">{{ errorMessage }}</span>
        </small>
      </div>
      <button name="button" @click="$emit('hide')" class="btn btn-default">
        Cancel
      </button>
      <ladda-button
        @lbClicked="createTag"
        el-class="btn-primary"
        :loading="loading"
      >
        Create
      </ladda-button>
    </div>
  </div>
</template>
<script>
import LaddaButton from "../../shared/ladda_button.vue";
import { errorableMix } from "./mixins/errorable_mix";

export default {
  name: "add-tag-form",
  components: { LaddaButton },
  mixins: [errorableMix],
  data() {
    return {
      loading: false,
      name: "",
      abbreviation: "",
      color: "#f2f2f2",
    };
  },
  methods: {
    createTag: _.throttle(function () {
      const vm = this;
      if (vm.loading === true) {
        return;
      }

      if (vm.name.length === 0) {
        vm.errorMessage = "Name must exist, please add one.";
        vm.error = true;
        return;
      }

      if (vm.abbreviation.length > 4 || vm.abbreviation.length < 1) {
        vm.errorMessage = "Abbreviation must be 1-4 characters.";
        vm.error = true;
        return;
      }

      const params = {
        tag: {
          name: vm.name,
          abbreviation: vm.abbreviation,
          color: vm.color,
        },
      };
      const url = vm.$apiService.tagsUrl();
      vm.loading = true;

      axios
        .post(url, params)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit("tag-created", response.data);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error: ${error.toString()}. Please try again later or contact support@wrestlingiq.com`;
          vm.error = true;
        });
    }, 500),
  },
};
</script>
<style scoped></style>
