<template>
  <div class="space-y-6">
    <div v-if="loading || error" class="text-center flex items-center justify-center">
      <spinner :use-svg="true" v-if="loading"></spinner>
      <p v-if="error" class="text-red-600">
        {{ errorMessage }}
      </p>
    </div>
    <div v-for="account in accounts" :key="account.id" class="flex items-center justify-between">
      <div class="flex-1">
        <h3 class="text-lg font-semibold text-gray-900">
          {{ account.team.name }}
        </h3>
        <p class="text-sm text-gray-600">
          {{ readableType(account) }}
        </p>
      </div>
      <div>
        <button 
          @click="switchTo(account)" 
          :disabled="switchingTo === account.id"
          class="flex items-center justify-center rounded-md bg-wiq-green px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-wiq-green-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wiq-green disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Choose
          <spinner v-if="switchingTo === account.id" :inline="true" class="ml-2" :use-svg="true" theme="light"></spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { errorableMix } from './mixins/errorable_mix';
import Spinner from './spinner.vue';

export default {
  name: 'switch-account-form',
  components: { Spinner },
  mixins: [errorableMix],
  props: {
    redirectUrl: {
      type: String,
      default: '/calendar',
    },
  },
  data() {
    return {
      accounts: [],
      loading: false,
      switchingTo: null,
    };
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    readableType(account) {
      return _.replace(account.profile_type, 'Profile', '');
    },
    switchTo(account) {
      const vm = this;
      vm.switchingTo = account.id;
      const url = vm.$apiService.accountSwitchUrl();
      const params = {
        profile: {
          id: account.id,
          type: account.profile_type,
        },
      };
      axios.post(url, params)
        .then((response) => {
          window.location.assign(vm.redirectUrl);
        })
        .catch((error) => {
          vm.switchingTo = null;
          vm.errorMessage = 'Error switching accounts';
          vm.error = true;
        });
    },
    getAccounts() {
      const vm = this;
      const url = vm.$apiService.accountsUrl();
      vm.loading = true;
      vm.$apiService.loadAllPages(url, 'accounts')
        .then((accounts) => {
          vm.loading = false;
          vm.accounts = accounts;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error retrieving accounts';
          vm.error = true;
          console.error('Unable to load accounts', error);
        });
    },
  },
};
</script>
