<template>
    <div role="form" class="parent-invite">
        <div class="form-group">
            <label>Email</label>
            <input v-model="email" type="email" class="form-control">
        </div>
        <div class="form-group">
            <label>First name</label>
            <input v-model="firstName" type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>Last name</label>
            <input v-model="lastName" type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>Wrestlers related to this parent</label>
            <div class="m-l-sm">
                <div v-for="profile in wrestlers">
                    <div>
                        <p>
                            {{ firstLast(profile) }} &nbsp;&nbsp
                            <a v-on:click="removeProfile(profile)" class="text-danger">(remove)</a>
                        </p>
                    </div>
                </div>
                <div v-if="!wrestlerProfilesIncluded()">
                    <span class="selectable" @click="toggleAddWrestler()">
                        <template v-if="showAddWrestler">
                            cancel add wrestler
                        </template>
                        <template v-else>
                            add wrestler (optional)
                        </template>
                    </span>
                </div>
                <template v-if="!wrestlerProfilesIncluded() && showAddWrestler">
                    <search
                            class="search-form"
                            response-key="wrestlers"
                            placeholder="Type wrestler name..."
                            :mutate-history="false"
                            :allow-add="false"
                            :display-all-on-empty="false"
                            readable-model-name="wrestler"
                            base-url="/api/v1/wrestlers">
                        <template v-slot:list="slotProps">
                            <div class="profile-wrapper" @click="profileClicked(slotProps.result)">
                                <div class='search-result'>
                                    <div class='hr-line-dashed'></div>
                                    <h3>
                                        <span class="selectable">{{ slotProps.result.full_name }}</span>
                                    </h3>
                                </div>
                            </div>
                        </template>
                    </search>
                </template>
            </div>
        </div>
        <div class="button-area text-right">
            <div v-show="error">
                <small>
                    <span class="text-danger">{{ errorMessage }}</span>
                </small>
            </div>
            <button v-if="showCancelButton" @click="cancelInvite" class="btn btn-default mr-2">
                Cancel
            </button>
            <ladda-button @lbClicked="postParentInvite" el-class="btn-primary" :loading="loading">
                Invite Parent
            </ladda-button>
        </div>
    </div>
</template>

<script>
import Search from '../../shared/search.vue';
import GenericSearchResult from './generic-search-result.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'parent-create',
  components: { LaddaButton, GenericSearchResult, Search },
  props: [
    // This is the wrestler profile they should already be linked to (when wrestlers create)
    'wrestlerProfiles',
    'redirect',
    'showCancelButton', // New prop for cancel button visibility
  ],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      email: '',
      firstName: '',
      lastName: '',
      wrestlers: [],
      showAddWrestler: false,
    };
  },
  created() {
    const vm = this;

    if (vm.wrestlerProfiles && vm.wrestlerProfiles.length > 0) {
      vm.wrestlers = vm.wrestlers.concat(vm.wrestlerProfiles);
    }
  },
  methods: {
    wrestlerProfilesIncluded() {
      return this.wrestlerProfiles && this.wrestlerProfiles.length > 0;
    },
    firstLast(profile) {
      return profile.full_name || profile.name || `${profile.first_name} ${profile.last_name}`;
    },
    toggleAddWrestler() {
      this.showAddWrestler = !this.showAddWrestler;
    },
    removeProfile(profile) {
      const vm = this;
      const index = vm.wrestlers.indexOf(profile);
      vm.wrestlers.splice(index, 1);
    },
    profileClicked(profile) {
      this.wrestlers.push(profile);
      this.$notificationManager.$emit('profile-clicked');
      this.toggleAddWrestler();
    },
    validate() {
      const vm = this;
      let errorMessage = 'Please ensure ';
      let error = false;
      if (vm.firstName.length === 0) {
        errorMessage += 'first name, ';
        error = true;
      }
      if (vm.lastName.length === 0) {
        errorMessage += 'last name, ';
        error = true;
      }
      if (vm.email.length === 0) {
        errorMessage += 'email, ';
        error = true;
      }

      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        return false;
      }
      return true;
    },
    getWrestlerIds() {
      return _.map(this.wrestlers, 'id');
    },
    postParentInvite: _.throttle(function () {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      if (vm.validate()) {
        vm.loading = true;
        const params = {
          parent_profile: {
            first_name: vm.firstName,
            last_name: vm.lastName,
          },
          email: vm.email,
          wrestler_profile_ids: vm.getWrestlerIds(),
        };
        const url = vm.$apiService.parentsUrl();
        axios.post(url, params)
          .then((response) => {
            vm.loading = false;
            const name = `${vm.firstName} ${vm.lastName}`;
            vm.$notificationManager.$emit('show-toast', `${name} has been sent an invite`, true);
            if (vm.redirect) {
              window.location.assign(vm.redirect);
            } else {
              window.location.assign('/parents');
            }
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
      }
    }, 500),
    cancelInvite() {
      this.$emit('close');
    },
  },
};
</script>
