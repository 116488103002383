<template>
    <div class='search-result'>
        <div class='hr-line-dashed'></div>
        <h3>
            {{ result.name }}
        </h3>
        <p class='search-type-label'>
            {{ result.conference }}
        </p>
    </div>
</template>

<script>
export default {
  name: 'opposing-team-search-result',
  props: [
    'result',
    'isSelectable',
  ],
};
</script>
