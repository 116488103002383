export const manualInvoiceRefundMixin = {
  props: {
    showRefundActions: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showRefundForm: false,
    }
  },
  // This requires a prop or data of 'invoice' to exist
  computed: {
    possibleRefundAmount() {
      return this.invoice.amount_paid - this.alreadyRefundedAmount;
    },
    alreadyRefundedAmount() {
      return _.sumBy(this.invoice.charges, function (charge) {
        return _.sumBy(charge.refunds, 'amount');
      });
    },
    fullyRefunded() {
      return this.invoice.amount_paid > 0 && this.possibleRefundAmount === 0;
    },
    partiallyRefunded() {
      return this.invoice.amount_paid > 0 && this.alreadyRefundedAmount > 0 && !this.fullyRefunded;
    },
    refunds() {
      return _.flatMap(this.invoice.charges, function (charge) {
        return charge.refunds;
      });
    },
    succeededCharge() {
      return _.head(_.filter(this.invoice.charges, { status: 'successful' }));
    },
    paidWithCard() {
      return this.paid && this.succeededCharge?.payment_method === 'card';
    },
    paid() {
      return this.invoice.status === 'paid';
    },
  },
  mounted() {
    this.$on('cancel-refund', this.hideRefundForm);
  },
  destroyed() {
    this.$off('cancel-refund', this.hideRefundForm);
  },
  methods: {
    hideRefundForm(charge) {
      if (charge.id === this.succeededCharge?.id) {
        this.showRefundForm = false;
      }
    },
  }
}
