<template>
  <div class="row">
    <div class="col-lg-12">
      <template v-if="polling">
        Grappling numbers...
        <spinner :inline="true"></spinner>
      </template>
      <template v-else>
        <template v-if="isGenericReport">
          <row-report :report="report"></row-report>
        </template>
        <template v-else-if="isPracticeReport">
          <practice-attendance-report :report="report"></practice-attendance-report>
        </template>
        <template v-else-if="isCheckInReport">
          <check-in-report :report="report" :cloudfront-base-url="cloudfrontBaseUrl"></check-in-report>
        </template>
        <template v-else-if="isRosterReport">
          <roster-report :report="report"></roster-report>
        </template>
        <template v-else-if="isStatsReport">
          <roster-stats-report :report="report"></roster-stats-report>
        </template>
        <template v-else-if="isPaidSessionAccountingReport">
          <paid-session-accounting-report :report="report"></paid-session-accounting-report>
        </template>
        <template v-else-if="isRegistrationAnswerReport">
          <registration-answer-report :report="report"
                                      :cloudfront-base-url="cloudfrontBaseUrl"></registration-answer-report>
        </template>
        <template v-else-if="isSessionRegistrationReport">
          <session-registration-answer-report :report="report"
                                              :cloudfront-base-url="cloudfrontBaseUrl"></session-registration-answer-report>
        </template>
        <template v-else-if="this.reportType === 'RecurringDonorReport'">
          <recurring-donor-report :report="report"></recurring-donor-report>
        </template>
        <template v-else-if="this.reportType === 'DonationTransactionReport'">
          <donation-transaction-report :report="report"></donation-transaction-report>
        </template>
        <template v-else-if="this.reportType === 'FundraiserSummaryReport'">
          <fundraiser-summary-report :report="report"></fundraiser-summary-report>
        </template>
        <template v-else-if="this.reportType === 'FundraiserAccountingReport'">
          <fundraiser-accounting-report :report="report"></fundraiser-accounting-report>
        </template>
        <template v-else-if="this.reportType === 'UsawReport'">
          <usaw-report :report="report"></usaw-report>
        </template>
        <template v-else-if="this.reportType === 'MembershipSummaryReport'">
          <membership-summary-report :report="report"></membership-summary-report>
        </template>
        <template v-else-if="this.reportType === 'InviteStatusReport'">
          <invite-status-report :report="report"></invite-status-report>
        </template>
        <template v-else>
          Unable to find reporting viewer code. Please email support@wrestlingiq.com with a screenshot and info about the report you were trying to run.
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import Spinner from './spinner.vue';
import PracticeAttendanceReport from './reports/practice_attendance_report.vue';
import RosterReport from './reports/roster_report.vue';
import RosterStatsReport from './reports/roster_stats_report.vue';
import PaidSessionAccountingReport from './reports/paid_session_accounting_report.vue';
import RegistrationAnswerReport from './reports/registration_answer_report.vue';
import SessionRegistrationAnswerReport from './reports/session_registration_answer_report.vue';
import RecurringDonorReport from './reports/recurring_donor_report.vue';
import DonationTransactionReport from './reports/donation_transaction_report.vue';
import CheckInReport from './reports/check_in_report.vue';
import UsawReport from './reports/usaw_report.vue';
import FundraiserSummaryReport from './reports/fundraiser_summary_report.vue';
import FundraiserAccountingReport from './reports/fundraiser_accounting_report.vue';
import MembershipSummaryReport from './reports/membership_summary_report.vue';
import RowReport from './reports/row_report.vue';
import InviteStatusReport from './reports/invite_status_report.vue';

export default {
  name: 'report-viewer',
  components: {
    InviteStatusReport,
    RowReport,
    MembershipSummaryReport,
    FundraiserAccountingReport,
    FundraiserSummaryReport,
    UsawReport,
    CheckInReport,
    DonationTransactionReport,
    SessionRegistrationAnswerReport,
    RegistrationAnswerReport,
    RosterReport,
    PracticeAttendanceReport,
    PaidSessionAccountingReport,
    RosterStatsReport,
    RecurringDonorReport,
    Spinner,
  },
  props: [
    'reportId',
    'cloudfrontBaseUrl',
  ],
  data() {
    return {
      polling: true,
      polling_interval: null,
      report: null,
      error: false,
      errorMessage: 'Error retrieving this report. If this is an old report, try generating a new one.',
      genericReportTypes: [
          'FullExportWrestlerReport', 'UsawExportReport', 'RegistrationFinanceSummaryReport', 'LastPracticeAttendedReport',
          'WinLossReport', 'CancelledSubscriptionsReport', 'CurrentlyPausedSubscriptionsReport', 'CheckInFeedReport',
      ],

    };
  },
  computed: {
    isGenericReport() {
      return  _.includes(this.genericReportTypes, this.reportType) || this.report.version === 'vrow';
    },
    isPracticeReport() {
      return this.reportType === 'PracticeAttendanceReport';
    },
    isCheckInReport() {
      return this.reportType === 'CheckInReport';
    },
    isRosterReport() {
      return this.reportType === 'RosterReport';
    },
    isPaidSessionAccountingReport() {
      return this.reportType === 'PaidSessionAccountingReport';
    },
    isStatsReport() {
      return this.reportType === 'RosterStatsReport';
    },
    isRegistrationAnswerReport() {
      return this.reportType === 'RegistrationAnswerReport';
    },
    isSessionRegistrationReport() {
      return this.reportType === 'SessionRegistrationAnswerReport';
    },
    reportType() {
      const vm = this;
      return _.get(vm.report, 'type', '');
    },
  },
  mounted() {
    const vm = this;
    vm.pollForReport();

    vm.polling_interval = setInterval((() => {
      vm.pollForReport();
    }), 5000);
  },
  destroyed() {
    const vm = this;
    clearInterval(vm.polling_interval);
  },
  methods: {
    pollForReport() {
      const vm = this;
      const url = vm.$apiService.reportUrl(vm.reportId);
      axios.get(url)
        .then((response) => {
          vm.report = response.data;
          if (vm.report.status === 'ready') {
            vm.polling = false;
            clearInterval(vm.polling_interval);
          }
        })
        .catch((error) => {
          vm.error = true;
        });
    },
  },
};
</script>
