<template>
  <div class="search-result">
    <div v-if="!noHr" class='hr-line-dashed'></div>
    <div class="row">
      <div class="col-xs-8">
        <h3>
          {{ job.name }}
        </h3>
        <p class="search-type-label">
          <span v-if="processedAt">
            {{ processedAt }}
          </span>
        </p>
      </div>
      <div class="col-xs-4">
        <div class="text-right">
          <div v-html="jobStatus"></div>
          <p class="search-type-label" v-if="succeeded">
            <a :href="canonicalUrl">View merged record</a>
          </p>
          <p class="search-type-label" v-else-if="failed">
            Failed
          </p>
          <p class="search-type-label" v-else>
            Processing
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { urlHelperMixin } from './mixins/url_helper_mix';

export default {
  name: 'merge-job-result',
  props: {
    job: {
      type: Object,
      required: true
    },
    noHr: {
      type: Boolean,
      default: false
    },
  },
  mixins: [
      urlHelperMixin
  ],
  computed: {
    processedAt() {
      if (!this.job.processed_at) {
        return '';
      }

      return moment(this.job.processed_at)
          .format('M/D/YYYY, hh:mm a');
    },
    canonicalUrl() {
      return this.urlForProfile(this.job.canonical_id, this.job.canonical_type)
    },
    succeeded() {
      return this.job.status === "ready";
    },
    failed() {
      return this.job.status === "failed";
    },
    jobStatus() {
      switch (this.job.status) {
        case 'requested':
        case 'processing':
        case 'queued':
          return '<p class="m-b-none"><i class=\'fa fa-circle-o-notch\'></i></p>';
        case 'ready':
          return `<p class='text-success m-b-none'><i class='fa fa-check-circle-o'></i></p>`;
        case 'failed':
          return `<p class='text-danger m-b-none'><i class='fa fa-times-circle'></i></p>`;
        default:
          return '';
      }
    }
  }
};
</script>
