<template>
  <div :id="containerId" style="width: 100%; height: 400px;">
  </div>
</template>
<script>
import Highcharts from 'highcharts';
import Data from 'highcharts/modules/data';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import { currencyMix } from '../../../shared/currency_mix';

Data(Highcharts);

Exporting(Highcharts);

OfflineExporting(Highcharts);

export default {
  name: 'highchart-wrapper',
  mixins: [currencyMix],
  props: {
    containerId: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
    useExporting: {
      type: Boolean,
      default: true,
    },
    isCurrency: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    // Set global things here, like credits and theme
    chartPayload() {
      // Turn off watermark
      let payload = this.payload;
      payload.credits = {
        enabled: false
      };

      payload.exporting = {
        enabled: this.useExporting,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            symbolStrokeWidth: 1,
            symbolFill: '#27AA83',
            symbolStroke: '#324D67'
          }
        }
      };

      return payload;
    }
  },
  mounted() {
    // Set the theme
    Highcharts.theme = this.theme;
    Highcharts.setOptions(Highcharts.theme);
    // Experimental allowed tags in svg/html. not sure if needed anymore
    Highcharts.AST.allowedTags.push('div');
    Highcharts.AST.allowedTags.push('label');
    Highcharts.AST.allowedTags.push('i');
    Highcharts.AST.allowedTags.push('p');
    Highcharts.AST.allowedAttributes.push('class');
    // Save the original subtitle for resetting it on responsive mobile mouse out.
    let vm = this;
    this.initialSubtitle = this.chartPayload.subtitle.text;
    if (this.payload.chart.type === 'line') {
      // Thread in responsive subtitle changing for mobile on line charts
      let responsive = {
        rules: [{
          condition: {
            callback: function (e) {
              return window.matchMedia('screen and (max-width: 480px)').matches;
            }
          },
          chartOptions: {
            tooltip: {
              shadow: false,
              backgroundColor: 'rgba(0,0,0,0)',
              useHTML: true,
              formatter: function (e) {
                let subtitle = `<div class='flex gap-x-sm align-items-baseline justify-space-between m-b-md'>
                                <div class='text-blue-600 text-16 font-semi flex flex-col'>
                                    <div>${vm.isCurrency? vm.formatAsCurrency(this.points[0].point.y * 100) : this.points[0].point.y}</div>
                                    <div class='text-12'>${this.points[0].point.name}</div>
                                </div>
                                <div class='text-gray-600 text-14 font-normal flex flex-col text-right'>
                                    <div>${vm.isCurrency? vm.formatAsCurrency(this.points[1].point.y * 100) : this.points[1].point.y}</div>
                                    <div class='text-12'>${this.points[1].point.name}</div>
                                </div>
                              </div>`;
                // Use the subtitles on mobile to show the numbers so your finger is not in the way of the tooltip
                e.chart.subtitle.attr({
                  text: subtitle
                });
                // Force tooltip to disappear on mobile, thumbs get in the way
                return "<div style='display:none;'></div>";
              }
            }
          }
        }]
      };
      this.chartPayload.responsive = responsive;
    }
    Highcharts.chart(this.containerId, this.chartPayload);
  },
  data() {
    return {
      initialSubtitle: '',
      theme: {
        colors: [
          '#4299D7', '#66D6AD', '#F7D06E',
          '#8562C6', '#E12D39', '#E8368F',
          '#F9703E', '#ABDB5E', '#54D1DB'
        ],
        chart: {
          backgroundColor: '#ffffff'
        },
        title: {
          style: {
            color: '#243A52',
            font: 'bold 16px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif'
          }
        },
        subtitle: {
          style: {
            color: '#48647F',
            font: 'bold 12px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif'
          }
        },
        legend: {
          itemStyle: {
            font: '9pt "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
            color: '#48647F'
          },
        }
      }
    };
  }
};
</script>
