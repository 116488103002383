<template>
    <div class="delete-doc-form archive-form should-dim modify-form m-t-sm">
        <div>
            Are you sure you want to delete this document?
        </div>
        <div class="">
            <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
            <br/>
            <button v-on:click="hideDocumentDeleteForm" class="btn btn-default">Cancel</button>
            <ladda-button @lbClicked="deleteDocPressed" el-class="btn-danger" :loading="loading">
                Yes, Delete
            </ladda-button>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'delete-document-form',
  components: { LaddaButton },
  props: [
    'document',
  ],
  data() {
    return {
      loading: false, // Loading is a network variable (hitting the api)

      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  methods: {
    hideDocumentDeleteForm() {
      const vm = this;
      vm.$notificationManager.$emit('hide-delete-document-form', vm.document);
    },
    deleteDocPressed: _.throttle(function () {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;

      const url = vm.$apiService.documentUrl(vm.document.id);
      axios.delete(url)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit('show-toast', 'Document deleted', true);
          vm.$notificationManager.$emit('document-deleted', vm.document);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
