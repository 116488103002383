<template>
    <h1 id="video-name">
        {{ decodedName }}
    </h1>
</template>

<script>
export default {
  name: 'decoded-name',
  props: [
    'name',
  ],
  data() {
    return {
      decodedName: '',
    };
  },
  created() {
    const vm = this;
    if (vm.name) {
      vm.decodedName = decodeURIComponent(vm.name);
    }
  },
};
</script>
