<template>
  <div>
    <div class="flex justify-space-between">
      <div>
        <p>
          <i :class="iconClass()"></i> {{ invite.invitee.first_name }} {{ invite.invitee.last_name }}
          <span v-if="readableType" class="text-gray-500">
                ({{ readableType }})
              </span>
          <VDropdown theme="info-tooltip" v-if="details !== ''">
            <!-- This will be the popover target (for the events and position) -->
            <i class="fa fa-info-circle text-gray-300"></i>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>
                <p>
                  {{ details }}
                </p>
              </div>
            </template>
          </VDropdown>
        </p>
      </div>
      <p v-if="!invite.id">
        <a v-on:click="emitRemove" class="text-danger">(remove)</a>
      </p>
      <p v-if="allowEdit">
        <i class="fa fa-pencil" @click.prevent="showEdit = !showEdit"></i>
      </p>
    </div>
    <div v-if="showEdit" class="flex gap-x-sm align-items-center justify-end m-b-sm">
      <div>
        <a
            class="cursor-pointer rounded-sm p-y-xxs p-x-sm border"
            :class="accepted ? 'border-green-600 bg-green-500 text-green-50' : 'border-green-600 bg-white text-green-600'"
            @click.prevent="updateInvite('accepted')"
        >
          Yes
        </a>
      </div>
      <div>
        <a
            class="cursor-pointer rounded-sm p-y-xs p-x-sm border"
            :class="declined ? 'border-red-600 bg-red-500 text-red-50' : 'border border-red-500 bg-white text-red-500'"
            @click.prevent="updateInvite('declined')"
        >
          No
        </a>
      </div>
      <div>
        <a
            class="cursor-pointer rounded-sm p-y-xs p-x-sm border"
            :class="pending ? 'border-gray-600 bg-gray-500 text-gray-50' : 'border border-gray-500 bg-white text-gray-600'"
            @click.prevent="updateInvite('pending')"
        >
          Unknown
        </a>
      </div>
      <div>
        <spinner v-if="loading"></spinner>
      </div>
    </div>
    <p v-if="error" class="text-10 text-danger">{{ errorMessage }}</p>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import Spinner from './spinner.vue';

export default {
  name: 'invite',
  components: {
    Spinner,
  },
  mixins: [errorableMix],
  props: {
    invite: {
      type: Object,
      required: true,
    },
    showType: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showEdit: false,
      loading: false,
    };
  },
  computed: {
    readableType() {
      if (!this.showType) {
        return '';
      }

      return _.replace(this.invite.invitee.type, 'Profile', '')
          .toLowerCase();
    },
    pending() {
      return this.invite.status === 'pending';
    },
    declined() {
      return this.invite.status === 'declined';
    },
    accepted() {
      return this.invite.status === 'accepted';
    },
    inviteeAndRespondantAreSame() {
      if (!this.invite.respondant) {
        return false;
      }

      return this.invite.invitee.id === this.invite.respondant.id && this.invite.invitee.type === this.invite.respondant.type;
    },
    details() {
      if (this.inviteeAndRespondantAreSame) {
        return `Invite ${this.invite.status } by ${this.invite.respondant.display_name} on ${this.updatedTime}.`;
      } else if (this.invite.respondant && this.invite.respondant.display_name !== '') {
        return `Invite ${this.invite.status } by ${this.invite.respondant.display_name} on behalf of ${this.invite.invitee.first_name } ${this.invite.invitee.last_name } on ${this.updatedTime}.`;
      }

      return '';
    },
    updatedTime() {
      const tz = moment.tz.guess();
      return moment(this.invite.updated_at).tz(tz).format('M/D/YYYY hh:mm a');
    }
  },
  methods: {

    iconClass() {
      const vm = this;
      if (this.pending) {
        return 'fa fa-clock-o';
      }
      if (this.declined) {
        return 'fa fa-close';
      }
      if (this.accepted) {
        return 'fa fa-check';
      }

      return '';
    },
    emitRemove() {
      this.$notificationManager.$emit('remove-event-invite', this.invite);
    },
    updateInvite(status) {
      const vm = this;
      if (vm.loading || vm.invite.status === status) {
        return
      }

      vm.loading = true;
      vm.clearError();

      // The default change is zero, because in most cases (decline going to pending, pending going to decline, etc) we don't change the accepted increment
      let changeAcceptedCountBy = 0;
      if (status === 'accepted') {
        // If the new status is accepted, we increment the accepted in either scenario (doesnt' matter if coming form pending or declined)
        changeAcceptedCountBy = 1;
      } else if (vm.invite.status === 'accepted') {
        // If the old status of the invite was accepted, we need to decrement the accepted count
        changeAcceptedCountBy = -1;
      }

      const url = this.$apiService.eventInviteUrl(vm.invite.id);
      axios.put(url, {
        event_invite: {
          status,
        },
      }).then(() => {
        vm.loading = false;
        vm.showEdit = false;
        vm.$notificationManager.$emit('event-invite-changed', vm.invite.invitee.type, changeAcceptedCountBy);
        vm.$notificationManager.$emit('show-toast', 'Invite updated', true);
      }).catch((error) => {
        vm.errorMessage = vm.parseErrorResponse(error);
        vm.error = true;
        vm.loading = false;
        vm.$notificationManager.$emit('show-toast', 'Invite update failed', false);
      });
    }
  },
};
</script>
