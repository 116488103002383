<template>
  <div role="form">
    <div class="row m-t-m">
      <div class="col-md-5">
        <h1 class="form-section-title">{{ isEdit ? `Edit ${code}` : 'Create a Scholarship Code' }}</h1>
        <p class="text-gray-600 m-t-sm">Scholarship codes function just like coupon codes in other online systems. They allow you to hand out a code for someone to type in to get a discount on a purchase.</p>
        <div class="alert alert-danger m-b-sm">
          <p><strong>Note:</strong> If you are using <strong>installment plans</strong> for your full session registrations, coupons are <strong>applied to every invoice</strong>.</p>

          <p><strong>For example:</strong> If you have a setup where parents pay $100/month for three months, applying a $25 off coupon will bring the invoices down to $75/month for all three months.</p>
        </div>
      </div>
      <div class="col-md-7">
        <div v-show="isEdit" class="alert alert-warning">
          <p>Note that it is impossible to edit the code or percent off for an existing coupon due to accounting best practices.</p>
          <p>Transaction history gets murky if a code is reused month to month but the discount keep changing. Please create a new code if you need to edit the code or percent off fields.</p>
        </div>
        <div :class="formClasses()">
          <div class="form-group should-dim">
            <label for="coupon_code" class="m-b-none">Code</label>
            <p class="text-gray-600 m-b-xs m-t-xxs">
              This is the code users will type in to get the discount.
            </p>
            <input v-model="code" type="text" class="form-control" id="coupon_code"
                   placeholder="Hint: Keep is short and simple. E.g. FALL_SCHOLARSHIP or COACH"
                   :disabled="isEdit">

          </div>
          <div class="form-group m-t-md">
            <label for="coupon_description" class="m-b-xs">Description</label>
            <textarea v-model="description" class="form-control" id="coupon_description" rows="2" style="resize:none" name="description"></textarea>
          </div>
          <div class="form-group flex m-t-md align-items-start"">
              <input type="checkbox" v-model="active" id="coupon_active">
              <div class="m-l-sm">
                <label for="coupon_active" class="m-b-none">
                  Active?
                </label>
                <p class="text-gray-600">
                    Uncheck to disable this scholarship code.
                  </p>
              </div>
          </div>
          <div class="form-group should-dim m-t-md flex align-items-start" :class="{'m-b-none': shouldLimitUses }">
            <input id="limit_uses" type="checkbox" v-model="shouldLimitUses">
            <div class="m-l-sm">
              <label for="limit_uses" class="m-b-none">
                Limit how many times this can be used per guardian account?
              </label>
              <p class="text-gray-600" :class="{'m-b-xxs': shouldLimitUses }">
                Leave unchecked for unlimited uses.
              </p>
            </div>
          </div>
          <div v-if="shouldLimitUses" class="m-b-md m-l-lg" style="max-width: 100px; margin-left: 28px">
            <input type="number" id="coupon_uses" v-model.number="uses" class="form-control">
          </div>
          <div class="form-group should-dim m-t-md">
            <div class="form-group">
              <label for="discount-strategy">Discount Strategy & Amount</label>
              <div class="alert alert-info m-t-sm">
                <ul style="padding-inline-start: 24px;">
                  <li><strong>Percent off:</strong> Applies a percentage discount to the total amount.</li>
                  <li><strong>Amount off:</strong> Subtracts a fixed amount from the total.</li>
                  <li v-if="allowFlatRate"><strong>Flat rate:</strong> Sets a fixed price for the registration, regardless of original cost. I.e. $20 flat rate coupon will set the price to $20. This is per wrestler, so if a parent is registering two kids, they will pay $40. Flat rate does not apply to anything except registrations and <strong>does not work with recurring subscriptions.</strong></li>
                </ul>
              </div>
              <select v-model="discountStrategy" id="discount-strategy" class="form-control">
                <option value="percent_off">Percent off</option>
                <option value="amount_off">Amount off</option>
                <option v-if="allowFlatRate" value="flat_rate">Flat rate</option>
              </select>
            </div>
            <div v-if="isPercent">
              <div class="input-group">
                <the-mask mask="###"
                          v-model="percentOff"
                          class="form-control"
                          id="percent-off"
                          ref="phone"
                          type="text"
                          :disabled="isEdit">
                </the-mask>
                <div class="input-group-addon">%</div>
              </div>
            </div>
            <div v-if="isAmountOff">
              <div class="input-group">
                <div class="input-group-addon">$</div>
                <money v-model="amountOff" v-bind="money" class="form-control"></money>
              </div>
            </div>
            <div v-if="isFlatRate">
              <div class="input-group">
                <div class="input-group-addon">$</div>
                <money v-model="flatRate" v-bind="money" class="form-control"></money>
              </div>
            </div>
          </div>
          <div class="button-area text-right m-t-xl">
            <ladda-button @lbClicked="save" el-class="btn-primary" :loading="loading">
              Save Code
            </ladda-button>
            <div v-show="error">
              <small>
                <span class="text-danger">{{ errorMessage }}</span>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import { Money } from 'v-money';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'coupon-form',
  components: {
    LaddaButton,
    TheMask,
    Money,
  },
  props: {
    existingCoupon: {
      type: Object,
      default: null,
    },
    allowFlatRate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: '',

      percentOff: null,
      amountOff: 0,
      flatRate: 0,
      code: '',
      description: '',
      discountStrategy: 'percent_off', // can be 'percent', 'amount', or 'flat_rate'
      shouldLimitUses: false,
      active: true,
      uses: 1,

      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  mounted() {
    const vm = this;
    if (vm.existingCoupon) {
      vm.description = vm.existingCoupon.description;
      vm.code = vm.existingCoupon.code;
      vm.percentOff = vm.existingCoupon.percent_off * 100;
      vm.active = vm.existingCoupon.active;
      // v money expects a float, api returns cents
      if (vm.existingCoupon.amount_off > 0) {
        vm.amountOff = vm.existingCoupon.amount_off / 100.00;
      }
      if (vm.existingCoupon.flat_rate > 0 && vm.allowFlatRate) {
        vm.flatRate = vm.existingCoupon.flat_rate / 100.00;
      }
      vm.discountStrategy = vm.existingCoupon.discount_strategy;
      vm.uses = vm.existingCoupon.uses;
      if (vm.uses > 0) {
        vm.shouldLimitUses = true;
      }
    }
  },
  computed: {
    isEdit() {
      return this.existingCoupon;
    },
    isPercent() {
      return this.discountStrategy === 'percent_off';
    },
    isAmountOff() {
      return this.discountStrategy === 'amount_off';
    },
    isFlatRate() {
      return this.discountStrategy === 'flat_rate';
    },
    percentForAPI() {
      if (this.isPercent) {
        return parseInt(this.percentOff) / 100.00;
      }

      return 0;
    },
    amountForAPI() {
      if (this.isAmountOff) {
        // v money returns a float, so we need to multiply since the api expects cents
        return _.round(this.amountOff * 100);
      }

      return 0;
    },
    flatRateForAPI() {
      if (this.isFlatRate) {
        // v money returns a float, so we need to multiply since the api expects cents
        return _.round(this.flatRate * 100);
      }

      return 0;
    },
    usesForAPI() {
      if (this.shouldLimitUses) {
        return this.uses;
      }
      // 0 denotes unlimited for coupons
      return 0;
    },
  },
  methods: {
    formClasses() {
      if (this.isEdit) {
        return 'm-l-sm dim';
      }

      return 'm-l-sm';
    },
    validate() {
      const vm = this;
      let errorMessage = 'Please ensure ';
      let error = false;
      if (!vm.code) {
        errorMessage += 'code, ';
        error = true;
      }
      if (vm.isPercent && !vm.percentOff) {
        errorMessage += 'percent off, ';
        error = true;
      }
      if (vm.isAmountOff && vm.amountForAPI === 0) {
        errorMessage += 'amount off, ';
        error = true;
      }
      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        return false;
      }
      return true;
    },
    save: _.throttle(function () {
      const vm = this;
      if (vm.loading) {
        return;
      }
      vm.error = false;
      if (vm.validate()) {
        vm.loading = true;
        if (vm.isEdit) {
          // update it
          vm.update();
        } else {
          // create it
          const params = {
            coupon: {
              code: vm.code,
              description: vm.description,
              percent_off: vm.percentForAPI,
              amount_off: vm.amountForAPI,
              flat_rate: vm.flatRateForAPI,
              discount_strategy: vm.discountStrategy,
              uses: vm.usesForAPI,
              active: vm.active,
            },
          };
          const url = vm.$apiService.couponsUrl();
          axios.post(url, params)
            .then((response) => {
              vm.loading = false;
              vm.$notificationManager.$emit('show-toast', 'Scholarship code saved', true);
              window.location.assign('/scholarship_codes');
            })
            .catch((error) => {
              vm.error = true;
              vm.errorMessage = `Error creating scholarship code${error.toString()}`;
              vm.loading = false;
            });
        }
      }
    }, 500),
    update: _.throttle(function () {
      const vm = this;
      const params = {
        coupon: {
          description: vm.description,
          active: vm.active,
        },
      };
      const url = vm.$apiService.couponUrl(vm.existingCoupon.id);
      axios.put(url, params)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit('show-toast', 'Scholarship code saved', true);
          window.location.assign('/scholarship_codes');
        })
        .catch((error) => {
          vm.error = true;
          vm.errorMessage = `Error updating scholarship code${error.toString()}`;
          vm.loading = false;
        });
    }, 500),
  },
};
</script>
