<template>
    <div class="scouting-question" :id="getQuestionId()">
        <div class="flex justify-space-between align-items-center gap-x-sm">
            <div>
                <h2 class="m-y-none">{{ promptWithoutColons }}</h2>
            </div>
            <div v-if="!currentAnswer && !answering">
                <a class="btn btn-default" @click.prevent="showAnswerForm"><i class="fa fa-plus"></i></a>
            </div>
        </div>
        <div class="flex flex-col gap-y-sm m-t-sm">
            <template v-for="answer in question.userAnswers">
                <div v-if="!answerBelongsToUser(answer)" class="social-comment border border-gray-50 border-solid rounded-xs p-xs">
                    <div class="media-name">
                        {{ answer.profile.display_name }}
                    </div>
                    <div class="media-body">
                        {{ answer.answer }}
                    </div>
                </div>
            </template>
            <template v-if="currentAnswer && !answering">
                <div class="social-comment border border-gray-50 border-solid rounded-xs p-xs">
                    <div class="flex justify-space-between align-items-center gap-x-sm">
                        <div class="media-name">
                            {{ currentAnswer.profile.display_name }}
                        </div>
                        <a @click.prevent="showAnswerForm">
                            <i class="fa fa-pencil"></i>
                        </a>
                    </div>
                    <div class="media-body" v-show="!answering">
                        {{ currentAnswer.answer }}
                    </div>
                </div>
            </template>
        </div>
        <template v-if="answering">
            <div class="answering-container p-b-xxl">
                <template v-if="question.type === 'SingleSelectQuestion'">
                    <div class="form-group">
                        <div class="radio" v-for="answer in question.answers">
                            <label>
                                <input type="radio" :name="getInputNameForRadioAnswer(answer)" v-on:click="selectRadioAnswer(answer)" value="answer" :checked="currentAnswerText === answer">
                                {{ answer }}
                            </label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <textarea v-model="currentAnswerText" class="form-control scouting-answer" rows="2" :disabled="loading"></textarea>
                </template>

                <div class="button-area">
                    <button v-show="!loading" type="button" v-on:click="cancel" class="btn btn-white">Cancel</button>
                    <ladda-button @lbClicked="saveAnswer" el-class="btn-primary" :loading="loading">
                        Save
                    </ladda-button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'scouting-question',
  components: { LaddaButton },
  props: [
    'question',
    'currentProfileId',
    'currentProfileType',
    'opposingWrestlerId',
  ],
  data() {
    return {
      loading: false,
      answering: false,

      error: false,
      errorMessage: '',

      currentAnswer: null,
      currentAnswerText: '',
    };
  },
  computed: {
    promptWithoutColons() {
      return this.question.prompt.replace(/[:]/g, '');
    },
  },
  created() {
    const vm = this;
    if (!('userAnswers' in vm.question)) {
      vm.question.userAnswers = [];
    }

    for (let i = 0; i < vm.question.userAnswers.length; i++) {
      const answer = vm.question.userAnswers[i];
      if (vm.answerBelongsToUser(answer)) {
        vm.currentAnswer = answer;
        vm.currentAnswerText = answer.answer;
      }
    }
  },
  methods: {
    answerBelongsToUser(answer) {
      return (String(answer.profile.id) === this.currentProfileId) && (answer.profile.type === this.currentProfileType);
    },
    getInputNameForRadioAnswer(answer) {
      return `answerRadio-${answer}`;
    },
    selectRadioAnswer(answer) {
      this.currentAnswerText = answer;
    },
    getQuestionId() {
      return `question-${this.question.id}`;
    },
    showAnswerForm() {
      this.answering = true;
    },
    cancel() {
      this.answering = false;
    },
    successHandler(response) {
      const vm = this;

      if (vm.currentAnswer === null) {
        vm.appendCreatedAnswer(response.data);
      } else {
        // Previous answer existed - replace new answer in userAnswers after retrieving it from server
        const newAnswerUrl = vm.$apiService.scoutingAnswerUrl(vm.currentAnswer.id);
        axios.get(newAnswerUrl)
          .then((response) => {
            vm.updateExistingAnswer(response.data);
          })
          .catch((error) => {
            vm.errorHandler(error);
          });
      }
    },
    showSuccessfulUI() {
      const vm = this;
      vm.loading = false;
      vm.answering = false;
      vm.$notificationManager.$emit('scouting-answer-created', vm.question);
      vm.$notificationManager.$emit('show-toast', 'Answer saved', true);
    },
    updateExistingAnswer(answer) {
      const vm = this;
      vm.currentAnswer = answer;
      vm.currentAnswerText = vm.currentAnswer.answer;
      const newAnswers = vm.question.userAnswers;
      for (let i = 0; i < newAnswers.length; i++) {
        const answer = newAnswers[i];
        if (vm.answerBelongsToUser(answer)) {
          newAnswers.splice(i, 1, vm.currentAnswer);
        }
      }
      vm.question.userAnswers = newAnswers;
      vm.showSuccessfulUI();
    },
    appendCreatedAnswer(answer) {
      // No answer previously - append
      const vm = this;
      vm.currentAnswer = answer;
      vm.currentAnswerText = vm.currentAnswer.answer;
      vm.question.userAnswers.push(vm.currentAnswer);
      vm.showSuccessfulUI();
    },
    errorHandler(error) {
      const vm = this;
      vm.loading = false;
      vm.errorMessage = 'Error, please try again later or contact support.';
      vm.error = true;
    },
    saveAnswer: _.throttle(function () {
      const vm = this;

      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }

      vm.loading = true;

      const params = {
        scouting_answer: {
          scouting_question_id: vm.question.id,
          answer: vm.currentAnswerText,
        },
      };

      if (vm.currentAnswer !== null) {
        // Update
        const url = vm.$apiService.opponentScoutingAnswerUrl(vm.opposingWrestlerId, vm.currentAnswer.id);
        axios.put(url, params)
          .then((response) => {
            vm.successHandler(response);
          })
          .catch((error) => {
            vm.errorHandler(error);
          });
      } else {
        // Create
        const url = vm.$apiService.opponentScoutingAnswersUrl(vm.opposingWrestlerId);
        axios.post(url, params)
          .then((response) => {
            vm.successHandler(response);
          })
          .catch((error) => {
            vm.errorHandler(error);
          });
      }
    }, 500),
  },
};
</script>
