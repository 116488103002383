export const cardLogoMix = {
  computed: {
    visaLogo() {
      return "<img src=\"https://mktg-cdn.wrestlingiq.com/cc_logos/visa_dark.png\" class=\"h-8 w-auto\" alt=\"VISA\"/>"
    },
    discoverLogo() {
      return "<img src=\"https://mktg-cdn.wrestlingiq.com/cc_logos/discover_dark.png\" class=\"h-8 w-auto\" alt=\"DISCOVER\"/>"
    },
    amexLogo() {
      return "<img src=\"https://mktg-cdn.wrestlingiq.com/cc_logos/amex_dark.png\" class=\"h-8 w-auto\" alt=\"AMEX\"/>"
    },
    mastercardLogo() {
      return "<img src=\"https://mktg-cdn.wrestlingiq.com/cc_logos/mastercard_dark.png\" class=\"h-8 w-auto\" alt=\"MASTERCARD\"/>"
    },
  }
}
