import FineUploaderS3 from 'fine-uploader-wrappers/s3';
import RSVP from 'rsvp';

export const trixFileAttachmentMix = {
  components: {
    FineUploaderS3,
    RSVP,
  },
  props: {
    accessKey: {
      type: String,
      required: false,
    },
    authToken: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
  },
  data() {
    let vm = this;
    const getKey = function (fileId) {
      const filename = vm.uploader.methods.getName(fileId);
      const keyRetrieval = new RSVP.Promise((resolve, reject) => {
        const params = {
          attachment: {
            name: filename,
            prefix: 'documents/',
          },
        };
        const url = vm.$apiService.attachmentsUrl();
        axios.post(url, params)
          .then((response) => {
            vm.pendingKey = response.data.key;
            resolve(response.data.key);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return keyRetrieval;
    };
    const uploader = new FineUploaderS3({
      options: {
        request: {
          endpoint: vm.endpoint,
          accessKey: vm.accessKey,
        },
        maxConnections: 1,
        signature: {
          endpoint: '/api/v1/attachments/s3_signature',
          customHeaders: {
            Authorization: `Bearer ${vm.authToken}`,
          },
        },
        uploadSuccess: {
          endpoint: '/api/v1/attachments/s3_confirm',
          customHeaders: {
            Authorization: `Bearer ${vm.authToken}`,
          },
        },
        objectProperties: {
          // acl: 'public-read',
          key: getKey,
        },
        chunking: {
          enabled: true,
        },
        resume: {
          // Resume is now disabled because the UI is not handling it correctly
          // to see the bug start an upload with pause enabled, then refresh the page
          // now try to upload again and you will see that the video does not start uploading
          enabled: false,
        },
      },
    });
    return {
      pendingAttachment: null,
      pendingKey: null,
      queuedAttachments: [],
      uploader
    }
  },
  mounted() {
    const vm = this;
    vm.uploader.on('complete', (id, name, response) => {
      // handle completed upload
      vm.$notificationManager.$emit('attachment-uploaded', id);
      const attachment = vm.pendingAttachment;
      attachment.setUploadProgress(100);
      const url = `${vm.cloudfrontBaseUrl}/${vm.pendingKey}`;
      attachment.setAttributes({
        url,
        href: url,
      });

      vm.$notificationManager.$emit('show-toast', 'Attachment uploaded', true);
      vm.pendingAttachment = null;
      vm.uploadAttachments();
    });

    vm.uploader.on('statusChange', (id, oldStatus, newStatus) => {
      console.log(`status changed on uploader from ${oldStatus} to ${newStatus}`);
    });

    document.addEventListener('trix-file-accept', vm.trixFileAccept);
    document.addEventListener('trix-attachment-add', vm.trixAttachmentAdd);
  },
  destroyed() {
    const vm = this;
    document.removeEventListener('trix-file-accept', vm.trixFileAccept)
    document.removeEventListener('trix-attachment-add', vm.trixAttachmentAdd)
  },
  methods: {
    trixFileAccept(event) {
      if (event.file.type.includes("video")) {
        event.preventDefault();
        alert('Video files are not allowed as attachments. Please contact support@wrestlingiq.com if you need assistance.');
      }

      // 15MB file limit on attachments
      if (event.file.size > 15728640) {
        event.preventDefault();
        alert('Attachments have a 15MB file size limit. Please contact support@wrestlingiq.com if you need assistance.');
      }
    },
    trixAttachmentAdd(event) {
      let attachment = event.attachment;
      if (attachment.file) {
        // we enforce our own queue, instead of relying on fineuploaders funky status changes
        this.queuedAttachments.push(attachment);
        this.uploadAttachments();
      }
    },
    uploadAttachments() {
      const vm = this;
      // only kick off another upload if one isn't currently uploading
      if (vm.pendingAttachment) {
        return;
      }

      const nextAttachment = vm.queuedAttachments.pop();
      if (nextAttachment) {
        vm.pendingAttachment = nextAttachment;
        vm.uploader.methods.addFiles([nextAttachment.file]);
      }
    },
  }
}
