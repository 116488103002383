<template>
    <div>
        <div class="row">
            <div class="col-xs-12">
                <h1 class="m-t-none font-heavy">{{ session.name }}</h1>
                <div v-if="sessionAlert">
                    <div v-html="sessionAlert"></div>
                </div>
                <p class="m-t-lg">
                    <span :class="viewingInfo ? 'font-heavy active-chip' : 'font-normal'">Info</span> >
                    <span :class="viewingPay ? 'font-heavy active-chip' : 'font-normal'">Pay</span>
                </p>
            </div>
        </div>
        <div class="panel panel-default m-t-none" v-show="viewingInfo">
            <div class="panel-body">
                <div class="row">
                    <div class="col-xs-12 col-lg-12">
                        <reg-email-question :question="{ id: 1, required: true, prompt: 'Email'}"
                                            @answer-changed="handleEmailChange"></reg-email-question>
                    </div>
                    <div class="col-xs-12 col-lg-6">
                        <reg-short-text-question autocomplete="given-name"
                                                 :question="{ id: 2, required: true, prompt: fNamePrompt}"
                                                 @answer-changed="handleFirstNameChange"></reg-short-text-question>
                    </div>
                    <div class="col-xs-12 col-lg-6">
                        <reg-short-text-question autocomplete="family-name"
                                                 :question="{ id: 3, required: true, prompt: lNamePrompt}"
                                                 @answer-changed="handleLastNameChange"></reg-short-text-question>
                    </div>
                    <div class="col-xs-12 col-lg-12" v-if="collectWeightClass">
                        <reg-number-question :question="{ id: 4, required: true, prompt: 'Weight class'}"
                                             @answer-changed="handleWeightClassChange"></reg-number-question>
                    </div>
                    <div class="col-xs-12 col-lg-6">
                        <recaptcha
                                ref="recaptcha"
                                :captcha-action="captchaAction"
                                :captcha-site-key="captchaSiteKey"
                                :captcha-v3-site-key="captchaV3SiteKey"
                        ></recaptcha>
                    </div>
                </div>
                <div class="row">
                    <div id="btn-step-1" class="col-xs-12 m-t-m">
                        <ladda-button @lbClicked="next" el-class="btn-primary btn-block" :loading="loading">
                            {{ buttonCta }}
                        </ladda-button>
                        <div v-show="error">
                            <small>
                                <span class="text-danger">{{ errorMessage }}</span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-t-none" v-show="viewingPay">
            <div v-if="multiplePaymentOptions && !selectedPaymentOption" class="col-xs-12">
                <div>
                    <h2>
                        Choose a payment option
                    </h2>
                    <p>
                        This session has multiple payment options available. Please pick the one that makes sense for
                        you.
                    </p>
                </div>
                <div class="grid grid-cols-2 sm-grid-cols-1 gap-12">
                    <div class="flex flex-col border border-solid border-gray-100 rounded-sm p-m"
                         v-if="option.status === 'active'" v-for="option in session.payment_options">
                        <h3 class="m-t-none text-gray-900" style="font-weight: 500;">
                            {{ option.name }}
                        </h3>
                        <div v-html="optionDescription(option)" class="color-gray-500 m-t-sm">
                        </div>
                        <div class="m-t-auto">
                            <h2 class="color-gray-900 m-t-md" style="font-size: 30px; font-weight: 900;">
                                {{ formatAsCurrency(option.price) }}
                            </h2>
                            <button class="btn btn-primary btn-xs-block m-t-sm" @click.prevent="selectOption(option)">
                                Choose..
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="selectedPaymentOption" class="col-xs-12">
                <spinner v-if="loadingPreview"></spinner>
                <div v-show="!loadingPreview">
                    <div class="row">
                        <div class="col-xs-12 col-md-5 m-b-md">
                            <div class="panel panel-default">
                                <div class="panel-body">
                                    <template v-if="multiplePaymentOptions && selectedPaymentOption">
                                        <div v-if="" class="m-b-md">
                                            {{ selectedPaymentOption.name }}
                                        </div>
                                        <hr/>
                                    </template>
                                    <template v-for="(invoice, index) in invoices">
                                        <template v-if="invoices.length > 1">
                                            <div class="row m-b-xs">
                                                <div class="col-xs-12">
                                                    <p class="m-b-none">
                                                        <span class="text-semi">Invoice #{{ index + 1 }}&nbsp;</span>
                                                        <small>{{ due(invoice) }}</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-for="(item, index) in invoice.invoice_items">
                                            <div :class="index === invoice.invoice_items.length - 1 ? 'row m-b-lg' : 'row'">
                                                <div class="col-xs-6">
                                                    <p class="m-b-none">
                                                        {{ item.description }}
                                                    </p>
                                                </div>
                                                <div class="col-xs-6 text-right">
                                                    <p class="m-b-none">
                                                        {{ formatAsCurrency(item.amount) }}
                                                    </p>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                    <hr/>
                                    <div class="row">
                                        <div class="col-xs-6 ">
                                            <span :class="installmentsPicked ? '': 'text-heavy'">
                                                Total
                                            </span>
                                        </div>
                                        <div class="col-xs-6 text-right">
                                                <span :class="installmentsPicked ? '': 'text-heavy'">
                                                    {{ total(0) }}
                                                </span>
                                        </div>
                                    </div>
                                    <div v-if="installmentPaymentDueNow()" class="row text-heavy">
                                        <div class="col-xs-6">
                                            Due now
                                        </div>
                                        <div class="col-xs-6 text-right ">
                                            {{ installmentTotalDueNow(0) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="panel panel-default" role="form"
                                     v-if="showCouponForm && !validCouponApplied">
                                    <div class="panel-body">
                                        <div class="alert alert-info">
                                            <small><strong>Note:</strong> scholarship codes are case sensitive. If your
                                                code isn't working, check your capitalization or contact your
                                                coach.</small>
                                        </div>
                                        <div class="form-group">
                                            <label class="font-normal">Scholarship Code</label>
                                            <input v-model="couponCode" type="text" class="form-control"
                                                   placeholder="Have a scholarship code? Enter it here"
                                            >
                                        </div>
                                        <div class="button-area text-right">
                                            <div>
                                                <div class="btn btn-default" @click="removeCoupon">
                                                    Cancel
                                                </div>
                                                <ladda-button @lbClicked="previewCharge(previewUrl)"
                                                              el-class="btn-primary" :loading="loadingPreview">
                                                    Apply
                                                </ladda-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!showCouponForm && !validCouponApplied"
                                     @click.prevent="toggleShowCoupon"
                                     class="m-l-m text-sm bg-gray-50 text-gray-600 rounded-xxs flex gap-x-xs align-items-center"
                                     style="padding: 2px 4px; width: fit-content;">
                                  <i class="fa fa-plus text-gray-300 cursor-pointer"></i>
                                  <div class="cursor-pointer">
                                    Scholarship Code
                                  </div>
                                </div>
                                <p class="m-l-m">
                                    <small v-if="validCouponApplied">
                                        <span class="text-success">{{ couponCode }} applied!</span> <a
                                            class="text-danger" @click.prevent="removeCoupon">(remove)</a>
                                    </small>
                                    <template v-if="multiplePaymentOptions && selectedPaymentOption">
                                        <a @click.prevent="selectOption(null)"><small>Change payment
                                            option...</small></a>
                                    </template>
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-7">
                            <div class="panel-group" id="accordion">
                                <template v-if="free">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h5 class="panel-title">
                                                Free
                                            </h5>
                                        </div>
                                        <div class="panel-body">
                                            <p>
                                                Tap the button below to complete your free registration.
                                            </p>
                                            <ladda-button data-cy="free-register" @lbClicked="freeRegister"
                                                          el-class="btn-primary m-t-lg" :loading="registering">
                                                Register
                                            </ladda-button>
                                        </div>
                                    </div>
                                </template>
                                <div v-show="!free">
                                    <div class="panel panel-default" v-if="allowsCash()">
                                        <div class="panel-heading" v-on:click="cashTapped()">
                                            <h5 class="panel-title">
                                                <label>
                                                    <input type="radio" value="cash" id="cash_payment"
                                                           v-model="paymentMethod">&nbsp; Cash or check
                                                </label>
                                            </h5>
                                        </div>
                                        <div v-show="cashSelected">
                                            <div class="panel-body">
                                                <p>
                                                    Payment will be due at the next practice or event for this session.
                                                </p>
                                                <ladda-button @lbClicked="cashRegister" el-class="btn-primary m-t-lg"
                                                              :loading="registering">
                                                    Register
                                                </ladda-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading" v-on:click="creditTapped()">
                                            <h5 class="panel-title">
                                                <label>
                                                    <input style="margin-top: 0;" type="radio" value="card"
                                                           id="credit_payment" v-model="paymentMethod">&nbsp; Credit or
                                                    debit
                                                </label>
                                            </h5>
                                        </div>
                                        <div v-show="creditSelected">
                                          <div id="credit-body" class="panel-body">
                                            <ValidationObserver ref="observer" v-slot="{ handleSubmit } ">
                                              <!-- Display a payment form -->
                                              <form role="form" id="payment-form">
                                                <div id="payment-element" v-if="useStripe">
                                                  <!--Stripe.js injects the Payment Element-->
                                                </div>
                                                <div v-else-if="useJustifi">
                                                  <div class="flex mt-2 flex-col space-y-4">
                                                    <ValidationProvider name="name on card" rules="required" v-slot="{ errors }">
                                                      <div class="form-group">
                                                        <label for="name_on_card" class="font-normal">Name on card</label>
                                                        <input type="text"
                                                               name="name on card"
                                                               id="name_on_card"
                                                               v-model="nameOnCard"
                                                               :class="inputClasses(errors[0])">
                                                        <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                                                          {{ errors[0] }}
                                                        </p>
                                                      </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="zip" rules="required" v-slot="{ errors }">
                                                      <div class="form-group">
                                                        <label for="zip" class="font-normal">ZIP</label>
                                                        <input type="text"
                                                               name="zip"
                                                               id="zip"
                                                               v-model="zip"
                                                               :class="inputClasses(errors[0])">
                                                        <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                                                          {{ errors[0] }}
                                                        </p>
                                                      </div>
                                                    </ValidationProvider>
                                                    <spinner v-if="justifiLoading"></spinner>
                                                    <justifi-card-form @cardFormReady="justifiLoading = false" validation-mode="onBlur"></justifi-card-form>
                                                  </div>
                                                </div>
                                                <ladda-button @lbClicked="handleSubmit(getToken)" id="submit" el-class="btn-primary m-t-lg btn-block" :loading="registering || creatingToken">
                                                  Register & Pay
                                                </ladda-button>
                                                <div id="payment-message" v-if="error"> {{ errorMessage }}</div>
                                              </form>
                                            </ValidationObserver>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="error">
                                <small><span class="text-danger">{{ errorMessage }}</span></small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LaddaButton from '../../shared/ladda_button.vue';
import Recaptcha from '../../shared/recaptcha.vue';
import RegEmailQuestion from './reg_questions/reg_email_question.vue';
import RegNumberQuestion from './reg_questions/reg_number_question.vue';
import RegShortTextQuestion from './reg_questions/reg_short_text_question.vue';
import Spinner from './spinner.vue';
import { formattingMixin } from './mixins/formatting_mix';
import { errorableMix } from './mixins/errorable_mix';
import { paymentOptionsMix } from './mixins/payment_options_mix';
import { recaptchaMix } from '../../shared/recaptcha_mix';
import { billingPartnerMix } from '../../shared/billing_partner_mix';
import { ValidationProvider, ValidationObserver } from 'vee-validate';


export default {
  name: 'anonymous-reg-form',
  mixins: [formattingMixin, errorableMix, paymentOptionsMix, recaptchaMix, billingPartnerMix],
  components: {
    LaddaButton,
    Recaptcha,
    RegEmailQuestion,
    RegNumberQuestion,
    RegShortTextQuestion,
    Spinner,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    successUrl: {
      type: String,
      required: true,
    },
    collectWeightClass: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      loadingPreview: false,
      viewing: 'info', // can be Info or Pay
      emailInput: {
        answer: null,
        valid: null,
      },
      firstName: {
        answer: null,
        valid: null,
      },
      lastName: {
        answer: null,
        valid: null,
      },
      weightClass: {
        answer: null,
        valid: null,
      },
      captchaVersion: 'v3',
      customer: null,
      creatingCharge: false,
      registering: false,
      paymentMethod: 'card',
      previewUrl: '/api/team/v1/payment_plans/preview',
      // For JustiFi
      billablEmail: '',
    };
  },
  computed: {
    fNamePrompt() {
      return this.collectWeightClass ? 'Wrestler first name' : 'First name';
    },
    lNamePrompt() {
      return this.collectWeightClass ? 'Wrestler last name' : 'Last name';
    },
    viewingInfo() {
      return this.viewing === 'info';
    },
    viewingPay() {
      return this.viewing === 'pay';
    },
    buttonCta() {
      return this.viewingInfo ? 'Next' : 'Register & Pay';
    },
    multiplePaymentOptions() {
      const options = _.filter(this.session.payment_options, ['status', 'active']);
      return options.length > 1;
    },
    sessionAlert() {
      return _.get(this.session, 'alert', null);
    },
  },
  mounted() {
    if (this.session.payment_options.length === 1) {
      this.selectedPaymentOptionId = this.session.payment_options[0].id;
    }
  },
  watch: {
    paymentMethod() {
      this.previewCharge(this.previewUrl);
    }
  },
  methods: {
    handleEmailChange(question, answer, dirty, valid) {
      const vm = this;
      vm.emailInput.answer = answer;
      vm.emailInput.valid = valid;
    },
    handleFirstNameChange(question, answer, dirty, valid) {
      const vm = this;
      vm.firstName.answer = answer;
      vm.firstName.valid = valid;
    },
    handleLastNameChange(question, answer, dirty, valid) {
      const vm = this;
      vm.lastName.answer = answer;
      vm.lastName.valid = valid;
    },
    handleWeightClassChange(question, answer, dirty, valid) {
      const vm = this;
      vm.weightClass.answer = answer;
      vm.weightClass.valid = valid;
    },
    inputClasses(error) {
      const normalClasses = 'form-control';
      if (!error) {
        return normalClasses;
      }

      return `text-danger border-red-500 ${normalClasses}`;
    },
    validateInfo() {
      const vm = this;
      const errors = [];

      let error = false;
      if (!vm.firstName.valid) {
        errors.push('first name');
        error = true;
      }
      if (!vm.lastName.valid) {
        errors.push('last name');
        error = true;
      }
      if (!vm.emailInput.valid) {
        errors.push('email');
        error = true;
      }
      if (vm.collectWeightClass && !vm.weightClass.valid) {
        errors.push('weight class');
        error = true;
      }

      if (error) {
        vm.errorMessage = vm.readableMessageFrom(errors, 'Please enter');
        vm.error = true;
        return false;
      }
      vm.error = false;
      return true;
    },
    next() {
      const vm = this;
      if (vm.loading) {
        return;
      }

      if (this.viewingInfo) {
        // validate entered info, then go to payment;
        const valid = vm.validateInfo();
        if (valid) {
          vm.createCustomer();
        }
      }
    },
    async createCustomer() {
      const vm = this;
      vm.loading = true;

      let params = {
        'g-recaptcha-response-data': await vm.$refs.recaptcha.getCaptchaResponse(),
        customer: {
          email: vm.emailInput.answer,
          first_name: vm.firstName.answer,
          last_name: vm.lastName.answer,
        },
      };
      if (vm.collectWeightClass) {
        params.customer.properties = {
          weight_class: vm.weightClass.answer
        };
      }
      const url = '/api/team/v1/customers';
      axios.post(url, params)
        .then((response) => {
          vm.customer = response.data;
          vm.nameOnCard = `${vm.firstName.answer} ${vm.lastName.answer}`;
          vm.billableEmail = vm.emailInput.answer;
          vm.$forceNextTick(() => {
            vm.setupPaymentElement(vm.customer.setup_intent.client_secret);
          });
          // This if is needed so we dont' fire if we don't have a single selected option
          if (vm.selectedPaymentOption) {
            vm.previewCharge(vm.previewUrl);
          }
          vm.viewing = 'pay';
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          if (vm.isRecaptchaError(error)) {
            vm.$refs.recaptcha.handleCaptchaFailed();
            vm.errorMessage = 'Recaptcha failed. Please solve and try again.';
          } else {
            vm.errorMessage = `Error, please try again later ${error.toString()}`;
          }
          vm.error = true;
        });
    },
    cashRegister() {
      this.createCharge('cash', null);
    },
    freeRegister() {
      this.createCharge('cash', null);
    },
    // Unused currently, for upsells
    buildProductPayload() {
      return [];
    },
    buildRegistrationPayload() {
      return [
        {
          payment_option_id: _.get(this.selectedPaymentOption, 'id', null),
          registerable: {
            id: this.customer.id,
            type: 'Customer',
          },
        },
      ];
    },
    getToken() {
      this.getPaymentToken(this.nameOnCard, this.billableEmail, this.zip);
    },
    tokenized(token) {
      this.registering = true;
      this.createCharge("card", token);
    },
    createCharge: _.throttle(function (payment_method_type, payment_method_id) {
      const vm = this;
      if (vm.creatingCharge) {
        return;
      }

      vm.creatingCharge = true;

      const url = '/api/team/v1/payment_plans';
      const params = {
        payment_method_type,
        payment_method_id,
        customer_id: vm.customer.id,
        payment_plan: {
          registrations: vm.buildRegistrationPayload(),
          coupon: vm.couponCode,
          donation_amount: vm.donationAmount || 0,
        },
      };

      axios.post(url, params)
        .then((response) => {
          window.location.assign(vm.successUrl);
        })
        .catch((error) => {
          vm.creatingCharge = false;
          vm.registering = false;
          vm.errorMessage = vm.getErrorMessage(error);
          vm.error = true;
        });
    }, 500),
  },
};
</script>
