<template>
    <div class="row">
        <div class="col-xs-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h1>Edit message group</h1>
                </div>
                <div class="ibox-content">
                    <div v-if="!existingLoaded">
                        <spinner></spinner>
                    </div>
                    <div v-if="error">
                        <p class="text-danger">
                            {{ errorMessage }}
                        </p>
                    </div>
                    <template v-if="existingLoaded">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="m-t-sm form-group">
                                    <label for="group_name">Group Name</label>
                                    <small>e.g. rides, varsity travel team, tournament help, etc</small>
                                    <input v-model="groupName" type="text" id="group_name" class="form-control" maxlength="50"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="m-t-sm form-group">
                                    <label for="group_purpose">Group Purpose</label>
                                    <small>e.g. "Used by parents and coaches to organize rides for tournaments."</small>
                                    <input v-model="groupPurpose" type="text" id="group_purpose" class="form-control" maxlength="75"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="m-t-sm form-group">
                                    <div class="m-t-md">
                                        <label>
                                            Who should be able to send messages within the group?
                                        </label>
                                        <div>
                                            <input type="radio" value="all" id="all_related_events" v-model="allowPublish"> Everyone
                                        </div>
                                        <div>
                                            <input type="radio" value="admins" id="only_this_event" v-model="allowPublish"> Only coaches and admins
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-lg">
                            <div class="col-xs-12 text-right">
                                <ladda-button @lbClicked="saveGroup" el-class="btn-primary" :loading="loading">
                                    Save
                                </ladda-button>
                                <div>
                                    <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';
import Spinner from './spinner.vue';

export default {
  name: 'edit-message-group-form',
  components: { LaddaButton, Spinner },
  props: [
    'groupId',
  ],
  data() {
    return {
      existingLoaded: false,
      loading: false,
      error: false,
      errorMessage: '',
      groupName: '',
      groupPurpose: '',
      allowPublish: 'all',
    };
  },
  created() {
    this.getGroup();
  },
  methods: {
    getGroup() {
      const vm = this;

      if (vm.loading) {
        return;
      }

      vm.loading = true;

      const url = vm.$apiService.messageGroupUrl(vm.groupId);
      axios.get(url)
        .then((response) => {
          const { data } = response;
          vm.groupName = data.name;
          vm.groupPurpose = data.purpose;
          vm.allowPublish = data.default_allow_publish;
          vm.loading = false;
          vm.existingLoaded = true;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error loading message group, please contact support@wrestlingiq.com';
          vm.error = true;
        });
    },
    saveGroup: _.throttle(function () {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.error = false;
      if (vm.groupName.length === 0) {
        vm.errorMessage = 'Please ensure the group has a name';
        vm.error = true;
        return;
      }

      vm.loading = true;
      const params = {
        message_group: {
          name: vm.groupName,
          purpose: vm.groupPurpose,
          default_allow_publish: vm.allowPublish,
        },
      };

      const url = vm.$apiService.messageGroupUrl(vm.groupId);
      axios.put(url, params)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit('show-toast', `${vm.groupName} saved`, true);
          window.location.assign(`/message_groups/${vm.groupId}`);
        }).catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error saving message group, please contact support@wrestlingiq.com';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
