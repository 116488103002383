<template>
  <div>
    <a v-if="linkOnlyView" @click.prevent="toggleValue" class="flex align-items-center gap-x-xs" style="display:flex;">
      <div v-if="subscribed" class="cursor-pointer" @click.prevent="toggleValue" :style="{ opacity: opacityValue }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 color-gray-700">
          <path d="M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z" />
          <path fill-rule="evenodd" d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z" clip-rule="evenodd" />
        </svg>
      </div>
      <div v-else class="cursor-pointer" @click.prevent="toggleValue" :style="{ opacity: opacityValue }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-6 h-6 color-gray-700" fill="currentColor">
          <path d="M633.99 471.02L36 3.51C29.1-2.01 19.03-.9 13.51 6l-10 12.49C-2.02 25.39-.9 35.46 6 40.98l598 467.51c6.9 5.52 16.96 4.4 22.49-2.49l10-12.49c5.52-6.9 4.41-16.97-2.5-22.49zM163.53 368c16.71-22.03 34.48-55.8 41.4-110.58l-45.47-35.55c-3.27 90.73-36.47 120.68-54.84 140.42-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h279.66l-61.4-48H163.53zM320 96c61.86 0 112 50.14 112 112 0 .2-.06.38-.06.58.02 16.84 1.16 31.77 2.79 45.73l59.53 46.54c-8.31-22.13-14.34-51.49-14.34-92.85 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84c-26.02 5.41-49.45 16.94-69.13 32.72l38.17 29.84C275 103.18 296.65 96 320 96zm0 416c35.32 0 63.97-28.65 63.97-64H256.03c0 35.35 28.65 64 63.97 64z"/>
        </svg>
      </div>
      {{ subscribed ? 'Disable' : 'Enable' }} Notifications
    </a>
    <VDropdown theme="info-tooltip" v-else>
      <!-- This will be the popover target (for the events and position) -->
      <div v-if="subscribed" class="cursor-pointer" @click.prevent="toggleValue" :style="{ opacity: opacityValue }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 color-gray-700">
          <path d="M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z" />
          <path fill-rule="evenodd" d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z" clip-rule="evenodd" />
        </svg>
      </div>
      <div v-else class="cursor-pointer" @click.prevent="toggleValue" :style="{ opacity: opacityValue }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-6 h-6 color-gray-700" fill="currentColor">
          <path d="M633.99 471.02L36 3.51C29.1-2.01 19.03-.9 13.51 6l-10 12.49C-2.02 25.39-.9 35.46 6 40.98l598 467.51c6.9 5.52 16.96 4.4 22.49-2.49l10-12.49c5.52-6.9 4.41-16.97-2.5-22.49zM163.53 368c16.71-22.03 34.48-55.8 41.4-110.58l-45.47-35.55c-3.27 90.73-36.47 120.68-54.84 140.42-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h279.66l-61.4-48H163.53zM320 96c61.86 0 112 50.14 112 112 0 .2-.06.38-.06.58.02 16.84 1.16 31.77 2.79 45.73l59.53 46.54c-8.31-22.13-14.34-51.49-14.34-92.85 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84c-26.02 5.41-49.45 16.94-69.13 32.72l38.17 29.84C275 103.18 296.65 96 320 96zm0 416c35.32 0 63.97-28.65 63.97-64H256.03c0 35.35 28.65 64 63.97 64z"/>
        </svg>
      </div>
      <!-- This will be the content of the popover -->
      <template v-slot:popper>
        <div>
          <p class="header">
            {{ readableHeader }}
          </p>
          <p>
            This notification is <strong>currently {{ subscribed ? 'on' : 'off' }}</strong>
          </p>
        </div>
      </template>
    </VDropdown>
  </div>
</template>
<script>
export default {
  name: 'profile-event-sub-toggle',
  props: {
    eventName: {
      type: String,
      required: true,
    },
    filterKey: {
      type: String,
      required: false,
    },
    filterValueToToggle: {
      type: Number,
      required: true,
    },
    readableHeader: {
      type: String,
      required: true,
    },
    existingSub: {
      type: Object,
      default() { return {}; },
    },
    linkOnlyView: {
      type: Boolean,
      deafult: false
    },
    sessionName: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      profile_event_subscription: {
        filters: {
          paid_session_ids: []
        }
      },
      subId: null,
      saving: false,
    }
  },
  created() {
    // Set up the data given passed in shit
    this.profile_event_subscription.event_name = this.eventName;
    const hasExistingSub = "id" in this.existingSub;
    if (this.filterKey) {
      this.profile_event_subscription.filters[this.filterKey] = hasExistingSub ? this.existingSub.filters[this.filterKey] : []
    }
    if (hasExistingSub) {
      this.subId = this.existingSub.id
    }

    // Listen for notifications
    this.$notificationManager.$on('profile-event-subs-changed', this.trackFilterChanges);
    this.$notificationManager.$on('profile-event-subs-changing', this.changeSaveState);
  },
  destroyed() {
    this.$notificationManager.$off('profile-event-subs-changed', this.trackFilterChanges);
    this.$notificationManager.$off('profile-event-subs-changing', this.changeSaveState);
  },
  computed: {
    opacityValue() {
      return this.saving ? 0.25 : 1.0;
    },
    subscribed() {
      return this.profile_event_subscription.filters[this.filterKey].includes(this.filterValueToToggle);
    },
  },
  methods: {
    changeSaveState(saving) {
      this.saving = saving;
    },
    trackFilterChanges(profileEventSubscription, subId) {
      // If we don't have a filter, stop caring
      if (!this.filterKey) {
        return false;
      }

      // We have a match!
      if (this.eventName === profileEventSubscription.event_name && this.filterKey in profileEventSubscription.filters) {
        // Due to how the backend works (it overwrites i.e. 'put' rather than appending/replacing with 'patch')
        // we should simply be blowing away our changes here. Whatever the sibling vue component broadcasts is truth
        this.$set(this.profile_event_subscription.filters, this.filterKey, profileEventSubscription.filters[this.filterKey])
        this.subId = subId;
      }
    },
    toggleValue() {
      // Just don't do anything if we are in a save state (for this or any sibling components who notified us of it)
      if (this.saving) {
        return;
      }

      // Lock down all sibling components (optimistically)
      this.saving = true;
      this.$notificationManager.$emit('profile-event-subs-changing', this.saving);
      // Now actually manipulate the thing
      if (this.subscribed) {
        // Remove if already in there
        this.$set(this.profile_event_subscription.filters, this.filterKey, _.without(this.profile_event_subscription.filters[this.filterKey], this.filterValueToToggle));
      } else {
        // Add it if not
        this.profile_event_subscription.filters[this.filterKey].push(this.filterValueToToggle);
      }

      if (this.subId) {
        this.update();
      } else {
        this.create();
      }
    },
    successHandler(subscribed) {
      this.$notificationManager.$emit('profile-event-subs-changed', this.profile_event_subscription, this.subId);

      this.saving = false;
      this.$notificationManager.$emit('profile-event-subs-changing', this.saving);

      let msg = subscribed ? `Notifications enabled for ${this.sessionName}` : `Notifications disabled for ${this.sessionName}`;
      this.$notificationManager.$emit('show-toast', msg, true);
    },
    update() {
      const vm = this;
      const url = vm.$apiService.profileEventSubscriptionUrl(this.subId);
      axios.put(url, this.profile_event_subscription)
          .then((response) => {
            vm.successHandler(vm.subscribed);
          })
          .catch((error) => {
            // todo catch errors
            console.log(error);
            this.$notificationManager.$emit('show-toast', error.toString(), false);
          });
    },
    create() {
      const vm = this;
      const url = vm.$apiService.profileEventSubscriptionsUrl();
      axios.post(url, this.profile_event_subscription)
          .then((response) => {
            vm.subId = response.data.id;
            vm.successHandler(vm.subscribed);
          })
          .catch((error) => {
            // todo catch errors
            console.log(error);
          });
    },
  }
};
</script>
