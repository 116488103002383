<template>
    <div class="reg-single-select">
        <div class="row">
            <div class="col-xs-12">
                <div role="form">
                    <div :class="getClass()">
                        <label>{{ prompt() }}</label>
                        <div v-if="hasSubtext" v-html="question.subtext"></div>
                        <div>
                          <pintura-photo-uploader
                              :pond-id="this.$srHelper.idFor('photo-q-', this.srId)"
                              :access-key="accessKey"
                              :endpoint="endpoint"
                              :cloudfront-base-url="cloudfrontBaseUrl"
                              :existing-attachments="existingAttachments"
                              prefix="registration_answers/"
                              @photos-changed="photosChanged"
                          ></pintura-photo-uploader>
                          <p v-show="error" class="text-danger">
                              {{ errorMessage }}
                          </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PinturaPhotoUploader from '../pintura_photo_uploader.vue';
export default {
  name: 'reg-photo-question',
  props: [
    'question',
    'existingAnswer',
    'endpoint',
    'accessKey',
    'authToken',
    'cloudfrontBaseUrl',
  ],
  components: {
    PinturaPhotoUploader,
  },
  data() {
    return {
      answer: {
        attachment_id: null,
        s3_file_name: '',
        file_name: '',
        status: '',
      },

      originalAttachmentId: null,
      dirty: false,
      valid: true,
      error: false,
      errorMessage: 'Please upload a file.',
      required: false,
      srId: '',
    };
  },
  computed: {
    hasSubtext() {
      const vm = this;
      if (!vm.question) {
        return false;
      }

      return vm.question.subtext && vm.question.subtext.length !== 0;
    },
    existingAttachments() {
      const vm = this;
      const oldAttachmentId = _.get(vm.existingAnswer, 'answer.attachment_id', null);
      if (!oldAttachmentId) {
        return [];
      }

      return [
        {
          // This expects to be a photo object, like the rails app would pass down.
          // pass that in here
          id: vm.existingAnswer.answer.attachment_id,
          name: vm.existingAnswer.answer.file_name,
          key: vm.existingAnswer.answer.s3_file_name,
        },
      ];
    },
  },
  created() {
    const vm = this;
    const oldAttachmentId = _.get(vm.existingAnswer, 'answer.attachment_id', null);
    if (oldAttachmentId) {
      vm.originalAttachmentId = oldAttachmentId;
      vm.answer = vm.existingAnswer.answer;
    }

    if (vm.question) {
      vm.required = vm.question.required;
      vm.srId = vm.question.id;
    }

    vm.calculateValid();
    vm.$notificationManager.$on('show-reg-errors', vm.validate);
  },
  destroyed() {
    this.$notificationManager.$off('show-reg-errors', this.validate);
  },
  methods: {
    photosChanged(photos) {
      // This callback is a bit weird, in that we basically only care about any newly uploaded photos
      // so we reset it every time.
      // In a perfect world we would reset this type of question to handle the _destroy flags
      // that pintura photo upload throws back. For now, punting on that.
      let newAnswer = {
        attachment_id: null,
        s3_file_name: '',
        file_name: '',
        status: '',
      }

      photos.forEach((photo) => {
        if (!photo._destroy) {
          newAnswer.attachment_id = photo.id;
          newAnswer.s3_file_name = photo.s3_file_name;
          newAnswer.file_name = photo.file_name;
          newAnswer.status = photo.status;
        }
      });

      // Now $set the newAnswer
      this.$set(this, 'answer', newAnswer);

      this.setDirty();
      this.calculateValid();
      this.emit();
    },
    answerDidChange() {
      this.setDirty();
      this.calculateValid();
      this.emit();
    },
    emit() {
      const vm = this;
      vm.$emit('answer-changed', vm.question, vm.answer, vm.dirty, vm.valid);
    },
    getClass() {
      if (this.error) {
        return 'form-group has-error';
      }

      return 'form-group';
    },
    setDirty() {
      const vm = this;
      // if we've never had an attachment id, this is always dirty
      if (!vm.originalAttachmentId) {
        vm.dirty = true;
        return;
      }

      if (vm.originalAttachmentId === vm.answer.attachment_id) {
        vm.dirty = false;
      } else {
        vm.dirty = true;
      }
    },
    calculateValid() {
      this.valid = (this.answer.status === 'uploaded');
      if (this.valid) {
        // if we are now valid, undo the red text
        this.validate();
      }
    },
    validate() {
      this.error = !this.valid;
    },
    prompt() {
      const vm = this;
      if (!vm.question) {
        return '';
      }

      let { prompt } = vm.question;

      if (!vm.required) {
        prompt += ' (optional)';
      }

      return prompt;
    },
  },
};
</script>
