<template>
    <div class="row">
        <div class="col-xs-12">
            <a @click="toggleForm" v-if="!showForm" class="text-danger"><small>Deactivate parent...</small></a>
            <div class="delete-parent-form should-dim modify-form card-bordered-dashed" v-if="showForm">
                <div>
                    Are you sure you want to deactivate this parent?<br/><br/>
                    This will remove their access to WrestlingIQ and is irreversible.
                </div>
                <div class="">
                    <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
                    <br/>
                    <button v-on:click="toggleForm" class="btn btn-default">Cancel</button>
                    <ladda-button @lbClicked="deleteParentPressed" el-class="btn-danger" :loading="loading">
                        Yes, deactivate
                    </ladda-button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'deactivate-parent-form',
  components: { LaddaButton },
  props: [
    'parentId',
  ],
  data() {
    return {
      showForm: false,
      loading: false, // Loading is a network variable (hitting the api)

      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    hideDeleteForm() {
      const vm = this;
      vm.$notificationManager.$emit('hide-deactivate-parent-form', vm.wrestlerId);
    },
    deleteParentPressed: _.throttle(function () {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;

      const url = vm.$apiService.parentUrl(vm.parentId);
      axios.delete(url)
        .then((response) => {
          vm.loading = false;

          vm.$notificationManager.$emit('show-toast', 'Parent deactivated', true);
          vm.$notificationManager.$emit('parent-deleted', vm.wrestlerId);
          window.location.assign('/parents');
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
