<template>
  <div role="form">
    <template v-if="loadingExisting">
      <spinner></spinner>
    </template>
    <div v-show="!loadingExisting">
      <div class="row m-t-m">
        <div class="col-md-4">
          <h1 class="form-section-title">Fundraiser Info</h1>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label for="session_name">Name</label>
            <input id="session_name" v-model="name" type="text" class="form-control">
          </div>
          <div class="form-group">
            <label>Goal amount</label>
            <money v-model="goal" v-bind="money" class="form-control"></money>
          </div>
          <div class="form-group">
            <label for="fundraiser_open" class="m-r-sm">
              Show progress bar
              <VDropdown theme="info-tooltip">
                <!-- This will be the popover target (for the events and position) -->
                <i class="fa fa-question"></i>
                <!-- This will be the content of the popover -->
                <template v-slot:popper>
                  <div>
                    <p class="header">
                      Why would I want to turn off the progress bar?
                    </p>
                    <p>
                      In almost all cases, you should keep the progress bar, because it helps your supporters and contributors rally around a specific goal.
                    </p>
                    <p>
                      However, in some situations—such as using a fundraiser for collecting gear money—you might want to turn it off.
                    </p>
                  </div>
                </template>
              </VDropdown>
            </label>
            <input id="show_progress_bar" type="checkbox" v-model="showProgressBar">
          </div>
          <div class="form-group">
            <label for="fundraiser_open" class="m-r-sm">
              Is the fundraiser open?
            </label>
            <input id="fundraiser_open" type="checkbox" v-model="fundraiserOpen">
          </div>
          <div class="form-group">
            <label class="m-r-sm">
              About the fundraiser (i.e. what are you raising money for and why)
            </label>
            <input id="why-editor" type="hidden" name="content">
            <trix-editor input="why-editor" class="trix-content"></trix-editor>
          </div>
          <div class="form-group">
            <label class="m-r-sm">
              Automated thank you email (if present, will be sent automatically after someone contributes)
            </label>
            <input id="thank-you-editor" type="hidden" name="content">
            <trix-editor input="thank-you-editor" class="trix-content"></trix-editor>
          </div>
        </div>
      </div>
      <div class="row m-t-xl">
        <div class="col-md-4">
          <h1 class="form-section-title m-b-m">Contributor info</h1>
        </div>
        <div class="col-md-8">
          <div class="-m-t-xs">
            <p>The following is collected by default for any contribution:
              <span class="text-heavy">first name, last name, business name (optional), email, phone, and street address.</span>
            </p>
            <p>
              If you need to collect additional info from each contributor, add it below. Additional questions will show up as optional free text entry fields.
            </p>
            <div v-for="(question, index) in metadata.additional_questions" class="form-group">
              <label :for="'additional_question_' + index">Field name, as seen by contributors</label>
              <input :id="'additional_question_' + index" v-model="question.title" type="text" class="form-control" placeholder="e.g. Graduating class">
            </div>
            <div class="card-bordered-dashed m-t-xs">
              <div class="row">
                <div class="col-xs-12">
                  <a @click.prevent="addExtraInfo"><i class="fa fa-plus"></i> Additional info</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-t-xl">
        <div class="col-md-4">
          <h1 class="form-section-title m-b-m">Giving Tiers</h1>
        </div>
        <div class="col-md-8">
          <div class="-m-t-xs">
            <div class="flex">
              <div>
                <label>
                  <input :disabled="existingId" type="radio" :value="true" id="standard_tiers"
                         v-model="useStandardTiers"> Standard tiers
                </label>
              </div>
              <div class="m-l-m">
                <label>
                  <input :disabled="existingId" type="radio" :value="false" id="custom_tiers"
                         v-model="useStandardTiers"> Custom tiers
                </label>
              </div>
            </div>
            <template v-if="useStandardTiers">
              <p class="m-t-sm">
                Standard tiers let donors choose between $50, $100, $250, or $500, with an additional option type in any
                amount.
              </p>
            </template>
            <template v-else>
              <div>
                <p class="m-t-sm m-b-sm">
                  Custom tiers give you control of the price points, quantity of each tier available, and how the tier
                  is shown in the fundraising UI. <strong>With custom tiers, donors can choose multiple items to add to their donation.</strong>
                </p>
                <div v-for="(price) in prices" class="m-t-xs">
                  <fundraiser-price-form :price="price"></fundraiser-price-form>
                </div>
                <div class="card-bordered-dashed m-t-xs" v-show="showSaveButton">
                  <div class="row">
                    <div class="col-xs-12">
                      <a @click.prevent="addNewPrice"><i class="fa fa-plus"></i> Tier</a>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="button-area text-right m-t-xl">
        <div v-show="showSaveButton">
          <div v-show="error">
            <small>
              <span class="text-danger">{{ errorMessage }}</span>
            </small>
          </div>
          <ladda-button @lbClicked="pingServer" el-class="btn-primary" :loading="loading">
            Save
          </ladda-button>
        </div>
        <div v-show="!showSaveButton">
          <small>Finish editing your pricing tiers to save the fundraiser.</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FundraiserPriceForm from './fundraiser_price_form.vue'
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { errorableMix } from './mixins/errorable_mix';
import { trixFileAttachmentMix } from './mixins/trix_file_attachment_mix';
import { Money } from 'v-money';

export default {
  name: 'fundraiser-form',
  mixins: [errorableMix, trixFileAttachmentMix],
  components: {
    FundraiserPriceForm,
    Money,
    LaddaButton,
    Spinner,
  },
  props: {
    teamId: {
      type: Number,
      required: true,
    },
    existingId: {
      type: Number,
      required: false,
      default: null,
    },
    currency: {
      type: String,
      default: 'usd',
    },
  },
  data() {
    return {
      loading: false,

      pricesBeingEdited: 0,

      loadingExisting: false,
      name: '',
      goal: 0,
      fundraiserId: null,
      fundraiserOpen: true,
      showProgressBar: true,
      thankYou: '',
      thankYouTrixEditor: null,
      why: '',
      whyTrixEditor: null,
      useStandardTiers: true,
      metadata: {
        additional_questions: [

        ]
      },

      prices: [],

      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    showSaveButton() {
      return this.pricesBeingEdited === 0;
    },
    validAdditionalQuestions() {
      return {
        additional_questions: _.filter(this.metadata.additional_questions, function(q) { return q.title !== '' })
      };
    }
  },
  mounted() {
    const vm = this;

    if (vm.existingId) {
      vm.getExisting();
    }

    document.addEventListener('trix-initialize', vm.trixInit);

    // Used by the price forms below to indicate state
    vm.$on('editing-price', vm.incrementEditing);
    vm.$on('editing-price-cancelled', vm.handleCancelledOption);
    vm.$on('discard-price-draft', vm.removeDraft);
    vm.$on('price-updated', vm.handleUpdatedOption);
  },
  destroyed() {
    const vm = this;
    vm.$off('editing-price', vm.incrementEditing);
    vm.$off('editing-price-cancelled', vm.handleCancelledOption);
    vm.$off('discard-price-draft', vm.removeDraft);
    vm.$off('price-updated', vm.handleUpdatedOption);
    document.removeEventListener('trix-initialize', vm.trixInit);
  },
  methods: {
    trixInit(event) {
      let vm = this;
      if (event.target.inputElement.id === 'why-editor') {
        vm.whyTrixEditor = event.target.editor;
        vm.loadTrixContent(vm.whyTrixEditor, vm.why);
      } else if (event.target.inputElement.id === 'thank-you-editor') {
        vm.thankYouTrixEditor = event.target.editor;
        vm.loadTrixContent(vm.thankYouTrixEditor, vm.thankYou);
      }
    },
    addExtraInfo() {
      this.metadata.additional_questions.push({
        title: "",
        type: "free_text",
        order: this.metadata.additional_questions.length + 1,
      })
    },
    incrementEditing() {
      this.pricesBeingEdited += 1;
    },
    decrementEditing() {
      this.pricesBeingEdited -= 1;
    },
    handleCancelledOption(newOptionId, shouldRemove) {
      if (newOptionId && shouldRemove) {
        const newOptionIdx = _.findIndex(this.prices, { newOptionId });
        this.prices.splice(newOptionIdx, 1);
      }
      this.decrementEditing();
    },
    removeDraft(newOptionId) {
      const newOptionIdx = _.findIndex(this.prices, { newOptionId });
      this.prices.splice(newOptionIdx, 1);
    },
    handleUpdatedOption(option) {
      const vm = this;
      if (option.id) {
        // this option already exists so replace it in prices
        const existingOptionIdx = _.findIndex(vm.prices, { id: option.id });
        vm.prices.splice(existingOptionIdx, 1, option);
      } else {
        // option does not already exist, find it's draft option or push
        const newOptionIdx = _.findIndex(vm.prices, { newOptionId: option.newOptionId });
        vm.prices.splice(newOptionIdx, 1, option);
      }

      this.decrementEditing();
    },
    addNewPrice() {
      // ok so this needs to throw down a price
      // and open it for editing
      const newOptionId = this.prices.length + 1;
      const newPriceOption = {
        draft: true,
        id: null,
        newOptionId,
        name: '',
        currency: this.currency,
        amount: 0,
      };
      this.prices.push(newPriceOption);
    },
    loadTrixContent(trixEditor, html) {
      const vm = this;
      if (trixEditor) {
        trixEditor.loadHTML(html);
      }
    },
    getExisting() {
      const vm = this;
      vm.loadingExisting = true;
      const url = vm.$apiService.fundraiserUrl(vm.existingId);
      axios.get(url)
          .then((response) => {
            const { data } = response;
            vm.fundraiserId = data.id;
            vm.name = data.name;
            vm.why = data.why;
            vm.thankYou = data.welcome_text;
            vm.fundraiserOpen = data.open;
            vm.showProgressBar = data.show_progress_bar;
            vm.goal = data.goal / 100;
            vm.useStandardTiers = data.use_standard_tiers;
            vm.metadata = data.metadata;

            const prices = _.get(data, 'billing_plans', []);
            prices.forEach((po) => {
              const option = {
                draft: false,
                id: po.id,
                name: po.name,
                amount: po.amount,
                active: po.active,
                sold_out: po.sold_out,
                inventory_type: po.inventory_type,
                quantity: po.quantity,
              };
              vm.prices.push(option);
            });

            vm.loadingExisting = false;
            vm.loadTrixContent(vm.whyTrixEditor, vm.why);
            vm.loadTrixContent(vm.thankYouTrixEditor, vm.thankYou);
          })
          .catch((error) => {
            console.log(error);
            vm.errorHandler(error);
          });
    },
    successHandler(name) {
      const vm = this;
      vm.loading = false;
      vm.$notificationManager.$emit('show-toast', `${name} saved`, true);
      window.location.assign('/fundraisers');
    },
    errorHandler(error) {
      const vm = this;
      console.log(error);
      vm.loading = false;
      vm.errorMessage = 'Error, please try again later or contact support.';
      vm.error = true;
    },
    validate() {
      const vm = this;

      let errorMessage = 'Please ensure ';
      let error = false;

      if (!vm.name) {
        errorMessage += 'name, ';
        error = true;
      }
      if (vm.goal <= 0) {
        errorMessage += 'goal, ';
        error = true;
      }

      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        return false;
      }

      if (_.size(vm.prices) === 0 && !vm.useStandardTiers) {
        vm.errorMessage = 'Fundraisers with custom tiers must have at least one pricing tier.';
        vm.error = true;
        return false;
      }

      return true;
    },
    getPlainTrixText(trixEditor) {
      return trixEditor ? trixEditor.getDocument().toString() : '';
    },
    checkIfPricesFinished() {
      const vm = this;
      // some checks if any still have a draft status
      if (!_.some(vm.prices, ['draft', true])) {
        // All have finished
        vm.successHandler(vm.name);
      }
    },
    getPriceJson(price) {
      return {
        billing_plan: {
          name: price.name,
          amount: price.amount,
          currency: price.currency,
          interval: 'one_time',
          usage_type: 'licensed',
          active: price.active,
          sold_out: price.sold_out,
          inventory_type: price.inventory_type,
          quantity: price.quantity,
        },
      };
    },
    updateOrCreatePrice(price, fundraiser_id) {
      const vm = this;
      if (!price.draft) {
        // Skip non draft payment options
        return;
      }

      const params = vm.getPriceJson(price);
      if (price.id) {
        // PUT
        const url = vm.$apiService.billingPlanUrl(price.id);
        axios.put(url, params)
            .then((response) => {
              price.draft = false;
              vm.checkIfPricesFinished();
            })
            .catch((error) => {
              vm.errorHandler(error);
            });
      } else {
        // POST
        const url = vm.$apiService.fundraiserPricesUrl(fundraiser_id);
        axios.post(url, params)
            .then((response) => {
              price.draft = false;
              vm.checkIfPricesFinished();
            })
            .catch((error) => {
              vm.errorHandler(error);
            });
      }
    },
    savePrices(fundraiser_id) {
      const vm = this;
      // If we are using the system defined tiers, we do not have custom prices to save so bail early
      if (vm.useStandardTiers) {
        vm.successHandler(vm.name);
        return;
      }

      if (_.some(vm.prices, ['draft', true])) {
        // If we have draft payment options, save them
        vm.prices.forEach((price) => {
          vm.updateOrCreatePrice(price, fundraiser_id);
        });
      } else {
        // else just fire the success handler as we are all saved up
        vm.successHandler(vm.name);
      }
    },
    pingServer: _.throttle(function () {
      const vm = this;
      if (vm.loading === true) {
        return;
      }

      if (vm.validate()) {
        vm.loading = true;

        const thanks = $('#thank-you-editor').val();
        const plainThanks = vm.getPlainTrixText(vm.thankYouTrixEditor);

        const why = $('#why-editor').val();
        const plainWhy = vm.getPlainTrixText(vm.whyTrixEditor);

        const params = {
          fundraiser: {
            name: vm.name,
            goal: _.round(vm.goal * 100),
            welcome_text: thanks,
            plain_welcome_text: plainThanks,
            why: why,
            plain_why_text: plainWhy,
            open: vm.fundraiserOpen,
            show_progress_bar: vm.showProgressBar,
            fee_added_to_donor: true,
            use_standard_tiers: vm.useStandardTiers,
            metadata: vm.validAdditionalQuestions,
          },
        };

        vm.loading = true;

        if (vm.fundraiserId) {
          // PUT
          const url = vm.$apiService.fundraiserUrl(vm.fundraiserId);
          axios.put(url, params)
              .then((response) => {
                vm.savePrices(vm.fundraiserId);
              })
              .catch((error) => {
                vm.errorHandler(error);
              });
        } else {
          // POST
          const url = vm.$apiService.fundraisersUrl();
          axios.post(url, params)
              .then((response) => {
                vm.savePrices(response.data.id);
              })
              .catch((error) => {
                vm.errorHandler(error);
              });
        }
      }
    }, 500),
  },
};
</script>
