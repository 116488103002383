export const formattingMixin = {
  methods: {
    formattedAnswer(answer, question, isCSV) {
      if (!answer) {
        return '';
      }
      const useHtml = !isCSV;

      if (question.type === 'RegAddressQuestion') {
        return this.formatAddressAns(answer.answer, useHtml);
      } if (question.type === 'RegPhoneNumberQuestion') {
        return this.formatPhoneAns(answer.answer);
      } if (question.type === 'RegYesNoQuestion') {
        return this.formatYesNoAns(answer.answer);
      } if (question.type === 'RegMultiSelectQuestion') {
        return this.formatMultiSelectAns(answer.answer, useHtml);
      } if (question.type === 'RegPhotoQuestion') {
        const fileUrl = `${this.cloudfrontBaseUrl}${answer.answer.s3_file_name}`;
        if (isCSV) {
          return fileUrl;
        }
        return `<a class="text-underline" href='${fileUrl}'>${fileUrl}</a>`;
      }
      return answer.answer;
    },
    formatAddressAns(answer, withHtml = true) {
      if (!answer) {
        return '';
      }

      const lineFormat = withHtml ? '<br/>' : '\r\n';

      const line1 = answer.line1 + lineFormat;
      let { line2 } = answer;
      if (line2) {
        line2 += lineFormat;
      }
      const city = `${answer.city}, `;
      const state = `${answer.state}, `;
      const { zip } = answer;

      return line1 + line2 + city + state + zip;
    },
    formatPhoneAns(number) {
      if (!number) {
        return '';
      }

      const cleaned = (`${number}`).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return number;
    },
    formatYesNoAns(answer) {
      if (!answer) {
        return '';
      }

      if (answer.addtl_info && answer.addtl_info.length > 0) {
        return `${answer.answer}, ${answer.addtl_info}`;
      }
      return answer.answer;
    },
    formatMultiSelectAns(answer, withHtml = true) {
      if (!answer) {
        return '';
      }
      const lineFormat = withHtml ? '<br/>' : '\r\n';
      let formattedAnswer = '';

      // answer is an array in multi select land
      for (let i = 0; i < answer.length; i++) {
        formattedAnswer = formattedAnswer + answer[i] + lineFormat;
      }
      return formattedAnswer;
    },
    // todo DRY this up to currencyMix instead
    formatAsCurrency($amountInCents) {
      if (!$amountInCents) {
        $amountInCents = 0;
      }

      return ($amountInCents / 100.00).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    formatWithMoment(dateTime, momentFormat) {
      return moment(dateTime).format(momentFormat);
    }
  },
};
