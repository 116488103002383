<template>
  <div class="add-mentionable-link">
    <button type="button" @click.prevent="showCreate" class="btn btn-default btn-block w-full" v-show="!creating">
      <i class="fa fa-plus"></i> {{ mentionableType }}
    </button>
    <div class="mentionable-create form-group" v-show="creating">
      <hr/>
      <label :for="mentionableAreaId">
        {{ cta }}
        <VDropdown theme="info-tooltip">
          <!-- This will be the popover target (for the events and position) -->
          <i class="fa fa-question"></i>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div>
              <p class="header" v-html="helpTitle">
              </p>
              <p v-html="helpText">
              </p>
            </div>
          </template>
        </VDropdown>
      </label>

      <div v-show="error">
        <small>
          <span class="text-danger">{{ errorMessage }}</span>
        </small>
      </div>
      <textarea v-model="mentionableText" :id="mentionableAreaId" rows="4" :disabled="loading">
      </textarea>
      <div class="button-area">
        <button v-show="!loading" type="button" v-on:click="cancel" class="btn btn-white">Cancel</button>
        <ladda-button @lbClicked="postMentionable" el-class="btn-primary" :loading="loading">
            Post {{ mentionableType }}
        </ladda-button>
      </div>
    </div>
  </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';
import Tribute from "tributejs";

export default {
  name: 'mentionable-create',
  components: { LaddaButton },
  props: [
    'baseUrl',
    'cta',
    'helpText',
    'helpTitle',
    'suggestUrl',
    'mentionableType',
    'mentionableKey',
  ],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',
      creating: false,
      mentionableText: '',
    };
  },
  mounted() {
    const vm = this;
    if (vm.suggestUrl.length === 0) {
      return;
    }

    axios.get(vm.suggestUrl)
      .then((response) => {
        const data = _.map(response.data.coaches, (coach) => { return { key: coach.full_name, value: coach.full_name } });
        const tribute = new Tribute({
          values: data,
          allowSpaces: true,
          noMatchTemplate: function () {
            return '<span style:"visibility: hidden;"></span>';
          }
        });
        tribute.attach(document.getElementById(`${vm.mentionableAreaId}`))
      })
      .catch((error) => {
        console.log('failed to load coaches... retry?');
      });
  },
  computed: {
    mentionableAreaId() {
      return `mentionableArea-${this.mentionableType}`;
    },
  },
  methods: {
    showCreate() {
      this.creating = true;
      // TODO set focus automatically
      // document.getElementById("feedbackArea").focus();
    },
    cancel() {
      this.creating = false;
    },
    postMentionable: _.throttle(function () {
      const vm = this;

      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }

      const content = vm.mentionableText;
      if (content.length === 0) {
        vm.errorMessage = 'Please enter something into the box, blank content is not allowed.';
        vm.error = true;
        return;
      }
      vm.error = false;

      vm.loading = true;

      const params = {};
      params[vm.mentionableKey] = {
        content: vm.mentionableText,
      };
      axios.post(vm.baseUrl, params)
        .then((response) => {
          vm.loading = false;
          vm.creating = false;
          vm.mentionableText = '';
          vm.$notificationManager.$emit('mentionable-created', vm.mentionableType);
          vm.$notificationManager.$emit('show-toast', `${vm.mentionableType} added`, true);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
