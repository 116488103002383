import { extend } from 'vee-validate';
import { required, email, digits, oneOf } from 'vee-validate/dist/rules';

export default function () {
  extend('oneOf', {
    ...oneOf,
    message: 'Please choose an option',
  });

  // No message specified.
  extend('email', {
    ...email,
    message: 'Please enter an {_field_}',
  });

  // Override the default message.
  extend('required', {
    ...required,
    message: 'Please enter {_field_}',
  });

  extend('digits', {
    ...digits,
    message: 'Please enter your 10 digit {_field_}',
  });

  extend('insuranceOffer' , {
    validate: value => {
      return value === 'ACCEPTED' || value === 'DECLINED' || value === 'NOT_RENDERED';
    },
    message: 'Please accept or decline the insurance offer.',
  })
}
