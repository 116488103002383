<template>
  <div>
    <VDropdown theme="info-tooltip">
      <!-- This will be the popover target (for the events and position) -->
      <a class="m-r-m cursor-pointer" :href="url">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 text-gray-700" fill="currentColor">
          <path
              d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z"/>
        </svg>
      </a>
      <!-- This will be the content of the popover -->
      <template v-slot:popper>
        <div>
          <p class="header">
            Replicate session
          </p>
          <p>
            Create a new session using {{ sessionName }} as the template.
          </p>
        </div>
      </template>
    </VDropdown>
  </div>
</template>
<script>
export default {
  name: 'replicate-session',
  props: {
    sessionId: {
      type: Number,
      required: true,
    },
    sessionName: {
      type: String,
      required: true,
    }
  },
  computed: {
    url() {
      return `/paid_sessions/new?replicate_session_id=${this.sessionId}`;
    }
  }
};
</script>
