<template>
    <div class="block">
        <div class="flex flex-col border border-gray-100 rounded-sm border-solid hover:shadow-md transition-shadow duration-300">
            <a :href="'/matches/' + match.id" class="block">
                <div class="video-cover-image relative" style="padding-top: 56.25%;">
                    <img v-if="match.video && match.video.cover_image_url"
                         :src="match.video.cover_image_url"
                         :alt="matchTitle"
                         @error="handleImageError"
                         class="absolute inset-0 w-full h-full object-cover rounded-t-sm">
                    <div v-show="showPlaceholder || !match.video" class="absolute inset-0 w-full h-full flex items-center justify-center bg-gray-100 rounded-t-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-gray-200">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z" />
                        </svg>
                    </div>
                    <div v-if="showEdit" @click.prevent.stop="showOptionsMenu = !showOptionsMenu" class="absolute bg-gray-900 text-gray-50 rounded-xxs p-x-xs p-y-xs text-10 flex flex-col gap-y-xxs btn-hover-visible" style="top: 8px; right: 8px;">
                        <i class="fa fa-pencil"></i>
                    </div>
                    <div v-if="matchName" class="absolute bg-gray-900 text-gray-50 rounded-sm p-x-xs p-y-xxs text-10 flex gap-x-xs" style="bottom: 8px; right: 8px; margin-left: 8px;">
                      <span class="text-gray-50">{{ matchName }}</span>
                    </div>
                </div>
                <div class="flex align-items-center justify-space-between w-full">
                    <div class="flex-grow-1">
                        <div class="flex justify-space-between p-y-xs p-x-m border-b border-solid border-gray-200">
                            <div class="flex gap-x-sm flex-wrap">
                              <span>{{ wrestlerName }}</span>
                              <span class="text-gray-400 text-uppercase">{{ wrestlerTeamName }}</span>  
                            </div>
                            <div class="text-gray-700 relative">
                              {{ match.final_wrestler_score }}
                              <i v-if="isHomeWrestlerWinner" class="fa fa-caret-left text-gray-700 absolute" style="right: -16px; top: 50%; transform: translateY(-50%);"></i>
                            </div>
                        </div>
                        <div class="flex justify-space-between p-y-xs p-x-m" :class="{ 'border-b border-solid border-gray-200': showOptionsMenu }">
                            <div class="flex gap-x-sm flex-wrap">
                              <span class="font-bold">{{ opponentName }}</span>
                              <span class="text-gray-400 text-uppercase">{{ opponentTeamName }}</span>
                            </div>
                            <div class="font-bold relative">
                              {{ match.final_opponent_score }}
                              <i v-if="isOpponentWinner" class="fa fa-caret-left text-gray-700 absolute" style="right: -16px; top: 50%; transform: translateY(-50%);"></i>
                            </div>
                        </div>
                    </div>
                    <div class="align-self-stretch align-items-center flex border-solid border-gray-200 p-x-sm" :class="{ 'border-l': !showOptionsMenu, 'border-b-l': showOptionsMenu }">
                        <div class="text-gray-600" v-html="outcomeAbbreviation"></div>
                    </div>
                </div>
            </a>
            <div v-if="showOptionsMenu" class="m-t-sm flex flex-wrap gap-sm justify-space-between align-items-center p-sm">
                <button type="button" @click="editing = true" class="btn btn-default btn-sm">
                  <i class="fa fa-pencil m-r-xxs"></i> Edit Match
                </button>
                <button @click="showMatchDeleteForm" class="btn btn-danger btn-sm">
                  <i class="fa fa-trash m-r-xxs"></i> Delete Match
                </button>
            </div>
            <div v-if="editing" class="border border-solid border-gray-300 p-md rounded-b-sm bg-gray-50 m-t-sm">
                <h1 class="m-y-none m-b-sm font-heavy m-b-sm border-b border-solid border-gray-300 p-b-sm">Edit Match</h1>
                <match-tag-form
                  :original-video="match.original_video"
                  :existing-match="match"
                  :prefilled-event="match.event"
                  :allow-event-create="allowEventCreate"
                  :ruleset-slug="match.ruleset"
                  :unknown-opponent-id="unknownOpponentId"
                  :max-grid-columns="1"
                  @cancel="editing = false"
                  >
                </match-tag-form>
            </div>
            <div v-if="deleteMatchForm" class="border border-solid border-gray-300 p-md rounded-b-sm bg-gray-50 m-t-sm">
                <h1 class="m-y-none m-b-sm font-heavy m-b-sm border-b border-solid border-gray-300 p-b-sm">Delete Match</h1>
                <div class="m-t-sm">
                  <delete-match-form v-if="deleteMatchForm" :match="match"></delete-match-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DeleteMatchForm from './delete_match_form.vue';
import MatchTagForm from './match_tag_form.vue';

export default {
  components: { DeleteMatchForm, MatchTagForm },
  name: 'match-search-result',
  props: {
    match: {
      type: Object,
      required: true
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    allowEventCreate: {
      type: Boolean,
      default: false
    },
    unknownOpponentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      deleteMatchForm: false,
      showPlaceholder: false,
      editing: false,
      showOptionsMenu: false,
      outcomeMap: {
        "dec": "DEC",
        "m_dec": "MD",
        "tf": "TF",
        "fall": "FALL",
        "dq": "DQ",
        "default": "DEF",
        "ff": "FF"
      }
    };
  },
  created() {
    const vm = this;
    vm.$notificationManager.$on('hide-delete-match-form', vm.hideListener);
    vm.$notificationManager.$on('match-deleted', vm.matchDeletedListener);
    vm.$notificationManager.$on('match-updated', vm.matchUpdatedListener);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('hide-delete-match-form', vm.hideListener);
    vm.$notificationManager.$off('match-deleted', vm.matchDeletedListener);
    vm.$notificationManager.$off('match-updated', vm.matchUpdatedListener);
  },
  computed: {
    opponentName() {
      let opponent = this.match.opponent;
      if (opponent && opponent.first_name && opponent.last_name) {
        const firstInitial = opponent.first_name.charAt(0);
        return `${firstInitial}. ${opponent.last_name}`;
      } else {
        return "Unknown";
      }
    },
    matchTitle() {
      return `${this.match.wrestler.full_name} vs ${this.opponentName}`;
    },
    formattedEventDate() {
      return new Date(this.match.event.start_at).toLocaleDateString();
    },
    matchName() {
      if (this.match.event) {
        return this.match.event.name;
      } else {
        return "";
      }
    },
    outcomeAbbreviation() {
      return this.outcomeMap[this.match.outcome_slug] || "&nbsp;&nbsp;&nbsp;&nbsp;";
    },
    wrestlerName() {
      let wrestler = this.match.wrestler;
      if (wrestler && wrestler.first_name && wrestler.last_name) {
        const firstInitial = wrestler.first_name.charAt(0);
        return `${firstInitial}. ${wrestler.last_name}`;
      } else {
        return "Unknown";
      }
    },
    wrestlerTeamName() {
      if (this.match.wrestler?.team) {
        const name = this.match.wrestler.team.name;
        if (name.includes(' ')) {
          const initials = name.split(' ').map(word => word[0]).join('');
          return initials.length > 2 ? initials.slice(0, 3) : initials;
        } else {
          return name.slice(0, 3);
        }
      }
      return "Unk";
    },
    opponentTeamName() {
      if (this.match.opponent?.team) {
        const name = this.match.opponent.team.name;
        if (name.includes(' ')) {
          const initials = name.split(' ').map(word => word[0]).join('');
          return initials.length > 2 ? initials.slice(0, 3) : initials;
        } else {
          return name.slice(0, 3);
        }
      }
      return "Unk";
    },
    isOpponentWinner() {
      if (!this.match || !this.match.opponent) {
        return false;
      }
      return this.match.winning_wrestler_profile_id === this.match.opponent.id;
    },
    isHomeWrestlerWinner() {
      if (!this.match || !this.match.wrestler) {
        return false;
      }
      return this.match.winning_wrestler_profile_id === this.match.wrestler.id;
    }
  },
  methods: {
    hideListener(match) {
      if (this.match.id === match.id) {
        this.hideMatchDeleteForm();
      }
    },
    matchDeletedListener(match) {
      if (this.match.id === match.id) {
        this.hideMatchDeleteForm();
        this.$emit('match-deleted', this.match);
      }
    },
    matchUpdatedListener(updatedMatch) {
      if (this.match.id === updatedMatch.id) {
        Object.assign(this.match, updatedMatch);
        this.editing = false;
      }
    },
    showMatchDeleteForm() {
      this.deleteMatchForm = true;
    },
    hideMatchDeleteForm() {
      this.deleteMatchForm = false;
    },
    handleImageError() {
      this.showPlaceholder = true;
    },
  },
};
</script>
