<template>
    <div class="sign-again archive-form should-dim modify-form m-t-sm" :style="getStyles()">
        <div v-if="!alertMode">
            This will force the entire team to sign this document the next time they login.<br/>
            There is no way to undo this action - would you like to continue?
        </div>
        <div>
            <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
            <br/>
            <button v-on:click="hideSignAgainForm" class="btn btn-default">{{ cancelText() }}</button>
            <ladda-button @lbClicked="signAgainPressed" el-class="btn-primary" :loading="loading">
                {{ cta() }}
            </ladda-button>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'sign-again-form',
  components: { LaddaButton },
  props: [
    'document',
    'alertMode',
    'newDoc',
  ],
  data() {
    return {
      loading: false, // Loading is a network variable (hitting the api)

      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  methods: {
    cta() {
      if (this.newDoc) {
        return 'Yes, require signature at next login';
      }

      return 'Yes, make everyone sign again';
    },
    cancelText() {
      if (this.alertMode) {
        return 'No, thanks';
      }

      return 'Cancel';
    },
    getStyles() {
      if (this.alertMode) {
        return 'text-align: left;';
      }

      return '';
    },
    hideSignAgainForm() {
      const vm = this;
      vm.$notificationManager.$emit('hide-sign-again-document-form', vm.document);
    },
    signAgainPressed: _.throttle(function () {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;

      const url = vm.$apiService.signAgainDocumentUrl(vm.document.id);
      axios.post(url, {})
        .then((response) => {
          vm.loading = false;

          vm.$notificationManager.$emit('show-toast', 'Document will be signed by team', true);
          vm.$notificationManager.$emit('document-sign-again', vm.document);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
