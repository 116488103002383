<template>
  <div class="ibox chat-view">
    <div class="ibox-content">
      <div class="row">
        <div class="col-md-8 chat-border">
          <div id="conversation" class="chat-discussion" v-bind:style="{ marginBottom: chatFieldHeight + 'px'}">
            <div>
              <div v-show="!noMoreData">
                <div v-if="!loadingMore && !loading" class="text-center">
                  <a v-on:click="getMoreMessages()"><small>Load more...</small></a>
                </div>
                <div class="loading-inline text-center p-h-sm">
                  <small v-show="loadingMore">Loading...</small>
                  <spinner v-if="loadingMore"></spinner>
                </div>
              </div>
              <div>
                <spinner v-if="loading"></spinner>
              </div>
              <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
              <div v-for="message in messages">
                <message :message="message" :active-profile-id="activeProfileId"
                         :active-profile-type="activeProfileType"></message>
              </div>
              <template v-if="isEmpty()">
                <p class="text-muted">🤼 No messages have been sent yet, but stay tuned. You will receive notifications
                  to your email about new messages.</p>
              </template>
            </div>
            <div id="chat-messenger" class="row chat-messenger">
              <message-form
                  v-if="canPublish"
                  :group="group"
                  :endpoint="endpoint"
                  :access-key="accessKey"
                  :auth-token="authToken"
                  :cloudfront-host="cloudfrontHost"
              ></message-form>
              <div v-if="!canPublish" class="p-m">
                This is an announcement only group. If you have a question, tap the sender's name to message them
                directly.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 chat-details">
          <div class="row p-h-md border">
            <div class="col-sm-12">
              <div id="chat-group-header" class="row">
                <div class="col-xs-10" style="padding-left: 0; padding-right: 0;">
                  <h2>
                    {{ group.name }}
                  </h2>
                </div>
                <div class="col-xs-2 flex align-items-center gap-x-xxs" style="padding-left: 0; padding-right: 0;">
                  <VDropdown theme="info-tooltip">
                    <!-- This will be the popover target (for the events and position) -->
                    <div v-if="!muted" class="cursor-pointer" @click.prevent="toggleMuted" :style="{ opacity: bellOpacityValue }">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 color-gray-700">
                        <path d="M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z" />
                        <path fill-rule="evenodd" d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <div v-else class="cursor-pointer" @click.prevent="toggleMuted" :style="{ opacity: bellOpacityValue }">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-6 h-6 color-gray-700" fill="currentColor">
                        <path d="M633.99 471.02L36 3.51C29.1-2.01 19.03-.9 13.51 6l-10 12.49C-2.02 25.39-.9 35.46 6 40.98l598 467.51c6.9 5.52 16.96 4.4 22.49-2.49l10-12.49c5.52-6.9 4.41-16.97-2.5-22.49zM163.53 368c16.71-22.03 34.48-55.8 41.4-110.58l-45.47-35.55c-3.27 90.73-36.47 120.68-54.84 140.42-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h279.66l-61.4-48H163.53zM320 96c61.86 0 112 50.14 112 112 0 .2-.06.38-.06.58.02 16.84 1.16 31.77 2.79 45.73l59.53 46.54c-8.31-22.13-14.34-51.49-14.34-92.85 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84c-26.02 5.41-49.45 16.94-69.13 32.72l38.17 29.84C275 103.18 296.65 96 320 96zm0 416c35.32 0 63.97-28.65 63.97-64H256.03c0 35.35 28.65 64 63.97 64z"/>
                      </svg>
                    </div>
                    <!-- This will be the content of the popover -->
                    <template v-slot:popper>
                      <div>
                        <p class="header">
                          Mute this group?
                        </p>
                        <p>
                          Mute group to stop receiving notifications for new messages.
                        </p>
                        <p>
                          This groups is <strong>currently {{ muted ? 'muted' : 'not muted' }}</strong>
                        </p>
                      </div>
                    </template>
                  </VDropdown>
                  <div class="button-block should-dim" v-if="admin">
                    <div class="btn-group">
                      <button data-toggle="dropdown" class="btn btn-link dropdown-toggle" aria-expanded="false">
                        <i aria-hidden="true" class="fa fa-ellipsis-v"></i>
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul class="dropdown-menu right-aligned">
                        <li v-if="canChangeMembers()">
                          <a :href="editLink()">Edit...</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                {{ group.purpose }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <template v-if="showMemberCount">
                <p v-if="admin && syncedDescription !== ''" class="m-t-only-xs-xs">
                  {{ syncedDescription }}
                </p>
                <div class="member-div">
                  <p class="pull-left member-count" v-on:click="toggleShowMembers()">
                    <a>
                      {{ memberCount }} members
                    </a>
                    <template v-if="showMemberPanel">
                      &nbsp; <a><i class="fa fa-caret-down"></i></a>
                    </template>
                    <template v-else>
                      &nbsp; <a><i class="fa fa-caret-right"></i></a>
                    </template>
                  </p>
                  <div class="pull-right">
                    <VDropdown theme="info-tooltip">
                      <!-- This will be the popover target (for the events and position) -->
                      <i class="fa fa-question"></i>
                      <!-- This will be the content of the popover -->
                      <template v-slot:popper>
                        <div>
                          <p class="header">
                            What is an 'inactive' member?
                          </p>
                          <p>
                            Inactive members do not receive any notifications.
                            Inactive members are people who have a profile in WrestlingIQ,
                            but have either never been invited or have never accepted their invite.
                          </p>
                        </div>
                      </template>
                    </VDropdown>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </template>
              <template v-else>
                <div class="loading-inline">
                  <small>Loading members...</small>
                  <spinner></spinner>
                </div>
              </template>
              <div class="member-panel" v-show="showMemberPanel">
                <paged-list response-key="memberships" object-type="MessageMembership" :base-url="getMemberUrl()"
                            :manual-paging="true">
                  <template v-slot:list="slotProps">
                    <div class="flex gap-x-xxs align-items-center">
                      <!-- todo decide if we want to show this to admins or only yourself -->
                      <VDropdown theme="info-tooltip" v-if="admin && slotProps.result.muted">
                        <!-- This will be the popover target (for the events and position) -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-6 h-6 color-gray-700" fill="currentColor">
                          <path d="M633.99 471.02L36 3.51C29.1-2.01 19.03-.9 13.51 6l-10 12.49C-2.02 25.39-.9 35.46 6 40.98l598 467.51c6.9 5.52 16.96 4.4 22.49-2.49l10-12.49c5.52-6.9 4.41-16.97-2.5-22.49zM163.53 368c16.71-22.03 34.48-55.8 41.4-110.58l-45.47-35.55c-3.27 90.73-36.47 120.68-54.84 140.42-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h279.66l-61.4-48H163.53zM320 96c61.86 0 112 50.14 112 112 0 .2-.06.38-.06.58.02 16.84 1.16 31.77 2.79 45.73l59.53 46.54c-8.31-22.13-14.34-51.49-14.34-92.85 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84c-26.02 5.41-49.45 16.94-69.13 32.72l38.17 29.84C275 103.18 296.65 96 320 96zm0 416c35.32 0 63.97-28.65 63.97-64H256.03c0 35.35 28.65 64 63.97 64z"/>
                        </svg>
                        <!-- This will be the content of the popover -->
                        <template v-slot:popper>
                          <div>
                            <p class="header">
                              Muted
                            </p>
                            <p>
                              This user has muted this group.
                            </p>
                          </div>
                        </template>
                      </VDropdown>
                      <p class="p-h-xxs" v-html="htmlForMember(slotProps)">
                      </p>
                    </div>
                  </template>
                </paged-list>
              </div>
              <div>
                <small>
                  <a v-if="canChangeMembers()" :href="editMembersLink()">Edit members...</a><br/>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LocalTime from 'local-time';
import Spinner from './spinner.vue';
import MessageForm from './message_form.vue';
import Message from './message.vue';
import PagedList from './paged_list.vue';

export default {
  components: {
    PagedList,
    Message,
    Spinner,
    MessageForm,
  },
  name: 'chat',
  props: {
    group: {
      type: Object,
      required: true,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    membership: {
      type: Object,
      required: true
    },
    syncedDescription: {
      type: String,
      default: ''
    },
    activeProfileId: {
      type: Number,
      required: true
    },
    activeProfileType: {
      type: String,
      required: true
    },
    // s3 upload props
    endpoint: {
      type: String,
      required: true,
    },
    accessKey: {
      type: String,
      required: true,
    },
    authToken: {
      type: String,
      required: true
    },
    cloudfrontHost: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loadingMore: false,
      noMoreData: false,

      nextPageUrl: null,
      page: 1,
      perPage: 30,

      messagesLoaded: false,
      count: 0,
      messages: [],

      error: false,
      errorMessage: '',

      createError: false,
      createErrorMessage: '',

      memberCount: 0,
      showMemberCount: false,
      showMemberPanel: false,

      pollInterval: null,
      chatFieldHeight: 0,

      updatingMuted: false,
      muted: false,
    };
  },
  created() {
    const vm = this;
    vm.getMessages();
    vm.$on('message-created', vm.getMessages);
    vm.$notificationManager.$on('total-result-count', vm.setupMemberCount);
  },
  mounted() {
    const vm = this;
    vm.muted = vm.membership.muted;
    vm.$nextTick(() => {
      window.addEventListener('resize', vm.calculateChatFieldHeight);

      // Init
      vm.calculateChatFieldHeight();
    });
  },
  destroyed() {
    const vm = this;
    window.removeEventListener('resize', vm.calculateChatFieldHeight);
    vm.$off('message-created', vm.getMessages);
    vm.$notificationManager.$off('total-result-count', vm.setupMemberCount);

    if (vm.pollInterval !== null) {
      clearInterval(vm.pollInterval);
    }
  },
  updated() {
    LocalTime.run();
  },
  computed: {
    bellOpacityValue() {
      return this.updatingMuted ? 0.25 : 1.0;
    },
    canPublish() {
      return this.membership.can_publish
    },
  },
  methods: {
    calculateChatFieldHeight() {
      if (!this.$el) {
        this.chatFieldHeight = 0;
      }
      const container = this.$el.querySelector('#chat-messenger');
      if (container) {
        this.chatFieldHeight = container.offsetHeight + 12;
        this.scrollToBottom();
      }
    },
    htmlForMember(props) {
      const name = props.result.profile.display_name;
      const status = props.result.profile.user_status;
      const isActive = status === 'active';
      const statusText = isActive ? '' : `(${status})`;
      const statusClass = isActive ? '' : 'text-muted';

      if (this.admin && props.result.profile.profile_type !== 'Customer') {
        const url = this.urlForProfile(props.result.profile);
        return `<a href='${url}'><span class="${statusClass}">${name} ${statusText}</span></a>`;
      }

      return `<span class="${statusClass}">${name} ${statusText}</span>`;
    },
    urlForProfile(profile) {
      const type = profile.profile_type;
      const pId = profile.profile_id;

      if (type === 'ParentProfile') {
        return `/parents/${pId}`;
      } if (type === 'CoachProfile') {
        return `/coaches/${pId}`;
      } if (type === 'WrestlerProfile') {
        return `/wrestlers/${pId}`;
      }
      return '';
    },
    canChangeMembers() {
      return this.admin;
    },
    editMembersLink() {
      return `/message_groups/${this.group.id}/edit_memberships`;
    },
    editLink() {
      return `/message_groups/${this.group.id}/edit`;
    },
    setupPolling() {
      const vm = this;
      if (vm.pollInterval !== null) {
        // if we already have an interval, don't create a new one
        return;
      }

      vm.pollInterval = setInterval((() => {
        vm.checkForNewMessages();
      }), 25000);
    },
    toggleShowMembers() {
      this.showMemberPanel = !this.showMemberPanel;
    },
    setupMemberCount(objectType, count) {
      const vm = this;
      if (objectType === 'MessageMembership') {
        vm.memberCount = count;
        vm.showMemberCount = true;
      }
    },
    isEmpty() {
      return this.messagesLoaded && this.count === '0';
    },
    getPageParams(page, perPage) {
      return `?page=${page}&per_page=${perPage}`;
    },
    setNoMoreData() {
      const vm = this;
      vm.nextPageUrl = null;
      if (vm.resultCount === '0') {
        vm.noMoreData = false;
      } else {
        vm.noMoreData = true;
      }
    },
    setupHistory(headers) {
      const vm = this;

      vm.count = headers.totalcount;

      if (!headers.link) {
        vm.setNoMoreData();
        return;
      }

      const paginationLinks = headers.link.split(',');
      let nextLinkDetected = false;
      paginationLinks.forEach((l) => {
        const nextRel = 'rel="next"';
        if (l.indexOf(nextRel) !== -1) {
          const regExp = /\<([^>]+)>/;
          vm.nextPageUrl = regExp.exec(l)[1];
          nextLinkDetected = true;
        }
      });

      if (!nextLinkDetected) {
        vm.setNoMoreData();
      }
    },
    getMemberUrl() {
      return this.$apiService.messageMembershipsUrl(this.group.id);
    },
    checkForNewMessages() {
      // this is used for ajax polling to see if there have been new messages in the thread
      const vm = this;
      const url = vm.$apiService.messagesUrl(vm.group.id) + vm.getPageParams(1, vm.perPage);

      axios.get(url)
        .then((response) => {
          const updatedCount = response.headers.totalcount;
          if (updatedCount !== vm.count) {
            // count mismatch, likely a new message, reload!
            vm.resetListResults();
            vm.loadMessages(response);
            // Only show new message if you are not the author
            const firstM = response.data.messages[0];
            if (firstM) {
              const authorId = firstM.author.id;
              const authorType = firstM.author.type;
              if (authorId !== vm.activeProfileId && authorType !== vm.activeProfileType) {
                vm.$notificationManager.$emit('show-toast', 'New message', true);
              }
            }
          }
        })
        .catch((error) => {
          console.log('error checking for new messages');
        });
    },
    getMessages() {
      const vm = this;
      vm.resetListResults();

      vm.loading = true;
      const url = vm.$apiService.messagesUrl(vm.group.id) + vm.getPageParams(vm.page, vm.perPage);
      axios.get(url)
        .then((response) => {
          vm.loading = false;
          vm.loadMessages(response);
          vm.setupPolling();
        })
        .catch((error) => {
          vm.resetListResults();
          vm.errorMessage = 'Error retrieving messages';
          vm.error = true;
        });
    },
    loadMessages(response) {
      const vm = this;
      vm.messages = vm.getReversedData(response.data.messages);
      vm.setupHistory(response.headers);
      vm.messagesLoaded = true;
      vm.$nextTick(() => {
        vm.scrollToBottom();
        vm.markAsRead();
      });
    },
    getReversedData(data) {
      _.reverse(data);
      return data;
    },
    scrollToBottom() {
      const container = this.$el.querySelector('#conversation');
      container.scrollTop = container.scrollHeight;
    },
    markAsRead() {
      const vm = this;
      const url = vm.$apiService.readMessagesUrl(vm.group.id);
      axios.post(url, {})
        .then((response) => {
          console.log('marked as read');
          vm.$notificationManager.$emit('message-read');
        })
        .catch((error) => {
          console.log('failed to mark as read');
        });
    },
    // This function is _throttled above, so we have some protection against the native scroll listener
    // Still need to be cognizant that I'm not requesting more than one page though
    getMoreMessages() {
      const vm = this;
      if (vm.loadingMore) {
        // In this scenario a load more page request is already happening
        return;
      }

      vm.loadingMore = true;
      vm.page += 1;
      axios.get(vm.nextPageUrl)
        .then((response) => {
          vm.loadingMore = false;

          // before adding another page, we need to get the proportion of new pages height to maintain scroll position
          const height = $('#conversation').height();
          const scrollPosition = height * (response.data.messages.length / vm.messages.length);

          const reversedM = response.data.messages;
          _.reverse(reversedM);

          vm.messages = reversedM.concat(vm.messages);

          vm.$nextTick(() => {
            $('#conversation').scrollTop(scrollPosition);
          });
          vm.setupHistory(response.headers);
          vm.error = false;
        })
        .catch((error) => {
          vm.resetListResults();
          vm.errorMessage = `Error retrieving ${vm.responseKey}`;
          vm.error = true;
        });
    },
    resetListResults() {
      const vm = this;
      vm.loading = false;

      vm.loadingMore = false;
      vm.noMoreData = false;

      vm.nextPageUrl = null;
      vm.page = 1;
      vm.perPage = 30;

      vm.messagesLoaded = false;
      vm.count = 0;
      vm.messages = [];

      vm.error = false;
      vm.errorMessage = '';
    },
    toggleMuted() {
      if (this.updatingMuted) {
        return;
      }

      this.error = false;
      const newValue = !this.muted
      const params = {
        message_membership: {
          id: this.membership.id,
          muted: newValue
        }
      };
      this.updatingMuted = true;
      axios.put(this.$apiService.messageMembershipUrl(this.membership.id), params)
          .then((response) => {
            this.updatingMuted = false;
            this.muted = newValue;
            console.log(`Updated mute value`);
          })
          .catch((error) => {
            this.errorMessage = "Unable to mute";
            this.error = true;
            this.updatingMuted = false;
            console.error(`Could not toggle mute`);
          });
    }
  },
};
</script>
