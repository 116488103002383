<template>
  <div class="p-y-sm">
    <div class="flex align-items-center justify-space-between">
      <div class="flex-shrink-1 cursor-pointer" @click="$notificationManager.$emit('single-roster-filter-requested', roster)">
        <h3 class="m-b-none m-t-none">
          <a>{{ roster.name }}</a>
        </h3>
      </div>
      <div v-if="showEdit" class="btn-group link-btn visible-xs visible-sm align-self-flex-start">
        <button
            data-toggle="dropdown"
            aria-expanded="false"
            class="btn btn-link icon-only dropdown-toggle p-t-n p-r-n"
        >
          <i aria-hidden="true" class="fa fa-ellipsis-v"></i>
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-right pull-right">
          <li>
            <a :href="`rosters/${roster.id}/edit`" class="flex align-items-center gap-x-xs" style="display:flex;">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 color-gray-700" fill="currentColor"><path d="M491.609 73.625l-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533l-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"/></svg>
              Edit
            </a>
          </li>
        </ul>
      </div>
      <div v-if="showEdit" class="flex justify-self-end align-items-center gap-xs hidden-xs hidden-sm">
        <div>
          <VDropdown theme="info-tooltip">
            <!-- This will be the popover target (for the events and position) -->
            <a :href="`rosters/${roster.id}/edit`" class="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 color-gray-600" fill="currentColor"><path d="M491.609 73.625l-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533l-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"/></svg>
            </a>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>
                <p class="header">
                  Edit roster
                </p>
              </div>
            </template>
          </VDropdown>
        </div>
        <div>
          <a v-if="!archiveConfirm" @click="archiveConfirm = true" class="text-gray-600">
            <span v-if="isArchived">
              <VDropdown theme="info-tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-6 h-6 color-gray-600" fill="currentColor"><path d="M432 80h-82.41l-34-56.7C307.88 10.44 289.44 0 274.44 0H173.59c-15 0-33.43 10.44-41.15 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6.66 6.66 0 0 1 177 48h94a6.67 6.67 0 0 1 5.16 2.91L293.62 80H154.38zM368 464H80V128h288zM203.76 348.71A12 12 0 0 0 224 340v-35.16c48.68 5.1 65.21 26 48.45 84.78-2.15 7.53 6.15 13.37 12 8.72C303.11 383.45 320 355 320 326.19c0-62.88-39.64-82-96-86.17V204a12 12 0 0 0-20.24-8.73l-72 68a12 12 0 0 0 0 17.44z"/></svg>
                <template v-slot:popper>
                <div>
                  <p class="header">
                    Restore roster
                  </p>
                  <p>
                    Restoring <strong>{{ roster.name }}</strong> will put it back into the team's default list of rosters.
                  </p>
                </div>
              </template>
              </VDropdown>
            </span>
            <span v-else>
              <VDropdown theme="info-tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 color-gray-700" fill="currentColor">
                <path d="M464 32H48C21.5 32 0 53.5 0 80v80c0 8.8 7.2 16 16 16h16v272c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V176h16c8.8 0 16-7.2 16-16V80c0-26.5-21.5-48-48-48zm-32 400H80V176h352v256zm32-304H48V80h416v48zM204 272h104c6.6 0 12-5.4 12-12v-24c0-6.6-5.4-12-12-12H204c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12z"/>
              </svg>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <p class="header">
                    Archive roster
                  </p>
                  <p>
                    Archiving {{ roster.name }} will remove it from the list of rosters and <strong>will affect all users on your account</strong>. You can still run reports on archived rosters.
                  </p>
                </div>
              </template>
              </VDropdown>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="m-t-xxs flex align-items-center gap-x-xxs">
        <p class="m-y-none">{{ roster.stats.roster_memberships_count }} wrestlers</p>
        <div class="flex flex-wrap gap-x-xxs gap-y-xxs items-center">
            <tag
                    v-for="tagging in roster.taggings"
                    :tag="tagging.tag"
                    :key="tagging.id"
            ></tag>
        </div>
    </div>
    <p class="m-t-xxs m-b-xxs text-red-600" v-if="isArchived">Archived</p>
    <div v-if="archiveConfirm" class="border border-solid border-gray-300 p-md bg-gray-50 m-t-sm" style="margin-left: -24px; margin-right: -24px; margin-bottom: -16px;">
      <h1 class="m-y-none m-b-sm font-heavy m-b-sm border-b border-solid border-gray-300 p-b-sm" v-html="isArchived ? 'Restore roster?' : 'Archive roster?'"></h1>
      <div v-if="isArchived" class="m-t-sm">
        Restoring <strong>{{ roster.name }}</strong> will put it back into the team's default list of rosters.
      </div>
      <div v-else class="m-t-sm">
        Archiving <strong>{{ roster.name }}</strong> will remove it from the team's default list of rosters <strong>for all users on your account</strong>. You can still run reports on archived rosters.
      </div>
      <div class="grid grid-cols-1 gap-xs m-t-sm">
        <ladda-button v-if="showEdit" @lbClicked="archivePressed" :el-class="isArchived ? 'btn-success w-full' : 'btn-danger w-full'" :loading="loading">
          Yes, <span v-if="isArchived">restore</span><span v-else>archive</span>
        </ladda-button>
        <button v-on:click="archiveConfirm = false" class="btn btn-default w-full">Cancel</button>
      </div>
      <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
    </div>
  </div>
</template>
<script>
import Tag from "../../shared/tag.vue";
import LaddaButton from '../../shared/ladda_button.vue';
import { errorableMix } from './mixins/errorable_mix';

export default {
  name: "roster",
  components: { Tag, LaddaButton },
  mixins: [errorableMix],
  props: {
    roster: {
      type: Object,
      required: true,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tags() {
      return _.map(this.roster.taggings, "tag");
    },
  },
  data() {
    return {
      archiveConfirm: false,
      isArchived: this.roster.archived,
      loading: false,
    };
  },
  methods: {
    archivePressed() {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;

      const params = {
        roster: {
          archived: !this.isArchived,
        },
      };

      const url = vm.$apiService.rosterUrl(vm.roster.id);
      axios.put(url, params)
          .then((response) => {
            vm.loading = false;
            vm.isArchived = !vm.isArchived;
            vm.archiveConfirm = false;
            vm.$notificationManager.$emit('show-toast', vm.isArchived ? 'Roster archived' : 'Roster restored', true);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = vm.parseErrorResponse(error);
            vm.error = true;
          });
    }
  }
};
</script>
