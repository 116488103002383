<template>
    <div class="search-result agreement-result">
        <template v-if="result">
            <div class='hr-line-dashed'></div>
            <div class="row">
                <div class="col-xs-10">
                    <h3>
                        <a :href="showUrl()">
                            {{ result.paid_session.name }}
                        </a>
                    </h3>
                    <p :class="subheadClass()">
                        {{ subhead() }}
                    </p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

export default {
  name: 'registration',
  props: [
    'result',
  ],
  data() {
    return {
      good_standing: true,
    };
  },
  mounted() {
    const vm = this;
    if (vm.result.status === 'paid' || vm.result.status === 'partially_paid') {
      vm.good_standing = true;
    } else {
      vm.good_standing = false;
    }
  },
  methods: {
    subheadClass() {
      if (this.good_standing) {
        return 'search-type-label';
      }

      return 'search-type-label text-danger';
    },
    subhead() {
      if (this.good_standing) {
        return 'Good standing';
      } else if (this.result.status === 'pending') {
        return 'Pending payment';
      } else if (this.result.status === 'canceled') {
        return 'Canceled';
      }
      return 'Payment overdue or failed';
    },
    showUrl() {
      return `/paid_sessions/${this.result.paid_session.id}`;
    },
  },
};
</script>
