export const paramAndHistoryMixin = {
  methods: {
    getQueryParameter(queryKey) {
      return decodeURIComponent((new RegExp(`[?|&]${queryKey}=` + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || '';
    },
    // This could be decomposed into several functions, leaving for now
    replaceHistoryState(queryKey, value) {
      const uri = window.location.href;
      queryKey = Array.isArray(value) ? `${queryKey}[]` : queryKey;
      const regPattern = Array.isArray(value) ? '[?&]' + _.escapeRegExp(queryKey) + '=.*?\\d*' : '([?&])' + _.escapeRegExp(queryKey) + '=.*?(&|$)';

      const regex = new RegExp(regPattern, 'ig');
      let separator = uri.indexOf('?') !== -1 ? '&' : '?';
      const match = uri.match(regex);
      const notOnlyParam = match != null && match[0].indexOf('&') !== -1 && match[0].indexOf('?') !== -1;
      const replacement = notOnlyParam ? '?' : '';
      let newState = uri;

      // Deal with array based value types
      if (Array.isArray(value)) {
        if (value.length === 0) {
          newState = uri.replace(regex, replacement);
        } else {
          if (match) {
            // First blow away all of the previous query key array values
            newState = uri.replace(regex, '');
            // Then transform the first & into a question mark
            let hasAmp = newState.indexOf('&') !== -1;
            let hasQue = newState.indexOf('?') !== -1;
            if (hasAmp && !hasQue) {
              // If we are here, we need to transform the first ampersand into a question mark
              // because blowing away previous key array values has pushed a parameter into the first slot that wasn't before
              newState = newState.replace('&', '?');
              separator = '&';
            } else {
              separator = hasQue ? '&' : '?';
            }
          }
          // Then build up all the array in the query parameters
          let params = [];
          value.forEach((v) => {
            params.push(`${queryKey}=${v}`);
          });

          newState = `${newState + separator}${params.join('&')}`;
        }
      } else {
        if (value === null) {
          // Remove the query string entirely
          if (match) {
            newState = uri.replace(regex, replacement);
          }
        } else {
          // handle single value type query parameters
          if (match) {
            newState = uri.replace(regex, `$1${queryKey}=${value}$2`);
          } else {
            newState = `${uri + separator + queryKey}=${value}`;
          }
        }
      }

      history.replaceState(null, null, newState);
    },
  },
};
