<template>
    <div>
        <template v-if="showSearchForm">
            <search
                    class="search-form"
                    response-key="results"
                    placeholder="Parent name..."
                    :mutate-history="false"
                    :allow-add="false"
                    :display-all-on-empty="false"
                    readable-model-name="parent"
                    :guardians-only="true"
                    base-url="/api/v1/search">
                <template v-slot:list="slotProps">
                    <div class="profile-wrapper" @click="profileClicked(slotProps.result)">
                        <div class='search-result'>
                            <div class='hr-line-dashed'></div>
                            <h3>
                                <span class="selectable">{{ slotProps.result.name }}</span>
                            </h3>
                        </div>
                    </div>
                </template>
            </search>
        </template>
        <template v-else-if="guardianableSelected && !showSearchForm">
            <div role="form" class="guardian-associate">
                <div class="form-group">
                    <label>
                        Would you like to add {{ guardianableSelected.name }} as a parent/guardian to {{ wrestlerName }}?
                    </label>
                </div>
                <div class="button-area text-right">
                    <div v-show="error">
                        <small>
                            <span class="text-danger">{{ errorMessage }}</span>
                        </small>
                    </div>
                    <button class="btn btn-default" @click="cancel()">Cancel</button>
                    <ladda-button @lbClicked="postGuardianRelationship" el-class="btn-primary" :loading="loading">
                        Yes, add parent
                    </ladda-button>
                </div>
            </div>
        </template>
        <template v-else>
            <div>
                <a @click.prevent="showForm()"><i class="fa fa-plus"></i> Parent</a>
            </div>
        </template>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';
import Search from '../../shared/search.vue';

export default {
  name: 'associate-guardian',
  components: { Search, LaddaButton },
  props: [
    'wrestlerProfileId',
    'wrestlerName',
  ],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      guardianableSelected: null,
      showSearchForm: false,
    };
  },
  methods: {
    showForm() {
      this.showSearchForm = true;
    },
    profileClicked(profile) {
      this.guardianableSelected = profile;
      this.showSearchForm = false;
    },
    cancel() {
      this.guardianableSelected = null;
      this.showSearchForm = false;
    },
    postGuardianRelationship: _.throttle(function () {
      const vm = this;

      if (vm.loading) {
        return;
      }
      const params = {
        guardian_relationship: {
          guardianable_id: vm.guardianableSelected.id,
          guardianable_type: vm.guardianableSelected.type,
          wrestler_profile_id: vm.wrestlerProfileId,
        },
      };

      const url = vm.$apiService.guardianRelationshipUrl();
      axios.post(url, params)
        .then((response) => {
          vm.loading = false;
          vm.$notificationManager.$emit('show-toast', 'Added parent', true);
          window.location.assign(`/wrestlers/${vm.wrestlerProfileId}`);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },

};
</script>
