<template>
  <div>
    <button @click="showForm" class="btn btn-primary">
      <i class="fa fa-plus"></i> Invite Parent / Guardian
    </button>
    <div v-if="isFormVisible" class="bg-gray-50 m-t-sm border rounded-sm border-solid border-gray-300 p-sm">
      <parent-create
        :redirect="redirect"
        :wrestler-profiles="wrestlerProfiles"
        :show-cancel-button="true"
        @close="hideForm"
      ></parent-create>
    </div>
  </div>
</template>

<script>
import ParentCreate from './parent_create.vue';

export default {
  components: {
    ParentCreate,
  },
  props: {
    wrestlerProfiles: {
      type: Array,
      required: true,
    },
    redirect: {
      type: String,
      default: '/wrestlers',
    },
  },
  data() {
    return {
      isFormVisible: false,
    };
  },
  methods: {
    showForm() {
      this.isFormVisible = true;
    },
    hideForm() {
      this.isFormVisible = false;
    },
  },
};
</script>