<template>
  <button type="button" @click="launchEditor" class="btn btn-default direction-ltr">
    <i class="fa fa-scissors"></i> Create Clip
  </button>
</template>

<script>
import {
  setPlugins,
  openDefaultEditor,
  imageStateToCanvas,
  createDefaultImageWriter,
  createDefaultMediaWriter,
} from '@pqina/pintura';

// Import trim plugin and locale
import { 
  plugin_trim, 
  plugin_trim_locale_en_gb,
  createMuxerEncoder,
  createDefaultVideoWriter,
  createMediaStreamEncoder,
} from '@pqina/pintura-video';

import * as Mp4Muxer from 'mp4-muxer';

export default {
  name: 'pintura-video-editor',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // Load the Trim plugin
    setPlugins(plugin_trim);
  },
  methods: {
    launchEditor() {
      const editor = openDefaultEditor({
        src: this.src,
        // Enable the trim plugin and set it as the default
        util: 'trim',
        utils: [
          'trim',
          'crop',
          'filter',
          'finetune',
          'annotate',
          'sticker',
          'resize',
        ],
        stickers: ['🎉', '😄', '👍', '💪', '🙌', '🔥', '💯', '🚀', '🏆'],

        // maximum duration of the clip in seconds
        imageMaxDuration: 15,


        // Disable crop tool if you don't want it at all
        // cropEnabled: false,

        // Add the Trim plugin locale object
        locale: {...plugin_trim_locale_en_gb},

        // here we supply two video writers
        imageWriter: createDefaultMediaWriter(
          {
              // Generic options writer options
          },
          [
              // For writing images
              createDefaultImageWriter(),

              // Use muxer to encode videos
              createDefaultVideoWriter({
                  encoder: createMuxerEncoder({
                      // when using the mp4 muxer we need to set video/mp4 mimetype
                      muxer: Mp4Muxer,
                      mimeType: 'video/mp4',

                      // video and audio bitrate to use (optional)
                      videoBitrate: 3000000, // 3MBps which is what our 720p videos are encoded to
                      audioBitrate: 96000, // 96kbps

                      // this draws the image
                      imageStateToCanvas,

                      // enable logging
                      log: true,
                  }),
              }),

              // Media stream as fallback
              createDefaultVideoWriter({
                  encoder: createMediaStreamEncoder({
                      imageStateToCanvas,
                  }),
              }),
          ]
        ),
      });

      let vm = this;
      editor.on('process', (videoState) => {
        vm.downloadFile(videoState.dest);
      });
    },
    downloadFile(file) {
        // Create a hidden link and set the URL using createObjectURL
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = URL.createObjectURL(file);
        link.download = file.name;

        // We need to add the link to the DOM for "click()" to work
        document.body.appendChild(link);
        link.click();

        // To make this work on Firefox we need to wait a short moment before clean up
        setTimeout(() => {
            URL.revokeObjectURL(link.href);
            link.parentNode.removeChild(link);
        }, 0);
    },
  },
};
</script>
