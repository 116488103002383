<template>
    <div class="search-result document-result">
        <template v-if="result && !isDeleted">
            <div v-if="!noHr" class='hr-line-dashed'></div>
            <div class="row">
                <div class="col-sm-12 col-md-8">
                    <h3>
                        <a :href="showUrl()">
                            {{ result.name }}
                        </a>
                    </h3>
                </div>
                <div class="col-sm-12 col-md-4 card-actions">
                    <div v-if="showDelete">
                        <a :href="editUrl()" class="m-r-sm">
                            Edit
                        </a>
                        <a class="m-r-sm" @click.prevent="showDocumentDeleteForm()">
                            Delete
                        </a>
                        <a v-if="showSignAgain" @click.prevent="showSignAgainForm()" class="m-r-sm">
                            Sign again
                        </a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <delete-document-form v-show="deleteDocumentForm" :document="result"></delete-document-form>
                    <sign-again-form v-show="signAgainForm" :document="result"></sign-again-form>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import DeleteDocumentForm from './delete_document_form.vue';
import SignAgainForm from './sign_again_form.vue';

export default {
  name: 'document-result',
  components: { SignAgainForm, DeleteDocumentForm },
  props: [
    'result',
    'showEdit',
    'showDelete',
    'noHr',
  ],
  data() {
    return {
      deleteDocumentForm: false,
      signAgainForm: false,
      showSignAgain: false,
      isDeleted: false,
    };
  },
  created() {
    const vm = this;
    vm.showSignAgain = (vm.result.needs_signature && vm.showEdit);

    vm.$notificationManager.$on('hide-delete-document-form', vm.hideListener);
    vm.$notificationManager.$on('document-deleted', vm.documentDeletedListener);

    vm.$notificationManager.$on('hide-sign-again-document-form', vm.hideListener);
    vm.$notificationManager.$on('document-sign-again', vm.hideListener);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('hide-delete-document-form', vm.hideListener);
    vm.$notificationManager.$off('document-deleted', vm.documentDeletedListener);

    vm.$notificationManager.$off('hide-sign-again-document-form', vm.hideListener);
    vm.$notificationManager.$off('document-sign-again', vm.hideListener);
  },
  methods: {
    hideListener(doc) {
      const vm = this;
      if (vm.result.id === doc.id) {
        vm.hideDocumentDeleteForm();
        vm.hideSignAgainForm();
      }
    },
    showUrl() {
      return `/documents/${this.result.id}`;
    },
    editUrl() {
      return `/documents/${this.result.id}/edit`;
    },
    documentDeletedListener(doc) {
      const vm = this;
      if (vm.result.id === doc.id) {
        vm.hideDocumentDeleteForm();
        vm.isDeleted = true;
      }
    },
    showDocumentDeleteForm() {
      this.deleteDocumentForm = true;
    },
    hideDocumentDeleteForm() {
      this.deleteDocumentForm = false;
    },
    showSignAgainForm() {
      this.signAgainForm = true;
    },
    hideSignAgainForm() {
      this.signAgainForm = false;
    },
  },
};
</script>
