<template>
  <div class='search-result'>
    <div class='hr-line-dashed'></div>
    <h3>
      <template v-if="isSelectable">
        {{ result.first_name }} {{ result.last_name }}
      </template>
      <template v-else>
        <a :href="'/opposing_teams/' + result.team.id + '/opposing_wrestlers/' + result.id ">{{ result.first_name }} {{ result.last_name }}</a>
      </template>
    </h3>
    <p class='search-type-label'>
      {{ result.team.name }}<br/>
      {{ result.weight_class }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'opposing-wrestler-search-result',
  props: [
    'result',
    'isSelectable',
  ],
};
</script>
