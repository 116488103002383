<template>
  <div>
    <a v-if="!confirm" @click="confirm = true" class="text-gray-800">
      <span v-if="isArchived" class="text-underline">
        Restore...
      </span>
      <span v-else>
        <VDropdown theme="info-tooltip">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-6 color-gray-700" fill="currentColor">
            <path d="M464 32H48C21.5 32 0 53.5 0 80v80c0 8.8 7.2 16 16 16h16v272c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V176h16c8.8 0 16-7.2 16-16V80c0-26.5-21.5-48-48-48zm-32 400H80V176h352v256zm32-304H48V80h416v48zM204 272h104c6.6 0 12-5.4 12-12v-24c0-6.6-5.4-12-12-12H204c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12z"/>
          </svg>
          <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>
                <p class="header">
                  Archive session
                </p>
                <p>
                  Remove {{ sessionName }} from the registration list.
                </p>
              </div>
            </template>
          </VDropdown>
      </span>
    </a>
    <div v-else
         class="archive-form should-dim modify-form m-t-sm card-bordered-dashed text-left">
      <div v-if="isArchived">
        Are you sure you want to restore {{ sessionName }}? Doing so will put it back in your list of sessions.
      </div>
      <div v-else-if="!allowArchive">
        Please ensure all members have been canceled to archive a recurring registration.
      </div>
      <div v-else>
        Are you sure you want to archive {{ sessionName }}? Archiving will remove it from your list of sessions, but will not impact historical registration data.
      </div>
      <div class="">
        <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
        <br/>
        <button v-on:click="confirm = false" class="btn btn-default">Cancel</button>
        <ladda-button v-if="allowArchive" @lbClicked="archivePressed" :el-class="isArchived ? 'btn-success' : 'btn-danger'" :loading="loading">
          Yes, <span v-if="isArchived">restore</span><span v-else>archive</span>
        </ladda-button>
      </div>
    </div>
  </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'archive-paid-session',
  components: { LaddaButton },
  props: {
    sessionId: {
      type: Number,
      required: true
    },
    isArchived: {
      type: Boolean,
      required: true
    },
    sessionName: {
      type: String,
      default: 'this session',
    },
    allowArchive: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      confirm: false,
      loading: false, // Loading is a network variable (hitting the api)

      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  methods: {
    archivePressed: _.throttle(function () {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;

      const params = {
        paid_session: {
          archived: !this.isArchived
        }
      }
      const url = vm.$apiService.paidSessionUrl(vm.sessionId);
      axios.put(url, params)
          .then((response) => {
            vm.loading = false;
            const msg = this.isArchived ? 'Restored..' : 'Archived'
            const url = this.isArchived ? '/paid_sessions' : `/paid_sessions?archived_session_id=${vm.sessionId}`;
            vm.$notificationManager.$emit('show-toast', msg, true);
            window.location.assign(url);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support.';
            vm.error = true;
          });
    }, 500),
  },
};
</script>
