<template>
    <div class="ibox">
        <div class="ibox-title">
            <div class="row">
                <div class="col-xs-6 col-md-8">
                    <h1>
                        {{ report.result.paid_session.name }}, Registration Q&A Report
                    </h1>
                    <p class="m-b-none"><small>
                        Report generated on {{ processedAt() }}
                    </small></p>
                </div>
                <div class="col-xs-6 col-md-4 text-right">
                    <div class="ibox-tools">
                        <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span class="hidden-xs">Download CSV</span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="ibox-content">
            <template v-if="anonSession">
                <div>
                    <h1>Registrants</h1>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                                <th v-bind:style="secondColStyles">First name</th>
                                <th>Email</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="cu in customers">
                                <td class="first-col"
                                    v-bind:style="firstColStyles"
                                >
                                    {{ cu.last_name }}
                                </td>
                                <td v-bind:style="secondColStyles">{{ cu.first_name }}</td>
                                <td>{{ cu.email }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
            <template v-else>
                <div>
                    <h1>Wrestlers</h1>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                                <th v-bind:style="secondColStyles">First name</th>
                                <th>Role</th>
                                <th v-for="question in wQuestions">{{ headerForQuestion(question) }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="wp in wrestlers">
                                <td class="first-col"
                                    v-bind:style="firstColStyles"
                                >
                                    {{ wp.last_name }}
                                </td>
                                <td v-bind:style="secondColStyles">{{ wp.first_name }}</td>
                                <td>Wrestler</td>
                                <td v-for="question in wQuestions" v-html="wrestlerAnswerFor(wAnswers, wp, question, false)">
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <h1>Parents</h1>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                                <th v-bind:style="secondColStyles">First name</th>
                                <th>Role</th>
                                <th v-for="question in pQuestions">{{ headerForQuestion(question) }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="pp in parents">
                                <td class="first-col"
                                    v-bind:style="firstColStyles"
                                >
                                    {{ pp.last_name }}
                                </td>
                                <td v-bind:style="secondColStyles">{{ pp.first_name }}</td>
                                <td>Parent</td>
                                <td v-for="question in pQuestions" v-html="parentAnswerFor(pAnswers, pp, question, false)">
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <h1>Coaches</h1>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                                <th v-bind:style="secondColStyles">First name</th>
                                <th>Role</th>
                                <th v-for="question in cQuestions">{{ headerForQuestion(question) }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="cp in coaches">
                                <td class="first-col"
                                    v-bind:style="firstColStyles"
                                >
                                    {{ cp.last_name }}
                                </td>
                                <td v-bind:style="secondColStyles">{{ cp.first_name }}</td>
                                <td>Coach</td>
                                <td v-for="question in cQuestions" v-html="coachAnswerFor(cAnswers, cp, question, false)">
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';

export default {
  name: 'session-registration-answer-report',
  mixins: [downloadMix, formattingMixin],
  props: [
    'report',
    'cloudfrontBaseUrl',
  ],
  data() {
    return {
      customers: [],

      wrestlers: [],
      wQuestions: [],
      wAnswers: [],

      parents: [],
      pQuestions: [],
      pAnswers: [],

      coaches: [],
      cQuestions: [],
      cAnswers: [],

      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  computed: {
    anonSession() {
      return this.report.result.paid_session.session_type === 'anonymous';
    },
  },
  mounted() {
    const vm = this;
    const customers = _.get(vm.report, 'result.customers.objects', null);
    vm.customers = _.sortBy(customers, ['last_name']);

    const wrestlers = _.get(vm.report, 'result.wrestler_profiles.objects', null);
    vm.wrestlers = _.sortBy(wrestlers, ['last_name']);
    vm.wQuestions = _.get(vm.report, 'result.wrestler_registration_questions.objects', null);
    vm.wAnswers = _.get(vm.report, 'result.wrestler_registration_answers.objects', null);

    const parents = _.get(vm.report, 'result.parent_profiles.objects', null);
    vm.parents = _.sortBy(parents, ['last_name']);
    vm.pQuestions = _.get(vm.report, 'result.parent_registration_questions.objects', null);
    vm.pAnswers = _.get(vm.report, 'result.parent_registration_answers.objects', null);

    const coaches = _.get(vm.report, 'result.coach_profiles.objects', null);
    vm.coaches = _.sortBy(coaches, ['last_name']);
    vm.cQuestions = _.get(vm.report, 'result.coach_registration_questions.objects', null);
    vm.cAnswers = _.get(vm.report, 'result.coach_registration_answers.objects', null);

    vm.$nextTick(function () {
      const header = this.$refs.lnameHeader;
      if (header) {
        const rect = header.getBoundingClientRect();
        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    headerForQuestion(question) {
      return `${question.prompt} (${question.group_with})`;
    },
    formattedAnswer(answer, question, isCSV) {
      if (!answer) {
        return '';
      }
      const useHtml = !isCSV;

      if (question.type === 'RegAddressQuestion') {
        return this.formatAddressAns(answer.answer, useHtml);
      } if (question.type === 'RegPhoneNumberQuestion') {
        return this.formatPhoneAns(answer.answer);
      } if (question.type === 'RegYesNoQuestion') {
        return this.formatYesNoAns(answer.answer);
      } if (question.type === 'RegMultiSelectQuestion') {
        return this.formatMultiSelectAns(answer.answer, useHtml);
      } if (question.type === 'RegPhotoQuestion') {
        const fileUrl = `${this.cloudfrontBaseUrl}${answer.answer.s3_file_name}`;
        if (isCSV) {
          return fileUrl;
        }
        return `<a class="text-underline" href='${fileUrl}'>${fileUrl}</a>`;
      }
      return answer.answer;
    },
    coachAnswerFor(cAnswers, profile, question, isCSV) {
      const vm = this;
      const answer = _.find(cAnswers, {
        profile_id: profile.id,
        profile_type: 'CoachProfile',
        registration_question_id: question.id,
      });

      return vm.formattedAnswer(answer, question, isCSV);
    },
    parentAnswerFor(pAnswers, profile, question, isCSV) {
      const vm = this;
      const answer = _.find(pAnswers, {
        profile_id: profile.id,
        profile_type: 'ParentProfile',
        registration_question_id: question.id,
      });

      return vm.formattedAnswer(answer, question, isCSV);
    },
    wrestlerAnswerFor(wAnswers, profile, question, isCSV) {
      const vm = this;
      const answer = _.find(wAnswers, {
        profile_id: profile.id,
        profile_type: 'WrestlerProfile',
        registration_question_id: question.id,
      });

      return vm.formattedAnswer(answer, question, isCSV);
    },
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    downloadCustomerCSV() {
      const vm = this;
      const fileName = `${vm.report.result.paid_session.name} - Customer Registration Q&A Report - ${vm.processedAt()}.csv`;

      let rows = [];
      rows.push([
        'last_name',
        'first_name',
        'email',
      ]);

      const answerRows = [];
      vm.customers.forEach((c) => {
        rows.push([
          c.last_name,
          c.first_name,
          c.email,
        ]);
      });
      rows = _.concat(rows, answerRows);
      this.downloadCSVFile(fileName, rows);
    },
    downloadWrestlerCSV() {
      const vm = this;
      const fileName = `${vm.report.result.paid_session.name} - Wrestler Registration Q&A Report - ${vm.processedAt()}.csv`;

      let rows = [];
      rows.push(vm.getHeaderCSVRows(vm.wQuestions));

      rows = _.concat(rows, vm.getBodyCSVRows('Wrestler', vm.wrestlers, vm.wQuestions, vm.wAnswers, vm.wrestlerAnswerFor));
      this.downloadCSVFile(fileName, rows);
    },
    downloadParentCSV() {
      const vm = this;
      const fileName = `${vm.report.result.paid_session.name} - Parent Registration Q&A Report - ${vm.processedAt()}.csv`;

      let rows = [];
      rows.push(vm.getHeaderCSVRows(vm.pQuestions));

      rows = _.concat(rows, vm.getBodyCSVRows('Parent', vm.parents, vm.pQuestions, vm.pAnswers, vm.parentAnswerFor));
      this.downloadCSVFile(fileName, rows);
    },
    downloadCoachCSV() {
      const vm = this;
      const fileName = `${vm.report.result.paid_session.name} - Coach Registration Q&A Report - ${vm.processedAt()}.csv`;

      let rows = [];
      rows.push(vm.getHeaderCSVRows(vm.cQuestions));

      rows = _.concat(rows, vm.getBodyCSVRows('Coach', vm.coaches, vm.cQuestions, vm.cAnswers, vm.coachAnswerFor));
      this.downloadCSVFile(fileName, rows);
    },
    getBodyCSVRows(role, profiles, questions, answers, retrievalFunction) {
      const vm = this;
      const rows = [];
      profiles.forEach((p) => {
        rows.push(_.concat([
          p.last_name,
          p.first_name,
          role,
        ], _.map(questions, (q) => retrievalFunction(answers, p, q, true))));
      });
      return rows;
    },
    getHeaderCSVRows(questions) {
      return _.concat([
        'Last name',
        'First name',
        'Role',
      ], _.flatMap(questions, this.headerForQuestion));
    },
    downloadCSV() {
      const vm = this;
      if (vm.anonSession) {
        vm.downloadCustomerCSV();
      } else {
        vm.downloadWrestlerCSV();
        vm.downloadParentCSV();
        vm.downloadCoachCSV();
      }
    },
  },
};
</script>
