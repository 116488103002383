<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="row">
        <div class="col-xs-6 col-md-8">
          <h1>
            {{ report.name }}, {{ report.result.roster_name }}, {{ reportSubTitle() }}
          </h1>
          <p class="m-b-none"><small>
            Report generated on {{ processedAt() }}
          </small></p>
        </div>
        <div class="col-xs-6 col-md-4 text-right">
          <div class="ibox-tools">
            <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span
                class="hidden-xs">Download CSV</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div class="m-t-md m-b-xs">
        <div class="row">
          <div class="col-lg-8">
            <ul class="list-inline">
              <li class="text-primary">
                <i class="fa fa-check"></i> Present
              </li>
              <li class="text-primary">
                Ex - Excused
              </li>
              <li class="text-danger">
                Ux - Unexcused
              </li>
              <li class="text-warning">
                La - Late
              </li>
              <li>
                In - Injured
              </li>
              <li>
                Ot - Other
              </li>
              <li>
                <i class="fa fa-question"></i> Unknown
              </li>
            </ul>
          </div>
          <div class="col-lg-4">
            <!-- todo filters based on client side data -->
            <!--<button class="btn btn-default btn-compact"><i class="fa fa-filter"></i> Filter</button>-->
          </div>
        </div>
      </div>
      <div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <template v-for="(header, index) in headers">
                <th v-if="index === 0" ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                <th v-else-if="index === 1" v-bind:style="secondColStyles">First name</th>
                <th v-else>
                  {{ header.text }}
                </th>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in rows">
              <template v-for="(value, index) in row">
                <td v-if="index === 0" class="first-col"
                    v-bind:style="firstColStyles"
                >
                  {{ value }}
                </td>
                <td v-else-if="index === 1" v-bind:style="secondColStyles">{{ value }}</td>
                <td v-else v-html="value"></td>
              </template>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downloadMix } from '../mixins/download_mix';
import { formattingMixin } from '../mixins/formatting_mix';

export default {
  name: 'check-in-report',
  mixins: [downloadMix, formattingMixin],
  props: {
    report: {
      type: Object,
      required: true,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      events: [],
      wrestlers: [],
      checkIns: [],
      questions: [],

      rows: [],
      csvRows: [],
      headers: [],

      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  mounted() {
    const vm = this;
    const events = _.get(vm.report, 'result.events.objects', null);
    vm.events = _.sortBy(events, ['start_at']);

    const wrestlers = _.get(vm.report, 'result.wrestler_profiles.objects', null);
    vm.wrestlers = _.sortBy(wrestlers, ['last_name']);

    vm.checkIns = _.get(vm.report, 'result.check_ins.objects', null);
    vm.questions = _.get(vm.report, 'result.registration_questions.objects', null);

    vm.rows = [];
    vm.setupRows();
  },
  methods: {
    questionNameForId(questionId) {
      const question = this.questionFor(questionId);

      return question?.prompt || '';
    },
    questionFor(questionId) {
      return _.find(this.questions, (question) => question.id === questionId);
    },
    setupRows() {
      const vm = this;

      // Setup the headers, so the rows can ensure they follow the exact same structure
      const standardHeaders = [
        {
          text: 'Last name',
        },
        {
          text: 'First name',
        },
        {
          text: 'Event',
        },
        {
          text: 'Recorded at',
        },
        {
          text: 'Status',
        },
        {
          text: 'Notes',
        },
      ];
      const customHeaders = _.map(vm.questions, (question) => ({
        text: question.prompt,
        question_id: question.id,
      }));
      vm.headers = _.concat(standardHeaders, customHeaders);

      // massage the data structure of some rows, one per check in
      const rows = [];
      // Not ideal, but going to go with it for now. Ideally we would store the data in a better structure
      // and only output csv if needed. That's not for today
      const csvRows = [];
      vm.checkIns.forEach((checkIn) => {
        const wrestler = vm.wrestlerForCheckIn(checkIn);
        const event = vm.eventForCheckIn(checkIn);
        let eventName = _.get(event, 'name', 'None');
        if (eventName !== 'None') {
          const eventStart = moment(event.start_at).format('MMM D');
          eventName = `${event.name} (${eventStart})`;
        }

        // First put down the standard headers responses
        const row = [
          wrestler.last_name,
          wrestler.first_name,
          eventName,
          moment(checkIn.created_at).format('M/D/YYYY, hh:mm a'),
          _.get(checkIn, 'status', 'unknown'),
          _.get(checkIn, 'notes', ''),
        ];

        const csvRow = _.clone(row);
        // Then grab custom answers in the order the headers expect them
        customHeaders.forEach((header) => {
          const answer = _.find(checkIn.registration_answers, { registration_question_id: header.question_id });
          if (answer) {
            row.push(vm.formattedAnswer(answer, vm.questionFor(answer.registration_question_id), false));
            // note the true flag at the end
            csvRow.push(vm.formattedAnswer(answer, vm.questionFor(answer.registration_question_id), true));
          } else {
            row.push('');
            csvRow.push('');
          }
        });

        rows.push(row);
        csvRows.push(csvRow);
      });
      vm.rows = rows;
      vm.csvRows = csvRows;

      vm.$nextTick(function () {
        const header = this.$refs.lnameHeader;
        // not sure why this report puts this down as an array, but probably cause I messed with the rendering
        if (header && header.length > 0) {
          const rect = header[0].getBoundingClientRect();
          this.$set(this.firstColStyles, 'width', `${rect.width}px`);
          this.$set(this.firstColStyles, 'position', 'absolute');

          this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
        }
      });
    },
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    reportSubTitle() {
      const vm = this;
      const start = moment(vm.report.start_at).format('MMM D, YYYY');
      const end = moment(vm.report.end_at).format('MMM D, YYYY');
      return `${start} to ${end}`;
    },
    wrestlerForCheckIn(checkIn) {
      const vm = this;
      return _.find(vm.wrestlers, {
        id: checkIn.wrestler_profile_id,
      });
    },
    eventForCheckIn(checkIn) {
      const vm = this;
      return _.find(vm.events, {
        id: checkIn.event_id,
      });
    },
    htmlForStatus(status) {
      switch (status) {
        case 'present':
          return "<i class='fa fa-check text-primary'></i>";
        case 'excused':
          return 'Ex';
        case 'unexcused':
          return "<span class='text-danger'>Ux</span>";
        case 'late':
          return "<span class='text-warning'>La</span>";
        case 'injured':
          return 'In';
        case 'Other':
          return 'Ot';
        default:
          return "<i class='fa fa-question'></i>";
      }
    },
    downloadCSV() {
      const vm = this;

      const fileName = `${vm.report.name} ${vm.report.result.roster_name} ${vm.reportSubTitle()}.csv`;
      const rows = _.concat([_.map(vm.headers, 'text')], vm.csvRows);
      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
